import AspAxios from "../../service/AspAxios";
import * as Paths from '../../service/config'

export default class VoucherService {

  getAllBrand() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllBrand).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))
    })
  }

  getAllCategories() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllCategory).then(res => {
        resolve(res.data);
      }).catch(err => reject(err))
    })
  }

  getAllSupply() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllSupply).then(res => {
        resolve(res.data);
      }).catch(err => reject(err))
    })
  }

  getSubCategoryByCategory(category) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getSubCategoryByCategory, category).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))
    })
  }

  findBySerialNo(serialNo) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.findBySerialNo + "/" + serialNo).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))
    })
  }

  findSupplyByBarcode(barcode, distyId) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.findSupplyByBarcode + "/" + barcode + "/" + distyId).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))
    })
  }

  findModelList(brandId, subCategoryId) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.findModelList + "/" + brandId + "/" + subCategoryId).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))
    })
  }

  createTicket(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.createTicket, request, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 60000
      })
        .then((res => {
          resolve(res.data);
        }))
        .catch(err => {
          reject(err);
        });
    });
  }

  searchTicket(request, _size, _page, _sort) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.searchTicket + '?size=' + _size + '&page=' + _page  + '&sort=' + _sort, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  searchTicketEager(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.searchTicketEager, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  approveTicket(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.approveTicket, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  rejectTicket(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.rejectTicket, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

};
