import AspAxios from "../../../service/AspAxios";
import * as Paths from "../../../service/config"


export default class ProductPriceAdminService{


  getCurrentModelPricesSupply(searchProductPrice,_page,_size)
  {
    return new Promise((resolve,reject)=>{
      AspAxios.post(Paths.getCurrentModelPricesSupplyForAdmin,searchProductPrice,{params:{size:_size,page:_page}}).then(res=>
        resolve(res.data)

      ).catch(err=>reject(err))
    })

  }
  getCurrentModelPricesDisty(searchProductPrice,_page,_size,_sort)
  {
    return new Promise((resolve,reject)=>{
      AspAxios.post(Paths.getCurrentModelPricesDistyForAdmin+ '?size=' + _size + '&page=' + _page  + '&sort=' + _sort ,searchProductPrice).then(res=>
        resolve(res.data)

      ).catch(err=>reject(err))
    })

  }

  getCurrentModelPricesDistyList(searchProductPrice)
  {
    return new Promise((resolve,reject)=>{
      AspAxios.post(Paths.getCurrentModelPricesDistyForAdminList,searchProductPrice).then(res=>
        resolve(res.data)

      ).catch(err=>reject(err))
    })

  }

  getModelPriceDetail(searchProductPrice)
  {
    return new Promise((resolve,reject)=>{
      AspAxios.post(Paths.getModelPriceDetail,searchProductPrice).then(res=>
        resolve(res.data)

      ).catch(err=>reject(err))
    })

  }

  getLastModelPriceDetail(searchProductPrice)
  {
    return new Promise((resolve,reject)=>{
      AspAxios.post(Paths.getLastModelPriceDetail,searchProductPrice).then(res=>
        resolve(res.data)

      ).catch(err=>reject(err))
    })

  }

};
