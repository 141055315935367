import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Fieldset } from "primereact/fieldset";
import { InputMask } from "primereact/inputmask";

import * as services from "./httpService";
import { handleError } from "../../service/HandleErrorService";

const CustomerInfoDropdown = ({
  id,
  value,
  onChange,
  onChangeName,
  onChangeSurname,
  onChangeMobileNo,
  onChangeBroadBand,
  onChangeFixedLine,
  customerInfo,
  nameValue,
  surnameValue,
  mobileNoValue,
  broadBandValue,
  fixedLineValue,
  disabled,
  label,
  className,
  placeholder,
  selectCustomerInfo,
}) => {

  const [ttCrm, setTtCrm] = useState([]);

  const toast = useRef();

  let options = (
    ttCrm.map((result) => {
      return (
        result.name
      )
    })
  )
  const fetchGetTtCrm = () => {
    services
      .ttCrmGetAll()
      .then((res) => {
        setTtCrm(res);
        console.log(res)
      })
      .catch((err) => {
        handleError(err, toast);
      });
  };
  useEffect(() => {
    fetchGetTtCrm();
  },[])

  return (
    <>
      <Fieldset
        className="p-text-bold"
        legend="Müşteri Tipi"
        style={{ marginTop: "10px" }}
      >
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-6">
            <label htmlFor="customerSurname">Müşteri Tipi</label>
            <Dropdown
              id={id}
              value={value}
              onChange={onChange}
              options={options}
              disabled={disabled}
              optionLabel={label}
              className={className}
              placeholder={placeholder}
              selectCustomerInfo={selectCustomerInfo}
            />
          </div>
        </div>
      </Fieldset>
      <Fieldset
        className="p-text-bold"
        legend="Müşteri Bilgileri"
        style={{ marginTop: "10px" }}
      >
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-6">
            <label htmlFor="customerName">Müşteri Adı</label>
            <InputText
              value={nameValue}
              id="customerName"
              type="text"
              onChange={onChangeName}
              placeholder="Müşteri adı giriniz.."
            />
          </div>

          <div className="p-field p-col-12 p-md-6">
            <label htmlFor="customerSurname">Müşteri Soyadı</label>
            <InputText
              value={surnameValue}
              onChange={onChangeSurname}
              id="customerSurname"
              type="text"
              placeholder="Müşteri soyadı giriniz.."
            />
          </div>

          {selectCustomerInfo === 1 ? (
            <div className="p-field p-col-12 p-md-6">
              <label htmlFor="customerMobileNo">Mobil No</label>
              <InputMask
                id="customerMobileNo"
                mask="(999) 999-9999"
                value={mobileNoValue}
                onChange={onChangeMobileNo}
                placeholder="(___) ___- ____"
              ></InputMask>
            </div>
          ) : selectCustomerInfo === 2 ? (
            <div className="p-field p-col-12 p-md-6">
              <label htmlFor="customerBroadBand">Geniş Bant - Hizmet No</label>
              <InputText
                id="customerBroadBand"
                value={broadBandValue}
                onChange={onChangeBroadBand}
                placeholder="Geniş bant - Hizmet No Giriniz.."
              />
            </div>
          ) : selectCustomerInfo === 3 ? (
            <div className="p-field p-col-12 p-md-6">
              <label htmlFor="customerFixedLine">Sabit Hat</label>
              <InputMask
                id="customerFixedLine"
                mask="(999) 999-9999"
                value={fixedLineValue}
                onChange={onChangeFixedLine}
                placeholder="(___) ___- ____"
              ></InputMask>
            </div>
          ) : null}
        </div>
      </Fieldset>
    </>
  );
};

export default CustomerInfoDropdown;
