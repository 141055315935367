import React, { Component, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

const BarcodeScanner = ({ visible, scan, onHide }) => {
  return (
    <div>
   
      <Dialog
        header={<h5 style={{textAlign:'center'}}>BARKOD OKUMA</h5> }
    
        className="p-m-4"
        visible={visible}
        modal
        onHide={onHide}
      >
        <div
          style={{
            width: "370px",
          }}
        >
          <BarcodeScannerComponent width={370} height={400} onUpdate={scan} />
        </div>
      </Dialog>
    </div>
  );
};

export default BarcodeScanner;
