import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import ProductSalesManagementService from "./httpService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Calendar} from "primereact/calendar";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {ResponsiveDataTableWithoutRowClass} from "../../components/ResponsiveDataTableWithoutRowClass";
import {Dropdown} from "primereact/dropdown";

const SaleInfoList = () => {

  const resellerUser = JSON.parse(localStorage.getItem('user')).user?.roles.includes("RESELLER_ADMIN") ||
    JSON.parse(localStorage.getItem('user')).user?.roles.includes("RESELLER_USER");
  const resellerCode = JSON.parse(localStorage.getItem('user')).user?.loginReseller?.code;
  const distyAdmin = JSON.parse(localStorage.getItem('user')).user?.roles.includes("DISTY_ADMIN");
  const distyCode = JSON.parse(localStorage.getItem('user')).user?.disty?.code;
  const supplyAdmin = JSON.parse(localStorage.getItem('user')).user?.roles.includes("SUPPLY_ADMIN");
  const supplyId = JSON.parse(localStorage.getItem('user')).user?.supply?.id;
  const toast = useRef(null);
  const productSalesManagementService = new ProductSalesManagementService();

  const [loading, setLoading] = useState(false);
  const [selectedTimeBegin, setSelectedTimeBegin] = useState(null);
  const [selectedTimeEnd, setSelectedTimeEnd] = useState(null);
  const [serialNo, setSerialNo] = useState(null);
  const [barcode, setBarcode] = useState(null);
  const [waybillNo, setWaybillNo] = useState(null);
  const [selectedDistyCode, setSelectedDistyCode] = useState(null);
  const [selectedResellerCode, setSelectedResellerCode] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [waybillDate, setWaybillDate] = useState(null);
  const [selectedProductSaleInfoType, setSelectedProductSaleInfoType] = useState(null);
  const [searchCriteriaLazyLoading, setSearchCriteriaLazyLoading] = useState(false);
  const [searchCriteriaProgressing, setSearchCriteriaProgressing] = useState(false);
  const [saleInfoList, setSaleInfoList] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState({});

  const [exportExcelDisabled, setExportExcelDisabled] = useState(null);

  const [technicalDetailModal, setTechnicalDetailModal] = useState(false);
  const [islemDetailModal, setIslemDetailModal] = useState(false);
  const [selectedIslem, setSelectedIslem] = useState(null);

  const [waybillDetailModal, setWaybillDetailModal] = useState(false);
  const [selectedWaybill, setSelectedWaybill] = useState(false);

  const [waybillDetailListModal, setWaybillDetailListModal] = useState(false);
  const [selectedWaybillList, setSelectedWaybillList] = useState(false);

  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  const [productSaleInfoTypeOptions, setProductSaleInfoTypeOptions] = useState(
    [
      {value: "WAYBILL", name: "İrsaliye"},
      {value: "INVOICE", name: "Fatura"}
    ]
  );

  useEffect(() => {
    if (distyAdmin) {
      setSelectedDistyCode(distyCode);
    } else if (resellerUser) {
      setSelectedResellerCode(resellerCode);
    } else {
      //searchSaleInfo({...paginationConfig, activePage: 0});
    }
  }, []);

  useEffect(() => {
    setSearchCriteriaLazyLoading(true);
  }, [selectedTimeBegin, selectedTimeEnd, serialNo, barcode, waybillNo, selectedProductSaleInfoType, selectedDistyCode, selectedResellerCode, invoiceDate, waybillDate, searchCriteria]);

  useEffect(() => {
    if (searchCriteriaLazyLoading) {
      setTimeout(() => {
        setSearchCriteriaProgressing(true);
        setSearchCriteriaLazyLoading(false);
      }, 2000);
    }
  }, [searchCriteriaLazyLoading]);

  useEffect(() => {
    if (searchCriteriaProgressing) {
      searchSaleInfo({...paginationConfig, activePage: 0});
      setSearchCriteriaProgressing(false);
    }
  }, [searchCriteriaProgressing]);

  const searchSaleInfo = (pageState) => {
    var dateControl = true;
    if (selectedTimeBegin != null && selectedTimeEnd != null && selectedTimeBegin.getTime() > selectedTimeEnd.getTime()) {
      dateControl = false;
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Aramak için başlangıç tarihi bitiş tarihinden önce olmalıdır.'
      });
    }
    if (dateControl) {
      setLoading(true);
      var sort = "";
      if (searchCriteria.sortField != null && searchCriteria.sortOrder != null) {
        sort += searchCriteria.sortField;
        if (searchCriteria.sortOrder == 1) {
          sort += ",asc";
        } else {
          sort += ",desc";
        }
      } else {
        sort += "createDateTime,desc";
      }
      searchCriteria.serialNo = serialNo;
      searchCriteria.barcode = barcode;
      searchCriteria.waybillNo = waybillNo;

      if (selectedTimeBegin != null) {
        searchCriteria.saleDateStart = selectedTimeBegin.toLocaleDateString("tr-TR") + " " + selectedTimeBegin.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.saleDateStart = null;
      }

      if (selectedTimeEnd != null) {
        searchCriteria.saleDateEnd = selectedTimeEnd.toLocaleDateString("tr-TR") + " " + selectedTimeEnd.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.saleDateEnd = null;
      }

      searchCriteria.productSaleInfoType = selectedProductSaleInfoType;
      searchCriteria.distyCode = selectedDistyCode;
      searchCriteria.resellerCode = selectedResellerCode;
      if (invoiceDate != null) {
        searchCriteria.invoiceDate = formatDate(invoiceDate);
      } else {
        searchCriteria.invoiceDate = null;
      }
      if (waybillDate != null) {
        searchCriteria.waybillDate = formatDate(waybillDate);
      } else {
        searchCriteria.waybillDate = null;
      }
      if(supplyAdmin){
        searchCriteria.supplyId = supplyId;
      }
      productSalesManagementService.searchSaleInfo(searchCriteria, pageState.itemsPerPage, pageState.activePage, sort).then(res => {
        console.log("searchSaleInfo", res.content);
        if (res.content && res.content.length > 0) {
          res.content.forEach(c => {
            if (c.waybillSaleInfoList && c.waybillSaleInfoList.length > 0) {
              let waybillDateMap = new Map();
              c.waybillSaleInfoList.forEach(w => {
                let date = w.waybillDateString.split(" ")[0];
                if (waybillDateMap.has(date)) {
                  waybillDateMap.set(date, waybillDateMap.get(date) + 1);
                } else {
                  waybillDateMap.set(date, 1);
                }
                let waybillDateMapString = "";
                waybillDateMap.forEach(function (value, key) {
                  waybillDateMapString += key + "(" + value + ")\n";
                });
                c.waybillDateMapString = waybillDateMapString;
              });
            }
          });
          setSaleInfoList(res.content);

          pageState.totalRecords = res.totalElements;
          pageState.first = pageState.itemsPerPage * pageState.activePage;
          setPaginationConfig({
            ...pageState,
          });
        } else {
          setSaleInfoList([]);
        }
      }).catch(err => {
        toast?.current?.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Satış bilgisi getirme başarısız.'
        });
        console.log(err);
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  const exportExcel = () => {
    var dateControl = false;
    if (invoiceDate != null || waybillDate != null || (waybillNo != null && waybillNo != "")) {
      dateControl = true;
    } else {
      if (serialNo != null && serialNo != "") {
        dateControl = true;
      } else if (barcode != null && barcode != "") {
        if (selectedTimeBegin != null && selectedTimeEnd != null) {
          if (selectedTimeEnd.getTime() - selectedTimeBegin.getTime() <= 32 * 24 * 3600000) {
            if (selectedTimeBegin.getTime() <= selectedTimeEnd.getTime()) {
              dateControl = true;
            } else {
              toast.current.show({
                severity: 'warn',
                summary: 'Uyarı',
                detail: 'Başlangıç tarihi bitiş tarihinden önce olmalıdır.'
              });
            }
          } else {
            toast.current.show({
              severity: 'warn',
              summary: 'Uyarı',
              detail: 'Excel almak için tarih aralığı bir aydan fazla olmamalıdır.'
            });
          }
        } else {
          toast.current.show({
            severity: 'warn',
            summary: 'Uyarı',
            detail: 'Excel almak için tarih alanları dolu olmalıdır.'
          });
        }
      } else {
        if (selectedTimeBegin != null && selectedTimeEnd != null) {
          if (selectedTimeEnd.getTime() - selectedTimeBegin.getTime() <= 32 * 24 * 3600000) {
            if (selectedTimeBegin.getTime() <= selectedTimeEnd.getTime()) {
              dateControl = true;
            } else {
              toast.current.show({
                severity: 'warn',
                summary: 'Uyarı',
                detail: 'Başlangıç tarihi bitiş tarihinden önce olmalıdır.'
              });
            }
          } else {
            toast.current.show({
              severity: 'warn',
              summary: 'Uyarı',
              detail: 'Excel almak için tarih aralığı bir aydan fazla olmamalıdır.'
            });
          }
        } else {
          toast.current.show({
            severity: 'warn',
            summary: 'Uyarı',
            detail: 'Excel almak için tarih alanları dolu olmalıdır.'
          });
        }
      }
    }
    if (dateControl) {
      setExportExcelDisabled(true);

      searchCriteria.serialNo = serialNo;
      searchCriteria.barcode = barcode;
      searchCriteria.waybillNo = waybillNo;
      if (selectedTimeBegin != null) {
        searchCriteria.saleDateStart = selectedTimeBegin.toLocaleDateString("tr-TR") + " " + selectedTimeBegin.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.saleDateStart = null;
      }

      if (selectedTimeEnd != null) {
        searchCriteria.saleDateEnd = selectedTimeEnd.toLocaleDateString("tr-TR") + " " + selectedTimeEnd.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.saleDateEnd = null;
      }

      searchCriteria.distyCode = selectedDistyCode;
      searchCriteria.resellerCode = selectedResellerCode;
      if (invoiceDate != null) {
        searchCriteria.invoiceDate = formatDate(invoiceDate);
      } else {
        searchCriteria.invoiceDate = null;
      }
      if (waybillDate != null) {
        searchCriteria.waybillDate = formatDate(waybillDate);
      } else {
        searchCriteria.waybillDate = null;
      }
      if(supplyAdmin){
        searchCriteria.supplyId = supplyId;
      }
      productSalesManagementService.searchSaleInfoEager(searchCriteria).then(res => {
        console.log("searchSaleInfoEager", res);
        import('xlsx').then(xlsx => {
          var excelList = [];
          res.forEach(a => {
            let barcodeList = "";
            let serialNoList = "";
            a.productSaleInfoDetailList?.forEach(p => {
              barcodeList += p.barcode + ",";
              serialNoList += p.serialNo + ",";
            });
            barcodeList = barcodeList.substring(0, barcodeList.length - 1);
            serialNoList = serialNoList.substring(0, serialNoList.length - 1);
            excelList.push({
              "Fatura Tarihi": a.invoiceDateString,
              "İrsaliye Tarihi": (a.productSaleInfoType == "INVOICE" && a.waybillDateMapString) ? a.waybillDateMapString : a.waybillDateString,
              "Dağıtım Tipi": a.productSaleInfoTypeDisplay,
              "Fatura No": a.invoiceNo,
              "İrsaliye No": a.waybillNo,
              "Distribütör Kodu": a.distyCode,
              "Bayi Kod": a.reseller?.code,
              "Bayi Ad": a.reseller?.name,
              "Bayi Bölge": a.reseller?.area,
              "Bayi Ana Bölge": a.reseller?.mainArea,
              "Açıklama": a.description,
              "KDV Tutarı": addCommas(a.vat),
              "KDV Oranı": addCommas(a.vatRatio),
              "Fiyat": addCommas(a.price),
              "KDV Dahil Fiyat": addCommas(a.vatIncludedPrice),
              "İskonto Tutarı": addCommas(a.discountAmount),
              "İskonto Oranı": addCommas(a.discountRatio),
              "Kargo Firması": a.shipmentCompanyName,
              "Kargo Takip Kodu": a.shipmentTrackingCode,
              "Kargo Takip Adresi": a.shipmentTrackingUrl,
              "Kargo Durumu": a.shipmentStatusDisplay,
              "Aksesuar Barkodları": barcodeList,
              "Aksesuar Seri Numaraları": serialNoList
            })
          });
          const worksheet = xlsx.utils.json_to_sheet(excelList);
          var wscols = [
            {width: 18},
            {width: 18},
            {width: 20},
            {width: 18},
            {width: 18},
            {width: 15},
            {width: 15},
            {width: 60},
            {width: 18},
            {width: 18},
            {width: 18},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 20},
            {width: 30},
            {width: 20},
            {width: 80},
            {width: 80}
          ];
          worksheet["!cols"] = wscols;
          const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
          const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
          saveAsExcelFile(excelBuffer, 'Distribütör Dağıtım Bilgileri');
        });
      }).catch(err => {
        toast?.current?.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Dağıtım bilgisi getirme başarısız.'
        });
        console.log(err);
      }).finally(() => {
        setExportExcelDisabled(false);
      });
    }
  };

  const exportExcelDetail = () => {
    var dateControl = false;
    if (invoiceDate != null || waybillDate != null || (waybillNo != null && waybillNo != "")) {
      dateControl = true;
    } else {
      if (serialNo != null && serialNo != "") {
        dateControl = true;
      } else if (barcode != null && barcode != "") {
        if (selectedTimeBegin != null && selectedTimeEnd != null) {
          if (selectedTimeEnd.getTime() - selectedTimeBegin.getTime() <= 32 * 24 * 3600000) {
            if (selectedTimeBegin.getTime() <= selectedTimeEnd.getTime()) {
              dateControl = true;
            } else {
              toast.current.show({
                severity: 'warn',
                summary: 'Uyarı',
                detail: 'Başlangıç tarihi bitiş tarihinden önce olmalıdır.'
              });
            }
          } else {
            toast.current.show({
              severity: 'warn',
              summary: 'Uyarı',
              detail: 'Excel almak için tarih aralığı bir aydan fazla olmamalıdır.'
            });
          }
        } else {
          toast.current.show({
            severity: 'warn',
            summary: 'Uyarı',
            detail: 'Excel almak için tarih alanları dolu olmalıdır.'
          });
        }
      } else {
        if (selectedTimeBegin != null && selectedTimeEnd != null) {
          if (selectedTimeEnd.getTime() - selectedTimeBegin.getTime() <= 32 * 24 * 3600000) {
            if (selectedTimeBegin.getTime() <= selectedTimeEnd.getTime()) {
              dateControl = true;
            } else {
              toast.current.show({
                severity: 'warn',
                summary: 'Uyarı',
                detail: 'Başlangıç tarihi bitiş tarihinden önce olmalıdır.'
              });
            }
          } else {
            toast.current.show({
              severity: 'warn',
              summary: 'Uyarı',
              detail: 'Excel almak için tarih aralığı bir aydan fazla olmamalıdır.'
            });
          }
        } else {
          toast.current.show({
            severity: 'warn',
            summary: 'Uyarı',
            detail: 'Excel almak için tarih alanları dolu olmalıdır.'
          });
        }
      }
    }
    if (dateControl) {
      setExportExcelDisabled(true);

      searchCriteria.serialNo = serialNo;
      searchCriteria.barcode = barcode;
      searchCriteria.waybillNo = waybillNo;
      if (selectedTimeBegin != null) {
        searchCriteria.saleDateStart = selectedTimeBegin.toLocaleDateString("tr-TR") + " " + selectedTimeBegin.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.saleDateStart = null;
      }

      if (selectedTimeEnd != null) {
        searchCriteria.saleDateEnd = selectedTimeEnd.toLocaleDateString("tr-TR") + " " + selectedTimeEnd.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.saleDateEnd = null;
      }

      searchCriteria.distyCode = selectedDistyCode;
      searchCriteria.resellerCode = selectedResellerCode;
      if (invoiceDate != null) {
        searchCriteria.invoiceDate = formatDate(invoiceDate);
      } else {
        searchCriteria.invoiceDate = null;
      }
      if (waybillDate != null) {
        searchCriteria.waybillDate = formatDate(waybillDate);
      } else {
        searchCriteria.waybillDate = null;
      }
      if(supplyAdmin){
        searchCriteria.supplyId = supplyId;
      }
      productSalesManagementService.searchSaleInfoEager(searchCriteria).then(res => {
        console.log("searchSaleInfoEager", res);
        import('xlsx').then(xlsx => {
          var excelList = [];
          res.forEach(a => {
            a.productSaleInfoDetailList?.forEach(p => {
              excelList.push({
                "Fatura Tarihi": a.invoiceDateString,
                "İrsaliye Tarihi": (a.productSaleInfoType == "INVOICE" && a.waybillDateMapString) ? a.waybillDateMapString : a.waybillDateString,
                "Dağıtım Tipi": a.productSaleInfoTypeDisplay,
                "Fatura No": a.invoiceNo,
                "İrsaliye No": a.waybillNo,
                "Distribütör Kodu": a.distyCode,
                "Bayi Kod": a.reseller?.code,
                "Bayi Ad": a.reseller?.name,
                "Bayi Bölge": a.reseller?.area,
                "Bayi Ana Bölge": a.reseller?.mainArea,
                "Açıklama": a.description,
                "KDV Tutarı": addCommas(a.vat),
                "KDV Oranı": addCommas(a.vatRatio),
                "Fiyat": addCommas(a.price),
                "KDV Dahil Fiyat": addCommas(a.vatIncludedPrice),
                "İskonto Tutarı": addCommas(a.discountAmount),
                "İskonto Oranı": addCommas(a.discountRatio),
                "Kargo Firması": a.shipmentCompanyName,
                "Kargo Takip Kodu": a.shipmentTrackingCode,
                "Kargo Takip Adresi": a.shipmentTrackingUrl,
                "Kargo Durumu": a.shipmentStatusDisplay,
                "Aksesuar Barkod": p.barcode,
                "Aksesuar Seri Numara": p.serialNo,
                "Aksesuar Açıklama": p.description,
                "Aksesuar KDV": p.vat,
                "Aksesuar KDV Oranı": p.vatRatio,
                "Aksesuar Fiyat": p.price,
                "Aksesuar KDV Dahil Fiyat": p.vatIncludedPrice,
                "Aksesuar İskonto Tutarı": p.discount,
                "Aksesuar İskonto Oranı": p.discountRatio
              });
            });

          });
          const worksheet = xlsx.utils.json_to_sheet(excelList);
          var wscols = [
            {width: 18},
            {width: 18},
            {width: 20},
            {width: 18},
            {width: 18},
            {width: 15},
            {width: 15},
            {width: 60},
            {width: 18},
            {width: 18},
            {width: 18},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 20},
            {width: 30},
            {width: 20},
            {width: 15},
            {width: 20},
            {width: 18},
            {width: 15},
            {width: 20},
            {width: 15},
            {width: 22},
            {width: 22},
            {width: 22},
          ];
          worksheet["!cols"] = wscols;
          const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
          const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
          saveAsExcelFile(excelBuffer, 'Distribütör Dağıtım Bilgileri');
        });
      }).catch(err => {
        toast?.current?.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Dağıtım bilgisi getirme başarısız.'
        });
        console.log(err);
      }).finally(() => {
        setExportExcelDisabled(false);
      });
    }
  };

  const exportExcelSelectedWaybill = () => {
    import('xlsx').then(xlsx => {
      var excelList = [];
      selectedWaybill.productSaleInfoDetailList?.forEach(p => {
        excelList.push({
          "Aksesuar Barkod": p.barcode,
          "Aksesuar Seri Numara": p.serialNo,
          "Aksesuar Durum": p.productSerialNo?.productSerialNoStatusDisplay,
          "Aksesuar Açıklama": p.description,
          "Aksesuar KDV": p.vat,
          "Aksesuar KDV Oranı": p.vatRatio,
          "Aksesuar Fiyat": p.price,
          "Aksesuar KDV Dahil Fiyat": p.vatIncludedPrice,
          "Aksesuar İskonto Tutarı": p.discount,
          "Aksesuar İskonto Oranı": p.discountRatio
        });
      });
      const worksheet = xlsx.utils.json_to_sheet(excelList);
      var wscols = [
        {width: 15},
        {width: 20},
        {width: 15},
        {width: 18},
        {width: 15},
        {width: 20},
        {width: 15},
        {width: 22},
        {width: 22},
        {width: 22},
      ];
      worksheet["!cols"] = wscols;
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      saveAsExcelFile(excelBuffer, 'Aksesuar Bigileri');
    });
  };

  const exportExcelSelectedIslem = () => {
    import('xlsx').then(xlsx => {
      var excelList = [];
      selectedIslem.productSaleInfoDetailList?.forEach(p => {
        excelList.push({
          "Aksesuar Barkod": p.barcode,
          "Aksesuar Seri Numara": p.serialNo,
          "Aksesuar Durum": p.productSerialNo?.productSerialNoStatusDisplay,
          "Aksesuar Açıklama": p.description,
          "Aksesuar KDV": p.vat,
          "Aksesuar KDV Oranı": p.vatRatio,
          "Aksesuar Fiyat": p.price,
          "Aksesuar KDV Dahil Fiyat": p.vatIncludedPrice,
          "Aksesuar İskonto Tutarı": p.discount,
          "Aksesuar İskonto Oranı": p.discountRatio
        });
      });
      const worksheet = xlsx.utils.json_to_sheet(excelList);
      var wscols = [
        {width: 15},
        {width: 20},
        {width: 15},
        {width: 18},
        {width: 15},
        {width: 20},
        {width: 15},
        {width: 22},
        {width: 22},
        {width: 22},
      ];
      worksheet["!cols"] = wscols;
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      saveAsExcelFile(excelBuffer, 'Aksesuar Bigileri');
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    searchSaleInfo(pageState);
  };

  const onFilter = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const onSort = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const onInvoiceDateChange = (e) => {
    setInvoiceDate(e.value);
  };

  const onWaybillDateChange = (e) => {
    setWaybillDate(e.value);
  };

  const technicalDetailModalModalShow = (e) => {
    setTechnicalDetailModal(true);
    saleInfoList.forEach(s => {
      if (s.id == e.currentTarget.id) {
        setSelectedIslem(s);
      }
    });
  };

  const technicalDetailModalModalHide = () => {
    setTechnicalDetailModal(false);
  };

  const islemDetailModalModalShow = (e) => {
    setIslemDetailModal(true);
    saleInfoList.forEach(s => {
      if (s.id == e.currentTarget.id) {
        setSelectedIslem(s);
      }
    });
  };

  const islemDetailModalModalHide = () => {
    setIslemDetailModal(false);
  };

  const waybillModalShow = (e) => {
    setWaybillDetailModal(true);
    saleInfoList.forEach(s => {
      if (s.id == e.currentTarget.id) {
        setSelectedWaybill(s);
      }
    });
  };

  const waybillModalShowWithWaybillSaleInfo = (e) => {
    setWaybillDetailModal(true);
    saleInfoList.forEach(s => {
      if (s.id == e.currentTarget.id) {
        setSelectedWaybill(s.waybillSaleInfo);
      }
    });
  };

  const waybillModalHide = () => {
    setWaybillDetailModal(false);
  };

  const waybillModalShowWithWaybillSaleInfoList = (e) => {
    setWaybillDetailListModal(true);
    saleInfoList.forEach(s => {
      if (s.id == e.currentTarget.id) {
        setSelectedWaybillList(s.waybillSaleInfoList);
      }
    });
  };

  const waybillModalHideList = () => {
    setWaybillDetailListModal(false);
  };


  const addCommas = num => num?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const responsiveColumnRight = (title, content) => {
    return (
      <div>
        <span className="p-column-title">{title}</span>
        <span style={{float: 'right'}}>{content}</span>
      </div>
    );
  };

  const invoiceDateTemplate = (data) => {
    return responsiveColumn("Fatura Tarihi", data?.invoiceDateString)
  };

  const waybillDateTemplate = (data) => {
    return responsiveColumn("İrsaliye Tarihi",
      <div>
        {
          (data?.productSaleInfoType == "INVOICE" && data?.waybillDateMapString) ? data?.waybillDateMapString : data?.waybillDateString
        }
      </div>
    )
  };

  const invoiceNoTemplate = (data) => {
    return responsiveColumn("Fatura No", data?.invoiceNo)
  };

  const productSaleInfoTypeDisplayTemplate = (data) => {
    return responsiveColumn("Dağıtım Tipi", data?.productSaleInfoTypeDisplay)
  };

  const distyCodeTemplate = (data) => {
    return responsiveColumn("Distribütör Kodu", data?.distyCode)
  };

  const resellerCodeTemplate = (data) => {
    return responsiveColumn("Bayi", data?.reseller?.code + " - " + data?.reseller?.name)
  };

  const vatIncludedPriceTemplate = (data) => {
    return responsiveColumnRight("KDV Dahil Fiyat", addCommas(data?.vatIncludedPrice))
  };

  const accessoryCampaignSerialTitleButtonTemplate = (data) => {
    return (
      <div className="p-d-flex p-justify-even">
        {
          data.productSaleInfoType == 'WAYBILL' &&
          <Button id={data.id} type="button" className="p-button-sm p-button-secondary" label="İrsaliye"
                  onClick={waybillModalShow}/>
        }
        {
          (data.productSaleInfoType == 'INVOICE' && data.waybillSaleInfoList != null && data.waybillSaleInfoList.length > 0) &&
          <Button id={data.id} type="button" className="p-button-sm p-button-secondary"
                  label={"İrsaliyeler(" + data.waybillSaleInfoList.length + ")"}
                  onClick={waybillModalShowWithWaybillSaleInfoList}/>
        }
        {
          (data.productSaleInfoType == 'INVOICE') &&
          <Button id={data.id} type="button" className="p-button-sm" label="Fatura"
                  onClick={islemDetailModalModalShow}/>
        }
        {
          (data.productSaleInfoType == 'TECHNICAL_SERVICE_CHANGE') &&
          <Button id={data.id} type="button" className="p-button-sm p-button-info" label="Detay"
                  onClick={technicalDetailModalModalShow}/>
        }
      </div>
    );
  };

  const filterDate = (value, filter) => {
    if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    return value === formatDate(filter);
  };

  const formatDate = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return day + '.' + month + '.' + date.getFullYear();
  };

  const invoiceDateFilter = <Calendar value={invoiceDate} onChange={onInvoiceDateChange} dateFormat="dd.mm.yy"
                                      className="p-column-filter" showButtonBar/>;

  const waybillDateFilter = <Calendar value={waybillDate} onChange={onWaybillDateChange} dateFormat="dd.mm.yy"
                                      className="p-column-filter" showButtonBar/>;

  const distyCodeFilter = <InputText value={selectedDistyCode} onChange={(e) => setSelectedDistyCode(e.target.value)}
                                     disabled={distyAdmin} className="p-column-filter"/>;

  const resellerCodeFilter = <InputText value={selectedResellerCode}
                                        onChange={(e) => setSelectedResellerCode(e.target.value)}
                                        disabled={resellerUser} className="p-column-filter"/>;

  const barcodeTemplate = (data) => {
    return responsiveColumn("Barkod", data?.barcode)
  };

  const serialNoTemplate = (data) => {
    return responsiveColumn("Seri Numara", data?.serialNo)
  };

  const statusTemplate = (data) => {
    return responsiveColumn("Durum", data?.productSerialNo?.productSerialNoStatusDisplay)
  };

  const descriptionTemplate = (data) => {
    return responsiveColumn("Açıklama", data?.description)
  };

  const vatTemplate = (data) => {
    return responsiveColumnRight("KDV", addCommas(data?.vat))
  };

  const vatRatioTemplate = (data) => {
    return responsiveColumn("KDV Oranı", data?.vatRatio)
  };

  const priceTemplate = (data) => {
    return responsiveColumnRight("Fiyat", addCommas(data?.price))
  };

  const discountTemplate = (data) => {
    return responsiveColumnRight("İskonto Tutarı", addCommas(data?.discount))
  };

  const discountRatioTemplate = (data) => {
    return responsiveColumn("İskonto Oranı", data?.discountRatio)
  };

  const saleRowClass = (data) => {
    return {
      'orangeRow': data.productSaleInfoType == 'WAYBILL',
      'blueRow': data.productSaleInfoType == 'TECHNICAL_SERVICE_CHANGE'
    }
  };

  const renderProductSaleInfoTypeFilter = () => {
    return (
      <Dropdown value={selectedProductSaleInfoType} options={productSaleInfoTypeOptions}
                onChange={(e) => setSelectedProductSaleInfoType(e.value)}
                optionLabel="name" showClear className="p-column-filter"/>
    );
  };

  const productSaleInfoTypeFilterElement = renderProductSaleInfoTypeFilter();

  const header =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>Distribütör Dağıtım Bilgileri</div>
        <div>
          <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
                  disabled={exportExcelDisabled}
                  onClick={exportExcel} style={{marginRight: 5}}/>
          <Button type="button" icon="pi pi-external-link" label="Detayları Excel’e Aktar"
                  className="p-button p-button-secondary p-button-sm"
                  disabled={exportExcelDisabled}
                  onClick={exportExcelDetail}/>
        </div>
      </div>
    </div>;

  const waybillHeader =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>Aksesuar Bigileri</div>
        <div>
          <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
                  disabled={exportExcelDisabled}
                  onClick={exportExcelSelectedWaybill}/>
        </div>
      </div>
    </div>;

  const islemHeader =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>Aksesuar Bigileri</div>
        <div>
          <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
                  disabled={exportExcelDisabled}
                  onClick={exportExcelSelectedIslem}/>
        </div>
      </div>
    </div>;

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-12 p-md-2 p-fluid">
            <label htmlFor="baslangic">Tarihi Aralığı</label>
            <Calendar id="baslangic" showIcon dateFormat={'dd.mm.yy'} style={{marginTop: 5}}
                      value={selectedTimeBegin} showTime showButtonBar
                      onChange={(e) => setSelectedTimeBegin(e.value)}
                      placeholder="Tarih Başlangıç"/>
          </div>
          <div className="p-col-12 p-md-2 p-fluid"><br className="small_hidden"/>
            <Calendar id="bitis" showIcon dateFormat={'dd.mm.yy'} style={{marginTop: 5}}
                      value={selectedTimeEnd} showTime showButtonBar
                      onChange={(e) => setSelectedTimeEnd(e.value)}
                      placeholder="Tarih Bitiş"/>
          </div>
          <div className="p-col-12 p-md-2 p-fluid">Aksesuar Ürün Seri No
            <InputText style={{marginTop: 5}} value={serialNo} placeholder="Seri No"
                       onChange={(e) => setSerialNo(e.target.value)}/>
          </div>
          <div className="p-col-12 p-md-2 p-fluid">Aksesuar Ürün Barkod
            <InputText style={{marginTop: 5}} value={barcode} placeholder="Barkod"
                       onChange={(e) => setBarcode(e.target.value)}/>
          </div>
          <div className="p-col-12 p-md-2 p-fluid">İrsaliye No
            <InputText style={{marginTop: 5}} value={waybillNo} placeholder="İrsaliye No"
                       onChange={(e) => setWaybillNo(e.target.value)}/>
          </div>
          <div className="p-col-12">
            <ResponsiveDataTableWithoutRowClass
              rowClassName={saleRowClass}
              lazy
              header={header}
              value={saleInfoList}
              paginationConfig={paginationConfig}
              onActivePageChange={onActivePageChange}
              onFilter={onFilter}
              filters={searchCriteria.filters}
              onSort={onSort}
              sortField={searchCriteria.sortField}
              sortOrder={searchCriteria.sortOrder}
              loading={loading}
              style={{fontSize: '11px'}}
            >
              <Column field="invoiceDate" body={invoiceDateTemplate} header="Fatura Tarihi" filter={true}
                      filterElement={invoiceDateFilter}
                      filterFunction={filterDate}
                      sortable/>
              <Column field="waybillDate" body={waybillDateTemplate} header="İrsaliye Tarihi" filter={true}
                      filterElement={waybillDateFilter}
                      filterFunction={filterDate}
                      sortable/>
              <Column field="productSaleInfoType" body={productSaleInfoTypeDisplayTemplate} header="Dağıtım Tipi"
                      filter={true} filterElement={productSaleInfoTypeFilterElement} sortable/>
              <Column field="invoiceNo" body={invoiceNoTemplate} header="Fatura No" filter={true}
                      filterMatchMode={"contains"} sortable/>
              <Column field="distyCode" body={distyCodeTemplate} header="Distribütör Kodu" filter={true}
                      filterElement={distyCodeFilter} filterMatchMode={"contains"} sortable/>
              <Column field="resellerCode" body={resellerCodeTemplate} header="Bayi" filter={true}
                      headerStyle={{width: "20%"}} filterElement={resellerCodeFilter}
                      filterMatchMode={"contains"} sortable/>
              <Column field="vatIncludedPrice" body={vatIncludedPriceTemplate} header="KDV Dahil Fiyat"
                      headerStyle={{textAlign: 'right'}} sortable/>
              <Column body={accessoryCampaignSerialTitleButtonTemplate}
                      headerStyle={{width: "14rem", textAlign: "center"}}
                      bodyStyle={{textAlign: 'center', overflow: 'visible', justifyContent: 'center'}}/>
            </ResponsiveDataTableWithoutRowClass>
          </div>
        </div>
        <Dialog header="Fatura Detay" visible={islemDetailModal} modal style={{width: '1200px'}}
                onHide={islemDetailModalModalHide}>
          <div className="datatable-responsive-demo">
            <div className="p-grid">
              <div className="p-field p-col-12">
                <p className="marginParagraph"><b>Fatura Tarihi:</b> {selectedIslem?.invoiceDateString}</p>
                <p className="marginParagraph"><b>Fatura No:</b> {selectedIslem?.invoiceNo}</p>
                <p className="marginParagraph"><b>Distribütör Kodu:</b> {selectedIslem?.distyCode}</p>
                <p className="marginParagraph">
                  <b>Bayi:</b> {selectedIslem?.reseller?.code} - {selectedIslem?.reseller?.name}</p>
                {selectedIslem?.description &&
                <p className="marginParagraph"><b>Açıklama:</b> {selectedIslem?.description}</p>}
                <p className="marginParagraph"><b>KDV Tutarı:</b> {addCommas(selectedIslem?.vat)}</p>
                <p className="marginParagraph"><b>KDV Oranı:</b> {selectedIslem?.vatRatio}</p>
                <p className="marginParagraph"><b>Fiyat:</b> {addCommas(selectedIslem?.price)}</p>
                <p className="marginParagraph"><b>KDV Dahil Fiyat:</b> {addCommas(selectedIslem?.vatIncludedPrice)}</p>
                {selectedIslem?.discount != null &&
                <p className="marginParagraph"><b>İskonto Tutarı:</b> {addCommas(selectedIslem?.discount)}</p>}
                {selectedIslem?.discountRatio != null &&
                <p className="marginParagraph"><b>İskonto Oranı:</b> {selectedIslem?.discountRatio}</p>}
                {selectedIslem?.shipmentCompanyName &&
                <p className="marginParagraph"><b>Kargo Firması:</b> {selectedIslem?.shipmentCompanyName}</p>}
                {selectedIslem?.shipmentTrackingCode &&
                <p className="marginParagraph"><b>Kargo Takip Kodu:</b> {selectedIslem?.shipmentTrackingCode}</p>}
                {selectedIslem?.shipmentTrackingUrl &&
                <p className="marginParagraph"><b>Kargo Takip Adresi:</b> <a target="_blank"
                                                                             href={selectedIslem?.shipmentTrackingUrl}>{selectedIslem?.shipmentTrackingUrl}</a>
                </p>}
                {selectedIslem?.shipmentStatusDisplay &&
                <p className="marginParagraph"><b>Kargo Durumu:</b> {selectedIslem?.shipmentStatusDisplay}</p>}
                <ResponsiveDataTable value={selectedIslem?.productSaleInfoDetailList}
                                     header={islemHeader}
                                     style={{fontSize: '11px', marginTop: 10}}
                                     paginator rows={5} rowsPerPageOptions={[5, 10, 15]}
                                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                     currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} arası gösteriliyor">
                  <Column body={barcodeTemplate} header="Barkod"/>
                  <Column body={serialNoTemplate} header="Seri Numara"/>
                  <Column body={statusTemplate} header="Durum"/>
                  <Column body={descriptionTemplate} header="Açıklama"/>
                  <Column body={vatTemplate} header="KDV" headerStyle={{textAlign: 'right'}}/>
                  <Column body={vatRatioTemplate} header="KDV Oranı"/>
                  <Column body={priceTemplate} header="Fiyat" headerStyle={{textAlign: 'right'}}/>
                  <Column body={vatIncludedPriceTemplate} header="KDV Dahil Fiyat" headerStyle={{textAlign: 'right'}}/>
                  <Column body={discountTemplate} header="İskonto Tutarı" headerStyle={{textAlign: 'right'}}/>
                  <Column body={discountRatioTemplate} header="İskonto Oranı"/>
                </ResponsiveDataTable>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog header="İrsaliye Detay" visible={waybillDetailModal} modal style={{width: '1200px'}}
                onHide={waybillModalHide}>
          <div className="datatable-responsive-demo">
            <div className="p-grid">
              <div className="p-field p-col-12">
                <p className="marginParagraph"><b>İrsaliye Tarihi:</b> {selectedWaybill?.waybillDateString}</p>
                <p className="marginParagraph"><b>İrsaliye No:</b> {selectedWaybill?.waybillNo}</p>
                <p className="marginParagraph"><b>Distribütör Kodu:</b> {selectedWaybill?.distyCode}</p>
                <p className="marginParagraph">
                  <b>Bayi:</b> {selectedWaybill?.reseller?.code} - {selectedWaybill?.reseller?.name}</p>
                {selectedWaybill?.description &&
                <p className="marginParagraph"><b>Açıklama:</b> {selectedWaybill?.description}</p>}
                {selectedWaybill?.vat &&
                <p className="marginParagraph"><b>KDV Tutarı:</b> {addCommas(selectedWaybill?.vat)}</p>}
                {selectedWaybill?.vatRatio &&
                <p className="marginParagraph"><b>KDV Oranı:</b> {selectedWaybill?.vatRatio}</p>}
                {selectedWaybill?.price &&
                <p className="marginParagraph"><b>Fiyat:</b> {addCommas(selectedWaybill?.price)}</p>}
                {selectedWaybill?.vatIncludedPrice &&
                <p className="marginParagraph"><b>KDV Dahil Fiyat:</b> {addCommas(selectedWaybill?.vatIncludedPrice)}
                </p>}
                {selectedWaybill?.discount != null &&
                <p className="marginParagraph"><b>İskonto Tutarı:</b> {addCommas(selectedWaybill?.discount)}</p>}
                {selectedWaybill?.discountRatio != null &&
                <p className="marginParagraph"><b>İskonto Oranı:</b> {selectedWaybill?.discountRatio}</p>}
                {selectedWaybill?.shipmentCompanyName &&
                <p className="marginParagraph"><b>Kargo Firması:</b> {selectedWaybill?.shipmentCompanyName}</p>}
                {selectedWaybill?.shipmentTrackingCode &&
                <p className="marginParagraph"><b>Kargo Takip Kodu:</b> {selectedWaybill?.shipmentTrackingCode}</p>}
                {selectedWaybill?.shipmentTrackingUrl &&
                <p className="marginParagraph"><b>Kargo Takip Adresi:</b> <a target="_blank"
                                                                             href={selectedWaybill?.shipmentTrackingUrl}>{selectedWaybill?.shipmentTrackingUrl}</a>
                </p>}
                {selectedWaybill?.shipmentStatusDisplay &&
                <p className="marginParagraph"><b>Kargo Durumu:</b> {selectedWaybill?.shipmentStatusDisplay}</p>}
                <ResponsiveDataTable value={selectedWaybill?.productSaleInfoDetailList}
                                     header={waybillHeader}
                                     style={{fontSize: '11px', marginTop: 10}}
                                     paginator rows={5} rowsPerPageOptions={[5, 10, 15]}
                                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                     currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} arası gösteriliyor">
                  <Column body={barcodeTemplate} header="Barkod"/>
                  <Column body={serialNoTemplate} header="Seri Numara"/>
                  <Column body={statusTemplate} header="Durum"/>
                  <Column body={descriptionTemplate} header="Açıklama"/>
                  <Column body={vatTemplate} header="KDV" headerStyle={{textAlign: 'right'}}/>
                  <Column body={vatRatioTemplate} header="KDV Oranı"/>
                  <Column body={priceTemplate} header="Fiyat" headerStyle={{textAlign: 'right'}}/>
                  <Column body={vatIncludedPriceTemplate} header="KDV Dahil Fiyat" headerStyle={{textAlign: 'right'}}/>
                  <Column body={discountTemplate} header="İskonto Tutarı" headerStyle={{textAlign: 'right'}}/>
                  <Column body={discountRatioTemplate} header="İskonto Oranı"/>
                </ResponsiveDataTable>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog header="İrsaliyeler Detay" visible={waybillDetailListModal} modal style={{width: '1200px'}}
                onHide={waybillModalHideList}>
          <div className="datatable-responsive-demo">
            <div className="p-grid">
              {selectedWaybillList && selectedWaybillList.map(function (selectedWaybill) {
                return (
                  <div className="p-field p-col-12">
                    <p className="marginParagraph"><b>İrsaliye Tarihi:</b> {selectedWaybill?.waybillDateString}</p>
                    <p className="marginParagraph"><b>İrsaliye No:</b> {selectedWaybill?.waybillNo}</p>
                    <p className="marginParagraph"><b>Distribütör Kodu:</b> {selectedWaybill?.distyCode}</p>
                    <p className="marginParagraph">
                      <b>Bayi:</b> {selectedWaybill?.reseller?.code} - {selectedWaybill?.reseller?.name}</p>
                    {selectedWaybill?.description &&
                    <p className="marginParagraph"><b>Açıklama:</b> {selectedWaybill?.description}</p>}
                    {selectedWaybill?.vat &&
                    <p className="marginParagraph"><b>KDV Tutarı:</b> {addCommas(selectedWaybill?.vat)}</p>}
                    {selectedWaybill?.vatRatio &&
                    <p className="marginParagraph"><b>KDV Oranı:</b> {selectedWaybill?.vatRatio}</p>}
                    {selectedWaybill?.price &&
                    <p className="marginParagraph"><b>Fiyat:</b> {addCommas(selectedWaybill?.price)}</p>}
                    {selectedWaybill?.vatIncludedPrice &&
                    <p className="marginParagraph"><b>KDV Dahil
                      Fiyat:</b> {addCommas(selectedWaybill?.vatIncludedPrice)}
                    </p>}
                    {selectedWaybill?.discount != null &&
                    <p className="marginParagraph"><b>İskonto Tutarı:</b> {addCommas(selectedWaybill?.discount)}</p>}
                    {selectedWaybill?.discountRatio != null &&
                    <p className="marginParagraph"><b>İskonto Oranı:</b> {selectedWaybill?.discountRatio}</p>}
                    {selectedWaybill?.shipmentCompanyName &&
                    <p className="marginParagraph"><b>Kargo Firması:</b> {selectedWaybill?.shipmentCompanyName}</p>}
                    {selectedWaybill?.shipmentTrackingCode &&
                    <p className="marginParagraph"><b>Kargo Takip Kodu:</b> {selectedWaybill?.shipmentTrackingCode}</p>}
                    {selectedWaybill?.shipmentTrackingUrl &&
                    <p className="marginParagraph"><b>Kargo Takip Adresi:</b> <a target="_blank"
                                                                                 href={selectedWaybill?.shipmentTrackingUrl}>{selectedWaybill?.shipmentTrackingUrl}</a>
                    </p>}
                    {selectedWaybill?.shipmentStatusDisplay &&
                    <p className="marginParagraph"><b>Kargo Durumu:</b> {selectedWaybill?.shipmentStatusDisplay}</p>}
                    <ResponsiveDataTable value={selectedWaybill?.productSaleInfoDetailList}
                                         header="Aksesuar Bigileri"
                                         style={{fontSize: '11px', marginTop: 10}}
                                         paginator rows={5} rowsPerPageOptions={[5, 10, 15]}
                                         paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                         currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} arası gösteriliyor">
                      <Column body={barcodeTemplate} header="Barkod"/>
                      <Column body={serialNoTemplate} header="Seri Numara"/>
                      <Column body={statusTemplate} header="Durum"/>
                      <Column body={descriptionTemplate} header="Açıklama"/>
                      <Column body={vatTemplate} header="KDV" headerStyle={{textAlign: 'right'}}/>
                      <Column body={vatRatioTemplate} header="KDV Oranı"/>
                      <Column body={priceTemplate} header="Fiyat" headerStyle={{textAlign: 'right'}}/>
                      <Column body={vatIncludedPriceTemplate} header="KDV Dahil Fiyat"
                              headerStyle={{textAlign: 'right'}}/>
                      <Column body={discountTemplate} header="İskonto Tutarı" headerStyle={{textAlign: 'right'}}/>
                      <Column body={discountRatioTemplate} header="İskonto Oranı"/>
                    </ResponsiveDataTable>
                    <hr style={{borderTop: '3px solid black'}}/>
                  </div>)
              })}
            </div>
          </div>
        </Dialog>
        <Dialog header="Teknik Servis Değişimi Detay" visible={technicalDetailModal} modal style={{width: '1000px'}}
                onHide={technicalDetailModalModalHide}>
          <div className="datatable-responsive-demo">
            <div className="p-grid">
              <div className="p-field p-col-12">
                <p className="marginParagraph"><b>Fatura Tarihi:</b> {selectedIslem?.invoiceDateString}</p>
                <p className="marginParagraph"><b>Tedarikçi Adı:</b> {selectedIslem?.supply?.name}</p>
                <p className="marginParagraph">
                  <b>Bayi:</b> {selectedIslem?.reseller?.code} - {selectedIslem?.reseller?.name}</p>
                <p className="marginParagraph"><b>Aksesuar
                  Barkod:</b> {selectedIslem?.productSaleInfoDetailList[0].barcode}</p>
                <p className="marginParagraph"><b>Aksesuar Seri
                  Numara:</b> {selectedIslem?.productSaleInfoDetailList[0].serialNo}</p>
                <p className="marginParagraph"><b>Aksesuar
                  İsmi:</b> {selectedIslem?.productSaleInfoDetailList[0].productSerialNo?.model?.name}</p>
                <p className="marginParagraph"><b>Aksesuar
                  Marka:</b> {selectedIslem?.productSaleInfoDetailList[0].productSerialNo?.model?.brand?.name}</p>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  )

};

export default SaleInfoList;
