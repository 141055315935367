import AspAxios from "../../../service/AspAxios"
import * as Paths from "../../../service/config"
import axios from "axios";


  export function updateSupply(supply){
    return new Promise((resolve, reject) => {
      axios.post(Paths.updateSupply, supply).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))
    })
  }