import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import FaultyProductManagementService from "./httpService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Calendar} from "primereact/calendar";
import moment from "moment";

const FaultyProductSearch = () => {

  const supplyId = JSON.parse(localStorage.getItem('user')).user?.supply?.id;
  const toast = useRef(null);
  const faultyProductManagementService = new FaultyProductManagementService();

  const [loading, setLoading] = useState(false);
  const [selectedTimeBegin, setSelectedTimeBegin] = useState(null);
  const [selectedTimeEnd, setSelectedTimeEnd] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [searchCriteriaLazyLoading, setSearchCriteriaLazyLoading] = useState(false);
  const [searchCriteriaProgressing, setSearchCriteriaProgressing] = useState(false);
  const [saleInfoList, setSaleInfoList] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState({});
  const [exportExcelDisabled, setExportExcelDisabled] = useState(null);

  const [detailModal, setDetailModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [selectedFaultyProductInfoStatus, setSelectedFaultyProductInfoStatus] = useState(null);

  const [selectedTicketId, setSelectedTicketId] = useState(null);

  const [approveTicketModal, setApproveTicketModal] = useState(false);
  const [approveTicketSerialNo, setApproveTicketSerialNo] = useState(false);
  const [approveTicketCargoName, setApproveTicketCargoName] = useState(false);
  const [approveTicketCargoCode, setApproveTicketCargoCode] = useState(false);
  const [approveTicketButtonDisabled, setApproveTicketButtonDisabled] = useState(false);

  const [rejectTicketModal, setRejectTicketModal] = useState(false);
  const [rejectTicketDescription, setRejectTicketDescription] = useState(null);
  const [rejectTicketButtonDisabled, setRejectTicketButtonDisabled] = useState(false);

  const [barcodeScannerVisible, setBarcodeScannerVisible] = useState(false);

  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  const [faultyProductInfoStatusOptions, setFaultyProductInfoStatusOptions] = useState(
    [
      {value: "WAITING", name: "Bekliyor"},
      {value: "APPROVED", name: "Onaylandı"},
      {value: "REJECTED", name: "Reddedildi"}
    ]
  );

  useEffect(() => {
    searchTicket({...paginationConfig, activePage: 0});
  }, []);

  useEffect(() => {
    setSearchCriteriaLazyLoading(true);
  }, [selectedTimeBegin, selectedTimeEnd, selectedCategory, selectedSubCategory, searchCriteria, selectedFaultyProductInfoStatus]);

  useEffect(() => {
    if (searchCriteriaLazyLoading) {
      setTimeout(() => {
        setSearchCriteriaProgressing(true);
        setSearchCriteriaLazyLoading(false);
      }, 2000);
    }
  }, [searchCriteriaLazyLoading]);

  useEffect(() => {
    if (searchCriteriaProgressing) {
      searchTicket({...paginationConfig, activePage: 0});
      setSearchCriteriaProgressing(false);
    }
  }, [searchCriteriaProgressing]);

  const searchTicket = (pageState) => {
    setLoading(true);
    var sort = "";
    if (searchCriteria.sortField != null && searchCriteria.sortOrder != null) {
      sort += searchCriteria.sortField;
      if (searchCriteria.sortOrder == 1) {
        sort += ",asc";
      } else {
        sort += ",desc";
      }
    } else {
      sort += "createDateTime,desc";
    }

    if (selectedTimeBegin != null) {
      searchCriteria.saleDateStart = selectedTimeBegin.toLocaleDateString("tr-TR") + " " + selectedTimeBegin.toLocaleTimeString("tr-TR");
    } else {
      searchCriteria.saleDateStart = null;
    }

    if (selectedTimeEnd != null) {
      searchCriteria.saleDateEnd = selectedTimeEnd.toLocaleDateString("tr-TR") + " " + selectedTimeEnd.toLocaleTimeString("tr-TR");
    } else {
      searchCriteria.saleDateEnd = null;
    }

    searchCriteria.category = selectedCategory;
    searchCriteria.subCategory = selectedSubCategory;
    searchCriteria.supplyId = supplyId;
    searchCriteria.faultyProductInfoStatus = selectedFaultyProductInfoStatus;

    faultyProductManagementService.searchTicket(searchCriteria, pageState.itemsPerPage, pageState.activePage, sort).then(res => {
      console.log("searchSaleInfo", res.content);
      if (res.content && res.content.length > 0) {
        setSaleInfoList(res.content);

        pageState.totalRecords = res.totalElements;
        pageState.first = pageState.itemsPerPage * pageState.activePage;
        setPaginationConfig({
          ...pageState,
        });
      } else {
        setSaleInfoList([]);
      }
    }).catch(err => {
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'İşlem bilgisi getirme başarısız.'
      });
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  };

  const exportExcel = () => {
    setExportExcelDisabled(true);

    if (selectedTimeBegin != null) {
      searchCriteria.saleDateStart = selectedTimeBegin.toLocaleDateString("tr-TR") + " " + selectedTimeBegin.toLocaleTimeString("tr-TR");
    } else {
      searchCriteria.saleDateStart = null;
    }

    if (selectedTimeEnd != null) {
      searchCriteria.saleDateEnd = selectedTimeEnd.toLocaleDateString("tr-TR") + " " + selectedTimeEnd.toLocaleTimeString("tr-TR");
    } else {
      searchCriteria.saleDateEnd = null;
    }

    searchCriteria.category = selectedCategory;
    searchCriteria.subCategory = selectedSubCategory;
    searchCriteria.supplyId = supplyId;
    searchCriteria.faultyProductInfoStatus = selectedFaultyProductInfoStatus;

    faultyProductManagementService.searchTicketEager(searchCriteria).then(res => {
      console.log("searchSaleInfoEager", res);
      import('xlsx').then(xlsx => {
        var excelList = [];
        res.forEach(a => {
          console.log(a)
          excelList.push({
            "Bildirim Tarihi": a.createDateTimeString,
            "Durum": a.faultyProductInfoStatusDisplay,
            "Bayi Adı": a.reseller?.name,
            "Bayi Kodu": a.reseller?.code,
            "Bayi Eposta": a.reseller?.email,
            "Bayi Tel": a.reseller?.phoneNumber,
            "Bayi Ana Bölge": a.reseller?.mainArea,
            "Bayi Bölge": a.reseller?.area,
            "Bayi İl": a.reseller?.resellerProvince?.name,
            "Bayi İlçe": a.reseller?.resellerDistrict?.name,
            "Bayi Adres": a.reseller?.addressText,
            "Ürün Seri Numara": a.productSerialNo?.serialNo,
            "Ürün Marka": a.model?.brand?.name,
            "Ürün Model": a.model?.name,
            "Ürün Barkod": a.model?.barcode,
            "Ürün Kategori": a.model?.subCategory?.category?.name,
            "Ürün Alt Kategori": a.model?.subCategory?.name,
            "Satış Tarihi": a?.saleDateString ? a?.saleDateString:a?.productSerialNo?.productSale?.completedDateTime ? moment(a?.productSerialNo?.productSale?.completedDateTime).format('DD.MM.YYYY'):"-",
            "Arıza Açıklama": a.description,
            "Bildirimin Sonuçlanma Tarihi": a.completedDateString,
            "Değişimin Reddedilmesinin Nedeni": a.rejectDescription,
            "Yeni Ürünün Seri Numarası": a?.productSaleInfo ? a.productSaleInfo.productSaleInfoDetailList[0].serialNo : "",
            "Kargo Firmasının Adı": a.cargoName,
            "Kargo Takip Numarası": a.cargoCode,
          })
        });
        const worksheet = xlsx.utils.json_to_sheet(excelList);
        var wscols = [
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
          {width: 18},
        ];
        worksheet["!cols"] = wscols;
        const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
        saveAsExcelFile(excelBuffer, 'Arızalı Ürün Bildirimlerii');
      });
    }).catch(err => {
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Dağıtım bilgisi getirme başarısız.'
      });
      console.log(err);
    }).finally(() => {
      setExportExcelDisabled(false);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    searchTicket(pageState);
  };

  const onFilter = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const onSort = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const detailModalShow = (e) => {
    setDetailModal(true);
    saleInfoList.forEach(s => {
      if (s.id == e.currentTarget.id) {
        setSelectedProduct(s);
      }
    });
  };

  const detaillModalHide = () => {
    setDetailModal(false);
  };

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const createDateTimeStringTemplate = (data) => {
    return responsiveColumn("Bildirim Tarihi", data?.createDateTimeString)
  };

  const faultyProductInfoStatusDisplayTemplate = (data) => {
    return responsiveColumn("Durum", data?.faultyProductInfoStatusDisplay)
  };

  const brandTemplate = (data) => {
    return responsiveColumn("Marka", data.model?.brand?.name)
  };

  const serialNoTemplate = (data) => {
    return responsiveColumn("Seri Numara", data?.productSerialNo?.serialNo)
  };

  const barcodeTemplate = (data) => {
    return responsiveColumn("Barkod", data?.model?.barcode)
  };

  const resellerCodeTemplate = (data) => {
    return responsiveColumn("Bayi", data?.reseller?.code + " - " + data?.reseller?.name)
  };

  const accessoryCampaignSerialTitleButtonTemplate = (data) => {
    return (
      <div className="p-d-flex p-justify-even">
        <Button id={data.id} type="button" className="p-button-sm p-button-info" label="Detay"
                onClick={detailModalShow}/>
      </div>
    );
  };

  const renderSaleStatusFilter = () => {
    return (
      <Dropdown value={selectedFaultyProductInfoStatus} options={faultyProductInfoStatusOptions}
                onChange={(e) => setSelectedFaultyProductInfoStatus(e.value)}
                optionLabel="name" optionValue="value" showClear className="p-column-filter"/>
    );
  };

  const saleStatusFilterElement = renderSaleStatusFilter();

  const header =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>Arızalı Ürün Bildirimleri</div>
        <div>
          <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
                  disabled={exportExcelDisabled}
                  onClick={exportExcel} style={{marginRight: 5}}/>
        </div>
      </div>
    </div>;

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-12 p-md-2 p-fluid">
            <label htmlFor="baslangic">Tarihi Aralığı</label>
            <Calendar id="baslangic" showIcon dateFormat={'dd.mm.yy'} style={{marginTop: 5}}
                      value={selectedTimeBegin} showTime showButtonBar
                      onChange={(e) => setSelectedTimeBegin(e.value)}
                      placeholder="Tarih Başlangıç"/>
          </div>
          <div className="p-col-12 p-md-2 p-fluid"><br className="small_hidden"/>
            <Calendar id="bitis" showIcon dateFormat={'dd.mm.yy'} style={{marginTop: 5}}
                      value={selectedTimeEnd} showTime showButtonBar
                      onChange={(e) => setSelectedTimeEnd(e.value)}
                      placeholder="Tarih Bitiş"/>
          </div>
          <div className="p-col-12 p-md-2 p-fluid">Ürün Kategori
            <InputText style={{marginTop: 5}} value={selectedCategory} placeholder="Kategori"
                       onChange={(e) => setSelectedCategory(e.target.value)}/>
          </div>
          <div className="p-col-12 p-md-2 p-fluid">Ürün Alt Kategori
            <InputText style={{marginTop: 5}} value={selectedSubCategory} placeholder="Alt Kategori"
                       onChange={(e) => setSelectedSubCategory(e.target.value)}/>
          </div>
          <div className="p-col-12">
            <ResponsiveDataTable
              lazy
              header={header}
              value={saleInfoList}
              paginationConfig={paginationConfig}
              onActivePageChange={onActivePageChange}
              onFilter={onFilter}
              filters={searchCriteria.filters}
              onSort={onSort}
              sortField={searchCriteria.sortField}
              sortOrder={searchCriteria.sortOrder}
              loading={loading}
              emptyMessage="Arızalı ürün bildirimi bulunamadı"
              style={{fontSize: '12px'}}
            >
              <Column field="createDateTime" body={createDateTimeStringTemplate} header="Bildirim Tarihi" sortable/>
              <Column field="faultyProductInfoStatusDisplay" body={faultyProductInfoStatusDisplayTemplate}
                      header="Durum" filter={true}
                      filterElement={saleStatusFilterElement}/>
              <Column field="brand" body={brandTemplate} header="Marka" filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="serialNo" body={serialNoTemplate} header="Seri Numara" filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="barcode" body={barcodeTemplate} header="Barkod" filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="resellerCode" body={resellerCodeTemplate} header="Bayi" filter={true}
                      filterMatchMode={"contains"} headerStyle={{width: "25%"}}/>
              <Column body={accessoryCampaignSerialTitleButtonTemplate}
                      headerStyle={{width: "8rem", textAlign: "center"}}
                      bodyStyle={{textAlign: 'center', overflow: 'visible', justifyContent: 'center'}}/>
            </ResponsiveDataTable>
          </div>
        </div>
        <Dialog header="Arızalı Ürün Detay" visible={detailModal} modal style={{width: '1000px'}}
                onHide={detaillModalHide}>
          <div className="datatable-responsive-demo">
            <div className="p-grid">
              <div className="p-field p-col-12 p-md-6">
                <p className="marginParagraph"><b>Bildirim Tarihi:</b> {selectedProduct?.createDateTimeString}</p>
                <p className="marginParagraph"><b>Durum:</b> {selectedProduct?.faultyProductInfoStatusDisplay}</p>
                <p className="marginParagraph"><b>Bayi Adı:</b> {selectedProduct?.reseller?.name}</p>
                <p className="marginParagraph"><b>Bayi Kodu:</b> {selectedProduct?.reseller?.code}</p>
                <p className="marginParagraph"><b>Bayi Eposta:</b> {selectedProduct.reseller?.email}</p>
                <p className="marginParagraph"><b>Bayi Tel:</b> {selectedProduct.reseller?.phoneNumber}</p>
                <p className="marginParagraph"><b>Bayi Ana Bölge:</b> {selectedProduct.reseller?.mainArea}</p>
                <p className="marginParagraph"><b>Bayi Bölge:</b> {selectedProduct.reseller?.area}</p>
                <p className="marginParagraph"><b>Bayi İl:</b> {selectedProduct.reseller?.resellerProvince?.name}</p>
                <p className="marginParagraph"><b>Bayi İlçe:</b> {selectedProduct.reseller?.resellerDistrict?.name}</p>
                <p className="marginParagraph"><b>Bayi Adres:</b> {selectedProduct.reseller?.addressText}</p>
              </div>
              <div className="p-field p-col-12 p-md-6">
                <p className="marginParagraph"><b>Ürün Seri
                  Numara:</b> {selectedProduct?.productSerialNo?.serialNo != null ? selectedProduct?.productSerialNo?.serialNo : "Yok"}
                </p>
                <p className="marginParagraph"><b>Ürün Marka:</b> {selectedProduct.model?.brand?.name}</p>
                <p className="marginParagraph"><b>Ürün Model:</b> {selectedProduct.model?.name}</p>
                <p className="marginParagraph"><b>Ürün Barkod:</b> {selectedProduct.model?.barcode}</p>
                <p className="marginParagraph"><b>Ürün Kategori:</b> {selectedProduct.model?.subCategory?.category?.name}</p>
                <p className="marginParagraph"><b>Ürün Alt Kategori:</b> {selectedProduct.model?.subCategory?.name}</p>
                <hr/>
                {selectedProduct?.saleDateString &&
                <p className="marginParagraph"><b>Satış Tarihi:</b> {selectedProduct?.saleDateString}
                </p>}
                <p className="marginParagraph"><b>Arıza
                  Açıklama:</b> {selectedProduct.description != null ? selectedProduct.description : "Yok"}</p>

                <p className="marginParagraph"><b>Ürünün Fotoğrafları</b></p>
                {selectedProduct && selectedProduct.photos.map((p, i) => {
                  return (<div><a href={p.publicAddress} target="_blank">Fotoğraf {i + 1}</a></div>)
                })}
                {selectedProduct?.faultyProductInfoStatus != "WAITING" && <hr/>}
                {selectedProduct?.completedDateString && <p className="marginParagraph"><b>Bildirimin sonuçlanma
                  tarihi:</b> {selectedProduct.completedDateString}</p>}
                {selectedProduct?.rejectDescription && <p className="marginParagraph"><b>Değişimin reddedilmesinin
                  nedeni:</b> {selectedProduct.rejectDescription}</p>}
                {selectedProduct?.productSaleInfo &&
                <p className="marginParagraph"><b>Yeni ürünün seri
                  numarası:</b> {selectedProduct.productSaleInfo.productSaleInfoDetailList[0].serialNo}
                </p>}
                {selectedProduct?.cargoName &&
                <p className="marginParagraph"><b>Kargo firmasının adı:</b> {selectedProduct.cargoName}
                </p>}
                {selectedProduct?.cargoCode &&
                <p className="marginParagraph"><b>Kargo takip numarası:</b> {selectedProduct.cargoCode}
                </p>}
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  )

};

export default FaultyProductSearch;
