import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import {handleError} from "../../service/HandleErrorService";
import VoucherService from "./httpService";
import {Button} from "primereact/button";
import {useHistory} from "react-router";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputNumber} from "primereact/inputnumber";
import {Checkbox} from "primereact/checkbox";
import {ProgressSpinner} from "primereact/progressspinner";

const AccessoryCampaignPassTitle = () => {

  const history = useHistory();
  const toast = useRef(null);
  const voucherService = new VoucherService();

  const [pageHeader, setPageHeader] = useState(null);
  const [ttCrmList, setTtCrmList] = useState(null);
  const [accessoryCampaignList, setAccessoryCampaignList] = useState(null);
  const [passOptions, setPassOptions] = useState(null);
  const [totalPassNumber, setTotalPassNumber] = useState(0);
  const [createPassButtonDisabled, setCreatePassButtonDisabled] = useState(false);
  const [accessoryCampaignPassTitleId, setAccessoryCampaignPassTitleId] = useState(null);
  const [accessoryCampaignPassTitle, setAccessoryCampaignPassTitle] = useState(null);
  const [accessoryCampaignPassTitleLoading, setAccessoryCampaignPassTitleLoading] = useState(false);
  const [activatePassButtonDisabled, setActivatePassButtonDisabled] = useState(false);
  const [createLoading, setCreateLoading] = useState(false)
  const [originalRows, setoriginalRows] = useState({});
  const dt = useRef(null);

  useEffect(() => {
    var localStorageId = JSON.parse(window.atob(localStorage.getItem("accessoryCampaignPassTitleId")));
    setAccessoryCampaignPassTitleId(localStorageId);
  }, []);

  useEffect(() => {
    if (accessoryCampaignPassTitleId != null) {
      setPageHeader("Şifre İşlemleri");
      getAccessoryCampaignPassTitleDetail(accessoryCampaignPassTitleId);
    } else {
      setPageHeader("Şifre Oluştur");
      getTtCrmList();
      getAllAccessoryCampaignList();
    }
  }, [accessoryCampaignPassTitleId]);

  useEffect(() => {
    if (ttCrmList != null && accessoryCampaignList != null && accessoryCampaignPassTitle == null) {
      var options = [];
      ttCrmList.forEach(t => {
        accessoryCampaignList.forEach(a => {
          options.push({
            ttCrmId: t.id,
            ttCrmName: t.name,
            accessoryCampaignId: a.id,
            amount: a.amount,
            termNumber: a.termNumber,
            unit: 0
          })
        })
      });
      setPassOptions(options);
    }
  }, [ttCrmList, accessoryCampaignList]);

  useEffect(() => {
    if (accessoryCampaignPassTitle != null) {
      setPassOptions(JSON.parse(accessoryCampaignPassTitle.params));
      setTotalPassNumber(accessoryCampaignPassTitle.totalPassNumberCount);
    }
  }, [accessoryCampaignPassTitle]);

  const getTtCrmList = () => {
    voucherService.getAllTtCrmList().then(res => {
      console.log("getAllTtCrmList", res);
      setTtCrmList(res);
    }).catch(err => handleError(err, toast))
  };

  const getAllAccessoryCampaignList = () => {
    voucherService.accessoryCampaignGetAllWithPassive().then(res => {
      console.log("accessoryCampaignGetAllWithPassive", res);
      res.forEach(r => {
        r.unit = 0;
      });
      setAccessoryCampaignList(res);
    }).catch(err => handleError(err, toast))
  };

  const getAccessoryCampaignPassTitleDetail = (id) => {
    setAccessoryCampaignPassTitleLoading(true);
    voucherService.getPassTitleById(id).then(res => {
      console.log("getPassTitleById", res);
      setAccessoryCampaignPassTitle(res);
    }).catch(err => handleError(err, toast)).finally(() => {
      setAccessoryCampaignPassTitleLoading(false);
    });
  };

  const createPass = () => {
    setCreateLoading(true);
    setCreatePassButtonDisabled(true);
    if (totalPassNumber > 0) {
      const request = {
        accessoryCampaignParams: JSON.stringify(passOptions),
        accessoryCampaignNumbers: passOptions
      };
      voucherService.createPass(request).then(res => {
        console.log("createPass", res);
        toast?.current?.show({
          severity: 'success',
          summary: 'Başarılı İşlem',
          detail: 'Şifreler oluşturuldu.'
        });
        setCreateLoading(false);
        setAccessoryCampaignPassTitleId(res.id);
        localStorage.setItem("accessoryCampaignPassTitleId", window.btoa(res.id));
      }).catch(err => {
        console.log(err);
        toast?.current?.show({
          severity: 'error',
          summary: 'Hata',
          detail: err.response.data.message,
          life: 4000
        });
        setCreateLoading(false);
        setCreatePassButtonDisabled(false);
      });
    } else {
      toast?.current?.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Oluşturulcak şifre adedi sıfırdan büyük olmalıdır.'
      });
      setCreateLoading(false);
      setCreatePassButtonDisabled(false);
    }
  };

  const activatePass = () => {
    setActivatePassButtonDisabled(true);
    const request = {
      id: accessoryCampaignPassTitleId
    };
    voucherService.passTitleActivate(request).then(res => {
      console.log("passTitleActivate", res);
      toast?.current?.show({
        severity: 'success',
        summary: 'Başarılı İşlem',
        detail: 'Şifreler aktifleştirildi.'
      });
      setTimeout(function () {
        goBack();
      }, 2000);
    }).catch(err => {
      console.log(err);
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Şifreler aktifleştirilmedi.'
      });
      setActivatePassButtonDisabled(false);
    })
  };

  const cancelPass = () => {
    setActivatePassButtonDisabled(true);
    const request = {
      id: accessoryCampaignPassTitleId
    };
    voucherService.passTitleDelete(request).then(res => {
      console.log("passTitleDelete", res);
      toast?.current?.show({
        severity: 'success',
        summary: 'Başarılı İşlem',
        detail: 'Şifreler iptal edildi.'
      });
      setTimeout(function () {
        goBack();
      }, 2000);
    }).catch(err => {
      console.log(err);
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Şifreler iptal edilemedi.'
      });
      setActivatePassButtonDisabled(false);
    })
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const exportXLSX = () => {
    import('xlsx').then(xlsx => {
      var excelList = [];
      accessoryCampaignPassTitle.accessoryCampaignPassList.forEach(a => {
        excelList.push({
          "TT CRM": a.ttCrm.name,
          "Kampanya Tutarı": a.accessoryCampaign.amount,
          "Taksit Sayısı": a.accessoryCampaign.termNumber,
          "Şifre": a.passValue
        })
      });
      const worksheet = xlsx.utils.json_to_sheet(excelList);
      var wscols = [
        {width: 15},
        {width: 15},
        {width: 11},
        {width: 15}
      ];
      worksheet["!cols"] = wscols;
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      saveAsExcelFile(excelBuffer, 'Şifreler');
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const goBack = () => {
    history.push("/accessoryCampaignPassTitleList");
  };

  const onEditorValueChange = (productKey, props, value) => {
    let updatedProducts = [...props.value];
    updatedProducts[props.rowIndex][props.field] = value;
    var unit = 0;
    passOptions.forEach(p => {
      unit += p.unit;
    });
    setTotalPassNumber(unit);
  };

  const unitEditor = (productKey, props) => {
    return <InputNumber value={props.rowData['unit']} min={0}
                        onValueChange={(e) => onEditorValueChange(productKey, props, e.value)}/>
  };

  const onRowEditInit = (event) => {
    originalRows[event.index] = {...passOptions[event.index]};
  };

  const onRowEditCancel = (event) => {
    let products = [...passOptions];
    products[event.index] = originalRows[event.index];
    delete originalRows[event.index];

    setPassOptions(products);
    var unit = 0;
    products.forEach(a => {
      unit += a.unit;
    });
    setTotalPassNumber(unit);
  };

  const addCommas = num => num != null ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;

  const unitTemplate = (data) => {
    return (
      <>
        {addCommas(data.unit)}
      </>
    );
  };

  const header =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>Oluşturulan Şifreler</div>
        <div>
          <Button type="button" icon="pi pi-external-link" label="CSV Aktar"
                  className="p-button p-button-secondary p-button-sm"
                  onClick={exportCSV} style={{marginRight: 5}}/>
          <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
                  onClick={exportXLSX}/>
        </div>
      </div>
    </div>;

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-12">
            <Button className="p-button-secondary" icon="pi pi-arrow-left" label="Geri Dön" onClick={goBack}/>
            <h5>{pageHeader}</h5>
          </div>
          {accessoryCampaignPassTitleLoading ?
            <div className="p-col-12 p-md-5" style={{alignSelf: 'center', width: 400}}>
              <div style={{display: 'flex', alignItems: 'center'}}><ProgressSpinner
                style={{width: '40px', height: '40px', marginRight: 5, marginLeft: 10}}
                strokeWidth="8" fill="#EEEEEE"
                animationDuration=".5s"/><b>Yükleniyor. Lütfen bekleyiniz.</b>
              </div>
            </div> :
            <div className="p-col-12 p-md-5">
              {accessoryCampaignPassTitleId == null ?
                <DataTable className="p-fluid" value={passOptions} editMode="row"
                           header="Şifre Adetleri" onRowEditInit={onRowEditInit} onRowEditCancel={onRowEditCancel}
                           paginator rows={15} rowsPerPageOptions={[5, 15, 50]}
                           paginatorTemplate="FirstPageLink PageLinks LastPageLink RowsPerPageDropdown"
                           emptyMessage="Katıt bulunamadı">
                  <Column field="ttCrmName" header="TT CRM" filter={true} filterMatchMode={"contains"}/>
                  <Column field="amount" header="Kampanya Tutarı" style={{textAlign: 'right'}}/>
                  <Column field="termNumber" header="Taksit Sayısı" style={{textAlign: 'right'}}/>
                  <Column field="unit" header="Adet" editor={(props) => unitEditor('accessoryCampaignList', props)}
                          style={{textAlign: 'right'}} body={unitTemplate}/>
                  <Column rowEditor headerStyle={{width: '7rem'}} bodyStyle={{textAlign: 'center'}}/>
                </DataTable> :
                <DataTable className="p-fluid" value={passOptions}
                           header="Şifre Adetleri"
                           paginator rows={15} rowsPerPageOptions={[5, 15, 50]}
                           paginatorTemplate="FirstPageLink PageLinks LastPageLink RowsPerPageDropdown"
                           emptyMessage="Katıt bulunamadı">
                  <Column field="ttCrmName" header="TT CRM" filter={true} filterMatchMode={"contains"} headerStyle={{width: "25%"}}/>
                  <Column field="amount" header="Kampanya Tutarı" style={{textAlign: 'right'}}/>
                  <Column field="termNumber" header="Taksit Sayısı" style={{textAlign: 'right'}}/>
                  <Column field="unit" header="Adet" style={{textAlign: 'right'}} body={unitTemplate}/>
                </DataTable>
              }
              <br/>
              <p><b>Toplam Şifre Adedi:</b> {addCommas(totalPassNumber)}</p>
              <Button icon="pi pi-clone" label="Oluştur" onClick={createPass}
                      disabled={accessoryCampaignPassTitleId != null || createPassButtonDisabled}/>
            </div>}
          {(createLoading || accessoryCampaignPassTitle?.titleStatus === "CONTINUING") &&
          <div className="p-col-12 p-md-7" style={{alignSelf: 'center', width: 400}}>
            <div style={{display: 'flex', alignItems: 'center'}}><ProgressSpinner
              style={{width: '40px', height: '40px', marginRight: 5, marginLeft: 10}}
              strokeWidth="8" fill="#EEEEEE"
              animationDuration=".5s"/><b>İşlem devam
              ediyor.<br/> Lütfen daha sonra menüden işlemi kontrol ediniz.</b>
            </div>
          </div>}
          {accessoryCampaignPassTitle && accessoryCampaignPassTitle?.titleStatus === "COMPLETED" &&
          <div className="p-col-12 p-md-7">
            <DataTable value={accessoryCampaignPassTitle.accessoryCampaignPassList}
                       header={header} ref={dt}
                       paginator rows={5} rowsPerPageOptions={[5, 20, 50]}
                       paginatorTemplate="FirstPageLink PageLinks LastPageLink CurrentPageReport RowsPerPageDropdown"
                       currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} arası gösteriliyor"
                       emptyMessage="Katıt bulunamadı">
              <Column field="ttCrm.name" header="TT CRM" filter={true} filterMatchMode={"contains"}/>
              <Column field="accessoryCampaign.amount" header="Kampanya Tutarı" style={{textAlign: 'right'}}/>
              <Column field="accessoryCampaign.termNumber" header="Taksit Sayısı" style={{textAlign: 'right'}}/>
              <Column headerStyle={{width: "38%"}} field="passValue" header="Şifre"/>
            </DataTable>
            <br/>
            <p><b>Oluşturulma Zamanı:</b> {accessoryCampaignPassTitle.createDateTimeString}</p>
            <p><b>Oluşturan Kişi Adı:</b> {accessoryCampaignPassTitle.userDto.name}</p>
            <p><b>Oluşturan Kişi Soyadı:</b> {accessoryCampaignPassTitle.userDto.surname}</p>
            <p><b>Aktiflik Durumu:</b> <Checkbox style={{cursor: 'context-menu'}} disabled={true}
                                                 checked={accessoryCampaignPassTitle.isActivated}/></p>
            <Button disabled={accessoryCampaignPassTitle.isActivated || activatePassButtonDisabled}
                    style={{marginRight: 5}} className="p-button-success" icon="pi pi-check" label="Aktifleştir"
                    onClick={activatePass}/>
            <Button disabled={accessoryCampaignPassTitle.isActivated || activatePassButtonDisabled}
                    className="p-button-danger" icon="pi pi-times" label="İptal Et" onClick={cancelPass}/>
          </div>}
        </div>
      </div>
    </>
  )

};

export default AccessoryCampaignPassTitle;
