import React, {useEffect, useRef, useState} from "react";
import CategoryService from "./httpService";
import {handleError} from "../../service/HandleErrorService";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Toast} from "primereact/toast";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {ConfirmDialog} from "primereact/confirmdialog";
import {TabPanel, TabView} from "primereact/tabview";
import {Dropdown} from "primereact/dropdown";
import {MultiSelect} from "primereact/multiselect";
import {DataTable} from "primereact/datatable";
import {InputNumber} from "primereact/inputnumber";

const CategoryManagement = () => {

  const toast = useRef(null);
  const [allSubCategories, setAllSubCategories] = useState([]);

  const dt = useRef(null);
  const [allCategories, setAllCategories] = useState([]);
  const categoryService = new CategoryService();
  const [deleteSubCategoryDialog, setDeleteSubCategoryDialog] = useState(false);
  const [deleteSubCategoryId, setDeleteSubCategoryId] = useState();
  const [createSubCategory, setCreateSubCategory] = useState({});
  const [editSubCategory, setEditSubCategory] = useState({});
  const [upsertCategory, setUpsertCategory] = useState();
  const [createCategory, setCreateCategory] = useState();
  const [deleteCategory, setDeleteCategory] = useState();
  const [selectedRepresentative, setSelectedRepresentative] = useState();
  const [selectedRepresentativeSubCategory, setSelectedRepresentativeSubCategory] = useState();
  const [createSubCategoryShow, setCreateSubCategoryShow] = useState(false);
  const [editSubCategoryShow, setEditSubCategoryShow] = useState(false);
  const [scoreLabel, setScoreLabel] = useState();
const [selectScoreType,setSelectScoreType]=useState();
  const [editCategoryDialog, setEditCategoryDialog] = useState(false);
  const [editCategoryHeader, setEditHeader] = useState(false);
  const [deleteCategoryDialog, setDeleteCategoryDialog] = useState(false);
  const [deleteCategoryHeader, setDeleteHeader] = useState(false);
  const [errorFields, setErrorFields] = useState({});
  const [allScoreTypes, setAllScoreTypes] = useState([]);
  useEffect(() => {
    getAll();
    getAllSubCategories();
    getAllScoreTypes();
  }, [])

  const sortData = (data) => {
      const x = data?.sort(function(a, b){
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
  })
  return x;
  }
  const getAllScoreTypes = () => {
    categoryService.getAllScoreType().then(res=>{
      setAllScoreTypes(res?.map(value=>{

        return{
          name:value.value,
          value:value.key
        }
      }));
    });
  }
  const getAll = () => {
    categoryService.getAll().then(res => {

      setAllCategories(sortData(res))
    }).catch(handleError)
  }

  const getAllSubCategories = () => {
    categoryService.getAllSubCategories().then(res => {
      setAllSubCategories(sortData(res))
    }).catch(err => handleError(err, toast))
  }


  const hideEditDialog = () => {

    setEditCategoryDialog(false);
    setErrorFields({});
    setUpsertCategory(null);
  }


  const editModelShow = (e, rowData) => {
    setUpsertCategory(rowData)
    setEditHeader("Kategori Düzenle");
    setErrorFields({});
    setEditCategoryDialog(true);

  };

  const hideCreateSubCategoryDialog = () => {
    setCreateSubCategoryShow(false);
    setCreateSubCategory(null);
    setErrorFields({});
    setScoreLabel("");

  }
  const hideEditSubCategoryDialog = () => {
    setEditSubCategoryShow(false);
    setEditSubCategory(null);
    setErrorFields({});
    setScoreLabel("");
  }

  const actions = (rowData) =>

    <div><Button
      icon="pi pi-pencil"
      className="p-button-rounded p-button-warning p-mr-2"
      label="Düzenle"

      onClick={e => editModelShow(e, rowData)}
    />
      <Button onClick={e => {
        setDeleteCategoryDialog(true);
        setDeleteCategory(rowData)
      }} icon="pi pi-times" className="p-button-rounded p-button-danger"/>
    </div>

  const editSubCategoryModal = (e, rowData) => {
    setEditSubCategory(rowData);
    setEditSubCategoryShow(true);
    if (rowData.productScoreType === "UNIT") {
      setScoreLabel("Puan")
    } else if (rowData.productScoreType === "AMOUNT") {
      setScoreLabel("Oran")
    } else {
      setScoreLabel("");
    }


  }

  const actionsSubCategories = (rowData) =>

    <div><Button
      icon="pi pi-pencil"
      className="p-button-rounded p-button-warning p-mr-2"
      label="Düzenle"

      onClick={e => editSubCategoryModal(e, rowData)}
    />
      <Button onClick={e => {
        setDeleteSubCategoryDialog(true);
        setDeleteSubCategoryId(rowData.id)
      }} icon="pi pi-times" className="p-button-rounded p-button-danger"/>
    </div>

  const isInvalid = (bindingField1, bindingField2) => {
    if (errorFields[`${bindingField1}`]) {

      return " p-invalid";
    }
    if (errorFields[`${bindingField2}`]) {
      return " p-invalid";
    }
    return "";
  }
  const showSubCategoryDialog = () => {

    setCreateSubCategoryShow(true);
  }
  const changeModel = (e) => {
    let _upsertCategory = {...upsertCategory};
    _upsertCategory.name = e.target.value;
    setUpsertCategory(_upsertCategory)
  }
  const changeCategory = (e, name) => {
    let _createCategory = {...createCategory};
    _createCategory.name = e.target.value;
    setCreateCategory(_createCategory)

  }
  const upsert = () => {
    categoryService.upsert(upsertCategory).then(res => {
      getAll();
      toast.current?.show({
        severity: "success",
        detail: "İşlem Başarılı",
        life: 3000,
      });
      setEditCategoryDialog(false);


    }).catch(err => setErrorFields(handleError(err, toast)))
  }

  const createNew = () => {

    setCreateCategory({name: ""});
    categoryService.upsert(createCategory).then(res => {
      toast.current?.show({
        severity: "success",
        detail: "İşlem Başarılı",
        life: 3000,
      });
      getAll();
    }).catch(err => setErrorFields(handleError(err, toast)))
  }

  const upsertSubCategory = (subCategory) => {

    categoryService.upsertSubCategories(subCategory).then(res => {
      getAllSubCategories();
      toast.current?.show({
        severity: "success",
        detail: "İşlem Başarılı",
        life: 3000,
      });
      setCreateSubCategory(null);
      setCreateSubCategoryShow(false);
      setEditSubCategory({});
      setEditSubCategoryShow(false);
    }).catch(err => setErrorFields(handleError(err, toast)));


  }
  const accept = () => {
    categoryService.delete(deleteCategory.id).then(res => {
      toast.current?.show({
        severity: "success",
        detail: "İşlem Başarılı",
        life: 3000,
      });

      getAll();
      getAllSubCategories();

    }).catch(err => handleError(err, toast))
  }
  const acceptSubCategory = () => {

    categoryService.deleteSubCategories(deleteSubCategoryId).then(res => {
      toast.current?.show({
        severity: "success",
        detail: "İşlem Başarılı",
        life: 3000,
      });
      getAllSubCategories();


    }).catch(err => handleError(err, toast))
  }


  const onRepresentativesChange = (e) => {

    dt.current?.filter(e.value, 'category.name', 'equals');
    setSelectedRepresentative(e.value)
  }

  const onRepresentativesChangeSubCategory = (e) => {

    dt.current?.filter(e.value, 'name', 'equals');
    setSelectedRepresentativeSubCategory(e.value)
  }

  const onScoreChange = (e) => {
console.log(allScoreTypes)
    dt.current?.filter(e.value, 'productScoreType', 'equals');
    setSelectScoreType(e.value)
  }

  const scoreItemTemplate = (option) => {
    return (
      <div className="p-multiselect-representative-option">
        <span className="image-text">{option.name}</span>
      </div>
    );
  }

  const representativesItemTemplate = (option) => {
    return (
      <div className="p-multiselect-representative-option">
        <span className="image-text">{option.name}</span>
      </div>
    );
  }

  const representativesItemTemplateSubCategory = (option) => {
    return (
      <div className="p-multiselect-representative-option">
        <span className="image-text">{option.name}</span>
      </div>
    );
  }




  // const subCategoryTemplate = (e) => {


  //   return <div style={{paddingLeft:8}}>{e?.name}</div>
  // }


  const categoryTemplate = (e) => {


    return <div style={{paddingLeft:8}}>{e?.category?.name}</div>
  }

  const subCategoryTemplate = (e) => {


    return <div style={{paddingLeft:8}}>{e?.name}</div>
  }

  const scoreRatioTemplate = (e) => {


    return <div style={{paddingLeft:8}}>{e?.scoreRatio}</div>
  }

  const scoreTypeTemplate = (e) => {

    let _scoreType = allScoreTypes.find(score => score.value === e?.productScoreType);

    return <div style={{paddingLeft:8}}>{_scoreType?.name}</div>
  }

  const representativeFilter = <Dropdown value={selectedRepresentative} options={allCategories}
                                         itemTemplate={representativesItemTemplate} onChange={onRepresentativesChange}
                                         optionLabel="name" filter optionValue="name" placeholder="Kategoriler"
                                         showClear
                                         className="p-column-filter"/>;

  const representativeFilterSubCategory = <Dropdown value={selectedRepresentativeSubCategory} options={allSubCategories}
                                         itemTemplate={representativesItemTemplateSubCategory} onChange={onRepresentativesChangeSubCategory}
                                         optionLabel="name" filter optionValue="name" placeholder="Alt Kategoriler"
                                         showClear
                                         className="p-column-filter"/>;


  const scoreFilter=
    <Dropdown id="productScoreType" value={selectScoreType}
              options={allScoreTypes} optionLabel="name" optionValue="value" placeholder="Skor Tipi"
                              itemTemplate={scoreItemTemplate} onChange={e=>onScoreChange(e)}
                              showClear
                              className="p-column-filter"/>;

  return (
    <>
      <Toast ref={toast}/>
      <TabView>
        <TabPanel header="Kategori Yönetimi">

      <span className="p-float-label marginInput">
          <InputText id="category" value={createCategory?.name}
                     onChange={e => changeCategory(e, 'category')}
                     className={isInvalid('category')}
                     style={{width: 300}}
          />
                     <label htmlFor="category">Kategori Ekle</label>
         <Button onClick={e => createNew(e)} label="Kaydet"/>
        </span>


          <ResponsiveDataTable

            header="Kategori Listesi"
            value={allCategories}
          >
            <Column field="name" header="Kategori" filter filterPlaceholder="Kategori Ara" filterMatchMode="contains"/>
            <Column body={actions}/>

          </ResponsiveDataTable>


          <Dialog header={editCategoryHeader} visible={editCategoryDialog} style={{width: 650}}
                  onHide={hideEditDialog}>

         <span className="p-float-label marginInput">
          <InputText id="category" value={upsertCategory?.name}
                     onChange={e => changeModel(e, 'category')}
                     className={isInvalid('category')}
                     style={{width: 300}}
          />
                     <label htmlFor="category">Kategori</label>
        </span>
            <Button label="Kaydet" onClick={upsert}/>


          </Dialog>

          <ConfirmDialog visible={deleteCategoryDialog} onHide={() => setDeleteCategoryDialog(false)}
                         message="Silmek istediğinizden emin misiniz?"
                         acceptLabel="Evet" rejectLabel="Hayır" icon="pi pi-exclamation-triangle" accept={accept}/>

        </TabPanel>
        <TabPanel header="Alt Kategori Yönetimi">
          <div className="p-d-flex p-ai-center export-buttons">
            <Button onClick={showSubCategoryDialog} icon="pi pi-plus-circle" label="Alt Kategori Ekle"
                    className="p-button-success p-mr-2 p-mb-2"/>


          </div>
          <DataTable value={allSubCategories}
                     ref={dt}>

            <Column field="category.name" header="Kategori" body={categoryTemplate} filter filterElement={representativeFilter} headerStyle={{paddingLeft:20}} />
            <Column field="name" header="Alt Kategori" body={subCategoryTemplate} filter filterElement={representativeFilterSubCategory} headerStyle={{paddingLeft:20}} />
            <Column field="productScoreType" header="Skor Tipi" body={e => scoreTypeTemplate(e)}filter filterElement={scoreFilter}  headerStyle={{paddingLeft:20}}/>
            <Column field="scoreRatio" header="Skor" sortable  body={scoreRatioTemplate} />

            <Column body={actionsSubCategories}/>

          </DataTable>


          <Dialog header="Alt Kategori Ekleme" visible={createSubCategoryShow} style={{width: 650}}
                  onHide={hideCreateSubCategoryDialog}>

             <span className="p-float-label marginInput">
            <Dropdown style={{width: 300}} value={createSubCategory?.category?.name} options={allCategories}
                      optionLabel="name" optionValue="name"
                      placeholder="Kategori"
                      id="categoryName"
                      className={isInvalid('categoryName')}
                      onChange={e => {
                        let _createSubCategory = {...createSubCategory};
                        let _category = allCategories.find(value => value.name === e.target.value)
                        _createSubCategory.category = _category;
                        setCreateSubCategory(_createSubCategory)
                      }}/>
             </span>

            <span className="p-float-label marginInput">
          <InputText id="subCategoryName" value={createSubCategory?.name}
                     onChange={e => {
                       let _createSubCategory = {...createSubCategory};
                       _createSubCategory.name = e.target.value;
                       setCreateSubCategory(_createSubCategory)
                     }}
                     className={isInvalid('subCategoryName')}
                     style={{width: 300}}
          />
                     <label htmlFor="subCategoryName">Alt Kategori</label>
        </span>
        
            <span className="p-float-label marginInput">
          <Dropdown id="productScoreType" value={createSubCategory?.productScoreType}
                    options={allScoreTypes} optionLabel="name" optionValue="value"
                    onChange={e => {
                      console.log(e.value)
                      let _createSubCategory = {...createSubCategory};
                      _createSubCategory.productScoreType = e.value;
                      setCreateSubCategory(_createSubCategory)

                      if (e.value === "UNIT") {
                        setScoreLabel("Puan")

                      } else if (e.value === "AMOUNT") {
                        setScoreLabel("Oran")
                      } else {
                        setScoreLabel("");
                      }
                    }}
                    className={isInvalid('productScoreType')}
                    style={{width: 300}}
          />
                     <label htmlFor="scoreType">Skor Tipi</label>
        </span>


            <span className="p-float-label marginInput">
          <InputText type="number" step="0,1" id="scoreRatio" value={createSubCategory?.scoreRatio}
                     onChange={e => {
                       let _createSubCategory = {...createSubCategory};
                       _createSubCategory.scoreRatio = e.target.value;
                       setCreateSubCategory(_createSubCategory)
                     }}
                     className={isInvalid('scoreRatio')}
                     style={{width: 300}}
          />
                     <label htmlFor="subCategoryName">{scoreLabel}</label>
        </span>

            <Button label="Kaydet" onClick={e => {
              upsertSubCategory(createSubCategory)
            }}/>


          </Dialog>
          <Dialog header="Alt Kategori Düzenle" visible={editSubCategoryShow} style={{width: 650}}
                  onHide={hideEditSubCategoryDialog}>

             <span className="p-float-label marginInput">
            <Dropdown style={{width: 300}} value={editSubCategory?.category?.name} options={allCategories}
                      optionLabel="name" optionValue="name"
                      placeholder="Kategori"
                      id="categoryName"
                      className={isInvalid('categoryName')}
                      onChange={e => {
                        let _editSubCategory = {...editSubCategory};
                        let _category = allCategories.find(value => value.name === e.target.value)
                        _editSubCategory.category = _category;
                        setEditSubCategory(_editSubCategory)
                      }}/>
             </span>

            <span className="p-float-label marginInput">
          <InputText id="subCategory" value={editSubCategory?.name}
                     onChange={e => {
                       let _editSubCategory = {...editSubCategory};
                       _editSubCategory.name = e.target.value;
                       setEditSubCategory(_editSubCategory)
                     }}
                     className={isInvalid('subCategoryName')}
                     style={{width: 300}}
          />
                     <label htmlFor="category">Alt Kategori</label>
        </span>

            <span className="p-float-label marginInput">
          <Dropdown id="productScoreType" value={editSubCategory?.productScoreType}
                    options={allScoreTypes} optionLabel="name" optionValue="value"
                    onChange={e => {
                      let _editSubCategory = {...editSubCategory};
                      _editSubCategory.productScoreType = e.value;
                      setEditSubCategory(_editSubCategory)
                      if (e.value === "UNIT") {
                        setScoreLabel("Puan")

                      } else if (e.value === "AMOUNT") {
                        setScoreLabel("Oran")
                      } else {
                        setScoreLabel("");
                      }
                    }}
                    className={isInvalid('productScoreType')}
                    style={{width: 300}}
          />
                     <label htmlFor="scoreType">Skor Tipi</label>
        </span>


            <span className="p-float-label marginInput">
          <InputText type="number" step="0,1" id="scoreRatio" value={editSubCategory?.scoreRatio}
                     onChange={e => {
                       let _editSubCategory = {...editSubCategory};
                       _editSubCategory.scoreRatio = e.target.value;
                       setEditSubCategory(_editSubCategory)
                     }}
                     className={isInvalid('scoreRatio')}
                     style={{width: 300}}
          />
                     <label htmlFor="scoreRatio">{scoreLabel}</label>
        </span>

            <Button label="Kaydet" onClick={e => upsertSubCategory(editSubCategory)}/>


          </Dialog>


          <ConfirmDialog visible={deleteSubCategoryDialog} onHide={() => setDeleteSubCategoryDialog(false)}
                         message="Silmek istediğinizden emin misiniz?"
                         acceptLabel="Evet" rejectLabel="Hayır" icon="pi pi-exclamation-triangle"
                         accept={acceptSubCategory}/>

        </TabPanel>

      </TabView>
    </>
  )

}
export default CategoryManagement;
