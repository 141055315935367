import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import {Link, useHistory} from "react-router-dom";
import {handleError} from "../../../service/HandleErrorService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from '../../../components/ResponsiveDataTable'
import ProductPriceAdminService from "./ProductPriceAdminService";
import {useDispatch} from "react-redux";
import * as actions from '../../../redux/modules/modelPrice/modelPrice.action';
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import CanSellService from "../../canSell/httpService";
import moment from "moment";
import {InputTextarea} from "primereact/inputtextarea";
import xlsx from "xlsx-js-style";

const ProductPriceDistyListAdmin = () => {
  const history = useHistory();
  const toast = useRef(null);
  const productPriceService = new ProductPriceAdminService();

  const [searchProductPrice, setSearchProductPrice] = useState({});
  const [productPriceList, setProductPriceList] = useState([]);
  const [searchRequest, setSearchRequest] = useState({});
  const canSellService = new CanSellService();
  const [allBrand, setAllBrand] = useState([]);
  const [allSupply, setAllSupply] = useState([]);
  const [allDisty, setAllDisty] = useState([]);
  const [excelList, setExcelList] = useState([]);
  const [allTimeOptions, setAllTimeOptions] = useState(["GÜNCEL", "ESKİ"]);
  const [loading, setLoading] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState({});
  const [searchCriteriaLazyLoading, setSearchCriteriaLazyLoading] = useState(false);
  const [searchCriteriaProgressing, setSearchCriteriaProgressing] = useState(false);

  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });


  useEffect(() => {
    getAll(paginationConfig);
    canSellService.getAllBrand().then(setAllBrand);
    canSellService.getAllSupply().then(setAllSupply);
    canSellService.getAllDisty().then(setAllDisty);
  }, [])

  useEffect(() => {
    getAll({...paginationConfig, activePage: 0});
  }, [searchCriteria]);

  const getAll = (pageState) => {
    var sort = "";
    if (searchCriteria.sortField != null && searchCriteria.sortOrder != null) {
      sort += searchCriteria.sortField;
      if (searchCriteria.sortOrder == 1) {
        sort += ",asc";
      } else {
        sort += ",desc";
      }
    } else {
      sort += "mps.start_date,desc";
    }
    setLoading(true);
    productPriceService.getCurrentModelPricesDisty(searchRequest, pageState.activePage, pageState.itemsPerPage, sort, searchRequest).then(res => {


      res.content?.forEach(value=> {
        Date.now() < Date.parse(value.mpsStartDate) ? value.mpsNextPrice = true : value.mpsNextPrice=false;

        Date.now() < Date.parse(value.mpdStartDate) ? value.mpdNextPrice = true : value.mpdNextPrice=false;

      });





      setProductPriceList(res.content)
      pageState.first = pageState.itemsPerPage * pageState.activePage;

      pageState.totalRecords = res.totalElements;
      setPaginationConfig({
        ...pageState,
      });

    }).catch(err => handleError(err, toast)).finally(() => {
      setLoading(false);
    });


  }

  const getExcelData=()=>{
    let _excelList=[];
    productPriceService.getCurrentModelPricesDistyList(searchRequest).then(res=>{
      res?.forEach(value=>{
        let _excelModel = {};
        _excelModel['Kategori'] = value?.category;
        _excelModel['Alt Kategori'] = value?.subCategory;
        _excelModel['Marka'] = value?.brand;
        _excelModel['Tedarikçi'] = value?.supply;
        _excelModel['Durum'] = value?.timeStatus;
        _excelModel['Distribütör'] = value?.distyName;
        _excelModel['Ürün Adı'] = value?.modelName;
        _excelModel['Barkod'] = value?.barcode;
        _excelModel['Tedarikçi Fiyatının Geçerli Olacağı Başlangıç Tarihi']=value?.mpsStartDate
        console.log(value?.mpsStartDate);
        _excelModel['Tedarikçi Fiyatının Geçerli Olacağı Başlangıç Saati']=moment(value?.mpsStartDate).format("hh:mm:ss");
        _excelModel['Tedarikçi Fiyatının Geçersiz Olduğu Başlangıç Tarihi']=value?.mpsEndDate
        _excelModel['Tedarikçi Fiyatının Geçersiz Olduğu Başlangıç Saati']=value?.mpsEndDate ? moment(value?.mpsEndDate).format("hh:mm:ss") : null;
        _excelModel['Distribütör Fiyatının Geçerli Olacağı Başlangıç Tarihi']=value?.mpdStartDate
        _excelModel['Distribütör Fiyatının Geçerli Olacağı Başlangıç Saati']=moment(value?.mpdStartDate).format("hh:mm:ss");

        _excelModel['Ürün Açıklaması'] = value?.description;
        _excelModel['Tedarik Durumu'] = value?.supplyStatus;
        _excelModel['Menşei'] = value?.origin;
        if (value.eol) {
          _excelModel['Eol'] = "EVET";
        } else if (value.eol === undefined) {
          _excelModel['Eol'] = "";
        } else {
          _excelModel['Eol'] = "HAYIR";

        }
        _excelModel['Onay Durumu'] = value?.approveType;

        _excelModel['Kdv Oranı'] = value?.taxRatio;
        _excelModel['Distribütör Alış Fiyatı'] = value?.distyPrice;
        _excelModel['Önerilen EDM Alış Fiyatı'] = value?.recommendedEdmPrice;
        _excelModel['Önerilen Bayi Alış Fiyatı'] = value?.recommendedResellerPrice;
        _excelModel['Önerilen Satış Fiyatı'] = value?.recommendedSellPrice;
        _excelModel['Bayi Alış Fiyatı'] = value?.resellerPrice;

        _excelList.push(_excelModel);
      })
      setExcelList(_excelList);


        const worksheet = xlsx.utils.json_to_sheet(_excelList);
        convertExcelColumnToDate(worksheet, 8);
        convertExcelColumnToDate(worksheet, 10);
        convertExcelColumnToDate(worksheet, 12);
        let wscols = [
          {wch: 10}, {wch: 10}, {wch: 10}, {wch: 10},
          {wch: 10}, {wch: 10}, {wch: 10}, {wch: 10},
          {wch: 10}, {wch: 10}, {wch: 10}, {wch: 10},
          {wch: 10}, {wch: 10}, {wch: 10},
      ];
      worksheet['!cols'] = wscols;
        const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
        saveAsExcelFile(excelBuffer, 'products');

    }).catch(err=>handleError(err,toast))

  }

  const convertExcelColumnToDate = (worksheet, columnIndex) => {
    let range = xlsx.utils.decode_range(worksheet["!ref"]);
    for (let R = range.s.r + 1; R <= range.e.r; ++R) {
        let cell_address = {c: columnIndex, r: R};
        let cell_ref = xlsx.utils.encode_cell(cell_address);
        if (worksheet[cell_ref].v) {
            worksheet[cell_ref].t = "d";
            worksheet[cell_ref].v = moment(new Date(worksheet[cell_ref].v), 'DD-MM-YYYY hh:mm:ss')
                .toDate();
        }
      }
    }

  const onSort = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const exportExcel = () => {

  }

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, "Ürün-Fiyat Listesi" + EXCEL_EXTENSION);
    });
  }

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    getAll(pageState);
  };


  const priceTemplate = (e, name) => {
    if (e[name] === undefined) {
      return <div>Girilmedi.</div>
    } else return <div>{e[name]}₺</div>

  }


  const taxRatioTemplate = (e) => {
    if (e['taxRatio'] === undefined) {
      return <div>Girilmedi.</div>
    } else return <div>%{e['taxRatio']}</div>

  }


  const routeDetail = (rowData) => {


    return <Button
      icon="pi pi-search"
      className="p-button-rounded p-button-success p-mr-2"
      label="Detay"

      onClick={e => {
        history.push(`/ProductPriceAdmin-Detail?id=${rowData.id}&mid=${rowData?.mid}&did=${rowData?.did}&&sid=${rowData?.sid}&&mpsId=${rowData?.mpsid}`)
      }}/>
  }
  const supplyTemplate=(e)=>{
    let _supply= allSupply.find(value=>value.id===e.sid);

    return _supply?.disties!==undefined? _supply?.disties[0].name:_supply?.name;


  }
  const modelNameTemplate=(e)=>{

    return <div ><InputTextarea style={{fontSize: 10,backgroundColor:'lawngreen',width:'100%'}} autoResize disabled value={e?.modelName}/>
    </div>  }
  return (
    <>

      <Toast ref={toast}/>

      <div className="card">
        <div className="p-formgroup-inline">
          <div className="p-field">
            <label htmlFor="brand" className="p-sr-only">Marka</label>
            <Dropdown id="brand" value={searchRequest.brand} options={allBrand} showClear placeholder="Marka"
                      optionLabel="name" optionValue="name"
                      onChange={e => {
                        let _searchRequest = {...searchRequest};
                        _searchRequest.brand = e.target.value;
                        setSearchRequest(_searchRequest);
                      }

                      }/>
          </div>

          <div className="p-field">
            <label htmlFor="name" className="p-sr-only">Ürün</label>
            <InputText id="name" value={searchRequest.modelName} type="text" placeholder="Ürün"
                       onChange={e => {
                         let _searchRequest = {...searchRequest};
                         _searchRequest.modelName = e.target.value;
                         setSearchRequest(_searchRequest);
                       }

                       }/>
          </div>

          <div className="p-field">
            <label htmlFor="barcode" className="p-sr-only">Barkod</label>
            <InputText id="barcode" value={searchRequest.barcode} type="text" placeholder="Barkod"
                       onChange={e => {
                         let _searchRequest = {...searchRequest};
                         _searchRequest.barcode = e.target.value;
                         setSearchRequest(_searchRequest);
                       }

                       }/>


          </div>


          <div className="p-field">
            <label htmlFor="supply" className="p-sr-only">Tedarikçi</label>
            <Dropdown id="supply" value={searchRequest.supply} options={allSupply} optionValue="name"

                      optionLabel={e => {

                        if (e?.disties !== undefined) {

                          return e?.disties[0]?.name;
                        } else {
                          return e.name
                        }
                      }}
                      placeholder="Tedarikçi"
                      showClear
                      onChange={e => {
                        let _searchRequest = {...searchRequest};
                        _searchRequest.supply = e.target.value;
                        setSearchRequest(_searchRequest);
                      }

                      }/>
          </div>
          <div className="p-field">
            <label htmlFor="supply" className="p-sr-only">Distribütör</label>
            <Dropdown id="supply" value={searchRequest.disty} options={allDisty} optionValue="name" optionLabel="name"
                      showClear
                      placeholder="Distribütör"
                      onChange={e => {
                        let _searchRequest = {...searchRequest};
                        _searchRequest.disty = e.target.value;
                        setSearchRequest(_searchRequest)
                      }}
            />
          </div>
          <div className="p-field">
            <label htmlFor="timeOption" className="p-sr-only">Durum</label>
            <Dropdown id="timeOption" value={searchRequest.timeStatus} options={allTimeOptions}
                      showClear
                      placeholder="Durum"
                      onChange={e => {
                        let _searchRequest = {...searchRequest};
                        _searchRequest.timeStatus = e.target.value;
                        setSearchRequest(_searchRequest)
                      }}
            />
          </div>

          <Button type="button" label="Ara" onClick={e => getAll({activePage: 0,itemsPerPage: paginationConfig.itemsPerPage})}/>

        </div>

      </div>

      <Button style={{marginBottom: 5}} label="Ürün Listesini İndir" type="button" icon="pi pi-file-excel"
              onClick={getExcelData} className="p-button-success p-mr-2" data-pr-tooltip="XLS"/>

      <div className="datatable-responsive-demo">

        <ResponsiveDataTable value={productPriceList}
                             paginationConfig={paginationConfig}
                             onActivePageChange={onActivePageChange}
                             emptyMessage="Ürün Bulunamadı."
                             style={{fontSize: 13}}
                             onSort={onSort}
                             sortField={searchCriteria.sortField}
                             sortOrder={searchCriteria.sortOrder}
                             lazy
                             loading={loading}
        >

          <Column field="brand" header="Marka"/>
          <Column field="modelName" body={modelNameTemplate} header="Ürün İsmi"/>
          <Column style={{width: 120}} field="barcode" header="Barkod"/>
          <Column field="supply" body={supplyTemplate} header="Tedarikçi"/>
          <Column field="distyName" header="Distribütör"/>
          <Column field="timeStatus" header="Durum"/>
          <Column body={taxRatioTemplate} header="KDV Oranı"/>
          <Column body={e => {

            if (e['mpsNextPrice']) {
              return <div style={{backgroundColor: 'greenyellow'}}>{moment(e['mpsStartDate']).format('DD-MM-YYYY')}</div>
            } else {
              return <div>{moment(e['mpsStartDate']).format('DD-MM-YYYY')}</div>
            }
          }}  header="Tedarikçi Fiyatının Geçerli Olacağı Başlangıç Tarihi"/>
          <Column body={e => {

            if (e['mpdNextPrice']) {
              return <div style={{backgroundColor: 'greenyellow'}}>{moment(e['mpdStartDate']).format('DD-MM-YYYY')}</div>
            } else {
              return <div>{moment(e['mpdStartDate']).format('DD-MM-YYYY')}</div>
            }
          }}  header="Distribütör Fiyatının Geçerli Olacağı Başlangıç Tarihi"/>

          <Column field="distyPrice" body={e => priceTemplate(e, 'distyPrice')} header="Distribütör Alış Fiyatı"
                  sortable/>
          <Column field="recommendedEdmPrice" body={e => priceTemplate(e, 'recommendedEdmPrice')} header="Önerilen EDM Alış Fiyatı"
                  sortable/>
          <Column field="recommendedResellerPrice" body={e => priceTemplate(e, 'recommendedResellerPrice')}
                  header="Önerilen Bayi Alış Fiyatı" sortable/>
          <Column field="recommendedSellPrice" body={e => priceTemplate(e, 'recommendedSellPrice')}
                  header="Önerilen Satış  Fiyatı " sortable/>
          <Column field="resellerPrice" body={e => priceTemplate(e, 'resellerPrice')} header="Bayi Alış Fiyatı"
                  sortable/>

          <Column body={e => routeDetail(e)} header="Ürün Detayı"/>


        </ResponsiveDataTable>

      </div>


    </>
  )
}
export default ProductPriceDistyListAdmin;
