import AspAxios from "../../service/AspAxios";
import * as Paths from '../../service/config'

export default class ParamService {

  getAllParameters() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllParameters).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  getByKeyAndResellerCode(key, resellerCode) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getParameterByKey + "/" + key + "/" + resellerCode).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  updateParameter(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.updateParameter, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

};
