import React, {useEffect, useRef, useState} from "react";
import * as services from "./httpService";
import {handleError} from "../../service/HandleErrorService";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {Toolbar} from "primereact/toolbar";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {InputMask} from "primereact/inputmask";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {MultiSelect} from "primereact/multiselect";

const DefineTtgUser = () => {
  let emptyUser = {
    name: "",
    surname: "",
    email: "",
    gsmNo: "5",
    roles: [],
    disty: {},
    resellers: [],
    supply: {}
  };

  const [filterRoles, setFilterRoles] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(emptyUser);
  const [searchRequest, setSearchRequest] = useState({});
  const [userSearch, setUserSearch] = useState({
    disty: "",
    supply: ""
  });

  const [userDialog, setUserDialog] = useState(false);
  const [inValidFields, setInValidFields] = useState({});
  const [roleMap, setRoleMap] = useState({});
  const [excelModel, setExcelModel] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  const loginUser = JSON.parse(localStorage.getItem("user"));
  let _isTTG = false;
  loginUser?.user?.roles.forEach(_role => {
    if (_role.includes("TTG")) {
      _isTTG = true;
    }
  });

  const [isTTGAdmin, setIsTTGAdmin] = useState(_isTTG);

  const toast = useRef();
  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    searchUser(pageState, userSearch)
  };

  const isValidUserSearch = () => {
    if (userSearch.supply) {
      return true;
    }
    if (userSearch.disty) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    searchUser(paginationConfig, userSearch);
    getAllRoles(paginationConfig, userSearch);
    fetchTtgRoles();
  }, []);

  useEffect(() => {
    getExcelList()
  }, [userSearch]);

  const search = () => {
    searchUser(paginationConfig, userSearch);
  };

  const getExcelList = () => {
    services.searchTtgUserList(userSearch)
      .then((res) => {
        let _excelModelList = [];
        res?.forEach(value => {
          let _excelModel = {};
          _excelModel['İsim'] = value?.name;
          _excelModel['Soyisim'] = value?.surname;
          _excelModel['Email'] = value?.email;
          _excelModel['gsmNo'] = value?.gsmNo;
          _excelModel['username'] = value?.username;
          let i = 1;
          value?.roles?.forEach(role => {
            _excelModel['Rol_' + i] = role
            i++;
          })
          _excelModelList.push(_excelModel)
        })
        setExcelModel(_excelModelList);
      })
      .catch((err) => {
        handleError(err, toast);
      });
  }

  function handleSearchUserResponse(res, pageState) {
    setUserList(res?.content?.filter(_user => {
      if (!_user.roles) {
        return true;
      } else {
        let isWsUser = false;
        _user.roles.forEach(_role => {
          if (_role.includes("WEB_SERVICE")) {
            isWsUser = true;
          }
        })
        return !isWsUser;
      }
    }));

    pageState.first = pageState.itemsPerPage * pageState.activePage;
    pageState.totalRecords = res?.totalElements;
    setPaginationConfig({
      ...pageState,
    });
  }

  const searchUser = (pageState, userSearch) => {
    if (!isTTGAdmin) {
      return;
    }
    services.searchTtgUser(userSearch, pageState.activePage, pageState.itemsPerPage)
      .then((res) => {
        handleSearchUserResponse(res, pageState);
      })
      .catch((err) => {
        handleError(err, toast);
      });
  };

  const getAllRoles = () => {
    services
      .getAllRoles()
      .then((res) => {
        let _roleMap = {};
        res.forEach(_roleObject => {
          _roleMap[`${_roleObject.key}`] = _roleObject.value;
        })
        setRoleMap(_roleMap);
      })
      .catch((err) => {
        handleError(err, toast);
      });
  };

  const fetchTtgRoles = () => {
    services
      .getRolesByUsage("ttg")
      .then((res) => {
        setSelectedRoles(res);
      })
      .catch((err) => {
        handleError(err, toast);
      });
  };

  const confirmDeleteUser = (request) => {
    services
      .deleteUser(request)
      .then(() => {
        searchUser(paginationConfig, userSearch);
      })
      .catch((err) => {
        handleError(err, toast);
      });
  };

  const confirmActiveUser = (request) => {
    services
      .activeUser(request)
      .then(() => {
        searchUser(paginationConfig, userSearch);
      })
      .catch((err) => {
        handleError(err, toast);
      });
  };

  const upsertUser = () => {
    if (validateUser(selectedUser)) {
      services
        .upsertUser(selectedUser)
        .then(() => {
          searchUser(paginationConfig, userSearch);
          hideDialog();
        })
        .catch((err) => {
          setInValidFields(handleError(err, toast));
        });
    } else {
      toast.current?.show({
        severity: "warn",
        summary: "Uyarı",
        detail: "Kullanıcı kaydı için zorunlu alanlar dolu olmalıdır.",
      });
    }
  };

  const validateUser = () => {
    let invalidFields = {};

    if (!selectedUser.name) {
      invalidFields.name = true;
    }
    if (!selectedUser.gsmNo || !validateGsmNo(selectedUser.gsmNo)) {
      invalidFields.gsmNo = true;
    }
    if (!selectedUser.roles) {
      invalidFields.roles = true;
    }
    if (!selectedUser.surname) {
      invalidFields.surname = true;
    }
    if (!selectedUser.roles) {
      invalidFields.roles = true;
    }
    if (!selectedUser.email || !validateEmail(selectedUser.email)) {
      invalidFields.email = true;
    }
    setInValidFields(invalidFields);
    return Object.keys(invalidFields).length === 0;

  }

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _user = {...selectedUser};
    _user[`${name}`] = val;
    setSelectedUser(_user);
  };

  const leftTemplate = () => {
    return (
      <div className="p-formgroup-inline">

        <div className="p-field">
          <label htmlFor="name" className="p-sr-only">İsim</label>
          <InputText id="name" value={searchRequest.name} type="text" placeholder="İsim"
                     onChange={e => {
                       let _userSearch = {...userSearch};
                       _userSearch.name = e.target.value;
                       setUserSearch(_userSearch)
                     }

                     }/>
        </div>

        <div className="p-field">
          <label htmlFor="gsmNo" className="p-sr-only">İsim</label>
          <InputText id="gsmNo" value={searchRequest.gsmNo} type="text" placeholder="GsmNo"
                     onChange={e => {
                       let _userSearch = {...userSearch};
                       _userSearch.gsmNo = e.target.value;
                       setUserSearch(_userSearch)
                     }

                     }/>
        </div>

        <div className="p-field">
          <label htmlFor="email" className="p-sr-only">Email</label>
          <InputText id="email" value={searchRequest?.email} type="text" placeholder="E-mail"
                     onChange={e => {
                       let _userSearch = {...userSearch};
                       _userSearch.email = e.target.value;
                       setUserSearch(_userSearch)
                     }

                     }/>
        </div>
        <div className="p-field">
          <MultiSelect display="chip"
                       id="roller"
                       placeholder="Rol"
                       optionLabel="value"
                       optionValue="key"
                       value={filterRoles}
                       onChange={e => {
                         setFilterRoles(e.target.value);
                         let _userSearch = {...userSearch};
                         _userSearch.roles = e.target.value;
                         setUserSearch(_userSearch)
                       }}
                       options={selectedRoles}/>
        </div>
        <div>
          <Button
            label="Ara"
            icon="pi pi-search"
            className="p-button-primary"
            onClick={search}
          />
        </div>

      </div>
    )
  }

  const newTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Yeni"
          icon="pi pi-plus"
          className="p-button-success p-mr-2"
          onClick={openNew}
        />
      </React.Fragment>
    );
  };

  const openNew = () => {
    setInValidFields({});
    setSelectedUser(emptyUser);
    setUserDialog(true);
  };


  const editUser = (rowData) => {
    setInValidFields({});
    setSelectedUser(rowData);
    setUserDialog(true);
  };

  const hideDialog = () => {
    setUserDialog(false);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {rowData.status === 1 ? (
          <div>
            <Button
              icon="pi pi-pencil"
              className="p-button-rounded p-button-warning p-mr-2"
              label="Düzenle"
              onClick={() => editUser(rowData)}
            />
            <Button
              icon="pi pi-eye-slash"
              className="p-button-rounded p-button-danger"
              label="Sil"
              onClick={() => confirmDeleteUser(rowData)}
            />
          </div>
        ) : (
          <div>
            <Button
              icon="pi pi-eye"
              className="p-button-rounded p-button-success p-mr-2"
              label="Aktif Et"
              onClick={() => confirmActiveUser(rowData)}
            />
          </div>
        )}
      </div>
    );
  };

  const userDialogFooter = (
    <>
      <Button
        label="Vazgeç"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button label="Kaydet" icon="pi pi-check" onClick={upsertUser}/>
    </>
  );

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function validateGsmNo(gsmNo) {
    const re = /^(5)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/;
    return re.test(String(gsmNo).toLocaleLowerCase());
  }

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const fullNameBodyTemplate = (rowData) => {
    return responsiveColumn(
      "Kullanıcı:",
      rowData?.name + " " + rowData?.surname
    );
  };

  const emailBodyTemplate = (rowData) => {
    return responsiveColumn(
      "Email:",
      rowData?.email?.length > 21
        ? rowData?.email.substring(0, 21) + "..."
        : rowData?.email
    );
  };

  const gsmBodyTemplate = (rowData) => {
    return responsiveColumn("Telefon:", rowData?.gsmNo);
  };

  const isInvalid = (bindingField1, bindingField2) => {
    if (inValidFields[`${bindingField1}`]) {
      return " p-invalid";
    }
    if (inValidFields[`${bindingField2}`]) {
      return " p-invalid";
    }
    return "";
  };

  const rolesBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Roller</span>
        {rowData?.roles?.map(_role => {
          return <>{roleMap[`${_role}`]}<br/></>
        })}
      </React.Fragment>
    );
  };

  const exportExcel = () => {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(excelModel);
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      saveAsExcelFile(excelBuffer, 'Ttg Kullanıcıları');
    });

  }

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  }
  const header =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>İşlemler</div>
        <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"

                onClick={exportExcel}/>
      </div>
    </div>;


  return (
    <div className="datatable-responsive-demo">
      <div className="card">
        <Toast ref={toast}/>

        {isTTGAdmin && <>
          <Toolbar className="p-mb-4" left={leftTemplate} right={newTemplate}/>

          <ResponsiveDataTable
            value={userList}
            datakey="id"
            header={header}

            paginationConfig={paginationConfig}
            onActivePageChange={onActivePageChange}
            emptyMessage="Kullanıcı Bulunamadı."
          >
            <Column
              field="name"
              header="İsim"
              body={fullNameBodyTemplate}
            />
            <Column
              field="gsmNo"
              header="Gsm No"
              body={gsmBodyTemplate}
            />
            <Column
              field="email"
              header="Email"
              body={emailBodyTemplate}
            />
            <Column
              field="roles"
              header="Roller"
              body={rolesBodyTemplate}
            />
            <Column
              body={actionBodyTemplate}
              headerStyle={{width: "20%", textAlign: "center"}}
              bodyStyle={{textAlign: "center"}}
            />
          </ResponsiveDataTable>
        </>}

        <Dialog
          visible={userDialog}
          style={{width: "450px"}}
          header="Kullanıcı Bilgileri"
          modal
          className="p-fluid"
          footer={userDialogFooter}
          onHide={hideDialog}
        >

          <div className="p-float-label marginInput">
            <InputText
              id="name"
              value={selectedUser.name}
              onChange={(e) => onInputChange(e, "name")}
              required
              autoFocus
              className={"p-d-block" + isInvalid("name")}
            />
            <label htmlFor="name">İsim Giriniz</label>
          </div>
          <div className="p-float-label marginInput">
            <InputText
              id="surname"
              value={selectedUser.surname}
              onChange={(e) => onInputChange(e, "surname")}
              required
              autoFocus
              className={"p-d-block" + isInvalid("surname")}
            />
            <label htmlFor="surname">Soyisim Giriniz</label>
          </div>
          <div className="p-float-label marginInput">
            <InputText
              id="email"
              value={selectedUser.email}
              onChange={(e) => onInputChange(e, "email")}
              required
              autoFocus
              className={"p-d-block" + isInvalid("email")}
            />
            <label htmlFor="email">Email Giriniz</label>
          </div>
          <div className="p-float-label marginInput">
            <InputMask
              id="gsmNo"
              value={selectedUser.gsmNo}
              onChange={(e) => onInputChange(e, "gsmNo")}
              mask="599 999 99 99"
              autoClear={true}
              className={"p-d-block" + isInvalid("gsmNo")}
              required
              autoFocus
            />
            <label htmlFor="gsmNo">Gsm No Giriniz</label>
          </div>
          <div className="p-float-label marginInput">
            <MultiSelect display="chip"
                         id="roller"
                         optionLabel="value"
                         optionValue="key"
                         value={selectedUser.roles}
                         className={"p-d-block" + isInvalid("roles")}
                         onChange={(e) => onInputChange(e, "roles")}
                         options={selectedRoles}/>
            <label htmlFor="roller">Rol Ekleyiniz</label>
          </div>


        </Dialog>
      </div>
    </div>
  );
};

export default DefineTtgUser;
