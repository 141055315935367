import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import ProductSalesManagementService from "./httpService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Calendar} from "primereact/calendar";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {ResponsiveDataTableWithoutRowClass} from "../../components/ResponsiveDataTableWithoutRowClass";
import {Dropdown} from "primereact/dropdown";

const SerialTransfersFromMe = () => {

  const resellerCode = JSON.parse(localStorage.getItem('user')).user?.loginReseller?.code;
  const toast = useRef(null);
  const productSalesManagementService = new ProductSalesManagementService();

  const [loading, setLoading] = useState(false);
  const [selectedTimeBegin, setSelectedTimeBegin] = useState(null);
  const [selectedTimeEnd, setSelectedTimeEnd] = useState(null);
  const [serialNo, setSerialNo] = useState(null);
  const [barcode, setBarcode] = useState(null);
  const [selectedResellerCode, setSelectedResellerCode] = useState(null);
  const [searchCriteriaLazyLoading, setSearchCriteriaLazyLoading] = useState(false);
  const [searchCriteriaProgressing, setSearchCriteriaProgressing] = useState(false);
  const [saleInfoList, setSaleInfoList] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState({});

  const [technicalDetailModal, setTechnicalDetailModal] = useState(false);
  const [selectedIslem, setSelectedIslem] = useState(null);

  const [exportExcelDisabled, setExportExcelDisabled] = useState(null);

  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  useEffect(() => {
    searchSaleInfo({...paginationConfig, activePage: 0});
  }, []);

  useEffect(() => {
    setSearchCriteriaLazyLoading(true);
  }, [selectedTimeBegin, selectedTimeEnd, serialNo, barcode, selectedResellerCode, searchCriteria]);

  useEffect(() => {
    if (searchCriteriaLazyLoading) {
      setTimeout(() => {
        setSearchCriteriaProgressing(true);
        setSearchCriteriaLazyLoading(false);
      }, 2000);
    }
  }, [searchCriteriaLazyLoading]);

  useEffect(() => {
    if (searchCriteriaProgressing) {
      searchSaleInfo({...paginationConfig, activePage: 0});
      setSearchCriteriaProgressing(false);
    }
  }, [searchCriteriaProgressing]);

  const searchSaleInfo = (pageState) => {
    var dateControl = true;
    if (selectedTimeBegin != null && selectedTimeEnd != null && selectedTimeBegin.getTime() > selectedTimeEnd.getTime()) {
      dateControl = false;
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Aramak için başlangıç tarihi bitiş tarihinden önce olmalıdır.'
      });
    }
    if (dateControl) {
      setLoading(true);
      var sort = "";
      if (searchCriteria.sortField != null && searchCriteria.sortOrder != null) {
        sort += searchCriteria.sortField;
        if (searchCriteria.sortOrder == 1) {
          sort += ",asc";
        } else {
          sort += ",desc";
        }
      } else {
        sort += "createDateTime,desc";
      }
      searchCriteria.serialNo = serialNo;
      searchCriteria.barcode = barcode;

      if (selectedTimeBegin != null) {
        searchCriteria.saleDateStart = selectedTimeBegin.toLocaleDateString("tr-TR") + " " + selectedTimeBegin.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.saleDateStart = null;
      }

      if (selectedTimeEnd != null) {
        searchCriteria.saleDateEnd = selectedTimeEnd.toLocaleDateString("tr-TR") + " " + selectedTimeEnd.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.saleDateEnd = null;
      }

      searchCriteria.resellerCode = selectedResellerCode;
      searchCriteria.transferredResellerCode = resellerCode;

      productSalesManagementService.mySerialTransfers(searchCriteria, pageState.itemsPerPage, pageState.activePage, sort).then(res => {
        console.log("searchSaleInfo", res.content);
        if (res.content && res.content.length > 0) {
          res.content.forEach(c => {
            c.serialNo = c.productSaleInfoDetailList[0].serialNo;
            c.barcode = c.productSaleInfoDetailList[0].barcode;
          });
          setSaleInfoList(res.content);

          pageState.totalRecords = res.totalElements;
          pageState.first = pageState.itemsPerPage * pageState.activePage;
          setPaginationConfig({
            ...pageState,
          });
        } else {
          setSaleInfoList([]);
        }
      }).catch(err => {
        toast?.current?.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Devir bilgisi getirme başarısız.'
        });
        console.log(err);
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    searchSaleInfo(pageState);
  };

  const onFilter = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const onSort = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const technicalDetailModalModalShow = (e) => {
    setTechnicalDetailModal(true);
    saleInfoList.forEach(s => {
      if (s.id == e.currentTarget.id) {
        setSelectedIslem(s);
      }
    });
  };

  const technicalDetailModalModalHide = () => {
    setTechnicalDetailModal(false);
  };

  const exportExcelData = () => {
    var dateControl = false;
    if (selectedTimeBegin != null && selectedTimeEnd != null) {
      if (selectedTimeEnd.getTime() - selectedTimeBegin.getTime() <= 93 * 24 * 3600000) {
        if (selectedTimeBegin.getTime() <= selectedTimeEnd.getTime()) {
          dateControl = true;
        } else {
          toast.current.show({
            severity: 'warn',
            summary: 'Uyarı',
            detail: 'Başlangıç tarihi bitiş tarihinden önce olmalıdır.'
          });
        }
      } else {
        toast.current.show({
          severity: 'warn',
          summary: 'Uyarı',
          detail: 'Excel almak için tarih aralığı üç aydan fazla olmamalıdır.'
        });
      }
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Excel almak için tarih alanları dolu olmalıdır.'
      });
    }
    if (dateControl) {
      setExportExcelDisabled(true);

      searchCriteria.serialNo = serialNo;
      searchCriteria.barcode = barcode;

      if (selectedTimeBegin != null) {
        searchCriteria.saleDateStart = selectedTimeBegin.toLocaleDateString("tr-TR") + " " + selectedTimeBegin.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.saleDateStart = null;
      }

      if (selectedTimeEnd != null) {
        searchCriteria.saleDateEnd = selectedTimeEnd.toLocaleDateString("tr-TR") + " " + selectedTimeEnd.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.saleDateEnd = null;
      }

      searchCriteria.resellerCode = selectedResellerCode;
      searchCriteria.transferredResellerCode = resellerCode;
      productSalesManagementService.mySerialTransfersEager(searchCriteria).then(res => {
        console.log("mySerialTransfersEager", res);
        import('xlsx').then(xlsx => {
          var excelList = [];
          res.forEach(a => {
            excelList.push({
              "İşlem Tarihi": a.invoiceDateString,
              "Devir Alan Bayi Kod": a.reseller?.code,
              "Devir Alan Bayi Ad": a.reseller?.name,
              "Devir Alınan Bayi Kod": a.transferredByReseller?.code,
              "Devir Alınan Bayi Ad": a.transferredByReseller?.name,
              "Aksesuar Barkod": a.productSaleInfoDetailList[0].barcode,
              "Aksesuar Seri Numara": a.productSaleInfoDetailList[0].serialNo,
              "Aksesuar Marka": a.productSaleInfoDetailList[0].productSerialNo.model.brand.name,
              "Aksesuar İsim": a.productSaleInfoDetailList[0].productSerialNo.model.name
            })
          });
          const worksheet = xlsx.utils.json_to_sheet(excelList);
          var wscols = [
            {width: 20},
            {width: 20},
            {width: 30},
            {width: 20},
            {width: 30},
            {width: 20},
            {width: 20},
            {width: 20},
            {width: 20},
            {width: 30}
          ];
          worksheet["!cols"] = wscols;
          const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
          const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
          exportExcelFile(excelBuffer, 'Benden Devir Alınan Seri Numaralar');
        });
      }).catch(err => {
        toast?.current?.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'İşlem bilgisi getirme başarısız.'
        });
        console.log(err);
      }).finally(() => {
        setExportExcelDisabled(false);
      });
    }
  };

  const exportExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const invoiceDateTemplate = (data) => {
    return responsiveColumn("İşlem Tarihi", data?.invoiceDateString)
  };

  const serialNoTemplate = (data) => {
    return responsiveColumn("Aksesuar Seri Numara", data?.serialNo)
  };

  const barcodeTemplate = (data) => {
    return responsiveColumn("Aksesuar Barkod", data?.barcode)
  };

  const resellerCodeTemplate = (data) => {
    return responsiveColumn("Devir Alınan Bayi", data?.reseller?.code + " - " + data?.reseller?.name)
  };

  const accessoryCampaignSerialTitleButtonTemplate = (data) => {
    return (
      <div className="p-d-flex p-justify-even">
        <Button id={data.id} type="button" className="p-button-sm p-button-info" label="Detay"
                onClick={technicalDetailModalModalShow}/>
      </div>
    );
  };

  const resellerCodeFilter = <InputText value={selectedResellerCode}
                                        onChange={(e) => setSelectedResellerCode(e.target.value)}
                                        className="p-column-filter"/>;

  const serialNoFilter = <InputText value={serialNo}
                                    onChange={(e) => setSerialNo(e.target.value)}
                                    className="p-column-filter"/>;

  const barcodeFilter = <InputText value={barcode}
                                   onChange={(e) => setBarcode(e.target.value)}
                                   className="p-column-filter"/>;

  const header =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>Benden Devir Alınan Seri Numaralar</div>
        <div>
          <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
                  disabled={exportExcelDisabled}
                  onClick={exportExcelData} />
        </div>
      </div>
    </div>;

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-12 p-md-2 p-fluid">
            <label htmlFor="baslangic">İşlem Tarihi Aralığı</label>
            <Calendar id="baslangic" showIcon dateFormat={'dd.mm.yy'} style={{marginTop: 5}}
                      value={selectedTimeBegin} showTime showButtonBar
                      onChange={(e) => setSelectedTimeBegin(e.value)}
                      placeholder="Tarih Başlangıç"/>
          </div>
          <div className="p-col-12 p-md-2 p-fluid"><br className="small_hidden"/>
            <Calendar id="bitis" showIcon dateFormat={'dd.mm.yy'} style={{marginTop: 5}}
                      value={selectedTimeEnd} showTime showButtonBar
                      onChange={(e) => setSelectedTimeEnd(e.value)}
                      placeholder="Tarih Bitiş"/>
          </div>
          <div className="p-col-12">
            <ResponsiveDataTableWithoutRowClass
              lazy
              header={header}
              value={saleInfoList}
              paginationConfig={paginationConfig}
              onActivePageChange={onActivePageChange}
              onFilter={onFilter}
              filters={searchCriteria.filters}
              onSort={onSort}
              sortField={searchCriteria.sortField}
              sortOrder={searchCriteria.sortOrder}
              loading={loading}
              style={{fontSize: '13px'}}
            >
              <Column field="invoiceDateString" body={invoiceDateTemplate} header="Aksesuar İşlem Tarihi" sortable/>
              <Column field="serialNo" body={serialNoTemplate} header="Aksesuar Seri Numara" filter={true} filterElement={serialNoFilter}
                      filterMatchMode={"contains"} sortable/>
              <Column field="barcode" body={barcodeTemplate} header="Barkod" filter={true} filterElement={barcodeFilter}
                      filterMatchMode={"contains"} sortable/>
              <Column field="resellerCode" body={resellerCodeTemplate} header="Devir Alan Bayi" filter={true}
                      headerStyle={{width: "30%"}} filterElement={resellerCodeFilter}
                      filterMatchMode={"contains"} sortable/>
              <Column body={accessoryCampaignSerialTitleButtonTemplate}
                      headerStyle={{width: "7rem", textAlign: "center"}}
                      bodyStyle={{textAlign: 'center', overflow: 'visible', justifyContent: 'center'}}/>
            </ResponsiveDataTableWithoutRowClass>
          </div>
        </div>
        <Dialog header="İşlem Detay" visible={technicalDetailModal} modal style={{width: '700px'}}
                onHide={technicalDetailModalModalHide}>
          <div className="datatable-responsive-demo">
            <div className="p-grid">
              <div className="p-field p-col-12">
                <p className="marginParagraph"><b>İşlem Tarihi:</b> {selectedIslem?.invoiceDateString}</p>
                <p className="marginParagraph">
                  <b>Devir Alan Bayi:</b> {selectedIslem?.reseller?.code} - {selectedIslem?.reseller?.name}</p>
                <p className="marginParagraph">
                  <b>Devir Alınan Bayi:</b> {selectedIslem?.transferredByReseller?.code} - {selectedIslem?.transferredByReseller?.name}</p>
                <p className="marginParagraph"><b>Aksesuar
                  Barkod:</b> {selectedIslem?.productSaleInfoDetailList[0].barcode}</p>
                <p className="marginParagraph"><b>Aksesuar Seri
                  Numara:</b> {selectedIslem?.productSaleInfoDetailList[0].serialNo}</p>
                <p className="marginParagraph"><b>Aksesuar Marka:</b> {selectedIslem?.productSaleInfoDetailList[0].productSerialNo.model.brand.name}</p>
                <p className="marginParagraph"><b>Aksesuar İsim:</b> {selectedIslem?.productSaleInfoDetailList[0].productSerialNo.model.name}</p>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  )

};

export default SerialTransfersFromMe;
