import ProductService from "./httpService";
import React, {useEffect, useRef, useState} from "react";
import {handleError} from "../../service/HandleErrorService";
import {Toast} from "primereact/toast";
import {Column} from "primereact/column";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Button} from "primereact/button";
import {useHistory} from "react-router-dom";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import classNames from "classnames";
import {InputNumber} from "primereact/inputnumber";
import {InputTextarea} from "primereact/inputtextarea";
import {Dropdown} from "primereact/dropdown";
import {FileUpload} from "primereact/fileupload";
import {ProgressBar} from "primereact/progressbar";
import {Tag} from "primereact/tag";
import CanSellService from "../canSell/httpService";
import CategoryService from "../categorymanagement/httpService";
import {Calendar} from "primereact/calendar";
import moment from "moment";
import {Galleria} from "primereact/galleria";

const ProductManagement = () => {
  const [loading,setLoading]=useState(false);
  const [count, setCount] = useState(0);
  const toast = useRef(null);
  const history = useHistory();
  const productService = new ProductService();
  const [allProduct, setAllProduct] = useState([]);
  const [createModel, setCreateModel] = useState();
  const [createModelDialog, setCreateModelDialog] = useState(false);
  const [createModelHeader, setCreateModelHeader] = useState(false);
  const [errorFields, setErrorFields] = useState({});
  const [modelImageModal, setModelImageModal] = useState(false);
  const fileUploadRef = useRef(null);
  const [totalSize, setTotalSize] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [searchRequest, setSearchRequest] = useState({});
  const canSellService = new CanSellService();
  const [excelList, setExcelList] = useState([]);
  const [approveTypes, setApproveTypes] = useState([]);
  const categoryService = new CategoryService();
  const [allApproveTypes, setAllApproveTypes] = useState([]);
  const [controls, setControls] = useState({
    categorySame: true,
    subCategorySame: true,
    brandSame: true,
    nameSame: true,
    barcodeName: true,
    supplyStatusSame: true,
    originSame: true,
    eolSame: true,


  });
  const [waitingDeletePictureList, setWaitingDeletePictureList] = useState([]);
  const [waitingPictureList, setWaitingPictureList] = useState([]);
  const [deletePictureList, setDeletePictureList] = useState([]);
  const [pictureList, setPictureList] = useState([]);
  const [updatedModel, setUpdatedModel] = useState();
  const [currentModel, setCurrentModel] = useState();
  const [allCategories, setAllCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState();
  const [selectCategory, setSelectCategory] = useState();
  const [checkDisty, setCheckDisty] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  useEffect(() => {

    if (JSON.parse(localStorage.getItem('user')).user?.disty) {
      setCheckDisty(true);
    }
    getAll({...paginationConfig, activePage: 0});
    getAllCategories();
    productService.getapproveTypes().then(res => {
      setAllApproveTypes(res)
    })
  }, [])
  const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  const itemTemplate2 = (item) => {
    return <img src={item?.baseUrl} style={{width: 200, height: 200}}/>;
  }

  const thumbnailTemplate2 = (item) => {
    return <img src={item?.baseUrl}/>;
  }

  const getAllCategories = () => {
    categoryService.getAll().then(setAllCategories)
  }
  const getAll = (pageState) => {


    productService.getAllSupply(pageState.activePage, pageState.itemsPerPage, searchRequest).then(res => {
        setAllProduct(res?.content)
        productService.getAllSupplyList(searchRequest).then(res2 => {
          let _excelList = [];
          res2?.forEach(value => {
            let _excelModel = {};
            _excelModel['Kategori'] = value?.subCategory.category.name;
            _excelModel['Alt Kategori'] = value?.subCategory.name;
            _excelModel['Marka'] = value?.brand.name;
            _excelModel['Ürün Adı'] = value?.name;
            _excelModel['Barkod'] = value?.barcode;
            _excelModel['Ürün Açıklaması'] = value?.description;
            _excelModel['Tedarik Durumu'] = value?.supplyStatus;
            _excelModel['Menşei'] = value?.origin;
            if (value.eol) {
              _excelModel['Eol'] = "EVET";
            } else if (value.eol === undefined) {
              _excelModel['Eol'] = "";
            } else {
              _excelModel['Eol'] = "HAYIR";

            }
            _excelModel['Onay Durumu'] = value.approveType;


            _excelList.push(_excelModel);
          })
          setExcelList(_excelList)
        }).catch(err => handleError(err, toast))
        pageState.totalRecords = res.totalElements;
        pageState.first = pageState.itemsPerPage * pageState.activePage;
        setPaginationConfig({
          ...pageState,
        });
      }
    )
      .catch(err => handleError(err, toast))


  }

  const exportExcel = () => {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(excelList);
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      saveAsExcelFile(excelBuffer, 'products');
    });
  }

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, "Ürün Listesi" + EXCEL_EXTENSION);
    });
  }

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    getAll(pageState);
  };

  const eolTemplate = (e) => {
    if (e.eol) {
      return <div>Evet</div>
    } else if (e.eol === undefined) {
      return <div>Boş</div>
    } else
      return <div>Hayır</div>


  }


  const actions = (rowData) => {
    return (<Button
        icon="pi pi-pencil"
        className="p-button-rounded p-button-warning p-mr-2"
        label="Düzenle"


        onClick={e => history.push("/ProductDetail/" + rowData.id)}
      />
    )
  }
  const creatModelShow = () => {
    setCreateModelHeader("Ürün Oluşturma");

    setCreateModelDialog(true);

  };

  const hideDialog = () => {

    setCreateModelDialog(false);
    setErrorFields({});
  }


  const upsertModels = () => {
    let _createModel = {...createModel};
    let _selectedFiles = [...selectedFiles];

    setLoading(true);
    productService.upsert(_createModel).then(res => {
      setCreateModelDialog(false);
      toast.current.show({
        severity: 'success',
        summary: 'Başarılı İşlem',
        detail: 'Ürün Onaya Gönderildi',
        life: 3000,

      });

      let formData = new FormData();
      _selectedFiles.forEach(value => {
        formData.append("file", value);
      })
      productService.upsertPhoto(formData,res.id,false).then(res2 => {

      }).catch(err => handleError(err, toast));

      setCreateModel(null);

      setErrorFields({});
      setLoading(false);
      getAll(paginationConfig)
    }).catch(err =>{
     setErrorFields(handleError(err, toast));
    setLoading(false);

    });
  }


  const isInvalid = (bindingField1, bindingField2) => {
    if (errorFields[`${bindingField1}`]) {

      return " p-invalid";
    }
    if (errorFields[`${bindingField2}`]) {
      return " p-invalid";
    }
    return "";
  }
  const brandImageModalHide = () => {
    setModelImageModal(false);
  };

  const onTemplateSelect = (e) => {

    let _selectedFiles = [...selectedFiles];
    let _totalSize = totalSize;
    Array.from(e.files)?.forEach(file => {
      _totalSize += file.size;

      _selectedFiles.push(file)
    });


    setSelectedFiles(_selectedFiles);
    setTotalSize(_totalSize);
  }

  const changeModel = (e, name) => {

    let _errorFields = {...errorFields};
    _errorFields[name] = false;
    if (e.target.value === "") {
      _errorFields[name] = true;
    }
    setErrorFields(_errorFields)
    let _createModel = {...createModel};
    _createModel[name] = e.target.value;
    setCreateModel(_createModel)

    if (name === "category") {
      productService.getSubCategoryByCategory(allCategories.find(value => value.name === e.target.value))
        .then(res => {
          setAllSubCategories(res);
        });

    }
  }

  const emptyTemplate = () => {
    return (
      <div className="p-d-flex p-ai-center p-dir-col">
        <i className="pi pi-image p-mt-3 p-p-5" style={{
          'fontSize': '5em',
          borderRadius: '50%',
          backgroundColor: 'var(--surface-b)',
          color: 'var(--surface-d)'
        }}/>
        <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}}
              className="p-my-5">Resimi Sürükleyiniz</span>
      </div>
    )
  }

  const onTemplateClear = () => {
    setTotalSize(0);
    setSelectedFiles([])
  }
  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  }

  const approveTemplate = (rowData) => {
    let _type = allApproveTypes.find(value => value.key === rowData.lastApproveType);
    return <div>
      <p>
        <p style={{fontSize: 13, paddingLeft: 30}}>{_type?.value}</p>
        {_type?.key === "WAITING" || _type?.key === "REJECTED" ?
          <Button style={{paddingRight: 20}} type="button"
                  className="p-button-info p-button-text"
                  label={_type?.key === "WAITING" ? "Onay Bekleyen Değişiklikler" : "Onaylanmayan Değişiklikler"}
                  onClick={e => {

                    setDeletePictureList(rowData?.currentUpdateModel?.pictures?.filter(value => value?.pictureType === "WEB" && value?.pictureCrudType === "NOTWANTDELETE"));
                    setPictureList(rowData?.currentUpdateModel?.pictures?.filter(value => value?.pictureType === "WEB" && value?.pictureCrudType === "NOTWANTINSERT"));

                    setWaitingDeletePictureList(rowData?.currentUpdateModel?.pictures?.filter(value => value?.pictureType === "WEB" && value?.pictureCrudType === "WANTDELETE"));
                    setWaitingPictureList(rowData?.currentUpdateModel?.pictures?.filter(value => value?.pictureType === "WEB" && value?.pictureCrudType === "WANTINSERT"));

                    let _controls = {};
                    rowData?.currentUpdateModel?.subCategory?.category?.name !== rowData?.subCategory?.category?.name ? _controls.categorySame = false : _controls.categorySame = true;


                    rowData?.currentUpdateModel?.subCategory?.name !== rowData?.subCategory?.name ? _controls.subCategorySame = false : _controls.subCategorySame = true;


                    rowData?.currentUpdateModel?.brand?.name !== rowData?.brand?.name ? _controls.brandSame = false : _controls.brandSame = true;


                    rowData?.currentUpdateModel?.name !== rowData?.name ? _controls.name = false : _controls.name = true;
                    rowData?.currentUpdateModel?.description !== rowData?.description ? _controls.description = false : _controls.description = true;


                    rowData?.currentUpdateModel?.barcode !== rowData?.barcode ? _controls.barcodeName = false : _controls.barcodeName = true;

                    rowData?.currentUpdateModel?.origin !== rowData?.origin ? _controls.originSame = false : _controls.originSame = true;


                    rowData?.currentUpdateModel?.supplyStatus !== rowData?.supplyStatus ? _controls.supplyStatusSame = false : _controls.supplyStatusSame = true
                    rowData?.currentUpdateModel?.eol !== rowData?.eol ? _controls.eolSame = false : _controls.eolSame = true

                    setControls(_controls)


                    setUpdatedModel(rowData?.currentUpdateModel)
                    setCurrentModel(rowData)
                    setTimeout(() => {
                      setDialog(true)

                    }, 500)
                  }}
          /> : ""}
      </p>
    </div>
  }
  const headerTemplate = (options) => {
    const {className, chooseButton, cancelButton} = options;
    const value = totalSize / 10000;
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

    return (
      <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
        {chooseButton}

        {cancelButton}
        <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 1 MB`} style={{
          width: '300px',
          height: '20px',
          marginLeft: 'auto'
        }}/>
      </div>
    );
  }

  const itemTemplate = (file, props) => {
    return (
      <div className="p-d-flex p-ai-center p-flex-wrap">
        <div className="p-d-flex p-ai-center" style={{width: '40%'}}>
          <img alt={file.name} role="presentation" src={file.objectURL} width={100}/>
          <span className="p-d-flex p-dir-col p-text-left p-ml-3">
                        {file.name}
            <small>{new Date().toLocaleDateString()}</small>
                    </span>
        </div>
        <Tag value={props.formatSize} severity="warning" className="p-px-3 p-py-2"/>
        <Button type="button" icon="pi pi-times"
                className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
                onClick={() => onTemplateRemove(file, props.onRemove)}/>
      </div>
    )
  }

  const chooseOptions = {
    icon: 'pi pi-fw pi-images',
    iconOnly: true,
    className: 'custom-choose-btn p-button-rounded p-button-outlined'
  };
  const uploadOptions = {
    icon: 'pi pi-fw pi-cloud-upload',
    iconOnly: true,
    className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'
  };
  const cancelOptions = {
    icon: 'pi pi-fw pi-times',
    iconOnly: true,
    className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'
  };

  const modelNameTemplate = (e) => {

    return <div><InputTextarea autoResize style={{backgroundColor: 'lawngreen', fontSize: 10, width: '100%'}} disabled
                               value={e?.name}/></div>
  }

  const headersTemplate = (
    <div className="card">
      <div className="p-formgroup-inline">
        <div className="p-field">
          <label htmlFor="category" className="p-sr-only">Kategori</label>
          <InputText id="category" value={searchRequest.category} type="text" placeholder="Kategori"
                     onChange={e => {
                       let _searchRequest = {...searchRequest};
                       _searchRequest.category = e.target.value;
                       setSearchRequest(_searchRequest);
                     }

                     }/>
        </div>
        <div className="p-field">
          <label htmlFor="subCategory" className="p-sr-only">Alt Kategori</label>
          <InputText id="subCategory" value={searchRequest.subCategory} type="text" placeholder="Alt Kategori"
                     onChange={e => {
                       let _searchRequest = {...searchRequest};
                       _searchRequest.subCategory = e.target.value;
                       setSearchRequest(_searchRequest);
                     }

                     }/>
        </div>


        <div className="p-field">
          <label htmlFor="name" className="p-sr-only">Ürün</label>
          <InputText id="name" value={searchRequest.modelName} type="text" placeholder="Ürün"
                     onChange={e => {
                       let _searchRequest = {...searchRequest};
                       _searchRequest.modelName = e.target.value;
                       setSearchRequest(_searchRequest);
                     }

                     }/>
        </div>

        <div className="p-field">
          <label htmlFor="barcode" className="p-sr-only">Barkod</label>
          <InputText id="barcode" value={searchRequest.barcode} type="text" placeholder="Barkod"
                     onChange={e => {
                       let _searchRequest = {...searchRequest};
                       _searchRequest.barcode = e.target.value;
                       setSearchRequest(_searchRequest);
                     }

                     }/>
        </div>

        <div className="p-field">
          <label htmlFor="supply" className="p-sr-only">Tedarikçi Durumu</label>
          <Dropdown id="supply" value={searchRequest.supplyStatus} options={["AÇIK", "KAPALI"]}
                    placeholder="Tedarikçi Durumu"
                    showClear
                    onChange={e => {
                      let _searchRequest = {...searchRequest};
                      _searchRequest.supplyStatus = e.target.value;
                      setSearchRequest(_searchRequest);
                    }

                    }/>
        </div>

        <div className="p-field">
          <label htmlFor="origin" className="p-sr-only">Menşei</label>
          <InputText id="origin" value={searchRequest.origin} type="text" placeholder="Menşei"
                     onChange={e => {
                       let _searchRequest = {...searchRequest};
                       _searchRequest.origin = e.target.value;
                       setSearchRequest(_searchRequest);
                     }

                     }/>
        </div>

        <div className="p-field">
          <label htmlFor="supply" className="p-sr-only">EOL</label>
          <Dropdown id="supply" value={searchRequest.eolValue}
                    options={["EVET", "HAYIR"]}
                    placeholder="EOL"
                    showClear
                    onChange={e => {
                      let _searchRequest = {...searchRequest};
                      if (e.target.value === "EVET") {
                        _searchRequest.eol = true;
                      } else if (e.target.value === "HAYIR") {
                        _searchRequest.eol = false;
                      } else {
                        _searchRequest.eol = undefined;

                      }
                      _searchRequest.eolValue = e.target.value;

                      setSearchRequest(_searchRequest);

                    }

                    }/>
        </div>

        <div className="p-field">
          <label htmlFor="supply" className="p-sr-only">Onay Durumu</label>
          <Dropdown id="supply" value={searchRequest.lastApproveType} options={allApproveTypes}
                    optionValue="key" optionLabel="value"
                    placeholder="Onay Durumu "
                    showClear
                    onChange={e => {
                      let _searchRequest = {...searchRequest};
                      _searchRequest.lastApproveType = e.target.value;
                      setSearchRequest(_searchRequest);
                    }

                    }/>
        </div>

        <Button type="button" label="Ara"
                onClick={e => getAll({activePage: 0, itemsPerPage: paginationConfig.itemsPerPage})}/>


      </div>
      <div>

      </div>
    </div>
  )
  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <br/>
        <br/>
        <div className="p-d-flex p-ai-center export-buttons">
          <Button onClick={creatModelShow} icon="pi pi-plus-circle" label="Yeni Ürün Ekle"
                  className="p-button-success p-mr-2 p-mb-2"/>
          <Button style={{marginBottom: 5}} label="Ürün Listesini İndir" type="button" icon="pi pi-file-excel"
                  onClick={exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLS"/>

        </div>
        <ResponsiveDataTable value={allProduct}
                             header={headersTemplate}
                             paginationConfig={paginationConfig}
                             onActivePageChange={onActivePageChange}
                             emptyMessage="Ürün Bulunamadı.">

          <Column field="subCategory.category.name" header="Kategori"/>
          <Column field="subCategory.name" header="Alt Kategori"/>
          <Column field="brand.name" header="Marka"/>
          <Column field="name" body={modelNameTemplate} header="Ürün İsmi"/>
          <Column style={{width: 130}} field="barcode" header="Barkod"/>
          <Column field="supplyStatus" header="Tedarik Durumu"/>
          <Column field="origin" header="Menşei"/>
          <Column body={e => eolTemplate(e)} header="Eol"/>
          <Column field="lastApproveType" body={approveTemplate} header="Son Güncelleme İsteği"/>
          <Column body={e => actions(e)}/>

        </ResponsiveDataTable>
      </div>
      <Dialog header={createModelHeader} visible={createModelDialog} style={{width: 650}}
              onHide={hideDialog}>
  <span className="p-float-label marginInput">
          <Dropdown id="category" value={createModel?.category}
                    options={allCategories}
                    optionValue="name"
                    optionLabel="name"
                    onChange={e => changeModel(e, 'category')}
                    className={isInvalid('category')}
                    style={{width: 300}}
          />
                     <label htmlFor="category">Kategori</label>
        </span>

        <span className="p-float-label marginInput">
          <Dropdown id="subCategory" value={createModel?.subCategory} options={allSubCategories}
                    optionValue="name"
                    optionLabel="name"
                    onChange={e => changeModel(e, 'subCategory')}
                    className={isInvalid('subCategory')}
                    style={{width: 300}}
          />
                     <label htmlFor="subCategory">Alt Kategori</label>
        </span>
        <span className="p-float-label marginInput">
          <InputText id="brand" value={createModel?.brand}
                     onChange={e => changeModel(e, 'brand')}
                     className={isInvalid('brand')}

                     style={{width: 300}}
          />
                     <label htmlFor="brand">Marka</label>
        </span>
        <span className="p-float-label marginInput">
          <InputText id="name" value={createModel?.name}
                     onChange={e => changeModel(e, 'name')}
                     style={{width: 300}}
                     className={isInvalid('name')}

          />
                     <label htmlFor="voucherCode">Ürün İsmi</label>
        </span>


        <span className="p-float-label marginInput">
          <InputTextarea autoResize id="description" value={createModel?.description}
                         onChange={e => changeModel(e, 'description')}
                         style={{width: 300}}
                         className={isInvalid('description')}

          />
                     <label htmlFor="description">Ürün Açıklaması</label>
        </span>


        <span className="p-float-label marginInput">
          <InputText id="barcode" value={createModel?.barcode}
                     onChange={e => changeModel(e, 'barcode')}
                     style={{width: 300}}
                     className={isInvalid('barcode')}

          />
                     <label htmlFor="barcode">Ürün Barkodu</label>
        </span>
        <span className="p-float-label marginInput">
          <InputText id="origin" value={createModel?.origin}
                     onChange={e => changeModel(e, 'origin')}
                     className={isInvalid('origin')}

                     style={{width: 300}}
          />
                     <label htmlFor="origin">Menşei</label>
        </span>
        <span className="p-float-label marginInput">
        <Dropdown value={createModel?.eolValue}
                  showClear

                  style={{width: 300}}
                  options={["EVET", "HAYIR"]}
                  onChange={e => {

                    let _createModel = {...createModel};
                    _createModel.eolValue = e.target.value;
                    if (e.target.value === "EVET") {
                      _createModel.eol = true;
                    } else if (e.target.value === "HAYIR") {
                      _createModel.eol = false;
                    }
                    setCreateModel(_createModel)
                  }}
        />
           <label htmlFor="origin">EOL</label>
</span>
        <span className="p-float-label marginInput">
        <Dropdown value={createModel?.supplyStatus}
                  className={isInvalid('supplyStatus')}
                  id="supplyStatus"
                  style={{width: 300}}
                  options={["AÇIK", "KAPALI"]}
                  onChange={e => {

                    let _errorFields = {...errorFields};
                    _errorFields[e.target.id] = false;
                    if (e.target.value === "") {
                      _errorFields[e.target.id] = true;
                    }
                    setErrorFields(_errorFields)
                    let _createModel = {...createModel};


                    _createModel.supplyStatus = e.target.value;

                    setCreateModel(_createModel)
                  }}
        />
            <label htmlFor="origin">Tedarik Durumu</label>
</span>
        <span className="p-float-label marginInput">

         <h5>Resimler</h5>
                <FileUpload ref={fileUploadRef} name="demo[]"
                            url="https://primefaces.org/primereact/showcase/upload.php" multiple accept="image/*"
                            maxFileSize={1000000}
                            onSelect={onTemplateSelect} onError={onTemplateClear}
                            onClear={onTemplateClear}
                           itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                            chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions}/>


        </span>

        <span className="p-float-label marginInput">

            <Calendar id="startDate"

                      value={moment(createModel?.startDate).format("DD-MM-YYYY")}
                      onChange={e => {
                        let _createModel = {...createModel};
                        _createModel.startDate = e.target.value;
                        setCreateModel(_createModel)
                      }}

            />
                   <label htmlFor="startDate">Ürün Fiyatlarının Geçerli Olacağı Tarih</label>
        </span>

        <span className="p-float-label marginInput">


          <InputText id="taxRatio" type="number" step="0,1" value={createModel?.taxRatio}
                     className={isInvalid('taxRatio')}

                     onChange={e => {
                       let _errorFields = {...errorFields};
                       _errorFields[e.target.id] = false;
                       if (e.target.value === "") {
                         _errorFields[e.target.id] = true;
                       }
                       setErrorFields(_errorFields)
                       let _createModel = {...createModel};
                       _createModel.taxRatio = parseFloat(e.target.value);
                       setCreateModel(_createModel)
                     }}/>
  <label htmlFor="taxRatio">Kdv Oranı</label>
      </span>


        <span className="p-float-label marginInput">


            <InputText id="distyPrice" type="number" step="0,1" value={createModel?.distyPrice}
                       className={isInvalid('distyPrice')}

                       onChange={e => {
                         let _errorFields = {...errorFields};
                         _errorFields[e.target.id] = false;
                         if (e.target.value === "") {
                           _errorFields[e.target.id] = true;
                         }
                         setErrorFields(_errorFields)
                         let _createModel = {...createModel};
                         _createModel.distyPrice = parseFloat(e.target.value);
                         setCreateModel(_createModel)
                       }}/>

           <label htmlFor="distyPrice">Distribütör Alış fiyatı</label>

      </span>

        <span className="p-float-label marginInput">


            <InputText id="recommendedResellerPrice" type="number" step="0,1"
                       value={createModel?.recommendedResellerPrice}
                       className={isInvalid('recommendedResellerPrice')}

                       onChange={e => {
                         let _errorFields = {...errorFields};
                         _errorFields[e.target.id] = false;
                         if (e.target.value === "") {
                           _errorFields[e.target.id] = true;
                         }
                         setErrorFields(_errorFields)
                         let _createModel = {...createModel};
                         _createModel.recommendedResellerPrice = parseFloat(e.target.value);
                         setCreateModel(_createModel)
                       }}/>

           <label htmlFor="recommendedResellerPrice">Önerilen Bayi Alış fiyatı</label>

      </span>

        <span className="p-float-label marginInput">


            <InputText id="recommendedSellPrice" type="number" step="0,1" value={createModel?.recommendedSellPrice}
                       className={isInvalid('recommendedSellPrice')}

                       onChange={e => {
                         let _errorFields = {...errorFields};
                         _errorFields[e.target.id] = false;
                         if (e.target.value === "") {
                           _errorFields[e.target.id] = true;
                         }
                         setErrorFields(_errorFields)
                         let _createModel = {...createModel};
                         _createModel.recommendedSellPrice = parseFloat(e.target.value);
                         setCreateModel(_createModel)
                       }}/>

           <label htmlFor="recommendedSellPrice">Önerilen Satış fiyatı</label>

      </span>

        <span className="p-float-label marginInput">


            <InputText id="recommendedEdmPrice" type="number" step="0,1" value={createModel?.recommendedEdmPrice}
                       className={isInvalid('recommendedEdmPrice')}

                       onChange={e => {
                         let _errorFields = {...errorFields};
                         _errorFields[e.target.id] = false;
                         if (e.target.value === "") {
                           _errorFields[e.target.id] = true;
                         }
                         setErrorFields(_errorFields)
                         let _createModel = {...createModel};
                         _createModel.recommendedEdmPrice = parseFloat(e.target.value);
                         setCreateModel(_createModel)
                       }}/>

           <label htmlFor="recommendedEdmPrice">Önerilen EDM Alış Fiyatı</label>

      </span>
        <span className="p-float-label marginInput">


            {checkDisty ? <><InputText id="resellerPrice" type="number" step="0,1" value={createModel?.resellerPrice}
                                       className={isInvalid('resellerPrice')}

                                       onChange={e => {
                                         let _errorFields = {...errorFields};
                                         _errorFields[e.target.id] = false;
                                         if (e.target.value === "") {
                                           _errorFields[e.target.id] = true;
                                         }
                                         setErrorFields(_errorFields)
                                         let _createModel = {...createModel};
                                         _createModel.resellerPrice = parseFloat(e.target.value);
                                         setCreateModel(_createModel)
                                       }}/>

              <label htmlFor="resellerPrice">Bayi Alış Fiyatı</label></> : ""}

              </span>


        <Button loading={loading} disabled={loading} onClick={e => upsertModels(e)} style={{width: 280, marginTop: 20}}

                label="Kaydet" className="p-mr-2 p-mb-2"/>

      </Dialog>

      <Dialog visible={dialog} modal style={{width: '500px'}}
              onHide={e => setDialog(false)}>

        {currentModel?.approveType === 'APPROVED' && currentModel?.lastApproveType === 'REJECTED' ?
          <div className="datatable-responsive-demo">
            <h5>Ürün Değişiklikleri</h5>
            <div className="p-grid">

              <div className="p-field p-col-12">

                {controls.categorySame ? <p className="marginParagraph">
                  <b>Kategori: </b> {currentModel?.subCategory?.category?.name}-->{updatedModel?.subCategory?.category?.name}
                </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                  <b>Kategori: </b> {currentModel?.subCategory?.category?.name}-->{updatedModel?.subCategory?.category?.name}
                </p>}
                {controls.categorySame ? <p className="marginParagraph">
                  <b>Alt Kategori: </b> {currentModel?.subCategory?.name}-->{updatedModel?.subCategory?.name}
                </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                  <b>Alt Kategori: </b> {currentModel?.subCategory?.name}-->{updatedModel?.subCategory?.name}
                </p>}
                {controls.brandSame ? <p className="marginParagraph">
                  <b>Marka: </b> {currentModel?.brand?.name}-->{updatedModel?.brand?.name}
                </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                  <b>Marka: </b> {currentModel?.brand?.name}-->{updatedModel?.brand?.name}
                </p>}

                {controls.subCategorySame ? <p className="marginParagraph">
                  <b>Ürün İsmi: </b> {currentModel?.name}-->{updatedModel?.name}
                </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                  <b>Ürün İsmi: </b> {currentModel?.name}-->{updatedModel?.name}
                </p>}
                {controls.description ? <p className="marginParagraph">
                  <b>Ürün Açıklaması: </b> {currentModel?.description}-->{updatedModel?.description}
                </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                  <b>Ürün Açıklaması: </b> {currentModel?.description}-->{updatedModel?.description}
                </p>}
                {controls.barcodeName ? <p className="marginParagraph">
                  <b>Barkod: </b> {currentModel?.barcode}-->{updatedModel?.barcode}
                </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                  <b>Barkod: </b> {currentModel?.barcode}-->{updatedModel?.barcode}
                </p>}

                {controls.supplyStatusSame ? <p className="marginParagraph">
                  <b>Tedarikçi Durumu: </b> {currentModel?.supplyStatus}-->{updatedModel?.supplyStatus}
                </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                  <b>Tedarikçi Durumu: </b> {currentModel?.supplyStatus}-->{updatedModel?.supplyStatus}
                </p>}

                {controls.originSame ? <p className="marginParagraph">
                  <b>Menşei: </b> {currentModel?.origin}-->{updatedModel?.origin}
                </p> : <p className="marginParagraph" style={{backgroundColor: "lightskyblue"}}>
                  <b>Menşei: </b> {currentModel?.origin}-->{updatedModel?.origin}
                </p>}

                {controls.eolSame ? <p className="marginParagraph">
                  <b>EOL: </b> {currentModel?.eol ? "EVET" : "HAYIR"}-->{updatedModel?.eol ? "EVET" : "HAYIR"}
                </p> : <p className="marginParagraph" style={{backgroundColor: "lightskyblue"}}>
                  <b>EOL: </b> {currentModel?.eol ? "EVET" : "HAYIR"}-->{updatedModel?.eol ? "EVET" : "HAYIR"}
                </p>}


                {pictureList?.length > 0 ? <div><h5>Yeni Resimler</h5>
                  <Galleria value={pictureList} responsiveOptions={responsiveOptions} numVisible={1} circular
                            style={{maxWidth: '640px'}}
                            showItemNavigators showThumbnails={false} item={itemTemplate2}
                            thumbnail={thumbnailTemplate2}/>
                </div> : ""}
                <br/>
                <br/>

                {deletePictureList?.length > 0 ? <div><h5>Silmek İstenen Resimler</h5>
                  <Galleria value={deletePictureList} responsiveOptions={responsiveOptions} numVisible={1} circular
                            style={{maxWidth: '640px'}}
                            showItemNavigators showThumbnails={false} item={itemTemplate2}
                            thumbnail={thumbnailTemplate2}/>
                </div> : ""}

                <p className="marginParagraph">
                  <b>Açıklama: </b> {updatedModel?.approveDescription ? updatedModel?.approveDescription : "Yok."}
                </p>

              </div>
            </div>
          </div> : currentModel?.approveType === 'APPROVED' && currentModel?.lastApproveType === 'WAITING' ?

            <div className="datatable-responsive-demo">
              <h5>Ürün Değişiklikleri</h5>
              <div className="p-grid">

                <div className="p-field p-col-12">

                  {controls.categorySame ? <p className="marginParagraph">
                    <b>Kategori: </b> {currentModel?.subCategory?.category?.name}-->{updatedModel?.subCategory?.category?.name}
                  </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                    <b>Kategori: </b> {currentModel?.subCategory?.category?.name}-->{updatedModel?.subCategory?.category?.name}
                  </p>}
                  {controls.categorySame ? <p className="marginParagraph">
                    <b>Alt Kategori: </b> {currentModel?.subCategory?.name}-->{updatedModel?.subCategory?.name}
                  </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                    <b>Alt Kategori: </b> {currentModel?.subCategory?.name}-->{updatedModel?.subCategory?.name}
                  </p>}
                  {controls.brandSame ? <p className="marginParagraph">
                    <b>Marka: </b> {currentModel?.brand?.name}-->{updatedModel?.brand?.name}
                  </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                    <b>Marka: </b> {currentModel?.brand?.name}-->{updatedModel?.brand?.name}
                  </p>}

                  {controls.subCategorySame ? <p className="marginParagraph">
                    <b>Ürün İsmi: </b> {currentModel?.name}-->{updatedModel?.name}
                  </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                    <b>Ürün İsmi: </b> {currentModel?.name}-->{updatedModel?.name}
                  </p>}
                  {controls.description ? <p className="marginParagraph">
                    <b>Ürün Açıklaması: </b> {currentModel?.description}-->{updatedModel?.description}
                  </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                    <b>Ürün Açıklaması: </b> {currentModel?.description}-->{updatedModel?.description}
                  </p>}
                  {controls.barcodeName ? <p className="marginParagraph">
                    <b>Barkod: </b> {currentModel?.barcode}-->{updatedModel?.barcode}
                  </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                    <b>Barkod: </b> {currentModel?.barcode}-->{updatedModel?.barcode}
                  </p>}

                  {controls.supplyStatusSame ? <p className="marginParagraph">
                    <b>Tedarikçi Durumu: </b> {currentModel?.supplyStatus}-->{updatedModel?.supplyStatus}
                  </p> : <p style={{backgroundColor: "lightskyblue"}} className="marginParagraph">
                    <b>Tedarikçi Durumu: </b> {currentModel?.supplyStatus}-->{updatedModel?.supplyStatus}
                  </p>}

                  {controls.originSame ? <p className="marginParagraph">
                    <b>Menşei: </b> {currentModel?.origin}-->{updatedModel?.origin}
                  </p> : <p className="marginParagraph" style={{backgroundColor: "lightskyblue"}}>
                    <b>Menşei: </b> {currentModel?.origin}-->{updatedModel?.origin}
                  </p>}

                  {controls.eolSame ? <p className="marginParagraph">
                    <b>EOL: </b> {currentModel?.eol ? "EVET" : "HAYIR"}-->{updatedModel?.eol ? "EVET" : "HAYIR"}
                  </p> : <p className="marginParagraph" style={{backgroundColor: "lightskyblue"}}>
                    <b>EOL: </b> {currentModel?.eol ? "EVET" : "HAYIR"}-->{updatedModel?.eol ? "EVET" : "HAYIR"}
                  </p>}


                  {waitingPictureList?.length > 0 ? <div><h5>Yeni Resimler</h5>
                    <Galleria value={waitingPictureList} responsiveOptions={responsiveOptions} numVisible={1} circular
                              style={{maxWidth: '640px'}}
                              showItemNavigators showThumbnails={false} item={itemTemplate2}
                              thumbnail={thumbnailTemplate2}/>
                  </div> : ""}
                  <br/>
                  <br/>

                  {waitingDeletePictureList?.length > 0 ? <div><h5>Silmek İstenen Resimler</h5>
                    <Galleria value={waitingDeletePictureList} responsiveOptions={responsiveOptions} numVisible={1}
                              circular
                              style={{maxWidth: '640px'}}
                              showItemNavigators showThumbnails={false} item={itemTemplate2}
                              thumbnail={thumbnailTemplate2}/>
                  </div> : ""}

                  <p className="marginParagraph">
                    <b>Açıklama: </b> {updatedModel?.approveDescription ? updatedModel?.approveDescription : "Yok."}
                  </p>

                </div>
              </div>
            </div>
            : <div className="datatable-responsive-demo"><h5>Yeni Ürün Girişi</h5>
              <div className="p-grid">

                <div className="p-field p-col-12">
                  <p className="marginParagraph">
                    <b>Kategori: </b> {updatedModel?.subCategory?.category?.name}
                  </p>

                  <p className="marginParagraph">
                    <b>Alt Kateogri: </b> {updatedModel?.subCategory?.name}
                  </p>

                  <p className="marginParagraph">
                    <b>Marka: </b> {updatedModel?.brand?.name}
                  </p>

                  <p className="marginParagraph">
                    <b>Ürün İsmi: </b> {updatedModel?.name}
                  </p>

                  <p className="marginParagraph">
                    <b>Ürün Açıklaması: </b> {updatedModel?.description}
                  </p>

                  <p className="marginParagraph">
                    <b>barkod: </b> {updatedModel?.barcode}
                  </p>

                  <p className="marginParagraph">
                    <b>Tedarikçi Durumu: </b> {updatedModel?.supplyStatus}
                  </p>

                  <p className="marginParagraph">
                    <b>Menşei: </b> {updatedModel?.origin}
                  </p>

                  <p className="marginParagraph">
                    <b>EOL: </b> {updatedModel?.eol ? "EVET" : "HAYIR"}
                  </p>


                  {waitingPictureList?.length > 0 ? <div><h5>Yeni Resimler</h5>

                    <Galleria value={waitingPictureList} responsiveOptions={responsiveOptions} numVisible={1} circular
                              style={{maxWidth: '640px'}}
                              showItemNavigators showThumbnails={false} item={itemTemplate2}
                              thumbnail={thumbnailTemplate2}/>
                  </div> : ""}

                  <p className="marginParagraph">
                    <b>Açıklama: </b> {updatedModel?.approveDescription ? updatedModel?.approveDescription : "Yok."}
                  </p>

                </div>
              </div>
            </div>}

      </Dialog>

    </>
  )
}
export default ProductManagement
