import React, {useEffect, useRef, useState} from "react";
import "./App.scss"
import classNames from "classnames";
import AppBreadcrumb from "./AppBreadcrumb";
import {withRouter} from "react-router";
import {Toast} from "primereact/toast";
import * as services from "../../pages/login/httpService";
import {handleError} from "../../service/HandleErrorService";
import {Link} from "react-router-dom";
import {Tooltip} from "primereact/tooltip";
import {Dropdown} from "primereact/dropdown";
import AspAxios from "../../service/AspAxios";
import * as Paths from "../../service/config";
import {userDetail} from "../../service/config";

const AppTopbar = (props) => {
  let tokens = {
    accessToken: localStorage.getItem("token"),
    refreshToken: localStorage.getItem("refreshToken"),
  };
  const toast = useRef(null);
  const profileItemClassName = classNames("profile-item", {
    "active-menuitem fadeInDown": props.topbarUserMenuActive,
  });
  let loginUser = JSON.parse(localStorage.getItem("user"));
  let autoLogin = localStorage.getItem("autoLogin");
  let resellerAdmin = JSON.parse(localStorage.getItem('user')).user?.roles?.includes("RESELLER_ADMIN") ? true : false;
  const [control, SetControl] = useState(false);

  const supplyUser = JSON.parse(localStorage.getItem('user')).user?.roles.includes("SUPPLY_ADMIN") ||
    JSON.parse(localStorage.getItem('user')).user?.roles.includes("SUPPLY_USER");
  const supplyId = JSON.parse(localStorage.getItem('user')).user?.supply?.id;
  const [faultyProductCount, setFaultyProductCount] = useState(0);

  const [selectReseller, setSelectReseller] = useState(
    {
      name: JSON.parse(localStorage.getItem('user')).user?.loginReseller?.name,
      code: JSON.parse(localStorage.getItem('user')).user?.loginReseller?.code
    });
  const [count, setCount] = useState("");
  let resellerUser = JSON.parse(localStorage.getItem('user')).user?.roles.includes("RESELLER_ADMIN") ||
    JSON.parse(localStorage.getItem('user')).user?.roles.includes("RESELLER_USER");
  let parentResellerCode = JSON.parse(localStorage.getItem('user')).user?.loginReseller?.parentReseller?.code;
  let resellers = JSON.parse(localStorage.getItem('user')).user?.resellers;
  let resellerCode = JSON.parse(localStorage.getItem('user')).user?.loginReseller?.code;

  let resellerName = JSON.parse(localStorage.getItem('user')).user?.loginReseller?.name;
  let changeCount = 0;
  const [childResellers, setChildResellers] = useState([]);
  let parentAdminControl = resellers?.find(value => value.code === parentResellerCode);
  let parentResellers = JSON.parse(localStorage.getItem('user')).user?.resellers;

  useEffect(() => {
    parentAdminControl = resellers?.find(value => value.code === parentResellerCode)

    resellerUser = JSON.parse(localStorage.getItem('user')).user?.roles.includes("RESELLER_ADMIN") ||
      JSON.parse(localStorage.getItem('user')).user?.roles.includes("RESELLER_USER");
    resellerCode = JSON.parse(localStorage.getItem('user')).user?.loginReseller?.code;
    resellerName = JSON.parse(localStorage.getItem('user')).user?.loginReseller?.name;
    loginUser = JSON.parse(localStorage.getItem("user"));
    autoLogin = localStorage.getItem("autoLogin");
    resellerAdmin = JSON.parse(localStorage.getItem('user')).user?.roles.includes("RESELLER_ADMIN") ? true : false;

  }, [changeCount]);

  useEffect(() => {
    if(parentAdminControl)
    {
      let _parentResellersCode=[];
      parentResellers?.forEach(value=>{
        _parentResellersCode.push(value.code)
      });
      AspAxios.post(Paths.getChildReseller,_parentResellersCode)
        .then((res) => {
          let _childrenReseller = [];
          _childrenReseller.push(...res?.data?.map(value => {

            return {
              fullName: value.code + " - " + value.name,
              ...value
            }
          }));


          setChildResellers(_childrenReseller)
        })
        .catch((err) => {
          console.log(err);

        });
    }
    if(supplyUser){
      AspAxios.get(Paths.getWaitingTicketCount + "/" + supplyId).then((res) => {
        console.log("getWaitingTicketCount", res.data);
        setFaultyProductCount(res.data);
      }).catch((err) => {
        console.log(err);
      });
    }
  }, [])

  const saveSelectReseller = (newCode) => {
    AspAxios.get(Paths.saveSelectReseller + "/" + newCode + "/" + tokens?.accessToken)
      .then((res) => {

        AspAxios.get(Paths.userDetail)
          .then((res) => {
            if (res.data) {
              localStorage.setItem("user", JSON.stringify(res.data));

              changeCount = changeCount + 1;
              window.location.reload();
            }
          })
          .catch((err) => {
            console.log(err)
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const Logout = () => {
    services
      .userLogout(tokens)
      .then((res) => {
        localStorage.clear();
        props.history.push("/");
      })
      .catch((error) => {
        localStorage.clear();
        props.history.push("/");
        handleError(error, toast);
      });
  };

  return (
    <div className="layout-topbar">
      <Toast ref={toast}/>
      <div className="topbar-left">
        <div>
          {(autoLogin == null || autoLogin != "true") ?
            <button
              type="button"
              className="menu-button p-link"
              onClick={props.onMenuButtonClick}
            >
              <i className="pi pi-chevron-left"/>
            </button> :
            <button
              type="button"
              className="menu-button p-link small_hidden"
              onClick={props.onMenuButtonClick}
            >
              <i className="pi pi-chevron-left"/>
            </button>}

        </div>
        <span className="topbar-separator"/>

        <div
          className="layout-breadcrumb viewname p-justify-between"
          style={{textTransform: "uppercase"}}
        >
          <AppBreadcrumb routers={props.routers}/>
        </div>
        <div>
          {(autoLogin == null || autoLogin != "true") &&
          <img
            id="logo-mobile"
            className="mobile-logo"
            src="assets/layout/images/profil.svg"
            alt="diamond-layout"
          />}
        </div>
      </div>


      {resellerUser ?
        <span className="small_show" style={{fontSize: 12, marginBottom: 3}}>

           {resellerCode} - {resellerName}
        </span> :
        <span style={{marginLeft: 25}}>
          {loginUser.gsmNo} - {loginUser.email}
            </span>}
      <div className="topbar-right">
        <ul className="topbar-menu">
          <li className={profileItemClassName}>
            {JSON.parse(localStorage.getItem('user')).user.roles.includes("TTG_ADMIN") ?
              <div>
                <Tooltip target=".custom-target-icon"/>
                <div className="notification custom-target-icon" data-pr-tooltip="ONAY BEKLEYEN ÜRÜNLER"
                     data-pr-position="right" data-pr-at="right+18 top" data-pr-my="left center-2">
              <span className="profile-name">
                <Link to="/WaitingMyAction">
                  <i className="pi pi-bell" a-pr-my="left center-2"/>
                  {count < 1 ? (
                    <span></span>
                  ) : (
                    <span className="badge">{count}</span>
                  )}
                </Link>
              </span>
                </div>
              </div> : ""}
            {supplyUser ?
              <div>
                <Tooltip target=".custom-target-icon"/>
                <div className="notification custom-target-icon" data-pr-tooltip="BİLDİRİLEN ARIZALI ÜRÜNLER"
                     data-pr-position="right" data-pr-at="right+18 top" data-pr-my="left center-2">
              <span className="profile-name">
                <Link to="/faultyProductExchange">
                  <i className="pi pi-bell" a-pr-my="left center-2"/>
                  {faultyProductCount < 1 ? (
                    <span></span>
                  ) : (
                    <span className="badge">{faultyProductCount}</span>
                  )}
                </Link>
              </span>
                </div>
              </div> : ""}
          </li>

          {   resellerUser && resellerAdmin && parentAdminControl?

            <li className={profileItemClassName}>
              <span className="profile-name">

                <Dropdown
                  value={selectReseller.code}
                  options={childResellers}
                  optionLabel="fullName"
                  optionValue="code"
                  onChange={e => {
                    let reseller = childResellers.find(value => value.code === e.target.value)
                    resellerName = reseller.name;
                    resellerCode = reseller.code;
                    setSelectReseller(reseller);
                    saveSelectReseller(resellerCode);
                  }
                  }
                  filter/>
                    </span>
            </li>
            : resellerUser ?


              <li className={profileItemClassName}>
              <span className="profile-name">

                {JSON.parse(localStorage.getItem('user')).user?.loginReseller?.code} -   {JSON.parse(localStorage.getItem('user')).user?.loginReseller?.name}
              </span>
              </li>


              : ""
          }

          <li className={profileItemClassName}>
            <button
              type="button"
              className="p-link"
              onClick={props.onTopbarUserMenu}
            >
                    <span className="profile-name">
                  {loginUser.user?.name?.toUpperCase() +
                  " " +
                  loginUser?.user?.surname?.toUpperCase()}
                    </span>
            </button>
            <ul className="profile-menu fade-in-up">
              <li>
                <button onClick={Logout} type="button" className="p-link">
                  <i className="pi pi-power-off"></i>
                  <span>Çıkış</span>
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default withRouter(AppTopbar);
