import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import ProductSalesManagementService from "./httpService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Calendar} from "primereact/calendar";
import {ResponsiveDataTableWithoutRowClass} from "../../components/ResponsiveDataTableWithoutRowClass";

const StockInfoList = () => {

  const parentResellerCode = JSON.parse(localStorage.getItem('user')).user?.loginReseller?.parentReseller?.code;
  const toast = useRef(null);
  const productSalesManagementService = new ProductSalesManagementService();

  const [loading, setLoading] = useState(false);
  const [selectedTimeBegin, setSelectedTimeBegin] = useState(null);
  const [selectedTimeEnd, setSelectedTimeEnd] = useState(null);
  const [firstFetch, setFirstFetch] = useState(true);
  const [searchCriteriaLazyLoading, setSearchCriteriaLazyLoading] = useState(false);
  const [searchCriteriaProgressing, setSearchCriteriaProgressing] = useState(false);
  const [saleInfoList, setSaleInfoList] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState({});

  const [exportExcelDisabled, setExportExcelDisabled] = useState(null);
  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  useEffect(() => {
    setSearchCriteriaLazyLoading(true);
  }, [selectedTimeBegin, selectedTimeEnd, searchCriteria]);

  useEffect(() => {
    if (searchCriteriaLazyLoading) {
      setTimeout(() => {
        setSearchCriteriaProgressing(true);
        setSearchCriteriaLazyLoading(false);
        setFirstFetch(false);
      }, firstFetch ? 10 : 1000);
    }
  }, [searchCriteriaLazyLoading]);

  useEffect(() => {
    if (searchCriteriaProgressing) {
      searchSaleInfo({...paginationConfig, activePage: 0});
      setSearchCriteriaProgressing(false);
    }
  }, [searchCriteriaProgressing]);

  const searchSaleInfo = (pageState) => {
    var dateControl = true;
    if (selectedTimeBegin != null && selectedTimeEnd != null && selectedTimeBegin.getTime() > (selectedTimeEnd.getTime() + (20 * 3600000))) {
      dateControl = false;
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Aramak için başlangıç tarihi bitiş tarihinden önce olmalıdır.'
      });
    }
    if (dateControl) {
      setLoading(true);
      var sort = "";
      if (searchCriteria.sortField != null && searchCriteria.sortOrder != null) {
        sort += searchCriteria.sortField;
        if (searchCriteria.sortOrder == 1) {
          sort += ",asc";
        } else {
          sort += ",desc";
        }
      } else {
        sort += "createDateTime,desc";
      }

      if (selectedTimeBegin != null) {
        searchCriteria.saleDateStart = selectedTimeBegin.toLocaleDateString("tr-TR");
      } else {
        searchCriteria.saleDateStart = null;
      }

      if (selectedTimeEnd != null) {
        searchCriteria.saleDateEnd = selectedTimeEnd.toLocaleDateString("tr-TR");
      } else {
        searchCriteria.saleDateEnd = null;
      }

      searchCriteria.parentResellerCode = parentResellerCode;
      productSalesManagementService.searchStock(searchCriteria, pageState.itemsPerPage, pageState.activePage, sort).then(res => {
        if (res.content && res.content.length > 0) {
          res.content.forEach(c => {
            if (c.waybillSaleInfoList && c.waybillSaleInfoList.length > 0) {
              let waybillDateMap = new Map();
              c.waybillSaleInfoList.forEach(w => {
                let date = w.waybillDateString.split(" ")[0];
                if (waybillDateMap.has(date)) {
                  waybillDateMap.set(date, waybillDateMap.get(date) + 1);
                } else {
                  waybillDateMap.set(date, 1);
                }
                let waybillDateMapString = "";
                waybillDateMap.forEach(function (value, key) {
                  waybillDateMapString += key + "(" + value + ")\n";
                });
                c.waybillDateMapString = waybillDateMapString;
              });
            }
          });
          setSaleInfoList(res.content);

          pageState.totalRecords = res.totalElements;
          pageState.first = pageState.itemsPerPage * pageState.activePage;
          setPaginationConfig({
            ...pageState,
          });
        } else {
          setSaleInfoList([]);
        }
      }).catch(err => {
        toast?.current?.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Satış bilgisi getirme başarısız.'
        });
        console.log(err);
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  const exportExcel = () => {
    var dateControl = true;
    if (selectedTimeBegin != null && selectedTimeEnd != null && selectedTimeBegin.getTime() > (selectedTimeEnd.getTime() + (20 * 3600000))) {
      dateControl = false;
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Aramak için başlangıç tarihi bitiş tarihinden önce olmalıdır.'
      });
    }
    if (dateControl) {
      setExportExcelDisabled(true);

      if (selectedTimeBegin != null) {
        searchCriteria.saleDateStart = selectedTimeBegin.toLocaleDateString("tr-TR");
      } else {
        searchCriteria.saleDateStart = null;
      }

      if (selectedTimeEnd != null) {
        searchCriteria.saleDateEnd = selectedTimeEnd.toLocaleDateString("tr-TR");
      } else {
        searchCriteria.saleDateEnd = null;
      }

      searchCriteria.parentResellerCode = parentResellerCode;
      productSalesManagementService.searchStockEager(searchCriteria).then(res => {
        import('xlsx').then(xlsx => {
          var excelList = [];
          res.forEach(a => {
            excelList.push({
              "Fatura Tarihi": a.invoiceDate,
              "İrsaliye Tarihi": a.waybillDate,
              "Fatura No": a.invoiceNo,
              "İrsaliye No": a.waybillNo,
              "Seri No": a.serialNo,
              "Barkod": a.barcode,
              "Kategori": a.category,
              "Alt Kategori": a.subCategory,
              "KDV Dahil Tutar": addCommas(a.vatIncludedPrice),
              "Güncel SKF": addCommas(a.recommendedPrice),
            })
          });
          const worksheet = xlsx.utils.json_to_sheet(excelList);
          var wscols = [
            {width: 18},
            {width: 18},
            {width: 20},
            {width: 20},
            {width: 20},
            {width: 20},
            {width: 25},
            {width: 25},
            {width: 18},
            {width: 18},
          ];
          worksheet["!cols"] = wscols;
          const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
          const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
          saveAsExcelFile(excelBuffer, 'Stoğumdaki Ürünler');
        });
      }).catch(err => {
        toast?.current?.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Stoğumdaki ürünleri getirme başarısız.'
        });
        console.log(err);
      }).finally(() => {
        setExportExcelDisabled(false);
      });
    }
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    searchSaleInfo(pageState);
  };

  const onFilter = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const onSort = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const addCommas = num => num?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const responsiveColumnRight = (title, content) => {
    return (
      <div>
        <span className="p-column-title">{title}</span>
        <span style={{float: 'right'}}>{content}</span>
      </div>
    );
  };

  const invoiceDateTemplate = (data) => {
    return responsiveColumn("Fatura Tarihi", data?.invoiceDate)
  };

  const waybillDateTemplate = (data) => {
    return responsiveColumn("İrsaliye Tarihi", data?.waybillDate)
  };

  const invoiceNoTemplate = (data) => {
    return responsiveColumn("Fatura No", data?.invoiceNo)
  };

  const waybillNoTemplate = (data) => {
    return responsiveColumn("İrsaliye No", data?.waybillNo)
  };

  const serialNoTemplate = (data) => {
    return responsiveColumn("Seri Numara", data?.serialNo)
  };

  const barcodeTemplate = (data) => {
    return responsiveColumn("Barkod", data?.barcode)
  };

  const categoryTemplate = (data) => {
    return responsiveColumn("Kategori", data?.category)
  };

  const subCategoryTemplate = (data) => {
    return responsiveColumn("Alt Kategori", data?.subCategory)
  };

  const vatIncludedPriceTemplate = (data) => {
    return responsiveColumnRight("KDV Dahil Tutar", addCommas(data?.vatIncludedPrice))
  };

  const recommendedPriceTemplate = (data) => {
    return responsiveColumnRight("Güncel SKF", addCommas(data?.recommendedPrice))
  };

  const header =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>Stoğumdaki Ürünler</div>
        <div>
          <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
                  disabled={exportExcelDisabled}
                  onClick={exportExcel}/>
        </div>
      </div>
    </div>;

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-12 p-md-2 p-fluid">
            <label htmlFor="baslangic">Tarihi Aralığı</label>
            <Calendar id="baslangic" showIcon dateFormat={'dd.mm.yy'} style={{marginTop: 5}}
                      value={selectedTimeBegin} showButtonBar
                      onChange={(e) => setSelectedTimeBegin(e.value)}
                      placeholder="Tarih Başlangıç"/>
          </div>
          <div className="p-col-12 p-md-2 p-fluid"><br className="small_hidden"/>
            <Calendar id="bitis" showIcon dateFormat={'dd.mm.yy'} style={{marginTop: 5}}
                      value={selectedTimeEnd} showButtonBar
                      onChange={(e) => setSelectedTimeEnd(e.value)}
                      placeholder="Tarih Bitiş"/>
          </div>
          <div className="p-col-12">
            <ResponsiveDataTableWithoutRowClass
              lazy
              header={header}
              value={saleInfoList}
              paginationConfig={paginationConfig}
              onActivePageChange={onActivePageChange}
              onFilter={onFilter}
              filters={searchCriteria.filters}
              onSort={onSort}
              sortField={searchCriteria.sortField}
              sortOrder={searchCriteria.sortOrder}
              loading={loading}
              style={{fontSize: '11px'}}
            >
              <Column field="invoiceDate" body={invoiceDateTemplate} header="Fatura Tarihi"/>
              <Column field="waybillDate" body={waybillDateTemplate} header="İrsaliye Tarihi"/>
              <Column field="invoiceNo" body={invoiceNoTemplate} header="Fatura No" filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="waybillNo" body={waybillNoTemplate} header="İrsaliye No" filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="serialNo" body={serialNoTemplate} header="Seri No" filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="barcode" body={barcodeTemplate} header="Barkod" filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="category" body={categoryTemplate} header="Kategori"/>
              <Column field="subCategory" body={subCategoryTemplate} header="Alt Kategori"/>
              <Column field="vatIncludedPrice" body={vatIncludedPriceTemplate} header="KDV Dahil Tutar"
                      headerStyle={{textAlign: 'right'}}/>
              <Column field="recommendedPrice" body={recommendedPriceTemplate} header="Güncel SKF"
                      headerStyle={{textAlign: 'right'}}/>
            </ResponsiveDataTableWithoutRowClass>
          </div>
        </div>
      </div>
    </>
  )

};

export default StockInfoList;
