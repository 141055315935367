import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import ResellerService from "./httpService";
import {Column} from "primereact/column";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import * as services from "../login/httpService";
import {handleError} from "../../service/HandleErrorService";
import {useHistory} from "react-router";

const AdminResellerLogin = () => {

  const buildStage = process.env.REACT_APP_STAGE;
  const history = useHistory();
  const toast = useRef(null);
  const resellerService = new ResellerService();

  const [loading, setLoading] = useState(false);
  const [searchCriteriaLazyLoading, setSearchCriteriaLazyLoading] = useState(false);
  const [searchCriteriaProgressing, setSearchCriteriaProgressing] = useState(false);
  const [resellerList, setResellerList] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState({});
  const [selectedDistyCode, setSelectedDistyCode] = useState(null);

  const [selectedReseller, setSelectedReseller] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [resellerLoginButtonDisabled, setResellerLoginButtonDisabled] = useState(false);

  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  const [roleOptions, setRoleOptions] = useState(
    [
      {value: false, name: "Bayi Kullanıcısı"},
      {value: true, name: "Bayi Admini"}
    ]
  );

  useEffect(() => {
    if (buildStage !== "test"){
      history.push("/dashboard");
    }
    searchReseller({...paginationConfig, activePage: 0});
  }, []);

  useEffect(() => {
    setSearchCriteriaLazyLoading(true);
  }, [selectedDistyCode, searchCriteria]);

  useEffect(() => {
    if (searchCriteriaLazyLoading) {
      setTimeout(() => {
        setSearchCriteriaProgressing(true);
        setSearchCriteriaLazyLoading(false);
      }, 2000);
    }
  }, [searchCriteriaLazyLoading]);

  useEffect(() => {
    if (searchCriteriaProgressing) {
      searchReseller({...paginationConfig, activePage: 0});
      setSearchCriteriaProgressing(false);
    }
  }, [searchCriteriaProgressing]);

  const searchReseller = (pageState) => {
    setLoading(true);
    var sort = "";
    searchCriteria.distyCode = selectedDistyCode;
    if (searchCriteria.sortField != null && searchCriteria.sortOrder != null) {
      sort += searchCriteria.sortField;
      if (searchCriteria.sortOrder == 1) {
        sort += ",asc";
      } else {
        sort += ",desc";
      }
    } else {
      sort += "code,desc";
    }

    resellerService.resellerSearchLazy(searchCriteria, pageState.itemsPerPage, pageState.activePage, sort).then(res => {
      console.log("searchReseller", res.content);
      if (res.content && res.content.length > 0) {
        setResellerList(res.content);

        pageState.totalRecords = res.totalElements;
        pageState.first = pageState.itemsPerPage * pageState.activePage;
        setPaginationConfig({
          ...pageState,
        });
      } else {
        setResellerList([]);
      }
    }).catch(err => {
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Satış bilgisi getirme başarısız.'
      });
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  };

  const resellerLogin = () => {
    if (selectedReseller != null) {
      if (selectedRole != null) {
        setResellerLoginButtonDisabled(true);
        const request = {
          resellerCode: selectedReseller.code,
          admin: selectedRole
        };
        resellerService.resellerLoginTicket(request).then(res => {
          console.log("loginReseller", res);
          history.push("/resellerLogin/" + res.ticket);
        }).catch(err => {
          console.log(err);
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: 'Bayi olarak giriş yapma başarısız.'
          });
          setResellerLoginButtonDisabled(false);
        });
      } else {
        toast.current.show({
          severity: 'warn',
          summary: 'Uyarı',
          detail: 'Bir rol seçili olmalıdır.'
        });
      }
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Bir bayi seçili olmalıdır.'
      });
    }
  };

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    searchReseller(pageState);
  };

  const onFilter = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const onSort = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const distyCodeFilter = <InputText value={selectedDistyCode} onChange={(e) => setSelectedDistyCode(e.target.value)}
                                     className="p-column-filter"/>;

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const resellerCodeTemplate = (data) => {
    return responsiveColumn("Bayi Kodu", data?.code)
  };

  const parentResellerCodeTemplate = (data) => {
    return responsiveColumn("Ana Bayi Kodu", data?.parentReseller?.code)
  };

  const resellerNameTemplate = (data) => {
    return responsiveColumn("Bayi Adı", data?.name)
  };

  const distyCodeTemplate = (data) => {
    return responsiveColumn("Distribütör Kodu", data?.disty?.code)
  };

  const header =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>Bayi Listesi</div>
      </div>
    </div>;

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-12 p-md-9">
            <ResponsiveDataTable
              lazy
              header={header}
              value={resellerList}
              paginationConfig={paginationConfig}
              onActivePageChange={onActivePageChange}
              onFilter={onFilter}
              filters={searchCriteria.filters}
              onSort={onSort}
              sortField={searchCriteria.sortField}
              sortOrder={searchCriteria.sortOrder}
              loading={loading}
              selection={selectedReseller}
              onSelectionChange={e => setSelectedReseller(e.value)} dataKey="id"
            >
              <Column selectionMode="single" headerStyle={{width: '3em'}}></Column>
              <Column field="code" body={resellerCodeTemplate} header="Bayi Kodu" filter={true}
                      filterMatchMode={"contains"} sortable/>
              <Column field="parentResellerCode" body={parentResellerCodeTemplate} header="Ana Bayi Kodu" filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="name" body={resellerNameTemplate} header="Bayi Adı" filter={true}
                      headerStyle={{width: '30%'}}
                      filterMatchMode={"contains"} sortable/>
              <Column field="distyCode" body={distyCodeTemplate} header="Distribütör Kodu" filter={true}
                      filterElement={distyCodeFilter} filterMatchMode={"contains"}/>
            </ResponsiveDataTable>
          </div>
          <div className="p-col-12 p-md-3 p-fluid">
            <h5>Rol Seçimi</h5>
            <Dropdown value={selectedRole} options={roleOptions} onChange={(e) => setSelectedRole(e.value)}
                      optionLabel="name" placeholder={"Roller"}/>
            <Button type="button" label="Bayi Olarak Giriş Yap" className="p-button" style={{marginTop: 10}}
                    disabled={resellerLoginButtonDisabled}
                    onClick={resellerLogin}/>
          </div>
        </div>
      </div>
    </>
  )

};

export default AdminResellerLogin;
