import AspAxios from "../../service/AspAxios";
import * as Paths from "../../service/config"

export default class CategoryService {

  upsert(category) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.upsertCategory, category).then(res => {
        resolve(res.data);
      }).catch(reject)
    })

  }

  getAll() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllCategory).then(res => {
        resolve(res.data);
      }).catch(reject)
    })

  }

  delete(id) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.deleteCategory+"/"+id).then(res => {
        resolve(res.data);
      }).catch(reject)
    })

  }

  getSubCategoriesByCategory(category) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllSubCategory,category).then(res => {
        resolve(res.data);
      }).catch(reject)
    })

  }

  getAllSubCategories() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllSubCategory).then(res => {
        resolve(res.data);
      }).catch(reject)
    })

  }
  getAllScoreType() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.allScoreType).then(res => {
        resolve(res.data);
      }).catch(reject)
    })

  }

  getStockType() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.stockType).then(res => {
        resolve(res.data);
      }).catch(reject)
    })

  }
  upsertSubCategories(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.upsertSubCategory,request).then(res => {
        resolve(res.data);
      }).catch(reject)
    })
  }

  deleteSubCategories(id) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.deleteSubCategory+"/"+id).then(res => {
        resolve(res.data);
      }).catch(reject)
    })
  }


}


