import AspAxios from "../../service/AspAxios";

import * as Paths from '../../service/config'
import {
  completedResellerOrderCart,
  getAllCompletedResellerProductOrder, getAllCompletedResellerProductOrderDetailEager,
  updateResellerOrderDetail
} from "../../service/config";

export default class ResellerOrderService {
  resellerCode = JSON.parse(localStorage.getItem('user')).user?.loginReseller?.code;

  getAllProducts(request, _size, _page) {

    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getHasProductsByReseller, request, {
        params: {size: _size, page: _page}
      }).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  getDistyProductDetailById(modelId) {

    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getDistyProductDetailById + modelId).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getSimilarProducts(modelId) {

    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getSimilarProducts + modelId).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getResellerProductCart(id) {

    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getActiveResellerOrderCart + id).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  completedResellerProductCart(id) {

    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.completedResellerOrderCart + id).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  deleteResellerProductDetailCart(request) {

    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.deleteResellerOrderDetail, request).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  resellerCreateProductOrder(request) {

    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.resellerCreateProductOrder, request).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getLabelType() {

    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.labelType).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getOrderType() {

    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.orderType).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getAllCompletedResellerProductOrder(request, _page, _size, _sort) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getAllCompletedResellerProductOrder, request, {
        params: {
          size: _size,
          page: _page,
          sort: _sort
        }
      }).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })

  }

  getAllCompletedResellerProductOrderById(id) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllCompletedResellerProductOrder + "/" + id
      ).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })

  }

  cancelResellerProductOrderById(id) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.cancelResellerProductOrder + "/" + id
      ).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })

  }

  getEagerExcelData(searhRequest) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getAllCompletedResellerProductOrderEager, searhRequest)
        .then(res => {
          resolve(res.data)
        }).catch(reject)
    })
  }

  getEagerExcelDataDetail(searhRequest) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getAllCompletedResellerProductOrderDetailEager, searhRequest)
        .then(res => {
          resolve(res.data)
        }).catch(reject)
    })
  }

}
