import React from "react";

export const EmptyPage = () => {
  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <h5>Empty Page</h5>
          <p>
            Use this page to start from scratch and place your custom content.
          </p>
        </div>
      </div>
    </div>
  );
};
