import ProductService from "../httpService"
import React, {useEffect, useRef, useState} from "react";

import {Toast} from "primereact/toast";
import {Column} from "primereact/column";

import {Button} from "primereact/button";
import {useHistory} from "react-router-dom";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import classNames from "classnames";
import {InputNumber} from "primereact/inputnumber";
import {InputTextarea} from "primereact/inputtextarea";
import {Dropdown} from "primereact/dropdown";
import {ResponsiveDataTable} from "../../../components/ResponsiveDataTable";
import {handleError} from "../../../service/HandleErrorService";
import CanSellService from "../../canSell/httpService";
import {Checkbox} from "primereact/checkbox";


const ProductManagementAdmin = () => {
  const toast = useRef(null);
  const history = useHistory();
  const productService = new ProductService();
  const [allProduct, setAllProduct] = useState([]);
  const [createModel, setCreateModel] = useState();
  const [createModelDialog, setCreateModelDialog] = useState(false);
  const [createModelHeader, setCreateModelHeader] = useState(false);
  const [searchRequest, setSearchRequest] = useState({});
  const canSellService = new CanSellService();
  const [allBrand, setAllBrand] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [allSupply, setAllSupply] = useState([]);
  const [excelList, setExcelList] = useState([]);
  const [allModelsId, setAllModelsId] = useState([]);
  const [allApproveTypes, setAllApproveTypes] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  const sortData = (data) => {
    const x = data?.sort(function(a, b){
    if(a.name < b.name) { return -1; }
    if(a.name > b.name) { return 1; }
    return 0;
})
return x;
}
  useEffect(() => {
    getAll(paginationConfig);
    canSellService.getAllBrand().then(setAllBrand);
    canSellService.getAllCategory().then(res => {
      setAllCategory(sortData(res))
    })
    canSellService.getAllSubCategory().then(res => {
      setAllSubCategory(sortData(res))
    })
    canSellService.getAllSupply().then(setAllSupply);
    productService.getapproveTypes().then(res => {
      setAllApproveTypes(res)
    })

  }, [])

  const getAll = (pageState) => {


    productService.getAllModelAdmin(pageState.activePage, pageState.itemsPerPage, searchRequest).then(res => {
      pageState.first = pageState.itemsPerPage * pageState.activePage;
      pageState.totalRecords = res.totalElements;
      setPaginationConfig({
        ...pageState,
      });





      setAllProduct(res?.content)
    }).catch(err => handleError(err, toast))

  }


  const getExcelUploadData = () => {
    productService.getAllModelAdminList(searchRequest).then(res2 => {

      let _excelList = [];
      res2?.forEach(value => {

        let _excelModel = {};
        _excelModel['Kategori'] = value?.subCategory.category.name;
        _excelModel['Alt Kategori'] = value?.subCategory.name;
        _excelModel['Marka'] = value?.brand.name;
        _excelModel['Tedarikçi'] = value?.supplyName;
        _excelModel['Ürün Adı'] = value?.name;
        _excelModel['Barkod'] = value?.barcode;
        _excelModel['Ürün Açıklaması'] = value?.description;
        _excelModel['Tedarik Durumu'] = value?.supplyStatus;
        _excelModel['Menşei'] = value?.origin;
        if (value.eol) {
          _excelModel['Eol'] = "EVET";
        } else if (value.eol === undefined) {
          _excelModel['Eol'] = "";
        } else {
          _excelModel['Eol'] = "HAYIR";

        }


        _excelList.push(_excelModel);
      })
      setExcelList(_excelList)

      import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(_excelList);
        const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
        saveAsExcelFile(excelBuffer, 'products');
      });


    }).catch(err => handleError(err, toast))
  }


  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, "Ürün Listesi" + EXCEL_EXTENSION);
    });
  }
  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    getAll(pageState);
  };

  const eolTemplate = (e) => {
    if (e.eol) {
      return <div>Evet</div>
    } else if (e.eol === undefined) {
      return <div>Girilmedi</div>
    } else
      return <div>Hayır</div>


  }
  const actions = (rowData) => {
    return (<Button
        icon="pi pi-search"
        className="p-button-rounded p-button-success p-mr-2"
        label="Detay"


        onClick={e => history.push("/ProductDetailAdmin/" + rowData.id)}
      />
    )
  }


  const supplyTemplate = (e) => {
    let _supply = allSupply.find(value => e.supplyName === value.name);

    return _supply?.disties !== undefined ? _supply?.disties[0]?.name : _supply?.name;

  }

  const approveTemplate = (e) => {
    let _type = allApproveTypes.find(value => value.key === e.approveType);

    if (_type?.value === "BEKLIYOR") {
      return <div style={{fontSize: 14}}>{_type?.value}</div>
    } else if (_type?.value === "REDDEDİLDİ") {
      return <div style={{fontSize: 14}}>{_type?.value}</div>

    } else {
      return <div style={{fontSize: 14}}>{_type?.value}</div>

    }
  }
  const modelNameTemplate = (e) => {

    return <div><InputTextarea style={{fontSize: 10, backgroundColor: 'lawngreen', width: '100%'}} autoResize disabled
                               value={e?.name}/>
    </div>
  }
  const dashboardCheckTemplate = (rowData) => {

    rowData.labels?.forEach(value => {
      if (value?.labelStatus === 'DASHBOARD') {

        rowData.dasboardChecked = true;
      }
    })

    return <div><Checkbox  checked={rowData.dasboardChecked} onChange={e=>{
      rowData.dasboardChecked=e.checked
    }}/></div>
  }


  const headersTemplate = (
    <div className="card">
      <div className="p-formgroup-inline">

      <div className="p-field">
          <label htmlFor="category" className="p-sr-only">Marka</label>
          <Dropdown id="category" value={searchRequest.category} options={allCategory} showClear placeholder="Kategori"
                    optionLabel="name" filter optionValue="name"
                    onChange={e => {
                      let _searchRequest = {...searchRequest};
                      _searchRequest.category = e.target.value;
                      setSearchRequest(_searchRequest);
                    }

                    }/>
        </div>

        <div className="p-field">
          <label htmlFor="subCategory" className="p-sr-only">Marka</label>
          <Dropdown id="subCategory" value={searchRequest.subCategory} options={allSubCategory} showClear placeholder="Alt Kategori"
                    optionLabel="name" filter optionValue="name"
                    onChange={e => {
                      let _searchRequest = {...searchRequest};
                      _searchRequest.subCategory = e.target.value;
                      setSearchRequest(_searchRequest);
                    }

                    }/>
        </div>

        <div className="p-field">
          <label htmlFor="brand" className="p-sr-only">Marka</label>
          <Dropdown id="brand" value={searchRequest.brand} options={allBrand} showClear placeholder="Marka"
                    optionLabel="name" optionValue="name"
                    onChange={e => {
                      let _searchRequest = {...searchRequest};
                      _searchRequest.brand = e.target.value;
                      setSearchRequest(_searchRequest);
                    }

                    }/>
        </div>

        <div className="p-field">
          <label htmlFor="supply" className="p-sr-only">Tedarikçi</label>
          <Dropdown id="supply" value={searchRequest.supply} options={allSupply} optionValue="name"
                    optionLabel={e => {

                      if (e.disties !== undefined) {

                        return e?.disties[0]?.name;
                      } else {
                        return e.name
                      }
                    }}
                    placeholder="Tedarikçi"
                    showClear
                    onChange={e => {
                      let _searchRequest = {...searchRequest};
                      _searchRequest.supply = e.target.value;
                      setSearchRequest(_searchRequest);
                    }

                    }/>
        </div>

        <div className="p-field">
          <label htmlFor="name" className="p-sr-only">Ürün</label>
          <InputText id="name" value={searchRequest.modelName} type="text" placeholder="Ürün"
                     onChange={e => {
                       let _searchRequest = {...searchRequest};
                       _searchRequest.modelName = e.target.value;
                       setSearchRequest(_searchRequest);
                     }

                     }/>
        </div>

        <div className="p-field">
          <label htmlFor="barcode" className="p-sr-only">Barkod</label>
          <InputText id="barcode" value={searchRequest.barcode} type="text" placeholder="Barkod"
                     onChange={e => {
                       let _searchRequest = {...searchRequest};
                       _searchRequest.barcode = e.target.value;
                       setSearchRequest(_searchRequest);
                     }

                     }/>
        </div>

        <div className="p-field">
          <label htmlFor="supply" className="p-sr-only">Tedarikçi Durumu</label>
          <Dropdown id="supply" value={searchRequest.supplyStatus} options={["AÇIK", "KAPALI"]}
                    placeholder="Tedarikçi Durumu"
                    showClear
                    onChange={e => {
                      let _searchRequest = {...searchRequest};
                      _searchRequest.supplyStatus = e.target.value;
                      setSearchRequest(_searchRequest);
                    }

                    }/>
        </div>

        <div className="p-field">
          <label htmlFor="origin" className="p-sr-only">Menşei</label>
          <InputText id="origin" value={searchRequest.origin} type="text" placeholder="Menşei"
                     onChange={e => {
                       let _searchRequest = {...searchRequest};
                       _searchRequest.origin = e.target.value;
                       setSearchRequest(_searchRequest);
                     }

                     }/>
        </div>

        <div className="p-field">
          <label htmlFor="supply" className="p-sr-only">EOL</label>
          <Dropdown id="supply" value={searchRequest.eolValue}
                    options={["EVET", "HAYIR"]}
                    placeholder="EOL"
                    showClear
                    onChange={e => {
                      let _searchRequest = {...searchRequest};
                      if (e.target.value === "EVET") {
                        _searchRequest.eol = true;
                      } else if (e.target.value === "HAYIR") {
                        _searchRequest.eol = false;
                      } else {
                        _searchRequest.eol = undefined;

                      }
                      _searchRequest.eolValue = e.target.value;

                      setSearchRequest(_searchRequest);

                    }

                    }/>
        </div>


        <Button icon="pi pi-search" type="button" label="Ara"
                onClick={e => getAll({activePage: 0, itemsPerPage: paginationConfig.itemsPerPage})}/>


      </div>
    </div>
  )


  return (
    <>
      <Toast ref={toast}/>


      <div className="datatable-responsive-demo">
        <br/>
        <br/>

        <Button style={{marginBottom: 5}} label="Ürün Listesini İndir" type="button" icon="pi pi-file-excel"
                onClick={getExcelUploadData} className="p-button-success p-mr-2" data-pr-tooltip="XLS"/>
        <ResponsiveDataTable value={allProduct}
                             header={headersTemplate}
                             paginationConfig={paginationConfig}
                             onActivePageChange={onActivePageChange}
                             emptyMessage="Ürün Bulunamadı.">
          <Column field="subCategory.category.name" header="Kategori"/>
          <Column field="subCategory.name" header="Alt Kategori"/>
          <Column field="brand.name" header="Marka"/>
          <Column field="supplyName" body={supplyTemplate} header="Tedarikçi"/>
          <Column field="name" body={modelNameTemplate} header="Ürün İsmi"/>
          <Column style={{width: 130}} field="barcode" header="Barkod"/>
          <Column field="supplyStatus" header="Tedarik Durumu"/>
          <Column field="origin" header="Menşei"/>
          <Column body={e => eolTemplate(e)} header="Eol"/>
          <Column body={e => actions(e)}/>

        </ResponsiveDataTable>
      </div>

    </>
  )
}
export default ProductManagementAdmin
