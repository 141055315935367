import AspAxios from "../../service/AspAxios";
import * as Paths from '../../service/config'

export default class VoucherService {

  getCalculationsMonthly(dimensionType, lastMonthCount) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getCalculationsMonthly + dimensionType + "/" + lastMonthCount).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  getSaleReportOptions() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getSaleReportOptions).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  getSaleReport(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getSaleReport, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

};
