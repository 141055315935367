import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../../assets/css/dialog.css";
import ReactDOM from "react-dom";

import React, {useState, useRef, useEffect} from "react";
import {Tree} from "primereact/tree";
import {Toast} from "primereact/toast";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import ProductService from "./httpService";
import {Galleria} from 'primereact/galleria';
import moment from "moment";

const Model = () => {
  const productService = new ProductService();
  const [selectedKey, setSelectedKey] = useState(null);
  const toast = useRef(null);
  const [search, setSearch] = useState({});
  const [allCategory, setAllCategory] = useState([]);
  const [selectCategory, setSelectCategory] = useState();
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [selectSubCategory, setSelectSubCategory] = useState([]);
  const [allModel, setAllModel] = useState([]);
const [pic,setPic]=useState();
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [currentNode, setCurrentNode] = useState({});


  const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  useEffect(() => {
    getAllProduct();
  }, []);

  const getAllProduct = () => {

    let _user = JSON.parse(localStorage.getItem('user'))


    if (_user?.user?.supply === undefined) {
      productService.getModelsTree({}).then((response) => {
        const classes = [];
        classes.push(
          ...response.map((category) => {
            return {
              key: category?.id,
              label: category?.name,
              data: {
                ...category
              },

              children: category?.children?.map((subCategory) => {
                return {
                  key: category?.id + "-" + subCategory?.id,
                  label: subCategory.name,
                  data: {
                    ...subCategory
                  },
                  children: subCategory?.children?.map((brand) => {
                    return {
                      key: category?.id + "-" + subCategory?.id + "-" + brand?.id,
                      label: brand?.name,
                      data: {
                        ...brand
                      },

                      children: brand?.children?.map((model) => {
                        return {
                          key:
                            category?.id + "-" + subCategory?.id + "-" + brand?.id + "-" + model?.id,
                          label: model?.name,
                          data: {
                            ...model,
                          },
                        };
                      }),
                    };
                  }),
                };
              }),
            };
          })
        );

        setAllModel(classes);

      });
    } else if(_user.user.disty)
    {
      productService.getModelsTreeDisty({}).then(response => {
        const classes = [];
        classes.push(
          ...response.map((category) => {
            return {
              key: category?.id,
              label: category?.name,
              data: {
                ...category
              },

              children: category?.children?.map((subCategory) => {
                return {
                  key: category?.id + "-" + subCategory?.id,
                  label: subCategory.name,
                  data: {
                    ...subCategory
                  },
                  children: subCategory?.children?.map((brand) => {
                    return {
                      key: category?.id + "-" + subCategory?.id + "-" + brand?.id,
                      label: brand?.name,
                      data: {
                        ...brand
                      },

                      children: brand?.children?.map((model) => {
                        return {
                          key:
                            category?.id + "-" + subCategory?.id + "-" + brand?.id + "-" + model?.id,
                          label: model?.name,
                          data: {
                            ...model,
                          },
                        };
                      }),
                    };
                  }),
                };
              }),
            };
          })
        );

        setAllModel(classes);

      });


    } else{
      productService.getModelsTreeSupply({}).then(response => {
        const classes = [];
        classes.push(
          ...response.map((category) => {
            return {
              key: category?.id,
              label: category?.name,
              data: {
                ...category
              },

              children: category?.children?.map((subCategory) => {
                return {
                  key: category?.id + "-" + subCategory?.id,
                  label: subCategory.name,
                  data: {
                    ...subCategory
                  },
                  children: subCategory?.children?.map((brand) => {
                    return {
                      key: category?.id + "-" + subCategory?.id + "-" + brand?.id,
                      label: brand?.name,
                      data: {
                        ...brand
                      },

                      children: brand?.children?.map((model) => {
                        return {
                          key:
                            category?.id + "-" + subCategory?.id + "-" + brand?.id + "-" + model?.id,
                          label: model?.name,
                          data: {
                            ...model,
                          },
                        };
                      }),
                    };
                  }),
                };
              }),
            };
          })
        );

        setAllModel(classes);

      });
    }
  };
  const onNodeSelect = (node) => {

    const currentNode = node.node.data;
    setCurrentNode(currentNode);

  };

  const nodeTemplate = (node, options) => {

    if (node?.data?.barcode !== undefined) {

      return (
        <div>
          <Button
            style={{borderRadius: 25, margin: 3}}
            label={node.label}
            className="p-button-raised p-button-success"
            onClick={(e) => setDisplayResponsive(true)}
          />
        </div>
      );
    } else {

      return <div>{node.label}</div>;
    }
  };

  const onHide = () => {
    setDisplayResponsive(false);
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="Tamam"
          iconPos="right"
          icon="pi pi-check"
          onClick={() => onHide(name)}
        />
      </div>
    );
  };

 const  itemTemplate=(item)=> {
    return <img src={item} alt={item.alt}  style={{width:200,height:200}} />;
  }

  const thumbnailTemplate=(item)=> {
    return <img src={item}  />;
  }
    return (
      <div>
        <Toast ref={toast}/>
        <div className="card">
          <h5>Ürün Ağacı</h5>
          <Tree
            value={allModel}
            filter filterMode="lenient" filterPlaceholder="Anahtar Kelime"
            selectionMode="single"
            nodeTemplate={nodeTemplate}
            onSelect={onNodeSelect}
          />
        </div>
        <div className="dialog-demo">

          <Dialog
            header={"Ürün Adı: " + currentNode.name}
            visible={displayResponsive}
            onHide={() => onHide("displayResponsive")}
            breakpoints={{"960px": "75vw"}}
            style={{width: "50vw", color:'red'}}
            footer={renderFooter("displayResponsive")}
          >

            {currentNode?.pictureUrl?.length>0?<div className="card" style={{backgroundColor:'grey'}}>

              <Galleria value={currentNode?.pictureUrl} responsiveOptions={responsiveOptions} numVisible={1} circular style={{ maxWidth: '640px' }}
                        showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />
            </div>:""}
            <p>

              <strong>
                {" "}
                <u> Ürünün Barkodu: </u>
              </strong>
              {currentNode.barcode ? currentNode.barcode : "Belirtilmemiş"}
            </p>
            <p>
              <strong>
                {" "}
                <u> Ürün Açıklaması: </u>
              </strong>
              {currentNode.description ? currentNode.description : "Belirtilmemiş"}
            </p>
            <p>
              <strong>
                {" "}
                <u> Ürün Sahibi: </u>
              </strong>
              {currentNode?.supplyName}
            </p>
            <p>
              <strong>
                {" "}
                <u> Önerilen Satış Fiyatı: </u>
              </strong>
              {currentNode.recommendedSellPrice ? currentNode.recommendedSellPrice + " TL" : "Belirtilmemiş"}
            </p>
            <p>
              <strong>
                {" "}
                <u> KDV: </u>
              </strong>
              {currentNode?.taxRatio?"%" + currentNode.taxRatio : "Belirtilmemiş"}
            </p>
            <p>
              <strong>
                {" "}
                <u> Ürünün Giriş Tarihi: </u>
              </strong>
              {currentNode.createDate ? moment(currentNode.createDate).format("DD-MM-YYYY") : "Belirtilmemiş"}
            </p>
            <p>
              <strong>
                {" "}
                <u> Ürün Fiyatının Geçerli Olacağı Tarih: </u>
              </strong>
              {currentNode.startDate ? moment(currentNode.startDate).format("DD-MM-YYYY") : "Belirtilmemiş"}
            </p>

          </Dialog>
        </div>

      </div>
    );
  };

  // const rootElement = document.getElementById("root");
  // ReactDOM.render(<Model/>, rootElement);

  export default Model;
