import {Toast} from "primereact/toast";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import moment from "moment";
import {Column} from "primereact/column";
import {ResponsiveDataTable} from "../../../components/ResponsiveDataTable";
import ProductPriceAdminService from "./ProductPriceAdminService";
import {useSelector} from "react-redux";
import store from "../../../redux/store";
import {handleError} from "../../../service/HandleErrorService";
import queryString from 'query-string'
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
const ProductPriceDetailAdmin = (props) => {

  const history = useHistory();
  const toast = useRef(null);
  const {productId} = useParams();
  const productPriceService = new ProductPriceAdminService();
  const [currentModelPrice, setCurrentModelPrice] = useState({});
  const [lastModelPriceSupplyList, setLastModelPriceSupplyList] = useState([]);
  const [lastModelPriceDistyList, setLastModelPriceDistyList] = useState([]);

  const [upsertModelPrice, setUpsertModelPrice] = useState({});


  let informations = useSelector((state) => state?.modelPriceAdmin?.modelPriceAdmin)

const {search}=useLocation();
  const {id,mid,did,sid,mpsId }=queryString.parse(search);


  useEffect(() => {

    getProductPriceDetail();

  }, [])
  const getProductPriceDetail = () => {
    let _modelPriceAdminRequest = {};
    _modelPriceAdminRequest.modelId =mid;
    _modelPriceAdminRequest.mpdId =id;
    _modelPriceAdminRequest.distyId =did;
    _modelPriceAdminRequest.supplyId =sid;
    _modelPriceAdminRequest.mpsId =mpsId;


    productPriceService.getModelPriceDetail(_modelPriceAdminRequest).then(res => {
      setCurrentModelPrice(res);
    }).catch(err => handleError(err, toast))
    productPriceService.getLastModelPriceDetail(_modelPriceAdminRequest).then(res => {

      setLastModelPriceDistyList(res?.lastModelPriceDisty);
      setLastModelPriceSupplyList(res?.lastModelPriceSupply);

    }).catch(err => handleError(err, toast))


  }


  const priceTemplate = (e, name, checkStyle) => {

    if (e[name] === undefined) {
      return <div>Girilmedi.</div>
    } else if (e[checkStyle] === false) {

      return <div style={{backgroundColor: "pink"}}>{e[name]}₺</div>
    } else if (e[checkStyle] === true) {
      return <div style={{backgroundColor: "chartreuse"}}>{e[name]}₺</div>

    } else return <div style={{backgroundColor: 'antiquewhite'}}>{e[name]}₺</div>

  }


  const taxRatioTemplate = (e) => {
    if (e['taxRatio'] == undefined) {
      return <div>Girilmedi.</div>
    } else return <div>%{e['taxRatio']}</div>

  }


  return (
    <>
      <Toast ref={toast}/>
      <div className="card">

        <h4>Ürün Bilgileri</h4>
        <div className="p-fluid p-formgrid p-grid">

          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="category">Kategori</label>
            <InputText disabled id="category" value={currentModelPrice?.category}/>
          </div>

          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="subCategory">Alt Kategori</label>
            <InputText id="subCategory" disabled value={currentModelPrice?.subCategory}/>
          </div>

          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="brand">Marka</label>
            <InputText id="brand" disabled value={currentModelPrice?.brand}/>
          </div>

          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="product">Ürün İsmi</label>
            <InputText disabled id="product" value={currentModelPrice?.modelName}/>
          </div>

          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="barcode">Barkod</label>
            <InputText id="barcode" disabled value={currentModelPrice?.barcode}/>
          </div>


          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="barcode">Ürün Açıklaması</label>
            <InputTextarea   autoResize id="barcode" disabled value={currentModelPrice?.description}/>
          </div>

          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="supply">Tedarikçi</label>
            <InputText id="supply" disabled value={currentModelPrice?.supply}/>
          </div>


          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="startDate">Giriş Zamanı</label>
            <InputText disabled id="startDate"

                       value={moment(currentModelPrice?.startDate).format("DD/MM/YYYY")}
            />
          </div>


          <div className="p-field p-col-12 p-md-2">
            <label htmlFor="distyPrice">Distribütör Alış fiyatı</label>
            <InputText id="distyPrice" type="number" step="0,1" value={currentModelPrice?.distyPrice}
                       disabled/></div>

          <div className="p-field p-col-12 p-md-2">
            <label htmlFor="recommendedEdmPrice">Önerilen EDM Alış fiyatı</label>
            <InputText id="recommendedEdmPrice" type="number" step="0,1"
                       value={currentModelPrice?.recommendedEdmPrice}
                       disabled/>
          </div>

          <div className="p-field p-col-12 p-md-2">
            <label htmlFor="recommendedResellerPrice">Önerilen Bayi Alış fiyatı</label>
            <InputText id="recommendedResellerPrice" type="number" step="0,1"
                       value={currentModelPrice?.recommendedResellerPrice}
                       disabled/>
          </div>

          <div className="p-field p-col-12 p-md-2">
            <label htmlFor="recommendedPrice">Önerilen Satış fiyatı</label>
            <InputText id="recommendedPrice" type="number" step="0,1" value={currentModelPrice?.recommendedSellPrice}
                       disabled/></div>


          <div className="p-field p-col-12 p-md-2">
            <label htmlFor="resellerPrice">Bayi Alış Fiyatı</label>
            <InputText id="resellerPrice" type="number" step="0,1" value={currentModelPrice?.resellerPrice}
                       disabled
                       onChange={e => {
                         let _upsertModelPrice = {...upsertModelPrice};
                         _upsertModelPrice.resellerPrice = parseFloat(e.target.value);
                         setUpsertModelPrice(_upsertModelPrice)
                       }}/></div>


        </div>

      </div>


      <div className="p-fluid p-formgrid p-grid">


        <div className="p-field p-col-12 p-md-7">


          <div className="datatable-responsive-demo">
            <div className="card">
              <ResponsiveDataTable value={lastModelPriceSupplyList}
                                   paginator
                                   emptyMessage="Geçmiş Fiyat Bulunamadı."
                                   rows={10} rowsPerPageOptions={[10,20,50,100]}
                                   header="Ürünün Geçmiş Tedarik Fiyat Listesi">
                <Column body={e => moment(e.startDate).format("DD/MM/YYYY")} header="Başlangıç Tarihi"/>
                <Column body={e => moment(e.endDate).format("DD/MM/YYYY")} header="Bitiş Tarihi"/>
                <Column body={taxRatioTemplate} header="KDV Oranı"/>
                <Column body={e => priceTemplate(e, 'distyPrice', 'checkDistyPrice')} header="Distribütçr Alış Fiyatı"/>
                <Column body={e => priceTemplate(e, 'recommendedResellerPrice', 'checkRecommendedResellerPrice')}
                        header="Önerilen Bayi Alış Fiyatı"/>
                <Column body={e => priceTemplate(e, 'recommendedSellPrice', 'checkRecommendedSellPrice')}
                        header="Önerilen Satış Fiyatı"/>


              </ResponsiveDataTable>
            </div>
          </div>
        </div>
        <div className="p-field p-col-12 p-md-5">
          <div className="datatable-responsive-demo">
            <div className="card">
              <ResponsiveDataTable value={lastModelPriceDistyList}
                                   emptyMessage="Geçmiş Fiyat Bulunamadı."
                                   paginator
                                   rows={10} rowsPerPageOptions={[10,20,50,100]}
                                   header="Ürünün Geçmiş Distribütör  Fiyat Listesi">
                <Column body={e => moment(e.startDate).format("DD/MM/YYYY")} header="Başlangıç Tarihi"/>
                <Column body={e => moment(e.endDate).format("DD/MM/YYYY")} header="Bitiş Tarihi"/>
                <Column body={e => priceTemplate(e, 'resellerPrice', 'checkResellerPrice')} header="Bayi Alış Fiyatı"/>

              </ResponsiveDataTable>
            </div>
          </div>

        </div>
        <div className="p-col-1">
          <Button label="Geri Dön" icon="pi pi-chevron-left"  onClick={e=>history.push("/ProductPriceAdmin-Disty")} className="p-button-secondary" />
        </div>
      </div>

    </>
  )
}
export default ProductPriceDetailAdmin;
