import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import FaultyProductManagementService from "./httpService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Dialog} from "primereact/dialog";
import {InputTextarea} from "primereact/inputtextarea";
import {InputText} from "primereact/inputtext";
import BarcodeScanner from "../SalesScreen/BarcodeScanner";
import {Dropdown} from "primereact/dropdown";

const FaultyProductExchange = () => {

  const supplyId = JSON.parse(localStorage.getItem('user')).user?.supply?.id;
  const toast = useRef(null);
  const faultyProductManagementService = new FaultyProductManagementService();

  const [loading, setLoading] = useState(false);
  const [searchCriteriaLazyLoading, setSearchCriteriaLazyLoading] = useState(false);
  const [searchCriteriaProgressing, setSearchCriteriaProgressing] = useState(false);
  const [saleInfoList, setSaleInfoList] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState({});

  const [detailModal, setDetailModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [selectedFaultyProductInfoStatus, setSelectedFaultyProductInfoStatus] = useState(null);

  const [selectedTicketId, setSelectedTicketId] = useState(null);

  const [approveTicketModal, setApproveTicketModal] = useState(false);
  const [approveTicketSerialNo, setApproveTicketSerialNo] = useState(false);
  const [approveTicketCargoName, setApproveTicketCargoName] = useState(false);
  const [approveTicketCargoCode, setApproveTicketCargoCode] = useState(false);
  const [approveTicketButtonDisabled, setApproveTicketButtonDisabled] = useState(false);

  const [rejectTicketModal, setRejectTicketModal] = useState(false);
  const [rejectTicketDescription, setRejectTicketDescription] = useState(null);
  const [rejectTicketButtonDisabled, setRejectTicketButtonDisabled] = useState(false);

  const [barcodeScannerVisible, setBarcodeScannerVisible] = useState(false);

  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  const [faultyProductInfoStatusOptions, setFaultyProductInfoStatusOptions] = useState(
    [
      {value: "WAITING", name: "Bekliyor"},
      {value: "APPROVED", name: "Onaylandı"},
      {value: "REJECTED", name: "Reddedildi"}
    ]
  );

  useEffect(() => {
    searchTicket({...paginationConfig, activePage: 0});
  }, []);

  useEffect(() => {
    setSearchCriteriaLazyLoading(true);
  }, [searchCriteria, selectedFaultyProductInfoStatus]);

  useEffect(() => {
    if (searchCriteriaLazyLoading) {
      setTimeout(() => {
        setSearchCriteriaProgressing(true);
        setSearchCriteriaLazyLoading(false);
      }, 2000);
    }
  }, [searchCriteriaLazyLoading]);

  useEffect(() => {
    if (searchCriteriaProgressing) {
      searchTicket({...paginationConfig, activePage: 0});
      setSearchCriteriaProgressing(false);
    }
  }, [searchCriteriaProgressing]);

  const searchTicket = (pageState) => {
    setLoading(true);
    var sort = "";
    if (searchCriteria.sortField != null && searchCriteria.sortOrder != null) {
      sort += searchCriteria.sortField;
      if (searchCriteria.sortOrder == 1) {
        sort += ",asc";
      } else {
        sort += ",desc";
      }
    } else {
      sort += "createDateTime,desc";
    }

    searchCriteria.supplyId = supplyId;
    searchCriteria.faultyProductInfoStatus = selectedFaultyProductInfoStatus;

    faultyProductManagementService.searchTicket(searchCriteria, pageState.itemsPerPage, pageState.activePage, sort).then(res => {
      console.log("searchSaleInfo", res.content);
      if (res.content && res.content.length > 0) {
        setSaleInfoList(res.content);

        pageState.totalRecords = res.totalElements;
        pageState.first = pageState.itemsPerPage * pageState.activePage;
        setPaginationConfig({
          ...pageState,
        });
      } else {
        setSaleInfoList([]);
      }
    }).catch(err => {
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'İşlem bilgisi getirme başarısız.'
      });
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  };

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    searchTicket(pageState);
  };

  const onFilter = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const onSort = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const detailModalShow = (e) => {
    setDetailModal(true);
    saleInfoList.forEach(s => {
      if (s.id == e.currentTarget.id) {
        setSelectedProduct(s);
      }
    });
  };

  const detaillModalHide = () => {
    setDetailModal(false);
  };

  const approveTicketModelShow = (e) => {
    saleInfoList.forEach(s => {
      if (s.id == e.currentTarget.id) {
        setSelectedTicketId(s.id);
      }
    });
    setApproveTicketSerialNo(null);
    setApproveTicketCargoName(null);
    setApproveTicketCargoCode(null);
    setApproveTicketButtonDisabled(false);
    setApproveTicketModal(true);
  };

  const approveTicketModelHide = () => {
    setSelectedTicketId(null);
    setApproveTicketSerialNo(null);
    setApproveTicketCargoName(null);
    setApproveTicketCargoCode(null);
    setApproveTicketButtonDisabled(false);
    setApproveTicketModal(false);
  };

  const approveTicket = () => {
    if (approveTicketSerialNo != null && approveTicketSerialNo != "" && approveTicketCargoName != null && approveTicketCargoName != "" && approveTicketCargoCode != null && approveTicketCargoCode != "") {
      setApproveTicketButtonDisabled(true);
      const request = {
        ticketId: selectedTicketId,
        serialNo: approveTicketSerialNo,
        cargoName: approveTicketCargoName,
        cargoCode: approveTicketCargoCode
      };
      faultyProductManagementService.approveTicket(request).then(res => {
        console.log("approveTicket", res);
        toast.current.show({
          severity: 'success',
          summary: 'Başarılı İşlem',
          detail: 'Değişim onaylandı.'
        });
        approveTicketModelHide();
        searchTicket(paginationConfig);
      }).catch(err => {
        console.log(err);
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: err.response.data.message,
          life: 4000
        });
        setApproveTicketButtonDisabled(false);
      });
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Değişimi onaylamak için bütün alanların doldurulması zorunludur.'
      });
    }
  };

  const rejectTicketModalShow = (e) => {
    saleInfoList.forEach(s => {
      if (s.id == e.currentTarget.id) {
        setSelectedTicketId(s.id);
      }
    });
    setRejectTicketDescription(null);
    setRejectTicketButtonDisabled(false);
    setRejectTicketModal(true);
  };

  const rejectTicketModelHide = () => {
    setSelectedTicketId(null);
    setRejectTicketDescription(null);
    setRejectTicketButtonDisabled(false);
    setRejectTicketModal(false);
  };

  const rejectTicket = () => {
    if (rejectTicketDescription != null) {
      setRejectTicketButtonDisabled(true);

      const request = {
        ticketId: selectedTicketId,
        description: rejectTicketDescription
      };
      faultyProductManagementService.rejectTicket(request).then(res => {
        console.log("rejectTicket", res);
        toast.current.show({
          severity: 'success',
          summary: 'Başarılı İşlem',
          detail: 'Değişim reddedildi.'
        });
        rejectTicketModelHide();
        searchTicket(paginationConfig);
      }).catch(err => {
        console.log(err);
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Değişim reddedilemedi.'
        });
        setRejectTicketButtonDisabled(false);
      });
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Değişimin reddedilmesinin nedenini yazmak zorunludur.'
      });
    }
  };

  const scan = (err, result) => {
    if (result) {
      setApproveTicketSerialNo(result.text);
      setBarcodeScannerVisible(false);
      console.log(result);
      toast.current?.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Seri No Okundu",
        life: 3000,
      });
    }
  };

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const createDateTimeStringTemplate = (data) => {
    return responsiveColumn("Bildirim Tarihi", data?.createDateTimeString)
  };

  const faultyProductInfoStatusDisplayTemplate = (data) => {
    return responsiveColumn("Durum", data?.faultyProductInfoStatusDisplay)
  };

  const serialNoTemplate = (data) => {
    return responsiveColumn("Seri Numara", data?.productSerialNo?.serialNo)
  };

  const barcodeTemplate = (data) => {
    return responsiveColumn("Barkod", data?.model?.barcode)
  };

  const resellerCodeTemplate = (data) => {
    return responsiveColumn("Bayi", data?.reseller?.code + " - " + data?.reseller?.name)
  };

  const accessoryCampaignSerialTitleButtonTemplate = (data) => {
    return (
      <div className="p-d-flex p-justify-even">
        <Button id={data.id} type="button" className="p-button-sm p-button-info" label="Detay"
                onClick={detailModalShow}/>
        {data.faultyProductInfoStatus == "WAITING" &&
        <Button id={data.id} type="button" className="p-button-sm p-button-danger" label="Değişim Ret"
                onClick={rejectTicketModalShow}/>}
        {data.faultyProductInfoStatus == "WAITING" &&
        <Button id={data.id} type="button" className="p-button-sm p-button-success" label="Değişim Onay"
                onClick={approveTicketModelShow}/>}
      </div>
    );
  };

  const renderSaleStatusFilter = () => {
    return (
      <Dropdown value={selectedFaultyProductInfoStatus} options={faultyProductInfoStatusOptions}
                onChange={(e) => setSelectedFaultyProductInfoStatus(e.value)}
                optionLabel="name" optionValue="value" showClear className="p-column-filter"/>
    );
  };

  const saleStatusFilterElement = renderSaleStatusFilter();

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-12">
            <ResponsiveDataTable
              lazy
              header="Bildirilen Arızalı Ürünler"
              value={saleInfoList}
              paginationConfig={paginationConfig}
              onActivePageChange={onActivePageChange}
              onFilter={onFilter}
              filters={searchCriteria.filters}
              onSort={onSort}
              sortField={searchCriteria.sortField}
              sortOrder={searchCriteria.sortOrder}
              loading={loading}
              emptyMessage="Bildirilen arızalı ürün bulunamadı"
            >
              <Column field="createDateTime" body={createDateTimeStringTemplate} header="Bildirim Tarihi" sortable/>
              <Column field="faultyProductInfoStatusDisplay" body={faultyProductInfoStatusDisplayTemplate}
                      header="Durum" filter={true}
                      filterElement={saleStatusFilterElement}/>
              <Column field="serialNo" body={serialNoTemplate} header="Seri Numara" filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="barcode" body={barcodeTemplate} header="Barkod" filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="resellerCode" body={resellerCodeTemplate} header="Bayi" filter={true}
                      filterMatchMode={"contains"} headerStyle={{width: "25%"}}/>
              <Column body={accessoryCampaignSerialTitleButtonTemplate}
                      headerStyle={{width: "21rem", textAlign: "center"}}
                      bodyStyle={{textAlign: 'center', overflow: 'visible', justifyContent: 'center'}}/>
            </ResponsiveDataTable>
          </div>
        </div>
        <Dialog header="Arızalı Ürün Detay" visible={detailModal} modal style={{width: '1000px'}}
                onHide={detaillModalHide}>
          <div className="datatable-responsive-demo">
            <div className="p-grid">
              <div className="p-field p-col-12 p-md-6">
                <p className="marginParagraph"><b>Bildirim Tarihi:</b> {selectedProduct?.createDateTimeString}</p>
                <p className="marginParagraph"><b>Durum:</b> {selectedProduct?.faultyProductInfoStatusDisplay}</p>
                <p className="marginParagraph"><b>Bayi Adı:</b> {selectedProduct?.reseller?.name}</p>
                <p className="marginParagraph"><b>Bayi Kodu:</b> {selectedProduct?.reseller?.code}</p>
                <p className="marginParagraph"><b>Bayi Eposta:</b> {selectedProduct.reseller?.email}</p>
                <p className="marginParagraph"><b>Bayi Tel:</b> {selectedProduct.reseller?.phoneNumber}</p>
                <p className="marginParagraph"><b>Bayi Ana Bölge:</b> {selectedProduct.reseller?.mainArea}</p>
                <p className="marginParagraph"><b>Bayi Bölge:</b> {selectedProduct.reseller?.area}</p>
                <p className="marginParagraph"><b>Bayi İl:</b> {selectedProduct.reseller?.resellerProvince?.name}</p>
                <p className="marginParagraph"><b>Bayi İlçe:</b> {selectedProduct.reseller?.resellerDistrict?.name}</p>
                <p className="marginParagraph"><b>Bayi Adres:</b> {selectedProduct.reseller?.addressText}</p>
              </div>
              <div className="p-field p-col-12 p-md-6">
                <p className="marginParagraph"><b>Ürün Seri
                  Numara:</b> {selectedProduct?.productSerialNo?.serialNo != null ? selectedProduct?.productSerialNo?.serialNo : "Yok"}
                </p>
                <p className="marginParagraph"><b>Ürün Marka:</b> {selectedProduct.model?.brand?.name}</p>
                <p className="marginParagraph"><b>Ürün Model:</b> {selectedProduct.model?.name}</p>
                <p className="marginParagraph"><b>Ürün Barkod:</b> {selectedProduct.model?.barcode}</p>
                <hr/>
                {selectedProduct?.saleDateString &&
                <p className="marginParagraph"><b>Satış Tarihi:</b> {selectedProduct?.saleDateString}
                </p>}
                <p className="marginParagraph"><b>Arıza
                  Açıklama:</b> {selectedProduct.description != null ? selectedProduct.description : "Yok"}</p>

                <p className="marginParagraph"><b>Ürünün Fotoğrafları</b></p>
                {selectedProduct && selectedProduct.photos.map((p, i) => {
                  return (<div><a href={p.publicAddress} target="_blank">Fotoğraf {i + 1}</a></div>)
                })}
                {selectedProduct?.faultyProductInfoStatus != "WAITING" && <hr/>}
                {selectedProduct?.completedDateString && <p className="marginParagraph"><b>Bildirimin sonuçlanma
                  tarihi:</b> {selectedProduct.completedDateString}</p>}
                {selectedProduct?.rejectDescription && <p className="marginParagraph"><b>Değişimin reddedilmesinin
                  nedeni:</b> {selectedProduct.rejectDescription}</p>}
                {selectedProduct?.productSaleInfo &&
                <p className="marginParagraph"><b>Yeni ürünün seri
                  numarası:</b> {selectedProduct.productSaleInfo.productSaleInfoDetailList[0].serialNo}
                </p>}
                {selectedProduct?.cargoName &&
                <p className="marginParagraph"><b>Kargo firmasının adı:</b> {selectedProduct.cargoName}
                </p>}
                {selectedProduct?.cargoCode &&
                <p className="marginParagraph"><b>Kargo takip numarası:</b> {selectedProduct.cargoCode}
                </p>}
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog header="Değişim Onay" visible={approveTicketModal} modal style={{width: '360px'}}
                onHide={approveTicketModelHide}>
          <span>Yeni Ürünün Seri Numarası</span>
          <div className="p-inputgroup" style={{width: 260, marginBottom: 10}}>
            <InputText id="approveTicketSerialNo" value={approveTicketSerialNo}
                       placeholder="Seri Numara"
                       onChange={(e) => setApproveTicketSerialNo(e.target.value)} style={{marginTop: 5}}/>
            <Button style={{marginTop: 5}} icon="pi pi-camera" className="p-button-warning"
                    disabled={approveTicketButtonDisabled}
                    onClick={() => setBarcodeScannerVisible(true)}/>
          </div>
          <BarcodeScanner
            scan={scan}
            visible={barcodeScannerVisible}
            onHide={() => setBarcodeScannerVisible(false)}
          />
          <span>Kargo Firmasının Adı</span>
          <div className="p-inputgroup" style={{width: 260, marginBottom: 10}}>
            <InputText id="approveTicketCargoName" value={approveTicketCargoName}
                       placeholder="Kargo Firmasının Adı"
                       onChange={(e) => setApproveTicketCargoName(e.target.value)} style={{marginTop: 5}}/>
          </div>
          <span>Kargo Takip Numarası</span>
          <div className="p-inputgroup" style={{width: 260, marginBottom: 10}}>
            <InputText id="approveTicketCargoName" value={approveTicketCargoCode}
                       placeholder="Kargo Takip Numarası"
                       onChange={(e) => setApproveTicketCargoCode(e.target.value)} style={{marginTop: 5}}/>
          </div>
          <Button style={{width: 170, marginTop: 10}} type="button" label="Değişimi Onayla"
                  className="p-button"
                  disabled={approveTicketButtonDisabled}
                  onClick={approveTicket}/>
        </Dialog>
        <Dialog header="Değişim Ret" visible={rejectTicketModal} modal style={{width: '360px'}}
                onHide={rejectTicketModelHide}>
          <span>Değişimin reddedilmesinin nedenini yazınız.</span>
          <InputTextarea id="rejectTicketDescription" value={rejectTicketDescription} rows={5} cols={40} autoResize
                         onChange={(e) => setRejectTicketDescription(e.target.value)} style={{marginTop: 5}}/>
          <Button style={{width: 170, marginTop: 10}} type="button" label="Değişimi Reddet"
                  className="p-button"
                  disabled={rejectTicketButtonDisabled}
                  onClick={rejectTicket}/>
        </Dialog>
      </div>
    </>
  )

};

export default FaultyProductExchange;
