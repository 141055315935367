import AspAxios from "../../service/AspAxios";
import * as Paths from '../../service/config'

export default class ReportingService {

  getCalculationsValues() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getCalculationsValues).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  getGiftScore() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.giftScore)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

};
