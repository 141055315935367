import AspAxios from "../../service/AspAxios";
import * as Paths from "../../service/config";

var config = {
  headers: {
    "Application-Name": "map",
  },
};
export function updateSmsTemplate(body) {
  return new Promise((resolve, reject) => {
    AspAxios.post(Paths.updateSmsTemplate, body, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateMailTemplate(body) {
  return new Promise((resolve, reject) => {
    AspAxios.post(Paths.updateMailTemplate, body, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAllSmsTemplate() {
  return new Promise((resolve, reject) => {
    AspAxios.get(Paths.getAllSmsTemplate, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getTemplateBySmsType(type) {
  return new Promise((resolve, reject) => {
    AspAxios.get(Paths.getTemplateBySmsType + type, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAllMailTemplate() {
  return new Promise((resolve, reject) => {
    AspAxios.get(Paths.getAllMailTemplate, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getTemplateByMailType(type) {
  return new Promise((resolve, reject) => {
    AspAxios.get(Paths.getTemplateByMailType + type, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
