import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import FaultyProductManagementService from "./httpService";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import BarcodeScanner from "../SalesScreen/BarcodeScanner";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { Calendar } from "primereact/calendar";
import { Column } from "jspdf-autotable";
import { ResponsiveDataTableWithoutRowClass } from "../../components/ResponsiveDataTableWithoutRowClass";

const FaultyProductNotification = () => {
  const distyId = JSON.parse(localStorage.getItem("user")).user?.loginReseller
    ?.disty?.id;
  const resellerId = JSON.parse(localStorage.getItem("user")).user
    ?.loginReseller?.id;
  const toast = useRef(null);
  const faultyProductManagementService = new FaultyProductManagementService();

  const [serialNo, setSerialNo] = useState(null);
  const [serialNoNotExist, setSerialNoNotExist] = useState(false);

  const [barcodeScannerVisible, setBarcodeScannerVisible] = useState(false);
  const [allBrand, setAllBrand] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [modelOptions, setModelOptions] = useState(null);
  const [selectedModelOption, setSelectedModelOption] = useState(null);

  const [faultyProductLoading, setFaultyProductLoading] = useState(false);

  const [
    searchProductWithSerialButtonDisabled,
    setSearchProductWithSerialButtonDisabled,
  ] = useState(false);
  const [selectedProductSerialNo, setSelectedProductSerialNo] = useState(null);

  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedSupply, setSelectedSupply] = useState(null);

  const [selectedDescription, setSelectedDescription] = useState(null);
  const [selectedSaleDate, setSelectedSaleDate] = useState(null);
  const [selectedImageFiles, setSelectedImageFiles] = useState(null);
  const [sendToSupplyButtonDisabled, setSendToSupplyButtonDisabled] =
    useState(false);
  const [data, setData] = useState([]);


  const sortData = (data) => {
    const x = data?.sort(function(a, b){
    if(a.name < b.name) { return -1; }
    if(a.name > b.name) { return 1; }
    return 0;
})
return x;
}

  useEffect(() => {
    faultyProductManagementService
      .getAllSupply()
      .then((res) => {
        setData(sortData(res));
      })
      .catch((err) => {
        toast?.current?.show({
          severity: "error",
          summary: "Hata",
          detail: "Distributör getirme başarısız.",
        });
      });
  }, []);

  useEffect(() => {
    if (serialNoNotExist) {
      faultyProductManagementService
        .getAllBrand()
        .then((res) => {
          console.log("getAllBrand", res);
          setAllBrand(res.filter((value) => value.status === 1));
        })
        .catch((err) => {
          toast?.current?.show({
            severity: "error",
            summary: "Hata",
            detail: "Markaları getirme başarısız.",
          });
          console.log(err);
        });
      faultyProductManagementService
        .getAllCategories()
        .then((res) => {
          console.log("getAllCategories", res);
          setAllCategories(sortData(res));
        })
        .catch((err) => {
          toast?.current?.show({
            severity: "error",
            summary: "Hata",
            detail: "Kategorileri getirme başarısız.",
          });
          console.log(err);
        });
    }
  }, [serialNoNotExist]);

  useEffect(() => {
    if (selectedCategory != null) {
      const request = {
        id: selectedCategory,
      };
      faultyProductManagementService
        .getSubCategoryByCategory(request)
        .then((res) => {
          console.log("getSubCategoryByCategory", res);
          setSubCategories(sortData(res));
        })
        .catch((err) => {
          toast?.current?.show({
            severity: "error",
            summary: "Hata",
            detail: "Alt kategorileri getirme başarısız.",
          });
          console.log(err);
        });
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedBrand != null && selectedSubCategory != null) {
      faultyProductManagementService
        .findModelList(selectedBrand, selectedSubCategory)
        .then((res) => {
          console.log("findModelList", res);
          setModelOptions(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedBrand, selectedSubCategory]);

  useEffect(() => {
    if (selectedModel != null) {
      faultyProductManagementService
        .findSupplyByBarcode(selectedModel.barcode, distyId)
        .then((res) => {
          console.log("findSupplyByBarcode", res);
          setSelectedSupply(res);
        })
        .catch((err) => {
          console.log(err);
          toast.current.show({
            severity: "error",
            summary: "Hata",
            detail: err.response.data.message,
            life: 4000,
          });
        })
        .finally(() => {
          setFaultyProductLoading(false);
        });
    }
  }, [selectedModel]);

  const scan = (err, result) => {
    if (result) {
      setSerialNo(result.text);
      setBarcodeScannerVisible(false);
      console.log(result);
      toast.current?.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Seri No Okundu",
        life: 3000,
      });
    }
  };

  const searchProductWithSerial = () => {
    if (serialNo != null && serialNo != "") {
      setFaultyProductLoading(true);
      setSearchProductWithSerialButtonDisabled(true);
      faultyProductManagementService
        .findBySerialNo(serialNo)
        .then((res) => {
          console.log("findBySerialNo", res);
          setSelectedProductSerialNo(res);
          setSelectedModel(res.model);
        })
        .catch((err) => {
          console.log(err);
          toast.current.show({
            severity: "error",
            summary: "Hata",
            detail: err.response.data.message,
            life: 4000,
          });
          setSearchProductWithSerialButtonDisabled(false);
          setFaultyProductLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Uyarı",
        detail: "Seri no dolu olmalıdır.",
      });
    }
  };

  const selectModelOption = () => {
    setFaultyProductLoading(true);
    setSelectedModel(selectedModelOption);
  };

  const photoRemoveHandler = (e) => {
    let newImageFiles = [];
    if (selectedImageFiles != null) {
      selectedImageFiles.forEach((i) => {
        if (i.objectURL != e.file.objectURL) {
          newImageFiles.push(i);
        }
      });
    }
    setSelectedImageFiles(newImageFiles);
  };

  const photoUploadHandler = ({ files }) => {
    setSelectedImageFiles(files);
  };

  const sendToSupply = () => {
    if (selectedProductSerialNo != null || selectedSaleDate != null) {
      if (selectedDescription != null && selectedDescription != "") {
        if (selectedImageFiles != null && selectedImageFiles.length > 0) {
          setSendToSupplyButtonDisabled(true);
          let formData = new FormData();
          if (selectedProductSerialNo != null) {
            formData.append("productSerialNoId", selectedProductSerialNo.id);
          }
          formData.append("modelId", selectedModel.id);
          formData.append("supplyId", selectedSupply.id);
          formData.append("resellerId", resellerId);
          formData.append("description", selectedDescription);
          if (selectedSaleDate != null) {
            formData.append(
              "saleDate",
              selectedSaleDate.toLocaleDateString("tr-TR")
            );
          }

          selectedImageFiles.forEach((value) => {
            formData.append("file", value);
          });
          faultyProductManagementService
            .createTicket(formData)
            .then((res) => {
              console.log("createTicket", formData);
              toast.current.show({
                severity: "success",
                summary: "Başarılı İşlem",
                detail: "Arızalı ürün tedarikçiye bildirildi.",
              });
              setTimeout(function () {
                window.location.reload();
              }, 3000);
            })
            .catch((err) => {
              setSendToSupplyButtonDisabled(false);
              console.log(err);
              toast.current.show({
                severity: "error",
                summary: "Hata",
                detail: err.response.data.message,
                life: 4000,
              });
            });
        } else {
          toast.current.show({
            severity: "warn",
            summary: "Uyarı",
            detail: "En az 1 fotoğraf zorunlur.",
          });
        }
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Uyarı",
          detail: "Arıza açıklaması zorunlur.",
        });
      }
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Uyarı",
        detail: "Satış tarihi zorunlur.",
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-7 p-md-3 p-fluid">
            Ürün Seri No
            <div className="p-inputgroup">
              <InputText
                style={{ marginTop: 5 }}
                value={serialNo}
                placeholder="Seri No"
                disabled={
                  serialNoNotExist || searchProductWithSerialButtonDisabled
                }
                onChange={(e) => setSerialNo(e.target.value)}
              />
              <Button
                style={{ marginTop: 5 }}
                icon="pi pi-camera"
                className="p-button-warning"
                disabled={
                  serialNoNotExist || searchProductWithSerialButtonDisabled
                }
                onClick={() => setBarcodeScannerVisible(true)}
              />
            </div>
          </div>
          <BarcodeScanner
            scan={scan}
            visible={barcodeScannerVisible}
            onHide={() => setBarcodeScannerVisible(false)}
          />
          <div className="p-col-5 p-md-2 p-fluid">
            <br />
            <Button
              style={{ marginTop: 5 }}
              type="button"
              icon="pi pi-search"
              label="Ürünü Ara"
              className="p-button"
              disabled={
                serialNoNotExist || searchProductWithSerialButtonDisabled
              }
              onClick={searchProductWithSerial}
            />
          </div>
          <div className="p-col-12">
            <Checkbox
              style={{ marginTop: 10, marginRight: 5 }}
              checked={serialNoNotExist}
              disabled={selectedProductSerialNo != null || selectedModel}
              onChange={(e) => setSerialNoNotExist(e.checked)}
            />
            Ürün Seri No Yok
          </div>
          {serialNoNotExist && (
            <div className="p-col-12 p-md-2 p-fluid">
              <Dropdown
                id="brand"
                value={selectedBrand}
                options={allBrand}
                showClear
                placeholder="Marka Seçiniz"
                optionLabel="name"
                optionValue="id"
                emptyMessage="Marka bulunamadı"
                disabled={selectedModel}
                onChange={(e) => {
                  setSelectedBrand(e.target.value);
                  setSelectedModelOption(null);
                }}
              />
            </div>
          )}
          {serialNoNotExist && selectedBrand != null && (
            <div className="p-col-12 p-md-2 p-fluid">
              <Dropdown
                id="brand"
                value={selectedCategory}
                options={allCategories}
                showClear
                placeholder="Kategori Seçiniz"
                optionLabel="name"
                optionValue="id"
                emptyMessage="Kategori bulunamadı"
                disabled={selectedModel}
                onChange={(e) => {
                  setSelectedCategory(e.target.value);
                  setSelectedSubCategory(null);
                  setSelectedModelOption(null);
                }}
              />
            </div>
          )}
          {serialNoNotExist &&
            selectedBrand != null &&
            selectedCategory != null && (
              <div className="p-col-12 p-md-2 p-fluid">
                <Dropdown
                  id="brand"
                  value={selectedSubCategory}
                  options={subCategories}
                  showClear
                  placeholder="Alt Kategori Seçiniz"
                  optionLabel="name"
                  optionValue="id"
                  emptyMessage="Alt kategori bulunamadı"
                  disabled={selectedModel}
                  onChange={(e) => {
                    setSelectedSubCategory(e.target.value);
                    setSelectedModelOption(null);
                  }}
                />
              </div>
            )}
          {serialNoNotExist &&
            selectedBrand != null &&
            selectedCategory != null &&
            selectedSubCategory != null && (
              <div className="p-col-12 p-md-3 p-fluid">
                <Dropdown
                  id="model"
                  value={selectedModelOption}
                  options={modelOptions}
                  showClear
                  placeholder="Ürün Seçiniz"
                  optionLabel="name"
                  emptyMessage="Ürün bulunamadı"
                  disabled={selectedModel}
                  onChange={(e) => {
                    setSelectedModelOption(e.target.value);
                  }}
                />
              </div>
            )}
          {serialNoNotExist &&
            selectedBrand != null &&
            selectedCategory != null &&
            selectedSubCategory != null &&
            selectedModelOption != null && (
              <div className="p-col-12 p-md-2 p-fluid">
                <Button
                  type="button"
                  icon="pi pi-reply"
                  label="Ürünü Seç"
                  className="p-button"
                  disabled={selectedModel}
                  onClick={selectModelOption}
                />
              </div>
            )}
          {faultyProductLoading && selectedModel != null && (
            <div
              className="p-col-12"
              style={{ alignSelf: "center", width: 400, marginTop: 10 }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <ProgressSpinner
                  style={{
                    width: "40px",
                    height: "40px",
                    marginRight: 5,
                    marginLeft: 10,
                  }}
                  strokeWidth="8"
                  fill="#EEEEEE"
                  animationDuration=".5s"
                />
                <b>Ürün aranıyor. Lütfen bekleyiniz.</b>
              </div>
            </div>
          )}
          {!faultyProductLoading &&
            selectedModel != null &&
            selectedSupply != null && (
              <div className="p-col-12">
                <hr />
                <p>
                  <b>Ürünün Tedarikçisi:</b> {selectedSupply?.name}
                </p>
                <p>
                  <b>Ürünün Markası:</b> {selectedModel?.brand.name}
                </p>
                <p>
                  <b>Ürünün Modeli:</b> {selectedModel?.name}
                </p>
                <p>
                  <b>Ürünün Barkodu:</b> {selectedModel?.barcode}
                </p>
                {selectedProductSerialNo == null && (
                  <p>
                    <b>Satış Tarihi (Zorunlu)</b>
                  </p>
                )}
                {selectedProductSerialNo == null && (
                  <Calendar
                    id="baslangic"
                    showIcon
                    dateFormat={"dd.mm.yy"}
                    value={selectedSaleDate}
                    onChange={(e) => setSelectedSaleDate(e.value)}
                  />
                )}
                <p style={{ marginTop: 10 }}>
                  <b>Arıza Açıklaması (Zorunlu)</b>
                </p>
                <InputTextarea
                  id="biyo"
                  value={selectedDescription}
                  rows={5}
                  cols={40}
                  autoResize
                  onChange={(e) => setSelectedDescription(e.target.value)}
                />
                <p style={{ marginTop: 10 }}>
                  <b>Ürünün Fotoğrafları (En Az 1 Fotoğraf Zorunlu)</b>
                </p>
                <FileUpload
                  name="photo"
                  accept="image/*"
                  maxFileSize={1000000}
                  className={"p-col-12 p-md-5"}
                  style={{ padding: 0 }}
                  disabled={sendToSupplyButtonDisabled}
                  chooseLabel="Fotoğraf Seç"
                  customUpload={true}
                  uploadHandler={photoUploadHandler}
                  onRemove={photoRemoveHandler}
                  auto
                  emptyTemplate={
                    <p className="p-m-0">
                      Yüklemek için fotoğrafı buraya sürükleyip bırakın.
                    </p>
                  }
                />
                <Button
                  style={{ width: 170, marginTop: 10 }}
                  type="button"
                  icon="pi pi-send"
                  label="Tedarikçiye Bildir"
                  className="p-button p-button-success"
                  disabled={sendToSupplyButtonDisabled}
                  onClick={sendToSupply}
                />
              </div>
            )}
          {!faultyProductLoading &&
            selectedModel != null &&
            selectedSupply == null && (
              <p>
                <b>Ürünün tedarikçisi bulunamadı.</b>
              </p>
            )}
        </div>
        <ResponsiveDataTableWithoutRowClass
          lazy
          value={data}
          style={{ fontSize: "13px" }}
        >
          <Column field="name" header="Tedarikçi ismi"/>
          <Column field="code" header="Tedarikçi kodu"/>
          <Column field="shippingName" header="Kargo ismi"/>
          <Column field="postCode" header="Kargo kodu"/>
          <Column field="address" header="Adres"/>
          <Column field="phoneNumber" header="Telefon Numarası"/>
        </ResponsiveDataTableWithoutRowClass>
      </div>
    </>
  );
};

export default FaultyProductNotification;
