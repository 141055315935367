const buildStage = process.env.REACT_APP_STAGE;
const local = false;

const localUrl = "http://localhost:1555/";
const CMV_TEST_URL = "http://api.map.cmvteknoloji.com/";
const TTG_TEST_URL = "https://api-test.tt-aksesuar.com/";
const TTG_PROD_URL = "https://api.tt-aksesuar.com/";
let baseUrl;
if (buildStage === "dev") {
  baseUrl = local ? localUrl : CMV_TEST_URL;
} else if (buildStage === "test") {
  baseUrl = TTG_TEST_URL;
} else if (buildStage === "prod") {
  baseUrl = TTG_PROD_URL;
}

let nott_url;
if (buildStage === "dev") {
  nott_url = "http://nott.cmvteknoloji.com/";
} else if (buildStage === "test") {
  nott_url = "https://not-test.tt-servis.com//";
} else if (buildStage === "prod") {
  nott_url = "https://not.tt-servis.com/";
}

const localUrlAuth = "http://localhost:63195/";
const CMV_AUTH_TEST_URL = "http://auth.asp.cmvteknoloji.com/";
const TTG_AUTH_TEST_URL = "https://auth-test.tt-servis.com/";
const TTG_AUTH_PROD_URL = "https://auth.tt-servis.com/";
let baseAuthUrl;
if (buildStage === "dev") {
  baseAuthUrl = local ? localUrlAuth : CMV_AUTH_TEST_URL;
} else if (buildStage === "test") {
  baseAuthUrl = TTG_AUTH_TEST_URL;
} else if (buildStage === "prod") {
  baseAuthUrl = TTG_AUTH_PROD_URL;
}


export const login = baseAuthUrl + "public/login";
export const takeOtpCodeWeb = baseUrl + "public/takeOtpCodeWeb/";
export const loginWithOtpCode = baseUrl + "public/loginWithOtpCode";
export const logout = baseAuthUrl + "public/logout";
export const refresh = baseAuthUrl + "public/refresh";
export const forgetPassword = baseAuthUrl + "public/forgotPassword";
export const resetPassword = baseAuthUrl + "public/resetPassword";
export const userDetail = baseUrl + "user/own";
export const userDetailWeb = baseUrl + "user/ownWeb";
export const resellerLogin = baseUrl + "public/login/reseller";
export const getResellerGsmNoList = baseUrl + "public/user/reseller/";
export const sendVerificationCode = baseUrl + "public/takeOtp/";
export const getChildReseller = baseUrl + "reseller/child";
export const saveSelectReseller = baseUrl + "reseller/moveToSubReseller";


//Marj

export const getAllMarj = baseUrl + "marj/getAll";
export const upsertlMarj = baseUrl + "marj/upsert";

//PRODUCTPRICE-SUPPLY
export const getCurrentModelPricesSupply = baseUrl + "modelPriceSupply/current/getAll";
export const getCurrentModelPricesSupplyList = baseUrl + "modelPriceSupply/current/findAll";


export const getCurrentModelPricesSupplyForExcel = baseUrl + "modelPriceSupply/current/getAll/excel";
export const getCurrentModelPriceSupply = baseUrl + "modelPriceSupply/current/";
export const getLastModelPriceSupply = baseUrl + "modelPriceSupply/last/";
export const updateModelPriceSupply = baseUrl + "modelPriceSupply/update";
export const modelPriceSupplyRejectedList = baseUrl + "modelPriceSupply/rejectedList/";
export const savePriceExcel = baseUrl + "modelPriceSupply/excel";

//Document
export const documentUpload = baseUrl + "document/upload";

//CATEGORY-SUBCATEGORY
export const getAllCategory = baseUrl + "category/getAll";
export const getCategoryTree = baseUrl + "category/tree";
export const upsertCategory = baseUrl + "category/upsert"
export const deleteCategory = baseUrl + "category/delete"

export const getSubCategoryByCategory = baseUrl + "subCategory/byCategory";
export const getAllSubCategory = baseUrl + "subCategory/getAll";
export const upsertSubCategory = baseUrl + "subCategory/upsert";
export const deleteSubCategory = baseUrl + "subCategory/delete";


//PRODUCTPRICE-DISTY
export const getCurrentModelPricesDisty = baseUrl + "modelPriceDisty/current/getAll";
export const getCurrentModelPricesForReseller = baseUrl + "current/modelPrices/getAll";
export const getCurrentModelPricesForResellerList = baseUrl + "current/modelPrices/findAll";
export const getCurrentModelPricesDistyList = baseUrl + "modelPriceDisty/current/findAll";


export const getCurrentModelPricesDistyForExcel = baseUrl + "modelPriceDisty/current/getAll/excel";
export const getCurrentModelPriceDisty = baseUrl + "modelPriceDisty/current/";
export const getLastModelPriceDisty = baseUrl + "modelPriceDisty/last/";
export const updateModelPriceDisty = baseUrl + "modelPriceDisty/update";

//PRODUCTPRICE-ADMIN
export const getCurrentModelPricesSupplyForAdmin = baseUrl + "modelPriceAdmin/current/supply/getAll"
export const getCurrentModelPricesDistyForAdmin = baseUrl + "modelPriceAdmin/current/disty/getAll"
export const getCurrentModelPricesDistyForAdminList = baseUrl + "modelPriceAdmin/current/disty/findAll"
export const getModelPriceDetail = baseUrl + "modelPriceAdmin/current/detail"
export const getLastModelPriceDetail = baseUrl + "modelPriceAdmin/last/detail"


//PRODUCT
export const getModelTree = baseUrl + "model/tree/admin";
export const getModelTreeSupply = baseUrl + "model/tree/supply";
export const getModelTreeDisty = baseUrl + "model/tree/disty";

export const getAllWaitingProducts = baseUrl + "model/waiting/getAll";
export const getAllWaitingProductsList = baseUrl + "model/waiting/findAll";
export const approveModel = baseUrl + "model/approve";
export const approveModelList = baseUrl + "model/approvelist";

export const getById = baseUrl + "model/byid";
export const upsertModel = baseUrl + "model/upsert";
export const upsertModelPhoto = baseUrl + "model/photo/upsert";
export const deleteModelPhoto = baseUrl + "model/photo/delete";
export const getAllModelAdmin = baseUrl + "model/getAll/admin";
export const getAllModelAdminList = baseUrl + "model/findAll/admin";
export const getAllModelAdminListIds = baseUrl + "model/findAll/admin/ids";

export const addToDashboard = baseUrl + "model/add/dashboard";
export const removeToDashboard = baseUrl + "model/remove/dashboard";

export const getAllModelSupply = baseUrl + "model/getAll/supply";
export const getAllModelSupplyList = baseUrl + "model/findAll/supply";

//USER
export const getAssignableRole = baseUrl + "role/assignable/";
export const searchUser = baseUrl + "user/search";
export const searchUserOwn = baseUrl + "user/search/own";
export const searchUserTtg = baseUrl + "user/search/ttg";
export const searchUserTtgList = baseUrl + "user/search/ttg/list";
export const searchUserReseller = baseUrl + "user/search/reseller";
export const findAllActiveResellers = baseUrl + "reseller/getAll";

export const searchUserResellerList = baseUrl + "user/search/reseller/list";
export const searchUserResellerListPage = baseUrl + "user/search/reseller/list/page";


export const upsertUser = baseUrl + "user/upsert";
export const getUser = baseUrl + "user/get";
export const deleteUser = baseUrl + "user/delete";
export const activeUser = baseUrl + "user/active";

//enums
export const allRoles = baseUrl + "enum/role";
export const allRolesByUsage = baseUrl + "enum/role/";
export const allApproveTypes = baseUrl + "enum/approvetype";
export const allScoreType = baseUrl + "enum/scoretype";
export const stockType = baseUrl + "enum/stockType";
export const labelType = baseUrl + "enum/labelType";
export const orderType = baseUrl + "enum/orderType";

//DISTY
export const searchDisty = baseUrl + "disty/search";
export const getAllDisty = baseUrl + "disty/all";


//EXCEL UPLOAD

export const readExcelForModel = baseUrl + "model/readExcel";
export const saveExcelForModel = baseUrl + "model/saveExcel";

export const readExcelForModelPriceSupply = baseUrl + "modelPriceSupply/readExcel";
export const saveExcelForModelPriceSupply = baseUrl + "modelPriceSupply/saveExcel";

export const readExcelForModelPriceDisty = baseUrl + "modelPriceDisty/readExcel";
export const saveExcelForModelPriceDisty = baseUrl + "modelPriceDisty/saveExcel";


export const updateSmsTemplate = nott_url + "sms/updateTemplate";
export const updateMailTemplate = nott_url + "mail/updateTemplate";
export const getAllSmsTemplate = nott_url + "sms/getAllTemplates";
export const getAllMailTemplate = nott_url + "mail/getAllTemplates";
export const getTemplateBySmsType = nott_url + "sms/getTemplateBySmsType/";
export const getTemplateByMailType = nott_url + "mail/getTemplateByMailType/";

//VOUCHER
export const ttCrmGetAll = baseUrl + "ttCrm/getAll";
export const ttCrmUpdateSerialUsage = baseUrl + "ttCrm/updateSerialUsage";
export const accessoryCampaignGetAll = baseUrl + "accessoryCampaign/getAll";
export const accessoryCampaignGetAllWithPassive = baseUrl + "accessoryCampaign/getAllWithPassive";
export const upsertAccessoryCampaign = baseUrl + "accessoryCampaign/upsert";
export const passiveAccessoryCampaign = baseUrl + "accessoryCampaign/passive";
export const activeAccessoryCampaign = baseUrl + "accessoryCampaign/active";
export const getSerialInfo = baseUrl + "accessoryCampaign/getSerialInfo";
export const getRemainingSerials = baseUrl + "accessoryCampaign/getRemainingSerials";
export const getRemainingPass = baseUrl + "accessoryCampaign/getRemainingPass";
export const getAllSerialTitles = baseUrl + "accessoryCampaign/getAllSerialTitles";
export const getAllSerial = baseUrl + "accessoryCampaign/getAllSerial";
export const getSerialTitleById = baseUrl + "accessoryCampaign/getSerialTitle";
export const createSerial = baseUrl + "accessoryCampaign/createSerial";
export const serialTitleActivate = baseUrl + "accessoryCampaign/serialTitleActivate";
export const serialTitleDelete = baseUrl + "accessoryCampaign/serialTitleDelete";
export const getPassInfo = baseUrl + "accessoryCampaign/getPassInfo";
export const getAllPassTitles = baseUrl + "accessoryCampaign/getAllPassTitles";
export const getPassTitleById = baseUrl + "accessoryCampaign/getPassTitle";
export const createPass = baseUrl + "accessoryCampaign/createPass";
export const passTitleActivate = baseUrl + "accessoryCampaign/passTitleActivate";
export const passTitleDelete = baseUrl + "accessoryCampaign/passTitleDelete";
export const searchSerial = baseUrl + "accessoryCampaign/searchSerial";
export const cancelSerials = baseUrl + "accessoryCampaign/cancelSerials";

//PRODUCTSALESMANAGEMENT
export const searchSaleInfo = baseUrl + "productSalesManagement/search";
export const searchSaleInfoEager = baseUrl + "productSalesManagement/searchEager";
export const searchStock = baseUrl + "productSalesManagement/searchStock";
export const searchStockEager = baseUrl + "productSalesManagement/searchStockEager";
export const searchProductSale = baseUrl + "productSalesManagement/searchProductSale";
export const searchProductSaleEager = baseUrl + "productSalesManagement/searchProductSaleEager";
export const cancelTheCompletedSale = baseUrl + "productSalesManagement/cancelTheCompletedSale";
export const cancelSaleApprove = baseUrl + "productSalesManagement/cancelSaleApprove";
export const multiCancelSaleApprove = baseUrl + "productSalesManagement/multiCancelSaleApprove";
export const cancelSaleReject = baseUrl + "productSalesManagement/cancelSaleReject";
export const resellerSerialTransfer = baseUrl + "productSalesManagement/resellerSerialTransfer";
export const mySerialTransfers = baseUrl + "productSalesManagement/mySerialTransfers";
export const mySerialTransfersEager = baseUrl + "productSalesManagement/mySerialTransfersEager";
export const resellerSerialTransferApprove = baseUrl + "productSalesManagement/resellerSerialTransferApprove";
export const resellerSerialTransferReject = baseUrl + "productSalesManagement/resellerSerialTransferReject";
export const searchSupplyInvoiceLazy = baseUrl + "supply/searchSupplyInvoiceLazy";
export const searchSupplyInvoiceEager = baseUrl + "supply/searchSupplyInvoiceEager";
export const findSupplyInvoiceDetailByInvoiceId = baseUrl + "supply/findSupplyInvoiceDetailByInvoiceId/";

//FAULTYPRODUCTMANAGEMENT
export const findBySerialNo = baseUrl + "faultyProductManagement/findBySerialNo";
export const findSupplyByBarcode = baseUrl + "faultyProductManagement/findSupplyByBarcode";
export const findModelList = baseUrl + "faultyProductManagement/findModelList";
export const createTicket = baseUrl + "faultyProductManagement/createTicket";
export const getWaitingTicketCount = baseUrl + "faultyProductManagement/getWaitingTicketCount";
export const searchTicket = baseUrl + "faultyProductManagement/searchTicket";
export const searchTicketEager = baseUrl + "faultyProductManagement/searchTicketEager";
export const approveTicket = baseUrl + "faultyProductManagement/approveTicket";
export const rejectTicket = baseUrl + "faultyProductManagement/rejectTicket";

//MODELSTOCKDISTY
export const searchModelStock = baseUrl + "modelStockDisty/searchLazy";
export const searchModelStockEager = baseUrl + "modelStockDisty/searchEager";
export const updateAllStatusMax = baseUrl + "modelStockDisty/updateAllStatusMax";
export const updateStatusMax = baseUrl + "modelStockDisty/updateStatusMax";

//PARAM
export const getAllParameters = baseUrl + "param/getAll";
export const updateParameter = baseUrl + "param/update";
export const getParameterByKey = baseUrl + "param/getByKey";

//RESELLER
export const resellerSearchLazy = baseUrl + "reseller/searchLazy";
export const resellerSearchEager = baseUrl + "reseller/searchEager";
export const resellerScore = baseUrl + "reseller/score";
export const resellerScoreWaitingTransactions = baseUrl + "reseller/scoreWaitingTransactions";
export const resellerScoreApproveWaitingTransactions = baseUrl + "reseller/scoreApproveWaitingTransactions";
export const resellerScoreCancelWaitingTransactions = baseUrl + "reseller/scoreCancelWaitingTransactions";
export const resellerScoreSearchTransactions = baseUrl + "reseller/scoreSearchTransactions";
export const resellerDistributionProducts = baseUrl + "reseller/distribution/products";
export const resellerDistributionProductsList = baseUrl + "reseller/distribution/products/list";
export const resellerLoginTicket = baseUrl + "login/reseller";

//SUPPLY
export const getAllSupply = baseUrl + "supply/getAll";
export const updateSupply = baseUrl + "supply/update";
export const getRealActiveSupply = baseUrl + "supply/real/active";


//CANSELL
export const canSellUpsert = baseUrl + "cansell/upsert";
export const canSellUpdate = baseUrl + "cansell/update";
export const getAllCanSell = baseUrl + "cansell/getAll";
export const deleteCanSell = baseUrl + "cansell/delete";
export const canSellByBrand = baseUrl + "cansell/getByBrand";


//BRAND
export const getAllBrand = baseUrl + "brand/getAllWithPassive";
export const createBrand = baseUrl + "brand/create";
export const updateBrand = baseUrl + "brand/update";
export const passiveBrand = baseUrl + "brand/passive";
export const activeBrand = baseUrl + "brand/active";


export const createSale = baseUrl + "productSalesManagement/createSale";
export const addProductToSale = baseUrl + "productSalesManagement/addProductToSale";
export const removeProductToSale = baseUrl + "productSalesManagement/removeProductToSale"
export const getSaleById = baseUrl + "productSalesManagement/getSaleById"
export const completeTheSale = baseUrl + "productSalesManagement/completeTheSale"
export const cancelTheSale = baseUrl + "productSalesManagement/cancelTheSale"


//RESELLERORDERMANAGEMENT
export const getHasProductsByReseller = baseUrl + "reseller/products"
export const getDistyProductDetailById = baseUrl + "reseller/product/"
export const getSimilarProducts = baseUrl + "reseller/similar/products/";

export const resellerCreateProductOrder = baseUrl + "reseller/productorder/create"
export const getActiveResellerOrderCart = baseUrl + "reseller/productorder/active/";
export const completedResellerOrderCart = baseUrl + "reseller/productorder/complete/";
export const deleteResellerOrderDetail = baseUrl + "reseller/productorderdetail/delete";
export const updateResellerOrderDetail = baseUrl + "reseller/productorderdetail/update";
export const cancelResellerProductOrder = baseUrl + "reseller/cancel/productorder"
export const getAllCompletedResellerProductOrder = baseUrl + "reseller/completed/productorder"
export const getAllCompletedResellerProductOrderEager = baseUrl + "reseller/completed/productorder/eager"
export const getAllCompletedResellerProductOrderDetailEager = baseUrl + "reseller/completed/productorder/detail/eager"

//REPORTING
export const getCalculationsMonthly = baseUrl + "reporting/getCalculationsMonthly/";
export const getCalculationsValues = baseUrl + "reporting/getCalculationsValues";
export const getSaleReportOptions = baseUrl + "reporting/getSaleReportOptions";
export const getSaleReport = baseUrl + "reporting/getSaleReport";

//Banner
export const findAllBanners = baseUrl + "findAll/banners"
export const currentBanners = baseUrl + "current/banners"
export const bannerGetById = baseUrl + "banner/"
export const upsertBanner = baseUrl + "upsert/banner"
export const updateBanner = baseUrl + "update/banner"
export const deleteBanner = baseUrl + "delete/banner"

//GİFT
export const findAllGifts = baseUrl + "findAll/gift"
export const upsertGiftImages = baseUrl + "upsert/gift/images"
export const upsertGift = baseUrl + "upsert/gift"
export const activeGift = baseUrl + "active/gift"
export const passiveGift = baseUrl + "passive/gift"
export const allData = baseUrl + "getAll/gift"
export const upsertGiftStocks = baseUrl + "upsert/gift/stocks"
export const findAllValidDates = baseUrl + "gift-valid-date"
export const upsertValidDate = baseUrl + "gift-valid-date"
export const deleteValidDate = baseUrl + "gift-valid-date"
export const checkExistValidDate = baseUrl + "gift-valid-date/active"
export const getAllApprovedSerials = baseUrl + "accessoryCampaign/getAllApprovedSerials"
export const getAllApprovedSerialsEager = baseUrl + "accessoryCampaign/getAllApprovedSerialsEager"
export const approveSerials = baseUrl + "accessoryCampaign/approveSerials"
export const giftScore = baseUrl + "gift/score"

//GİFT TRANSACTION
export const findAllActiveGifts = baseUrl + "findAll/active/gift"
export const findAllActiveExistGifts = baseUrl + "findAll/active/gift/exist"
export const findAllTransactionGifts = baseUrl + "findAll/gift/transaction"
export const findAllTransactionGiftsEager = baseUrl + "findAll/gift/transactionEager"
export const buyToGift = baseUrl + "upsert/gift/transaction"
export const approveGift = baseUrl + "approve/gift/transaction"
export const getGiftTransactionType = baseUrl + "enum/giftTransactionType"
export const getMyGift = baseUrl + "findAll/gift/transaction/reseller"
export const getMyGiftEager = baseUrl + "findAll/gift/transaction/resellerEager"
export const approveResellerScore = baseUrl + "reseller/approveScore"


//
export const findAllApproveWaitingModelPriceSupply = baseUrl + "modelpricesupply/approve/waiting/findAll";
export const modelPriceSupplyApproveRequest = baseUrl + "modelpricesupply/approve";
export const modelPriceSupplyApproveRequestList = baseUrl + "modelpricesupply/batch/approve";