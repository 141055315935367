import * as actionTypes from './modelPrice.type';
import {SAVE_MODEL_PRICE_ADMIN} from "./modelPrice.type";

const initialState = {
  modelPriceAdmin: {},
};

const reducer = (
  state = initialState,
  {
    type,
    payload,
    modelPriceAdmin,
    ...params
  }
) => {
  switch (type) {
    case actionTypes.SAVE_MODEL_PRICE_ADMIN:
      return { ...state, modelPriceAdmin: payload }
    default:
      return state;
  }
}

export default reducer;
