import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import {handleError} from "../../service/HandleErrorService";
import VoucherService from "./httpService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Checkbox} from "primereact/checkbox";
import {useHistory} from "react-router";
import {InputText} from "primereact/inputtext";

const AccessoryCampaignSerialTitleList = () => {

  const history = useHistory();
  const toast = useRef(null);
  const voucherService = new VoucherService();

  const [loading, setLoading] = useState(false);
  const [serialInfo, setSerialInfo] = useState([]);
  const [accessoryCampaignSerialTitleList, setAccessoryCampaignSerialTitleList] = useState([]);

  const [searchCriteriaLazyLoading, setSearchCriteriaLazyLoading] = useState(false);
  const [searchCriteriaProgressing, setSearchCriteriaProgressing] = useState(false);
  const [serialValue, setSerialValue] = useState(null);
  const [resellerCode, setResellerCode] = useState(null);
  const [excelButtonDisabled, setExcelButtonDisabled] = useState(false);

  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  useEffect(() => {
    getSerialInfo();
    getAll({...paginationConfig, activePage: 0});
  }, []);

  useEffect(() => {
    setSearchCriteriaLazyLoading(true);
  }, [serialValue, resellerCode]);

  useEffect(() => {
    if (searchCriteriaLazyLoading) {
      setTimeout(() => {
        setSearchCriteriaProgressing(true);
        setSearchCriteriaLazyLoading(false);
      }, 2000);
    }
  }, [searchCriteriaLazyLoading]);

  useEffect(() => {
    if (searchCriteriaProgressing) {
      getAll({...paginationConfig, activePage: 0});
      setSearchCriteriaProgressing(false);
    }
  }, [searchCriteriaProgressing]);

  const getSerialInfo = () => {
    voucherService.getSerialInfo().then(res => {
      console.log("getSerialInfo", res);
      setSerialInfo(res);
    }).catch(err => {
      console.log(err);
    })
  };

  const getAll = (pageState) => {
    setLoading(true);
    if (serialValue != null && serialValue != "") {
      voucherService.getAllSerialTitlesBySerialValue(serialValue, pageState.itemsPerPage, pageState.activePage).then(res => {
        console.log("getAllSerialTitlesBySerialValue", res.content);
        setAccessoryCampaignSerialTitleList(res.content);

        pageState.totalRecords = res.totalElements;
        pageState.first = pageState.itemsPerPage * pageState.activePage;
        setPaginationConfig({
          ...pageState,
        });
      }).catch(err => handleError(err, toast)).finally(() => {
        setLoading(false);
      });
    } else if (resellerCode != null && resellerCode != "") {
      voucherService.getAllSerialTitlesByResellerCode(resellerCode, pageState.itemsPerPage, pageState.activePage).then(res => {
        console.log("getAllSerialTitlesByResellerCode", res.content);
        setAccessoryCampaignSerialTitleList(res.content);

        pageState.totalRecords = res.totalElements;
        pageState.first = pageState.itemsPerPage * pageState.activePage;
        setPaginationConfig({
          ...pageState,
        });
      }).catch(err => handleError(err, toast)).finally(() => {
        setLoading(false);
      })
    } else {
      voucherService.getAllSerialTitles(pageState.itemsPerPage, pageState.activePage).then(res => {
        console.log("getAllSerialTitles", res.content);
        setAccessoryCampaignSerialTitleList(res.content);

        pageState.totalRecords = res.totalElements;
        pageState.first = pageState.itemsPerPage * pageState.activePage;
        setPaginationConfig({
          ...pageState,
        });
      }).catch(err => handleError(err, toast)).finally(() => {
        setLoading(false);
      })
    }
  };

  const openAccessoryCampaignSerialTitle = () => {
    localStorage.setItem("accessoryCampaignSerialTitleId", window.btoa(null));

    history.push("/accessoryCampaignSerialTitle");
  };

  const openAccessoryCampaignSerialTitleWithId = (e) => {
    localStorage.setItem("accessoryCampaignSerialTitleId", window.btoa(e.currentTarget.id));
    history.push("/accessoryCampaignSerialTitle");
  };

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    getAll(pageState);
  };

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const responsiveColumnRight = (title, content) => {
    return (
      <div>
        <span className="p-column-title">{title}</span>
        <span style={{float: 'right'}}>{content}</span>
      </div>
    );
  };

  const createDateTimeTemplate = (data) => {
    return responsiveColumn("Oluşturulma Zamanı", data?.createDateTimeString)
  };

  const nameTemplate = (data) => {
    return responsiveColumn("Oluşturan Kişi Adı", data?.userDto.name)
  };

  const surnameTemplate = (data) => {
    return responsiveColumn("Oluşturan Kişi Soyadı", data?.userDto.surname)
  };

  const totalUsedSerialNumberCountTemplate = (data) => {
    return responsiveColumnRight("Kullanılan Seri Numarası Adedi", addCommas(data?.totalUsedSerialNumber))
  };

  const totalSerialNumberCountTemplate = (data) => {
    return responsiveColumnRight("Oluşturulan Seri Numarası Adedi", addCommas(data?.totalSerialNumberCount))
  };

  const titleStatusDisplayTemplate = (data) => {
    return responsiveColumn("İşlem Durumu", data?.titleStatus == "COMPLETED" ? data?.titleStatusDisplay : data?.titleStatusDisplay + "(" + addCommasRatio(data?.totalCreatedSerialNumber / data?.totalSerialNumberCount * 100) + "%)")
  };

  const activeTemplate = (rowData) => {
    return responsiveColumn("Aktiflik Durumu", <Checkbox style={{cursor: 'context-menu'}} disabled={true}
                                                         checked={rowData.isActivated}/>)
  };

  const accessoryCampaignSerialTitleButtonTemplate = (data) => {
    return (
      <div className="p-d-flex p-justify-even">
        <Button id={data.id} type="button" className="p-button-rounded p-button-warning" icon="pi pi-external-link"
                tooltip="İşlemler"
                tooltipOptions={{position: 'left', mouseTrack: true, mouseTrackTop: 15}}
                onClick={openAccessoryCampaignSerialTitleWithId}/>
      </div>
    );
  };

  const addCommasRatio = num => num?.toFixed(0).toString();

  const addCommas = num => num != null ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;

  const numFix = num => num != null ? num.toFixed(4).toString() : null;

  const exportXLSX = () => {
    setExcelButtonDisabled(true);
    if ((serialValue != null && serialValue != "") || (resellerCode != null && resellerCode != "")) {
      import('xlsx').then(xlsx => {
        var excelList = [];
        if (serialValue != null && serialValue != "") {
          voucherService.getAllSerialBySerialValue(serialValue).then(res => {
            res.forEach(a => {
              excelList.push({
                "Oluşturulma Zamanı": a.createDateTimeString,
                "Bayi Kodu": a.reseller.code,
                "Oluşturan Kişi Adı": a.userDto.name,
                "Oluşturan Kişi Soyadı" : a.userDto.surname,
                "Kampanya Tutarı": a.accessoryCampaign.amount,
                "Taksit Sayısı": a.accessoryCampaign.termNumber,
                "Seri Numara Durumu": a.usageStatusDisplay,
                "Seri Numara": a.serialValue,
                "Aktiflik Durumu": a.isActivated ? "Aktif" : "Pasif"
              })
            });
            const worksheet = xlsx.utils.json_to_sheet(excelList);
            var wscols = [
              {width: 17},
              {width: 15},
              {width: 17},
              {width: 17},
              {width: 15},
              {width: 15},
              {width: 17},
              {width: 17},
              {width: 15}
            ];
            worksheet["!cols"] = wscols;
            const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
            const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
            saveAsExcelFile(excelBuffer, 'Seri_Numaralar');
          }).catch(err => handleError(err, toast)).finally(() => {
            setExcelButtonDisabled(false);
          })
        } else {
          voucherService.getAllSerialByResellerCode(resellerCode).then(res => {
            res.forEach(a => {
              excelList.push({
                "Oluşturulma Zamanı": a.createDateTimeString,
                "Bayi Kodu": a.reseller.code,
                "Oluşturan Kişi Adı": a.userDto.name,
                "Oluşturan Kişi Soyadı" : a.userDto.surname,
                "Kampanya Tutarı": a.accessoryCampaign.amount,
                "Taksit Sayısı": a.accessoryCampaign.termNumber,
                "Seri Numara Durumu": a.usageStatusDisplay,
                "Seri Numara": a.serialValue,
                "Aktiflik Durumu": a.isActivated ? "Aktif" : "Pasif"
              })
            });
            const worksheet = xlsx.utils.json_to_sheet(excelList);
            var wscols = [
              {width: 17},
              {width: 15},
              {width: 17},
              {width: 17},
              {width: 15},
              {width: 15},
              {width: 17},
              {width: 17},
              {width: 15}
            ];
            worksheet["!cols"] = wscols;
            const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
            const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
            saveAsExcelFile(excelBuffer, 'Seri_Numaralar');
          }).catch(err => handleError(err, toast)).finally(() => {
            setExcelButtonDisabled(false);
          })
        }
      });
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Seri numara veya bayi kodu filtresi dolu olmalıdır.'
      });
      setExcelButtonDisabled(false);
    }
  };

  const getRemainingSerials = () => {
    import('xlsx').then(xlsx => {
      setExcelButtonDisabled(true);
      voucherService.getRemainingSerials().then(res => {
        console.log("getRemainingSerials", res);
        var excelList = [];
        res.forEach(a => {
          excelList.push({
            "Bayi Kodu": a.resellerCode,
            "Distribütör Kodu": a.distyCode,
            "Kampanya": "Paket" + a.amount.toFixed(0),
            "Kalan Seri" : a.campaignRemaining,
            "Toplam Seri": a.campaignTotal
          })
        });
        const worksheet = xlsx.utils.json_to_sheet(excelList);
        var wscols = [
          {width: 15},
          {width: 15},
          {width: 15},
          {width: 15},
          {width: 15}
        ];
        worksheet["!cols"] = wscols;
        const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
        saveAsExcelFile(excelBuffer, 'Seri_Stoğu_Azalanlar');
        setExcelButtonDisabled(false);
      }).catch(err => {
        console.log(err);
        setExcelButtonDisabled(false);
      });
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const header =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>Oluşturulan Seri Numaraları</div>
        <div>
          <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button"
                  disabled={excelButtonDisabled}
                  onClick={exportXLSX}/>
        </div>
      </div>
    </div>;

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card">
          <p><b>Oluşturucalabilcek Seri Numara Sayısı:</b> {addCommas(serialInfo?.canBeCreatedUnit)}</p>
          <p><b>Oluşturulan Seri Numara
            Sayısı:</b> {addCommas(serialInfo?.createdUnit)} - {numFix(serialInfo?.createdUnit / serialInfo?.canBeCreatedUnit * 100)}%
          </p>
          <p><b>Kullanılan Seri Numara
            Sayısı:</b> {addCommas(serialInfo?.usedUnit)} - {serialInfo?.usedUnit != 0 ? numFix(serialInfo?.usedUnit / serialInfo?.createdUnit * 100) : numFix(0)}%
          </p>
          <Button onClick={openAccessoryCampaignSerialTitle} icon="pi pi-plus-circle" label="Seri Numara Oluştur"
                  className="p-button-success p-mr-2 p-mb-2"/>
          <Button type="button" icon="pi pi-external-link" label="Seri Stoğu Azalanları Excel`e Aktar" className="p-button p-mr-2 p-mb-2"
                  disabled={excelButtonDisabled}
                  onClick={getRemainingSerials}/>
          <hr/>
          <InputText style={{marginBottom: 10, marginRight: 10, width: 320}} value={serialValue}
                     placeholder="Aramak İstediğiniz Seri Numarayı Giriniz"
                     onChange={(e) => setSerialValue(e.target.value)}/>
          <InputText style={{marginBottom: 10, width: 320}} value={resellerCode}
                     placeholder="Aramak İstediğiniz Bayi Kodunu Giriniz"
                     onChange={(e) => setResellerCode(e.target.value)}/>
          <ResponsiveDataTable
            header={header}
            className="p-datatable-responsive-demo"
            loading={loading}
            value={accessoryCampaignSerialTitleList}
            paginationConfig={paginationConfig}
            onActivePageChange={onActivePageChange}
            emptyMessage="Seri numara bulunamadı"
          >
            <Column body={createDateTimeTemplate} header="Oluşturulma Zamanı"/>
            <Column body={nameTemplate} header="Oluşturan Kişi Adı"/>
            <Column body={surnameTemplate} header="Oluşturan Kişi Soyadı"/>
            <Column body={totalUsedSerialNumberCountTemplate} header="Kullanılan Seri Numara Adedi"
                    headerStyle={{textAlign: 'right'}}/>
            <Column body={totalSerialNumberCountTemplate} header="Oluşturulan Seri Numara Adedi"
                    headerStyle={{textAlign: 'right'}}/>
            <Column body={titleStatusDisplayTemplate} header="İşlem Durumu"/>
            <Column body={activeTemplate} header="Aktiflik Durumu"/>
            <Column body={accessoryCampaignSerialTitleButtonTemplate}
                    headerStyle={{width: "7rem", textAlign: "center"}}
                    bodyStyle={{textAlign: 'center', overflow: 'visible', justifyContent: 'center'}}/>
          </ResponsiveDataTable>
        </div>
      </div>
    </>
  )

};

export default AccessoryCampaignSerialTitleList;
