import React from "react";

const AppFooter = () => {
  return (
    <div className="layout-footer">
      <div className="footer-logo-container">
        {/*<img id="footer-logo" src="assets/layout/images/qr-code.svg" alt="diamond-layout" />*/}
        <span className="app-name">TT-MAP</span>
      </div>
      <span className="copyright">&#169; TT-MAP - 2021</span>
    </div>
  );
};

export default AppFooter;
