import React from "react";
import { useLocation, withRouter } from "react-router-dom";

const AppBreadcrumb = (props) => {
  const location = useLocation();
  let pathname = location.pathname;
  if (props.routers) {
    let name = pathname.replace("/", "");
    let currentRouter = props.routers.find(
      (router) => router.path === pathname
    );
    name = currentRouter ? currentRouter["meta"].breadcrumb[0].label : name;
    let title = name.split("/");
    return <span>{title[0]}</span>;
  }
};

export default withRouter(AppBreadcrumb);
