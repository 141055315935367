import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { data } from "./data";
import { OrderList } from "primereact/orderlist";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./OrderListDemo.css";
import { TabView, TabPanel } from "primereact/tabview";
import { VirtualScroller } from "primereact/virtualscroller";
import "./TabViewDemo.css";
import Sales from "./Sales";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import CustomerInfoDropdown from "../../components/CustomerInfoDropdown";
import { Fieldset } from "primereact/fieldset";

import BarcodeScanner from "./BarcodeScanner";

import * as services from "./httpService";
import { handleError } from "../../service/HandleErrorService";

const SalesScreen = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 991);
  }, [window.innerWidth]);

  useEffect(() => {
    setLoading(true);
    fetchGetAllBrands({ ...paginationConfig, activePage: 0 });
  }, [searchCriteria]);

  

  let emptyCustomerInfo = {
    id: "",
    customer: {
      customerName: "",
      customerSurname: "",
      customerType: "",
      customerMobileNo: "",
      customerFixedLine: "",
      customerBroadBand: "",
    },
  };


  const [searchCriteria, setSearchCriteria] = useState({
    name: "",
    description: "",
  });
  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  const fetchGetAllBrands = () => {
    services
      .getAllBrands()
      .then((res) => {
        setSearchResults(res);
        console.log(res);
        setLoading(false);
      })
      .catch((err) => {
        handleError(err, toast);
        setLoading(false);
      });
  };
  
  const [customerInfo, setCustomerInfo] = useState(emptyCustomerInfo);
  const [isMobile, setIsMobile] = useState(null);
  const [barcodeScannerVisible, setBarcodeScannerVisible] = useState(false);
  const [results, setResults] = React.useState([]);
  const [selectCustomerInfo, setSelectCustomerInfo] = React.useState("");
  const [price, setPrice] = React.useState(0);

  const toast = useRef();
  const [showInfoMessage, setShowInfoMessage] = useState(true);
  //--------------------------//
  let emptyCustomerName = "";
  const [customerName, setCustomerName] = useState(emptyCustomerName);
  let emptyCustomerSurname = "";
  const [customerSurname, setCustomerSurname] = useState(emptyCustomerSurname);
  let emptyCustomerType = null;
  const [customerType, setCustomerType] = useState(emptyCustomerType);
  let emptyCustomerMobileNo = null;
  const [customerMobileNo, setCustomerMobileNo] = useState(
    emptyCustomerMobileNo
  );
  let emptyCustomerFixedLine = null;
  const [customerFixedLine, setCustomerFixedLine] = useState(
    emptyCustomerFixedLine
  );
  let emptyCustomerBroadBand = null;
  const [customerBroadBand, setCustomerBroadBand] = useState(
    emptyCustomerBroadBand
  );

  const onChangeName = (event) => {
    setCustomerName(event.target.value);
    console.log(event.target.value);
  };
  const onChangeSurname = (event) => {
    setCustomerSurname(event.target.value);
    console.log(event.target.value);
  };
  const onChangeCustomerMobileNo = (event) => {
    setCustomerMobileNo(event.target.value);
    console.log(event.target.value);
  };
  const onChangeCustomerFixedLine = (event) => {
    setCustomerFixedLine(event.target.value);
    console.log(event.target.value);
  };
  const onChangeCustomerBroadBand = (event) => {
    setCustomerBroadBand(event.target.value);
    console.log(event.target.value);
  };

  const customerInfoNextButton = () => {
    setActiveIndex(1);
  };
  const customerInfoCleanButton = () => {
    setCustomerName(emptyCustomerName);
    setCustomerSurname(emptyCustomerSurname);
    setCustomerMobileNo(emptyCustomerMobileNo);
    setCustomerFixedLine(emptyCustomerFixedLine);
    setCustomerBroadBand(emptyCustomerBroadBand);
    setSelectCustomerInfo("");
  };

  const scan = (err, result) => {
    if (result) {
      setResults([...results, result.text]);
      setBarcodeScannerVisible(false);
      console.log(result);
      setPrice(price + 50);
      toast.current?.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Ürün Sepete Eklendi",
        life: 3000,
      });
    }
  };

  const infoDialogFooter = () => {
    let acceptClassname =
      window.innerWidth <= 991
        ? "p-button-outlined p-button-success p-m-2 "
        : "p-button-outlined p-button-success";
    let cancelClassname =
      window.innerWidth <= 991
        ? "p-button-outlined p-button-danger p-m-2 "
        : "p-button-outlined p-button-danger";
    return (
      <div>
        <Button
          label="Okudum, Onaylıyorum"
          icon="pi pi-check"
          className={acceptClassname}
          onClick={() => setShowInfoMessage(false)}
          autoFocus
        />
        <Button
          label="Vazgeç"
          icon="pi pi-times"
          onClick={() => setShowInfoMessage(false)}
          className={cancelClassname}
        />
      </div>
    );
  };
  const infoDialogHeader = () => {
    return (
      <div className="p-d-flex p-ai-center">
        <i
          style={{ fontSize: "2em", color: "orange" }}
          className="pi pi-info-circle p-mr-3"
        ></i>
        <div style={{ fontSize: "1.2em", color: "orange" }}>UYARI</div>
      </div>
    );
  };

  const product = (item) => {
    return (
      <div className="product-item p-p-0 ">
        <div className="p-d-flex p-flex-md-row p-mt-1">
          <img
            src={item.image}
            style={{ width: 30, height: 30 }}
            onError={(e) =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            alt={item.name}
          />
          <div className="product-list-detail p-ml-4">
            <h5 style={{ fontSize: 13 }} className="p-mb-2">
              {item}
            </h5>
            <i
              style={{ fontSize: 13 }}
              className="pi pi-tag product-category-icon"
            ></i>
            {/* <span style={{ fontSize: 13 }} className="product-category">
              {item.category}
            </span> */}
          </div>
        </div>
      </div>
    );
  };

  // const basicItemTemplate = (item, options) => {
  //   return (
  //     <div>
  //       <img
  //         src={item.image}
  //         style={{ width: 100, height: 100 }}
  //         onError={(e) =>
  //           (e.target.src =
  //             "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
  //         }
  //         alt={item.name}
  //       />
  //     </div>
  //   );
  // };

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        header={infoDialogHeader}
        visible={showInfoMessage}
        onHide={() => setShowInfoMessage(false)}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "50vw" }}
        footer={infoDialogFooter}
      >
        <div style={{ fontSize: 17, textAlign: "center", marginTop: 10 }}>
          Satışa başlamadan önce abonenin Türk Telekom sistemlerinde ilgili
          kampanyaya uygun olduğundan emin olunuz.
        </div>
      </Dialog>
      <div className="card">
        <div>
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel header="Müşteri Bilgileri">
              <CustomerInfoDropdown
                value={selectCustomerInfo}
                onChange={(e) => setSelectCustomerInfo(e?.target?.value)}
                customerInfo={customerInfo}
                onChangeName={onChangeName}
                nameValue={customerName}
                onChangeSurname={onChangeSurname}
                surnameValue={customerSurname}
                onChangeMobileNo={onChangeCustomerMobileNo}
                mobileNoValue={customerMobileNo}
                onChangeBroadBand={onChangeCustomerBroadBand}
                broadBandValue={customerBroadBand}
                onChangeFixedLine={onChangeCustomerFixedLine}
                fixedLineValue={customerFixedLine}
                selectCustomerInfo={selectCustomerInfo}
                placeholder="Müşteri Tipi Seçiniz.."
              />
              <div>
                <div>
                  <Button
                    icon="pi pi-trash"
                    iconPos="right"
                    label="Temizle"
                    className="p-button p-mt-4 orderlist-demo p-col-4"
                    onClick={customerInfoCleanButton}
                  />
                </div>
                <div>
                  <Button
                    icon="pi pi-chevron-right"
                    iconPos="right"
                    label="İlerle"
                    className="p-button p-mt-4 orderlist-demo p-col-4"
                    onClick={customerInfoNextButton}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Markalar">
              <div>
                <h6
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    display: "grid",
                  }}
                >
                  Lütfen kampanyaya dahil aşağıdaki markalara ait seri
                  numaralarını okutun
                </h6>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {searchResults?.map((result) => {
                    return (
                      <Card
                        style={{
                          width: "12em",
                          margin: 8,
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        <div style={{ textAlign: "center", fontSize: 18 }}>
                          <div style={{ fontWeight: "bold", marginBottom: 15 }}>
                            {result.name}
                          </div>
                          <div>
                            <img
                              style={{
                                width: 105,
                                height: 85,
                                alignSelf: "center",
                              }}
                              alt="Card"
                              src={result.image.publicAddress}
                              onError={(e) =>
                                (e.target.src =
                                  "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                              }
                            />
                          </div>
                        </div>
                      </Card>
                    );
                  })}
                </div>
              </div>

              <Button
                icon="pi pi-chevron-right"
                iconPos="right"
                label="İlerle"
                className="p-button p-mt-4 orderlist-demo p-col-12"
                onClick={() => setActiveIndex(2)}
              />
            </TabPanel>
            <TabPanel header="Sepetim">
              <div className="p-jc-center p-d-flex ">
                <div className="virtualscroller-demo p-mt-0 orderlist-demo p-col-12">
                  <Button
                    icon="pi pi-plus"
                    iconPos="right"
                    label="SEPETE ÜRÜN EKLE"
                    className="p-button-success p-mb-4 p-mt-3 orderlist-demo p-col-12"
                    onClick={() => setBarcodeScannerVisible(true)}
                  />
                  <div className="carousel-demo p-mt-0">
                    <div className="card">
                      <BarcodeScanner
                        scan={scan}
                        visible={barcodeScannerVisible}
                        onHide={() => setBarcodeScannerVisible(false)}
                      />
                      <h5 style={{ color: "orange", margin: 0 }}>
                        <i
                          className="pi pi-shopping-cart"
                          style={{
                            fontSize: "1.2em",
                            marginRight: 10,
                            color: "orange",
                          }}
                        ></i>
                        SEPETİM
                      </h5>
                      {results.length > 0 ? (
                        <OrderList
                          value={results}
                          className="p-mt-3"
                          listStyle={{ height: "auto" }}
                          dataKey="id"
                          itemTemplate={product}
                        ></OrderList>
                      ) : (
                        <div
                          className="p-text-bold p-text-center p-mt-4"
                          style={{ color: "gray" }}
                        >
                          Sepetinizde ürün bulunmamaktadır
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {isMobile && results.length > 0 && (
                <div
                  style={{ position: "fixed", left: 0, right: 0, bottom: 0 }}
                >
                  <div
                    className="card p-shadow-24 p-d-flex"
                    style={{
                      position: "relative",
                      padding: "0.2rem",
                      paddingLeft: 20,
                    }}
                  >
                    <div className=" p-ai-center p-jc-center p-mt-4">
                      <h5 className="p-mr-2" style={{ fontSize: 15 }}>
                        ÖDENECEK TUTAR:{" "}
                      </h5>
                      <p className="p-mr-2">
                        <h4
                          style={{
                            color: "green",
                            fontSize: 27,
                            fontWeight: "bold",
                          }}
                        >
                          {price} TL
                        </h4>
                      </p>
                    </div>
                    <Button
                      label="SATIŞI TAMAMLA"
                      onClick={() => setActiveIndex(3)}
                      className="p-button"
                      style={{
                        height: 48,
                        minWidth: 155,
                        alignSelf: "center",
                        position: "absolute",
                        right: 20,
                        borderRadius: 8,
                      }}
                    />
                  </div>
                </div>
              )}
            </TabPanel>
            <TabPanel header="Satışı Tamamla">
              <Sales onClick={() => setActiveIndex(2)} />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  );
};

export default SalesScreen;
