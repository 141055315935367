export const handleError = (error, toast) => {
  if (error.response) {
    switch (error.response.status) {
      case 400:
      case 422:
        if (error?.response?.data?.code===10000) {
          toast.current?.show({
            severity: "error",
            summary: "Hata",
            detail: error.response.data?.fields[0],
            life: 4000,
          });
          return ;
        }
        let errorFields = {};
        if (error.response.data.message) {
          toast.current?.show({
            severity: "error",
            summary: "Hata",
            detail: error.response.data.description,
            life: 4000,
          });
          if (error.response.data.fields) {
            error.response.data.fields.map((_field, _index) => {
              errorFields[`${_field}`] = true;
            });
            return errorFields;
          }
        } else {
          let uniqueErrorMessages = [
            ...new Set(
              error.response.data.errors.map((_data, _index) => {
                if (_data.fields) {
                  _data.fields.forEach((field) => {
                    errorFields[`${field}`] = true;
                  });
                }
                return _data.message;
              })
            ),
          ];

          uniqueErrorMessages.forEach((errorMessages) => {
            toast.current?.show({
              severity: "error",
              summary: "Hata",
              detail: errorMessages,
              life: 3000,
            });
          });
          return errorFields;
        }
        break;
      case 500:
        toast.current?.show({
          severity: "error",
          summary: "Hata",
          detail: "Beklenmedik bir hata oluştu",
          life: 3000,
        });
        break;
      default:
        toast.current?.show({
          severity: "error",
          summary: "Hata",
          detail: `Beklenmedik bir hata oluştu. Hata kodu: HTTP-${error.response.status}`,
          life: 3000,
        });
        break;
    }
  }
  return Promise.reject(error);
};
