import * as Paths from "../../service/config";
import AspAxios from "../../service/AspAxios";

function clean(obj) {
    let _obj = {};
    for (const propName in obj) {
      if (obj[propName] !== "") {
        _obj[propName] = obj[propName];
      }
    }
    return _obj;
  }

export function ttCrmGetAll(request) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.ttCrmGetAll, clean(request))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }