import { ResponsiveDataTable } from "../../components/ResponsiveDataTable";
import React, { useEffect, useRef, useState } from "react";
import { GiftService } from "./GiftService";
import { handleError } from "../../service/HandleErrorService";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { ProgressBar } from "primereact/progressbar";
import { Tag } from "primereact/tag";
import { DataView } from "primereact/dataview";
import { Paginator } from "primereact/paginator";
import "./giftView.css";
import { InputNumber } from "primereact/inputnumber";
import { Galleria } from "primereact/galleria";
import { allData } from "../../service/config";

const GiftView = () => {
  const isMounted = useRef(false);
  const [layout, setLayout] = useState("grid");
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(12);
  const [basicPage, setBasicPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(12);

  const [sortValue, setSortValue] = useState("null");
  const [dropValue, setDropValue] = useState("");
  const [dropValueActive, setDropValueActive] = useState("");
  const [sortValueActive, setSortValueActive] = useState("null");
  let _options = ["Büyükten Küçüğe Sırala", "Küçükten Büyüğe Sırala"];
  let _activeoptions = ["Aktif", "Pasif"];
  const fileUploadRef = useRef(null);
  const [totalSize, setTotalSize] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [allGifts, setAllGifts] = useState([]);
  const [searchRequest, setSearchRequest] = useState({});
  const [upsertGift, setUpsertGift] = useState({});
  const [loading, setLoading] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [editGift, setEditGift] = useState({});
  const [stockExcelDialog, setStockExcelDialog] = useState(false);
  const [editImages, setEditImages] = useState([]);
  const [validExcelRows, setValidExcelRows] = useState([]);
  const [invalidExcelRows, setInvalidExcelRows] = useState([]);
  const [viewImageDialog, setViewImageDialog] = useState(false);
  const [deleteImages, setDeleteImages] = useState([]);
  const [createDialog, setCreateDialog] = useState(false);
  const [createGift, setCreateGift] = useState({});
  const [newFiles, setNewFiles] = useState([]);
  const [exportExcelDisabled, setExportExcelDisabled] = useState(null);
  const [checkClear, setCheckClear] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 12,
    first: 0,
    totalRecords: 36,
    activePage: 0,
  });
  const giftService = new GiftService();
  const toast = useRef(null);


  useEffect(() => {
    setBasicPage(0);
    getAll({ activePage: 0, itemsPerPage: basicRows });
  }, [searchRequest, sortValue, sortValueActive]);

  const getAll = (pageState) => {
    let _sort = "";
    if (sortValueActive === "asc") {
      _sort = "&sort=status,asc";
    } else if (sortValueActive === "desc") {
      _sort = "&sort=status,desc";
    }

    if (sortValue === "asc") {
      _sort += "&sort=giftScore,asc";
    } else if (sortValue === "desc") {
      _sort += "&sort=giftScore,desc";
    }

    if (_sort === "") {
      _sort = "&sort=createDateTime,asc";
    }

    let _searchRequest = { ...searchRequest };
    setLoading(true);
    giftService
      .findAllGiftPage(
        _searchRequest,
        pageState.itemsPerPage,
        pageState.activePage,
        _sort
      )
      .then((res) => {
        setBasicFirst(pageState.activePage * pageState.itemsPerPage);
        setTotalRecords(res.totalElements);
        setBasicRows(pageState.itemsPerPage);
        setBasicPage(pageState.activePage);

        setAllGifts(res.content);
      })
      .catch((err) => handleError(err, toast))
      .finally(() => setLoading(false));
  };

  const nameFilterRender = () => {
    return (
      <InputText
        className="p-column-filter"
        value={searchRequest?.name}
        onChange={(e) => {
          let _request = { ...searchRequest };
          _request.name = e.target.value;
          setSearchRequest(_request);
        }}
      />
    );
  };

  const onTemplateSelect = (e) => {
    let _selectedFiles = [...selectedFiles];
    let _totalSize = totalSize;
    Array.from(e?.files)?.forEach((file) => {
      _totalSize += file.size;

      _selectedFiles.push(file);
    });

    setSelectedFiles(_selectedFiles);
    setTotalSize(_totalSize);
  };

  const onTemplateClear = () => {
    setTotalSize(0);
    setSelectedFiles([]);
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, cancelButton } = options;
    const value = totalSize / 10000;
    const formatedValue =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : "0 B";

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}

        {cancelButton}
        <ProgressBar
          value={value}
          displayValueTemplate={() => `${formatedValue} / 1 MB`}
          style={{
            width: "300px",
            height: "20px",
            marginLeft: "auto",
          }}
        />
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="p-d-flex p-ai-center p-flex-wrap">
        <div className="p-d-flex p-ai-center" style={{ width: "40%" }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
          <span className="p-d-flex p-dir-col p-text-left p-ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="p-px-3 p-py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };

  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };

  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  const emptyTemplate = () => {
    return (
      <div className="p-d-flex p-ai-center p-dir-col">
        <i
          className="pi pi-image p-mt-3 p-p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        />
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="p-my-5"
        >
          Resimi Sürükleyiniz
        </span>
      </div>
    );
  };

  const upsertNewGift = () => {
    let _selectedFiles = [...selectedFiles];
    let _createGift = { ...createGift };
    setLoading(true);
    giftService
      .upserGift(_createGift)
      .then((res) => {
        let formData = new FormData();
        _selectedFiles.forEach((value) => {
          formData.append("file", value);
        });
        giftService
          .uploadGiftImages(formData, res.id)
          .then((res) => {
            toast.current.show({
              severity: "success",
              summary: "Başarılı İşlem",
              detail: "Puan Ürünü Kaydedildi.",
              life: 3000,
            });
            getAll({ activePage: basicPage, itemsPerPage: basicRows });
            setCreateGift({});
            setSelectedFiles([]);
            setCreateDialog(false);
            setLoading(false);
          })
          .catch((err) => {
            handleError(err, toast);
            setLoading(false);
          });
      })
      .catch((err) => {
        setLoading(false);
        handleError(err, toast);
      });
  };

  const updateGift = () => {
    let _selectedFiles = [...selectedFiles];
    let _editGift = { ...editGift };
    let _deleteImages = [...deleteImages];
    _editGift.willRemoveImageList = _deleteImages;
    if(_editGift.stock === undefined || _editGift.stock === null || _editGift.stock === "") {
      _editGift.stock = 0;
    }
    setLoading(true);
    giftService
      .upserGift(_editGift)
      .then((res) => {
        let formData = new FormData();
        _selectedFiles.forEach((value) => {
          formData.append("file", value);
        });
        giftService
          .uploadGiftImages(formData, res.id)
          .then((res2) => {
            toast.current.show({
              severity: "success",
              summary: "Başarılı İşlem",
              detail: "Puan Ürünü Kaydedildi.",
              life: 3000,
            });

            getAll({ activePage: basicPage, itemsPerPage: basicRows });
            setEditImages([]);
            setSelectedFiles([]);
            setEditGift({});
            setEditDialog(false);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            handleError(err, toast);
          });
      })
      .catch((err) => {
        setLoading(false);
        handleError(err, toast);
      });
  };

  const descriptionFilterRender = () => {
    return (
      <InputText
        value={searchRequest?.description}
        onChange={(e) => {
          let _request = { ...searchRequest };
          _request.description = e.target.value;
          setSearchRequest(_request);
        }}
        className="p-column-filter"
      />
    );
  };

  const descriptionFilter = descriptionFilterRender();
  const nameFilter = nameFilterRender();

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {rowData.status === 1 ? (
          <div>
            <Button
              icon="pi pi-pencil"
              className="p-button-rounded p-button-warning p-mr-2"
              label="Düzenle"
              onClick={() => editGifts(rowData)}
            />
            <Button
              icon="pi pi-eye-slash"
              className="p-button-rounded p-button-danger"
              label="Sil"
              onClick={() =>
                giftService.passiveGift(rowData).then((res) => {
                  getAll({ activePage: basicPage, itemsPerPage: basicRows });
                })
              }
            />
          </div>
        ) : (
          <div>
            <Button
              icon="pi pi-eye"
              className="p-button-rounded p-button-success p-mr-2"
              label="Aktif Et"
              onClick={() =>
                giftService.activeGift(rowData).then((res) => {
                  getAll({ activePage: basicPage, itemsPerPage: basicRows });
                })
              }
            />
          </div>
        )}
      </div>
    );
  };

  const editGifts = (rowData) => {
    setEditGift(rowData);
    setEditImages(rowData.images);
    setEditDialog(true);
  };

  const imageTemplate = (rowData) => {
    return <img style={{}} src={rowData.publicAddress} />;
  };

  const deleteActions = (rowData) => {
    return (
      <div>
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-danger"
          onClick={(e) => {
            let _editImages = [...editImages];
            _editImages.forEach((value, index) => {
              if (value.id === rowData.id) {
                _editImages.splice(index, 1);
              }
            });
            setEditImages(_editImages);
            let _deleteImages = [...deleteImages];
            _deleteImages.push(rowData);
            setDeleteImages(_deleteImages);
          }}
        />
      </div>
    );
  };

  const itemTemplateImages = (image) => {
    return (
      <img src={image?.publicAddress} style={{ width: 200, height: 200 }} />
    );
  };

  const onSort = (e) => {
    let _lazyParams = { ...searchRequest, ...e };
    setSearchRequest(_lazyParams);
  };

  const renderGridItem = (item) => {
    return (
      <div className="p-col-12 p-md-3">
        <div className="p-grid p-dir-col p-ai-center">
          <Galleria
            value={item?.images}
            autoPlay
            showItemNavigators
            showThumbnails={false}
            showItemNavigatorsOnHover
            showIndicators
            circular
            item={itemTemplateImages}
            numVisible={4}
          ></Galleria>
        </div>

        <div style={{ fontSize: 16 }} className="product-name">
          {item?.name}
        </div>

        <span className="product-price">
          <b>Ürün Puanı:</b> {item?.giftScore}
        </span>
        {
          item?.barcode &&
          <div className="product-price">
            <b>Ürün Barkodu:</b> {item?.barcode}
          </div>
        }


        <div className="product-price">
          <b>Stok Miktarı:</b> {item?.stock}
        </div>


        {actionBodyTemplate(item)}
        <br />
      </div>
    );
  };
  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    getAll({ activePage: event.page, itemsPerPage: event.rows });
  };


  const itemTemplates = (product) => {
    if (!product) {
      return;
    }

    return renderGridItem(product);
  };

  const exportExcel = () => {
    giftService
      .getAllData()
      .then((res) => {
        console.log(allData);
        console.log("searchSaleInfoEager", res);
        import("xlsx").then((xlsx) => {
          var excelList = [];
          res.forEach((a) => {
            let _excelModel = {};
            _excelModel["İsim"] = a?.name;
            _excelModel["Ürün Puanı"] = a?.giftScore;
            _excelModel["Açıklama"] = a?.description;
            _excelModel["Ürün Barkodu"] = a?.barcode;
            _excelModel["Stok Miktarı"] = a?.stock;
            if (a?.status == 1) {
              _excelModel["Durum"] = "Aktif";
            } else {
              _excelModel["Durum"] = "Pasif";
            }
            excelList.push(_excelModel);
          });
          const worksheet = xlsx.utils.json_to_sheet(excelList);
          var wscols = [
            { width: 18 },
            { width: 18 },
            { width: 20 },
            { width: 20 },
            { width: 20 },
            { width: 18 },
          ];
          worksheet["!cols"] = wscols;
          const workbook = {
            Sheets: { data: worksheet },
            SheetNames: ["data"],
          };
          const excelBuffer = xlsx.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });
          saveAsExcelFile(excelBuffer, "Bayi Puan Hediyeleri");
        });
      })
      .catch((err) => {
        toast?.current?.show({
          severity: "error",
          summary: "Hata",
          detail: "Bayi Puan Hediyeleri getirme başarısız.",
        });
        console.log(err);
      })
      .finally(() => {
        setExportExcelDisabled(false);
      });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const openStockExcelModal = () => {
    setStockExcelDialog(true);
  }

  const exportExampleStockExcel = () => {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet([
        {
          "Ürün Barkodu": "barkod1",
          "Stok Miktarı": 0,
        }
      ]);
      const wscols = [
        { width: 20 },
        { width: 20 },
      ];
      worksheet["!cols"] = wscols;
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      saveAsExcelFile(excelBuffer, 'Stok Yükleme İçin Örnek Excel Dosyası');
    });
  }

  const importExcel = ({ files }) => {
    if (files.length === 1) {
      const file = files[0];
      let formData = new FormData();
      formData.append('file', file);
      files.length = 0;
      import('xlsx')
        .then(xlsx => {
          const reader = new FileReader();
          reader.onload = async (e) => {
            const wb = xlsx.read(e.target.result, { type: 'array' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = xlsx.utils.sheet_to_json(ws, { header: 1 });

            const cols = data[0];
            data.shift();


            let _importedCols = cols?.map(col => ({ field: col, col }));
            let _importedData = data?.map(d => {
              return cols.reduce((obj, c, i) => {
                obj[c] = d[i];
                return obj;
              }, {});
            });

            let validRecords = [];
            let invalidRecords = [];

            _importedData.forEach(value => {
              let barcode = null;
              let stock = null;
              if (value['Ürün Barkodu'] !== undefined && value['Ürün Barkodu'] !== null) {
                barcode = value['Ürün Barkodu'].toString()
              }

              console.log(value)
              console.log(typeof value['Stok Miktarı'])

              if (value['Stok Miktarı'] !== undefined && value['Stok Miktarı'] !== null && !isNaN(value['Stok Miktarı']) && value['Stok Miktarı'] >= 0) {
                stock = value['Stok Miktarı'].toString()
              }
              if (barcode === null || stock === null) {
                invalidRecords.push({ barcode: value['Ürün Barkodu'], stock: value['Stok Miktarı'] })
              } else {
                validRecords.push({ barcode, stock })
              }

            })

            setValidExcelRows(validRecords)
            setInvalidExcelRows(invalidRecords)
          };

          reader.readAsArrayBuffer(file);
        });
    }
  };


  const clear = () => {
    setValidExcelRows([])
    setInvalidExcelRows([])
  }

  const onHideStockDialog = () => {
    if(!loading) {
      clear()
      setStockExcelDialog(false)
    }
  }

  const saveValidRows = () => {
    setLoading(true);
    giftService.upsertGiftStocksByBarcode(validExcelRows)
      .then(res => {
        clear()
        setStockExcelDialog(false)
        getAll({ activePage: basicPage, itemsPerPage: basicRows });
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.current?.show({
            severity: "error",
            summary: "Hata",
            detail: err?.response?.data?.message,
            life: 4000,
          });
        }
        setLoading(false);
      });
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="datatable-responsive-demo">
        <span className="p-float-label marginInput" style={{ marginLeft: 10 }}>
          <Button
            onClick={(e) => setCreateDialog(true)}
            icon="pi pi-plus-circle"
            label="Yeni Ürün Ekle"
            className="p-button-success"
          />
          <Button
            type="button"
            icon="pi pi-upload"
            label="Stok Yükle"
            className="p-button justify-self-end p-button-help"
            onClick={openStockExcelModal}
            style={{ marginLeft: 10 }}
          />
        </span>

        <span className="p-float-label marginInput" style={{ marginLeft: 10 }}>
          <InputText
            placeholder="Ürün Ara..."
            value={searchRequest?.name}
            onChange={(e) => {
              let _searchRequest = { ...searchRequest };
              _searchRequest.name = e.target.value;
              setSearchRequest(_searchRequest);
            }}
          />
        </span>
        <span className="p-float-label marginInput" style={{ marginLeft: 10 }}>
          <Dropdown
            value={dropValue}
            options={_options}
            showClear
            placeholder="Puana Göre Sırala"
            onChange={(e) => {
              if (e.target.value === "Büyükten Küçüğe Sırala") {
                setSortValue("desc");
                setDropValue(e.target.value);
              } else if (e.target.value === "Küçükten Büyüğe Sırala") {
                setSortValue("asc");
                setDropValue(e.target.value);
              } else {
                setSortValue("");
                setDropValue("");
              }
            }}
          />

          <Dropdown
            value={dropValueActive}
            options={_activeoptions}
            showClear
            placeholder="Aktif/Pasif"
            onChange={(e) => {
              if (e.target.value === "Aktif") {
                setSortValueActive("desc");
                setDropValueActive(e.target.value);
              } else if (e.target.value === "Pasif") {
                setSortValueActive("asc");
                setDropValueActive(e.target.value);
              } else {
                setSortValueActive("");
                setDropValueActive("");
              }
            }}
          />

        </span>
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: 8 }}>
          <Button
            type="button"
            icon="pi pi-external-link"
            label="Excel'e Aktar"
            className="p-button p-button-sm justify-self-end"
            disabled={exportExcelDisabled}
            onClick={exportExcel}
            style={{ marginLeft: 10 }}
          />
        </div>
        <div className="card">
          <DataView
            value={allGifts}
            layout={layout}
            itemTemplate={itemTemplates}
            lazy
            loading={loading}
            emptyMessage="Ürün Bulunamadı"
          />
        </div>
        <Paginator
          first={basicFirst}
          rows={basicRows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[12, 24, 36]}
          onPageChange={onBasicPageChange}
        />
      </div>

      <Dialog
        header="Yeni Kayıt"
        style={{ width: "100%" }}
        onHide={(e) => {
          setCreateDialog(false);
          setCreateGift({});
          setSelectedFiles([]);
        }}
        visible={createDialog}
      >
        <span className="p-float-label marginInput">
          <InputText
            id="name"
            value={createGift?.name}
            onChange={(e) => {
              let _createGift = { ...createGift };
              _createGift.name = e.target.value;
              setCreateGift(_createGift);
            }}
            style={{ width: 300 }}
          />
          <label htmlFor="name">Ürün İsmi</label>
        </span>

        <span className="p-float-label marginInput">
          <InputText
            id="description"
            value={createGift?.description}
            onChange={(e) => {
              let _createGift = { ...createGift };
              _createGift.description = e.target.value;
              setCreateGift(_createGift);
            }}
            style={{ width: 300 }}
          />
          <label htmlFor="description">Ürün Açıklaması</label>
        </span>
        <span className="p-float-label marginInput">
          <InputText
            type="number"
            min={1}
            max={1000000}
            id="giftScore"
            value={createGift?.giftScore}
            onChange={(e) => {
              let _createGift = { ...createGift };
              _createGift.giftScore = e.target.value;
              setCreateGift(_createGift);
            }}
            style={{ width: 300 }}
          />
          <label htmlFor="giftScore">Ürün Skoru</label>
        </span>

        <span className="p-float-label marginInput">
          <InputText
            id="barcode"
            value={createGift?.barcode}
            onChange={(e) => {
              let _createGift = { ...createGift };
              _createGift.barcode = e.target.value;
              setCreateGift(_createGift);
            }}
            style={{ width: 300 }}
          />
          <label htmlFor="giftScore">Ürün Barkodu</label>
        </span>

        <span className="p-float-label marginInput">
          <InputText
            type="number"
            min={1}
            max={1000000}
            id="stock"
            value={createGift?.stock}
            onChange={(e) => {
              let _createGift = { ...createGift };
              let stock = 0;
              if (e.target.value > 0) {
                stock = e.target.value;
              }
              _createGift.stock = stock;
              setCreateGift(_createGift);
            }}
            style={{ width: 300 }}
          />
          <label htmlFor="stock">Stok Miktarı</label>
        </span>

        <div>
          <span className="p-float-label marginInput">
            <h5>Resimler</h5>
            <FileUpload
              ref={fileUploadRef}
              name="demo[]"
              url="https://primefaces.org/primereact/showcase/upload.php"
              multiple
              accept="image/*"
              maxFileSize={10000000000}
              onSelect={onTemplateSelect}
              onError={onTemplateClear}
              onClear={onTemplateClear}
              itemTemplate={itemTemplate}
              emptyTemplate={emptyTemplate}
              chooseOptions={chooseOptions}
              uploadOptions={uploadOptions}
              cancelOptions={cancelOptions}
            />
          </span>
        </div>

        <Button
          className="p-button profile-image"
          loading={loading}
          disabled={loading}
          label="Kaydet"
          onClick={(e) => {
            upsertNewGift();
          }}
        />
      </Dialog>

      <Dialog
        header="Düzenle"
        style={{ width: "100%" }}
        onHide={(e) => {
          setEditDialog(false);
          setEditGift({});
          setSelectedFiles([]);
        }}
        visible={editDialog}
      >
        <span className="p-float-label marginInput">
          <InputText
            id="name"
            value={editGift?.name}
            onChange={(e) => {
              let _editGift = { ...editGift };
              _editGift.name = e.target.value;
              setEditGift(_editGift);
            }}
            style={{ width: 300 }}
          />
          <label htmlFor="name">Ürün İsmi</label>
        </span>

        <span className="p-float-label marginInput">
          <InputText
            id="description"
            value={editGift?.description}
            onChange={(e) => {
              let _editGift = { ...editGift };
              _editGift.description = e.target.value;
              setEditGift(_editGift);
            }}
            style={{ width: 300 }}
          />
          <label htmlFor="description">Ürün Açıklaması</label>
        </span>
        <span className="p-float-label marginInput">
          <InputText
            type="number"
            min={1}
            max={1000000}
            id="giftScore"
            value={editGift?.giftScore}
            onChange={(e) => {
              let _editGift = { ...editGift };
              _editGift.giftScore = e.target.value;
              setEditGift(_editGift);
            }}
            style={{ width: 300 }}
          />
          <label htmlFor="giftScore">Ürün Skoru</label>
        </span>

        <span className="p-float-label marginInput">
          <InputText
            id="barcode"
            value={editGift?.barcode}
            onChange={(e) => {
              let _editGift = { ...editGift };
              _editGift.barcode = e.target.value;
              setEditGift(_editGift);
            }}
            style={{ width: 300 }}
          />
          <label htmlFor="giftScore">Ürün Barkodu</label>
        </span>

        <span className="p-float-label marginInput">
          <InputText
            type="number"
            min={0}
            max={1000000}
            id="stock"
            value={editGift?.stock}
            onChange={(e) => {
              let _editGift = { ...editGift };
              let stock = e.target.value;
              if (stock < 0) {
                stock = 0;
              }
              _editGift.stock = stock;
              setEditGift(_editGift);
            }}
            style={{ width: 300 }}
          />
          <label htmlFor="stock">Stok Miktarı</label>
        </span>

        <div>
          <span className="p-float-label marginInput">
            <ResponsiveDataTable
              emptyMessage="Kayıtlı Resim bulunamadı"
              header="Kayıtlı Resimler"
              value={editImages}
            >
              <Column
                header="Resim"
                body={imageTemplate}
                field="publicAddress"
              />
              <Column header="Sil" body={deleteActions} />
            </ResponsiveDataTable>
          </span>
        </div>
        <div>
          <span className="p-float-label marginInput">
            <h5>Yeni Resimler</h5>
            <FileUpload
              ref={fileUploadRef}
              name="demo[]"
              url="https://primefaces.org/primereact/showcase/upload.php"
              multiple
              accept="image/*"
              maxFileSize={1000000}
              onSelect={onTemplateSelect}
              onError={onTemplateClear}
              onClear={onTemplateClear}
              itemTemplate={itemTemplate}
              emptyTemplate={emptyTemplate}
              chooseOptions={chooseOptions}
              uploadOptions={uploadOptions}
              cancelOptions={cancelOptions}
            />
          </span>
        </div>

        <Button
          className="p-button profile-image"
          loading={loading}
          label="Kaydet"
          onClick={(e) => {
            updateGift();
          }}
        />
      </Dialog>

      <Dialog
        style={{ width: "100%" }}
        onHide={(e) => setViewImageDialog(false)}
        visible={viewImageDialog}
      >
        <ResponsiveDataTable
          emptyMessage="Kayıtlı Resim bulunamadı"
          header="Kayıtlı Resimler"
          value={editImages}
        >
          <Column header="Resim" body={imageTemplate} field="publicAddress" />
        </ResponsiveDataTable>
      </Dialog>

      <Dialog
        header='Stok Yükle'
        style={{ width: '50vw' }}
        onHide={onHideStockDialog}
        visible={stockExcelDialog}
      >

        <div className="p-d-flex p-ai-center p-py-2">
          <Button label="Örnek Excel Dokümanı İndir" icon={"pi pi-download"} onClick={e => exportExampleStockExcel(e)} className="p-button-help p-mr-2" disabled={loading}/>
          <FileUpload
            disabled={loading}
            maxFileSize={100000000}
            chooseOptions={{ label: 'Excel Dokümanı Yükle', icon: 'pi pi-file-excel', className: 'p-button-primary' }}
            mode="basic" name="demo[]" auto
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            className="p-mr-2" customUpload={true} uploadHandler={importExcel} />

          {validExcelRows?.length > 0 ? <Button type="button" style={{ marginRight: 10 }} disabled={loading}
            label="Yüklenen Verileri Temizle" icon="pi pi-times"
            onClick={clear} className="p-button-info" /> : ""}
        </div>

        {
          validExcelRows?.length > 0 &&
          <ResponsiveDataTable
            loading={loading}
            header="Exceldeki Kayıtlar"
            value={validExcelRows}
            paginator rows={5}
          >
            <Column field="Index" body={(d, p) => p.rowIndex + 1}
              headerStyle={{ width: '5.4rem' }} />
            <Column header="Ürün Barkodu" field="barcode" />
            <Column header="Stok Miktarı" field="stock" />
          </ResponsiveDataTable>
        }

        {
          invalidExcelRows?.length > 0 &&
          <ResponsiveDataTable
            loading={loading}
            header="Geçersiz Kayıtlar"
            value={invalidExcelRows}
            paginator rows={5}
            className={"p-mt-4"}
          >
            <Column field="Index" body={(d, p) => p.rowIndex + 1}
              headerStyle={{ width: '5.4rem' }} />
            <Column header="Ürün Barkodu" field="barcode" />
            <Column header="Stok Miktarı" field="stock" />
          </ResponsiveDataTable>
        }

        <div className="p-d-flex p-ai-center p-my-3">
          {
            validExcelRows?.length > 0 &&
            <Button label="Exceldeki Kayıtları Kaydet" icon={"pi pi-upload"} onClick={e => saveValidRows(e)} className="p-button-success" disabled={loading} />
          }
        </div>
      </Dialog>
    </>
  );
};
export default GiftView;
