import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import {handleError} from "../../service/HandleErrorService";
import VoucherService from "./httpService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Checkbox} from "primereact/checkbox";
import {useHistory} from "react-router";
import {InputText} from "primereact/inputtext";

const AccessoryCampaignPassTitleList = () => {

  const history = useHistory();
  const toast = useRef(null);
  const voucherService = new VoucherService();

  const [loading, setLoading] = useState(false);
  const [passInfo, setPassInfo] = useState([]);
  const [accessoryCampaignPassTitleList, setAccessoryCampaignPassTitleList] = useState([]);

  const [searchCriteriaLazyLoading, setSearchCriteriaLazyLoading] = useState(false);
  const [searchCriteriaProgressing, setSearchCriteriaProgressing] = useState(false);
  const [passValue, setPassValue] = useState(null);
  const [excelButtonDisabled, setExcelButtonDisabled] = useState(false);

  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  useEffect(() => {
    getSerialInfo();
    getAll({...paginationConfig, activePage: 0});
  }, []);

  useEffect(() => {
    setSearchCriteriaLazyLoading(true);
  }, [passValue]);

  useEffect(() => {
    if (searchCriteriaLazyLoading) {
      setTimeout(() => {
        setSearchCriteriaProgressing(true);
        setSearchCriteriaLazyLoading(false);
      }, 2000);
    }
  }, [searchCriteriaLazyLoading]);

  useEffect(() => {
    if (searchCriteriaProgressing) {
      getAll({...paginationConfig, activePage: 0});
      setSearchCriteriaProgressing(false);
    }
  }, [searchCriteriaProgressing]);

  const getSerialInfo = () => {
    voucherService.getPassInfo().then(res => {
      console.log("getPassInfo", res);
      setPassInfo(res);
    }).catch(err => {
      console.log(err);
    })
  };

  const getAll = (pageState) => {
    setLoading(true);
    if(passValue == null){
      voucherService.getAllPassTitles(pageState.itemsPerPage, pageState.activePage).then(res => {
        console.log("getAllSerialTitles", res.content);
        setAccessoryCampaignPassTitleList(res.content);

        pageState.totalRecords = res.totalElements;
        pageState.first = pageState.itemsPerPage * pageState.activePage;
        setPaginationConfig({
          ...pageState,
        });
      }).catch(err => handleError(err, toast)).finally(() => {
        setLoading(false);
      })
    } else {
      voucherService.getAllPassTitlesByPassValue(passValue, pageState.itemsPerPage, pageState.activePage).then(res => {
        console.log("getAllPassTitlesByPassValue", res.content);
        setAccessoryCampaignPassTitleList(res.content);

        pageState.totalRecords = res.totalElements;
        pageState.first = pageState.itemsPerPage * pageState.activePage;
        setPaginationConfig({
          ...pageState,
        });
      }).catch(err => handleError(err, toast)).finally(() => {
        setLoading(false);
      })
    }
  };

  const openAccessoryCampaignPassTitle = () => {
    localStorage.setItem("accessoryCampaignPassTitleId", window.btoa(null));
    history.push("/accessoryCampaignPassTitle");
  };

  const openAccessoryCampaignPassTitleWithId = (e) => {
    localStorage.setItem("accessoryCampaignPassTitleId", window.btoa(e.currentTarget.id));
    history.push("/accessoryCampaignPassTitle");
  };

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    getAll(pageState);
  };

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const responsiveColumnRight = (title, content) => {
    return (
      <div>
        <span className="p-column-title">{title}</span>
        <span style={{float: 'right'}}>{content}</span>
      </div>
    );
  };

  const createDateTimeTemplate = (data) => {
    return responsiveColumn("Oluşturulma Zamanı", data?.createDateTimeString)
  };

  const nameTemplate = (data) => {
    return responsiveColumn("Oluşturan Kişi Adı", data?.userDto.name)
  };

  const surnameTemplate = (data) => {
    return responsiveColumn("Oluşturan Kişi Soyadı", data?.userDto.surname)
  };

  const totalUsedPassCountTemplate = (data) => {
    return responsiveColumnRight("Kullanılan Şifre Adedi", addCommas(data?.totalUsedPass))
  };

  const totalSerialNumberCountTemplate = (data) => {
    return responsiveColumnRight("Oluşturulan Şifre Adedi", addCommas(data?.totalPassNumberCount))
  };

  const titleStatusDisplayTemplate = (data) => {
    return responsiveColumn("İşlem Durumu", data?.titleStatus == "COMPLETED" ? data?.titleStatusDisplay : data?.titleStatusDisplay + "(" + addCommasRatio(data?.totalCreatedPass / data?.totalPassNumberCount * 100) + "%)")
  };


  const activeTemplate = (rowData) => {
    return responsiveColumn("Aktiflik Durumu", <Checkbox style={{cursor: 'context-menu'}} disabled={true}
                                                         checked={rowData.isActivated}/>)
  };

  const accessoryCampaignSerialTitleButtonTemplate = (data) => {
    return (
      <div className="p-d-flex p-justify-even">
        <Button id={data.id} type="button" className="p-button-rounded p-button-warning" icon="pi pi-external-link"
                tooltip="İşlemler"
                tooltipOptions={{position: 'left', mouseTrack: true, mouseTrackTop: 15}}
                onClick={openAccessoryCampaignPassTitleWithId}/>
      </div>
    );
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const addCommasRatio = num => num?.toFixed(0).toString();

  const addCommas = num => num != null ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;

  const numFix = num => num != null ? num.toFixed(4).toString() : null;

  const getRemainingPass = () => {
    import('xlsx').then(xlsx => {
      setExcelButtonDisabled(true);
      voucherService.getRemainingPass().then(res => {
        var excelList = [];
        res.forEach(a => {
          excelList.push({
            "Adet": a.count,
            "Ttcrm Kodu": a.ttcrmCode,
            "Kampanya": "Paket" + a.amount.toFixed(0)
          })
        });
        const worksheet = xlsx.utils.json_to_sheet(excelList);
        var wscols = [
          {width: 15},
          {width: 15},
          {width: 15},
          {width: 15},
          {width: 15}
        ];
        worksheet["!cols"] = wscols;
        const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
        saveAsExcelFile(excelBuffer, 'Sifre_Stoğu_Azalanlar');
        setExcelButtonDisabled(false);
      }).catch(err => {
        console.log(err);
        setExcelButtonDisabled(false);
      });
    });
  };

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card">
          <p><b>Oluşturucalabilcek Şifre Sayısı:</b> {addCommas(passInfo?.canBeCreatedUnit)}</p>
          <p><b>Oluşturulan Şifre
            Sayısı:</b> {addCommas(passInfo?.createdUnit)} - {numFix(passInfo?.createdUnit / passInfo?.canBeCreatedUnit * 100)}%
          </p>
          <p><b>Kullanılan Şifre
            Sayısı:</b> {addCommas(passInfo?.usedUnit)} - {passInfo?.usedUnit !== 0 ? numFix(passInfo?.usedUnit / passInfo?.createdUnit * 100) : numFix(0)}%
          </p>
          <Button onClick={openAccessoryCampaignPassTitle} icon="pi pi-plus-circle" label="Şifre Oluştur"
                  className="p-button-success p-mr-2 p-mb-2"/>
          <Button type="button" icon="pi pi-external-link" label="Şifre Stoğu Azalanları Excel`e Aktar" className="p-button p-mr-2 p-mb-2"
                  disabled={excelButtonDisabled}
                  onClick={getRemainingPass}/>
          <hr/>
          <InputText style={{marginBottom: 10, width: 320}} value={passValue}
                     placeholder="Aramak İstediğiniz Şifreyi Giriniz"
                     onChange={(e) => setPassValue(e.target.value)}/>
          <ResponsiveDataTable
            className="p-datatable-responsive-demo"
            header="Oluşturulan Şifreler"
            loading={loading}asd
            value={accessoryCampaignPassTitleList}
            paginationConfig={paginationConfig}
            onActivePageChange={onActivePageChange}
            emptyMessage="Şifre bulunamadı"
          >
            <Column body={createDateTimeTemplate} header="Oluşturulma Zamanı"/>
            <Column body={nameTemplate} header="Oluşturan Kişi Adı"/>
            <Column body={surnameTemplate} header="Oluşturan Kişi Soyadı"/>
            <Column body={totalUsedPassCountTemplate} header="Kullanılan Şifre Adedi" headerStyle={{textAlign: 'right'}}/>
            <Column body={totalSerialNumberCountTemplate} header="Oluşturulan Şifre Adedi" headerStyle={{textAlign: 'right'}}/>
            <Column body={titleStatusDisplayTemplate} header="İşlem Durumu"/>
            <Column body={activeTemplate} header="Aktiflik Durumu"/>
            <Column body={accessoryCampaignSerialTitleButtonTemplate}
                    headerStyle={{width: "7em", textAlign: "center"}}
                    bodyStyle={{textAlign: 'center', overflow: 'visible', justifyContent: 'center'}}/>
          </ResponsiveDataTable>
        </div>
      </div>
    </>
  )

};

export default AccessoryCampaignPassTitleList;
