import React, { useEffect, useRef, useState } from "react";
import { handleError } from "../../service/HandleErrorService";
import { GiftService } from "./GiftService";
import { Button } from "primereact/button";
import { ResponsiveDataTable } from "../../components/ResponsiveDataTable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import classNames from "classnames";
import moment from "moment/moment";

const GiftValidDate = () => {

    const toast = useRef(null);
    const giftService = new GiftService();

    const [loading, setLoading] = useState(false);
    const [loadingUpsert, setLoadingUpsert] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [list, setList] = useState(false);
    const [selectedData, setSelectedData] = useState(false);
    const [visibleUpsertModal, setVisibleUpsertModal] = useState(false);
    const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);


    useEffect(() => {
        findAll();
    }, []);

    const findAll = () => {
        setLoading(true);
        giftService.findAllGiftValidDates()
            .then(res => setList(res))
            .catch(err => handleError(err, toast))
            .finally(() => setLoading(false));
    }

    const onUpsert = () => {

        setSubmitted(true)

        if (selectedData?.startDate == null || selectedData?.endDate == null) {
            toast.current?.show({
                severity: "warn",
                summary: "Uyarı",
                detail: "Başlangıç ve bitiş tarihleri boş olmamalıdır",
                life: 2000,
            });
            return;
        }

        if (selectedData.startDate > selectedData?.endDate) {
            toast.current?.show({
                severity: "warn",
                summary: "Uyarı",
                detail: "Başlangıç tarihi bitiş tarihinden ileride olmamalıdır",
                life: 2000,
            });
            return;
        }


        setLoadingUpsert(true)
        giftService.upsertGiftValidDate(selectedData)
            .then(res => {
                setVisibleUpsertModal(false);
                setSubmitted(false)
                findAll();
            })
            .catch(err => handleError(err, toast))
            .finally(() => setLoadingUpsert(false));
    }

    const onDelete = () => {
        giftService.deleteGiftValidDate(selectedData?.id)
            .then(res => {
                findAll();
                setVisibleDeleteModal(false);
            })
            .catch(err => handleError(err, toast));
    }

    const openUpsertModal = (data) => {
        if (data) {
            setSelectedData({
                id: data?.id,
                startDate: new Date(data?.startDate),
                endDate: new Date(data?.endDate)
            })
            setSubmitted(false)
        } else {
            setSubmitted(false)
            setSelectedData(null)
        }
        setVisibleUpsertModal(true)
    }

    const closeUpsertModal = () => {
        setSelectedData(null)
        setVisibleUpsertModal(false)
    }

    const openDeleteModal = (data) => {
        setSelectedData({
            id: data?.id,
            startDate: new Date(data?.startDate),
            endDate: new Date(data?.endDate)
        })
        setVisibleDeleteModal(true)
    }

    const closeDeleteModal = () => {
        setVisibleDeleteModal(false)
        setSelectedData(null)
    }

    const setStartDate = (date) => {
        let _data = { ...selectedData };
        _data.startDate = date;
        setSelectedData(_data);
    }

    const setEndDate = (date) => {
        let _data = { ...selectedData };
        _data.endDate = date;
        setSelectedData(_data);
    }

    const actionButtonTemplate = (data) => {
        return (
            <div className="p-d-flex p-justify-even">
                <Button
                    id={data.id}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning"
                    label="Düzenle"
                    onClick={() => openUpsertModal(data)}
                />
                <Button
                    id={data.id}
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    label="Sil"
                    onClick={() => openDeleteModal(data)}
                />
            </div>
        );
    };

    const responsiveColumn = (title, content) => {
        return (
            <div>
                <div className="p-column-title">{title}</div>
                {content}
            </div>
        );
    };

    const startDateTemplate = (data) => {
        return responsiveColumn("Başlangıç Tarihi", moment(data?.startDate).format("DD.MM.YYYY"))
    }

    const endDateTemplate = (data) => {
        return responsiveColumn("Bitiş Tarihi", moment(data?.endDate).format("DD.MM.YYYY"))
    }


    return (
        <>
            <Toast ref={toast} />
            <div className="datatable-responsive-demo">
                <div className="card">
                    <Button onClick={() => openUpsertModal(null)} icon="pi pi-plus-circle" label="Yeni Tarih"
                        className="p-button-success p-mr-2 p-mb-2" />
                    <ResponsiveDataTable
                        value={list}
                        className="p-datatable-responsive-demo"
                        sortField={"endDate"}
                        sortOrder={-1}
                        loading={loading}
                        header="Tarih Listesi"
                        emptyMessage="Hediye kataloğu açık olacağı tarihler bulunamadı"
                        paginator rows={10} rowsPerPageOptions={[10, 20, 30]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} arası gösteriliyor">
                        <Column field="startDate" body={startDateTemplate} header="Başlangıç Tarihi" sortable />
                        <Column field="endDate" body={endDateTemplate} header="Bitiş Tarihi" sortable />
                        <Column body={actionButtonTemplate} headerStyle={{ width: '18rem' }}
                            bodyStyle={{ textAlign: 'center', overflow: 'visible', justifyContent: 'center' }} />
                    </ResponsiveDataTable>
                </div>
            </div>

            <Dialog
                header={selectedData?.id ? 'Tarih Güncelleme' : 'Yeni Tarih Ekle'} visible={visibleUpsertModal}
                style={{ width: '360px' }} onHide={closeUpsertModal}>

                <div className="p-grid">
                    <div className="p-col-12">
                        <span className="p-float-label marginInput">
                            <Calendar
                                id="startDate"
                                showIcon
                                dateFormat={"dd.mm.yy"}
                                style={{ width: 280 }}
                                value={selectedData?.startDate}
                                onChange={(e) => setStartDate(e.value)}
                                className={classNames({ 'p-invalid': submitted && !selectedData?.startDate })}
                            />
                            <label htmlFor="startDate">Başlangıç Tarihi</label>
                        </span>
                        <span className="p-float-label marginInput">
                            <Calendar
                                id="endDate"
                                showIcon
                                dateFormat={"dd.mm.yy"}
                                style={{ width: 280 }}
                                value={selectedData?.endDate}
                                onChange={(e) => setEndDate(e.value)}
                                className={classNames({ 'p-invalid': submitted && !selectedData?.endDate })}
                            />
                            <label htmlFor="endDate">Bitiş Tarihi</label>
                        </span>
                        <Button style={{ marginTop: 20 }} className="p-button-success" label={selectedData?.id ? 'Güncelle' : 'Ekle'}
                            disabled={loadingUpsert} onClick={onUpsert} />
                    </div>
                </div>
            </Dialog>

            <Dialog header={"Tarih Silme"} visible={visibleDeleteModal} style={{ width: '300px' }}
                onHide={closeDeleteModal}>
                <div className="p-grid">
                    <div className="p-col-12">
                        {`${moment(selectedData?.startDate).format("DD.MM.YYYY")} - ${moment(selectedData?.endDate).format("DD.MM.YYYY")} arası tarihi silmek istediğinize emin misiniz?`}
                    </div>
                    <div className="p-col-6">
                        <Button style={{ marginTop: 20, width: '100%' }} className="p-button-primary" label={'Hayır'}
                            icon="pi pi-times"
                            onClick={closeDeleteModal} />
                    </div>

                    <div className="p-col-6">
                        <Button style={{ marginTop: 20, width: '100%' }} className="p-button-danger" label={'Evet'}
                            icon="pi pi-trash"
                            onClick={onDelete} />
                    </div>
                </div>
            </Dialog >
        </>
    )
};
export default GiftValidDate;
