import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import ProductSalesManagementService from "./httpService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Calendar} from "primereact/calendar";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";

const ProductSaleList = () => {

  const resellerUser = JSON.parse(localStorage.getItem('user')).user?.roles.includes("RESELLER_ADMIN") ||
    JSON.parse(localStorage.getItem('user')).user?.roles.includes("RESELLER_USER");
  const resellerCode = JSON.parse(localStorage.getItem('user')).user?.loginReseller?.code;
  const parentResellerCode = JSON.parse(localStorage.getItem('user')).user?.loginReseller?.parentReseller?.code;
  const resellerType = JSON.parse(localStorage.getItem('user')).user?.loginReseller?.resellerType;
  const supplyAdmin = JSON.parse(localStorage.getItem('user')).user?.roles.includes("SUPPLY_ADMIN");
  const supplyId = JSON.parse(localStorage.getItem('user')).user?.supply?.id;
  const distyAdmin = JSON.parse(localStorage.getItem('user')).user?.roles.includes("DISTY_ADMIN");
  const distyCode = JSON.parse(localStorage.getItem('user')).user?.disty?.code;
  const toast = useRef(null);
  const productSalesManagementService = new ProductSalesManagementService();

  const [loading, setLoading] = useState(false);
  const [searchCriteriaLazyLoading, setSearchCriteriaLazyLoading] = useState(false);
  const [searchCriteriaProgressing, setSearchCriteriaProgressing] = useState(false);
  const [saleInfoList, setSaleInfoList] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState({});
  const [selectedParentResellerCode, setSelectedParentResellerCode] = useState(null);
  const [selectedResellerCode, setSelectedResellerCode] = useState(null);

  const [detailModal, setDetailModal] = useState(false);
  const [selectedSale, setSelectedSale] = useState(false);

  const [selectedTimeBegin, setSelectedTimeBegin] = useState(null);
  const [selectedTimeEnd, setSelectedTimeEnd] = useState(null);
  const [serialNo, setSerialNo] = useState(null);
  const [barcode, setBarcode] = useState(null);
  const [pass, setPass] = useState(null);
  const [distyName, setDistyName] = useState(null);
  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [serial, setSerial] = useState(null);
  const [saleType, setSaleType] = useState(null);
  const [saleStatus, setSaleStatus] = useState(null);

  const [exportExcelDisabled, setExportExcelDisabled] = useState(null);

  const [cancelModalHeader, setCancelModalHeader] = useState(null);
  const [cancelModalMessage, setCancelModalMessage] = useState(null);
  const [cancelModal, setCancelModal] = useState(false);
  const [cancelDescriptionModal, setCancelDescriptionModal] = useState(false);
  const [selectedCancelOption, setSelectedCancelOption] = useState(false);

  const [selectedProductSaleId, setSelectedProductSaleId] = useState(null);
  const [selectedProductSerialNoIdList, setSelectedProductSerialNoIdList] = useState(null);

  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  const [saleTypeOptions, setSaleTypeOptions] = useState(
    [
      {value: "TEMLIK", name: "Kampanyalı"},
      {value: "VANILLA", name: "Peşin-Kredi Kartlı"}
    ]
  );

  const [saleStatusOptions, setSaleStatusOptions] = useState(
    [
      {value: "COMPLETED", name: "İşlem"},
      {value: "SALE_CANCEL_APPROVAL", name: "İptal Onayı"},
      {value: "SALE_CANCELED", name: "İptal"}
    ]
  );

  const [cancelOptions, setCancelOptions] = useState(
    [
      "iptal-TT Sistemlerinde Satışı Yapılamadı",
      "iptal-TT Sistemlerinde Satışı Gerçekleşti",
      "iptal-TT Sistemindeki Satış İptali Bayi Destek Onayında  "
    ]
  );

  useEffect(() => {
    if (resellerUser) {
      setSelectedParentResellerCode(parentResellerCode);
      if(resellerType != "TTM" && resellerType != "EDM"){
        setSelectedResellerCode(resellerCode);
      }
    } else if (distyAdmin) {
      setDistyName(distyCode);
    } else {
      searchSaleInfo({...paginationConfig, activePage: 0});
    }
  }, []);

  useEffect(() => {
    setSearchCriteriaLazyLoading(true);
  }, [selectedTimeBegin, selectedTimeEnd, selectedParentResellerCode, selectedResellerCode, serialNo, barcode, serial, pass, distyName, saleType, saleStatus, searchCriteria]);

  useEffect(() => {
    if (searchCriteriaLazyLoading) {
      setTimeout(() => {
        setSearchCriteriaProgressing(true);
        setSearchCriteriaLazyLoading(false);
      }, 2000);
    }
  }, [searchCriteriaLazyLoading]);

  useEffect(() => {
    if (searchCriteriaProgressing) {
      searchSaleInfo({...paginationConfig, activePage: 0});
      setSearchCriteriaProgressing(false);
    }
  }, [searchCriteriaProgressing]);

  const searchSaleInfo = (pageState) => {
    var dateControl = true;
    if (selectedTimeBegin != null && selectedTimeEnd != null && selectedTimeBegin.getTime() > selectedTimeEnd.getTime()) {
      dateControl = false;
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'İşlem aramak için başlangıç tarihi bitiş tarihinden önce olmalıdır.'
      });
    }
    if (dateControl) {
      setLoading(true);
      var sort = "";
      if (searchCriteria.sortField != null && searchCriteria.sortOrder != null) {
        sort += searchCriteria.sortField;
        if (searchCriteria.sortOrder == 1) {
          sort += ",asc";
        } else {
          sort += ",desc";
        }
      } else {
        sort += "completedDateTime,desc";
      }

      searchCriteria.parentResellerCode = selectedParentResellerCode;
      searchCriteria.resellerCode = selectedResellerCode;
      searchCriteria.serialNo = serialNo;
      searchCriteria.barcode = barcode;
      searchCriteria.serial = serial;
      searchCriteria.pass = pass;
      searchCriteria.distyName = distyName;
      searchCriteria.category = category;
      searchCriteria.subCategory = subCategory;
      searchCriteria.saleType = saleType;
      searchCriteria.saleStatus = saleStatus;

      if (selectedTimeBegin != null) {
        searchCriteria.saleDateStart = selectedTimeBegin.toLocaleDateString("tr-TR") + " " + selectedTimeBegin.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.saleDateStart = null;
      }

      if (selectedTimeEnd != null) {
        searchCriteria.saleDateEnd = selectedTimeEnd.toLocaleDateString("tr-TR") + " " + selectedTimeEnd.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.saleDateEnd = null;
      }

      searchCriteria.productSaleStatusList = ["COMPLETED", "SALE_CANCEL_APPROVAL", "SALE_CANCELED", "SERIAL_NO_CANCEL_APPROVAL", "SERIAL_NO_CANCELED"];

      if (supplyAdmin) {
        searchCriteria.supplyId = supplyId;
      }
      productSalesManagementService.searchProductSale(searchCriteria, pageState.itemsPerPage, pageState.activePage, sort).then(res => {
        console.log("searchSaleInfo", res.content);
        if (res.content && res.content.length > 0) {
          setSaleInfoList(res.content);

          pageState.totalRecords = res.totalElements;
          pageState.first = pageState.itemsPerPage * pageState.activePage;
          setPaginationConfig({
            ...pageState,
          });
        } else {
          setSaleInfoList([]);
        }
      }).catch(err => {
        toast?.current?.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'İşlem bilgisi getirme başarısız.'
        });
        console.log(err);
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  const exportExcel = () => {
    var dateControl = false;
    if ((serialNo != null && serialNo != "") || (barcode != null && barcode != "")  || (pass != null && pass != "") || (serial != null && serial != "") || (serial != null && serial != "")
      || (selectedResellerCode != null && selectedResellerCode != "") || (selectedParentResellerCode != null && selectedParentResellerCode != "")) {
      dateControl = true;
    } else if (selectedTimeBegin != null && selectedTimeEnd != null) {
      if (selectedTimeEnd.getTime() - selectedTimeBegin.getTime() <= 32 * 24 * 3600000) {
        if (selectedTimeBegin.getTime() <= selectedTimeEnd.getTime()) {
          dateControl = true;
        } else {
          toast.current.show({
            severity: 'warn',
            summary: 'Uyarı',
            detail: 'Başlangıç tarihi bitiş tarihinden önce olmalıdır.'
          });
        }
      } else {
        toast.current.show({
          severity: 'warn',
          summary: 'Uyarı',
          detail: 'Excel almak için tarih aralığı bir aydan fazla olmamalıdır.'
        });
      }
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Excel almak için filtreleme yapılmalı veya tarih alanları dolu olmalıdır.'
      });
    }
    if (dateControl) {
      setExportExcelDisabled(true);

      searchCriteria.resellerCode = selectedResellerCode;
      searchCriteria.serialNo = serialNo;
      searchCriteria.barcode = barcode;
      searchCriteria.serial = serial;
      searchCriteria.pass = pass;
      searchCriteria.distyName = distyName;
      searchCriteria.subCategory = subCategory;
      searchCriteria.category = category;

      if (selectedTimeBegin != null) {
        searchCriteria.saleDateStart = selectedTimeBegin.toLocaleDateString("tr-TR") + " " + selectedTimeBegin.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.saleDateStart = null;
      }

      if (selectedTimeEnd != null) {
        searchCriteria.saleDateEnd = selectedTimeEnd.toLocaleDateString("tr-TR") + " " + selectedTimeEnd.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.saleDateEnd = null;
      }

      searchCriteria.productSaleStatusList = ["COMPLETED", "SALE_CANCEL_APPROVAL", "SALE_CANCELED", "SERIAL_NO_CANCEL_APPROVAL", "SERIAL_NO_CANCELED"];
      if (supplyAdmin) {
        searchCriteria.supplyId = supplyId;
      }
      productSalesManagementService.searchProductSaleEager(searchCriteria).then(res => {
        console.log("searchProductSaleEager", res);
        import('xlsx').then(xlsx => {
          var excelList = [];
          res.forEach(a => {
            let barcodeList = "";
            let serialNoList = "";
            let brandList = "";
            let modelList = "";
            let priceList = "";
            let finalPriceList = "";
            let grossPrice = "";
            let NSFTESFList = "";
            let saleStatusList = "";
            a.productSerialNoList?.forEach(p => {
              barcodeList += p.barcode + ",";
              serialNoList += p.serialNo + ",";
              brandList += p.model?.brand?.name + ",";
              modelList += p.model?.name + ",";
              priceList += addCommas(p.recommendedSellPrice) + "|";
              saleStatusList += p.saleStatusDisplay + ",";
            });
            if (a.productSaleType == "TEMLIK") {
              a.productSerialNoList?.forEach(p => {
                finalPriceList += addCommas(p?.priceWithTolerance * (1 - (a?.discountAmount / (a?.finalPrice + a?.discountAmount)))) + "|";
                grossPrice += p?.distyPrice ? addCommas((p?.priceWithTolerance * (1 - (a?.discountAmount / (a?.finalPrice + a?.discountAmount)))) - p?.distyPrice) + "|" : "";
                NSFTESFList += "%" + addCommasToFixedZero(p?.priceWithTolerance * (1 - (a?.discountAmount / (a?.finalPrice + a?.discountAmount))) / p?.recommendedSellPrice * 100) + "|";
              });
            } else {
              a.productSerialNoList?.forEach(p => {
                finalPriceList += addCommas(p?.selectedPrice) + "|";
                grossPrice += p?.distyPrice ? addCommas(p?.selectedPrice - p?.distyPrice) + "|" : "";
                NSFTESFList += "%" + addCommasToFixedZero(p?.selectedPrice / p?.recommendedSellPrice * 100) + "|";
              });
            }
            barcodeList = barcodeList.substring(0, barcodeList.length - 1);
            serialNoList = serialNoList.substring(0, serialNoList.length - 1);
            brandList = brandList.substring(0, brandList.length - 1);
            modelList = modelList.substring(0, modelList.length - 1);
            priceList = priceList.substring(0, priceList.length - 1);
            saleStatusList = saleStatusList.substring(0, saleStatusList.length - 1);
            finalPriceList = finalPriceList.substring(0, finalPriceList.length - 1);
            NSFTESFList = NSFTESFList.substring(0, NSFTESFList.length - 1);
            if(grossPrice.length > 0){
              grossPrice = grossPrice.substring(0, grossPrice.length - 1);
            }
            let saleDate = new Date(a.completedDateTime);
            if (!supplyAdmin) {
              excelList.push({
                "İşlem Tarihi": new Date(saleDate.getFullYear(), saleDate.getMonth(), saleDate.getDate()),
                "İşlem Saati": a.completedDateTimeDisplay.split(" ")[1],
                "İşlem Tipi": a.productSaleTypeDisplay,
                "İşlem Durum": a.productSaleStatusDisplay,
                "Müşteri Tipi": a.ttCrm?.name,
                "Ana Bayi Kod": a.reseller?.parentReseller?.code,
                "Ana Bayi Ad": a.reseller?.parentReseller?.name,
                "Bayi Kod": a.reseller?.code,
                "Bayi Ad": a.reseller?.name,
                "Bayi Durumu": a.reseller?.resellerActive ? "Aktif" : "Pasif",
                "Bayi Bölge": a.reseller?.area,
                "Bayi Ana Bölge": a.reseller?.mainArea,
                "Distribütör": a.reseller?.disty?.name,
                "İşlemi Yapan": a.sellerUser?.name + " " + a.sellerUser?.surname,
                "İşlem Fiyatı": parseFloat(a.finalPrice),
                "İskonto Tutarı": a.productSaleType == "TEMLIK" ? parseFloat(a?.discountAmount) : null,
                "İskonto Oranı": a.productSaleType == "TEMLIK" ? "%" + addCommasToFixedZero(a?.discountAmount / a?.finalPrice * 100) : null,
                "Müşteri Faturası Tutarı": a?.accessoryCampaign != null ? parseFloat(a?.accessoryCampaign?.termAmount * a?.accessoryCampaign?.termNumber) : null,
                "Peşin/Kredi Kartı ile Ödenen Tutar": a?.accessoryCampaign != null ? parseFloat(a?.finalPrice - a?.accessoryCampaign?.amount) : null,
                "Seçilen Kampanya": a.accessoryCampaign?.description,
                "Kampanya Seri": a.accessoryCampaignSerial?.serialValue,
                "Kampanya Şifre": a.accessoryCampaignPass?.passValue,
                "Müşteri Adı": a.customerName,
                "Müşteri Soyadı": a.customerSurname,
                "Müşteri Telefon Numarası": a.customerPhoneNo,
                "Müşteri Hizmet No": a.customerServiceNo,
                "Müşteri PSTN No": a.customerPstnNo,
                "Aksesuar Barkodları": barcodeList,
                "Aksesuar Seri Numaraları": serialNoList,
                "Aksesuar Markaları": brandList,
                "Aksesuar Modelleri": modelList,
                "Aksesuar Tavsiye Edilen Satış Fiyatları": priceList,
                "Aksesuar Nihai Satış Fiyatı": finalPriceList,
                "Aksesuar NSF / TESF": NSFTESFList,
                "Aksesuar Brüt Kar": grossPrice,
                "Aksesuar İşlem Durumları": saleStatusList,
                "İptal Talebinin Reddedilme Sebebi": a.productSaleStatus == "COMPLETED" ? a.rejectCancelSaleDescription : null
              })
            } else {
              excelList.push({
                "İşlem Tarihi": new Date(saleDate.getFullYear(), saleDate.getMonth(), saleDate.getDate()),
                "İşlem Saati": a.completedDateTimeDisplay.split(" ")[1],
                "İşlem Tipi": a.productSaleTypeDisplay,
                "İşlem Durum": a.productSaleStatusDisplay,
                "Müşteri Tipi": a.ttCrm?.name,
                "Ana Bayi Kod": a.reseller?.parentReseller?.code,
                "Ana Bayi Ad": a.reseller?.parentReseller?.name,
                "Bayi Kod": a.reseller?.code,
                "Bayi Ad": a.reseller?.name,
                "Bayi Durumu": a.reseller?.resellerActive ? "Aktif" : "Pasif",
                "Bayi Bölge": a.reseller?.area,
                "Bayi Ana Bölge": a.reseller?.mainArea,
                "Distribütör": a.reseller?.disty?.name,
                "İşlemi Yapan": a.sellerUser?.name + " " + a.sellerUser?.surname,
                "İşlem Fiyatı": parseFloat(a.finalPrice),
                "İskonto Tutarı": a.productSaleType == "TEMLIK" ? parseFloat(a?.discountAmount) : null,
                "İskonto Oranı": a.productSaleType == "TEMLIK" ? "%" + addCommasToFixedZero(a?.discountAmount / a?.finalPrice * 100) : null,
                "Müşteri Faturası Tutarı": a?.accessoryCampaign != null ? parseFloat(a?.accessoryCampaign?.termAmount * a?.accessoryCampaign?.termNumber) : null,
                "Peşin/Kredi Kartı ile Ödenen Tutar": a?.accessoryCampaign != null ? parseFloat(a?.finalPrice - a?.accessoryCampaign?.amount) : null,
                "Seçilen Kampanya": a.accessoryCampaign?.description,
                "Aksesuar Barkodları": barcodeList,
                "Aksesuar Seri Numaraları": serialNoList,
                "Aksesuar Markaları": brandList,
                "Aksesuar Modelleri": modelList,
                "Aksesuar Tavsiye Edilen Satış Fiyatları": priceList,
                "Aksesuar Nihai Satış Fiyatı": finalPriceList,
                "Aksesuar NSF / TESF": NSFTESFList,
                "Aksesuar İşlem Durumları": saleStatusList,
                "İptal Talebinin Reddedilme Sebebi": a.productSaleStatus == "COMPLETED" ? a.rejectCancelSaleDescription : null
              })
            }
          });
          if (!supplyAdmin) {
            excelList.push({
              "": "***Hesaplanan Brüt Kar* alış ve satış fiyatları arasındaki farklar üzerinden hesaplanmakta olup, distribütörlerin sağladığı ek destek, prim gibi faydalar dahil edilmemiştir."
            })
          }

          const worksheet = xlsx.utils.json_to_sheet(excelList, {header:[""]});
          var wscols = [
            {width: 1},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 60},
            {width: 15},
            {width: 60},
            {width: 15},
            {width: 22},
            {width: 22},
            {width: 15},
            {width: 28},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 20},
            {width: 30},
            {width: 35},
            {width: 18},
            {width: 18},
            {width: 15},
            {width: 15},
            {width: 25},
            {width: 20},
            {width: 30},
            {width: 30},
            {width: 30},
            {width: 30},
            {width: 30},
            {width: 30},
            {width: 30},
            {width: 30},
            {width: 30},
            {width: 30},
            {width: 80}
          ];
          worksheet["!cols"] = wscols;
          const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
          const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
          saveAsExcelFile(excelBuffer, 'İşlemler');
        });
      }).catch(err => {
        toast?.current?.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'İşlem bilgisi getirme başarısız.'
        });
        console.log(err);
      }).finally(() => {
        setExportExcelDisabled(false);
      });
    }
  };

  const exportExcelDetail = () => {
    var dateControl = false;
    if ((serialNo != null && serialNo != "") || (barcode != null && barcode != "") || (pass != null && pass != "") || (serial != null && serial != "") || (serial != null && serial != "")
      || (selectedResellerCode != null && selectedResellerCode != "") || (selectedParentResellerCode != null && selectedParentResellerCode != "")) {
      dateControl = true;
    } else if (selectedTimeBegin != null && selectedTimeEnd != null) {
      if (selectedTimeEnd.getTime() - selectedTimeBegin.getTime() <= 32 * 24 * 3600000) {
        if (selectedTimeBegin.getTime() <= selectedTimeEnd.getTime()) {
          dateControl = true;
        } else {
          toast.current.show({
            severity: 'warn',
            summary: 'Uyarı',
            detail: 'Başlangıç tarihi bitiş tarihinden önce olmalıdır.'
          });
        }
      } else {
        toast.current.show({
          severity: 'warn',
          summary: 'Uyarı',
          detail: 'Excel almak için tarih aralığı bir aydan fazla olmamalıdır.'
        });
      }
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Excel almak için filtreleme yapılmalı veya tarih alanları dolu olmalıdır.'
      });
    }
    if (dateControl) {
      setExportExcelDisabled(true);

      searchCriteria.resellerCode = selectedResellerCode;
      searchCriteria.serialNo = serialNo;
      searchCriteria.barcode = barcode;
      searchCriteria.serial = serial;
      searchCriteria.pass = pass;
      searchCriteria.distyName = distyName;
      searchCriteria.subCategory = subCategory;
      searchCriteria.category = category;

      if (selectedTimeBegin != null) {
        searchCriteria.saleDateStart = selectedTimeBegin.toLocaleDateString("tr-TR") + " " + selectedTimeBegin.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.saleDateStart = null;
      }

      if (selectedTimeEnd != null) {
        searchCriteria.saleDateEnd = selectedTimeEnd.toLocaleDateString("tr-TR") + " " + selectedTimeEnd.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.saleDateEnd = null;
      }

      searchCriteria.productSaleStatusList = ["COMPLETED", "SALE_CANCEL_APPROVAL", "SALE_CANCELED", "SERIAL_NO_CANCEL_APPROVAL", "SERIAL_NO_CANCELED"];
      if (supplyAdmin) {
        searchCriteria.supplyId = supplyId;
      }
      productSalesManagementService.searchProductSaleEager(searchCriteria).then(res => {
        console.log("searchProductSaleEager", res);
        import('xlsx').then(xlsx => {
          var excelList = [];
          res.forEach(a => {
            a.productSerialNoList?.forEach(p => {
                let finalPrice = null;
                let grossPrice = null;
                let NSFTESF = "";
                if (a.productSaleType == "TEMLIK") {
                  finalPrice = parseFloat(p?.priceWithTolerance * (1 - (a?.discountAmount / (a?.finalPrice + a?.discountAmount))));
                  grossPrice = p?.distyPrice ? parseFloat((p?.priceWithTolerance * (1 - (a?.discountAmount / (a?.finalPrice + a?.discountAmount)))) -  p?.distyPrice) : null;
                  NSFTESF += "%" + addCommasToFixedZero(p?.priceWithTolerance * (1 - (a?.discountAmount / (a?.finalPrice + a?.discountAmount))) / p?.recommendedSellPrice * 100);
                } else {
                  finalPrice = parseFloat(p?.selectedPrice);
                  grossPrice =  p?.distyPrice ? parseFloat(p?.selectedPrice - p?.distyPrice) : null;
                  NSFTESF += "%" + addCommasToFixedZero(p?.selectedPrice/ p?.recommendedSellPrice * 100);
                }
                let saleDate = new Date(a.completedDateTime);
                if (!supplyAdmin) {
                  console.log(a)
                  excelList.push({
                    "İşlem Tarihi": new Date(saleDate.getFullYear(), saleDate.getMonth(), saleDate.getDate()),
                    "İşlem Saati": a.completedDateTimeDisplay.split(" ")[1],
                    "İşlem Tipi": a.productSaleTypeDisplay,
                    "İşlem Durum": a.productSaleStatusDisplay,
                    "Müşteri Tipi": a.ttCrm?.name,
                    "Ana Bayi Kod": a.reseller?.parentReseller?.code,
                    "Ana Bayi Ad": a.reseller?.parentReseller?.name,
                    "Bayi Kod": a.reseller?.code,
                    "Bayi Ad": a.reseller?.name,
                    "Bayi Durumu": a.reseller?.resellerActive ? "Aktif" : "Pasif",
                    "Bayi Bölge": a.reseller?.area,
                    "Bayi Ana Bölge": a.reseller?.mainArea,
                    "Distribütör": a.reseller?.disty?.name,
                    "Kategori": p.model?.subCategory?.category?.name,
                    "Alt Kategori": p.model?.subCategory?.name,
                    "İşlemi Yapan": a.sellerUser?.name + " " + a.sellerUser?.surname,
                    "İşlem Fiyatı": parseFloat(a.finalPrice),
                    "İskonto Tutarı": a.productSaleType == "TEMLIK" ? parseFloat(a?.discountAmount) : null,
                    "İskonto Oranı": a.productSaleType == "TEMLIK" ? "%" + addCommasToFixedZero(a?.discountAmount / a?.finalPrice * 100) : null,
                    "Müşteri Faturası Tutarı": a?.accessoryCampaign != null ? parseFloat(a?.accessoryCampaign?.termAmount * a?.accessoryCampaign?.termNumber) : null,
                    "Peşin/Kredi Kartı ile Ödenen Tutar": a?.accessoryCampaign != null ? parseFloat(a?.finalPrice - a?.accessoryCampaign?.amount) : null,
                    "Seçilen Kampanya": a.accessoryCampaign?.description,
                    "Kampanya Seri": a.accessoryCampaignSerial?.serialValue,
                    "Kampanya Şifre": a.accessoryCampaignPass?.passValue,
                    "Müşteri Adı": a.customerName,
                    "Müşteri Soyadı": a.customerSurname,
                    "Müşteri Telefon Numarası": a.customerPhoneNo,
                    "Müşteri Hizmet No": a.customerServiceNo,
                    "Müşteri PSTN No": a.customerPstnNo,
                    "Aksesuar Barkod": p.barcode,
                    "Aksesuar Seri Numara": p.serialNo,
                    "Aksesuar Marka": p.model?.brand?.name,
                    "Aksesuar Model": p.model?.name,
                    "Aksesuar Tavsiye Edilen Satış Fiyat": parseFloat(p.recommendedSellPrice),
                    "Aksesuar Nihai Satış Fiyat": finalPrice,
                    "Aksesuar NSF / TESF": NSFTESF,
                    "Brüt Kar": grossPrice,
                    "Aksesuar İşlem Durum": p.saleStatusDisplay,
                    "İptal Talebinin Reddedilme Sebebi": a.productSaleStatus == "COMPLETED" ? a.rejectCancelSaleDescription : null
                  })
                } else {
                  excelList.push({
                    "İşlem Tarihi": new Date(saleDate.getFullYear(), saleDate.getMonth(), saleDate.getDate()),
                    "İşlem Saati": a.completedDateTimeDisplay.split(" ")[1],
                    "İşlem Tipi": a.productSaleTypeDisplay,
                    "İşlem Durum": a.productSaleStatusDisplay,
                    "Müşteri Tipi": a.ttCrm?.name,
                    "Ana Bayi Kod": a.reseller?.parentReseller?.code,
                    "Ana Bayi Ad": a.reseller?.parentReseller?.name,
                    "Bayi Kod": a.reseller?.code,
                    "Bayi Ad": a.reseller?.name,
                    "Bayi Durumu": a.reseller?.resellerActive ? "Aktif" : "Pasif",
                    "Bayi Bölge": a.reseller?.area,
                    "Bayi Ana Bölge": a.reseller?.mainArea,
                    "Distribütör": a.reseller?.disty?.name,
                    "Kategori": p.model?.subCategory?.category?.name,
                    "Alt Kategori": p.model?.subCategory?.name,
                    "İşlemi Yapan": a.sellerUser?.name + " " + a.sellerUser?.surname,
                    "İşlem Fiyatı": parseFloat(a.finalPrice),
                    "İskonto Tutarı": a.productSaleType == "TEMLIK" ? parseFloat(a?.discountAmount) : null,
                    "İskonto Oranı": a.productSaleType == "TEMLIK" ? "%" + addCommasToFixedZero(a?.discountAmount / a?.finalPrice * 100) : null,
                    "Müşteri Faturası Tutarı": a?.accessoryCampaign != null ? parseFloat(a?.accessoryCampaign?.termAmount * a?.accessoryCampaign?.termNumber) : null,
                    "Peşin/Kredi Kartı ile Ödenen Tutar": a?.accessoryCampaign != null ? parseFloat(a?.finalPrice - a?.accessoryCampaign?.amount) : null,
                    "Seçilen Kampanya": a.accessoryCampaign?.description,
                    "Aksesuar Barkod": p.barcode,
                    "Aksesuar Seri Numara": p.serialNo,
                    "Aksesuar Marka": p.model?.brand?.name,
                    "Aksesuar Model": p.model?.name,
                    "Aksesuar Tavsiye Edilen Satış Fiyat": parseFloat(p.recommendedSellPrice),
                    "Aksesuar Nihai Satış Fiyat": finalPrice,
                    "Aksesuar NSF / TESF": NSFTESF,
                    "Aksesuar İşlem Durum": p.saleStatusDisplay,
                    "İptal Talebinin Reddedilme Sebebi": a.productSaleStatus == "COMPLETED" ? a.rejectCancelSaleDescription : null
                  })
                }
              });
          });
          if (!supplyAdmin) {
            excelList.push({
              "": "***Hesaplanan Brüt Kar* alış ve satış fiyatları arasındaki farklar üzerinden hesaplanmakta olup, distribütörlerin sağladığı ek destek, prim gibi faydalar dahil edilmemiştir."
            })
          }
          const worksheet = xlsx.utils.json_to_sheet(excelList, {header:[""]});
          var wscols = [
            {width: 1},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 60},
            {width: 15},
            {width: 60},
            {width: 15},
            {width: 22},
            {width: 22},
            {width: 15},
            {width: 28},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 20},
            {width: 30},
            {width: 35},
            {width: 18},
            {width: 18},
            {width: 15},
            {width: 15},
            {width: 25},
            {width: 20},
            {width: 30},
            {width: 20},
            {width: 20},
            {width: 20},
            {width: 60},
            {width: 30},
            {width: 20},
            {width: 20},
            {width: 20},
            {width: 20},
            {width: 80}
          ];
          worksheet["!cols"] = wscols;
          const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
          const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
          saveAsExcelFile(excelBuffer, 'İşlemler');
        });
      }).catch(err => {
        toast?.current?.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'İşlem bilgisi getirme başarısız.'
        });
        console.log(err);
      }).finally(() => {
        setExportExcelDisabled(false);
      });
    }
  };

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    searchSaleInfo(pageState);
  };

  const onFilter = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const onSort = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const detailModalShow = (e) => {
    setDetailModal(true);
    saleInfoList.forEach(s => {
      if (s.id == e.currentTarget.id) {
        setSelectedSale(s);
      }
    });
  };

  const detaillModalHide = () => {
    setDetailModal(false);
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const cancelSale = () => {
    if (selectedCancelOption != null) {
      setLoading(true);
      setDetailModal(false);
      setCancelModal(false);
      const request = {
        productSaleId: selectedProductSaleId,
        rejectSaleDescription: selectedCancelOption,
        productSerialNoIdList: selectedProductSerialNoIdList
      };
      productSalesManagementService.cancelTheCompletedSale(request).then(res => {
        console.log("cancelTheCompletedSale", res);
        toast.current.show({
          severity: 'success',
          summary: 'Başarılı İşlem',
          detail: 'TALEBİNİZ DEĞERLENDİRMEYE ALINACAKTIR',
          life: 4000
        });
      }).catch(err => {
        console.log(err);
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: err.response.data.message,
          life: 4000
        });
      }).finally(() => {
        searchSaleInfo(paginationConfig);
      });
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Seçim yapmadan talep gönderemezsiniz.'
      });
    }
  };

  const saleCancelModelShow = () => {
    setCancelDescriptionModal(false);
    setCancelModal(true);
  };

  const serialNoCancelModelShow = (e) => {
    saleInfoList.forEach(s => {
      if (s.productSerialNoList != null) {
        s.productSerialNoList.forEach(p => {
          if (p.id == e.currentTarget.id) {
            setSelectedProductSaleId(s.id);
            let serialNoIdList = [];
            serialNoIdList.push(p.id);
            setSelectedProductSerialNoIdList(serialNoIdList);
          }
        });
      }
    });
    setCancelModalHeader("Seri No İptal Etme");
    setCancelModalMessage("Seri no iptal etmek istediğinizden emin misiniz? Seri no iptal için onaya gönderilecektir.");
    setCancelModal(true);
  };

  const cancelModalHide = () => {
    setSelectedProductSaleId(null);
    setSelectedProductSerialNoIdList(null);
    setCancelModal(false);
  };

  const cancelModalDescriptionShow = (e) => {
    saleInfoList.forEach(s => {
      if (s.id == e.currentTarget.id) {
        setSelectedProductSaleId(s.id);
        let serialNoIdList = [];
        s.productSerialNoList.forEach(p => {
          serialNoIdList.push(p.id);
        });
        setSelectedProductSerialNoIdList(serialNoIdList);
      }
    });
    setSelectedCancelOption(null);
    setCancelModalHeader("İşlem İptal Etme");
    setCancelModalMessage("Lütfen satışın durumunu seçiniz:");
    setCancelDescriptionModal(true);
  };

  const cancelModalDescriptionHide = () => {
    setCancelDescriptionModal(false);
  };

  const parentResellerCodeFilterFilter = <InputText value={selectedParentResellerCode}
                                                    onChange={(e) => setSelectedParentResellerCode(e.target.value)}
                                                    disabled={resellerUser} className="p-column-filter"/>;

  const resellerCodeFilter = <InputText value={selectedResellerCode}
                                        onChange={(e) => setSelectedResellerCode(e.target.value)}
                                        disabled={resellerUser && resellerType != "TTM" && resellerType != "EDM"} className="p-column-filter"/>;

  const addCommas = num => num?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const addCommasToFixedZero = num => num?.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title" style={{fontSize: '10pt'}}>{title}</div>
        {content}
      </div>
    );
  };

  const responsiveColumnRight = (title, content) => {
    return (
      <div>
        <span className="p-column-title">{title}</span>
        <span style={{float: 'right'}}>{content}</span>
      </div>
    );
  };

  const saleDateTemplate = (data) => {
    return responsiveColumn("İşlem Tarihi", data?.completedDateTimeDisplay)
  };

  const productSaleStatusDisplayTemplate = (data) => {
    return responsiveColumn("İşlem Durum", data?.productSaleStatusDisplay)
  };

  const productSaleTypeDisplayTemplate = (data) => {
    return responsiveColumn("İşlem Tipi", data?.productSaleTypeDisplay)
  };

  const ttCrmTemplate = (data) => {
    return responsiveColumn("Müşteri Tipi", data?.ttCrm?.name)
  };

  const parentResellerCodeTemplate = (data) => {
    return responsiveColumn("Ana Kod", data?.reseller?.parentReseller?.code + " - " + data?.reseller?.parentReseller?.name)
  };

  const resellerCodeTemplate = (data) => {
    return responsiveColumn("Kod", data?.reseller?.code + " - " + data?.reseller?.name)
  };

  const nameTemplate = (data) => {
    return responsiveColumn("İşlemi Yapan", data?.sellerUser?.name + " " + data?.sellerUser?.surname)
  };

  const vatIncludedPriceTemplate = (data) => {
    return responsiveColumnRight("İşlem Fiyatı", addCommas(data?.finalPrice))
  };

  const accessoryCampaignSerialTitleButtonTemplate = (data) => {
    return (
      <div className="p-d-flex p-justify-even">
        <Button id={data.id} type="button" className="p-button-sm p-button-info" label="Detay"
                onClick={detailModalShow}/>
        {!supplyAdmin && !distyAdmin &&
        <Button id={data.id} type="button" className="p-button-sm p-button-danger" label="İptal"
                disabled={data.productSaleStatus === "SALE_CANCEL_APPROVAL" || data.productSaleStatus === "SALE_CANCELED"}
                onClick={cancelModalDescriptionShow}/>}
      </div>
    );
  };

  const cancelButtonTemplate = (data) => {
    return (
      <div className="p-d-flex p-justify-even">
        <Button id={data.id} type="button" className="p-button-sm p-button-danger" label="İptal"
                disabled={data.saleStatus === "CANCEL_APPROVAL" || data.saleStatus === "CANCELED"}
                onClick={serialNoCancelModelShow}/>
      </div>
    );
  };

  const barcodeTemplate = (data) => {
    return responsiveColumn("Barkod", data?.barcode)
  };

  const serialNoTemplate = (data) => {
    return responsiveColumn("Seri Numara", data?.serialNo)
  };

  const brandTemplate = (data) => {
    return responsiveColumn("Marka", data?.model?.brand?.name)
  };

  const modelTemplate = (data) => {
    return responsiveColumn("Model", data?.model?.name)
  };

  const saleStatusDisplayTemplate = (data) => {
    return responsiveColumn("İşlem Durumu", data?.saleStatusDisplay)
  };

  const priceTemplate = (data) => {
    return responsiveColumnRight("Tavsiye Edilen Satış Fiyatı", addCommas(data?.recommendedSellPrice))
  };

  const finalPriceTemplateTemplate = (data) => {
    return responsiveColumnRight("Nihai Satış Fiyatı", addCommas(data?.priceWithTolerance * (1 - (selectedSale?.discountAmount / (selectedSale?.finalPrice + selectedSale?.discountAmount)))))
  };

  const NSFTESFTemplateTemplate = (data) => {
    return responsiveColumnRight("NSF / TESF", "%" + addCommasToFixedZero(data?.priceWithTolerance * (1 - (selectedSale?.discountAmount / (selectedSale?.finalPrice + selectedSale?.discountAmount))) / data?.recommendedSellPrice * 100))
  };

  const grossPriceTemplateTemplate = (data) => {
    return responsiveColumnRight("Brüt Kar", data?.distyPrice ? addCommas((data?.priceWithTolerance * (1 - (selectedSale?.discountAmount / (selectedSale?.finalPrice + selectedSale?.discountAmount)))) - data?.distyPrice): null)
  };

  const vanillaFinalPriceTemplateTemplate = (data) => {
    return responsiveColumnRight("Nihai Satış Fiyatı", addCommas(data?.selectedPrice))
  };

  const vanillaNSFTESFTemplateTemplate = (data) => {
    return responsiveColumnRight("NSF / TESF", "%" + addCommasToFixedZero(data?.selectedPrice / data?.recommendedSellPrice * 100))
  };

  const vanillaGrossPriceTemplateTemplate = (data) => {
    return responsiveColumnRight("Brüt Kar", data?.distyPrice ? addCommas(data?.selectedPrice - data?.distyPrice): null)
  };

  const header =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>İşlemler</div>
        <div>
          <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
                  disabled={exportExcelDisabled}
                  onClick={exportExcel} style={{marginRight: 5}}/>
          <Button type="button" icon="pi pi-external-link" label="Detayları Excel’e Aktar"
                  className="p-button p-button-secondary p-button-sm"
                  disabled={exportExcelDisabled}
                  onClick={exportExcelDetail}/>
        </div>
      </div>
    </div>;

  const renderSaleTypeFilter = () => {
    return (
      <Dropdown value={saleType} options={saleTypeOptions} onChange={(e) => setSaleType(e.value)} optionLabel="name"
                showClear className="p-column-filter"/>
    );
  };

  const renderSaleStatusFilter = () => {
    return (
      <Dropdown value={saleStatus} options={saleStatusOptions} onChange={(e) => setSaleStatus(e.value)}
                optionLabel="name" showClear className="p-column-filter"/>
    );
  };

  const saleTypeFilterElement = renderSaleTypeFilter();
  const saleStatusFilterElement = renderSaleStatusFilter();

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-12 p-md-3 p-fluid">
            <label htmlFor="baslangic">İşlem Tarihi Aralığı</label>
            <Calendar id="baslangic" showIcon dateFormat={'dd.mm.yy'} style={{marginTop: 5}}
                      value={selectedTimeBegin} showTime showButtonBar
                      onChange={(e) => setSelectedTimeBegin(e.value)}
                      placeholder="İşlem Tarihi Başlangıç"/>
            <Calendar id="bitis" showIcon dateFormat={'dd.mm.yy'} style={{marginTop: 5}}
                      value={selectedTimeEnd} showTime showButtonBar
                      onChange={(e) => setSelectedTimeEnd(e.value)}
                      placeholder="İşlem Tarihi Bitiş"/>
          </div>
          <div className="p-col-12 p-md-3 p-fluid">Aksesuar Ürün Seri No Ve Barkod
            <InputText style={{marginTop: 5}} value={serialNo} placeholder="Seri No"
                       onChange={(e) => setSerialNo(e.target.value)}/>
            <InputText style={{marginTop: 5}} value={barcode} placeholder="Barkod"
                       onChange={(e) => setBarcode(e.target.value)}/>
          </div>
          {!supplyAdmin &&
          <div className="p-col-12 p-md-3 p-fluid">Kampanya Seri Ve Şifre
            <InputText style={{marginTop: 5}} value={serial} placeholder="Seri"
                       onChange={(e) => setSerial(e.target.value)}/>
            <InputText style={{marginTop: 5}} value={pass} placeholder="Şifre"
                       onChange={(e) => setPass(e.target.value)}/>
          </div>}
          <div className="p-col-12 p-md-3 p-fluid">Distribütör
            <InputText style={{marginTop: 5}} value={distyName} placeholder="Distribütör"
                       disabled={distyAdmin}
                       onChange={(e) => setDistyName(e.target.value)}/>
          </div>
          <div className="p-col-12">
            <ResponsiveDataTable
              lazy
              header={header}
              value={saleInfoList}
              paginationConfig={paginationConfig}
              onActivePageChange={onActivePageChange}
              onFilter={onFilter}
              filters={searchCriteria.filters}
              onSort={onSort}
              sortField={searchCriteria.sortField}
              sortOrder={searchCriteria.sortOrder}
              loading={loading}
              emptyMessage="İşlem bulunamadı"
              style={{fontSize: '11px'}}
            >
              <Column field="completedDateTime" body={saleDateTemplate} header="İşlem Tarihi" sortable/>
              <Column field="productSaleType" body={productSaleTypeDisplayTemplate} header="İşlem Tipi" filter
                      filterElement={saleTypeFilterElement}/>
              <Column field="productSaleStatus" body={productSaleStatusDisplayTemplate} header="İşlem Durum" filter
                      filterElement={saleStatusFilterElement}/>
              <Column field="ttCrmName" body={ttCrmTemplate} header="Müşteri Tipi" filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="parentResellerCode" body={parentResellerCodeTemplate} header="Ana Kod" filter={true}
                      headerStyle={{width: "18%"}}
                      filterElement={parentResellerCodeFilterFilter} filterMatchMode={"contains"}/>
              <Column field="resellerCode" body={resellerCodeTemplate} header="Kod" filter={true}
                      headerStyle={{width: "18%"}}
                      filterElement={resellerCodeFilter} filterMatchMode={"contains"}/>
              <Column field="sellerName" body={nameTemplate} header="İşlemi Yapan" filter={true}
                      headerStyle={{width: "14%"}} filterMatchMode={"contains"}/>
              <Column field="finalPrice" body={vatIncludedPriceTemplate} header="İşlem Fiyatı"
                      headerStyle={{textAlign: 'right'}} sortable/>
              <Column body={accessoryCampaignSerialTitleButtonTemplate}
                      headerStyle={{width: "11rem", textAlign: "center"}}
                      bodyStyle={{textAlign: 'center', overflow: 'visible', justifyContent: 'center'}}/>
            </ResponsiveDataTable>
          </div>
        </div>
        <Dialog header="İşlem Detay" visible={detailModal} modal style={{width: '1000px'}}
                onHide={detaillModalHide}>
          <div className="datatable-responsive-demo">
            <div className="p-grid">
              <div className="p-field p-col-12">
                <p className="marginParagraph"><b>İşlem Tarihi:</b> {selectedSale?.completedDateTimeDisplay}</p>
                <p className="marginParagraph"><b>İşlem Tipi:</b> {selectedSale?.productSaleTypeDisplay}</p>
                <p className="marginParagraph"><b>İşlem Durumu:</b> {selectedSale?.productSaleStatusDisplay}</p>
                {selectedSale?.ttCrm != null &&
                <p className="marginParagraph"><b>Müşteri Tipi:</b> {selectedSale?.ttCrm?.name}</p>}
                <p className="marginParagraph"><b>Ana
                  Kod:</b> {selectedSale?.reseller?.parentReseller?.code} - {selectedSale?.reseller?.parentReseller?.name}
                </p>
                <p className="marginParagraph">
                  <b>Kod:</b> {selectedSale?.reseller?.code} - {selectedSale?.reseller?.name}</p>
                <p className="marginParagraph"><b>İşlemi Yapan Ad:</b> {selectedSale?.sellerUser?.name}</p>
                <p className="marginParagraph"><b>İşlemi Yapan Soyad:</b> {selectedSale?.sellerUser?.surname}</p>
                <p className="marginParagraph"><b>İşlem Fiyatı:</b> {addCommas(selectedSale?.finalPrice)}</p>
                {(selectedSale?.discountAmount != null && selectedSale?.productSaleType == "TEMLIK") &&
                <p className="marginParagraph"><b>İskonto Tutarı:</b> {addCommas(selectedSale?.discountAmount)}</p>}
                {(selectedSale?.discountAmount != null && selectedSale?.productSaleType == "TEMLIK") &&
                <p className="marginParagraph"><b>İskonto
                  Oranı:</b> {"%" + addCommasToFixedZero(selectedSale?.discountAmount / selectedSale?.finalPrice * 100)}</p>}
                {selectedSale?.accessoryCampaign &&
                <p className="marginParagraph"><b>Müşteri Faturası
                  Tutarı:</b> {addCommas(selectedSale?.accessoryCampaign?.termAmount * selectedSale?.accessoryCampaign?.termNumber)}
                </p>}
                {selectedSale?.accessoryCampaign &&
                <p className="marginParagraph"><b>Peşin/Kredi Kartı ile Ödenen
                  Tutar:</b> {addCommas(selectedSale?.finalPrice - selectedSale?.accessoryCampaign?.amount)}</p>}
                {selectedSale?.accessoryCampaign &&
                <p className="marginParagraph"><b>Seçilen Kampanya:</b> {selectedSale?.accessoryCampaign?.description}
                </p>}
                {!supplyAdmin && selectedSale?.accessoryCampaignSerial &&
                <p className="marginParagraph"><b>Kampanya
                  Seri:</b> {selectedSale?.accessoryCampaignSerial?.serialValue}
                </p>}
                {!supplyAdmin && selectedSale?.accessoryCampaignPass &&
                <p className="marginParagraph"><b>Kampanya Şifre:</b> {selectedSale?.accessoryCampaignPass?.passValue}
                </p>}
                {!supplyAdmin && selectedSale?.customerName &&
                <p className="marginParagraph"><b>Müşteri Adı:</b> {selectedSale?.customerName}</p>}
                {!supplyAdmin && selectedSale?.customerSurname &&
                <p className="marginParagraph"><b>Müşteri Soyadı:</b> {selectedSale?.customerSurname}</p>}
                {!supplyAdmin && selectedSale?.customerPhoneNo &&
                <p className="marginParagraph"><b>Müşteri Telefon Numarası:</b> {selectedSale?.customerPhoneNo}</p>}
                {!supplyAdmin && selectedSale?.customerServiceNo &&
                <p className="marginParagraph"><b>Müşteri Hizmet No:</b> {selectedSale?.customerServiceNo}</p>}
                {!supplyAdmin && selectedSale?.customerPstnNo &&
                <p className="marginParagraph"><b>Müşteri PSTN No:</b> {selectedSale?.customerPstnNo}</p>}
                {selectedSale?.scoreTransactionAmount != null &&
                <p className="marginParagraph"><b>Kazanılan Puan:</b> {addCommas(selectedSale?.scoreTransactionAmount)}
                </p>}
                {selectedSale?.rejectCancelSaleDescription &&
                <p className="marginParagraph"><b>İptal Talebinin Reddedilme
                  Sebebi:</b> {selectedSale?.rejectCancelSaleDescription}</p>}
                <p className="marginParagraph"><b>***Hesaplanan Brüt Kar* alış ve satış fiyatları arasındaki farklar üzerinden hesaplanmakta olup, distribütörlerin sağladığı ek destek, prim gibi faydalar dahil edilmemiştir.</b></p>
                <ResponsiveDataTable value={selectedSale?.productSerialNoList}
                                     header="Aksesuar Bigileri"
                                     style={{fontSize: '12px', marginTop: 10}}
                                     paginator rows={5} rowsPerPageOptions={[5, 10, 15]}
                                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                     currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} arası gösteriliyor">
                  <Column body={barcodeTemplate} header="Barkod"/>
                  <Column body={serialNoTemplate} header="Seri Numara" headerStyle={{width: "11rem"}}/>
                  <Column body={brandTemplate} header="Marka"/>
                  <Column body={modelTemplate} header="Model"/>
                  <Column body={saleStatusDisplayTemplate} header="İşlem Durumu"/>
                  <Column body={priceTemplate} header="Tavsiye Edilen Satış Fiyatı" headerStyle={{textAlign: 'right'}}/>
                  {selectedSale?.productSaleType == "TEMLIK" &&
                  <Column body={finalPriceTemplateTemplate} header="Nihai Satış Fiyatı"
                          headerStyle={{textAlign: 'right'}}/>}
                  {selectedSale?.productSaleType == "TEMLIK" &&
                  <Column body={NSFTESFTemplateTemplate} header="NSF / TESF" headerStyle={{textAlign: 'right'}}/>}
                  {selectedSale?.productSaleType == "TEMLIK" && !supplyAdmin &&
                  <Column body={grossPriceTemplateTemplate} header="Brüt Kar"
                          headerStyle={{textAlign: 'right'}}/>}
                  {selectedSale?.productSaleType == "VANILLA" &&
                  <Column body={vanillaFinalPriceTemplateTemplate} header="Nihai Satış Fiyatı"
                          headerStyle={{textAlign: 'right'}}/>}
                  {selectedSale?.productSaleType == "VANILLA" &&
                  <Column body={vanillaNSFTESFTemplateTemplate} header="NSF / TESF" headerStyle={{textAlign: 'right'}}/>}
                  {selectedSale?.productSaleType == "VANILLA" && !supplyAdmin &&
                  <Column body={vanillaGrossPriceTemplateTemplate} header="Brüt Kar"
                          headerStyle={{textAlign: 'right'}}/>}
                  {/*<Column body={cancelButtonTemplate}
                          headerStyle={{width: "6rem", textAlign: "center"}}
                          bodyStyle={{textAlign: 'center', overflow: 'visible', justifyContent: 'center'}}/>*/}
                </ResponsiveDataTable>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog header={cancelModalHeader} visible={cancelModal} modal style={{width: '420px'}}
                contentStyle={{paddingLeft: 16, paddingRight: 16}}
                onHide={cancelModalHide}>
          <p style={{fontSize: 17, marginBottom: 10}}>{cancelModalMessage}</p>
          <Dropdown value={selectedCancelOption} options={cancelOptions} style={{marginBottom: 10}}
                    onChange={(e) => setSelectedCancelOption(e.value)} placeholder={"Seçim yapınız"}
                    className="p-column-filter"/>
          <Button label="TALEBİ GÖNDER" onClick={cancelSale} autoFocus/>
        </Dialog>
        <Dialog header={cancelModalHeader} visible={cancelDescriptionModal} modal style={{width: '420px'}}
                contentStyle={{paddingLeft: 16, paddingRight: 16}}
                onHide={cancelModalDescriptionHide}>
          <p style={{fontSize: 17}}>Lütfen Dikkat! Bu talebi iki durum için gerçekleştirebilirsiniz:</p>
          <ol style={{fontSize: 17}}>
            <li>TT sistemlerinde satış gerçekleşmediyse</li>
            <li>TT sistemlerinde satış iptal edildiyse</li>
          </ol>
          <Button type="button" label="TAMAM" style={{width: 100}}
                  className="p-button"
                  onClick={saleCancelModelShow}/>
        </Dialog>
      </div>
    </>
  )

};

export default ProductSaleList;
