import React, { useState, useEffect, useRef } from "react";
import { Fieldset } from "primereact/fieldset";
import { Editor } from "primereact/editor";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import * as services from "./httpService";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

const DefineMailSmsTemplate = () => {
  const toast = useRef();
  const [smsBody, setSmsBody] = useState({
    body: "",
    description: "",
    type: "",
    bodyPlaceHolders: [],
  });
  const [mailBody, setMailBody] = useState({
    description: "",
    subject: "",
    type: "",
    subjectPlaceHolders: [],
  });
  const [mailContent, setMailContent] = useState("");
  const [smsType, setSmsType] = useState([]);
  const [mailType, setMailType] = useState([]);

  const getSmsMailType = () => {
    services
      .getAllSmsTemplate()
      .then((res) => {
        let data = [];
        data.push(
          ...res.map((item) => {
            return {
              label: item.type,
              value: item.type,
            };
          })
        );
        setSmsType(data);
      })
      .catch((err) => console.log(err));
    services
      .getAllMailTemplate()
      .then((res) => {
        setMailContent(res.body);
        let data = [];
        data.push(
          ...res.map((item) => {
            return {
              label: item.type,
              value: item.type,
            };
          })
        );
        setMailType(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getSmsMailType();
  }, []);

  useEffect(() => {
    if (smsBody.type) {
      services
        .getTemplateBySmsType(smsBody.type)
        .then((res) => {
          setSmsBody({
            ...smsBody,
            type: res.type,
            description: res.description,
            body: res.body,
            bodyPlaceHolders: res.bodyPlaceHolders,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [smsBody.type]);

  useEffect(() => {
    if (mailBody.type) {
      services
        .getTemplateByMailType(mailBody.type)
        .then((res) => {
          setMailContent(res.body);
          setMailBody({
            ...mailBody,
            type: res.type,
            description: res.description,
            bodyPlaceHolders: res.bodyPlaceHolders,
            subject: res.subject,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [mailBody.type]);

  const saveSmsTemplate = () => {
    let body = {
      body: smsBody.body,
      description: smsBody.description,
      type: smsBody.type,
    };
    services
      .updateSmsTemplate(body)
      .then((res) => {
        setSmsBody({ ...smsBody, ...res });
        toast.current?.show({
          severity: "success",
          summary: "Başarılı",
          detail: "SMS Şablonu Güncellendi",
          life: 2000,
        });
      })
      .catch((err) => {
        toast.current?.show({
          severity: "error",
          summary: "Hata",
          detail: "Beklenmedik bir hata oluştu",
          life: 3000,
        });
      });
  };

  const saveMailTemplate = () => {
    let body = {
      body: mailContent,
      description: mailBody.description,
      isHtml: true,
      subject: mailBody.subject,
      type: mailBody.type,
    };
    services
      .updateMailTemplate(body)
      .then((res) => {
        setMailBody({ ...smsBody, ...res });
        toast.current?.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Mail Şablonu Güncellendi",
          life: 2000,
        });
      })
      .catch((err) => {
        toast.current?.show({
          severity: "error",
          summary: "Hata",
          detail: "Beklenmedik bir hata oluştu",
          life: 3000,
        });
      });
  };

  const onSMSItemChange = (e, name) => {
    const val = (e && e.target ? e.target.value : e) || "";
    let _smsBody = { ...smsBody };
    if (val !== "") {
      _smsBody[`${name}`] = val;
    } else {
      _smsBody[`${name}`] = "";
    }
    setSmsBody(_smsBody);
  };
  const onSMSBodyChange = (e, name) => {
    const val = (e && e.target ? e.target.value : e) || "";
    let _smsBody = { ...smsBody };
    if (val !== "") {
      _smsBody[`${name}`] = val;
    } else {
      _smsBody[`${name}`] = "";
    }
    setSmsBody(_smsBody);
  };

  const onMailBodyChange = (e, name) => {
    const val = (e && e.target ? e.target.value : e) || "";
    let _mailBody = { ...mailBody };
    if (val !== "") {
      _mailBody[`${name}`] = val;
    } else {
      _mailBody[`${name}`] = "";
    }
    setMailBody(_mailBody);
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="card">
        <Fieldset className="p-text-bold" legend="SMS Tanımlama" toggleable>
          <div className="p-field p-d-flex p-flex-column p-col-12 p-md-4 p-p-0">
            <div className="p-fluid p-formgrid p-grid">
              <div className="p-field p-col-12 p-md-6">
                <label htmlFor="customerName">SMS Türü</label>
                <Dropdown
                  value={smsBody.type}
                  options={smsType}
                  onChange={(e) => onSMSItemChange(e, "type")}
                  virtualScrollerOptions={{ itemSize: 31 }}
                  placeholder="Sms Türü Seçiniz"
                />
              </div>
            </div>
            <label htmlFor="description">Açıklama</label>
            <InputTextarea
              id="description"
              autoResize
              className="p-d-block"
              value={smsBody.description}
              onChange={(e) => onSMSBodyChange(e, "description")}
              rows={4}
              cols={20}
            />
            <div className="p-field p-d-flex p-flex-column p-mt-3  p-p-0">
              <label htmlFor="sms">SMS</label>
              <InputTextarea
                id="sms"
                autoResize
                className="p-d-block"
                value={smsBody.body}
                onChange={(e) => onSMSBodyChange(e, "body")}
                rows={4}
                cols={20}
              />
            </div>
          </div>
          <div className="p-grid  p-jc-start p-mt-2">
            <div className="p-field p-col-12 p-md-2">
              <Button
                label="Kaydet"
                onClick={saveSmsTemplate}
                className="p-d-block"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </Fieldset>
        <Fieldset
          className="p-mt-4 p-text-bold"
          legend="Mail Tanımlama"
          toggleable
        >
          <div className="p-field p-d-flex p-flex-column p-col-12 p-md-4 p-p-0">
            <div className="p-fluid p-formgrid p-grid">
              <div className="p-field p-col-12 p-md-6">
                <label htmlFor="customerName">Mail Türü</label>
                <Dropdown
                  value={mailBody.type}
                  options={mailType}
                  onChange={(e) => onMailBodyChange(e, "type")}
                  virtualScrollerOptions={{ itemSize: 31 }}
                  placeholder="Mail Türü Seçiniz"
                />
              </div>
            </div>

            <label htmlFor="description">Açıklama</label>
            <InputTextarea
              id="description"
              autoResize
              className="p-d-block"
              value={mailBody.description}
              onChange={(e) => onMailBodyChange(e, "description")}
              rows={4}
              cols={20}
            />
          </div>
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-4">
              <label htmlFor="subject">Konu</label>
              <InputText
                id="subject"
                type="text"
                placeholder="Konu Giriniz"
                value={mailBody.subject}
                onChange={(e) => onMailBodyChange(e, "subject")}
              />
            </div>
          </div>
          <Editor
            style={{ height: "360px" }}
            value={mailContent}
            onTextChange={(e) => setMailContent(e?.htmlValue)}
          />
          <div className="p-grid  p-justify-start p-mt-2">
            <div className="p-field p-col-12 p-md-2">
              <Button
                label="Kaydet"
                onClick={saveMailTemplate}
                className="p-d-block"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </Fieldset>
      </div>
    </div>
  );
};

export default DefineMailSmsTemplate;
