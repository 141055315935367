import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import {handleError} from "../../service/HandleErrorService";
import VoucherService from "./httpService";
import {Button} from "primereact/button";
import {useHistory} from "react-router";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputNumber} from "primereact/inputnumber";
import {Checkbox} from "primereact/checkbox";
import {FileUpload} from "primereact/fileupload";
import {ProgressSpinner} from "primereact/progressspinner";

const AccessoryCampaignSerialTitle = () => {

  const history = useHistory();
  const toast = useRef(null);
  const voucherService = new VoucherService();

  const [pageHeader, setPageHeader] = useState(null);
  const [accessoryCampaignList, setAccessoryCampaignList] = useState(null);
  const [totalSerialNumber, setTotalSerialNumber] = useState(0);
  const [createSerialNumber, setCreateSerialNumber] = useState(0);
  const [createSerialButtonDisabled, setCreateSerialButtonDisabled] = useState(false);
  const [accessoryCampaignSerialTitleId, setAccessoryCampaignSerialTitleId] = useState(null);
  const [accessoryCampaignSerialTitle, setAccessoryCampaignSerialTitle] = useState(null);
  const [accessoryCampaignSerialTitleLoading, setAccessoryCampaignSerialTitleLoading] = useState(false);
  const [activateSerialButtonDisabled, setActivateSerialButtonDisabled] = useState(false);
  const [excelSection, setExcelSection] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const [createLoading, setCreateLoading] = useState(false)
  const [originalRows, setoriginalRows] = useState({});
  const dt = useRef(null);

  const exampleResellerDistribution = [
    {
      "Bayi Kodu": "bayiKodu",
      "Yüzde %": "1,00"
    }
  ];

  useEffect(() => {
    var localStorageId = JSON.parse(window.atob(localStorage.getItem("accessoryCampaignSerialTitleId")));
    setAccessoryCampaignSerialTitleId(localStorageId);
  }, []);

  useEffect(() => {
    if (accessoryCampaignSerialTitleId != null) {
      setPageHeader("Seri Numara İşlemleri");
      getAccessoryCampaignSerialTitleDetail(accessoryCampaignSerialTitleId);
    } else {
      setPageHeader("Seri Numara Oluştur");
      getAllAccessoryCampaignList();
    }
  }, [accessoryCampaignSerialTitleId]);

  useEffect(() => {
    if (accessoryCampaignSerialTitle != null) {
      setExcelData(JSON.parse(accessoryCampaignSerialTitle.params));
      setCreateSerialNumber(accessoryCampaignSerialTitle.totalSerialNumberCount);
    }
  }, [accessoryCampaignSerialTitle]);

  const getAllAccessoryCampaignList = () => {
    voucherService.accessoryCampaignGetAllWithPassive().then(res => {
      console.log("accessoryCampaignGetAllWithPassive", res);
      res.forEach(r => {
        r.unit = 0;
      });
      setAccessoryCampaignList(res);
    }).catch(err => handleError(err, toast))
  };

  const getAccessoryCampaignSerialTitleDetail = (id) => {
    setAccessoryCampaignSerialTitleLoading(true);
    voucherService.getSerialTitleById(id).then(res => {
      console.log("getSerialTitleById", res);
      setAccessoryCampaignSerialTitle(res);
    }).catch(err => handleError(err, toast)).finally(() => {
      setAccessoryCampaignSerialTitleLoading(false)
    });
  };

  const excelSectionOpen = () => {
    if (totalSerialNumber > 0) {
      setExcelSection(true);
    } else {
      toast?.current?.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Oluşturulcak seri numara adedi sıfırdan büyük olmalıdır.'
      });
      setCreateSerialButtonDisabled(false);
    }
  };

  const createSerial = () => {
    setCreateLoading(true);
    setCreateSerialButtonDisabled(true);
    let serialNumbers = [];
    excelData.forEach(e => {
      e.sayilar.forEach(s => {
        serialNumbers.push({resellerCode: e.bayiKodu, accessoryCampaignId: s.id, unit: s.unit});
      });
    });
    const request = {
      accessoryCampaignParams: JSON.stringify(excelData),
      accessoryCampaignNumbers: serialNumbers
    };
    voucherService.createSerial(request).then(res => {
      console.log("createSerial", res);
      toast?.current?.show({
        severity: 'success',
        summary: 'Başarılı İşlem',
        detail: 'Seri numaralar oluşturuldu.'
      });
      setAccessoryCampaignSerialTitleId(res.id);
      setCreateLoading(false);
      localStorage.setItem("accessoryCampaignSerialTitleId", window.btoa(res.id));
    }).catch(err => {
      console.log(err);
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: err.response.data.message,
        life: 4000
      });
      setCreateSerialButtonDisabled(false);
      setCreateLoading(false);
    });

  };

  const activateSerial = () => {
    setActivateSerialButtonDisabled(true);
    const request = {
      id: accessoryCampaignSerialTitleId
    };
    voucherService.serialTitleActivate(request).then(res => {
      console.log("serialTitleActivate", res);
      toast?.current?.show({
        severity: 'success',
        summary: 'Başarılı İşlem',
        detail: 'Seri numaralar aktifleştirildi.'
      });
      setTimeout(function () {
        goBack();
      }, 2000);
    }).catch(err => {
      console.log(err);
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Seri numaralar aktifleştirilmedi.'
      });
      setActivateSerialButtonDisabled(false);
    })
  };

  const cancelSerial = () => {
    setActivateSerialButtonDisabled(true);
    const request = {
      id: accessoryCampaignSerialTitleId
    };
    voucherService.serialTitleDelete(request).then(res => {
      console.log("serialTitleDelete", res);
      toast?.current?.show({
        severity: 'success',
        summary: 'Başarılı İşlem',
        detail: 'Seri numaralar iptal edildi.'
      });
      setTimeout(function () {
        goBack();
      }, 2000);
    }).catch(err => {
      console.log(err);
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Seri numaralar iptal edilemedi.'
      });
      setActivateSerialButtonDisabled(false);
    })
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const exportXLSX = () => {
    import('xlsx').then(xlsx => {
      var excelList = [];
      accessoryCampaignSerialTitle.accessoryCampaignSerialList.forEach(a => {
        excelList.push({
          "Distribütör": a.reseller.disty.name,
          "Bayi Kodu": a.reseller.code,
          "Malzeme Kodu": a.accessoryCampaign.voucherCode,
          "Payflex Seri Numara": a.serialValue
        })
      });
      const worksheet = xlsx.utils.json_to_sheet(excelList);
      var wscols = [
        {width: 15},
        {width: 15},
        {width: 15},
        {width: 18}
      ];
      worksheet["!cols"] = wscols;
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      saveAsExcelFile(excelBuffer, 'Seri_Numaralar');
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const exportExcel = (e) => {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(exampleResellerDistribution);
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      exportExcelFile(excelBuffer, 'Bayi Dağıtım Anahtar');
    });
  };

  const exportExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const importExcel = ({files}) => {
    if (files.length === 1) {
      const file = files[0];
      files.length = 0;

      import('xlsx').then(xlsx => {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const wb = xlsx.read(e.target.result, {type: 'array'});
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = xlsx.utils.sheet_to_json(ws, {header: 1});

          // Prepare DataTable
          const cols = data[0];
          data.shift();

          let _importedData = data.map(d => {
            return cols.reduce((obj, c, i) => {
              obj[c] = d[i];
              return obj;
            }, {});
          });

          let resellerDistribution = (_importedData.map(value => {
            if (value['Bayi Kodu'] !== undefined && value['Bayi Kodu'] !== null) {
              value['Bayi Kodu'] = value['Bayi Kodu'].toString()
            }
            if (value['Yüzde %'] !== undefined && value['Yüzde %'] !== null) {
              value['Yüzde %'] = parseFloat(value['Yüzde %'])
            }
            return {
              ...value
            }
          }));

          let _resellerDistribution = [];
          let totalRatio = 0;
          let totalCreate = 0;
          resellerDistribution.forEach(value => {
            if (value['Bayi Kodu'] != null && value['Yüzde %'] != null) {
              totalRatio += value['Yüzde %'];
              let totalSerialNumber = 0;
              let serialNumbers = [];
              accessoryCampaignList.forEach(a => {
                let count = parseInt(a.unit / 100 * value['Yüzde %'], 10);
                if (count > 0) {
                  totalSerialNumber += count;
                  totalCreate += count;
                  serialNumbers.push({id: a.id, amount: a.amount, unit: count});
                }
              });
              _resellerDistribution.push({
                bayiKodu: value['Bayi Kodu'],
                yuzde: value['Yüzde %'],
                toplamSayi: totalSerialNumber,
                sayilar: serialNumbers
              });
            }
          });
          if (_resellerDistribution.length > 0) {
            if (totalRatio <= 100.0001) {
              toast?.current?.show({
                severity: 'success',
                summary: 'Başarılı İşlem',
                detail: 'Bayi Dağıtım Anahtar Excel`i Yüklendi.'
              });
              setCreateSerialNumber(totalCreate);
              setExcelData(_resellerDistribution);
            } else {
              toast?.current?.show({
                severity: 'error',
                summary: 'Hata',
                detail: 'Yüzde Oranları Toplamı 100`ü Geçemez.'
              });
            }
          } else {
            toast?.current?.show({
              severity: 'error',
              summary: 'Hata',
              detail: 'Geçersiz Bayi Dağıtım Anahtar Excel`i Yüklendi.'
            });
          }
        };

        reader.readAsArrayBuffer(file);
      }).catch(err => {
        console.log(err);
        toast?.current?.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Bayi Dağıtım Anahtar Excel`i Yüklenemedi.'
        });
      });
    }
  };

  const goBack = () => {
    history.push("/accessoryCampaignSerialTitleList");
  };

  const onEditorValueChange = (productKey, props, value) => {
    let updatedProducts = [...props.value];
    updatedProducts[props.rowIndex][props.field] = value;
    var unit = 0;
    accessoryCampaignList.forEach(a => {
      unit += a.unit;
    });
    setTotalSerialNumber(unit);
  };

  const unitEditor = (productKey, props) => {
    return <InputNumber value={props.rowData['unit']} min={0}
                        onValueChange={(e) => onEditorValueChange(productKey, props, e.value)}/>
  };

  const onRowEditInit = (event) => {
    originalRows[event.index] = {...accessoryCampaignList[event.index]};
  };

  const onRowEditCancel = (event) => {
    let products = [...accessoryCampaignList];
    products[event.index] = originalRows[event.index];
    delete originalRows[event.index];

    setAccessoryCampaignList(products);
    var unit = 0;
    products.forEach(a => {
      unit += a.unit;
    });
    setTotalSerialNumber(unit);
  };

  const addCommasRatio = num => num?.toFixed(2).toString();

  const header =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>Oluşturulan Seri Numaralar</div>
        <div>
          <Button type="button" icon="pi pi-external-link" label="CSV Aktar"
                  className="p-button p-button-secondary p-button-sm"
                  onClick={exportCSV} style={{marginRight: 5}}/>
          <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
                  onClick={exportXLSX}/>
        </div>
      </div>
    </div>;

  const yuzdeTemplate = (data) => {
    return (
      <div>
        {addCommasRatio(data.yuzde)}
      </div>
    );
  };

  const addCommas = num => num != null ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;

  const sayilarTemplate = (data) => {
    return data.sayilar.map(item => <div style={{textAlign:'left'}}>{item.amount} ➔ <span style={{float:'right'}}>{addCommas(item.unit)}</span></div>);
  };

  const unitTemplate = (data) => {
    return (
      <>
        {addCommas(data.unit)}
      </>
    );
  };

  const toplamSayiTemplate = (data) => {
    return (
      <>
        {addCommas(data.toplamSayi)}
      </>
    );
  };

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-12">
            <Button className="p-button-secondary" icon="pi pi-arrow-left" label="Geri Dön" onClick={goBack}/>
            <h5 style={{marginBottom: 0}}>{pageHeader}</h5>
          </div>
          {accessoryCampaignSerialTitleLoading ?
            <div className="p-col-12 p-md-5" style={{alignSelf: 'center', width: 400}}>
              <div style={{display: 'flex', alignItems: 'center'}}><ProgressSpinner
                style={{width: '40px', height: '40px', marginRight: 5, marginLeft: 10}}
                strokeWidth="8" fill="#EEEEEE"
                animationDuration=".5s"/><b>Yükleniyor. Lütfen bekleyiniz.</b>
              </div>
            </div> :
            <div className="p-col-12 p-md-5">
              {accessoryCampaignSerialTitleId == null && !excelSection &&
              <div>
                <DataTable className="p-fluid" value={accessoryCampaignList} editMode="row"
                           header="Seri Numara Adetleri" onRowEditInit={onRowEditInit} onRowEditCancel={onRowEditCancel}
                           paginator rows={15} rowsPerPageOptions={[5, 15, 50]}
                           paginatorTemplate="FirstPageLink PageLinks LastPageLink RowsPerPageDropdown">
                  <Column field="amount" header="Kampanya Tutarı" style={{textAlign: 'right'}}/>
                  <Column field="termNumber" header="Taksit Sayısı" style={{textAlign: 'right'}}/>
                  <Column field="unit" header="Adet" editor={(props) => unitEditor('accessoryCampaignList', props)}
                          style={{textAlign: 'right'}} body={unitTemplate}/>
                  <Column rowEditor headerStyle={{width: '7rem'}} bodyStyle={{textAlign: 'center'}}/>
                </DataTable>
                <br/>
                <p><b>Toplam Seri Numara Adedi:</b> {addCommas(totalSerialNumber)}</p>
                <br/>
              </div>}
              {accessoryCampaignSerialTitleId == null && excelSection &&
              <div>
                <DataTable className="p-fluid" value={accessoryCampaignList}
                           header="Seri Numara Adetleri"
                           paginator rows={15} rowsPerPageOptions={[5, 15, 50]}
                           paginatorTemplate="FirstPageLink PageLinks LastPageLink RowsPerPageDropdown">
                  <Column field="amount" header="Kampanya Tutarı" style={{textAlign: 'right'}}/>
                  <Column field="termNumber" header="Taksit Sayısı" style={{textAlign: 'right'}}/>
                  <Column field="unit" header="Adet" body={unitTemplate} style={{textAlign: 'right'}}/>
                </DataTable>
                <br/>
                <p><b>Toplam Seri Numara Adedi:</b> {addCommas(totalSerialNumber)}</p>
                <br/>
              </div>
              }
              {accessoryCampaignSerialTitleId == null && !excelSection &&
              <Button icon="pi pi-arrow-right" label="Devam" onClick={excelSectionOpen}/>}
              {excelSection && !excelData &&
              <div className="p-d-flex p-ai-center p-py-2" style={{marginBottom: 10}}>
                <Button label="Örnek Bayi Dağıtım Anahtar Excel`ini İndir" onClick={e => exportExcel(e)}
                        className="p-button-help"/>
                <FileUpload
                  chooseOptions={{
                    label: 'Bayi Dağıtım Anahtar Excel`ini Yükle',
                    icon: 'pi pi-file-excel',
                    className: 'p-button-success'
                  }}
                  style={{marginLeft: 10}}
                  mode="basic" name="demo[]" auto
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  className="p-mr-2" customUpload={true} uploadHandler={importExcel}/>
              </div>}
              {(accessoryCampaignSerialTitleId != null || excelSection && excelData) &&
              <div>
                <DataTable className="p-fluid" value={excelData}
                           header="Seri Numara Bayi Dağıtım Bilgisi"
                           paginator rows={5} rowsPerPageOptions={[5, 10, 15]}
                           paginatorTemplate="FirstPageLink PageLinks LastPageLink RowsPerPageDropdown">
                  <Column field="bayiKodu" header="Bayi Kodu"/>
                  <Column body={yuzdeTemplate} headerClassName="text-align-right" bodyClassName="text-align-right"
                          field="yuzde" header="Yüzde %"/>
                  <Column body={toplamSayiTemplate} field="toplamSayi" header="Toplam Seri Numara" style={{textAlign: 'right'}}/>
                  <Column body={sayilarTemplate} header="Kampanya Bazında Seri Numara"/>
                </DataTable>
                <br/>
                <p>{accessoryCampaignSerialTitleId == null ? <b>Oluşturulacak Seri Numara Adedi:</b> :
                  <b>Oluşturulan Seri Numara Adedi:</b>} {addCommas(createSerialNumber)}</p>
                <Button icon="pi pi-clone" label="Oluştur" onClick={createSerial} style={{marginTop: 10}}
                        disabled={accessoryCampaignSerialTitleId != null || createSerialButtonDisabled}/>
              </div>
              }
            </div>}
          {(createLoading || accessoryCampaignSerialTitle?.titleStatus === "CONTINUING") &&
          <div className="p-col-12 p-md-7" style={{alignSelf: 'center', width: 400}}>
            <div style={{display: 'flex', alignItems: 'center'}}><ProgressSpinner
              style={{width: '40px', height: '40px', marginRight: 5, marginLeft: 10}}
              strokeWidth="8" fill="#EEEEEE"
              animationDuration=".5s"/><b>İşlem devam
              ediyor.<br/> Lütfen daha sonra menüden işlemi kontrol ediniz.</b>
            </div>
          </div>
          }
          {accessoryCampaignSerialTitle && accessoryCampaignSerialTitle?.titleStatus === "COMPLETED" &&
          <div className="p-col-12 p-md-7">
            <DataTable value={accessoryCampaignSerialTitle.accessoryCampaignSerialList}
                       header={header} ref={dt}
                       emptyMessage={"Seri numara bulunamadı"}
                       paginator rows={5} rowsPerPageOptions={[5, 20, 50]}
                       paginatorTemplate="FirstPageLink PageLinks LastPageLink CurrentPageReport RowsPerPageDropdown"
                       currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} arası gösteriliyor">
              <Column field="reseller.code" header="Bayi Kodu" filter={true}/>
              <Column field="accessoryCampaign.amount" header="Kampanya Tutarı" style={{textAlign: 'right'}}/>
              <Column field="accessoryCampaign.termNumber" header="Taksit Sayısı" style={{textAlign: 'right'}}/>
              <Column headerStyle={{width: "50%"}} field="serialValue" header="Seri Numara" filter={true}/>
            </DataTable>
            <br/>
            <p><b>Oluşturulma Zamanı:</b> {accessoryCampaignSerialTitle.createDateTimeString}</p>
            <p><b>Oluşturan Kişi Adı:</b> {accessoryCampaignSerialTitle.userDto.name}</p>
            <p><b>Oluşturan Kişi Soyadı:</b> {accessoryCampaignSerialTitle.userDto.surname}</p>
            <p><b>Aktiflik Durumu:</b> <Checkbox style={{cursor: 'context-menu'}} disabled={true}
                                                 checked={accessoryCampaignSerialTitle.isActivated}/></p>
            <Button disabled={accessoryCampaignSerialTitle.isActivated || activateSerialButtonDisabled}
                    style={{marginRight: 5}} className="p-button-success" icon="pi pi-check" label="Aktifleştir"
                    onClick={activateSerial}/>
            <Button disabled={accessoryCampaignSerialTitle.isActivated || activateSerialButtonDisabled}
                    className="p-button-danger" icon="pi pi-times" label="İptal Et" onClick={cancelSerial}/>
          </div>}
        </div>
      </div>
    </>
  )

};

export default AccessoryCampaignSerialTitle;
