import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import ProductService from "../httpService";
import {Toast} from "primereact/toast";
import {handleError} from "../../../service/HandleErrorService";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {ResponsiveDataTable} from "../../../components/ResponsiveDataTable";
import {Column} from "primereact/column";

const ProductDetailAdmin = () => {
  const {productId} = useParams();
  const toast = useRef(null);
  const history = useHistory();
  const [currentModel, setCurrentModel] = useState({});
  const [brand, setBrand] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const productService = new ProductService();
  const [pictures,setPictures]=useState([]);
  let [createModel, setCreateModel] = useState({
    id: null,
    category: null,
    subCategory: null,
    brand: null,
    name: null,
    description: null,
    barcode: null,
    eol: null,
    origin: null,
    supplyStatus: null

  });
  useEffect(() => {
    console.log(productId)

    getDetail();

  }, [])

  const getDetail = () => {
    console.log(productId)
    productService.getById(productId).then(res => {
      setCurrentModel(res)
      let _upsertProduct = {};
      _upsertProduct.id = res?.id
      _upsertProduct.category = res.subCategory?.category?.name;
      _upsertProduct.subCategory = res?.subCategory?.name;
      _upsertProduct.brand = res?.brand?.name;
      _upsertProduct.name = res.name
      _upsertProduct.description = res.description;
      _upsertProduct.barcode = res?.barcode;
      _upsertProduct.eol = res?.eol
      if (res?.eol) {
        _upsertProduct.eolValue = "EVET";
      } else if (!res?.eol) {
        _upsertProduct.eolValue = "HAYIR";

      }
      _upsertProduct.origin = res?.origin
      _upsertProduct.supplyStatus = res?.supplyStatus;
      setPictures(res?.pictures?.filter(value => value.pictureType === "WEB"&&(value.pictureCrudType==="INSERTED"||value.pictureCrudType==="NOTWANTDELETE"
        ||value?.pictureCrudType==="WANTDELETE")));

      setCreateModel(_upsertProduct);
    }).catch(err => handleError(err, toast))

  }
  const changeModel = (e, name) => {

    let _createModel = {...createModel};
    _createModel[name] = e.target.value;
    setCreateModel(_createModel)
  }

  const imageTemplate = (e) => {

    return <img src={e?.baseUrl} style={{height:'auto',width:200}}></img>
  }

  const upsertModel = async () => {
    let _upsertProduct = {...createModel};
    await productService.upsert(_upsertProduct).then(res => {

    }).catch(err => handleError(err, toast))
    toast.current.show({
      severity: 'success',
      summary: 'Başarılı İşlem',
      detail: 'Ürün Güncellendi',
      life: 800,

    });
    setInterval(() => {

      history.push("/ProductManagement")
    }, 800);
  }
  return (
    <>
      <Toast ref={toast}/>
      <h4>Ürün Bilgileri</h4>

      <div className="card">

        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-8 p-md-4">
      <span className="p-float-label marginInput">
          <InputText id="category" value={createModel?.category}
                     disabled onChange={e => changeModel(e, 'category')}

          />
                     <label htmlFor="category">Kategori</label>
        </span>
          </div>
          <div className="p-field p-col-8 p-md-4">
      <span className="p-float-label marginInput">
          <InputText id="subCategory" value={createModel?.subCategory}
                     disabled onChange={e => changeModel(e, 'subCategory')}

          />
                     <label htmlFor="subCategory">Alt Kategori</label>
        </span>
          </div>
          <div className="p-field p-col-8 p-md-4">

      <span className="p-float-label marginInput">
          <InputText id="brand" value={createModel?.brand}
                     disabled onChange={e => changeModel(e, 'brand')}

          />
                     <label htmlFor="brand">Marka</label>
        </span>
          </div>
          <div className="p-field p-col-8 p-md-4">
      <span className="p-float-label marginInput">
          <InputText id="name" value={createModel?.name}
                     disabled onChange={e => changeModel(e, 'name')}

          />
                     <label htmlFor="name">Ürün İsmi</label>
        </span>
          </div>
          <div className="p-field p-col-8 p-md-4">
      <span className="p-float-label marginInput">
          <InputTextarea autoResize id="description" value={createModel?.description}
                         disabled onChange={e => changeModel(e, 'description')}

          />
                     <label htmlFor="description">Ürün Açıklaması</label>
        </span>
          </div>
          <div className="p-field p-col-8 p-md-4">

      <span className="p-float-label marginInput">
          <InputText id="barcode" value={createModel?.barcode}
                     disabled onChange={e => changeModel(e, 'barcode')}

          />
                     <label htmlFor="barcode">Ürün Barkodu</label>
        </span>
          </div>
          <div className="p-field p-col-8 p-md-4">
      <span className="p-float-label marginInput">
          <InputText id="barcode" value={createModel?.barcode}
                     disabled onChange={e => changeModel(e, 'barcode')}

          />
                     <label htmlFor="barcode">Ürün Barkodu</label>
        </span>
          </div>
          <div className="p-field p-col-8 p-md-4">

      <span className="p-float-label marginInput">
          <InputText id="origin" value={createModel?.origin}
                     disabled onChange={e => changeModel(e, 'origin')}

          />
                     <label htmlFor="origin">Menşei</label>
        </span>
          </div>
          <div className="p-field p-col-8 p-md-4">
      <span className="p-float-label marginInput">
        <Dropdown value={createModel?.eolValue}
                  disabled
                  options={["EVET", "HAYIR"]}
                  showClear
                  onChange={e => {
                    let _createModel = {...createModel};
                    _createModel.eolValue = e.target.value;
                    setCreateModel(_createModel)
                  }}
                  placeholder="Eol giriniz"/>
                             <label htmlFor="origin">EOL</label>

</span>
          </div>
          <div className="p-field p-col-8 p-md-4">
      <span className="p-float-label marginInput">
        <Dropdown value={createModel?.supplyStatus}
                  disabled
                  options={["AÇIK", "KAPALI"]}
                  onChange={e => {
                    let _createModel = {...createModel};


                    _createModel.supplyStatus = e.target.value;

                    setCreateModel(_createModel)
                  }}
                  placeholder="Tedarikçi Durumu"/>
         <label htmlFor="origin">Tedarik Durumu</label>
</span>
          </div>

          <div className="p-field p-col-8 p-md-8">
            <ResponsiveDataTable
              header="Kayıtlı Ürün Resimleri"
              value={pictures}
              emptyMessage="Resim Bulunamadı.">

              <Column body={imageTemplate} header="Resim"/>



            </ResponsiveDataTable>



          </div>


        </div>
        <div className="p-field p-col-8 p-md-4">
          <Button onClick={e => history.push("/ProductManagement-Admin")} style={{width: 280, marginTop: 20}}

                  label="Geri Dön" className="p-mr-2 p-mb-2"/>

        </div>
      </div>

    </>
  )

}
export default ProductDetailAdmin
