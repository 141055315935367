import React, {useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import ProductSalesManagementService from "./httpService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Calendar} from "primereact/calendar";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Checkbox} from "primereact/checkbox";

const PassiveSerial = () => {

  const toast = useRef(null);
  const productSalesManagementService = new ProductSalesManagementService();

  const [loading, setLoading] = useState(false);
  const [saleInfoList, setSaleInfoList] = useState([]);

  const [selectedTimeBegin, setSelectedTimeBegin] = useState(null);
  const [selectedTimeEnd, setSelectedTimeEnd] = useState(null);
  const [resellerCode, setResellerCode] = useState(null);
  const [serialNo, setSerialNo] = useState(null);

  const [exportExcelDisabled, setExportExcelDisabled] = useState(null);

  const [multiCancelDisabled, setMultiCancelDisabled] = useState(true);
  const [multiCancelModal, setMultiCancelModal] = useState(null);
  const [multiCancelModalMessage, setMultiCancelModalMessage] = useState(null);
  const [renderKey, setRenderKey] = useState(0);

  const searchSaleInfo = () => {
    var dateControl = false;
    var resellerControl = false;
    var serialControl = false;
    if (selectedTimeBegin != null && selectedTimeEnd != null) {
      dateControl = true;
    }
    if (resellerCode != null && resellerCode !== "" && resellerCode.length > 0) {
      resellerControl = true;
    }
    if (serialNo != null && serialNo !== "" && serialNo.length > 0) {
      serialControl = true;
    }
    if (dateControl || resellerControl || serialControl) {
      var searchDateControl = true;
      if (selectedTimeBegin != null && selectedTimeEnd != null) {
        if (selectedTimeEnd.getTime() - selectedTimeBegin.getTime() <= 7 * 25 * 3600000) {
          if (selectedTimeBegin.getTime() > selectedTimeEnd.getTime()) {
            searchDateControl = false;
            toast.current.show({
              severity: 'warn',
              summary: 'Uyarı',
              detail: 'Başlangıç tarihi bitiş tarihinden önce olmalıdır.'
            });
          }
        } else {
          searchDateControl = false;
          toast.current.show({
            severity: 'warn',
            summary: 'Uyarı',
            detail: 'Tarih aralığı bir haftadan fazla olmamalıdır.'
          });
        }
      }
      if (searchDateControl) {
        setLoading(true);

        let searchCriteria = {};
        searchCriteria.resellerCode = resellerCode;
        searchCriteria.serial = serialNo;

        if (selectedTimeBegin != null) {
          searchCriteria.startDate = selectedTimeBegin.toLocaleDateString("tr-TR") + " " + selectedTimeBegin.toLocaleTimeString("tr-TR");
        } else {
          searchCriteria.startDate = null;
        }

        if (selectedTimeEnd != null) {
          searchCriteria.endDate = selectedTimeEnd.toLocaleDateString("tr-TR") + " " + selectedTimeEnd.toLocaleTimeString("tr-TR");
        } else {
          searchCriteria.endDate = null;
        }

        productSalesManagementService.searchSerial(searchCriteria).then(res => {
          console.log("searchProductSaleEager", res);
          res.forEach(r => {
            r.selected = false;
          });
          setMultiCancelDisabled(true);
          setSaleInfoList(res);
        }).catch(err => {
          toast?.current?.show({
            severity: 'error',
            summary: 'Hata',
            detail: 'İşlem bilgisi getirme başarısız.'
          });
          console.log(err);
        }).finally(() => {
          setLoading(false);
        });
      }
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        life: 5000,
        detail: 'Tarih Aralığı, Bayi Kodu, Seri Numara filterelerinden en az biri dolu olmalıdır.'
      });
    }
  };

  const exportExcel = () => {
    setExportExcelDisabled(true);
    import('xlsx').then(xlsx => {
      var excelList = [];
      saleInfoList.forEach(a => {
        let barcodeList = "";
        let serialNoList = "";
        let brandList = "";
        let modelList = "";
        let priceList = "";
        let saleStatusList = "";
        a.productSerialNoList?.forEach(p => {
          barcodeList += p.barcode + ",";
          serialNoList += p.serialNo + ",";
          brandList += p.model?.brand?.name + ",";
          modelList += p.model?.name + ",";
          priceList += addCommas(p.recommendedSellPrice) + "|";
          saleStatusList += p.saleStatusDisplay + ",";
        });
        barcodeList = barcodeList.substring(0, barcodeList.length - 1);
        serialNoList = serialNoList.substring(0, serialNoList.length - 1);
        brandList = brandList.substring(0, brandList.length - 1);
        modelList = modelList.substring(0, modelList.length - 1);
        priceList = priceList.substring(0, priceList.length - 1);
        saleStatusList = saleStatusList.substring(0, saleStatusList.length - 1);
        excelList.push({
          "Oluşturulma Zamanı": a.createDateTimeString,
          "Bayi Kodu": a.resellerCode,
          "Oluşturan Kişi Adı": a.createdByName,
          "Oluşturan Kişi Soyadı" : a.createdBySurname,
          "Kampanya Tutarı": a.amount,
          "Taksit Sayısı": a.termNumber,
          "Seri Numara Durumu": a.usageStatusDisplay,
          "Seri Numara": a.serialValue,
          "Aktiflik Durumu": a.isActivated
        })
      });
      const worksheet = xlsx.utils.json_to_sheet(excelList);
      var wscols = [
        {width: 17},
        {width: 15},
        {width: 17},
        {width: 17},
        {width: 15},
        {width: 15},
        {width: 17},
        {width: 17},
        {width: 15}
      ];
      worksheet["!cols"] = wscols;
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      saveAsExcelFile(excelBuffer, 'Kampanya Seri Numaraları');
      setExportExcelDisabled(false);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const selectAll = () => {
    let count = 0;
    saleInfoList.forEach(s => {
      if(s.usageStatusDisplay === "Kullanılmadı" && s.isActivated === "Aktif") {
        s.selected = true;
        count++;
      }
    });
    if (count > 0) {
      setMultiCancelDisabled(false);
    } else {
      toast?.current?.show({
        severity: 'warn',
        summary: 'Uyarı',
        life: 5000,
        detail: 'Listede seçilebilecek aktif kullanılmamış seri numara yok.'
      });
    }
    setRenderKey(Math.random());
  };

  const multiCancelModalShow = () => {
    var count = 0;
    saleInfoList.forEach(s => {
      if (s.selected) {
        count++;
      }
    });
    if (count > 0) {
      setMultiCancelModalMessage(count + " adet kampanya seri numarasını pasife almak istediğinizden emin misiniz?");
      setMultiCancelModal(true);
    } else {
      toast?.current?.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Seçilen seri numarası yok.'
      });
    }
  };

  const multiCancelModalHide = () => {
    setMultiCancelModal(false);
  };

  const multiCancelSale = () => {
    setLoading(true);
    setMultiCancelModal(false);
    let productSaleIdList = [];
    saleInfoList.forEach(s => {
      if (s.selected) {
        productSaleIdList.push(s.id);
      }
    });
    const request = {
      serialIdList: productSaleIdList
    };
    productSalesManagementService.cancelSerials(request).then(res => {
      console.log("multiCancelSaleApprove", res);
      toast.current.show({
        severity: 'success',
        summary: 'Başarılı İşlem',
        detail: 'Seçtiğiniz seri numaralar pasife alındı.'
      });
    }).catch(err => {
      console.log(err);
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: err.response.data.message,
        life: 4000
      });
    }).finally(() => {
      searchSaleInfo();
    });
  };

  const multiCancelRenderFooter = () => {
    return (
      <div>
        <Button label="Hayır" icon="pi pi-times" onClick={multiCancelModalHide} className="p-button-text"/>
        <Button label="Evet" icon="pi pi-check" onClick={multiCancelSale} autoFocus/>
      </div>
    );
  };

  const addCommas = num => num?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const createDateTemplate = (data) => {
    return responsiveColumn("Oluşturulma Zamanı", data?.createDateTimeString)
  };

  const resellerCodeTemplate = (data) => {
    return responsiveColumn("Bayi Kodu", data?.resellerCode)
  };

  const nameTemplate = (data) => {
    return responsiveColumn("Oluşturan Kişi Adı", data?.createdByName)
  };

  const surnameTemplate = (data) => {
    return responsiveColumn("Oluşturan Kişi Soyadı", data?.createdBySurname)
  };

  const amountTemplate = (data) => {
      return responsiveColumn("Kampanya Tutarı", data?.amount)
  };

  const termNumberTemplate = (data) => {
      return responsiveColumn("Taksit Sayısı", data?.termNumber)
  };

  const usageStatusTemplate = (data) => {
      return responsiveColumn("Seri Numara Durumu", data?.usageStatusDisplay)
  };

  const serialValueTemplate = (data) => {
      return responsiveColumn("Seri Numara", data?.serialValue)
  };

  const isActivatedTemplate = (data) => {
      return responsiveColumn("Aktiflik Durumu", data?.isActivated)
  };

  const header =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>Kampanya Seri Numaraları</div>
        <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
                disabled={exportExcelDisabled || saleInfoList.length === 0}
                onClick={exportExcel}/>
      </div>
    </div>;

  const onChangeSelected = (e) => {
    let count = 0;
    saleInfoList.forEach(s => {
      if (s.id == e.target.id) {
        s.selected = e.target.checked;
      }
      if (s.selected) {
        count++;
      }
    });
    if (count > 0) {
      setMultiCancelDisabled(false);
    } else {
      setMultiCancelDisabled(true);
    }
    setRenderKey(Math.random());
  };

  const selectedTemplate = (rowData) => {
    return <Checkbox id={rowData.id}
                     disabled={rowData.usageStatusDisplay !== "Kullanılmadı" || rowData.isActivated !== "Aktif"}
                     style={{cursor: 'context-menu'}} checked={rowData.selected}
                     onChange={e => onChangeSelected(e)}/>
  };

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-12 p-md-2 p-fluid">
            <label htmlFor="baslangic">Tarih Aralığı</label>
            <Calendar id="baslangic" showIcon dateFormat={'dd.mm.yy'} style={{marginTop: 5}}
                      value={selectedTimeBegin} showTime showSeconds showButtonBar
                      onChange={(e) => setSelectedTimeBegin(e.value)}
                      placeholder="Tarih Başlangıç"/>
          </div>
          <div className="p-col-12 p-md-2 p-fluid"><br className="small_hidden"/>
            <Calendar id="bitis" showIcon dateFormat={'dd.mm.yy'} style={{marginTop: 5}}
                      value={selectedTimeEnd} showTime showSeconds showButtonBar
                      onChange={(e) => setSelectedTimeEnd(e.value)}
                      placeholder="Tarih Bitiş"/>
          </div>
          <div className="p-col-12 p-md-2 p-fluid">Bayi Kodu
            <InputText style={{marginTop: 5}} value={resellerCode} placeholder="Bayi Kodu"
                       onChange={(e) => setResellerCode(e.target.value)}/>
          </div>
          <div className="p-col-12 p-md-2 p-fluid">
            Seri Numara
            <InputText style={{marginTop: 5}} value={serialNo} placeholder="Seri Numara"
                       onChange={(e) => setSerialNo(e.target.value)}/>
          </div>
          <div className="p-col-12 p-md-1 p-fluid">
            <Button
              style={{marginTop: 24}}
              disabled={loading}
              label="Ara"
              icon="pi pi-search"
              className="p-button-primary"
              onClick={searchSaleInfo}
            />
          </div>
          <div className="p-col-12">
            <Button onClick={selectAll} label="Listedeki Tüm Aktif Kullanılmamış Seri Numaları Seç"
                    disabled={saleInfoList.length === 0} style={{marginRight: 7}}/>
            <Button onClick={multiCancelModalShow} label="Seçilen Seri Numaları Pasife Al"
                    disabled={multiCancelDisabled}
                    className="p-button-danger"/>
          </div>
          <div className="p-col-12">
            <ResponsiveDataTable
              header={header}
              value={saleInfoList}
              paginator rows={10} rowsPerPageOptions={[10, 20, 30]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} arası gösteriliyor"
              loading={loading}
              emptyMessage="Kampanya seri numarası bulunamadı"
              style={{fontSize: '12px'}}
            >
              <Column field="reserved" body={selectedTemplate} headerStyle={{width: '2rem'}}/>
              <Column field="createDateTimeString" body={createDateTemplate} header="Oluşturulma Zamanı" sortable/>
              <Column field="resellerCode" body={resellerCodeTemplate} header="Bayi Kodu" sortable/>
              <Column field="createdByName" body={nameTemplate} header="Oluşturan Kişi Adı" sortable filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="createdBySurname" body={surnameTemplate} header="Oluşturan Kişi Soyadı	" sortable filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="amount" body={amountTemplate} header="Kampanya Tutarı" sortable filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="termNumber" body={termNumberTemplate} header="Taksit Sayısı" sortable filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="usageStatusDisplay" body={usageStatusTemplate} header="Seri Numara Durumu" sortable filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="serialValue" body={serialValueTemplate} header="Seri Numara" sortable/>
              <Column field="isActivated" body={isActivatedTemplate} header="Aktiflik Durumu" sortable filter={true}
                      filterMatchMode={"contains"}/>
            </ResponsiveDataTable>
          </div>
        </div>
        <Dialog header={"Seçilen Seri Numaları Pasife Alma"} visible={multiCancelModal} modal style={{width: '420px'}}
                footer={multiCancelRenderFooter('displayConfirmation')} onHide={multiCancelModalHide}>
          <div className="confirmation-content">
            <span>{multiCancelModalMessage}</span>
          </div>
        </Dialog>
      </div>
    </>
  )

};

export default PassiveSerial;
