import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import * as services from "./httpService";







const ProductFaultyUpdate = () => {

  const toast = useRef(null);
  const [serialNoNotExist, setSerialNoNotExist] = useState(false);
  const [searchProductWithSerialButtonDisabled, setSearchProductWithSerialButtonDisabled] = useState(false);
  const [shippingName, setShippingName] = useState("")
  const [postCode, setPostCode] = useState("")
  const [address, setAddress] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const user = JSON.parse(localStorage.getItem('user'))?.user?.supply

console.log(user)


  const saveSupply = (e) => {
    let params = {
      id:user?.id,
      name:user?.name,
      code:user?.code,
      shippingName,
      postCode,
      address,
      phoneNumber
   }
   console.log(params)
   services.updateSupply(params).then((res)=> {toast.current?.show({
    severity: "success",
    summary: "Başarılı",
    detail: "Kaydedildi",
    life: 3000,
  });}).catch((err) =>{console.log(err);
    toast.current.show({
      severity: "error",
      summary: "Hata",
      detail: err.response.data.message,
      life: 4000,
    });})
  }


  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
            <div className="p-inputgroup">
              <InputText style={{marginTop: 5}} value={shippingName} placeholder="Kargo Adı"
                         onChange={(e) => setShippingName(e.target.value)}/>
            
              <InputText style={{marginTop: 5}} value={postCode} placeholder="Gönderi Kodu"
                         onChange={(e) => setPostCode(e.target.value)}/>
            
              <InputText style={{marginTop: 5}} value={address} placeholder="Adres"
                         onChange={(e) => setAddress(e.target.value)}/>
            
            
              <InputText style={{marginTop: 5}} value={phoneNumber} placeholder="Telefon Numarası"
                         onChange={(e) => setPhoneNumber(e.target.value)}/>
               <Button label="Kaydet" onClick={e => saveSupply(e)} icon="pi pi-check"/>
            </div>  
        </div>
      </div>
    </>
  )

};

export default ProductFaultyUpdate;
