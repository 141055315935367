import "../assets/css/ResponsiveDataTable.css"
import { DataTable } from "primereact/datatable";
import React from "react";
import { Paginator } from "primereact/paginator";

export const ResponsiveDataTableWithoutRowClass = (props) => {
  return (
    <>
      <DataTable
        emptyMessage="Kayıt bulunamadı"
        className="p-datatable-responsive-demo"
        {...props}
      />
      {props.paginationConfig ? (
        <Paginator
          rows={props.paginationConfig.itemsPerPage}
          totalRecords={props.paginationConfig.totalRecords}
          first={props.paginationConfig.first}
          rowsPerPageOptions={[5, 10, 25]}
          onPageChange={props.onActivePageChange}
          template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} arası gösteriliyor"
        />
      ) : null}
    </>
  );
};
