import { useEffect, useRef, useState } from "react";
import * as services from "./httpService";
import { Toast } from "primereact/toast";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import errorImage from "../../assets/images/error.png";
import { handleError } from "../../service/HandleErrorService";

export const ResellerLoginWarning = () => {
  const toast = useRef();

  const [loading, setLoading] = useState(true);
  const [ticketError, setTicketError] = useState(false);

  const history = useHistory();
  const { ticketId } = useParams();

  useEffect(() => {
    loginRequest();
  }, []);

  const loginRequest = () => {
    let body = {
      authTicket: ticketId,
    };
    services
      .resellerLogin(body)
      .then((res) => {
        localStorage.setItem("token", res.token);
        localStorage.setItem("refreshToken", res.refreshToken);
        services
          .userDetail(res.token)
          .then((res) => {
            if (res) {
              localStorage.setItem("user", JSON.stringify(res));
              localStorage.setItem("autoLogin", "false");
              history.push("/dashboard");
            }
          })
          .catch((err) => {
            handleError(err, toast);
          });
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data?.code === 1000) {
          setTicketError(true);
        }
      });
  };

  return (
    <>
      <Toast ref={toast} />
      {loading ? (
        <div className="loading-box">
          <div className="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <h6>Yükleniyor... Lütfen bekleyin...</h6>
          </div>
        </div>
      ) : (
        <>
          {ticketError ? (
            <div className="loading-box">
              <img src={errorImage} width="170" height="170" />
              <h6>DOĞRULAMA KODU GEÇERSİZ !</h6>
            </div>
          ) : (
            <>
              <div className="reseller-bg-warning   notfound  p-flex-column">
                <div className="p-d-flex   ">
                  <div className="p-field p-col-12 p-md-12 ">
                    <div className="p-d-flex p-ai-center ">
                      <i
                        className="pi pi-info-circle p-mr-6"
                        style={{ fontSize: "7rem", color: "orange" }}
                      ></i>
                      <p style={{ fontSize: "5rem", color: "orange" }}>UYARI</p>
                    </div>
                    <div className="p-mt-4">
                      <label
                        style={{ color: "#fff", fontSize: "1.1rem" }}
                        htmlFor="binary"
                      >
                        <b style={{ fontWeight: "bold" }}>"Kullanıcı"</b> rolü
                        ile işlem yapılamamaktadır. Lütfen akillitercihler.com'a{" "}
                        <b>"Yönetici"</b> rolü ile giriş yapınız.
                        Yetkilendirilmiş kullanıcılar için Satış İşlemleri
                        TT-MAP Mobil uygulamasından yapılmaktadır.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
