import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import ParameterService from "./httpService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import classNames from "classnames";
import {InputNumber} from "primereact/inputnumber";

const ResellerParamList = () => {

  const resellerCode = JSON.parse(localStorage.getItem('user')).user?.loginReseller?.code;
  const toast = useRef(null);
  const parameterService = new ParameterService();

  const [loading, setLoading] = useState(false);
  const [parameterList, setParameterList] = useState(null);

  const [updateButtonDisabled, setUpdateButtonDisabled] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedKey, setSelectedKey] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState(false);
  const [selectedValue, setSelectedValue] = useState(false);

  useEffect(() => {
    getMaxDiscountRatio();
  }, []);

  const getMaxDiscountRatio = () => {
    setLoading(true);
    parameterService.getByKeyAndResellerCode("MAX_DISCOUNT_RATIO", resellerCode).then(res => {
      console.log("getByKeyAndResellerCode", res);
      setParameterList([res]);
    }).catch(err => {
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Parametre bilgisi getirme başarısız.'
      });
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  };

  const updateModalShow = (e) => {
    setSubmitted(false);
    setUpdateModal(true);
    parameterList.forEach(p => {
      if (p.key == e.currentTarget.id) {
        setSelectedKey(p.key);
        setSelectedDescription(p.description);
        setSelectedValue(p.value);
      }
    });
  };

  const updateModalHide = () => {
    setUpdateModal(false);
  };

  const updateParameter = () => {
    setSubmitted(true);
    setUpdateButtonDisabled(true);
    if (selectedValue != null) {
      if (selectedValue >= 0 && selectedValue <= 100) {
        const request = {
          key: selectedKey,
          resellerCode: resellerCode,
          description: selectedDescription,
          value: selectedValue
        };
        parameterService.updateParameter(request).then(res => {
          console.log("updateParameter", res);
          toast.current.show({
            severity: 'success',
            summary: 'Başarılı İşlem',
            detail: 'Parametre düzenlendi.'
          });
          updateModalHide();
          getMaxDiscountRatio();
        }).catch(err => {
          console.log(err);
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: 'Parametre düzenlenemedi.'
          });
        }).finally(() => {
          setUpdateButtonDisabled(false);
        })
      } else {
        toast.current.show({
          severity: 'warn',
          summary: 'Uyarı',
          detail: 'Değer alanı sıfırdan küçük ve yüzden büyük olamaz olmalıdır.'
        });
        setUpdateButtonDisabled(false);
      }
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Değer alanı dolu olmalıdır.'
      });
      setUpdateButtonDisabled(false);
    }
  };

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const keyTemplate = (data) => {
    return responsiveColumn("Ad", data?.key)
  };

  const descriptionTemplate = (data) => {
    return responsiveColumn("Açıklama", data?.description)
  };

  const valueTemplate = (data) => {
    return responsiveColumn("Değer", "%" + data?.value)
  };

  const parametreButtonTemplate = (data) => {
    return (
      <div className="p-d-flex p-justify-even">
        <Button id={data.key} type="button" className="p-button-sm p-button-info" label="Düzenle"
                onClick={updateModalShow}/>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-12">
            <p><b>TESF:</b> Tavsiye Edilen Satış Fiyatı</p>
            <ResponsiveDataTable
              className="p-datatable-responsive-demo"
              header="Bayi Parametreleri"
              value={parameterList}
              loading={loading}
              emptyMessage="Parametre bulunamadı"
              paginator rows={10} rowsPerPageOptions={[10, 20, 30]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} arası gösteriliyor"
              style={{border:'2px solid lightgray'}}
            >
              <Column field="description" body={descriptionTemplate} header="Açıklama" filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="value" body={valueTemplate} header="Değer" filter={true}
                      filterMatchMode={"contains"}/>
              <Column body={parametreButtonTemplate}
                      headerStyle={{width: "8rem", textAlign: "center"}}
                      bodyStyle={{textAlign: 'center', overflow: 'visible', justifyContent: 'center'}}/>
            </ResponsiveDataTable>
          </div>
        </div>
        <Dialog header="Parametre Düzenle" visible={updateModal} modal style={{width: '320px'}}
                onHide={updateModalHide}>
          <div className="p-grid p-fluid">
            <div className="p-col-12">
              <span className="p-float-label marginInput">
            <InputText id="description" value={selectedDescription}
                       disabled={true}/>
            <label htmlFor="description">Açıklama</label>
           </span>
              <span className="p-float-label marginInput">
            <InputNumber id="value" value={selectedValue} min={0} max={100}
                         onChange={(e) => setSelectedValue(e.value)}
                         className={classNames({'p-invalid': submitted && !selectedValue})}/>
            <label htmlFor="value">Değer</label>
           </span>
              <Button style={{marginTop: 20}} className="p-button-success" label="Kaydet"
                      disabled={updateButtonDisabled} onClick={updateParameter}/>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  )

};

export default ResellerParamList;
