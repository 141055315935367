import AspAxios from "../../service/AspAxios"
import * as Paths from '../../service/config'
import axios from "axios";
import {addToDashboard, getAllModelAdminListIds, getAllModelSupplyList, getCategoryTree} from "../../service/config";

export default class ProductService {


  getModelsTree(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getModelTree, request).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }

  addToDashboard(request) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.addToDashboard+"/"+ request).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }

  removeToDashboard(request) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.removeToDashboard+"/"+ request).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }

  approveModel(request,value) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.approveModel+"/"+value,request).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }
  approveModelList(request,value) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.approveModelList+"/"+value,request).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }


  getModelsTreeSupply(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getModelTreeSupply, request).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }

  getModelsTreeDisty(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getModelTreeDisty, request).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }


  getWaitingProducts(request,_page,_size) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getAllWaitingProducts, request,{params:{size:_size,page:_page}}).then(res => {

        resolve(res.data)
      }).catch(err => reject(err))

    })
  }
  getWaitingProductsList(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getAllWaitingProductsList, request).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }
   getById(id) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getById + "/" + id).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }

  upsert(upsertModel) {
    console.log(upsertModel)
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.upsertModel, upsertModel, {
      }).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }

  upsertPhoto(request,modelId,toMail) {

    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.upsertModelPhoto+"/"+modelId+"/"+toMail, request, {

        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 60000

      }).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }

  deletePhotos(_deleteImages,toMail) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.deleteModelPhoto+"/"+toMail,_deleteImages).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }
  getAllCategory() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllCategory).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }


  getSubCategoryByCategory(category) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getSubCategoryByCategory, category).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }

  getAllBrand() {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getAllBrand).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }

  getAllSupply(_page, _size,searchRequest) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getAllModelSupply, searchRequest, {params: {size: _size, page: _page}}).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }

  getAllSupplyList(searchRequest) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getAllModelSupplyList, searchRequest).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }


  getAllModelAdmin(_page, _size,searchRequest) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getAllModelAdmin, searchRequest, {params: {size: _size, page: _page}}).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }

  getAllModelAdminList(searchRequest) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getAllModelAdminList, searchRequest).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }

  getAllModelAdminListIds(searchRequest) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getAllModelAdminListIds, searchRequest).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }

  readExcel(excelProductPrices) {
    return new Promise((resolve, reject) => {
      axios.post(Paths.readExcelForModel, {excelProductPrices}).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }

  saveExcel(excelProductPrices) {
    return new Promise((resolve, reject) => {
      axios.post(Paths.saveExcelForModel, {excelProductPrices}).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }
  getapproveTypes() {
    return new Promise((resolve, reject) => {
      axios.get(Paths.allApproveTypes).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }

  savePriceExcel(file) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.savePriceExcel, file, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 60000
      }).then(res => {

        resolve(res.data)
      }).catch(err => reject(err))
    })
  }

};
