import AspAxios from "../../service/AspAxios";
import * as Paths from '../../service/config'

export class BannerService {

  currentBanner() {

    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.currentBanners).then(res => {

        resolve(res.data)
      }).catch(err => reject(err))
    })

  }

  getById(id) {

    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.bannerGetById + id).then(res => {

        resolve(res.data)
      }).catch(err => reject(err))
    })

  }

  findAllBanners() {

    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.findAllBanners).then(res => {

        resolve(res.data)
      }).catch(err => reject(err))
    })

  }

  upsertBanner(file, startDate, endDate) {

    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.upsertBanner + "/" + startDate + "/" + endDate, file, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 60000
      }).then(res => {

        resolve(res.data)
      }).catch(err => reject(err))
    })

  }

  updateBanner(request) {

    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.updateBanner, request).then(res => {

        resolve(res.data)
      }).catch(err => reject(err))
    })

  }

  deleteBanner(id) {

    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.deleteBanner + "/" + id).then(res => {

        resolve(res.data)
      }).catch(err => reject(err))
    })

  }
}
