import ProductService from "../httpService"
import React, {useEffect, useRef, useState} from "react";

import {Toast} from "primereact/toast";
import {Column} from "primereact/column";

import {Button} from "primereact/button";
import {useHistory} from "react-router-dom";
import {InputText} from "primereact/inputtext";

import {InputTextarea} from "primereact/inputtextarea";
import {Dropdown} from "primereact/dropdown";
import {ResponsiveDataTable} from "../../../components/ResponsiveDataTable";
import {handleError} from "../../../service/HandleErrorService";
import CanSellService from "../../canSell/httpService";



const ProductAddedToDashboard = () => {

  const [allCategory, setAllCategory] = useState([]);
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [selectedProducts8, setSelectedProducts8] = useState([]);
  const toast = useRef(null);
  const history = useHistory();
  const productService = new ProductService();
  const [allProduct, setAllProduct] = useState([]);
  const [createModel, setCreateModel] = useState();
  const [createModelDialog, setCreateModelDialog] = useState(false);
  const [createModelHeader, setCreateModelHeader] = useState(false);
  const [searchRequest, setSearchRequest] = useState({});
  const canSellService = new CanSellService();
  const [allBrand, setAllBrand] = useState([]);
  const [allSupply, setAllSupply] = useState([]);
  const [excelList, setExcelList] = useState([]);
  const [allApproveTypes, setAllApproveTypes] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  const sortData = (data) => {
    const x = data?.sort(function(a, b){
    if(a.name < b.name) { return -1; }
    if(a.name > b.name) { return 1; }
    return 0;
})
return x;
}
  useEffect(() => {
    getAll(paginationConfig);
    canSellService.getAllCategory().then(res => {
      setAllCategory(sortData(res))
    })
    canSellService.getAllSubCategory().then(res => {
      setAllSubCategory(sortData(res))
    })
    canSellService.getAllBrand().then(setAllBrand);
    canSellService.getAllSupply().then(setAllSupply);
    productService.getapproveTypes().then(res => {
      setAllApproveTypes(res)
    })

  }, [])

  const getAll = (pageState) => {


    productService.getAllModelAdmin(pageState.activePage, pageState.itemsPerPage, searchRequest).then(res => {
      pageState.first = pageState.itemsPerPage * pageState.activePage;
      pageState.totalRecords = res.totalElements;
      setPaginationConfig({
        ...pageState,
      });
      let _selected = [...selectedProducts8];
      res?.content?.forEach(value => {

        value?.labels?.find(label => {
          if (label === 'DASHBOARD') {

            _selected.push(value);
          }
        })
      });
      setSelectedProducts8(_selected);

      setAllProduct(res?.content)

    }).catch(err => handleError(err, toast))

  }

  const exportExcel = () => {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(excelList);
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      saveAsExcelFile(excelBuffer, 'products');
    });
  }

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, "Ürün Listesi" + EXCEL_EXTENSION);
    });
  }
  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    getAll(pageState);
  };

  const eolTemplate = (e) => {
    if (e.eol) {
      return <div>Evet</div>
    } else if (e.eol === undefined) {
      return <div>Girilmedi</div>
    } else
      return <div>Hayır</div>


  }


  const supplyTemplate = (e) => {
    let _supply = allSupply.find(value => e.supplyName === value.name);

    return _supply?.disties !== undefined ? _supply?.disties[0]?.name : _supply?.name;

  }

  const approveTemplate = (e) => {
    let _type = allApproveTypes.find(value => value.key === e.approveType);

    if (_type?.value === "BEKLIYOR") {
      return <div style={{fontSize: 14}}>{_type?.value}</div>
    } else if (_type?.value === "REDDEDİLDİ") {
      return <div style={{fontSize: 14}}>{_type?.value}</div>

    } else {
      return <div style={{fontSize: 14}}>{_type?.value}</div>

    }
  }
  const modelNameTemplate = (e) => {

    return <div><InputTextarea style={{fontSize: 10, backgroundColor: 'lawngreen', width: '100%'}} autoResize disabled
                               value={e?.name}/>
    </div>
  }


  const headersTemplate = (
    <div className="card">

      <div className="p-formgroup-inline">
      <div className="p-field">
          <label htmlFor="category" className="p-sr-only">Marka</label>
          <Dropdown id="category" value={searchRequest.category} options={allCategory} showClear placeholder="Kategori"
                    optionLabel="name" filter optionValue="name"
                    onChange={e => {
                      let _searchRequest = {...searchRequest};
                      _searchRequest.category = e.target.value;
                      setSearchRequest(_searchRequest);
                    }

                    }/>
        </div>
        <div className="p-field">
          <label htmlFor="subCategory" className="p-sr-only">Marka</label>
          <Dropdown id="subCategory" value={searchRequest.subCategory} options={allSubCategory} showClear placeholder="Alt Kategori"
                    optionLabel="name" filter optionValue="name"
                    onChange={e => {
                      let _searchRequest = {...searchRequest};
                      _searchRequest.subCategory = e.target.value;
                      setSearchRequest(_searchRequest);
                    }

                    }/>
        </div>

        <div className="p-field">
          <label htmlFor="brand" className="p-sr-only">Marka</label>
          <Dropdown id="brand" value={searchRequest.brand} options={allBrand} showClear placeholder="Marka"
                    optionLabel="name" optionValue="name"
                    onChange={e => {
                      let _searchRequest = {...searchRequest};
                      _searchRequest.brand = e.target.value;
                      setSearchRequest(_searchRequest);
                    }

                    }/>
        </div>

        <div className="p-field">
          <label htmlFor="supply" className="p-sr-only">Tedarikçi</label>
          <Dropdown id="supply" value={searchRequest.supply} options={allSupply} optionValue="name"
                    optionLabel={e => {

                      if (e.disties !== undefined) {

                        return e?.disties[0]?.name;
                      } else {
                        return e.name
                      }
                    }}
                    placeholder="Tedarikçi"
                    showClear
                    onChange={e => {
                      let _searchRequest = {...searchRequest};
                      _searchRequest.supply = e.target.value;
                      setSearchRequest(_searchRequest);
                    }

                    }/>
        </div>

        <div className="p-field">
          <label htmlFor="name" className="p-sr-only">Ürün</label>
          <InputText id="name" value={searchRequest.modelName} type="text" placeholder="Ürün"
                     onChange={e => {
                       let _searchRequest = {...searchRequest};
                       _searchRequest.modelName = e.target.value;
                       setSearchRequest(_searchRequest);
                     }

                     }/>
        </div>

        <div className="p-field">
          <label htmlFor="barcode" className="p-sr-only">Barkod</label>
          <InputText id="barcode" value={searchRequest.barcode} type="text" placeholder="Barkod"
                     onChange={e => {
                       let _searchRequest = {...searchRequest};
                       _searchRequest.barcode = e.target.value;
                       setSearchRequest(_searchRequest);
                     }

                     }/>
        </div>

        <div className="p-field">
          <label htmlFor="supply" className="p-sr-only">Tedarikçi Durumu</label>
          <Dropdown id="supply" value={searchRequest.supplyStatus} options={["AÇIK", "KAPALI"]}
                    placeholder="Tedarikçi Durumu"
                    showClear
                    onChange={e => {
                      let _searchRequest = {...searchRequest};
                      _searchRequest.supplyStatus = e.target.value;
                      setSearchRequest(_searchRequest);
                    }

                    }/>
        </div>

        <div className="p-field">
          <label htmlFor="origin" className="p-sr-only">Menşei</label>
          <InputText id="origin" value={searchRequest.origin} type="text" placeholder="Menşei"
                     onChange={e => {
                       let _searchRequest = {...searchRequest};
                       _searchRequest.origin = e.target.value;
                       setSearchRequest(_searchRequest);
                     }

                     }/>
        </div>

        <div className="p-field">
          <label htmlFor="supply" className="p-sr-only">EOL</label>
          <Dropdown id="supply" value={searchRequest.eolValue}
                    options={["EVET", "HAYIR"]}
                    placeholder="EOL"
                    showClear
                    onChange={e => {
                      let _searchRequest = {...searchRequest};
                      if (e.target.value === "EVET") {
                        _searchRequest.eol = true;
                      } else if (e.target.value === "HAYIR") {
                        _searchRequest.eol = false;
                      } else {
                        _searchRequest.eol = undefined;

                      }
                      _searchRequest.eolValue = e.target.value;

                      setSearchRequest(_searchRequest);

                    }

                    }/>
        </div>


        <Button icon="pi pi-search" type="button" label="Ara"
                onClick={e => getAll({activePage: 0, itemsPerPage: paginationConfig.itemsPerPage})}/>


      </div>

    </div>
  )


  const actions = (rowData) => {
    let _label = rowData.labels?.find(value => value.labelStatus === 'DASHBOARD')
    return (
      <div>
        <Button style={{fontSize: 12}} label={_label ? "Ana Sayfadan Çıkar" : "Ana Sayfaya Ekle"}
                className={_label ? "p-button-danger" : "p-button-success"}
                onClick={e => {
                  if (_label) {
                    productService.removeToDashboard(rowData.id).then(res => {
                      toast.current?.show({
                        severity: "info",
                        summary: "Success",
                        detail: "Ürün Ana Sayfadan çıkartıldı.",
                        life: 3000,
                      });
                    }).catch(err => handleError(err, toast))
                    getAll({
                      ...paginationConfig,
                      activePage: paginationConfig.activePage,
                      itemsPerPage: paginationConfig.itemsPerPage
                    });

                  } else {
                    productService.addToDashboard(rowData.id).then(res => {
                      toast.current?.show({
                        severity: "success",
                        summary: "Success",
                        detail: "Ürün Ana Sayfaya eklendi.",
                        life: 3000,
                      });
                      getAll({
                        ...paginationConfig,
                        activePage: paginationConfig.activePage,
                        itemsPerPage: paginationConfig.itemsPerPage
                      });

                    }).catch(err => handleError(err, toast))
                  }
                }

                }/>
                  </div>
                  )
                }

  return (
    <>
      <Toast ref={toast}/>


      <div className="datatable-responsive-demo">
        <br/>
        <br/>


        <ResponsiveDataTable value={allProduct}
                             header={headersTemplate}
                             selectionMode="checkbox" selection={selectedProducts8}
                             paginationConfig={paginationConfig}
                             onActivePageChange={onActivePageChange}
                             emptyMessage="Ürün Bulunamadı.">

          <Column field="subCategory.category.name" header="Kategori"/>
          <Column field="subCategory.name" header="Alt Kategori"/>
          <Column field="brand.name" header="Marka"/>
          <Column field="supplyName" body={supplyTemplate} header="Tedarikçi"/>
          <Column field="name" body={modelNameTemplate} header="Ürün İsmi"/>
          <Column style={{width: 130}} field="barcode" header="Barkod"/>
          <Column field="supplyStatus" header="Tedarik Durumu"/>
          <Column field="origin" header="Menşei"/>
          <Column body={e => eolTemplate(e)} header="Eol"/>
          <Column body={actions} header="Durumu"/>

        </ResponsiveDataTable>
      </div>

    </>
  )
}
export default ProductAddedToDashboard
