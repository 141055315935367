import React, {useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import CanSellService from "./httpService";
import {InputText} from "primereact/inputtext";
import {MultiSelect} from "primereact/multiselect";
import {Button} from "primereact/button";
import {handleError} from "../../service/HandleErrorService";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import classNames from "classnames";
import {Dropdown} from "primereact/dropdown";

const CanSell = () => {
  const [allBrand, setAllBrand] = useState([]);
  const [allSupply, setAllSupply] = useState([]);
  const [selectSupplies, setSelectSupplies] = useState();
  const toast = useRef(null);
  const [selectCanSells, setSelectCanSells] = useState({});
  const [selectBrand, setSelectBrand] = useState();
  const [brand, setBrand] = useState();
  const [suppliesForUpdate, setSuppliesForUpdate] = useState([]);
  const canSellService = new CanSellService();
  const [allCanSell, setAllCanSell] = useState([]);

  const [upsertCanSellModal, setUpsertCanSellModal] = useState(false);

  useEffect(() => {
    getSupplies();
    getBrand();
    getAll();
  }, []);

  const getAll = () => {
    canSellService.getAllCansell({}).then(res => {
      setAllCanSell(res)
    }).catch(err => handleError(err, toast));
  };

  const getSupplies = () => {
    canSellService.getAllSupply().then(res => {

      let _classes = (res.map(value => {

        if (value?.disties) {
          value.name = value?.disties[0].name;
        }
        return {
          ...value
        }
      }));
      console.log(_classes);
      setAllSupply(_classes);

    });
  };

  const getBrand = async () => {
    await canSellService.getAllBrand().then(res => setAllBrand(res));
  };

  const updateCanSell = () => {
    let _selectCanSell = {};
    _selectCanSell.brand = brand;
    _selectCanSell.supplies = suppliesForUpdate;
    canSellService.update(_selectCanSell).then(res => {
      toast.current?.show({
        severity: "success",
        detail: 'İşlem Gerçekleştirildi.',
        life: 3000,
      });
      setSuppliesForUpdate(null)
      setBrand(null)

    }).catch(err => handleError(err, toast))
  };

  const upsertCanSellModalShow = () => {
    setSelectBrand(null);
    setSelectSupplies(null);
    setUpsertCanSellModal(true);
  };

  const upsertCanSellModalHide = () => {
    setUpsertCanSellModal(false);
  };

  const upsertCanSell = async (e) => {
    if (selectBrand != null) {
      if (selectSupplies != null && selectSupplies.length > 0) {
      let _selectCanSell = {};
      _selectCanSell.brandId = selectBrand.id;
      _selectCanSell.supplies = selectSupplies;

      await canSellService.upsert(_selectCanSell).then(res => {
        if (res) {
          toast.current?.show({
            severity: "success",
            detail: 'İşlem Onaylandı',
            life: 3000,
          });
          upsertCanSellModalHide();
        } else {
          toast.current?.show({
            severity: "warn",
            detail: 'Marka zaten tanımlıdır',
            life: 3000,
          });
        }
        setSelectBrand(null);
        setSelectSupplies(null);
        getAll();
      });
      getBrand();
      } else {
        toast.current.show({
          severity: 'warn',
          summary: 'Uyarı',
          detail: 'Tedarikçiler seçili olmalıdır.'
        });
      }
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Marka seçili olmalıdır.'
      });
    }
  };

  const changeBrand = (e) => {
    setBrand(e.target.value);

    canSellService.getCanSellByBrand(e.target.value).then(res => {
      let _classes = [];
      res.forEach(value => {

        if (value !== null) {
          _classes.push(value)
        }
      })
      setSuppliesForUpdate(_classes);
    }).catch(err => handleError(err, toast))
  };

  const actions = (rowData) => {
    return <div>
      <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
              onClick={e => {
                deleteCanSelll(e, rowData)
              }
              }/>
    </div>
  };

  const deleteCanSelll = (e, rowData) => {
    canSellService.deleteCanSell(rowData).then(res => {
      toast.current?.show({
        severity: "success",
        detail: 'İşlem Gerçekleştirildi.',
        life: 3000,
      });
      getAll();
    }).catch(err => handleError(err, toast))
  };

  const upperTemplate = (e, name) => {
    if (e[name]?.disties !== undefined) {
      return (<div>{e[name]?.disties[0]?.name}</div>)

    } else {
      return (
        <div>{e[name]?.name}</div>
      )
    }
  };

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card">
          <Button onClick={upsertCanSellModalShow} icon="pi pi-plus-circle" label="Yeni Kayıt"
                  className="p-button-success p-mr-2 p-mb-2"/>
          <ResponsiveDataTable value={allCanSell}
                               emptyMessage="Eşleşme Bulunamadı.">

            <Column body={e => upperTemplate(e, 'brand')} header="Marka"/>
            <Column body={e => upperTemplate(e, 'supply')} header="Tedarikçi"/>
            <Column body={e => actions(e)} header="Sil"/>
          </ResponsiveDataTable>
        </div>
      </div>
      <Dialog header={"Marka Tedarikçi Kaydet"} visible={upsertCanSellModal} style={{width: '450px'}}
              onHide={upsertCanSellModalHide}>
        <span className="p-float-label marginInput">
          <Dropdown id="marka" value={selectBrand}
                    onChange={(e) => setSelectBrand(e.value)}
                    options={allBrand}
                    style={{width: 280}} optionLabel={"name"}/>
          <label htmlFor="marka">Marka</label>
        </span>
        <span className="p-float-label marginInput">
          <MultiSelect id="tedarik" value={selectSupplies} style={{width: 280}}
                       options={allSupply}
                       onChange={(e) => setSelectSupplies(e.target.value)}
                       optionLabel="name"/>
          <label htmlFor="tedarik">Tedarikçiler</label>
        </span>
        <Button label="Kaydet" onClick={e => upsertCanSell(e)}/>
      </Dialog>
    </>
  )
};

export default CanSell;
