import * as Paths from "../../service/config";
import AspAxios from "../../service/AspAxios";

export function getActiveSupplies() {
  return new Promise((resolve, reject) => {
    AspAxios.get(Paths.getRealActiveSupply)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
