import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import {handleError} from "../../service/HandleErrorService";
import VoucherService from "./httpService";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {InputText} from "primereact/inputtext";
import classNames from "classnames";
import {InputNumber} from "primereact/inputnumber";
import {Checkbox} from "primereact/checkbox";

const AccessoryCampaignList = () => {

  const toast = useRef(null);
  const voucherService = new VoucherService();

  const [accessoryCampaignList, setAccessoryCampaignList] = useState([]);
  const [submitted, setSubmited] = useState(false);
  const [upsertCampaignButtonDisabled, setUpsertCampaignButtonDisabled] = useState(false);
  const [upsertCampaignModal, setUpsertCampaignModal] = useState(false);
  const [upsertCampaignHeader, setUpsertCampaignHeader] = useState(false);

  const [deleteCampaignButtonDisabled, setDeleteCampaignButtonDisabled] = useState(false);
  const [deleteCampaignModal, setDeleteCampaignModal] = useState(false);
  const [deleteCampaignModalHeader, setDeleteCampaignModalHeader] = useState(null);
  const [deleteCampaignModalMessage, setDeleteCampaignModalMessage] = useState(null);

  const [activeCampaignButtonDisabled, setActiveCampaignButtonDisabled] = useState(false);
  const [activeCampaignModal, setActiveCampaignModal] = useState(false);
  const [activeCampaignModalHeader, setActiveCampaignModalHeader] = useState(null);
  const [activeCampaignModalMessage, setActiveCampaignModalMessage] = useState(null);

  const [selectedAccessoryCampaignId, setSelectedAccessoryCampaignId] = useState(null);
  const [selectedVoucherCode, setSelectedVoucherCode] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState(null);
  const [selectedBillTemplate, setSelectedBillTemplate] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [selectedAmountCode, setSelectedAmountCode] = useState(null);
  const [selectedTermNumber, setSelectedTermNumber] = useState(null);
  const [selectedTermNumberCode, setSelectedTermNumberCode] = useState(null);
  const [selectedTermAmount, setSelectedTermAmount] = useState(null);
  const [selectedStockWarning, setSelectedStockWarning] = useState(null);
  const [selectedPassStockWarning, setSelectedPassStockWarning] = useState(null);

  useEffect(() => {
    getAll();
  }, []);

  const getAll = () => {
    voucherService.accessoryCampaignGetAllWithPassive().then(res => {
      console.log("accessoryCampaignGetAllWithPassive", res);
      setAccessoryCampaignList(res);
    }).catch(err => handleError(err, toast))
  };

  const addCampaignModalShow = () => {
    setUpsertCampaignHeader("Kampanya Oluşturma");
    setSelectedAccessoryCampaignId(null);
    setSelectedVoucherCode(null);
    setSelectedDescription(null);
    setSelectedBillTemplate(null);
    setSelectedAmount(null);
    setSelectedAmountCode(null);
    setSelectedTermNumber(null);
    setSelectedTermNumberCode(null);
    setSelectedTermAmount(null);
    setSelectedStockWarning(null);
    setSelectedPassStockWarning(null);
    setSubmited(false);
    setUpsertCampaignModal(true);
  };

  const editCampaignModalShow = (e) => {
    setSelectedAccessoryCampaignId(e.currentTarget.id);
    accessoryCampaignList.forEach(a => {
      if (a.id == e.currentTarget.id) {
        setSelectedVoucherCode(a.voucherCode);
        setSelectedDescription(a.description);
        setSelectedBillTemplate(a.billTemplate);
        setSelectedAmount(a.amount);
        setSelectedAmountCode(a.amountCode);
        setSelectedTermNumber(a.termNumber);
        setSelectedTermNumberCode(a.termNumberCode);
        setSelectedTermAmount(a.termAmount);
        setSelectedStockWarning(a.stockWarning);
        setSelectedPassStockWarning(a.passStockWarning);
      }
    });
    setUpsertCampaignHeader("Kampanya Düzenleme");
    setSubmited(false);
    setUpsertCampaignModal(true);
  };

  const upsertCampaignModalHide = () => {
    setUpsertCampaignModal(false);
  };

  const upsertAccessoryCampaign = () => {
    setSubmited(true);
    setUpsertCampaignButtonDisabled(true);
    if (selectedVoucherCode != null && selectedDescription != null && selectedBillTemplate != null && selectedAmount != null && selectedTermNumber != null && selectedTermAmount != null) {
      if (selectedVoucherCode.length == 7) {
        if (selectedAmountCode.length == 1) {
          if (selectedTermNumberCode.length == 1) {
            const request = {
              id: selectedAccessoryCampaignId,
              voucherCode: selectedVoucherCode,
              description: selectedDescription,
              billTemplate: selectedBillTemplate,
              amount: selectedAmount,
              amountCode: selectedAmountCode,
              termNumber: selectedTermNumber,
              termNumberCode: selectedTermNumberCode,
              termAmount: selectedTermAmount,
              stockWarning: selectedStockWarning,
              passStockWarning: selectedPassStockWarning
            };
            voucherService.upsertAccessoryCampaign(request).then(res => {
              console.log("upsertAccessoryCampaign", res);
              getAll();
              toast.current.show({
                severity: 'success',
                summary: 'Başarılı İşlem',
                detail: 'Kampanya kaydedildi.'
              });
            }).catch(err => {
              console.log(err);
              toast.current.show({
                severity: 'error',
                summary: 'Hata',
                detail: 'Kampanya kaydedilemedi.'
              });
            }).finally(() => {
              setUpsertCampaignModal(false);
              setUpsertCampaignButtonDisabled(false);
            });
          } else {
            toast.current.show({
              severity: 'warn',
              summary: 'Uyarı',
              detail: 'Taksit kodu 1 haneli olmalıdır.'
            });
            setUpsertCampaignButtonDisabled(false);
          }
        } else {
          toast.current.show({
            severity: 'warn',
            summary: 'Uyarı',
            detail: 'Tutar kodu 1 haneli olmalıdır.'
          });
          setUpsertCampaignButtonDisabled(false);
        }
      } else {
        toast.current.show({
          severity: 'warn',
          summary: 'Uyarı',
          detail: 'SN kodu 7 haneli olmalıdır.'
        });
        setUpsertCampaignButtonDisabled(false);
      }
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Kampanya kaydetmek için zorunlu alanlar dolu olmalıdır.'
      });
      setUpsertCampaignButtonDisabled(false);
    }
  };

  const deleteCampaignModalShow = (e) => {
    var voucherCode = null;
    accessoryCampaignList.forEach(a => {
      if (a.id == e.currentTarget.id) {
        voucherCode = a.voucherCode;
      }
    });
    setSelectedAccessoryCampaignId(e.target.id);
    setDeleteCampaignModalHeader("Kampanya Pasif Et");
    setDeleteCampaignModalMessage(voucherCode + " kodlu kampanyayı pasif duruma getirmek istediğinizden emin misiniz?");
    setDeleteCampaignModal(true);
  };

  const deleteCampaignModalHide = () => {
    setDeleteCampaignModal(false);
  };

  const deleteAccessoryCampaign = () => {
    setDeleteCampaignButtonDisabled(true);
    const request = {
      id: selectedAccessoryCampaignId
    };
    voucherService.passiveAccessoryCampaign(request).then(res => {
      console.log("passiveAccessoryCampaign", res);
      getAll();
      toast.current.show({
        severity: 'success',
        summary: 'Başarılı İşlem',
        detail: 'Kampanya pasif duruma getirildi.'
      });
    }).catch(err => {
      console.log(err);
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Kampanya pasif duruma getirilemedi.'
      });
    }).finally(() => {
      setDeleteCampaignModal(false);
      setDeleteCampaignButtonDisabled(false);
    });
  };

  const activeCampaignModalShow = (e) => {
    var voucherCode = null;
    accessoryCampaignList.forEach(a => {
      if (a.id == e.currentTarget.id) {
        voucherCode = a.voucherCode;
      }
    });
    setSelectedAccessoryCampaignId(e.target.id);
    setActiveCampaignModalHeader("Kampanya Aktif Et");
    setActiveCampaignModalMessage(voucherCode + " kodlu kampanyayı aktif duruma getirmek istediğinizden emin misiniz?");
    setActiveCampaignModal(true);
  };

  const activeCampaignModalHide = () => {
    setActiveCampaignModal(false);
  };

  const activeAccessoryCampaign = () => {
    setActiveCampaignButtonDisabled(true);
    const request = {
      id: selectedAccessoryCampaignId
    };
    voucherService.activeAccessoryCampaign(request).then(res => {
      console.log("activeAccessoryCampaign", res);
      getAll();
      toast.current.show({
        severity: 'success',
        summary: 'Başarılı İşlem',
        detail: 'Kampanya aktif duruma getirildi.'
      });
    }).catch(err => {
      console.log(err);
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Kampanya aktif duruma getirilemedi.'
      });
    }).finally(() => {
      setActiveCampaignModal(false);
      setActiveCampaignButtonDisabled(false);
    });
  };

  const renderFooter = () => {
    return (
      <div>
        <Button label="Hayır" icon="pi pi-times" onClick={deleteCampaignModalHide} className="p-button-text"/>
        <Button label="Evet" icon="pi pi-check" disabled={deleteCampaignButtonDisabled}
                onClick={deleteAccessoryCampaign} autoFocus/>
      </div>
    );
  };

  const renderFooterActiveCampaignModal = () => {
    return (
      <div>
        <Button label="Hayır" icon="pi pi-times" onClick={activeCampaignModalHide} className="p-button-text"/>
        <Button label="Evet" icon="pi pi-check" disabled={activeCampaignButtonDisabled}
                onClick={activeAccessoryCampaign} autoFocus/>
      </div>
    );
  };

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const responsiveColumnRight = (title, content) => {
    return (
      <div>
        <span className="p-column-title">{title}</span>
        <span style={{float: 'right'}}>{content}</span>
      </div>
    );
  };

  const statusTemplate = (data) => {
    return responsiveColumn("Aktiflik Durumu", <Checkbox style={{cursor: 'context-menu'}} disabled={true}
                                                         checked={data.status === 1}/>)
  };

  const voucherCodeTemplate = (data) => {
    return responsiveColumn("Seri/Şifre’de Kullanılan SN Kod", data?.voucherCode)
  };

  const descriptionTemplate = (data) => {
    return responsiveColumn("Açıklama", data?.description)
  };

  const billTemplate = (data) => {
    return responsiveColumn("Kampanya Fatura Şablonu", data?.billTemplate)
  };

  const amountTemplate = (data) => {
    return responsiveColumnRight("Tutarı", data?.amount)
  };

  const amountCodeTemplate = (data) => {
    return responsiveColumnRight("Seri/Şifre’de Kullanılan Tutar Kod", data?.amountCode)
  };

  const termNumberTemplate = (data) => {
    return responsiveColumnRight("Taksit Sayısı", data?.termNumber)
  };

  const termNumberCodeTemplate = (data) => {
    return responsiveColumnRight("Seri/Şifre’de Kullanılan Taksit Kod", data?.termNumberCode)
  };

  const termAmountTemplate = (data) => {
    return responsiveColumnRight("Taksit Tutarı", data?.termAmount)
  };

  const stockWarningTemplate = (data) => {
    return responsiveColumnRight("Seri Numara Kritik Stok Seviyesi", data?.stockWarning)
  };

  const passStockWarningTemplate = (data) => {
    return responsiveColumnRight("Şifre Kritik Stok Seviyesi", data?.passStockWarning)
  };

  const accessoryCampaignButtonTemplate = (data) => {
    return (
      <div className="p-d-flex p-justify-even">
        <Button id={data.id} type="button" className="p-button-rounded" icon="pi pi-pencil" tooltip="Düzenle"
                tooltipOptions={{position: 'left', mouseTrack: true, mouseTrackTop: 15}} onClick={editCampaignModalShow}/>
        {data.status === 1 ? (
            <Button id={data.id} type="button" className="p-button-rounded p-button-danger" icon="pi pi-eye-slash" tooltip="Pasif Et"
                    tooltipOptions={{position: 'left', mouseTrack: true, mouseTrackTop: 15}} onClick={deleteCampaignModalShow}/>
        ) : (
          <Button id={data.id} type="button" className="p-button-rounded p-button-success" icon="pi pi-eye" tooltip="Aktif Et"
                  tooltipOptions={{position: 'left', mouseTrack: true, mouseTrackTop: 15}} onClick={activeCampaignModalShow}/>
        )}
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card">
          <Button onClick={addCampaignModalShow} icon="pi pi-plus-circle" label="Yeni Kampanya"
                  className="p-button-success p-mr-2 p-mb-2"/>
          <ResponsiveDataTable value={accessoryCampaignList} className="p-datatable-responsive-demo"
                               style={{fontSize: '13px'}}
                               header="Kampanya Listesi"
                               paginator rows={10} rowsPerPageOptions={[10, 20, 30]}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} arası gösteriliyor">
            <Column body={statusTemplate} header="Aktiflik Durumu"/>
            <Column body={voucherCodeTemplate} header="Seri/Şifre’de Kullanılan SN Kod"/>
            <Column body={descriptionTemplate} header="Açıklama"/>
            <Column body={billTemplate} header="Kampanya Fatura Şablonu"/>
            <Column body={amountTemplate} header="Tutarı" headerStyle={{textAlign: 'right'}}/>
            <Column body={amountCodeTemplate} header="Seri/Şifre’de Kullanılan Tutar Kod" headerStyle={{textAlign: 'right'}}/>
            <Column body={termNumberTemplate} header="Taksit Sayısı" headerStyle={{textAlign: 'right'}}/>
            <Column body={termNumberCodeTemplate} header="Seri/Şifre’de Kullanılan Taksit Kod" headerStyle={{textAlign: 'right'}}/>
            <Column body={termAmountTemplate} header="Taksit Tutarı" headerStyle={{textAlign: 'right'}}/>
            <Column body={stockWarningTemplate} header="Seri Numara Kritik Stok Seviyesi" headerStyle={{textAlign: 'right'}}/>
            <Column body={passStockWarningTemplate} header="Şifre Kritik Stok Seviyesi" headerStyle={{textAlign: 'right'}}/>
            <Column body={accessoryCampaignButtonTemplate}
                    bodyStyle={{textAlign: 'center', overflow: 'visible', justifyContent: 'center'}}/>
          </ResponsiveDataTable>
        </div>
      </div>
      <Dialog header={upsertCampaignHeader} visible={upsertCampaignModal} style={{width: '450px'}}
              onHide={upsertCampaignModalHide}>
        <span className="p-float-label marginInput" style={{marginBottom: 0}}>
          <InputText id="voucherCode" value={selectedVoucherCode}
                     onChange={(e) => setSelectedVoucherCode(e.target.value)}
                     style={{width: 280}}
                     className={classNames({'p-invalid': submitted && !selectedVoucherCode})}/>
                     <label htmlFor="voucherCode">SN Kod</label>
        </span>
        <span className="p-float-label marginInput" style={{marginBottom: 0}}>
          <InputText id="description" value={selectedDescription}
                     onChange={(e) => setSelectedDescription(e.target.value)}
                     style={{width: 280}}
                     className={classNames({'p-invalid': submitted && !selectedDescription})}/>
                     <label htmlFor="description">Açıklama</label>
        </span>
        <span className="p-float-label marginInput" style={{marginBottom: 0}}>
          <InputText id="billTemplate" value={selectedBillTemplate}
                     onChange={(e) => setSelectedBillTemplate(e.target.value)}
                     style={{width: 280}}
                     className={classNames({'p-invalid': submitted && !selectedBillTemplate})}/>
                     <label htmlFor="billTemplate">Kampanya Fatura Şablonu</label>
        </span>
        <span className="p-float-label marginInput" style={{marginBottom: 0}}>
          <InputNumber id="amount" value={selectedAmount}
                       onChange={(e) => setSelectedAmount(e.value)}
                       style={{width: 280}}
                       className={classNames({'p-invalid': submitted && !selectedAmount})}/>
                     <label htmlFor="amount">Tutarı</label>
        </span>
        <span className="p-float-label marginInput" style={{marginBottom: 0}}>
          <InputText id="amountCode" value={selectedAmountCode}
                     onChange={(e) => setSelectedAmountCode(e.target.value)}
                     style={{width: 280}}
                     className={classNames({'p-invalid': submitted && !selectedAmountCode})}/>
                     <label htmlFor="amountCode">Tutar Kod</label>
        </span>
        <span className="p-float-label marginInput" style={{marginBottom: 0}}>
          <InputNumber id="termNumber" value={selectedTermNumber}
                       onChange={(e) => setSelectedTermNumber(e.value)}
                       style={{width: 280}}
                       className={classNames({'p-invalid': submitted && !selectedTermNumber})}/>
                     <label htmlFor="termNumber">Taksit Sayısı</label>
        </span>
        <span className="p-float-label marginInput" style={{marginBottom: 0}}>
          <InputText id="termNumberCode" value={selectedTermNumberCode}
                     onChange={(e) => setSelectedTermNumberCode(e.target.value)}
                     style={{width: 280}}
                     className={classNames({'p-invalid': submitted && !selectedTermNumberCode})}/>
                     <label htmlFor="termNumberCode">Taksit Kod</label>
        </span>
        <span className="p-float-label marginInput" style={{marginBottom: 0}}>
          <InputNumber id="termAmount" value={selectedTermAmount}
                     onChange={(e) => setSelectedTermAmount(e.value)}
                     style={{width: 280}} minFractionDigits={2} maxFractionDigits={2}
                     className={classNames({'p-invalid': submitted && !selectedTermAmount})}/>
                     <label htmlFor="termAmount">Taksit Tutarı</label>
        </span>
        <span className="p-float-label marginInput" style={{marginBottom: 0}}>
          <InputNumber id="stockWarning" value={selectedStockWarning}
                     onChange={(e) => setSelectedStockWarning(e.value)}
                     style={{width: 280}}
                     className={classNames({'p-invalid': submitted && !selectedStockWarning})}/>
                     <label htmlFor="stockWarning">Seri Numara Kritik Stok Seviyesi</label>
        </span>
        <span className="p-float-label marginInput" style={{marginBottom: 0}}>
          <InputNumber id="passStockWarning" value={selectedPassStockWarning}
                     onChange={(e) => setSelectedPassStockWarning(e.value)}
                     style={{width: 280}}
                     className={classNames({'p-invalid': submitted && !selectedPassStockWarning})}/>
                     <label htmlFor="passStockWarning">Şifre Kritik Stok Seviyesi</label>
        </span>
        <Button onClick={upsertAccessoryCampaign} style={{width: 280, marginTop: 20}}
                disabled={upsertCampaignButtonDisabled}
                label="Kaydet" className="p-mr-2 p-mb-2"/>
      </Dialog>
      <Dialog header={deleteCampaignModalHeader} visible={deleteCampaignModal} modal style={{width: '400px'}}
              footer={renderFooter('displayConfirmation')} onHide={deleteCampaignModalHide}>
        <div className="confirmation-content">
          <span>{deleteCampaignModalMessage}</span>
        </div>
      </Dialog>
      <Dialog header={activeCampaignModalHeader} visible={activeCampaignModal} modal style={{width: '400px'}}
              footer={renderFooterActiveCampaignModal('displayConfirmation')} onHide={activeCampaignModalHide}>
        <div className="confirmation-content">
          <span>{activeCampaignModalMessage}</span>
        </div>
      </Dialog>
    </>
  )

};

export default AccessoryCampaignList;
