import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import ResellerService from "./httpService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";

const ResellerList = () => {

  const distyAdmin = JSON.parse(localStorage.getItem('user')).user?.roles.includes("DISTY_ADMIN");
  const distyCode = JSON.parse(localStorage.getItem('user')).user?.disty?.code;
  const toast = useRef(null);
  const resellerService = new ResellerService();

  const [loading, setLoading] = useState(false);
  const [searchCriteriaLazyLoading, setSearchCriteriaLazyLoading] = useState(false);
  const [searchCriteriaProgressing, setSearchCriteriaProgressing] = useState(false);
  const [resellerList, setResellerList] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState({});
  const [selectedDistyCode, setSelectedDistyCode] = useState(null);

  const [detailModal, setDetailModal] = useState(false);
  const [selectedReseller, setSelectedReseller] = useState(false);

  const [exportExcelDisabled, setExportExcelDisabled] = useState(null);

  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  useEffect(() => {
    if (distyAdmin) {
      setSelectedDistyCode(distyCode);
    } else {
      searchReseller({...paginationConfig, activePage: 0});
    }
  }, []);

  useEffect(() => {
    setSearchCriteriaLazyLoading(true);
  }, [selectedDistyCode, searchCriteria]);

  useEffect(() => {
    if (searchCriteriaLazyLoading) {
      setTimeout(() => {
        setSearchCriteriaProgressing(true);
        setSearchCriteriaLazyLoading(false);
      }, 2000);
    }
  }, [searchCriteriaLazyLoading]);

  useEffect(() => {
    if (searchCriteriaProgressing) {
      searchReseller({...paginationConfig, activePage: 0});
      setSearchCriteriaProgressing(false);
    }
  }, [searchCriteriaProgressing]);

  const searchReseller = (pageState) => {
    setLoading(true);
    var sort = "";
    searchCriteria.distyCode = selectedDistyCode;
    if (searchCriteria.sortField != null && searchCriteria.sortOrder != null) {
      sort += searchCriteria.sortField;
      if (searchCriteria.sortOrder == 1) {
        sort += ",asc";
      } else {
        sort += ",desc";
      }
    } else {
      sort += "code,desc";
    }

    resellerService.resellerSearchLazy(searchCriteria, pageState.itemsPerPage, pageState.activePage, sort).then(res => {
      console.log("searchReseller", res.content);
      if (res.content && res.content.length > 0) {
        setResellerList(res.content);

        pageState.totalRecords = res.totalElements;
        pageState.first = pageState.itemsPerPage * pageState.activePage;
        setPaginationConfig({
          ...pageState,
        });
      } else {
        setResellerList([]);
      }
    }).catch(err => {
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Satış bilgisi getirme başarısız.'
      });
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  };

  const exportExcel = () => {
    setExportExcelDisabled(true);

    resellerService.resellerSearchEager(searchCriteria).then(res => {
      console.log("searchProductSaleEager", res);
      import('xlsx').then(xlsx => {
        var excelList = [];
        res.forEach(a => {
          excelList.push({
            "Bayi Kodu": a.code,
            "Ana Bayi Kodu": a.parentReseller?.code,
            "Bayi Adı": a.name,
            "Distribütör Kodu": a.disty?.code,
            "Bayi Tipi": a.resellerType,
            "Bayi Durumu": a.resellerActive ? "Aktif" : "Pasif",
            "Bayi Eposta": a.email,
            "Bayi Tel": a.phoneNumber,
            "Bayi Ana Bölge": a.mainArea,
            "Bayi Bölge": a.area,
            "Bayi İl": a.resellerProvince?.name,
            "Bayi İlçe": a.resellerDistrict?.name,
            "Bayi Adres": a.addressText
          })
        });
        const worksheet = xlsx.utils.json_to_sheet(excelList);
        var wscols = [
          {width: 15},
          {width: 15},
          {width: 60},
          {width: 15},
          {width: 15},
          {width: 15},
          {width: 30},
          {width: 15},
          {width: 30},
          {width: 30},
          {width: 18},
          {width: 18},
          {width: 70}
        ];
        worksheet["!cols"] = wscols;
        const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
        saveAsExcelFile(excelBuffer, 'Bayi Listesi');
      });
    }).catch(err => {
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Bayi bilgisi getirme başarısız.'
      });
      console.log(err);
    }).finally(() => {
      setExportExcelDisabled(false);
    });
  };

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    searchReseller(pageState);
  };

  const onFilter = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const onSort = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const detailModalShow = (e) => {
    setDetailModal(true);
    resellerList.forEach(s => {
      if (s.id == e.currentTarget.id) {
        setSelectedReseller(s);
      }
    });
  };

  const detaillModalHide = () => {
    setDetailModal(false);
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const distyCodeFilter = <InputText value={selectedDistyCode} onChange={(e) => setSelectedDistyCode(e.target.value)}
                                     disabled={distyAdmin} className="p-column-filter"/>;

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const resellerCodeTemplate = (data) => {
    return responsiveColumn("Bayi Kodu", data?.code)
  };

  const parentResellerCodeTemplate = (data) => {
    return responsiveColumn("Ana Bayi Kodu", data?.parentReseller?.code)
  };

  const resellerNameTemplate = (data) => {
    return responsiveColumn("Bayi Adı", data?.name)
  };

  const distyCodeTemplate = (data) => {
    return responsiveColumn("Distribütör Kodu", data?.disty?.code)
  };

  const accessoryCampaignSerialTitleButtonTemplate = (data) => {
    return (
      <div className="p-d-flex p-justify-even">
        <Button id={data.id} type="button" className="p-button-sm" label="Detay"
                onClick={detailModalShow}/>
      </div>
    );
  };

  const header =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>Bayi Listesi</div>
        <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
                disabled={exportExcelDisabled}
                onClick={exportExcel}/>
      </div>
    </div>;

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card">
          <ResponsiveDataTable
            lazy
            header={header}
            value={resellerList}
            paginationConfig={paginationConfig}
            onActivePageChange={onActivePageChange}
            onFilter={onFilter}
            filters={searchCriteria.filters}
            onSort={onSort}
            sortField={searchCriteria.sortField}
            sortOrder={searchCriteria.sortOrder}
            loading={loading}
          >
            <Column field="code" body={resellerCodeTemplate} header="Bayi Kodu" filter={true}
                    filterMatchMode={"contains"} sortable/>
            <Column field="parentResellerCode" body={parentResellerCodeTemplate} header="Ana Bayi Kodu" filter={true}
                    filterMatchMode={"contains"}/>
            <Column field="name" body={resellerNameTemplate} header="Bayi Adı" filter={true}
                    filterMatchMode={"contains"} sortable/>
            <Column field="distyCode" body={distyCodeTemplate} header="Distribütör Kodu" filter={true}
                    filterElement={distyCodeFilter} filterMatchMode={"contains"}/>
            <Column body={accessoryCampaignSerialTitleButtonTemplate} headerStyle={{width: "12%", textAlign: "center"}}
                    bodyStyle={{textAlign: 'center', overflow: 'visible', justifyContent: 'center'}}/>
          </ResponsiveDataTable>
        </div>
        <Dialog header="Bayi Detay" visible={detailModal} modal style={{width: '500px'}}
                onHide={detaillModalHide}>
          <div className="datatable-responsive-demo">
            <div className="p-grid">
              <div className="p-field p-col-12">
                <p className="marginParagraph"><b>Bayi Kodu:</b> {selectedReseller?.code}</p>
                {selectedReseller?.parentReseller?.code &&
                <p className="marginParagraph"><b>Ana Bayi Kodu:</b> {selectedReseller?.parentReseller?.code}</p>}
                <p className="marginParagraph"><b>Bayi Adı:</b> {selectedReseller?.name}</p>
                <p className="marginParagraph"><b>Distribütör Kodu:</b> {selectedReseller?.disty?.code}</p>
                <p className="marginParagraph"><b>Bayi Tipi:</b> {selectedReseller?.resellerType}</p>
                <p className="marginParagraph"><b>Bayi Durumu:</b> {selectedReseller?.resellerActive ? "Aktif" : "Pasif"}</p>
                <p className="marginParagraph"><b>Bayi Eposta:</b> {selectedReseller?.email}</p>
                <p className="marginParagraph"><b>Bayi Tel:</b> {selectedReseller?.phoneNumber}</p>
                <p className="marginParagraph"><b>Bayi Ana Bölge:</b> {selectedReseller?.mainArea}</p>
                <p className="marginParagraph"><b>Bayi Bölge:</b> {selectedReseller?.area}</p>
                <p className="marginParagraph"><b>Bayi İl:</b> {selectedReseller?.resellerProvince?.name}</p>
                <p className="marginParagraph"><b>Bayi İlçe:</b> {selectedReseller?.resellerDistrict?.name}</p>
                <p className="marginParagraph"><b>Bayi Adres:</b> {selectedReseller?.addressText}</p>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  )

};

export default ResellerList;
