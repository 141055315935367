import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import ReportingService from "./httpService";
import {SelectButton} from "primereact/selectbutton";
import {Chart} from "primereact/chart";
import {ProgressSpinner} from "primereact/progressspinner";
import {Calendar} from "primereact/calendar";
import {handleError} from "../../service/HandleErrorService";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import "../../assets/css/ResponsiveDataTable.css"

const DailySaleReport = () => {

  const toast = useRef(null);
  const reportingService = new ReportingService();

  const [searchCriteriaLazyLoading, setSearchCriteriaLazyLoading] = useState(false);
  const [searchCriteriaProgressing, setSearchCriteriaProgressing] = useState(false);

  const [allSales, setAllSales] = useState(null);
  const [salePrice, setSalePrice] = useState(null);
  const [saleQuantity, setSaleQuantity] = useState(true);
  const [optionsLoading, setOptionsLoading] = useState(true);

  const [selectedTimeBegin, setSelectedTimeBegin] = useState(null);
  const [selectedTimeEnd, setSelectedTimeEnd] = useState(null);
  const [selectedSaleReportSaleStatus, setSelectedSaleReportSaleStatus] = useState("SATIS");
  const [selectedSaleReportType, setSelectedSaleReportType] = useState("AKSESUAR_SATIS");
  const [selectedProductSaleType, setSelectedProductSaleType] = useState(null);
  const [selectedDisty, setSelectedDisty] = useState(null);
  const [selectedTtCrm, setSelectedTtCrm] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedResellerType, setSelectedResellerType] = useState(null);
  const [selectedResellerMainArea, setSelectedResellerMainArea] = useState(null);

  const [getSaleReportButtonDisabled, setSaleReportButtonDisabled] = useState(false);

  const [chartExist, setChartExist] = useState(false);
  const [chartLoading, setChartLoading] = useState(true);

  const [saleQuantityChartData, setSaleQuantityChartData] = useState(null);
  const [salePriceChartData, setSalePriceChartData] = useState(null);

  const [saleReportSaleStatusOptions, setSaleReportSaleStatusOptions] = useState(
    [
      {value: "SATIS", name: "Satış İşlemi"},
      {value: "IPTAL", name: "İptal İşlemi"}
    ]
  );

  const [saleReportTypeOptions, setSaleReportTypeOptions] = useState(
    [
      {value: "AKSESUAR_SATIS", name: "Aksesuar Satışı Bazında"},
      {value: "SATIS_ISLEMI", name: "Satış İşlemi Bazında"}
    ]
  );

  const [productSaleTypeOptions, setProductSaleTypeOptions] = useState(
    [
      {value: "TEMLIK", name: "Kampanyalı"},
      {value: "VANILLA", name: "Peşin-Kredi Kartlı"}
    ]
  );

  const [distyOptions, setDistyOptions] = useState([]);
  const [ttCrmOptions, setTtCrmOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [resellerTypeOptions, setResellerTypeOptions] = useState([]);
  const [resellerMainAreaOptions, setResellerMainAreaOptions] = useState([]);

  useEffect(() => {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    setSelectedTimeEnd(d);
    var z = new Date();
    z.setDate(z.getDate() - 31);
    setSelectedTimeBegin(z);

    reportingService.getSaleReportOptions().then(res => {
      console.log("getSaleReportOptions", res);

      let ttCrmList = [];
      res?.ttCrmList?.forEach(r => {
        ttCrmList.push({value: r.id, name: r.name});
      });
      setTtCrmOptions(ttCrmList);

      let distyList = [];
      res?.distyList?.forEach(r => {
        distyList.push({value: r.id, name: r.name});
      });
      setDistyOptions(distyList);

      let brandList = [];
      res?.brandList?.forEach(r => {
        brandList.push({value: r.id, name: r.name});
      });
      setBrandOptions(brandList);

      let resellerTypeList = [];
      res?.resellerTypeList?.forEach(r => {
        resellerTypeList.push({value: r, name: r});
      });
      setResellerTypeOptions(resellerTypeList);

      let resellerMainAreaList = [];
      res?.resellerMainAreaList?.forEach(r => {
        resellerMainAreaList.push({value: r, name: r});
      });
      setResellerMainAreaOptions(resellerMainAreaList);
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      setOptionsLoading(false);
    });
  }, []);

  useEffect(() => {
    setChartLoading(true);
    setSearchCriteriaLazyLoading(true);
  }, [selectedTimeBegin, selectedTimeEnd, selectedSaleReportSaleStatus, selectedSaleReportType, selectedProductSaleType, selectedDisty, selectedTtCrm, selectedBrand, selectedResellerType, selectedResellerMainArea]);

  useEffect(() => {
    if (searchCriteriaLazyLoading) {
      setTimeout(() => {
        setSearchCriteriaProgressing(true);
        setSearchCriteriaLazyLoading(false);
      }, 2000);
    }
  }, [searchCriteriaLazyLoading]);

  useEffect(() => {
    if (searchCriteriaProgressing) {
      getSaleReport();
      setSearchCriteriaProgressing(false);
    }
  }, [searchCriteriaProgressing]);

  const getSaleReport = () => {
    if (selectedTimeBegin != null && selectedTimeEnd != null) {
      if (selectedTimeBegin.getTime() <= selectedTimeEnd.getTime()) {
        if (selectedSaleReportType != null) {
          if (selectedSaleReportSaleStatus != null) {
            setChartExist(true);
            setChartLoading(true);
            setSaleReportButtonDisabled(true);

            const request = {
              dateStart: selectedTimeBegin.toLocaleDateString("tr-TR"),
              dateEnd: selectedTimeEnd.toLocaleDateString("tr-TR"),
              saleReportSaleStatus: selectedSaleReportSaleStatus,
              saleReportType: selectedSaleReportType
            };

            if(selectedProductSaleType != null && selectedProductSaleType.length > 0){
              request.productSaleTypeList = selectedProductSaleType;
            }
            if(selectedTtCrm != null && selectedTtCrm.length > 0){
              request.ttCrmIdList = selectedTtCrm;
            }
            if(selectedBrand != null && selectedBrand.length > 0){
              request.brandIdList = selectedBrand;
            }
            if(selectedDisty != null && selectedDisty.length > 0){
              request.distyIdList = selectedDisty;
            }
            if(selectedResellerType != null && selectedResellerType.length > 0){
              request.resellerTypeList = selectedResellerType;
            }
            if(selectedResellerMainArea != null && selectedResellerMainArea.length > 0){
              request.resellerMainAreaList = selectedResellerMainArea;
            }

            reportingService.getSaleReport(request).then(res => {
              console.log("reportingService", res);
              setAllSales(res.reportDailyDataList);
              setSalePrice(res.salePrice);
              setSaleQuantity(res.saleQuantity);

              let saleQuantityData = {
                labels: [],
                datasets: []
              };
              res?.reportDailyDataList?.forEach(r => {
                saleQuantityData.labels.push(r.calculationDayString);
              });

              let salePricetData = {
                labels: [],
                datasets: []
              };
              res?.reportDailyDataList?.forEach(r => {
                salePricetData.labels.push(r.calculationDayString);
              });

              saleQuantityData.datasets.push({
                backgroundColor: '#42A5F5',
                label: 'Satış Adeti',
                data: []
              });

              res?.reportDailyDataList?.forEach(r => {
                saleQuantityData.datasets[0].data.push(
                  r.saleQuantity
                );
              });

              salePricetData.datasets.push({
                backgroundColor: '#42A5F5',
                label: 'Satış Tutarı',
                data: []
              });

              res?.reportDailyDataList?.forEach(r => {
                salePricetData.datasets[0].data.push(
                  r.salePrice
                );
              });

              setSaleQuantityChartData(saleQuantityData);
              setSalePriceChartData(salePricetData);
            }).catch(err => handleError(err, toast)).finally(() => {
              setChartLoading(false);
              setSaleReportButtonDisabled(false);
            });
          } else {
            setChartExist(false);
            toast.current.show({
              severity: 'warn',
              summary: 'Uyarı',
              detail: 'Raporlanacak işlem durumu seçili olmalıdır.'
            });
          }
        } else {
          setChartExist(false);
          toast.current.show({
            severity: 'warn',
            summary: 'Uyarı',
            detail: 'Raporlama tipi seçili olmalıdır.'
          });
        }
      } else {
        setChartExist(false);
        toast.current.show({
          severity: 'warn',
          summary: 'Uyarı',
          detail: 'Başlangıç tarihi bitiş tarihinden önce olmalıdır.'
        });
      }
    } else {
      setChartExist(false);
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Tarih alanları dolu olmalıdır.'
      });
    }
  };


  const exportExcel = () => {
    import('xlsx').then(xlsx => {
      var excelList = [];
      allSales.forEach(c => {
        if(selectedSaleReportSaleStatus == "SATIS"){
          excelList.push({
            "": c.calculationDayString,
            "Satış Adeti": c.saleQuantity,
            "Satış Tutarı": c.salePrice
          })
        } else {
          excelList.push({
            "": c.calculationDayString,
            "İptal Adeti": c.saleQuantity,
            "İptal Tutarı": c.salePrice
          })
        }
      });
      if(selectedSaleReportSaleStatus == "SATIS"){
        excelList.push({
          "": "Toplam",
          "Satış Adeti": saleQuantity,
          "Satış Tutarı": salePrice
        })
      } else {
        excelList.push({
          "": "Toplam",
          "İptal Adeti": saleQuantity,
          "İptal Tutarı": salePrice
        })
      }
      const worksheet = xlsx.utils.json_to_sheet(excelList);
      var wscols = [
        {width: 12},
        {width: 12},
        {width: 12}
      ];
      worksheet["!cols"] = wscols;
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      saveAsExcelFile(excelBuffer, selectedSaleReportSaleStatus == "SATIS" ? "Satış Adetleri Ve Tutarları Tablosu" : "İptal Adetleri Ve Tutarları Tablosu");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const addCommas = num => num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const addCommasWithToFixed = num => num?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const calculationsHeader =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>{selectedSaleReportSaleStatus == "SATIS" ? "Satış" : "İptal"} Adetleri Ve Tutarları Tablosu</div>
        <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
                onClick={exportExcel} style={{marginRight: 5}}/>
      </div>
    </div>;

  let saleFooterGroup = <ColumnGroup>
    <Row>
      <Column footer={"Toplam"} footerStyle={{textAlign: 'left'}}/>
      <Column footer={addCommas(saleQuantity)} footerStyle={{textAlign: 'right'}}/>
      <Column footer={addCommasWithToFixed(salePrice)} footerStyle={{textAlign: 'right'}}/>
    </Row>
  </ColumnGroup>;

  const saleQuantityBody = (rowData) => {
    return <div>{addCommas(rowData.saleQuantity)}</div>
  };

  const salePriceBody = (rowData) => {
    return <div>{addCommasWithToFixed(rowData.salePrice)}</div>
  };

  let _row = 0;
  const rowClass = (data) => {
    _row = _row + 1;
    return {
      whiteSmokeRow: _row % 2 === 0,
    };
  };

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          {optionsLoading ?
            <div className="p-col-12 p-md-5" style={{alignSelf: 'center', width: 400}}>
              <div style={{display: 'flex', alignItems: 'center'}}><ProgressSpinner
                style={{width: '40px', height: '40px', marginRight: 5, marginLeft: 10}}
                strokeWidth="8" fill="#EEEEEE"
                animationDuration=".5s"/><b>Yükleniyor. Lütfen bekleyiniz.</b>
              </div>
            </div> :
            <>
              <div className="p-col-12 p-md-2 p-fluid">
                <h5>Tarihi Aralığı*</h5>
                <Calendar id="baslangic" showIcon dateFormat={'dd.mm.yy'}
                          value={selectedTimeBegin} showButtonBar
                          onChange={(e) => setSelectedTimeBegin(e.value)}
                          placeholder="Tarih Başlangıç"/>
              </div>
              <div className="p-col-12 p-md-2 p-fluid"><br className="small_hidden"/><br className="small_hidden"/>
                <Calendar id="bitis" showIcon dateFormat={'dd.mm.yy'} style={{marginTop: -3}}
                          value={selectedTimeEnd} showButtonBar
                          onChange={(e) => setSelectedTimeEnd(e.value)}
                          placeholder="Tarih Bitiş"/>
              </div>
              <div className="p-col-12 p-md-4">
                <h5>Raporlama Tipi*</h5>
                <SelectButton optionLabel="name" optionValue="value" value={selectedSaleReportType}
                              options={saleReportTypeOptions} onChange={(e) => setSelectedSaleReportType(e.value)}/>
              </div>
              <div className="p-col-12 p-md-4">
                <h5>İşlem Durumu*</h5>
                <SelectButton optionLabel="name" optionValue="value" value={selectedSaleReportSaleStatus}
                              options={saleReportSaleStatusOptions}
                              onChange={(e) => setSelectedSaleReportSaleStatus(e.value)}/>
              </div>
              <div className="p-col-12"/>
              <div className="p-col-12 p-md-4">
                <h5>İşlem Tipi (Çoklu Seçim)</h5>
                <SelectButton optionLabel="name" optionValue="value" value={selectedProductSaleType} multiple
                              options={productSaleTypeOptions} onChange={(e) => setSelectedProductSaleType(e.value)}/>
              </div>
              <div className="p-col-12 p-md-4">
                <h5>Distribütör (Çoklu Seçim)</h5>
                <SelectButton optionLabel="name" optionValue="value" value={selectedDisty} multiple
                              options={distyOptions} onChange={(e) => setSelectedDisty(e.value)}/>
              </div>
              <div className="p-col-12 p-md-4">
                <h5>TT CRM (Çoklu Seçim)</h5>
                <SelectButton optionLabel="name" optionValue="value" value={selectedTtCrm} multiple
                              options={ttCrmOptions} onChange={(e) => setSelectedTtCrm(e.value)}/>
              </div>
              <div className="p-col-12"/>
              <div className="p-col-12 p-md-4">
                <h5>Bayi Tipi (Çoklu Seçim)</h5>
                <SelectButton optionLabel="name" optionValue="value" value={selectedResellerType} multiple
                              options={resellerTypeOptions} onChange={(e) => setSelectedResellerType(e.value)}/>
              </div>
              <div className="p-col-12 p-md-8">
                <h5>Marka (Çoklu Seçim)</h5>
                <SelectButton optionLabel="name" optionValue="value" value={selectedBrand} multiple
                              options={brandOptions} onChange={(e) => setSelectedBrand(e.value)}/>
              </div>
              <div className="p-col-12"/>
              <div className="p-col-12">
                <h5>Bayi Ana Bölge (Çoklu Seçim)</h5>
                <SelectButton optionLabel="name" optionValue="value" value={selectedResellerMainArea} multiple
                              options={resellerMainAreaOptions} onChange={(e) => setSelectedResellerMainArea(e.value)}/>
              </div>
              {chartExist &&
              <>
                <div className="p-col-12">
                  <hr style={{borderWidth: 2}}/>
                </div>
                {chartLoading ?
                  <div className="p-col-12 p-md-5" style={{alignSelf: 'center', width: 400}}>
                    <div style={{display: 'flex', alignItems: 'center'}}><ProgressSpinner
                      style={{width: '40px', height: '40px', marginRight: 5, marginLeft: 10}}
                      strokeWidth="8" fill="#EEEEEE"
                      animationDuration=".5s"/><b>Yükleniyor. Lütfen bekleyiniz.</b>
                    </div>
                  </div> :
                  <>
                    <div className="p-col-12">
                      <h5 style={{margin: 0}}>{selectedSaleReportSaleStatus == "SATIS" ? "Satış" : "İptal"} Adetleri
                        Grafiği</h5>
                      <Chart type="bar" data={saleQuantityChartData} height={320}
                             options={{maintainAspectRatio: false}}/>
                    </div>
                    <div className="p-col-12"/>
                    <div className="p-col-12">
                      <h5 style={{margin: 0}}>{selectedSaleReportSaleStatus == "SATIS" ? "Satış" : "İptal"} Tutarları
                        Grafiği</h5>
                      <Chart type="bar" data={salePriceChartData} height={320} options={{maintainAspectRatio: false}}/>
                    </div>
                    <div className="p-col-12">
                      <DataTable rowClassName={rowClass} value={allSales} footerColumnGroup={saleFooterGroup}
                                 header={calculationsHeader} style={{border: '2px solid lightgray'}}>
                        <Column field="calculationDayString" bodyStyle={{paddingTop: 0, paddingBottom: 0}}/>
                        <Column body={saleQuantityBody}
                                header={selectedSaleReportSaleStatus == "SATIS" ? "Satış Adeti" : "İptal Adeti"}
                                headerStyle={{textAlign: 'right'}}
                                bodyStyle={{textAlign: 'right', paddingTop: 0, paddingBottom: 0, paddingRight: 14}}/>
                        <Column body={salePriceBody}
                                header={selectedSaleReportSaleStatus == "SATIS" ? "Satış Tutarı" : "İptal Tutarı"}
                                headerStyle={{textAlign: 'right'}}
                                bodyStyle={{textAlign: 'right', paddingTop: 0, paddingBottom: 0, paddingRight: 14}}/>
                      </DataTable>
                    </div>
                  </>}
              </>}
            </>}
        </div>
      </div>
    </>
  )

};

export default DailySaleReport;
