import AspAxios from "../../../service/AspAxios";
import * as Paths from "../../../service/config"
import axios from "axios";
import {getCurrentModelPricesDistyForExcel, getCurrentModelPricesForResellerList} from "../../../service/config";

export default class ProductPriceDistyService {


  getCurrentModelPrices(searchProductPrice,_page,_size)
  {
    return new Promise((resolve,reject)=>{
      AspAxios.post(Paths.getCurrentModelPricesDisty,searchProductPrice,{params:{size:_size,page:_page}}).then(res=>
        resolve(res.data)

      ).catch(err=>reject(err))
    })

  }
  getCurrentModelPricesForReseller(searchProductPrice,_page,_size)
  {
    return new Promise((resolve,reject)=>{
      AspAxios.post(Paths.getCurrentModelPricesForReseller,searchProductPrice,{params:{size:_size,page:_page}}).then(res=>
        resolve(res.data)

      ).catch(err=>reject(err))
    })

  }

  getCurrentModelPricesForResellerList(searchProductPrice,_page,_size)
  {
    return new Promise((resolve,reject)=>{
      AspAxios.post(Paths.getCurrentModelPricesForResellerList,searchProductPrice,{params:{size:_size,page:_page}}).then(res=>
        resolve(res.data)

      ).catch(err=>reject(err))
    })

  }
  getCurrentModelPricesList(searchProductPrice)
  {
    return new Promise((resolve,reject)=>{
      AspAxios.post(Paths.getCurrentModelPricesDistyList,searchProductPrice).then(res=>
        resolve(res.data)

      ).catch(err=>reject(err))
    })

  }

  getCurrentModelPricesDistyForExcel()
  {
    return new Promise((resolve,reject)=>{
      AspAxios.post(Paths.getCurrentModelPricesDistyForExcel,{}).then(res=>
        resolve(res.data)

      ).catch(err=>reject(err))
    })

  }



  currentModelPricePriceDetail(productId,mpsId)
  {
    return new Promise((resolve,reject)=>{
      AspAxios.post(Paths.getCurrentModelPriceDisty+productId+"/"+mpsId).then(res=>
        resolve(res.data)

      ).catch(err=>reject(err))
    })

  }

  lastModelPrices(productId)
  {
    return new Promise((resolve,reject)=>{
      AspAxios.get(Paths.getLastModelPriceDisty+productId).then(res=>
        resolve(res.data)

      ).catch(err=>reject(err))
    })

  }

  upsert(createProductPrice)
  {
    return new Promise((resolve,reject)=>{
      AspAxios.post(Paths.updateModelPriceDisty,createProductPrice).then(res=>
        resolve(res.data)

      ).catch(err=>reject(err))
    })

  }


  readExcel(excelProductPrices)
  {
    return new Promise((resolve,reject)=>{
      axios.post(Paths.readExcelForModelPriceDisty, {excelProductPrices}).then(res=>{
        resolve(res.data)
      }).catch(err=>reject(err))

    })
  }

  saveExcel(excelProductPrices)
  {
    return new Promise((resolve,reject)=>{
      axios.post(Paths.saveExcelForModelPriceDisty, {excelProductPrices}).then(res=>{
        resolve(res.data)
      }).catch(err=>reject(err))

    })
  }

};
