import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import ProductSalesManagementService from "./httpService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Calendar} from "primereact/calendar";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {ResponsiveDataTableWithoutRowClass} from "../../components/ResponsiveDataTableWithoutRowClass";

const SupplyInvoiceList = () => {

  const distyAdmin = JSON.parse(localStorage.getItem('user')).user?.roles.includes("DISTY_ADMIN");
  const distyCode = JSON.parse(localStorage.getItem('user')).user?.disty?.code;
  const supplyAdmin = JSON.parse(localStorage.getItem('user')).user?.roles.includes("SUPPLY_ADMIN");
  const supplyCode = JSON.parse(localStorage.getItem('user')).user?.supply?.code;
  const toast = useRef(null);
  const productSalesManagementService = new ProductSalesManagementService();

  const [loading, setLoading] = useState(false);
  const [selectedTimeBegin, setSelectedTimeBegin] = useState(null);
  const [selectedTimeEnd, setSelectedTimeEnd] = useState(null);
  const [serialNo, setSerialNo] = useState(null);
  const [barcode, setBarcode] = useState(null);
  const [selectedSupplyCode, setSelectedSupplyCode] = useState(null);
  const [selectedDistyCode, setSelectedDistyCode] = useState(null);
  const [searchCriteriaLazyLoading, setSearchCriteriaLazyLoading] = useState(false);
  const [searchCriteriaProgressing, setSearchCriteriaProgressing] = useState(false);
  const [saleInfoList, setSaleInfoList] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState({});

  const [exportExcelDisabled, setExportExcelDisabled] = useState(null);

  const [islemDetailModal, setIslemDetailModal] = useState(false);
  const [selectedIslem, setSelectedIslem] = useState(null);

  const [selectedProductSaleInfoDetailListLoading, setSelectedProductSaleInfoDetailListLoading] = useState(false);
  const [selectedProductSaleInfoDetailList, setSelectedProductSaleInfoDetailList] = useState(null);

  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  useEffect(() => {
    if (distyAdmin) {
      setSelectedDistyCode(distyCode);
    } else if (supplyAdmin) {
      setSelectedSupplyCode(supplyCode);
    } else {
      searchSaleInfo({...paginationConfig, activePage: 0});
    }
  }, []);

  useEffect(() => {
    setSearchCriteriaLazyLoading(true);
  }, [selectedTimeBegin, selectedTimeEnd, serialNo, barcode, selectedSupplyCode, selectedDistyCode, searchCriteria]);

  useEffect(() => {
    if (searchCriteriaLazyLoading) {
      setTimeout(() => {
        setSearchCriteriaProgressing(true);
        setSearchCriteriaLazyLoading(false);
      }, 2000);
    }
  }, [searchCriteriaLazyLoading]);

  useEffect(() => {
    if (searchCriteriaProgressing) {
      searchSaleInfo({...paginationConfig, activePage: 0});
      setSearchCriteriaProgressing(false);
    }
  }, [searchCriteriaProgressing]);

  const searchSaleInfo = (pageState) => {
    var dateControl = true;
    if (selectedTimeBegin != null && selectedTimeEnd != null && selectedTimeBegin.getTime() > selectedTimeEnd.getTime()) {
      dateControl = false;
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Aramak için başlangıç tarihi bitiş tarihinden önce olmalıdır.'
      });
    }
    if (dateControl) {
      setLoading(true);
      var sort = "";
      if (searchCriteria.sortField != null && searchCriteria.sortOrder != null) {
        sort += searchCriteria.sortField;
        if (searchCriteria.sortOrder == 1) {
          sort += ",asc";
        } else {
          sort += ",desc";
        }
      } else {
        sort += "createDateTime,desc";
      }
      searchCriteria.serialNo = serialNo;
      searchCriteria.barcode = barcode;

      if (selectedTimeBegin != null) {
        searchCriteria.invoiceDateStart = selectedTimeBegin.toLocaleDateString("tr-TR") + " " + selectedTimeBegin.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.invoiceDateStart = null;
      }

      if (selectedTimeEnd != null) {
        searchCriteria.invoiceDateEnd = selectedTimeEnd.toLocaleDateString("tr-TR") + " " + selectedTimeEnd.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.invoiceDateEnd = null;
      }

      searchCriteria.supplyCode = selectedSupplyCode;
      searchCriteria.distyCode = selectedDistyCode;

      productSalesManagementService.searchSupplyInvoiceLazy(searchCriteria, pageState.itemsPerPage, pageState.activePage, sort).then(res => {
        console.log("searchSupplyInvoiceLazy", res.content);
        if (res.content && res.content.length > 0) {
          res.content.forEach(c => {
            if (c.waybillSaleInfoList && c.waybillSaleInfoList.length > 0) {
              let waybillDateMap = new Map();
              c.waybillSaleInfoList.forEach(w => {
                let date = w.waybillDateString.split(" ")[0];
                if (waybillDateMap.has(date)) {
                  waybillDateMap.set(date, waybillDateMap.get(date) + 1);
                } else {
                  waybillDateMap.set(date, 1);
                }
                let waybillDateMapString = "";
                waybillDateMap.forEach(function (value, key) {
                  waybillDateMapString += key + "(" + value + ")\n";
                });
                c.waybillDateMapString = waybillDateMapString;
              });
            }
          });
          setSaleInfoList(res.content);

          pageState.totalRecords = res.totalElements;
          pageState.first = pageState.itemsPerPage * pageState.activePage;
          setPaginationConfig({
            ...pageState,
          });
        } else {
          setSaleInfoList([]);
        }
      }).catch(err => {
        toast?.current?.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Satış bilgisi getirme başarısız.'
        });
        console.log(err);
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  const exportExcel = () => {
    var dateControl = false;
    if (serialNo != null && serialNo != "") {
      dateControl = true;
    } else if (serialNo != null && serialNo != "") {

    } else {
      if (selectedTimeBegin != null && selectedTimeEnd != null) {
        if (selectedTimeEnd.getTime() - selectedTimeBegin.getTime() <= 93 * 24 * 3600000) {
          if (selectedTimeBegin.getTime() <= selectedTimeEnd.getTime()) {
            dateControl = true;
          } else {
            toast.current.show({
              severity: 'warn',
              summary: 'Uyarı',
              detail: 'Başlangıç tarihi bitiş tarihinden önce olmalıdır.'
            });
          }
        } else {
          toast.current.show({
            severity: 'warn',
            summary: 'Uyarı',
            detail: 'Excel almak için tarih aralığı üç aydan fazla olmamalıdır.'
          });
        }
      } else {
        toast.current.show({
          severity: 'warn',
          summary: 'Uyarı',
          detail: 'Excel almak için tarih alanları veya seri no alanı dolu olmalıdır.'
        });
      }
    }
    if (dateControl) {
      setExportExcelDisabled(true);

      searchCriteria.serialNo = serialNo;
      searchCriteria.barcode = barcode;

      if (selectedTimeBegin != null) {
        searchCriteria.invoiceDateStart = selectedTimeBegin.toLocaleDateString("tr-TR") + " " + selectedTimeBegin.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.invoiceDateStart = null;
      }

      if (selectedTimeEnd != null) {
        searchCriteria.invoiceDateEnd = selectedTimeEnd.toLocaleDateString("tr-TR") + " " + selectedTimeEnd.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.invoiceDateEnd = null;
      }

      searchCriteria.supplyCode = selectedSupplyCode;
      searchCriteria.distyCode = selectedDistyCode;

      productSalesManagementService.searchSupplyInvoiceEager(searchCriteria).then(res => {
        console.log("searchSaleInfoEager", res);
        import('xlsx').then(xlsx => {
          var excelList = [];
          res.forEach(a => {
            excelList.push({
              "Fatura Tarihi": a.invoiceDateString,
              "Fatura No": a.invoiceNo,
              "İrsaliye No": a.waybillNo,
              "Tedarikçi Kodu": a.supplyCode,
              "Distribütör Kodu": a.distyCode,
              "Açıklama": a.description,
              "KDV Tutarı": addCommas(a.vat),
              "KDV Oranı": addCommas(a.vatRatio),
              "Fiyat": addCommas(a.price),
              "KDV Dahil Fiyat": addCommas(a.vatIncludedPrice),
              "İskonto Tutarı": addCommas(a.discountAmount),
              "İskonto Oranı": addCommas(a.discountRatio)
            })
          });
          const worksheet = xlsx.utils.json_to_sheet(excelList);
          var wscols = [
            {width: 18},
            {width: 18},
            {width: 18},
            {width: 18},
            {width: 18},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 15},
            {width: 15}
          ];
          worksheet["!cols"] = wscols;
          const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
          const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
          saveAsExcelFile(excelBuffer, 'Tedarikçi Dağıtım Bilgileri');
        });
      }).catch(err => {
        toast?.current?.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Dağıtım bilgisi getirme başarısız.'
        });
        console.log(err);
      }).finally(() => {
        setExportExcelDisabled(false);
      });
    }
  };

  const exportExcelSelectedIslem = () => {
    import('xlsx').then(xlsx => {
      var excelList = [];
      selectedProductSaleInfoDetailList.forEach(p => {
        excelList.push({
          "Aksesuar Barkod": p.barcode,
          "Aksesuar Seri Numara": p.serialNo,
          "Aksesuar Açıklama": p.description,
          "Aksesuar KDV": p.vat,
          "Aksesuar KDV Oranı": p.vatRatio,
          "Aksesuar Fiyat": p.price,
          "Aksesuar KDV Dahil Fiyat": p.vatIncludedPrice,
          "Aksesuar İskonto Tutarı": p.discount,
          "Aksesuar İskonto Oranı": p.discountRatio
        });
      });
      const worksheet = xlsx.utils.json_to_sheet(excelList);
      var wscols = [
        {width: 15},
        {width: 20},
        {width: 15},
        {width: 18},
        {width: 15},
        {width: 20},
        {width: 15},
        {width: 22},
        {width: 22},
        {width: 22},
      ];
      worksheet["!cols"] = wscols;
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      saveAsExcelFile(excelBuffer, 'Aksesuar Bigileri');
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    searchSaleInfo(pageState);
  };

  const onFilter = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const onSort = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const technicalDetailModalModalShow = (e) => {
    setSelectedProductSaleInfoDetailList(null);
    setSelectedProductSaleInfoDetailListLoading(true);
    saleInfoList.forEach(s => {
      if (s.id == e.currentTarget.id) {
        setIslemDetailModal(true);
        setSelectedIslem(s);
        productSalesManagementService.findSupplyInvoiceDetailByInvoiceId(s.id).then(res => {
          setSelectedProductSaleInfoDetailList(res);
        }).catch(err => {
          console.log(err);
          toast?.current?.show({
            severity: 'error',
            summary: 'Hata',
            detail: 'Aksesuar bilgisi getirme başarısız.'
          });
        }).finally(() => {
          setSelectedProductSaleInfoDetailListLoading(false);
        });
      }
    });
  };

  const islemDetailModalModalHide = () => {
    setIslemDetailModal(false);
  };

  const addCommas = num => num?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const responsiveColumnRight = (title, content) => {
    return (
      <div>
        <span className="p-column-title">{title}</span>
        <span style={{float: 'right'}}>{content}</span>
      </div>
    );
  };

  const invoiceDateTemplate = (data) => {
    return responsiveColumn("Fatura Tarihi", data?.invoiceDateString)
  };

  const invoiceNoTemplate = (data) => {
    return responsiveColumn("Fatura No", data?.invoiceNo)
  };

  const supplyCodeTemplate = (data) => {
    return responsiveColumn("Tedarikçi Kodu", data?.supplyCode)
  };

  const distyCodeTemplate = (data) => {
    return responsiveColumn("Distribütör Kodu", data?.distyCode)
  };

  const vatIncludedPriceTemplate = (data) => {
    return responsiveColumnRight("KDV Dahil Fiyat", addCommas(data?.vatIncludedPrice))
  };

  const accessoryCampaignSerialTitleButtonTemplate = (data) => {
    return (
      <div className="p-d-flex p-justify-even">
        <Button id={data.id} type="button" className="p-button-sm p-button-info" label="Detay"
                onClick={technicalDetailModalModalShow}/>
      </div>
    );
  };

  const distyCodeFilter = <InputText value={selectedDistyCode} onChange={(e) => setSelectedDistyCode(e.target.value)}
                                     disabled={distyAdmin} className="p-column-filter"/>;

  const supplyCodeFilter = <InputText value={selectedSupplyCode}
                                      onChange={(e) => setSelectedSupplyCode(e.target.value)}
                                      disabled={supplyAdmin} className="p-column-filter"/>;

  const barcodeTemplate = (data) => {
    return responsiveColumn("Barkod", data?.barcode)
  };

  const serialNoTemplate = (data) => {
    return responsiveColumn("Seri Numara", data?.serialNo)
  };

  const descriptionTemplate = (data) => {
    return responsiveColumn("Açıklama", data?.description)
  };

  const vatTemplate = (data) => {
    return responsiveColumnRight("KDV", addCommas(data?.vat))
  };

  const vatRatioTemplate = (data) => {
    return responsiveColumn("KDV Oranı", data?.vatRatio)
  };

  const priceTemplate = (data) => {
    return responsiveColumnRight("Fiyat", addCommas(data?.price))
  };

  const discountTemplate = (data) => {
    return responsiveColumnRight("İskonto Tutarı", addCommas(data?.discount))
  };

  const discountRatioTemplate = (data) => {
    return responsiveColumn("İskonto Oranı", data?.discountRatio)
  };

  const header =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>Tedarikçi Dağıtım Bilgileri</div>
        <div>
          <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
                  disabled={exportExcelDisabled}
                  onClick={exportExcel} style={{marginRight: 5}}/>
        </div>
      </div>
    </div>;

  const islemHeader =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>Aksesuar Bigileri</div>
        <div>
          <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
                  disabled={exportExcelDisabled}
                  onClick={exportExcelSelectedIslem}/>
        </div>
      </div>
    </div>;

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-12 p-md-2 p-fluid">
            <label htmlFor="baslangic">Fatura Tarihi Aralığı</label>
            <Calendar id="baslangic" showIcon dateFormat={'dd.mm.yy'} style={{marginTop: 5}}
                      value={selectedTimeBegin} showTime showButtonBar
                      onChange={(e) => setSelectedTimeBegin(e.value)}
                      placeholder="Tarih Başlangıç"/>
          </div>
          <div className="p-col-12 p-md-2 p-fluid"><br className="small_hidden"/>
            <Calendar id="bitis" showIcon dateFormat={'dd.mm.yy'} style={{marginTop: 5}}
                      value={selectedTimeEnd} showTime showButtonBar
                      onChange={(e) => setSelectedTimeEnd(e.value)}
                      placeholder="Tarih Bitiş"/>
          </div>
          <div className="p-col-12 p-md-3 p-fluid">Aksesuar Ürün Seri No
            <InputText style={{marginTop: 5}} value={serialNo} placeholder="Seri No"
                       onChange={(e) => setSerialNo(e.target.value)}/>
          </div>
          <div className="p-col-12 p-md-3 p-fluid">Aksesuar Ürün Barkod
            <InputText style={{marginTop: 5}} value={barcode} placeholder="Barkod"
                       onChange={(e) => setBarcode(e.target.value)}/>
          </div>
          <div className="p-col-12">
            <ResponsiveDataTableWithoutRowClass
              lazy
              header={header}
              value={saleInfoList}
              paginationConfig={paginationConfig}
              onActivePageChange={onActivePageChange}
              onFilter={onFilter}
              filters={searchCriteria.filters}
              onSort={onSort}
              sortField={searchCriteria.sortField}
              sortOrder={searchCriteria.sortOrder}
              loading={loading}
            >
              <Column field="invoiceDate" body={invoiceDateTemplate} header="Fatura Tarihi"
                      sortable/>
              <Column field="invoiceNo" body={invoiceNoTemplate} header="Fatura No" filter={true}
                      filterMatchMode={"contains"} sortable/>
              <Column field="supplyCode" body={supplyCodeTemplate} header="Tedarikçi Kodu" filter={true}
                      filterElement={supplyCodeFilter}
                      filterMatchMode={"contains"} sortable/>
              <Column field="distyCode" body={distyCodeTemplate} header="Distribütör Kodu" filter={true}
                      filterElement={distyCodeFilter} filterMatchMode={"contains"} sortable/>
              <Column field="vatIncludedPrice" body={vatIncludedPriceTemplate} header="KDV Dahil Fiyat"
                      headerStyle={{textAlign: 'right'}} sortable/>
              <Column body={accessoryCampaignSerialTitleButtonTemplate}
                      headerStyle={{width: "8rem", textAlign: "center"}}
                      bodyStyle={{textAlign: 'center', overflow: 'visible', justifyContent: 'center'}}/>
            </ResponsiveDataTableWithoutRowClass>
          </div>
        </div>
        <Dialog header="Fatura Detay" visible={islemDetailModal} modal style={{width: '1200px'}}
                onHide={islemDetailModalModalHide}>
          <div className="datatable-responsive-demo">
            <div className="p-grid">
              <div className="p-field p-col-12">
                <p className="marginParagraph"><b>Fatura Tarihi:</b> {selectedIslem?.invoiceDateString}</p>
                <p className="marginParagraph"><b>Fatura No:</b> {selectedIslem?.invoiceNo}</p>
                <p className="marginParagraph"><b>Tedarikçi Kodu:</b> {selectedIslem?.supplyCode}</p>
                <p className="marginParagraph"><b>Distribütör Kodu:</b> {selectedIslem?.distyCode}</p>
                {selectedIslem?.description &&
                <p className="marginParagraph"><b>Açıklama:</b> {selectedIslem?.description}</p>}
                <p className="marginParagraph"><b>KDV Tutarı:</b> {addCommas(selectedIslem?.vat)}</p>
                <p className="marginParagraph"><b>KDV Oranı:</b> {selectedIslem?.vatRatio}</p>
                <p className="marginParagraph"><b>Fiyat:</b> {addCommas(selectedIslem?.price)}</p>
                <p className="marginParagraph"><b>KDV Dahil Fiyat:</b> {addCommas(selectedIslem?.vatIncludedPrice)}</p>
                {selectedIslem?.discount != null &&
                <p className="marginParagraph"><b>İskonto Tutarı:</b> {addCommas(selectedIslem?.discount)}</p>}
                <ResponsiveDataTable value={selectedProductSaleInfoDetailList}
                                     header={islemHeader}
                                     loading={selectedProductSaleInfoDetailListLoading}
                                     style={{fontSize: '11px', marginTop: 10}}
                                     paginator rows={5} rowsPerPageOptions={[5, 10, 15]}
                                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                     currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} arası gösteriliyor">
                  <Column body={barcodeTemplate} header="Barkod"/>
                  <Column body={serialNoTemplate} header="Seri Numara" headerStyle={{width: "14rem"}}/>
                  <Column body={descriptionTemplate} header="Açıklama"/>
                  <Column body={vatTemplate} header="KDV" headerStyle={{textAlign: 'right'}}/>
                  <Column body={vatRatioTemplate} header="KDV Oranı"/>
                  <Column body={priceTemplate} header="Fiyat" headerStyle={{textAlign: 'right'}}/>
                  <Column body={vatIncludedPriceTemplate} header="KDV Dahil Fiyat" headerStyle={{textAlign: 'right'}}/>
                  <Column body={discountTemplate} header="İskonto Tutarı" headerStyle={{textAlign: 'right'}}/>
                  <Column body={discountRatioTemplate} header="İskonto Oranı"/>
                </ResponsiveDataTable>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  )

};

export default SupplyInvoiceList;
