import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import {GiftService} from "./GiftService";
import {Button} from "primereact/button";
import classNames from "classnames";
import {InputTextarea} from "primereact/inputtextarea";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";

const ScoreEarning = () => {

  const toast = useRef(null);
  const giftService = new GiftService();

  const [updateAllSubmitted, setUpdateAllSubmitted] = useState(false);
  const [updateAllButtonDisabled, setUpdateAllButtonDisabled] = useState(false);
  const [selectedScoreEarning, setSelectedScoreEarning] = useState(null);
  const [selectedScoreEarningDescription, setSelectedScoreEarningDescription] = useState(null);

  useEffect(() => {
    getParams();
  }, []);

  const getParams = () => {
    giftService.getGiftScore().then(res => {
      console.log("getGiftScore", res);
      setSelectedScoreEarning(res.scoreEarning ? "AÇIK" : "KAPALI");
      setSelectedScoreEarningDescription(res.scoreEarningDescription);
    }).catch(err => {
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Puan bilgisi getirme başarısız.'
      });
      console.log(err);
    });
  };

  const updateAllScoreStaus = () => {
    setUpdateAllSubmitted(true);
    setUpdateAllButtonDisabled(true);
    if (selectedScoreEarning != null) {
      const request = {
        scoreEarning: selectedScoreEarning === "AÇIK",
        scoreEarningDescription: selectedScoreEarningDescription != null ? selectedScoreEarningDescription : "",
      };
      giftService.updateGiftScore(request).then(res => {
        console.log("updateGiftScore", res);
        toast.current.show({
          severity: 'success',
          summary: 'Başarılı İşlem',
          detail: 'Puan kazanımı güncellendi.'
        });
        getParams();
      }).catch(err => {
        console.log(err);
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Puan kazanımı güncellenemedi.'
        });
      }).finally(() => {
        setUpdateAllButtonDisabled(false);
      })
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Bütün alanlar dolu olmalıdır.'
      });
      setUpdateAllButtonDisabled(false);
    }
  };

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-12 p-md-3 p-fluid">
            Puan Kazanımı
            <Dropdown
              style={{marginTop: 10, marginBottom: 10}} value={selectedScoreEarning} min={0}
              options={["AÇIK", "KAPALI"]}
              onChange={(e) => setSelectedScoreEarning(e.value)}
              className={classNames({'p-invalid': updateAllSubmitted && !selectedScoreEarning})}/>
            Puan Kazanımı Açıklama
            <br/>
            <InputTextarea
              style={{marginTop: 10}} value={selectedScoreEarningDescription} min={0}
              onChange={(e) => setSelectedScoreEarningDescription(e.target.value)}
              rows={4}
              cols={20}/>
            <Button style={{marginTop: 10}} label="Güncelle"
                    disabled={updateAllButtonDisabled} onClick={updateAllScoreStaus}/>
          </div>
        </div>
      </div>
    </>
  )

};

export default ScoreEarning;
