import * as Paths from "../../service/config";
import AspAxios from "../../service/AspAxios";

function clean(obj) {
  let _obj = {};
  for (const propName in obj) {
    if (obj[propName] !== "") {
      _obj[propName] = obj[propName];
    }
  }
  return _obj;
}

export function upsertUser(request) {
  return new Promise((resolve, reject) => {
    AspAxios.post(Paths.upsertUser, clean(request))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAllRoles() {
  return new Promise((resolve, reject) => {
    AspAxios.get(Paths.allRoles)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getRolesByUsage(_usage) {
  return new Promise((resolve, reject) => {
    AspAxios.get(Paths.allRolesByUsage + _usage)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function searchUser(_search, _page, _size) {
  return new Promise((resolve, reject) => {
    AspAxios.post(
      Paths.searchUser + "?page=" + _page + "&size=" + _size,
      clean(_search)
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function searchTtgUser(_search, _page, _size) {
  return new Promise((resolve, reject) => {
    AspAxios.post(
      Paths.searchUserTtg + "?page=" + _page + "&size=" + _size,
      clean(_search)
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function searchTtgUserList(_search) {
  return new Promise((resolve, reject) => {
    AspAxios.post(
      Paths.searchUserTtgList,
      clean(_search)
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function searchResellerUser(_search, _page, _size) {
  return new Promise((resolve, reject) => {
    AspAxios.post(
      Paths.searchUserReseller + "?page=" + _page + "&size=" + _size,
      clean(_search)
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getResellers()
{
  return new Promise((resolve, reject) => {
    AspAxios.get(
      Paths.findAllActiveResellers
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}


export function searchResellerUserList(_search) {
  return new Promise((resolve, reject) => {
    AspAxios.post(
      Paths.searchUserResellerList,
      clean(_search)
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}



export function activeUser(request) {
  return new Promise((resolve, reject) => {
    AspAxios.post(Paths.activeUser, clean(request))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deleteUser(request) {
  return new Promise((resolve, reject) => {
    AspAxios.post(Paths.deleteUser, clean(request))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
