import React, {useEffect, useState} from "react";
import {Dropdown} from "primereact/dropdown";
import * as services from "./httpService";
import {handleError} from "../../service/HandleErrorService";

const SupplyDropdown = ({
                          id,
                          value,
                          onChange,
                          disabled,
                          label,
                          className,
                          placeholder,
                          toast
                        }) => {

  const [supplies, setSupplies] = useState([]);

  useEffect(() => {
    getAssignableRoles();
  }, []);

  const getAssignableRoles = () => {
    services
      .getActiveSupplies()
      .then((res) => {
        setSupplies(res);
      })
      .catch((err) => {
        handleError(err, toast)
      });
  };

  return (
    <span className="p-float-label marginInput">
             <Dropdown showClear
                       id={id && "d_id"}
                       value={value}
                       disabled={disabled}
                       onChange={onChange}
                       style={{width: 300}}
                       optionLabel="name"
                       options={supplies}/>
              <label htmlFor="origin">Tedarikçiler</label>
    </span>
  );
};

export default SupplyDropdown;
