import React, {useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import CanSellService from "./httpService";
import {InputText} from "primereact/inputtext";
import classNames from "classnames";
import {Button} from "primereact/button";
import {Checkbox} from "primereact/checkbox";
import {FileUpload} from "primereact/fileupload";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";

const BrandCreate = () => {

  const toast = useRef(null);
  const canSellService = new CanSellService();

  const [allBrandLoading, setAllBrandLoading] = useState(false);
  const [allBrand, setAllBrand] = useState(null);
  const [filteredAllBrand, setFilteredAllBrand] = useState(null);

  const [submitted, setSubmitted] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedNewBrand, setSelectedNewBrand] = useState(false);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [selectedOldImageFile, setSelectedOldImageFile] = useState(null);
  const [saveBrandButtonDisabled, setSaveBrandButtonDisabled] = useState(false);

  const [brandImageModal, setBrandImageModal] = useState(false);
  const [selectedBrandForImage, setSelectedBrandForImage] = useState(null);

  const [saveBrandModalHeader, setSaveBrandModalHeader] = useState(null);
  const [saveBrandModal, setSaveBrandModal] = useState(null);
  const [selectedBrandForSave, setSelectedBrandForSave] = useState(null);

  const [allScoreTypes, setAllScoreTypes] = useState([]);
  const [selectScoreType, setSelectScoreType] = useState(null);
  const [editScore, setEditScore] = useState({});
  const [scoreLabel, setScoreLabel] = useState(null);

  useEffect(() => {
    getBrandList();
    getAllScoreTypes();
  }, []);

  useEffect(() => {
    if(selectScoreType != null) {
      let filteredList = [];
      allBrand.forEach(b => {
        if(b.productScoreType === selectScoreType) {
          filteredList.push(b);
        }
      });
      setFilteredAllBrand(filteredList);
    } else {
      setFilteredAllBrand(allBrand);
    }
  }, [allBrand, selectScoreType]);

  const getBrandList = () => {
    setAllBrandLoading(true);
    canSellService.getAllBrand().then(res => {
      console.log("getAllBrand", res);
      setAllBrand(res);
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      setAllBrandLoading(false);
    });
  };

  const getAllScoreTypes = () => {
    canSellService.getAllScoreType().then(res=>{
      setAllScoreTypes(res?.map(value=>{
        return{
          name:value.value,
          value:value.key
        }
      }));
    });
  }

  const saveBrand = () => {
    setSubmitted(true);
    setSaveBrandButtonDisabled(true);
    if (selectedName != null && selectedName !== "") {
      if (editScore != null && editScore?.productScoreType != null && editScore?.scoreRatio != null) {
      if (selectedBrandForSave == null) {
        if (selectedImageFile != null) {
          let formData = new FormData();
          formData.append('file', selectedImageFile);
          formData.append('productScoreType', editScore?.productScoreType);
          formData.append('scoreRatio', editScore?.scoreRatio);
          canSellService.createBrand(formData, selectedName, selectedNewBrand ? selectedNewBrand : false).then(res => {
            console.log("createBrand", res);
            toast.current.show({
              severity: 'success',
              summary: 'Başarılı İşlem',
              detail: 'Marka oluşturuldu.'
            });
            saveBrandModalHide();
            getBrandList();
          }).catch(err => {
            console.log(err);
            toast.current.show({
              severity: 'error',
              summary: 'Hata',
              detail: 'Marka oluşturulamadı.'
            });
          }).finally(() => {
            setSaveBrandButtonDisabled(false);
          })
        } else {
          toast.current.show({
            severity: 'warn',
            summary: 'Uyarı',
            detail: 'Marka resmi seçili olmalıdır.'
          });
          setSaveBrandButtonDisabled(false);
        }
      } else {
        let formData = new FormData();
        if(selectedImageFile != null){
          formData.append('file', selectedImageFile);
        }
        formData.append('productScoreType', editScore?.productScoreType);
        formData.append('scoreRatio', editScore?.scoreRatio);
        canSellService.updateBrand(formData, selectedBrandForSave.id, selectedName, selectedNewBrand ? selectedNewBrand : false).then(res => {
          console.log("createBrand", res);
          toast.current.show({
            severity: 'success',
            summary: 'Başarılı İşlem',
            detail: 'Marka düzenlendi.'
          });
          saveBrandModalHide();
          getBrandList();
        }).catch(err => {
          console.log(err);
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: 'Marka düzenlenemedi.'
          });
        }).finally(() => {
          setSaveBrandButtonDisabled(false);
        })
      }
      } else {
        toast.current.show({
          severity: 'warn',
          summary: 'Uyarı',
          detail: 'Skor dolu olmalıdır.'
        });
        setSaveBrandButtonDisabled(false);
      }
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Marka adı dolu olmalıdır.'
      });
      setSaveBrandButtonDisabled(false);
    }
  };

  const brandImageModalShow = (e) => {
    allBrand.forEach(b => {
      if (b.id == e.currentTarget.id) {
        setSelectedBrandForImage(b);
      }
    });
    setBrandImageModal(true);
  };

  const brandImageModalHide = () => {
    setBrandImageModal(false);
  };

  const addBrandModalShow = () => {
    setEditScore({});
    setSubmitted(false);
    setSelectedBrandForSave(null);
    setSelectedName(null);
    setSelectedNewBrand(null);
    setSelectedImageFile(null);
    setSelectedOldImageFile(null);
    setSaveBrandModalHeader("Yeni Marka Oluştur");
    setSaveBrandModal(true);
  };

  const editBrandModalShow = (e) => {
    setSubmitted(false);
    let brand = null;
    allBrand.forEach(b => {
      if (b.id == e.currentTarget.id) {
        setSelectedBrandForSave(b);
        brand = b;
      }
    });
    setSelectedName(brand.name);
    setSelectedNewBrand(brand.newBrand);
    setSelectedImageFile(null);
    setSelectedOldImageFile(brand.image);
    setSaveBrandModalHeader("Markayı Düzenle");
    setEditScore({productScoreType: brand.productScoreType, scoreRatio: brand.scoreRatio});
    if (brand.productScoreType === "UNIT") {
      setScoreLabel("Puan")
    } else if (brand.productScoreType === "AMOUNT") {
      setScoreLabel("Oran")
    } else {
      setScoreLabel("");
    }
    setSaveBrandModal(true);
  };

  const saveBrandModalHide = () => {
    setSaveBrandModal(false);
  };

  const photoUploadHandler = ({files}) => {
    if (files.length === 1) {
      const [file] = files;
      console.log(file);
      setSelectedImageFile(file);
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Sadece bir tane resim seçilmiş olmalıdır.'
      });
    }
  };

  const passiveBrand = (e) => {
    setAllBrandLoading(true);
    const request = {
      id: e.currentTarget.id
    };
    canSellService.passiveBrand(request).then(res => {
      console.log("passiveBrand", res);
      toast.current.show({
        severity: 'success',
        summary: 'Başarılı İşlem',
        detail: 'Marka pasif edildi.'
      });
      getBrandList()
    }).catch(err => {
      console.log(err);
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Marka pasif edilemedi.'
      });
      setAllBrandLoading(false);
    })
  };

  const activeBrand = (e) => {
    setAllBrandLoading(true);
    const request = {
      id: e.currentTarget.id
    };
    canSellService.activeBrand(request).then(res => {
      console.log("activeBrand", res);
      toast.current.show({
        severity: 'success',
        summary: 'Başarılı İşlem',
        detail: 'Marka aktif edildi.'
      });
      getBrandList()
    }).catch(err => {
      console.log(err);
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Marka aktif edilemedi.'
      });
      setAllBrandLoading(false);
    })
  };

  const accessoryCampaignButtonTemplate = (data) => {
    return (
      <div className="p-d-flex p-justify-even">
        <Button
          id={data.id}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-warning"
          label="Düzenle"
          onClick={editBrandModalShow}
        />
        {data.status === 1 ? (
          <div>
            <Button
              id={data.id}
              icon="pi pi-eye-slash"
              className="p-button-rounded p-button-danger"
              label="Pasif Et"
              onClick={passiveBrand}
            />
          </div>
        ) : (
          <div>
            <Button
              id={data.id}
              icon="pi pi-eye"
              className="p-button-rounded p-button-success"
              label="Aktif Et"
              onClick={activeBrand}
            />
          </div>
        )}
      </div>
    );
  };

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const statusTemplate = (data) => {
    return responsiveColumn("Aktiflik Durumu", <Checkbox style={{cursor: 'context-menu'}} disabled={true}
                                                            checked={data.status == 1}/>)
  };

  const nameTemplate = (data) => {
    return responsiveColumn("Marka Adı", data?.name)
  };

  const brandImageTemplate = (data) => {
    return responsiveColumn("Marka Resmi", <Button id={data.id} type="button" className="p-button-link" label="Resim"
                                                   onClick={brandImageModalShow}/>)
  };

  const newBrandTemplate = (data) => {
    return responsiveColumn("Yeni Bir Marka Mı?", <Checkbox style={{cursor: 'context-menu'}} disabled={true}
                                                            checked={data.newBrand}/>)
  };

  const scoreRatioTemplate = (e) => {
    return <div style={{paddingLeft:8}}>{e?.scoreRatio}</div>
  }

  const scoreTypeTemplate = (e) => {
    let _scoreType = allScoreTypes.find(score => score.value === e?.productScoreType);

    return <div style={{paddingLeft:8}}>{_scoreType?.name}</div>
  }

  const onScoreChange = (e) => {
    setSelectScoreType(e.value)
  }

  const scoreItemTemplate = (option) => {
    return (
      <div className="p-multiselect-representative-option">
        <span className="image-text">{option.name}</span>
      </div>
    );
  }

  const scoreFilter=
    <Dropdown id="productScoreType" value={selectScoreType}
              options={allScoreTypes} optionLabel="name" optionValue="value" placeholder="Skor Tipi"
              itemTemplate={scoreItemTemplate} onChange={e=>onScoreChange(e)}
              showClear
              className="p-column-filter"/>;

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card">
          <Button onClick={addBrandModalShow} icon="pi pi-plus-circle" label="Yeni Marka"
                  className="p-button-success p-mr-2 p-mb-2"/>
          <ResponsiveDataTable value={filteredAllBrand} className="p-datatable-responsive-demo"
                               loading={allBrandLoading}
                               header="Marka Listesi"
                               emptyMessage="Marka Bulunamadı"
                               paginator rows={10} rowsPerPageOptions={[10, 20, 30]}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} arası gösteriliyor">
            <Column body={statusTemplate} header="Aktiflik Durumu"/>
            <Column field="name" body={nameTemplate} header="Marka Adı" filter={true}
                    filterMatchMode={"contains"}/>
            <Column body={brandImageTemplate} header="Marka Resmi"/>
            <Column body={newBrandTemplate} header="Yeni Bir Marka Mı?"/>
            <Column field="productScoreType" header="Skor Tipi" body={e => scoreTypeTemplate(e)} filter filterElement={scoreFilter} headerStyle={{paddingLeft:20}}/>
            <Column field="scoreRatio" header="Skor" sortable  body={scoreRatioTemplate} />
            <Column body={accessoryCampaignButtonTemplate} headerStyle={{width: '18rem'}}
                    bodyStyle={{textAlign: 'center', overflow: 'visible', justifyContent: 'center'}}/>
          </ResponsiveDataTable>
        </div>
      </div>
      <Dialog header={saveBrandModalHeader} visible={saveBrandModal} style={{width: '360px'}}
              onHide={saveBrandModalHide}>
        <div className="p-grid">
          <div className="p-col-12">
           <span className="p-float-label marginInput">
            <InputText id="ad" value={selectedName}
                       onChange={(e) => setSelectedName(e.target.value)}
                       style={{width: 280}}
                       className={classNames({'p-invalid': submitted && !selectedName})}/>
            <label htmlFor="ad">Marka Adı</label>
           </span>
            <span className="p-float-label marginInput">
            <Dropdown id="productScoreType" value={editScore?.productScoreType}
                      options={allScoreTypes} optionLabel="name" optionValue="value"
                      onChange={e => {
                        let _EditScore = {...editScore};
                        _EditScore.productScoreType = e.value;
                        setEditScore(_EditScore)
                        if (e.value === "UNIT") {
                          setScoreLabel("Puan")

                        } else if (e.value === "AMOUNT") {
                          setScoreLabel("Oran")
                        } else {
                          setScoreLabel("");
                        }
                      }}
                      style={{width: 280}}
                      className={classNames({'p-invalid': submitted && !editScore?.productScoreType})}
            />
                     <label htmlFor="scoreType">Skor Tipi</label>
            </span>

            <span className="p-float-label marginInput">
            <InputText type="number" step="0,1" id="scoreRatio" value={editScore?.scoreRatio}
                       onChange={e => {
                         let _EditScore = {...editScore};
                         _EditScore.scoreRatio = e.target.value;
                         setEditScore(_EditScore)
                       }}
                       style={{width: 280}}
                       className={classNames({'p-invalid': submitted && !editScore?.scoreRatio})}
            />
              <label htmlFor="scoreRatio">{scoreLabel}</label>
            </span>
            <span style={{fontSize: '12pt'}} className="p-float-label marginInput">
            Yeni bir marka mı ?
            <Checkbox style={{marginLeft: 10}} checked={selectedNewBrand}
                      onChange={(e) => setSelectedNewBrand(e.checked)}/>
           </span>
            <span>
            <FileUpload mode="basic" name="photo" accept="image/*" maxFileSize={1000000}
                        chooseLabel="Marka Resmini Seçin" auto customUpload={true}
                        uploadHandler={photoUploadHandler} disabled={selectedImageFile != null}/>
            <br/>
              {selectedImageFile && <p><b>Seçili resim:</b> {selectedImageFile?.name}</p>}
              {!selectedImageFile && selectedOldImageFile &&
              <p style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <b>Marka Resmi:</b>
                <img src={selectedOldImageFile?.publicAddress} alt="Brand Image"
                     style={{marginLeft:5, width: 96}}/>
              </p>}
           </span>

            <Button style={{marginTop: 20}} className="p-button-success" label="Kaydet"
                    disabled={saveBrandButtonDisabled} onClick={saveBrand}/>
          </div>
        </div>
      </Dialog>
      <Dialog header={selectedBrandForImage?.name + " Marka Resmi"} visible={brandImageModal} style={{width: '300px'}}
              onHide={brandImageModalHide}>
        <img src={selectedBrandForImage?.image?.publicAddress} alt="Brand Image" style={{width: 256}}/>
      </Dialog>
    </>
  )
}

export default BrandCreate;
