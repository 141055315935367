import React, {useEffect, useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import classNames from "classnames";
import {Toast} from "primereact/toast";
import {Dialog} from "primereact/dialog";
import {InputMask} from "primereact/inputmask";
import {Checkbox} from "primereact/checkbox";
import * as services from "./httpService";
import {handleError} from "../../service/HandleErrorService";
import {TabPanel, TabView} from "primereact/tabview";
import {useHistory} from "react-router-dom";
import otpImage from "../../assets/images/otp.png";

export const Login = (props) => {
  const history = useHistory();
  let emptyProduct = {
    username: "",
    password: "",
  };

  const [submitted, setSubmitted] = useState(false);
  const [submittedGsmEmail, setSubmittedGsmEmail] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedGsmEmail, setCheckedGsmEmail] = useState(false);
  const [login, setLogin] = useState(emptyProduct);
  const [visibleModal, setVisibleModal] = useState(false);
  const [gsmEmail, setGsmEmail] = useState("");
  const [loading2, setLoading2] = useState(false);
  const [smsSendStatus, setSMSSendStatus] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");
  const [smsVerifyStatus, setSMSVerifyStatus] = useState(null);

  const toast = useRef(null);

  useEffect(() => {
    setLogin(emptyProduct);
    setSubmitted(false);
  }, []);

  const onLogin = () => {
    setLoading2(true);

    setSubmitted(true);
    if (!checked) {
      setLoading2(false)
      return toast.current?.show({
        severity: "warn",
        summary: "Uyarı",
        detail: "KVKK Aydınlatma Metni'ni onaylamadan devam edemezsiniz",
        life: 3000,
      });

    }
    services
      .login(login)
      .then((res) => {
        localStorage.setItem("token", res.token);
        localStorage.setItem("refreshToken", res.refreshToken);
        userDetail(res.token);
        setLoading2(false);
      })
      .catch((error) => {
        handleError(error, toast);
        setLoading2(false);
      });

  };

  const onLoginWithGsmEmail = () => {
    setSubmittedGsmEmail(true);
    if (!gsmEmail) {
      return toast.current?.show({
        severity: "warn",
        summary: "Uyarı",
        detail: "Gsm No / E-Posta boş bırakılamaz",
        life: 3000,
      });
    }
    if (!checkedGsmEmail) {
      return toast.current?.show({
        severity: "warn",
        summary: "Uyarı",
        detail: "KVKK Aydınlatma Metni'ni onaylamadan devam edemezsiniz",
        life: 3000,
      });
    }
    if (smsSendStatus === true) {
      setSMSSendStatus(false);
      setVerificationCode("");
      setSMSVerifyStatus(null);
    } else {
      services
        .takeOtpCodeWeb(gsmEmail)
        .then((res) => setSMSSendStatus(true))
        .catch((err) => {
          if (err?.response?.status === 400) {
            toast.current?.show({
              severity: "error",
              summary: "HATA",
              detail: `${err?.response?.data?.description}`,
              life: 3000,
            });
          } else {
            handleError(err, toast);
          }
        });
    }
  };

  const verifyVerificationCode = () => {
    if (!verificationCode) {
      return toast.current?.show({
        severity: "warn",
        summary: "Uyarı",
        detail: "Doğrulama kodu boş bırakılamaz",
        life: 3000,
      });
    }
    let replaceCode = verificationCode.replace(/[-]/g, "");
    let body = {
      username: gsmEmail,
      otpCode: replaceCode,
    };
    services
      .loginWithOtpCode(body)
      .then((res) => {
        setSMSVerifyStatus(true);
        localStorage.setItem("token", res.token);
        localStorage.setItem("refreshToken", res.refreshToken);
        services
          .userDetail(res.token)
          .then((res) => {
            if (res) {
              localStorage.setItem("user", JSON.stringify(res));
              setTimeout(() => {
                history.push("/dashboard");
              }, 1000);
            }
          })
          .catch((err) => {
            handleError(err, toast);
          });
      })
      .catch((err) => {
        setSMSVerifyStatus(false);
      });
  };

  const userDetail = (token) => {
    services
      .userDetailWeb(token)
      .then((res) => {
        if (res) {
          localStorage.setItem("user", JSON.stringify(res));
          localStorage.setItem("autoLogin", "false");
          props.history.push("/dashboard");
        }
      })
      .catch((err) => {
        handleError(err, toast);
      });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _login = {...login};
    _login[`${name}`] = val;
    setLogin(_login);
  };

  const enterKey = (e) => {
    if (e.key === "Enter") {
      onLogin();
    }
  };

  return (
    <>
      <div className="login-body">
        <Toast ref={toast}/>
        <div className="login-wrapper">
          <div style={{overflow: "scroll"}} className="login-panel">
            <img
              src="assets/layout/images/logo.png"
              className="logo"
              alt="diamond-layout"
            />
            <div className="login-form p-mt-6">
              <h2>HOŞ GELDİNİZ</h2>
              <br/>
              <br/>

              <TabView
                className="tabview p-shadow-0"
                style={{width: "364px"}}
                id="login-tabview"
              >
                <TabPanel
                  header="Kullanıcı Adı"
                  leftIcon="pi pi-sign-in"
                  headerStyle={{width: "182px"}}
                >
                  <div
                    className="p-fluid p-formgrid p-grid p-ai-center"
                    style={{flexDirection: "column"}}
                  >
                    <div className="p-field p-col-12 p-md-8">
                      <InputText
                        onKeyPress={enterKey}
                        placeholder="Kullanıcı Adı Giriniz"
                        id="username"
                        value={login ? login.username : null}
                        onChange={(e) => onInputChange(e, "username")}
                        required
                        autoFocus
                        className={classNames({
                          "p-invalid": submitted && !login.username,
                        })}
                      />
                      {submitted && !login.username && (
                        <small className="p-invalid">
                          Kullanıcı Adı Gereklidir.
                        </small>
                      )}
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                      <InputText
                        onKeyPress={enterKey}
                        placeholder="Şifre Giriniz"
                        id="password"
                        type="password"
                        value={login ? login.password : null}
                        onChange={(e) => onInputChange(e, "password")}
                        required
                        className={classNames({
                          "p-invalid": submitted && !login.password,
                        })}
                      />
                      {submitted && !login.password && (
                        <small className="p-invalid">Şifre Gereklidir.</small>
                      )}
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                      <div className="p-mt-1">
                        <Checkbox
                          inputId="binary"
                          checked={checked}
                          onChange={(e) => setChecked(e.checked)}
                          className={classNames({
                            "p-invalid": submitted && !checked,
                          })}
                        />

                        <label className="p-ml-2" htmlFor="binary">
                          KVKK Aydınlatma Metni'ni okudum ve kabul ediyorum
                        </label>
                      </div>
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                      <Button
                        onClick={onLogin}
                        loading={loading2}
                        label="GİRİŞ YAP"
                        type="button"
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel
                  header="GSM No / E-Posta"
                  leftIcon="pi pi-sign-in"
                  headerStyle={{width: "182px"}}
                >
                  <div
                    className="p-fluid p-formgrid p-grid p-ai-center"
                    style={{flexDirection: "column"}}
                  >
                    <div className="p-field p-col-12 p-md-8">
                      <InputText
                        placeholder="GSM No (5xxx)  /  E-Posta"
                        value={gsmEmail}
                        onChange={(e) => setGsmEmail(e?.target?.value)}
                        required
                        autoFocus
                        className={classNames({
                          "p-invalid": submittedGsmEmail && !gsmEmail,
                        })}
                      />
                      {submittedGsmEmail && !gsmEmail && (
                        <small className="p-invalid">
                          Gsm No / E-Posta Gereklidir.
                        </small>
                      )}
                    </div>

                    <div className="p-field p-col-12 p-md-8">
                      <div className="p-mt-1">
                        <Checkbox
                          inputId="binary"
                          checked={checkedGsmEmail}
                          onChange={(e) => setCheckedGsmEmail(e.checked)}
                          className={classNames({
                            "p-invalid": submittedGsmEmail && !checkedGsmEmail,
                          })}
                        />

                        <label className="p-ml-2" htmlFor="binary">
                          KVKK Aydınlatma Metni'ni okudum ve kabul ediyorum
                        </label>
                      </div>
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                      <Button
                        label={
                          smsSendStatus === true
                            ? "GSM No / E-Posta Değiştir"
                            : "Doğrulama kodu gönder"
                        }
                        className="p-mt-0"
                        onClick={onLoginWithGsmEmail}
                      />
                    </div>
                  </div>
                  {smsSendStatus && (
                    <div className="p-fluid p-formgrid p-grid p-ai-center">
                      <div
                        className="p-field p-col-12 p-md-12 p-mt-0 "
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <i
                          className="pi pi-arrow-down"
                          style={{fontSize: "1.2em", color: "orange"}}
                        ></i>
                      </div>
                      <div
                        className="p-field p-col-12 p-md-12 p-mt-2 "
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <img src={otpImage} width="60" height="60"/>
                      </div>
                      <div className="p-field p-col-12 p-md-12 p-d-flex p-jc-center">
                        <label
                          style={{fontSize: 16, color: "#689F38"}}
                          className="p-text-bold p-text-center"
                          htmlFor="verificationCode"
                        >
                          Kodu Girin
                        </label>
                      </div>
                      <div className="p-field p-col-12 p-md-12 p-jc-center ">
                        <div className="p-field p-col-12 p-md-12 p-mb-0">
                          <InputMask
                            id="verifyNumber"
                            type="text"
                            style={{fontSize: 16}}
                            className="p-text-center"
                            mask="*-*-*-*-*-*"
                            value={verificationCode}
                            onChange={(e) => {
                              setVerificationCode(e.value);
                              e.value?.replace(/[-_]/g, "").length < 6 &&
                              setSMSVerifyStatus(null);
                            }}
                            keyfilter="num"
                            placeholder="Doğrulama kodu"
                            aria-describedby="phone"
                            disabled={smsVerifyStatus === true}
                          />
                          {smsVerifyStatus !== true && (
                            <small
                              style={{
                                fontSize: 12,
                              }}
                              id="phone"
                            >
                              Almadınız mı ? {""}
                              <b
                                style={{
                                  color: "#2196F3",
                                }}
                                className="kvkk-sendsms "
                                onClick={() => {
                                  onLoginWithGsmEmail();
                                  setSMSVerifyStatus(null);
                                  setVerificationCode("");
                                }}
                              >
                                Yeniden gönder.
                              </b>
                            </small>
                          )}
                        </div>
                        <div className="p-field p-col-12 p-md-12 p-mt-3 ">
                          <Button
                            icon={
                              smsVerifyStatus === true
                                ? "pi pi-check"
                                : smsVerifyStatus === false
                                ? "pi pi-times"
                                : "pi"
                            }
                            iconPos="right"
                            label={
                              smsVerifyStatus === true
                                ? "Kod doğrulandı"
                                : smsVerifyStatus === false
                                ? "Geçersiz Doğrulama Kodu"
                                : "Doğrula ve Giriş Yap"
                            }
                            className={classNames(
                              {
                                "p-button-success": smsVerifyStatus === true,
                              },
                              {
                                "p-d-inline-flex": false,
                              },
                              {
                                "p-button-danger": smsVerifyStatus === false,
                              }
                            )}
                            onClick={verifyVerificationCode}
                            disabled={smsVerifyStatus === true}
                          />
                          {smsVerifyStatus === true && (
                            <h6 style={{textAlign: "center", color: "red"}}>
                              Anasayfaya Yönlendiriliyorsunuz...
                            </h6>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </TabPanel>
              </TabView>

              <div className="p-field p-col-12 p-md-12">
                <p>
                  <a style={{fontSize:'1.1rem',fontWeight:'bold'}} href="#/sifremiunuttum">Yeni Şifre Al</a>
                </p>
                <div
                  className="p-mt-2 p-mb-2"
                  style={{
                    height: 1,
                    width: "100%",
                    backgroundColor: "gray",
                    alignSelf: "center",
                    opacity: 0.2,
                  }}
                />
                <div className="p-field p-col-12 p-md-12">
                  <h5>Kişisel Verilerin Korunması Hakkında</h5>
                  <p className="p-text-justify">
                    ARNECA TEKNOLOJİ LİMİTED ŞİRKETİ olarak,
                    kişisel verilerinizin güvenliği konusunda azami özen ve
                    hassasiyet göstermekteyiz. Bu nedenle yasal mevzuat uyarınca
                    düzenlenmiş Aydınlatma Metnini inceleyip kabul etmeniz
                    gerekmektedir. Aydınlatma Metni'ni{" "}
                    <storng
                      className="kvkk-sendsms"
                      style={{color: "#2196f3"}}
                      onClick={() => setVisibleModal(true)}
                    >
                      görüntülemek için tıklayınız.
                    </storng>
                  </p>
                </div>
              </div>
            </div>

            <br/>
          </div>
          <div className="login-image">
            <div className="login-image-content" align="center">
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <h1>TT-MAP</h1>
              <h3>
                Kullanıcı adı ve şifreniz ile sisteme giriş yapabilirsiniz.{" "}
              </h3>
            </div>
          </div>
        </div>
        <Dialog
          visible={visibleModal}
          header="KİŞİSEL VERİLERİN KORUNMASI HAKKINDA AYDINLATMA METNİ"
          modal
          className="p-fluid p-col-12 p-md-4 p-p-0 "
          position="top"
          onHide={() => setVisibleModal(false)}
        >
          <p>
            ARNECA TEKNOLOJİ LİMİTED ŞİRKETİ olarak, kişisel
            verilerinizin güvenliği konusunda azami özen ve hassasiyet
            göstermekteyiz. Bu nedenle, şirketimize ait web sitesini ziyaret
            ettiğinizde kullanılan uygulamalar neticesinde elde edilen ve/veya
            tarafınızca iletilen her türlü kişisel verilerin, 6698 satılı
            Kişisel Verilerin Korunması Kanunu (KVK Kanunu)’na uygun olarak
            işlenerek muhafaza edileceğini ve “Veri Sorumlusu” sıfatı ile,
            aşağıda açıklandığı şekilde ve mevzuatın emredici sınırları
            dahilinde işleneceğini beyan ederiz.
          </p>
          <p>
            KVK Kanunu kapsamında, şirketimizin aydınlatma yükümlülüğü
            bulunmakta olup, şirketimizin kişisel veri toplama yöntemi, hukuki
            sebepleri, hangi amaçlarla işlendiği ve aktarıldığı bilgileri ve
            veri sahibi olarak kullanabileceğiniz haklarınız aşağıda
            belirtilmektedir. Şirketimiz verileri web sitemizi ziyaret
            ettiğinizde kullanılan çerez (cookie) uygulamaları ile veya çeşitli
            formların tarafınızca doldurularak iletilmesi yolu ile elde
            etmektedir. Kişisel verileriniz; yukarıda belirtmiş olduğumuz hukuki
            sebepler dahilinde; web sitemizi iyileştirmek, ziyaretçilerin
            tercihlerini takip ederek ilgi alanlarına uygun ve
            kişiselleştirilmiş bir web sitesi ziyareti sunmak, web sitemizin en
            çok tercih edilen bölümlerini tanımlayarak web sitemizi
            ziyaretçilerin ihtiyaçlarına göre şekillendirmek ve hizmetlerimizi
            geliştirmek, aramızda akdedilmiş sözleşmelerin ifa edilmesi,
            şirketimize iletmiş olduğunuz teklif, öneri, şikayet, bilgi ve
            taleplerinizin cevaplandırılması, bu hususlarda sizlerle yeniden
            iletişime geçmek, talepleriniz doğrultusunda ürün ve hizmetlerimize
            ilişkin düzenli olarak bilgilendirilmeniz, müşteri memnuniyetleri
            süreçlerini yönetmek, hizmetlerimiz ile ilgili taleplerinizin
            cevaplanması ve bu konuda sizlerle iletişim kurabilmemiz, pazarlama,
            reklam, araştırma ve şirketimizin tabi olduğu mevzuatta öngörülen
            hukuki yükümlülüklerimizin yerine getirilmesi amacıyla
            işlenebilmektedir. Şirketimiz kişisel verilerinizi Kanun’da yer alan
            temel ilkeler ile Kanun’un 8’inci ve 9’uncu maddesine uygun olarak;
            hizmetlerimizi sunabilmek, şirketimize iletmiş olduğunuz
            taleplerinizi cevaplandırmak, sonuçlandırmak, sizlerle iletişime
            geçmek, talebiniz doğrultusunda ürün ve hizmetlerimiz konusunda
            bilgilendirilmeniz, pazarlama, reklam ve ilgi alanlarınız
            doğrultusunda sizlere kişiselleştirilmiş bir web sitesi ziyareti
            sunmak amacıyla hizmetlerinden yararlandığımız veya işbirliği içinde
            olduğumuz tedarikçilerimiz ve iş ortaklarımızla; gerekli denetleme
            ve raporlamalar için bağlı bulunduğumuz şirket ve işortaklarımızla;
            ya da düzenleyici denetleyici kurumlar ve kanunen yetkilendirilmiş
            resmi merciler ve gerçek kişiler gibi üçüncü kişilerle
            paylaşabilmekte, Kanun’un öngördüğü şekilde yurtiçine ve bulut
            bilişim teknolojisinden yararlanmak amacıyla yurtdışına
            aktarılabilmektedir. Şirketimiz kişisel verileriniz aktarılması
            sürecinde Kanun’un 8’inci ve 9’uncu maddelerine uygun hareket
            etmekte ve verilerinizin gerektiği şekilde korunması için gerekli
            her türlü teknik ve idari tedbirleri almaktadır. Tarafınızca
            aktarmış olduğunuz kişisel verileriniz, ilgili bölümü işaretlemek
            suretiyle ayrıca talepte bulunmanız durumunda sunmuş olduğumuz ürün
            ve hizmetlerimiz hakkında düzenli olarak bilgilendirmeniz için
            işlenecektir. Kişisel veri sahibi olarak Kanun ve yürürlükte bulunan
            sair mevzuat çerçevesinde Şirketimize başvurarak kendiniz ile ilgili
            aşağıdaki bilgileri edinme ve yine aşağıda belirtilen işlemleri
            talep etme hakkınız bulunmaktadır.
          </p>
          <p>
            Bu kapsamda kişisel veri sahipleri; Kişisel veri işlenip
            işlenmediğini öğrenme, Kişisel verileri işlenmişse buna ilişkin
            bilgi talep etme, Kişisel verilerin işlenme amacını ve bunların
            amacına uygun kullanılıp kullanılmadığını öğrenme, Yurt içinde veya
            yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,
            Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
            düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel
            verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme, KVK
            Kanunu’nun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş
            olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması
            hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve
            bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü
            kişilere bildirilmesini isteme, İşlenen verilerin münhasıran
            otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin
            kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme, Kişisel
            verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması
            hâlinde zararın giderilmesini talep etme haklarına sahiptir. KVK
            Kanunu’nun 13. maddesinin 1. fıkrası gereğince, yukarıda belirtilen
            haklarınızı kullanmak ile ilgili talebinizi, yazılı veya Kişisel
            Verileri Koruma Kurulu’nun belirlediği diğer yöntemlerle
            Şirketimiz’e iletebilirsiniz. Yukarıda belirtilen haklarınızı
            kullanmak için kimliğinizi tespit edici gerekli bilgiler ile KVK
            Kanunu’nun 11. maddesinde belirtilen haklardan kullanmayı talep
            ettiğiniz hakkınıza yönelik açıklamalarınızı içeren talebinizi;
            Bağlarbaşı Mah. Ergenekon Sok. No:6 Maltepe/ İstanbul adresimize
            yazılı olarak gönderebilirsiniz.
          </p>
        </Dialog>
      </div>
    </>
  );
};
