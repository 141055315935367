import {useEffect, useRef, useState} from "react";
import * as services from "./httpService";
import {Toast} from "primereact/toast";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import errorImage from "../../assets/images/error.png";
import {handleError} from "../../service/HandleErrorService";

export const AutoLogin = () => {
  const toast = useRef();

  const [error, setError] = useState(false);

  const history = useHistory();
  const {token} = useParams();
  const {refreshToken} = useParams();
  const {resellerCode} = useParams();
  const {redirectPage} = useParams();

  useEffect(() => {
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
    loginRequest();
  }, []);

  const loginRequest = () => {
    services
      .userDetail(token)
      .then((res) => {
        console.log("userDetail", res);
        if (res) {
          if(res.user != null && res.user.resellers != null){
            res.user.resellers.forEach(r => {
              if(r.code == resellerCode){
                res.user.loginReseller = r;
              }
            })
          }
          localStorage.setItem("user", JSON.stringify(res));
          localStorage.setItem("autoLogin", "true");
          console.log("redirectPage", redirectPage);
          history.push("/" + redirectPage);
        }
      })
      .catch((err) => {
        setError(true);
        handleError(err, toast);
      });
  };

  return (
    <>
      <Toast ref={toast}/>
      {!error ? (
        <div className="loading-box">
          <div className="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <h6>Yükleniyor... Lütfen bekleyin...</h6>
          </div>
        </div>
      ) : (
        <div className="loading-box">
          <img src={errorImage} width="170" height="170"/>
          <h6>GİRİŞ BAŞARISIZ !</h6>
        </div>
      )}
    </>
  );
};
