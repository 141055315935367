import AspAxios from "../../../service/AspAxios";
import * as Paths from "../../../service/config"
import axios from "axios";
import {getCurrentModelPricesSupplyForExcel} from "../../../service/config";

export default class ProductPriceSupplyService{


  getCurrentModelPrices(searchProductPrice,_page,_size)
  {
    return new Promise((resolve,reject)=>{
      AspAxios.post(Paths.getCurrentModelPricesSupply,searchProductPrice,{params:{size:_size,page:_page}}).then(res=>
        resolve(res.data)

      ).catch(err=>reject(err))
    })

  }
  getCurrentModelPricesList(searchProductPrice)
  {
    return new Promise((resolve,reject)=>{
      AspAxios.post(Paths.getCurrentModelPricesSupplyList,searchProductPrice).then(res=>
        resolve(res.data)

      ).catch(err=>reject(err))
    })

  }
  getCurrentModelPricesSupplyForExcel()
  {
    return new Promise((resolve,reject)=>{
      AspAxios.post(Paths.getCurrentModelPricesSupplyForExcel,{}).then(res=>
        resolve(res.data)

      ).catch(err=>reject(err))
    })

  }

  savePriceExcel(file) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.savePriceExcel, file, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 60000
      }).then(res => {

        resolve(res.data)
      }).catch(err => reject(err))
    })
  }

  currentModelPricePriceDetail(productId)
  {
    return new Promise((resolve,reject)=>{
      AspAxios.get(Paths.getCurrentModelPriceSupply+productId).then(res=>
        resolve(res.data)

      ).catch(err=>reject(err))
    })

  }

  lastModelPrices(productId)
  {
    return new Promise((resolve,reject)=>{
      AspAxios.get(Paths.getLastModelPriceSupply+productId).then(res=>
        resolve(res.data)

      ).catch(err=>reject(err))
    })

  }

  upsert(createProductPrice)
  {
    return new Promise((resolve,reject)=>{
      AspAxios.post(Paths.updateModelPriceSupply,createProductPrice).then(res=>
        resolve(res.data)

      ).catch(err=>reject(err))
    })

  }


  readExcel(excelProductPrices)
  {
    return new Promise((resolve,reject)=>{
      axios.post(Paths.readExcelForModelPriceSupply, {excelProductPrices}).then(res=>{
        resolve(res.data)
      }).catch(err=>reject(err))

    })
  }

  saveExcel(excelProductPrices)
  {
    return new Promise((resolve,reject)=>{
      axios.post(Paths.saveExcelForModelPriceSupply, {excelProductPrices}).then(res=>{
        resolve(res.data)
      }).catch(err=>reject(err))

    })
  }

  modelPriceSupplyRejectedList(modelId) {
    return new Promise((resolve, reject) => {
      axios.get(Paths.modelPriceSupplyRejectedList + modelId).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))
    })
  }

};
