import {Toast} from "primereact/toast";
import {useHistory, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import ProductPriceService from "./ProductPriceDistyService";
import {handleError} from "../../../service/HandleErrorService";
import {InputText} from "primereact/inputtext";
import moment from "moment";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import * as Paths from "../../../service/config"
import {ResponsiveDataTable} from "../../../components/ResponsiveDataTable";
import ProductPriceDistyService from "./ProductPriceDistyService";
import ProductPriceSupplyService from "../supply/ProductPriceSupplyService";
import {InputTextarea} from "primereact/inputtextarea";
import {Calendar} from "primereact/calendar";

const ProductPriceDistyDetail = () => {

  const history = useHistory();
  const toast = useRef(null);
  const {productId, mid, mpsId} = useParams();
  const productPriceService = new ProductPriceDistyService();
  const [currentModelPrice, setCurrentModelPrice] = useState({});
  const [lastModelPriceSupplyList, setLastModelPriceSupplyList] = useState([]);
  const [lastModelPriceDistyList, setLastModelPriceDistyList] = useState([]);
  const [errorFields, setErrorFields] = useState({});
  const [upsertModelPrice, setUpsertModelPrice] = useState({});
  const [hasSupply, setHasSupply] = useState(true);


  useEffect(() => {
    getProductPriceDetail();
  }, [])
  const getProductPriceDetail = () => {

    productPriceService.currentModelPricePriceDetail(productId, mpsId).then(res => {

      setCurrentModelPrice(res);
      let _upsertModelPrice = {...upsertModelPrice};
      if (res?.sid === res?.defaultSupply) {
        setHasSupply(false);

      }

      _upsertModelPrice.taxRatio = res?.taxRatio;
      _upsertModelPrice.distyPrice = res?.distyPrice;
      _upsertModelPrice.recommendedSellPrice = res?.recommendedSellPrice;
      _upsertModelPrice.recommendedResellerPrice = res?.recommendedResellerPrice;
      _upsertModelPrice.resellerPrice = res?.resellerPrice;
      _upsertModelPrice.modelId = res?.mid;

      productPriceService.lastModelPrices(mid).then(res => {
        setLastModelPriceSupplyList(res?.lastModelPriceSupply)
        setLastModelPriceDistyList(res?.lastModelPriceDisty)
      }).catch(err => handleError(err, toast))
      setUpsertModelPrice(_upsertModelPrice)
    }).catch(err => {

    })


  }

  const upsert = () => {
    let _upsertModelPrice = {...upsertModelPrice};

    if (_upsertModelPrice.taxRatio === currentModelPrice.taxRatio
      && _upsertModelPrice.distyPrice === currentModelPrice.distyPrice
      && _upsertModelPrice.recommendedSellPrice === currentModelPrice.recommendedSellPrice
      && _upsertModelPrice.recommendedResellerPrice === currentModelPrice.recommendedResellerPrice) {
      _upsertModelPrice.sameControl = false;
    } else {
      _upsertModelPrice.sameControl = true;
    }


    productPriceService.upsert(_upsertModelPrice).then(res => {
      toast.current.show({
        severity: 'success',
        summary: 'Başarılı İşlem',
        detail: 'Ürün Güncellendi',
        life: 1200,

      });
      getProductPriceDetail();
      setTimeout(() => {
        history.push("/ProductPrice-Disty")

      }, 1300)
      setErrorFields({});

    }).catch(err => setErrorFields(handleError(err, toast)))

  }

  const priceTemplate = (e, name, checkStyle) => {

    if (e[name] === undefined) {
      return <div>Girilmedi.</div>
    } else if (e[checkStyle] === false) {

      return <div style={{backgroundColor: "pink"}}>{e[name]}₺</div>
    } else if (e[checkStyle] === true) {
      return <div style={{backgroundColor: "chartreuse"}}>{e[name]}₺</div>

    } else return <div style={{backgroundColor: 'antiquewhite'}}>{e[name]}₺</div>

  }


  const taxRatioTemplate = (e) => {
    if (e['taxRatio'] == undefined) {
      return <div>Girilmedi.</div>
    } else return <div>%{e['taxRatio']}</div>

  }

  const isInvalid = (bindingField1, bindingField2) => {
    if (errorFields[`${bindingField1}`]) {

      return " p-invalid";
    }
    if (errorFields[`${bindingField2}`]) {
      return " p-invalid";
    }
    return "";
  }
  return (
    <>
      <Toast ref={toast}/>
      <div className="card">

        <h4>Ürün Bilgileri</h4>
        <div className="p-fluid p-formgrid p-grid">

          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="category">Kategori</label>
            <InputText disabled id="category" value={currentModelPrice?.category}/>
          </div>

          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="subCategory">Alt Kategori</label>
            <InputText id="subCategory" disabled value={currentModelPrice?.subCategory}/>
          </div>

          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="brand">Marka</label>
            <InputText id="brand" disabled value={currentModelPrice?.brand}/>
          </div>

          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="product">Ürün İsmi</label>
            <InputText disabled id="product" value={currentModelPrice?.modelName}/>
          </div>

          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="barcode">Barkod</label>
            <InputText id="barcode" disabled value={currentModelPrice?.barcode}/>
          </div>


          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="description">Ürün Açıklaması</label>
            <InputTextarea autoResize id="description" disabled value={currentModelPrice?.description}/>
          </div>

          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="supply">Tedarikçi</label>
            <InputText id="supply" disabled value={currentModelPrice?.supply}/>
          </div>


          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="startDate">Giriş Zamanı</label>
            <InputText disabled id="startDate"

                       value={moment(currentModelPrice?.startDate).format("DD/MM/YYYY")}


            />
          </div>
          {!hasSupply? <div className="p-field p-col-12 p-md-3">

            <label htmlFor="mpsStartDate">Tedarikçi Fiyatlarının Geçerlilik Tarihi</label>
            <Calendar id="mpsStartDate"

                      value={moment(upsertModelPrice.mpsStartDate).format("DD-MM-YYYY")}
                      onChange={e => {
                        let _upsertModelPrice = {...upsertModelPrice};
                        _upsertModelPrice.mpsStartDate = e.target.value;
                        setUpsertModelPrice(_upsertModelPrice)
                      }}

            />

          </div> :""}
          <div className="p-field p-col-12 p-md-3">

            <label htmlFor="startDate">Distribütör Fiyatlarının Geçerlilik Tarihi</label>
            <Calendar id="startDate"

                      value={moment(upsertModelPrice.startDate).format("DD-MM-YYYY")}
                      onChange={e => {
                        let _upsertModelPrice = {...upsertModelPrice};
                        _upsertModelPrice.startDate = e.target.value;
                        setUpsertModelPrice(_upsertModelPrice)
                      }}

            />
          </div>


          <div className="p-field p-col-12 p-md-2">
            <label htmlFor="taxRatio">KDV Oranı</label>
            <InputText id="taxRatio" type="number" step="0,1" value={upsertModelPrice?.taxRatio}
                       className={isInvalid('taxRatio')}

                       onChange={e => {
                         let _errorFields = {...errorFields};
                         _errorFields[e.target.id] = false;
                         if (e.target.value === "") {
                           _errorFields[e.target.id] = true;
                         }
                         setErrorFields(_errorFields)
                         let _upsertModelPrice = {...upsertModelPrice};
                         _upsertModelPrice.taxRatio = parseFloat(e.target.value);
                         setUpsertModelPrice(_upsertModelPrice)
                       }} disabled={hasSupply}/></div>

          <div className="p-field p-col-12 p-md-2">
            <label htmlFor="distyPrice">Distribütör Alış fiyatı</label>
            <InputText id="distyPrice" type="number" step="0,1" value={upsertModelPrice?.distyPrice}
                       className={isInvalid('distyPrice')}

                       onChange={e => {
                         let _errorFields = {...errorFields};
                         _errorFields[e.target.id] = false;
                         if (e.target.value === "") {
                           _errorFields[e.target.id] = true;
                         }
                         setErrorFields(_errorFields)
                         let _upsertModelPrice = {...upsertModelPrice};
                         _upsertModelPrice.distyPrice = parseFloat(e.target.value);
                         setUpsertModelPrice(_upsertModelPrice)
                       }} disabled={hasSupply}/></div>


          <div className="p-field p-col-12 p-md-2">
            <label htmlFor="recommendedResellerPrice">Önerilen Bayi Alış fiyatı</label>
            <InputText id="recommendedResellerPrice" type="number" step="0,1"
                       className={isInvalid('recommendedResellerPrice')}
                       onChange={e => {
                         let _errorFields = {...errorFields};
                         _errorFields[e.target.id] = false;
                         if (e.target.value === "") {
                           _errorFields[e.target.id] = true;
                         }
                         setErrorFields(_errorFields)
                         let _upsertModelPrice = {...upsertModelPrice};
                         _upsertModelPrice.recommendedResellerPrice = parseFloat(e.target.value);
                         setUpsertModelPrice(_upsertModelPrice)
                       }} value={upsertModelPrice?.recommendedResellerPrice}
                       disabled={hasSupply}/>
          </div>

          <div className="p-field p-col-12 p-md-2">
            <label htmlFor="recommendedSellPrice">Önerilen Satış fiyatı</label>
            <InputText id="recommendedSellPrice" type="number" step="0,1" value={upsertModelPrice?.recommendedSellPrice}
                       className={isInvalid('recommendedSellPrice')}
                       onChange={e => {
                         let _errorFields = {...errorFields};
                         _errorFields[e.target.id] = false;
                         if (e.target.value === "") {
                           _errorFields[e.target.id] = true;
                         }
                         setErrorFields(_errorFields)
                         let _upsertModelPrice = {...upsertModelPrice};
                         _upsertModelPrice.recommendedSellPrice = parseFloat(e.target.value);
                         setUpsertModelPrice(_upsertModelPrice)
                       }} disabled={hasSupply}/></div>


          <div className="p-field p-col-12 p-md-2">
            <label htmlFor="resellerPrice">Bayi Alış Fiyatı</label>
            <InputText id="resellerPrice" type="number" step="0,1" value={upsertModelPrice?.resellerPrice}
                       className={isInvalid('resellerPrice')}
                       onChange={e => {
                         let _errorFields = {...errorFields};
                         _errorFields[e.target.id] = false;
                         if (e.target.value === "") {
                           _errorFields[e.target.id] = true;
                         }
                         setErrorFields(_errorFields)
                         let _upsertModelPrice = {...upsertModelPrice};
                         _upsertModelPrice.resellerPrice = parseFloat(e.target.value);
                         setUpsertModelPrice(_upsertModelPrice)
                       }}/></div>


          <div style={{marginTop: 24}} className="p-field p-col-12 p-md-1 p-justify-start">
            <Button label="Güncelle" onClick={upsert}/>
          </div>

        </div>

      </div>


      <div className="p-fluid p-formgrid p-grid">


        <div className="p-field p-col-12 p-md-7">


          <div className="datatable-responsive-demo">
            <div className="card">
              <ResponsiveDataTable value={lastModelPriceSupplyList}
                                   paginator
                                   rows={10} rowsPerPageOptions={[10, 20, 50, 100]}
                                   header="Ürünün Geçmiş Tedarik Fiyat Listesi">
                <Column body={e => moment(e.startDate).format("DD/MM/YYYY")} header="Başlangıç Tarihi"/>
                <Column body={e => moment(e.endDate).format("DD/MM/YYYY")} header="Bitiş Tarihi"/>
                <Column body={taxRatioTemplate} header="KDV Oranı"/>
                <Column body={e => priceTemplate(e, 'distyPrice', 'checkDistyPrice')} header="Distribütör Alış Fiyatı"/>
                <Column body={e => priceTemplate(e, 'recommendedResellerPrice', 'checkRecommendedResellerPrice')}
                        header="Önerilen Bayi Alış Fiyatı"/>
                <Column body={e => priceTemplate(e, 'recommendedSellPrice', 'checkRecommendedSellPrice')}
                        header="Önerilen Satış Fiyatı"/>


              </ResponsiveDataTable>
            </div>
          </div>
        </div>
        <div className="p-field p-col-12 p-md-5">
          <div className="datatable-responsive-demo">
            <div className="card">
              <ResponsiveDataTable value={lastModelPriceDistyList}
                                   paginator
                                   rows={10} rowsPerPageOptions={[10, 20, 50, 100]}
                                   header="Ürünün Geçmiş Distribütör  Fiyat Listesi">
                <Column body={e => moment(e.startDate).format("DD/MM/YYYY")} header="Başlangıç Tarihi"/>
                <Column body={e => moment(e.endDate).format("DD/MM/YYYY")} header="Bitiş Tarihi"/>
                <Column body={e => priceTemplate(e, 'resellerPrice', 'checkResellerPrice')} header="Bayi Alış Fiyatı"/>

              </ResponsiveDataTable>
            </div>
          </div>
        </div>
        <div className="p-col-1">
          <Button label="Geri Dön" onClick={e => history.push("/ProductPrice-Disty")} className="p-button-secondary"/>
        </div>
      </div>

    </>
  )
}
export default ProductPriceDistyDetail;
