import { Help } from "../pages/Help";
import { EmptyPage } from "../pages/EmptyPage";
import { Access } from "../pages/Access";
import ProductPriceSupplyList from "../pages/productprice/supply/ProductPriceSupplyList";
import TtCrmList from "../pages/voucher/TtCrmList";
import AccessoryCampaignList from "../pages/voucher/AccessoryCampaignList";
import CanSell from "../pages/canSell/CanSell";
import ProductExcel from "../pages/product/ProductExcel";
import ProductPriceSupplyDetail from "../pages/productprice/supply/ProductPriceSupplyDetail";
import ProductPriceSupplyExcel from "../pages/productprice/excel/ProductPriceSupplyExcel";
import ProductPriceDistyExcel from "../pages/productprice/excel/productPriceDistyExcel";
import ProductPriceDistyList from "../pages/productprice/disty/ProductPriceDistyList";
import ProductPriceDistyDetail from "../pages/productprice/disty/ProductPriceDistyDetail";
import ProductPriceSupplyListAdmin from "../pages/productprice/admin/ProductPriceSupplyListAdmin";
import ProductPriceDistyListAdmin from "../pages/productprice/admin/ProductPriceDistyListAdmin";
import ProductPriceDetailAdmin from "../pages/productprice/admin/ProductPriceDetailAdmin";
import Model from "../pages/product/model";
import AccessoryCampaignSerialTitleList from "../pages/voucher/AccessoryCampaignSerialTitleList";
import AccessoryCampaignSerialTitle from "../pages/voucher/AccessoryCampaignSerialTitle";
import AccessoryCampaignPassTitleList from "../pages/voucher/AccessoryCampaignPassTitleList";
import AccessoryCampaignPassTitle from "../pages/voucher/AccessoryCampaignPassTitle";
import SaleInfoList from "../pages/productsalesmanagement/SaleInfoList";
import DefineMailSmsTemplate from "../pages/DefineMailSmsTemplate";
import SalesScreen from "../pages/SalesScreen";
import ProductManagement from "../pages/product/ProductManagement";
import ProductDetail from "../pages/product/ProductDetail";
import ProductManagementAdmin from "../pages/product/admin/ProductManagementAdmin";
import ProductDetailAdmin from "../pages/product/admin/ProductDetailAdmin";
import DefineUser from "../pages/DefineUser";
import BrandCreate from "../pages/canSell/BrandCreate";
import DefineTtgUser from "../pages/DefineTtgUser";
import Marj from "../pages/marj/Marj";
import ProductSaleList from "../pages/productsalesmanagement/ProductSaleList";
import CategoryManagement from "../pages/categorymanagement/CategoryManagement";
import WaitingMyAction from "../pages/actions/WaitingMyAction";
import ResellerList from "../pages/reseller/ResellerList";
import ResellerScoreTransactions from "../pages/reseller/ResellerScoreTransactions";
import CancelSale from "../pages/productsalesmanagement/CancelSale";
import CategoryView from "../pages/categorymanagement/CategoryView";
import ResellerProductsManagement from "../pages/resellerordermanagement/ResellerProductsManagement";
import ResellerProductOrderCreate from "../pages/resellerordermanagement/ResellerProductOrderCreate";
import DefineResellerUser from "../pages/DefineResellerUser/DefineResellerUser";
import ModelStockList from "../pages/modelstockdisty/ModelStockList";
import ResellerDistributionProduct from "../pages/reseller/ResellerDistributionProduct";
import ParamList from "../pages/param/ParamList";
import BannerView from "../pages/banner/BannerView";
import StockStatus from "../pages/modelstockdisty/StockStatus";
import AdminResellerList from "../pages/reseller/AdminResellerLogin";
import ProductAddedToDashboard from "../pages/product/admin/ProductAddedToDashboard";
import FaultyProductNotification from "../pages/faultyproductmanagement/FaultyProductNotification";
import FaultyProductExchange from "../pages/faultyproductmanagement/FaultyProductExchange";
import GiftView from "../pages/gifts/GiftView";
import GiftTransaction from "../pages/gifts/GiftTransaction";
import GiftApprove from "../pages/gifts/GiftApprove";
import LastGiftView from "../pages/gifts/LastGiftView";
import ResellerParamList from "../pages/param/ResellerParamList";
import ResellerOrderView from "../pages/resellerordermanagement/ResellerOrderView";
import ReportingMonthly from "../pages/reporting/ReportingMonthly";
import Dashboard from "../pages/dashboard/Dashboard";
import ProductPriceForReseller from "../pages/productprice/reseller/ProductPriceForReseller";
import distyUser from "../pages/DefineUser/DistUser";
import DistUser from "../pages/DefineUser/DistUser";
import MySerialTransfers from "../pages/productsalesmanagement/MySerialTransfers";
import SerialTransfersFromMe from "../pages/productsalesmanagement/SerialTransfersFromMe";
import FaultyProductList from "../pages/faultyproductmanagement/FaultyProductList";
import WaitingMySupplyPrice from "../pages/actions/WaitingMySupplyPrice";
import SupplyInvoiceList from "../pages/productsalesmanagement/SupplyInvoiceList";
import DailySaleReport from "../pages/reporting/DailySaleReport";
import FaultyProductSearch from "../pages/faultyproductmanagement/FaultyProductSearch";
import ProductFaultyUpdate from "../pages/productprice/productfaultyupdate/ProductFaultyUpdate";
import PassiveSerial from "../pages/voucher/PassiveSerial";
import GiftValidDate from "../pages/gifts/GIftValidDate";
import ApproveCampaignSerials from "../pages/gifts/ApproveCampaignSerials";
import ScoreEarning from "../pages/gifts/ScoreEarning";
import StockInfoList from "../pages/productsalesmanagement/StockInfoList";

export const routers = [
  {
    path: "/access",
    component: Access,
    meta: { breadcrumb: [{ parent: "Utilities", label: "YETKİSİZ GİRİŞ" }] },
  },
  {
    path: "/help",
    component: Help,
    meta: { breadcrumb: [{ parent: "Pages", label: "YARDIM" }] },
  },
  {
    path: "/empty",
    component: EmptyPage,
    meta: { breadcrumb: [{ parent: "Pages", label: "BOŞ SAYFA" }] },
  },
  {
    path: '/dashboard',
    exact: true,
    component: Dashboard,
    meta: { breadcrumb: [{ parent: 'Dashboard', label: 'DASHBOARD' }] }
  },

  //PRODUCT

  {
    path: "/Model",
    component: Model,
    meta: { breadcrumb: [{ parent: "Pages", label: "Ürün Ağacı" }] },
    roles: ["TTG_ADMIN", "SUPPLY_USER", "SUPPLY_ADMIN", "DISTY_USER", "DISTY_ADMIN"]
  },
  {
    path: "/ProductManagement",
    component: ProductManagement,
    meta: { breadcrumb: [{ parent: "Pages", label: "Ürün - Kod Yönetimi" }] },
    roles: ["SUPPLY_USER", "SUPPLY_ADMIN", "DISTY_USER", "DISTY_ADMIN"]
  },
  {
    path: "/ProductManagement-Admin",
    component: ProductManagementAdmin,
    meta: { breadcrumb: [{ parent: "Pages", label: "Ürün - Kod Yönetimi" }] },
    roles: ["TTG_ADMIN"]
  },

  {
    path: "/ProductDetail/:productId",
    component: ProductDetail,
    meta: { breadcrumb: [{ parent: "Pages", label: "Ürün Detayı" }] },
    roles: ["SUPPLY_USER", "SUPPLY_ADMIN", "DISTY_USER", "DISTY_ADMIN"]

  },
  {
    path: "/ProductDetailAdmin/:productId",
    component: ProductDetailAdmin,
    meta: { breadcrumb: [{ parent: "Pages", label: "Ürün Detayı" }] },
    roles: ["TTG_ADMIN"]
  },


  //Product Price Supply
  {
    path: "/ProductPrice-Supply",
    component: ProductPriceSupplyList,
    meta: { breadcrumb: [{ parent: "Pages", label: "Ürün - Fiyat Yönetimi" }] },
    roles: ["SUPPLY_USER", "SUPPLY_ADMIN"]
  },

  {
    path: "/ProductFault-Update",
    component: ProductFaultyUpdate,
    meta: { breadcrumb: [{ parent: "Pages", label: "Ürün - Arıza Bilgi" }] },
    roles: ["SUPPLY_USER", "SUPPLY_ADMIN", "DISTY_ADMIN"]
  },



  {
    path: "/ProductPrice-Detail/:productId/:mid",
    component: ProductPriceSupplyDetail,
    meta: { breadcrumb: [{ parent: "Pages", label: "Ürün Fiyat Detayı" }] },
    roles: ["DISTY_USER", "DISTY_ADMIN", "SUPPLY_USER", "SUPPLY_ADMIN"]
  },

  //Excel
  {
    path: "/ProductPrice-Supply-Excel",
    component: ProductPriceSupplyExcel,
    meta: { breadcrumb: [{ parent: "Pages", label: "Ürün Fiyat Toplu Güncelleme - Excelden Aktarım " }] },
    roles: ["DISTY_USER", "DISTY_ADMIN", "SUPPLY_USER", "SUPPLY_ADMIN"]
  },

  {
    path: "/ProductPrice-Disty-Excel",
    component: ProductPriceDistyExcel,
    meta: { breadcrumb: [{ parent: "Pages", label: "Ürün Fiyat Toplu Güncelleme - Excelden Aktarım (Distribütör)" }] },
    roles: ["DISTY_USER", "DISTY_ADMIN"]
  },

  {
    path: "/DefineUser",
    component: DefineUser,
    meta: {
      breadcrumb: [{ parent: "Pages", label: "KULLANICI TANIMLAMA" }],
    },
    roles: ["TTG_ADMIN", "RESELLER_ADMIN"]
  },

  {
    path: "/DefineResellerUser",
    component: DefineResellerUser,
    meta: {
      breadcrumb: [{ parent: "Pages", label: "BAYİ KULLANICISI TANIMLAMA" }],
    },
    roles: ["TTG_ADMIN"]
  },


  {
    path: "/DefineTTGUser",
    component: DefineTtgUser,
    meta: {
      breadcrumb: [{ parent: "Pages", label: "TURK TELEKOM KULLANICISI TANIMLAMA" }],
    },
    roles: ["TTG_ADMIN"]
  },

  //ProductPrice Disty
  {
    path: "/ProductPrice-Disty",
    component: ProductPriceDistyList,
    meta: { breadcrumb: [{ parent: "Pages", label: "Ürün - Fiyat Yönetimi " }] },
    roles: ["DISTY_USER", "DISTY_ADMIN"]
  },


  {
    path: "/ProductPrice-Detail-Disty/:productId/:mid/:mpsId",
    component: ProductPriceDistyDetail,
    meta: { breadcrumb: [{ parent: "Pages", label: "Ürün Detayı" }] },
    roles: ["DISTY_USER", "DISTY_ADMIN"]
  },

  //PRODUCTPRICE-ADMIN

  /* {
     path: "/ProductPriceAdmin-Supply",
     component: ProductPriceSupplyListAdmin,
     meta: { breadcrumb: [{ parent: "Pages", label: "Ürün Fiyat Listesi" }] },
   },*/
  {
    path: "/ProductPriceAdmin-Disty",
    component: ProductPriceDistyListAdmin,
    meta: { breadcrumb: [{ parent: "Pages", label: "Ürün - Fiyat Yönetimi" }] },
    roles: ["TTG_ADMIN"]
  },

  {
    path: "/ProductPriceAdmin-Detail",
    component: ProductPriceDetailAdmin,
    meta: { breadcrumb: [{ parent: "Pages", label: "Ürün Fiyat Detayı" }] },
    roles: ["TTG_ADMIN"]
  },




  //Marka
  {
    path: "/brandCreate",
    component: BrandCreate,
    meta: { breadcrumb: [{ parent: "Pages", label: "Marka Tanımlama" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/CanSell",
    component: CanSell,
    meta: { breadcrumb: [{ parent: "Pages", label: "Marka-Tedarikçi " }] },
    roles: ["TTG_ADMIN"]
  },


  {
    path: "/ProductExcel",
    component: ProductExcel,
    meta: { breadcrumb: [{ parent: "Pages", label: "Ürün Kod - Excelden Aktarım" }] },
    roles: ["SUPPLY_USER", "SUPPLY_ADMIN", "DISTY_USER", "DISTY_ADMIN"]
  },
  {
    path: "/ttCrmList",
    component: TtCrmList,
    meta: { breadcrumb: [{ parent: "Pages", label: "TT CRM" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/accessoryCampaignList",
    component: AccessoryCampaignList,
    meta: { breadcrumb: [{ parent: "Pages", label: "KAMPANYA TANIMLAMA" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/accessoryCampaignSerialTitleList",
    component: AccessoryCampaignSerialTitleList,
    meta: { breadcrumb: [{ parent: "Pages", label: "SERİ NUMARALARI" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/accessoryCampaignSerialTitle",
    component: AccessoryCampaignSerialTitle,
    meta: { breadcrumb: [{ parent: "Pages", label: "SERİ NUMARA İŞLEMLERİ" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/accessoryCampaignPassTitleList",
    component: AccessoryCampaignPassTitleList,
    meta: { breadcrumb: [{ parent: "Pages", label: "ŞİFRELER" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/accessoryCampaignPassTitle",
    component: AccessoryCampaignPassTitle,
    meta: { breadcrumb: [{ parent: "Pages", label: "ŞİFRE İŞLEMLERİ" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/approveCampaignSerials",
    component: ApproveCampaignSerials,
    meta: { breadcrumb: [{ parent: "Pages", label: "KAMPANYA SERİSİ İLE PUAN ONAYLAMA" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/saleInfoList",
    component: SaleInfoList,
    meta: { breadcrumb: [{ parent: "Pages", label: "DİSTRİBÜTÖR DAĞITIM BİLGİLERİ" }] },
    roles: ["TTG_ADMIN", "DISTY_ADMIN", "SUPPLY_ADMIN", "RESELLER_ADMIN", "RESELLER_USER"]
  },
  {
    path: "/stockInfoList",
    component: StockInfoList,
    meta: { breadcrumb: [{ parent: "Pages", label: "STOĞUMDAKİ ÜRÜNLER" }] },
    roles: ["RESELLER_ADMIN", "RESELLER_USER"]
  },
  {
    path: "/supplyInvoiceList",
    component: SupplyInvoiceList,
    meta: { breadcrumb: [{ parent: "Pages", label: "TEDARİKÇİ DAĞITIM BİLGİLERİ" }] },
    roles: ["TTG_ADMIN", "DISTY_ADMIN", "SUPPLY_ADMIN"]
  },
  {
    path: "/mySerialTransfers",
    component: MySerialTransfers,
    meta: { breadcrumb: [{ parent: "Pages", label: "BAYİ SERİ NO DEVİR" }] },
    roles: ["RESELLER_ADMIN"]
  },
  {
    path: "/serialTransfersFromMe",
    component: SerialTransfersFromMe,
    meta: { breadcrumb: [{ parent: "Pages", label: "BENDEN DEVİR ALINAN SERİLER" }] },
    roles: ["RESELLER_ADMIN"]
  },
  {
    path: "/productSaleList",
    component: ProductSaleList,
    meta: { breadcrumb: [{ parent: "Pages", label: "İŞLEMLER" }] },
    roles: ["TTG_ADMIN", "DISTY_ADMIN", "SUPPLY_ADMIN", "RESELLER_ADMIN", "RESELLER_USER", "TTG_CUSTOMER_SERVICE"]
  },
  {
    path: "/cancelSale",
    component: CancelSale,
    meta: { breadcrumb: [{ parent: "Pages", label: "İŞLEM İPTAL" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/passiveSerial",
    component: PassiveSerial,
    meta: { breadcrumb: [{ parent: "Pages", label: "SERİ NUMARALARI PASİFE ALMA" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/modelStockList",
    component: ModelStockList,
    meta: { breadcrumb: [{ parent: "Pages", label: "DİSTRİBÜTÖR STOK BİLGİLERİ" }] },
    roles: ["TTG_ADMIN", "DISTY_ADMIN", "SUPPLY_ADMIN"]
  },
  {
    path: "/paramList",
    component: ParamList,
    meta: { breadcrumb: [{ parent: "Pages", label: "PARAMETRELER" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/resellerParamList",
    component: ResellerParamList,
    meta: { breadcrumb: [{ parent: "Pages", label: "BAYİ PARAMETRELERİ" }] },
    roles: ["RESELLER_ADMIN"]
  },
  {
    path: "/stockStatus",
    component: StockStatus,
    meta: { breadcrumb: [{ parent: "Pages", label: "ALT KATEGORİ STOK DURUMLARI" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/resellerList",
    component: ResellerList,
    meta: { breadcrumb: [{ parent: "Pages", label: "BAYİ LİSTESİ" }] },
    roles: ["TTG_ADMIN", "DISTY_ADMIN"]
  },
  {
    path: "/adminResellerLogin",
    component: AdminResellerList,
    meta: { breadcrumb: [{ parent: "Pages", label: "BAYİ OLARAK GİRİŞ" }] },
    roles: ["TTG_ADMIN"]
  },
  /*{
    path: "/resellerScoreTransactions",
    component: ResellerScoreTransactions,
    meta: { breadcrumb: [{ parent: "Pages", label: "BAYİ PUAN İŞLEMLERİ" }] },
    roles: ["RESELLER_ADMIN"]
  },*/
  {
    path: "/faultyProductNotification",
    component: FaultyProductNotification,
    meta: { breadcrumb: [{ parent: "Pages", label: "ARIZALI ÜRÜN BİLDİRİMİ" }] },
    roles: ["RESELLER_ADMIN", "RESELLER_USER"]
  },
  {
    path: "/faultyProductList",
    component: FaultyProductList,
    meta: { breadcrumb: [{ parent: "Pages", label: "ARIZALI ÜRÜN BİLDİRİMLERİM" }] },
    roles: ["RESELLER_ADMIN", "RESELLER_USER"]
  },
  {
    path: "/faultyProductExchange",
    component: FaultyProductExchange,
    meta: { breadcrumb: [{ parent: "Pages", label: "ARIZALI ÜRÜN DEĞİŞİMİ" }] },
    roles: ["SUPPLY_ADMIN", "SUPPLY_USER"]
  },
  {
    path: "/faultyProductSearch",
    component: FaultyProductSearch,
    meta: { breadcrumb: [{ parent: "Pages", label: "ARIZALI ÜRÜN BİLDİRİMLERİ" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/smsMailTemplate",
    component: DefineMailSmsTemplate,
    meta: { breadcrumb: [{ parent: "Pages", label: "SMS-Mail Tanımlama" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/satis",
    component: SalesScreen,
    meta: { breadcrumb: [{ parent: "Pages", label: "Satış Uygulaması" }] },
    roles: ["TTG_ADMIN"]
  },

  {
    path: "/Marj",
    component: Marj,
    meta: { breadcrumb: [{ parent: "Pages", label: "Tolerans Oranı Yönetimi" }] },
    roles: ["TTG_ADMIN"]
  },

  {
    path: "/CategoryManagement",
    component: CategoryManagement,
    meta: { breadcrumb: [{ parent: "Pages", label: "Kategori Yönetimi" }] },
    roles: ["TTG_ADMIN"]
  },

  {
    path: "/CategoryView",
    component: CategoryView,
    meta: { breadcrumb: [{ parent: "Pages", label: "Kategori ve Alt Kategori Ekranı" }] },
    roles: ["SUPPLY_USER", "SUPPLY_ADMIN", "DISTY_USER", "DISTY_ADMIN"]
  },

  {
    path: "/WaitingMyAction",
    component: WaitingMyAction,
    meta: { breadcrumb: [{ parent: "Pages", label: "Onay Bekleyen Ürünler" }] },
    roles: ["TTG_ADMIN"]
  },

  {
    path: "/WaitingMySupplyPrice",
    component: WaitingMySupplyPrice,
    meta: { breadcrumb: [{ parent: "Pages", label: "Onay Bekleyen Ürün Fiyatları" }] },
    roles: ["DISTY_ADMIN"]
  },

  {
    path: "/resellerOrderManagement",
    component: ResellerProductsManagement,
    meta: { breadcrumb: [{ parent: "Pages", label: "SİPARİŞ YÖNETİMİ" }] },
    roles: ["RESELLER_ADMIN"]
  },


  {
    path: "/resellerProduct/:productId/:barcode",
    component: ResellerProductOrderCreate,
    meta: { breadcrumb: [{ parent: "Pages", label: "ÜRÜN BİLGİLERİ" }] },
    roles: ["RESELLER_ADMIN"]
  },
  {
    path: "/resellerProductOrder/follow",
    component: ResellerOrderView,
    meta: { breadcrumb: [{ parent: "Pages", label: "BAYİ SİPARİŞ TAKİBİ" }] },
    roles: ["RESELLER_ADMIN", "TTG_ADMIN", "DISTY_ADMIN"]
  },

  {
    path: "/productPriceInfoList",
    component: ResellerDistributionProduct,
    meta: { breadcrumb: [{ parent: "Pages", label: "ÜRÜN FİYAT BILGILERI" }] },
    roles: ["RESELLER_ADMIN"]
  },

  {
    path: "/bannerList",
    component: BannerView,
    meta: { breadcrumb: [{ parent: "Pages", label: "BANNER TANIMLAMA" }] },
    roles: ["TTG_ADMIN"]
  },

  {
    path: "/addToDashboard",
    component: ProductAddedToDashboard,
    meta: { breadcrumb: [{ parent: "Pages", label: "BAYİ SİPARİŞ ANA SAYFAYA ÜRÜN EKLEME" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/defineResellerScoreOfGift",
    component: GiftView,
    meta: { breadcrumb: [{ parent: "Pages", label: "BAYİ PUAN HEDİYELERİ TANIMLAMA" }] },
    roles: ["TTG_ADMIN"]
  },

  {
    path: "/approveResellerScoreOfGift",
    component: GiftApprove,
    meta: { breadcrumb: [{ parent: "Pages", label: "BAYİ PUAN HEDİYELERİ ONAYLAMA" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/scoreEarning",
    component: ScoreEarning,
    meta: { breadcrumb: [{ parent: "Pages", label: "PUAN KAZANIMI DURUMU" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/defineResellerScoreOfGiftValidDate",
    component: GiftValidDate,
    meta: { breadcrumb: [{ parent: "Pages", label: "HEDİYE KATALOĞU AÇIK OLACAĞI TARİHLERİ BELİRLEME" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/lastResellerScoreOfGift",
    component: LastGiftView,
    meta: { breadcrumb: [{ parent: "Pages", label: "BAYİ PUAN HEDİYELERİM " }] },
    roles: ["RESELLER_ADMIN", "RESELLER_USER"]
  },
  {
    path: "/giftTransaction",
    component: GiftTransaction,
    meta: { breadcrumb: [{ parent: "Pages", label: "BAYİ PUAN İLE ÜRÜN ALMA SİSTEMİ" }] },
    roles: ["RESELLER_ADMIN", "RESELLER_USER"]
  },
  {
    path: "/reportingMonthly",
    component: ReportingMonthly,
    meta: { breadcrumb: [{ parent: "Pages", label: "SON 6 AY SATIŞ RAPORU" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/dailySaleReport",
    component: DailySaleReport,
    meta: { breadcrumb: [{ parent: "Pages", label: "GÜNLÜK SATIŞ RAPORU" }] },
    roles: ["TTG_ADMIN"]
  },
  {
    path: "/productPrices",
    component: ProductPriceForReseller,
    meta: { breadcrumb: [{ parent: "Pages", label: "ÜRÜN BİLGİLERİ" }] },
    roles: ["RESELLER_ADMIN"]
  },
  {
    path: "/addUserDist",
    component: DistUser,
    meta: { breadcrumb: [{ parent: "Pages", label: "KULLANICI TANIMLAMA" }] },
    roles: ["DISTY_ADMIN"]
  },




];
