import * as Paths from "../../service/config";
import AspAxios from "../../service/AspAxios";

function clean(obj) {
  let _obj = {};
  for (const propName in obj) {
    if (obj[propName] !== "") {
      _obj[propName] = obj[propName];
    }
  }
  return _obj;
}

export function login(Login) {
  return new Promise((resolve, reject) => {
    AspAxios.post(Paths.login, clean(Login))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function takeOtpCodeWeb(gsmEmail) {
  return new Promise((resolve, reject) => {
    AspAxios.get(Paths.takeOtpCodeWeb + gsmEmail)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function loginWithOtpCode(body) {
  return new Promise((resolve, reject) => {
    AspAxios.post(Paths.loginWithOtpCode,body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function userDetail(token) {
  return new Promise((resolve, reject) => {
    AspAxios.get(Paths.userDetail)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function userDetailWeb(token) {
  return new Promise((resolve, reject) => {
    AspAxios.get(Paths.userDetailWeb)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function userLogout(token) {
  return new Promise((resolve, reject) => {
    AspAxios.post(Paths.logout, clean(token))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function forgetPassword(forget) {
  return new Promise((resolve, reject) => {
    AspAxios.post(Paths.forgetPassword, clean(forget))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function resetPassword(resetPassword) {
  return new Promise((resolve, reject) => {
    AspAxios.post(Paths.resetPassword, clean(resetPassword))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function refreshToken() {
  return new Promise((resolve, reject) => {
    const request = {
      token: localStorage.getItem("token"),
      refreshToken: localStorage.getItem("refreshToken"),
    };
    AspAxios.post(Paths.refresh, clean(request))
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        resolve(res.data);
      })
      .catch((err) => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        reject(err);
      });
  });
}

export function resellerLogin(body) {
  return new Promise((resolve, reject) => {
    AspAxios.post(Paths.resellerLogin, body, {
      headers: {
        "Accept-Language": "tr-TR",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getResellerGsmNoList(ticketId) {
  return new Promise((resolve, reject) => {
    AspAxios.get(Paths.getResellerGsmNoList + ticketId, {
      headers: {
        "Accept-Language": "tr-TR",
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function sendVerificationCode(authTicket, gsmNo) {
  return new Promise((resolve, reject) => {
    AspAxios.get(Paths.sendVerificationCode + authTicket + "/" + gsmNo)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
