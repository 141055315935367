import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import ModelStockDistyService from "./httpService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import classNames from "classnames";
import {InputNumber} from "primereact/inputnumber";
import {Checkbox} from "primereact/checkbox";

const StockStatus = () => {

  const toast = useRef(null);
  const modelStockDistyService = new ModelStockDistyService();

  const [loading, setLoading] = useState(false);
  const [subCategoryList, setSubCategoryList] = useState(null);

  const [updateAllSubmitted, setUpdateAllSubmitted] = useState(false);
  const [selectedNoneStockStatusMaxParam, setSelectedNoneStockStatusMaxParam] = useState(null);
  const [selectedLimitedStockStatusMaxParam, setSelectedLimitedStockStatusMaxParam] = useState(null);
  const [selectedUpdateStockStatusMaxManuelUpdated, setSelectedUpdateStockStatusMaxManuelUpdated] = useState(null);
  const [updateAllButtonDisabled, setUpdateAllButtonDisabled] = useState(false);

  const [updateButtonDisabled, setUpdateButtonDisabled] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [selectedSubCategoryName, setSelectedSubCategoryName] = useState(null);
  const [selectedNoneStockStatusMax, setSelectedNoneStockStatusMax] = useState(null);
  const [selectedLimitedStockStatusMax, setSelectedLimitedStockStatusMax] = useState(null);

  useEffect(() => {
    getParams();
    getAllSubCategories();
  }, []);

  const getParams = () => {
    modelStockDistyService.getParameterByKey("NONE_STOCK_STATUS_MAX").then(res => {
      console.log("getParameterByKey", res);
      setSelectedNoneStockStatusMaxParam(res.value);
    }).catch(err => {
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Stok yok sınır getirme başarısız.'
      });
      console.log(err);
    });
    modelStockDistyService.getParameterByKey("LIMITED_STOCK_STATUS_MAX").then(res => {
      console.log("getParameterByKey", res);
      setSelectedLimitedStockStatusMaxParam(res.value);
    }).catch(err => {
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Stok limitli sınır getirme başarısız.'
      });
      console.log(err);
    });
  };

  const getAllSubCategories = () => {
    setLoading(true);
    modelStockDistyService.getAllSubCategories().then(res => {
      console.log("getAllSubCategories", res);
      res.sort((a, b) => (a.name > b.name) ? 1 : -1);
      setSubCategoryList(res);
    }).catch(err => {
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Alt kategori bilgisi getirme başarısız.'
      });
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  };

  const updateModalShow = (e) => {
    setSubmitted(false);
    setUpdateModal(true);
    subCategoryList.forEach(p => {
      if (p.id == e.currentTarget.id) {
        setSelectedSubCategoryId(p.id);
        setSelectedSubCategoryName(p.name);
        setSelectedNoneStockStatusMax(p.noneStockStatusMax);
        setSelectedLimitedStockStatusMax(p.limitedStockStatusMax);
      }
    });
  };

  const updateModalHide = () => {
    setUpdateModal(false);
  };

  const updateStockStaus = () => {
    setSubmitted(true);
    setUpdateButtonDisabled(true);
    if (selectedNoneStockStatusMax != null && selectedLimitedStockStatusMax != null) {
      if (selectedNoneStockStatusMax < selectedLimitedStockStatusMax) {
        const request = {
          subCategoryId: selectedSubCategoryId,
          noneStockStatusMax: selectedNoneStockStatusMax,
          limitedStockStatusMax: selectedLimitedStockStatusMax
        };
        modelStockDistyService.updateStatusMax(request).then(res => {
          console.log("updateStatusMax", res);
          toast.current.show({
            severity: 'success',
            summary: 'Başarılı İşlem',
            detail: 'Stok durumu düzenlendi.'
          });
          updateModalHide();
          getAllSubCategories();
        }).catch(err => {
          console.log(err);
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: 'Stok durumu düzenlenemedi.'
          });
        }).finally(() => {
          setUpdateButtonDisabled(false);
        })
      } else {
        toast.current.show({
          severity: 'warn',
          summary: 'Uyarı',
          detail: 'Stok yok için stok limitliden daha küçük bir değer girilmelidir.'
        });
        setUpdateButtonDisabled(false);
      }
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Bütün alanlar dolu olmalıdır.'
      });
      setUpdateButtonDisabled(false);
    }
  };

  const updateAllStockStaus = () => {
    setUpdateAllSubmitted(true);
    setUpdateAllButtonDisabled(true);
    if (selectedNoneStockStatusMaxParam != null && selectedLimitedStockStatusMaxParam != null) {
      if (selectedNoneStockStatusMaxParam < selectedLimitedStockStatusMaxParam) {
        const request = {
          noneStockStatusMax: selectedNoneStockStatusMaxParam,
          limitedStockStatusMax: selectedLimitedStockStatusMaxParam,
          updateStockStatusMaxManuelUpdated: selectedUpdateStockStatusMaxManuelUpdated
        };
        modelStockDistyService.updateAllStatusMax(request).then(res => {
          console.log("updateAllStatusMax", res);
          toast.current.show({
            severity: 'success',
            summary: 'Başarılı İşlem',
            detail: 'Stok durumu güncellendi.'
          });
          setSelectedUpdateStockStatusMaxManuelUpdated(false);
          getParams();
          getAllSubCategories();
        }).catch(err => {
          console.log(err);
          toast.current.show({
            severity: 'error',
            summary: 'Hata',
            detail: 'Stok durumu güncellenemedi.'
          });
        }).finally(() => {
          setUpdateAllButtonDisabled(false);
        })
      } else {
        toast.current.show({
          severity: 'warn',
          summary: 'Uyarı',
          detail: 'Stok yok için stok limitliden daha küçük bir değer girilmelidir.'
        });
        setUpdateAllButtonDisabled(false);
      }
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Bütün alanlar dolu olmalıdır.'
      });
      setUpdateAllButtonDisabled(false);
    }
  };

  const addCommas = num => num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const responsiveColumnRight = (title, content) => {
    return (
      <div>
        <span className="p-column-title">{title}</span>
        <span style={{float: 'right'}}>{content}</span>
      </div>
    );
  };

  const nameTemplate = (data) => {
    return responsiveColumn("Alt Kategori Ad", data?.name)
  };

  const noneStockStatusMaxTemplate = (data) => {
    return responsiveColumnRight("Stok Yok Sınır", addCommas(data?.noneStockStatusMax))
  };

  const limitedStockStatusMaxTemplate = (data) => {
    return responsiveColumnRight("Stok Limitli Sınır", addCommas(data?.limitedStockStatusMax))
  };

  const parametreButtonTemplate = (data) => {
    return (
      <div className="p-d-flex p-justify-even">
        <Button id={data.id} type="button" className="p-button-sm p-button-info" label="Düzenle"
                disabled={data.manuelUpdateDisabled}
                onClick={updateModalShow}/>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-12 p-md-3 p-fluid">Stok Yok Sınır
            <InputNumber style={{marginTop: 10}} value={selectedNoneStockStatusMaxParam} min={0}
                         onChange={(e) => setSelectedNoneStockStatusMaxParam(e.value)}
                         className={classNames({'p-invalid': updateAllSubmitted && !selectedNoneStockStatusMaxParam})}/>
                         Stok Limitli Sınır
            <InputNumber style={{marginTop: 10}} value={selectedLimitedStockStatusMaxParam} min={0}
                         onChange={(e) => setSelectedLimitedStockStatusMaxParam(e.value)}
                         className={classNames({'p-invalid': updateAllSubmitted && !selectedLimitedStockStatusMaxParam})}/>
            <Checkbox style={{marginTop: 10, marginRight: 5}} checked={selectedUpdateStockStatusMaxManuelUpdated}
                      onChange={(e) => setSelectedUpdateStockStatusMaxManuelUpdated(e.checked)}/>Elle Girilmiş Ürünlerde
            Güncellensin
            <Button style={{marginTop: 10}} label="Hepsini Güncelle"
                    disabled={updateAllButtonDisabled} onClick={updateAllStockStaus}/>
          </div>
          <div className="p-col-12">
            <ResponsiveDataTable
              style={{marginTop: 5}}
              className="p-datatable-responsive-demo"
              header="Alt Kategori Stok Durumları"
              value={subCategoryList}
              loading={loading}
              emptyMessage="Alt kategori bulunamadı"
              paginator rows={10} rowsPerPageOptions={[10, 20, 30]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} arası gösteriliyor"
            >
              <Column field="name" body={nameTemplate} header="Alt Kategori Ad" filter={true} sortable={true}
                      filterMatchMode={"contains"}/>
              <Column field="noneStockStatusMax" body={noneStockStatusMaxTemplate} header="Stok Yok Sınır" filter={true}
                      sortable={true}
                      filterMatchMode={"contains"} headerStyle={{textAlign: 'right'}}/>
              <Column field="limitedStockStatusMax" body={limitedStockStatusMaxTemplate} header="Stok Limitli Sınır"
                      filter={true} sortable={true}
                      filterMatchMode={"contains"} headerStyle={{textAlign: 'right'}}/>
              <Column body={parametreButtonTemplate}
                      headerStyle={{width: "8rem", textAlign: "center"}}
                      bodyStyle={{textAlign: 'center', overflow: 'visible', justifyContent: 'center'}}/>
            </ResponsiveDataTable>
          </div>
        </div>
        <Dialog header="Stok Durumu Düzenle" visible={updateModal} modal style={{width: '320px'}}
                onHide={updateModalHide}>
          <div className="p-grid p-fluid">
            <div className="p-col-12">
           <span className="p-float-label marginInput">
            <InputText id="name" value={selectedSubCategoryName}
                       disabled={true}/>
            <label htmlFor="name">Alt Kategori Ad</label>
           </span>
              <span className="p-float-label marginInput">
            <InputNumber id="selectedNoneStockStatusMax" value={selectedNoneStockStatusMax}
                         onChange={(e) => setSelectedNoneStockStatusMax(e.value)} min={0}
                         className={classNames({'p-invalid': submitted && !selectedNoneStockStatusMax})}/>
            <label htmlFor="selectedNoneStockStatusMax">Stok Yok Sınır</label>
           </span>
              <span className="p-float-label marginInput">
            <InputNumber id="selectedLimitedStockStatusMax" value={selectedLimitedStockStatusMax}
                         onChange={(e) => setSelectedLimitedStockStatusMax(e.value)} min={0}
                         className={classNames({'p-invalid': submitted && !selectedLimitedStockStatusMax})}/>
            <label htmlFor="selectedLimitedStockStatusMax">Stok Limitli Sınır</label>
           </span>
              <Button style={{marginTop: 20}} className="p-button-success" label="Kaydet"
                      disabled={updateButtonDisabled} onClick={updateStockStaus}/>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  )

};

export default StockStatus;
