import {handleError} from "../../../service/HandleErrorService";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {FileUpload} from "primereact/fileupload";
import {Dialog} from "primereact/dialog";
import {TabPanel, TabView} from "primereact/tabview";
import {ResponsiveDataTable} from "../../../components/ResponsiveDataTable";
import {Column} from "primereact/column";
import {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import ProductPriceSupplyService from "../supply/ProductPriceSupplyService";
import moment from "moment";


export const ProductPriceSupplyExcel = () => {

  const history = useHistory();
  const [loading,setLoading]=useState(false);

  const toast = useRef(null);
  const productPriceService = new ProductPriceSupplyService();
  const [importedData, setImportedData] = useState([]);
  const [selectedImportedData, setSelectedImportedData] = useState([]);
  const [importedCols, setImportedCols] = useState([{field: '', header: 'Header'}]);
  const [excelDialogOpen, setExcelDialogOpen] = useState(false);
  const [excelFailData, setExcelFailData] = useState([]);
  const [checkData, setCheckData] = useState([]);
  const [checkClear, setCheckClear] = useState(false);
  const [excelImported, setExcelImported] = useState(false);
  const [success, setSuccess] = useState(false);
  const [currentProductPrices, setCurrentProductPrices] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  useEffect(() => {

    getExampleListExcel();
  }, [])


  const getExampleListExcel = () => {

    productPriceService.getCurrentModelPricesSupplyForExcel().then(res => {
      let __currentProductPrices = [...currentProductPrices];
      res?.forEach(value => {
        let _currentProductPrice = {};
        _currentProductPrice['Barkod'] = value.model.barcode;

        value.startDate=(value?.startDate.replace("T","").replace("-","").replace("-","").replace(":","").replace(":","")
          .substring(0,14));

        _currentProductPrice['Ürün Fiyatlarının Geçerli Olacağı Tarih'] = value?.startDate;
        _currentProductPrice['Distribütör Alış Fiyatı'] = value?.distyPrice;
        _currentProductPrice['Önerilen EDM Alış Fiyatı'] = value?.recommendedEdmPrice;
        _currentProductPrice['Önerilen Bayi Alış Fiyatı'] = value?.recommendedResellerPrice;
        _currentProductPrice['Önerilen Satış Fiyatı'] = value?.recommendedSellPrice;
        _currentProductPrice['KDV Oranı'] = value?.taxRatio;
        __currentProductPrices.push(_currentProductPrice);

      })

      setCurrentProductPrices(__currentProductPrices)

    }).catch(err => handleError(err, toast))
  }
  const exampleModelPrice = [{
    "Barkod": 291403,
    "Ürün Fiyatlarının Geçerli Olacağı Tarih": 20211030,
    "Distribütör Alış Fiyatı": 45.50,
    "Önerilen EDM Alış Fiyatı": 48,
    "Önerilen Bayi Alış Fiyatı": 50,
    "Önerilen Satış Fiyatı": 55.00,
    "Kdv Oranı": 18,
  }];

  const exportExcel = () => {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(exampleModelPrice);
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      saveAsExcelFile(excelBuffer, 'Tedarikçi Ürün Fiyatı Listesi');
    });
  }

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  const importExcel = ({files}) => {
    if (files.length === 1) {
      const file = files[0];
      let formData = new FormData();
      formData.append('file', file);
      productPriceService.savePriceExcel(formData);
      files.length = 0;
      import('xlsx').then(xlsx => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const wb = xlsx?.read(e.target.result, {type: 'array'});
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = xlsx.utils.sheet_to_json(ws, {header: 1});

          // Prepare DataTable
          const cols = data[0];
          data.shift();


          let _importedCols = cols?.map(col => ({field: col, header: toCapitalize(col)}));
          let _importedData = data.map(d => {
            return cols.reduce((obj, c, i) => {
              obj[c] = d[i];
              return obj;
            }, {});
          });

          let excelUpdateProductPrices = (_importedData.map(value => {
            if (value['Barkod'] !== undefined && value['Barkod'] !== null) {
              value['Barkod'] = value['Barkod'].toString()

            }
            if (value['Ürün Fiyatlarının Geçerli Olacağı Tarih'] !== undefined) {

              value['Ürün Fiyatlarının Geçerli Olacağı Tarih'] = value['Ürün Fiyatlarının Geçerli Olacağı Tarih'].toString();
              const _year = value['Ürün Fiyatlarının Geçerli Olacağı Tarih'].substring(0, 4);
              const _mont = value['Ürün Fiyatlarının Geçerli Olacağı Tarih'].substring(4, 6);
              const _day = value['Ürün Fiyatlarının Geçerli Olacağı Tarih'].substring(6, 8);
              const _hour = "16";
              const _min = "00";
              const _second = "00";

              value['Ürün Fiyatlarının Geçerli Olacağı Tarih'] = new Date(_year, (_mont - 1), _day, _hour, _min, _second);
              value['Ürün Fiyatlarının Geçerli Olacağı Tarih'] = moment(value['Ürün Fiyatlarının Geçerli Olacağı Tarih']).format();

            }
            return value;
          }));

          let _excelProductPrices = (excelUpdateProductPrices.map(value => {
            return {

              barcode: value['Barkod'] ? value['Barkod'] : null,
              startDate: value['Ürün Fiyatlarının Geçerli Olacağı Tarih'] ? value['Ürün Fiyatlarının Geçerli Olacağı Tarih'] : null,
              distyPrice: value['Distribütör Alış Fiyatı'] ? value['Distribütör Alış Fiyatı'] : null,
              recommendedEdmPrice: value['Önerilen EDM Alış Fiyatı'] ? value['Önerilen EDM Alış Fiyatı'] : null,
              recommendedResellerPrice: value['Önerilen Bayi Alış Fiyatı'] ? value['Önerilen Bayi Alış Fiyatı'] : null,
              recommendedSellPrice: value['Önerilen Satış Fiyatı'] ? value['Önerilen Satış Fiyatı'] : null,
              taxRatio: value['Kdv Oranı'] ? value['Kdv Oranı'] : null,


            }
          }));

          productPriceService.readExcel(_excelProductPrices).then(res => {
            let _checkBarcode = false;
            let _checkError = false;
            setCheckClear(true);
            if (res.errorData !== undefined) {
              res.errorData.forEach(value => {
                if (value.errorMaps['barcode']) {
                  _checkBarcode = true;
                }
              })
              _checkError = true;
            }

            if (!_checkError) {
              toast.current?.show({
                severity: "info",
                detail: 'Hatalı Ürün Bulunamadı',
                life: 3000,
              });
            } else {
              toast.current?.show({
                severity: "info",
                detail: 'Hatalı Ürün bulundu',
                life: 3000,
              });
            }

            /* if (_checkBarcode) {
               toast.current?.show({
                 severity: "error",
                 detail: 'Yüklemek istediğiniz ürün barkodlarının yüklenmesi gerekmektedir',
                 life: 3000,
               });
             }*/

            setCheckData(res?.checkData);
            setExcelFailData(res?.errorData)

            setExcelDialogOpen(true);
            setExcelImported(true);
            setSuccess(false);
          }).catch(err => handleError(err, toast))
          setImportedCols(_importedCols);
          setImportedData(_importedData);
        };

        reader.readAsArrayBuffer(file);
      });
    }
  }


  const toCapitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  const showExcelDialog = () => {
    setExcelDialogOpen(true);

  }
  const hideExcelDialog = () => {
    setExcelDialogOpen(false);


  }
  const excelDialogFooter = (
    <>
      <Button loading={loading} disabled={loading} label="Vazgeç" onClick={hideExcelDialog} icon="pi pi-times" className="p-button-text"/>
      <Button loading={loading} disabled={loading}  label="Onaylanan Ürünleri Kaydet" onClick={e => excelDataUpsert(e)} icon="pi pi-check"/>
    </>
  );

  const excelDataUpsert = (e) => {
    setLoading(true);
    productPriceService.saveExcel(checkData).then(res => {

      if (res) {
        setSuccess(true);
        toast.current?.show({
          severity: "success",
          detail: 'İşleminiz Onaya Gönderilmiştir',
          life: 3000,
        });
      } else {
        toast.current?.show({
          severity: "warn",
          detail: 'Onaylanan Ürün Bulunamadı',
          life: 3000,
        });
      }
      setImportedCols(null)
      setCheckClear(false);
      setCheckData([]);
      setExcelFailData([]);
      setExcelDialogOpen(false);
      setLoading(false);


    }).catch(err => {
      handleError(err, toast)
      setLoading(false);
    });
  }
  const errorTempate = (e, name) => {

    let boolean = e?.errorMaps !== undefined && e.errorMaps[name] === true;
    if (boolean) {
      return <div style={{backgroundColor: 'pink'}}>{e[name] ? e[name] : "Boş Değer"}</div>
    } else if (name === "startDate") {
      return <div>{moment(e.startDate).format("DD-MM-YYYY")}</div>

    } else {

      return <div>{e[name]}</div>

    }

  }
  const clear = () => {
    setImportedData([]);
    setExcelFailData([]);
    setCheckData([]);
    setSelectedImportedData([]);
    setCheckClear(false)
    setImportedCols([{field: '', header: 'Header'}]);
  }
  return (
    <>

      <Toast ref={toast}/>
      <div className="p-d-flex p-ai-center p-py-2">


        <Button label="Örnek Excel doküman indir" onClick={e => exportExcel(e)} className="p-button-help"/>


        <FileUpload
          maxFileSize={100000000}
          chooseOptions={{label: 'Excel Dokümanı Yükle', icon: 'pi pi-file-excel', className: 'p-button-success'}}
          mode="basic" name="demo[]" auto
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          className="p-mr-2"  customUpload={true} uploadHandler={importExcel}/>

        {checkClear ? <Button type="button" style={{marginRight: 10}}
                              label="Yüklenen Verileri Temizle" icon="pi pi-times"
                              onClick={clear} className="p-button-info"/> : ""}
      </div>


      <TabView>
        <TabPanel header="Onaylanan Veriler">
          <div className="datatable-responsive-demo">
            <div className="p-col-2" style={{
              backgroundColor: 'aliceblue',
              fontSize: 16,
              marginBottom: 25
            }}>{checkData?.length > 0 ? `Yüklenecek ${checkData.length} adet kayıt vardır` : success ? "Girilen talebiniz Distiribütör onayına gönderildi": excelImported ? "Yüklenebilecek ürün bilgileri listelenmiştir" :"doğru kayıt bulunamadı"}</div>

            <ResponsiveDataTable loading={loading} value={checkData}
                                 paginator rows={20} onPage={0}
                                 emptyMessage="Ürün Bulunamadı.">

              <Column body={e => errorTempate(e, 'barcode')} header="Barkod"/>
              <Column body={e => errorTempate(e, 'taxRatio')} header=" KDV Oranı"/>
              <Column body={e => moment(e.startDate).format("DD-MM-YYYY")} header="Ürün Fiyatlarının Geçerli Olacağı Tarih"/>
              <Column body={e => errorTempate(e, 'distyPrice')} header="Distribütör Alış Fiyatı"/>
              <Column body={e => errorTempate(e, 'recommendedEdmPrice')} header="Önerilen EDM Alış Fiyatı"/>
              <Column body={e => errorTempate(e, 'recommendedResellerPrice')} header="Önerilen Bayi Alış Fiyatı"/>
              <Column body={e => errorTempate(e, 'recommendedSellPrice')} header="Önerilen Satış Fiyatı"/>

            </ResponsiveDataTable>
            <Button label="Onaylanan Ürünleri Kaydet" disabled={loading} onClick={e => excelDataUpsert(e)} icon="pi pi-check"/>
          </div>
        </TabPanel>
        <TabPanel header="Hatalı Olan Veriler">
          <div className="datatable-responsive-demo">
            <div className="p-col-2" style={{
              backgroundColor: 'aliceblue',
              fontSize: 16,
              marginBottom: 25
            }}>{excelFailData?.length > 0 ? `${excelFailData.length} tane hatalı kayıt bulundu` : "hatalı kayıt bulunamadı"}</div>

            <ResponsiveDataTable value={excelFailData}
                                 loading={loading}
                                 paginator rows={20} onPage={0}
                                 emptyMessage="Ürün Bulunamadı.">
              <Column body={e => errorTempate(e, 'barcode')} header="Barkod"/>
              <Column body={e => errorTempate(e, 'taxRatio')} header="KDV Oranı"/>
              <Column body={e => errorTempate(e, 'startDate')} header="Ürün Fiyatlarının Geçerli Olacağı Tarih"/>
              <Column body={e => errorTempate(e, 'distyPrice')} header="Distribütör Alış Fiyatı"/>
              <Column body={e => errorTempate(e, 'recommendedEdmPrice')} header="Önerilen EDM Alış Fiyatı"/>
              <Column body={e => errorTempate(e, 'recommendedResellerPrice')} header="Önerilen Bayi Alış Fiyatı"/>
              <Column body={e => errorTempate(e, 'recommendedSellPrice')} header="Önerilen Satış Fiyatı"/>

            </ResponsiveDataTable>
          </div>
        </TabPanel>


      </TabView>


    </>
  )
}
export default ProductPriceSupplyExcel
