import AspAxios from "../../service/AspAxios";
import * as Paths from '../../service/config'

export default class VoucherService {

  getAllTtCrmList() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.ttCrmGetAll).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  ttCrmUpdateSerialUsage(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.ttCrmUpdateSerialUsage, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  accessoryCampaignGetAll() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.accessoryCampaignGetAll).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  accessoryCampaignGetAllWithPassive() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.accessoryCampaignGetAllWithPassive).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  upsertAccessoryCampaign(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.upsertAccessoryCampaign, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  passiveAccessoryCampaign(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.passiveAccessoryCampaign, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  activeAccessoryCampaign(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.activeAccessoryCampaign, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  getSerialInfo() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getSerialInfo).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  getRemainingSerials() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getRemainingSerials).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  getRemainingPass() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getRemainingPass).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  getAllSerialTitles(_size, _page) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllSerialTitles, {params: {size: _size, page: _page}}).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  getAllSerialTitlesBySerialValue(serialValue, _size, _page) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllSerialTitles + "?serialValue=" + serialValue, {params: {size: _size, page: _page}}).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  getAllSerialTitlesByResellerCode(resellerCode, _size, _page) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllSerialTitles + "?resellerCode=" + resellerCode, {params: {size: _size, page: _page}}).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }


  getAllSerialBySerialValue(serialValue) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllSerial + "?serialValue=" + serialValue).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  getAllSerialByResellerCode(resellerCode) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllSerial + "?resellerCode=" + resellerCode).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  getSerialTitleById(id) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getSerialTitleById + "/" + id).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  createSerial(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.createSerial, request, {timeout: 600000}).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  serialTitleActivate(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.serialTitleActivate, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  serialTitleDelete(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.serialTitleDelete, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  getPassInfo() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getPassInfo).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  getAllPassTitles(_size, _page) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllPassTitles, {params: {size: _size, page: _page}}).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  getAllPassTitlesByPassValue(passValue, _size, _page) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllPassTitles + "/" + passValue, {params: {size: _size, page: _page}}).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  getPassTitleById(id) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getPassTitleById + "/" + id).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  createPass(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.createPass, request, {timeout: 600000}).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  passTitleActivate(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.passTitleActivate, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  passTitleDelete(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.passTitleDelete, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  searchSerial(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.searchSerial, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  cancelSerials(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.cancelSerials, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

};
