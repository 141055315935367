import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import {handleError} from "../../service/HandleErrorService";
import ReportingService from "./httpService";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {SelectButton} from "primereact/selectbutton";
import {Chart} from "primereact/chart";
import {Row} from "primereact/row";
import {ColumnGroup} from "primereact/columngroup";
import {Button} from "primereact/button";

const ReportingMonthly = () => {

  const toast = useRef(null);
  const reportingService = new ReportingService();

  const [lastMonth, setSelectedLastMonth] = useState(6);
  const [calculationsMonthlyList, setCalculationsMonthlyList] = useState([]);
  const [selectedDimensionType, setSelectedDimensionType] = useState("BRAND");
  const [columnName1, setColumnName1] = useState(null);
  const [columnName2, setColumnName2] = useState(null);
  const [columnName3, setColumnName3] = useState(null);
  const [columnName4, setColumnName4] = useState(null);
  const [columnName5, setColumnName5] = useState(null);
  const [columnName6, setColumnName6] = useState(null);
  const [saleQuantityChartData, setSaleQuantityChartData] = useState(null);
  const [salePriceChartData, setSalePriceChartData] = useState(null);
  const [saleQuantityTotal1, setSaleQuantityTotal1] = useState(0);
  const [saleQuantityTotal2, setSaleQuantityTotal2] = useState(0);
  const [saleQuantityTotal3, setSaleQuantityTotal3] = useState(0);
  const [saleQuantityTotal4, setSaleQuantityTotal4] = useState(0);
  const [saleQuantityTotal5, setSaleQuantityTotal5] = useState(0);
  const [saleQuantityTotal6, setSaleQuantityTotal6] = useState(0);
  const [salePriceTotal1, setSalePriceTotal1] = useState(0);
  const [salePriceTotal2, setSalePriceTotal2] = useState(0);
  const [salePriceTotal3, setSalePriceTotal3] = useState(0);
  const [salePriceTotal4, setSalePriceTotal4] = useState(0);
  const [salePriceTotal5, setSalePriceTotal5] = useState(0);
  const [salePriceTotal6, setSalePriceTotal6] = useState(0);

  const [dimensionTypeOptions, setDimensionTypeOptions] = useState(
    [
      {value: "BRAND", name: "Marka"},
      {value: "SALE_TYPE", name: "Satış Tipi"},
      {value: "RESELLER_TYPE", name: "Bayi Tipi"},
      {value: "RESELLER_MAIN_AREA", name: "Bayi Ana Bölge"},
      {value: "DISTY", name: "Distribütör"},
      {value: "TT_CRM", name: "TT CRM"}
    ]
  );

  const [monthOptions, setMonthOptions] = useState(
    [
      {value: 1, name: "Ocak"},
      {value: 2, name: "Şubat"},
      {value: 3, name: "Mart"},
      {value: 4, name: "Nisan"},
      {value: 5, name: "Mayıs"},
      {value: 6, name: "Haziran"},
      {value: 7, name: "Temmuz"},
      {value: 8, name: "Ağustos"},
      {value: 9, name: "Eylül"},
      {value: 10, name: "Ekim"},
      {value: 11, name: "Kasım"},
      {value: 12, name: "Aralık"}
    ]
  );

  const [colorOptions, setColorOptions] = useState([
      "#42A5F5",
      "#66BB6A",
      "#FFA726",
      "#26C6DA",
      "#7E57C2",
      "#DEB887",
      "#FF00FF",
      "#FFD700",
      "#ADFF2F",
      "#FA8072"
    ]
  );

  const stackedOptions = {
    maintainAspectRatio: false,
    aspectRatio: .8,
    plugins: {
      tooltips: {
        mode: 'index',
        intersect: false
      },
      legend: {
        labels: {
          color: '#495057'
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      },
      y: {
        stacked: true,
        ticks: {
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      }
    }
  };

  useEffect(() => {
    if (lastMonth != null) {
      const d = new Date();
      const month = d.getMonth() + 1;

      var columnNames = [];
      for (let i = lastMonth - 1; i >= 0; i--) {
        if (month - i > 0) {
          monthOptions.forEach(m => {
            if (m.value == month - i) {
              columnNames.push(m.name);
            }
          })
        } else {
          monthOptions.forEach(m => {
            if (m.value == 12 + month - i) {
              columnNames.push(m.name);
            }
          })
        }
      }

      setColumnName1(columnNames[0]);
      setColumnName2(columnNames[1]);
      setColumnName3(columnNames[2]);
      setColumnName4(columnNames[3]);
      setColumnName5(columnNames[4]);
      setColumnName6(columnNames[5]);
    }
  }, [lastMonth]);

  useEffect(() => {
    if (selectedDimensionType != null && lastMonth != null && columnName1 != null) {
      getCalculationsMonthly();
    }
  }, [selectedDimensionType, lastMonth, columnName1]);

  const getCalculationsMonthly = () => {
    reportingService.getCalculationsMonthly(selectedDimensionType, lastMonth).then(res => {
      console.log("getCalculationsMonthly", res);

      let saleQuantityData = {
        labels: [],
        datasets: []
      };
      saleQuantityData.labels.push(columnName1);
      saleQuantityData.labels.push(columnName2);
      saleQuantityData.labels.push(columnName3);
      saleQuantityData.labels.push(columnName4);
      saleQuantityData.labels.push(columnName5);
      saleQuantityData.labels.push(columnName6);

      let salePricetData = {
        labels: [],
        datasets: []
      };
      salePricetData.labels.push(columnName1);
      salePricetData.labels.push(columnName2);
      salePricetData.labels.push(columnName3);
      salePricetData.labels.push(columnName4);
      salePricetData.labels.push(columnName5);
      salePricetData.labels.push(columnName6);

      let totalQuantity1 = 0;
      let totalQuantity2 = 0;
      let totalQuantity3 = 0;
      let totalQuantity4 = 0;
      let totalQuantity5 = 0;
      let totalQuantity6 = 0;
      let saleQuantityIndex = 0;
      res.forEach(r => {
        r.saleQuantity1 = r.saleQuantityList[0];
        totalQuantity1 += r.saleQuantityList[0];

        r.saleQuantity2 = r.saleQuantityList[1];
        totalQuantity2 += r.saleQuantityList[1];

        r.saleQuantity3 = r.saleQuantityList[2];
        totalQuantity3 += r.saleQuantityList[2];

        r.saleQuantity4 = r.saleQuantityList[3];
        totalQuantity4 += r.saleQuantityList[3];

        r.saleQuantity5 = r.saleQuantityList[4];
        totalQuantity5 += r.saleQuantityList[4];

        r.saleQuantity6 = r.saleQuantityList[5];
        totalQuantity6 += r.saleQuantityList[5];

        saleQuantityData.datasets.push({
          type: 'bar',
          label: r.dimensionKey,
          backgroundColor: colorOptions[saleQuantityIndex],
          data: [
            r.saleQuantityList[0],
            r.saleQuantityList[1],
            r.saleQuantityList[2],
            r.saleQuantityList[3],
            r.saleQuantityList[4],
            r.saleQuantityList[5]
          ]
        });
        saleQuantityIndex++;
      });

      let totalPrice1 = 0;
      let totalPrice2 = 0;
      let totalPrice3 = 0;
      let totalPrice4 = 0;
      let totalPrice5 = 0;
      let totalPrice6 = 0;
      let salePriceIndex = 0;
      res.forEach(r => {
        r.salePrice1 = r.salePriceList[0];
        totalPrice1 += r.salePriceList[0];

        r.salePrice2 = r.salePriceList[1];
        totalPrice2 += r.salePriceList[1];

        r.salePrice3 = r.salePriceList[2];
        totalPrice3 += r.salePriceList[2];

        r.salePrice4 = r.salePriceList[3];
        totalPrice4 += r.salePriceList[3];

        r.salePrice5 = r.salePriceList[4];
        totalPrice5 += r.salePriceList[4];

        r.salePrice6 = r.salePriceList[5];
        totalPrice6 += r.salePriceList[5];

        salePricetData.datasets.push({
          type: 'bar',
          label: r.dimensionKey,
          backgroundColor: colorOptions[salePriceIndex],
          data: [
            r.salePriceList[0],
            r.salePriceList[1],
            r.salePriceList[2],
            r.salePriceList[3],
            r.salePriceList[4],
            r.salePriceList[5]
          ]
        });
        salePriceIndex++;
      });

      setSaleQuantityChartData(saleQuantityData);
      setSalePriceChartData(salePricetData);
      setCalculationsMonthlyList(res);

      setSaleQuantityTotal1(totalQuantity1);
      setSaleQuantityTotal2(totalQuantity2);
      setSaleQuantityTotal3(totalQuantity3);
      setSaleQuantityTotal4(totalQuantity4);
      setSaleQuantityTotal5(totalQuantity5);
      setSaleQuantityTotal6(totalQuantity6);

      setSalePriceTotal1(totalPrice1);
      setSalePriceTotal2(totalPrice2);
      setSalePriceTotal3(totalPrice3);
      setSalePriceTotal4(totalPrice4);
      setSalePriceTotal5(totalPrice5);
      setSalePriceTotal6(totalPrice6);
    }).catch(err => handleError(err, toast))
  };

  const onChangeselectedDimensionType = (e) => {
    if (e?.value != null) {
      setSelectedDimensionType(e?.value);
      setSaleQuantityChartData(null);
      setSalePriceChartData(null);
    }
  };

  const addCommas = num => num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const saleQuantityBody1 = (rowData) => {
    return <div>{addCommas(rowData.saleQuantity1)}</div>
  };

  const saleQuantityBody2 = (rowData) => {
    return <div>{addCommas(rowData.saleQuantity2)}</div>
  };

  const saleQuantityBody3 = (rowData) => {
    return <div>{addCommas(rowData.saleQuantity3)}</div>
  };

  const saleQuantityBody4 = (rowData) => {
    return <div>{addCommas(rowData.saleQuantity4)}</div>
  };

  const saleQuantityBody5 = (rowData) => {
    return <div>{addCommas(rowData.saleQuantity5)}</div>
  };

  const saleQuantityBody6 = (rowData) => {
    return <div>{addCommas(rowData.saleQuantity6)}</div>
  };

  const addCommasWithToFixed = num => num?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const salePriceBody1 = (rowData) => {
    return <div>{addCommasWithToFixed(rowData.salePrice1)}</div>
  };

  const salePriceBody2 = (rowData) => {
    return <div>{addCommasWithToFixed(rowData.salePrice2)}</div>
  };

  const salePriceBody3 = (rowData) => {
    return <div>{addCommasWithToFixed(rowData.salePrice3)}</div>
  };

  const salePriceBody4 = (rowData) => {
    return <div>{addCommasWithToFixed(rowData.salePrice4)}</div>
  };

  const salePriceBody5 = (rowData) => {
    return <div>{addCommasWithToFixed(rowData.salePrice5)}</div>
  };

  const salePriceBody6 = (rowData) => {
    return <div>{addCommasWithToFixed(rowData.salePrice6)}</div>
  };

  const exportQuantityExcel = () => {
    import('xlsx').then(xlsx => {
      var excelList = [];
      calculationsMonthlyList.forEach(c => {
        if(columnName1 == monthOptions[0].name){
          excelList.push({
            "": c.dimensionKey,
            "Ocak": c.saleQuantity1,
            "Şubat": c.saleQuantity2,
            "Mart": c.saleQuantity3,
            "Nisan": c.saleQuantity4,
            "Mayıs": c.saleQuantity5,
            "Haziran": c.saleQuantity6
          })
        }

        if(columnName1 == monthOptions[1].name){
          excelList.push({
            "": c.dimensionKey,
            "Şubat": c.saleQuantity1,
            "Mart": c.saleQuantity2,
            "Nisan": c.saleQuantity3,
            "Mayıs": c.saleQuantity4,
            "Haziran": c.saleQuantity5,
            "Temmuz": c.saleQuantity6
          })
        }

        if(columnName1 == monthOptions[2].name){
          excelList.push({
            "": c.dimensionKey,
            "Mart": c.saleQuantity1,
            "Nisan": c.saleQuantity2,
            "Mayıs": c.saleQuantity3,
            "Haziran": c.saleQuantity4,
            "Temmuz": c.saleQuantity5,
            "Ağustos": c.saleQuantity6
          })
        }

        if(columnName1 == monthOptions[3].name){
          excelList.push({
            "": c.dimensionKey,
            "Nisan": c.saleQuantity1,
            "Mayıs": c.saleQuantity2,
            "Haziran": c.saleQuantity3,
            "Temmuz": c.saleQuantity4,
            "Ağustos": c.saleQuantity5,
            "Eylül": c.saleQuantity6
          })
        }

        if(columnName1 == monthOptions[4].name){
          excelList.push({
            "": c.dimensionKey,
            "Mayıs": c.saleQuantity1,
            "Haziran": c.saleQuantity2,
            "Temmuz": c.saleQuantity3,
            "Ağustos": c.saleQuantity4,
            "Eylül": c.saleQuantity5,
            "Ekim": c.saleQuantity6
          })
        }

        if(columnName1 == monthOptions[5].name){
          excelList.push({
            "": c.dimensionKey,
            "Haziran": c.saleQuantity1,
            "Temmuz": c.saleQuantity2,
            "Ağustos": c.saleQuantity3,
            "Eylül": c.saleQuantity4,
            "Ekim": c.saleQuantity5,
            "Kasım": c.saleQuantity6
          })
        }

        if(columnName1 == monthOptions[6].name){
          excelList.push({
            "": c.dimensionKey,
            "Temmuz": c.saleQuantity1,
            "Ağustos": c.saleQuantity2,
            "Eylül": c.saleQuantity3,
            "Ekim": c.saleQuantity4,
            "Kasım": c.saleQuantity5,
            "Aralık": c.saleQuantity6
          })
        }

        if(columnName1 == monthOptions[7].name){
          excelList.push({
            "": c.dimensionKey,
            "Ağustos": c.saleQuantity1,
            "Eylül": c.saleQuantity2,
            "Ekim": c.saleQuantity3,
            "Kasım": c.saleQuantity4,
            "Aralık": c.saleQuantity5,
            "Ocak": c.saleQuantity6
          })
        }

        if(columnName1 == monthOptions[8].name){
          excelList.push({
            "": c.dimensionKey,
            "Eylül": c.saleQuantity1,
            "Ekim": c.saleQuantity2,
            "Kasım": c.saleQuantity3,
            "Aralık": c.saleQuantity4,
            "Ocak": c.saleQuantity5,
            "Şubat": c.saleQuantity6
          })
        }

        if(columnName1 == monthOptions[9].name){
          excelList.push({
            "": c.dimensionKey,
            "Ekim": c.saleQuantity1,
            "Kasım": c.saleQuantity2,
            "Aralık": c.saleQuantity3,
            "Ocak": c.saleQuantity4,
            "Şubat": c.saleQuantity5,
            "Mart": c.saleQuantity6
          })
        }

        if(columnName1 == monthOptions[10].name){
          excelList.push({
            "": c.dimensionKey,
            "Kasım": c.saleQuantity1,
            "Aralık": c.saleQuantity2,
            "Ocak": c.saleQuantity3,
            "Şubat": c.saleQuantity4,
            "Mart": c.saleQuantity5,
            "Nisan": c.saleQuantity6
          })
        }

        if(columnName1 == monthOptions[11].name){
          excelList.push({
            "": c.dimensionKey,
            "Aralık": c.saleQuantity1,
            "Ocak": c.saleQuantity2,
            "Şubat": c.saleQuantity3,
            "Mart": c.saleQuantity4,
            "Nisan": c.saleQuantity5,
            "Mayıs":  c.saleQuantity6
          })
        }
      });


      if(columnName1 == monthOptions[0].name){
        excelList.push({
          "": "Toplam",
          "Ocak":saleQuantityTotal1,
          "Şubat":saleQuantityTotal2,
          "Mart":saleQuantityTotal3,
          "Nisan":saleQuantityTotal4,
          "Mayıs":saleQuantityTotal5,
          "Haziran":saleQuantityTotal6
        })
      }

      if(columnName1 == monthOptions[1].name){
        excelList.push({
          "": "Toplam",
          "Şubat":saleQuantityTotal1,
          "Mart":saleQuantityTotal2,
          "Nisan":saleQuantityTotal3,
          "Mayıs":saleQuantityTotal4,
          "Haziran":saleQuantityTotal5,
          "Temmuz":saleQuantityTotal6
        })
      }

      if(columnName1 == monthOptions[2].name){
        excelList.push({
          "": "Toplam",
          "Mart":saleQuantityTotal1,
          "Nisan":saleQuantityTotal2,
          "Mayıs":saleQuantityTotal3,
          "Haziran":saleQuantityTotal4,
          "Temmuz":saleQuantityTotal5,
          "Ağustos":saleQuantityTotal6
        })
      }

      if(columnName1 == monthOptions[3].name){
        excelList.push({
          "": "Toplam",
          "Nisan":saleQuantityTotal1,
          "Mayıs":saleQuantityTotal2,
          "Haziran":saleQuantityTotal3,
          "Temmuz":saleQuantityTotal4,
          "Ağustos":saleQuantityTotal5,
          "Eylül":saleQuantityTotal6
        })
      }

      if(columnName1 == monthOptions[4].name){
        excelList.push({
          "": "Toplam",
          "Mayıs":saleQuantityTotal1,
          "Haziran":saleQuantityTotal2,
          "Temmuz":saleQuantityTotal3,
          "Ağustos":saleQuantityTotal4,
          "Eylül":saleQuantityTotal5,
          "Ekim":saleQuantityTotal6
        })
      }

      if(columnName1 == monthOptions[5].name){
        excelList.push({
          "": "Toplam",
          "Haziran":saleQuantityTotal1,
          "Temmuz":saleQuantityTotal2,
          "Ağustos":saleQuantityTotal3,
          "Eylül":saleQuantityTotal4,
          "Ekim":saleQuantityTotal5,
          "Kasım":saleQuantityTotal6
        })
      }

      if(columnName1 == monthOptions[6].name){
        excelList.push({
          "": "Toplam",
          "Temmuz":saleQuantityTotal1,
          "Ağustos":saleQuantityTotal2,
          "Eylül":saleQuantityTotal3,
          "Ekim":saleQuantityTotal4,
          "Kasım":saleQuantityTotal5,
          "Aralık":saleQuantityTotal6
        })
      }

      if(columnName1 == monthOptions[7].name){
        excelList.push({
          "": "Toplam",
          "Ağustos":saleQuantityTotal1,
          "Eylül":saleQuantityTotal2,
          "Ekim":saleQuantityTotal3,
          "Kasım":saleQuantityTotal4,
          "Aralık":saleQuantityTotal5,
          "Ocak":saleQuantityTotal6
        })
      }

      if(columnName1 == monthOptions[8].name){
        excelList.push({
          "": "Toplam",
          "Eylül":saleQuantityTotal1,
          "Ekim":saleQuantityTotal2,
          "Kasım":saleQuantityTotal3,
          "Aralık":saleQuantityTotal4,
          "Ocak":saleQuantityTotal5,
          "Şubat":saleQuantityTotal6
        })
      }

      if(columnName1 == monthOptions[9].name){
        excelList.push({
          "": "Toplam",
          "Ekim":saleQuantityTotal1,
          "Kasım":saleQuantityTotal2,
          "Aralık":saleQuantityTotal3,
          "Ocak":saleQuantityTotal4,
          "Şubat":saleQuantityTotal5,
          "Mart":saleQuantityTotal6
        })
      }

      if(columnName1 == monthOptions[10].name){
        excelList.push({
          "": "Toplam",
          "Kasım":saleQuantityTotal1,
          "Aralık":saleQuantityTotal2,
          "Ocak":saleQuantityTotal3,
          "Şubat":saleQuantityTotal4,
          "Mart":saleQuantityTotal5,
          "Nisan":saleQuantityTotal6
        })
      }

      if(columnName1 == monthOptions[11].name){
        excelList.push({
          "": "Toplam",
          "Aralık":saleQuantityTotal1,
          "Ocak":saleQuantityTotal2,
          "Şubat":saleQuantityTotal3,
          "Mart":saleQuantityTotal4,
          "Nisan":saleQuantityTotal5,
          "Mayıs": saleQuantityTotal6
        })
      }

      const worksheet = xlsx.utils.json_to_sheet(excelList);
      var wscols = [
        {width: 10},
        {width: 10},
        {width: 10},
        {width: 10},
        {width: 10},
        {width: 10},
        {width: 10}
      ];
      worksheet["!cols"] = wscols;
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      saveAsExcelFile(excelBuffer, 'Aksesuar Satış Adetleri');
    });
  };

  const exportPriceExcel = () => {
    import('xlsx').then(xlsx => {
      var excelList = [];
      calculationsMonthlyList.forEach(c => {
        if(columnName1 == monthOptions[0].name){
          excelList.push({
            "": c.dimensionKey,
            "Ocak": c.salePrice1,
            "Şubat": c.salePrice2,
            "Mart": c.salePrice3,
            "Nisan": c.salePrice4,
            "Mayıs": c.salePrice5,
            "Haziran": c.salePrice6
          })
        }

        if(columnName1 == monthOptions[1].name){
          excelList.push({
            "": c.dimensionKey,
            "Şubat": c.salePrice1,
            "Mart": c.salePrice2,
            "Nisan": c.salePrice3,
            "Mayıs": c.salePrice4,
            "Haziran": c.salePrice5,
            "Temmuz": c.salePrice6
          })
        }

        if(columnName1 == monthOptions[2].name){
          excelList.push({
            "": c.dimensionKey,
            "Mart": c.salePrice1,
            "Nisan": c.salePrice2,
            "Mayıs": c.salePrice3,
            "Haziran": c.salePrice4,
            "Temmuz": c.salePrice5,
            "Ağustos": c.salePrice6
          })
        }

        if(columnName1 == monthOptions[3].name){
          excelList.push({
            "": c.dimensionKey,
            "Nisan": c.salePrice1,
            "Mayıs": c.salePrice2,
            "Haziran": c.salePrice3,
            "Temmuz": c.salePrice4,
            "Ağustos": c.salePrice5,
            "Eylül": c.salePrice6
          })
        }

        if(columnName1 == monthOptions[4].name){
          excelList.push({
            "": c.dimensionKey,
            "Mayıs": c.salePrice1,
            "Haziran": c.salePrice2,
            "Temmuz": c.salePrice3,
            "Ağustos": c.salePrice4,
            "Eylül": c.salePrice5,
            "Ekim": c.salePrice6
          })
        }

        if(columnName1 == monthOptions[5].name){
          excelList.push({
            "": c.dimensionKey,
            "Haziran": c.salePrice1,
            "Temmuz": c.salePrice2,
            "Ağustos": c.salePrice3,
            "Eylül": c.salePrice4,
            "Ekim": c.salePrice5,
            "Kasım": c.salePrice6
          })
        }

        if(columnName1 == monthOptions[6].name){
          excelList.push({
            "": c.dimensionKey,
            "Temmuz": c.salePrice1,
            "Ağustos": c.salePrice2,
            "Eylül": c.salePrice3,
            "Ekim": c.salePrice4,
            "Kasım": c.salePrice5,
            "Aralık": c.salePrice6
          })
        }

        if(columnName1 == monthOptions[7].name){
          excelList.push({
            "": c.dimensionKey,
            "Ağustos": c.salePrice1,
            "Eylül": c.salePrice2,
            "Ekim": c.salePrice3,
            "Kasım": c.salePrice4,
            "Aralık": c.salePrice5,
            "Ocak": c.salePrice6
          })
        }

        if(columnName1 == monthOptions[8].name){
          excelList.push({
            "": c.dimensionKey,
            "Eylül": c.salePrice1,
            "Ekim": c.salePrice2,
            "Kasım": c.salePrice3,
            "Aralık": c.salePrice4,
            "Ocak": c.salePrice5,
            "Şubat": c.salePrice6
          })
        }

        if(columnName1 == monthOptions[9].name){
          excelList.push({
            "": c.dimensionKey,
            "Ekim": c.salePrice1,
            "Kasım": c.salePrice2,
            "Aralık": c.salePrice3,
            "Ocak": c.salePrice4,
            "Şubat": c.salePrice5,
            "Mart": c.salePrice6
          })
        }

        if(columnName1 == monthOptions[10].name){
          excelList.push({
            "": c.dimensionKey,
            "Kasım": c.salePrice1,
            "Aralık": c.salePrice2,
            "Ocak": c.salePrice3,
            "Şubat": c.salePrice4,
            "Mart": c.salePrice5,
            "Nisan": c.salePrice6
          })
        }

        if(columnName1 == monthOptions[11].name){
          excelList.push({
            "": c.dimensionKey,
            "Aralık": c.salePrice1,
            "Ocak": c.salePrice2,
            "Şubat": c.salePrice3,
            "Mart": c.salePrice4,
            "Nisan": c.salePrice5,
            "Mayıs":  c.salePrice6
          })
        }
      });

      if(columnName1 == monthOptions[0].name){
        excelList.push({
          "": "Toplam",
          "Ocak":salePriceTotal1,
          "Şubat":salePriceTotal2,
          "Mart":salePriceTotal3,
          "Nisan":salePriceTotal4,
          "Mayıs":salePriceTotal5,
          "Haziran":salePriceTotal6
        })
      }

      if(columnName1 == monthOptions[1].name){
        excelList.push({
          "": "Toplam",
          "Şubat":salePriceTotal1,
          "Mart":salePriceTotal2,
          "Nisan":salePriceTotal3,
          "Mayıs":salePriceTotal4,
          "Haziran":salePriceTotal5,
          "Temmuz":salePriceTotal6
        })
      }

      if(columnName1 == monthOptions[2].name){
        excelList.push({
          "": "Toplam",
          "Mart":salePriceTotal1,
          "Nisan":salePriceTotal2,
          "Mayıs":salePriceTotal3,
          "Haziran":salePriceTotal4,
          "Temmuz":salePriceTotal5,
          "Ağustos":salePriceTotal6
        })
      }

      if(columnName1 == monthOptions[3].name){
        excelList.push({
          "": "Toplam",
          "Nisan":salePriceTotal1,
          "Mayıs":salePriceTotal2,
          "Haziran":salePriceTotal3,
          "Temmuz":salePriceTotal4,
          "Ağustos":salePriceTotal5,
          "Eylül":salePriceTotal6
        })
      }

      if(columnName1 == monthOptions[4].name){
        excelList.push({
          "": "Toplam",
          "Mayıs":salePriceTotal1,
          "Haziran":salePriceTotal2,
          "Temmuz":salePriceTotal3,
          "Ağustos":salePriceTotal4,
          "Eylül":salePriceTotal5,
          "Ekim":salePriceTotal6
        })
      }

      if(columnName1 == monthOptions[5].name){
        excelList.push({
          "": "Toplam",
          "Haziran":salePriceTotal1,
          "Temmuz":salePriceTotal2,
          "Ağustos":salePriceTotal3,
          "Eylül":salePriceTotal4,
          "Ekim":salePriceTotal5,
          "Kasım":salePriceTotal6
        })
      }

      if(columnName1 == monthOptions[6].name){
        excelList.push({
          "": "Toplam",
          "Temmuz":salePriceTotal1,
          "Ağustos":salePriceTotal2,
          "Eylül":salePriceTotal3,
          "Ekim":salePriceTotal4,
          "Kasım":salePriceTotal5,
          "Aralık":salePriceTotal6
        })
      }

      if(columnName1 == monthOptions[7].name){
        excelList.push({
          "": "Toplam",
          "Ağustos":salePriceTotal1,
          "Eylül":salePriceTotal2,
          "Ekim":salePriceTotal3,
          "Kasım":salePriceTotal4,
          "Aralık":salePriceTotal5,
          "Ocak":salePriceTotal6
        })
      }

      if(columnName1 == monthOptions[8].name){
        excelList.push({
          "": "Toplam",
          "Eylül":salePriceTotal1,
          "Ekim":salePriceTotal2,
          "Kasım":salePriceTotal3,
          "Aralık":salePriceTotal4,
          "Ocak":salePriceTotal5,
          "Şubat":salePriceTotal6
        })
      }

      if(columnName1 == monthOptions[9].name){
        excelList.push({
          "": "Toplam",
          "Ekim":salePriceTotal1,
          "Kasım":salePriceTotal2,
          "Aralık":salePriceTotal3,
          "Ocak":salePriceTotal4,
          "Şubat":salePriceTotal5,
          "Mart":salePriceTotal6
        })
      }

      if(columnName1 == monthOptions[10].name){
        excelList.push({
          "": "Toplam",
          "Kasım":salePriceTotal1,
          "Aralık":salePriceTotal2,
          "Ocak":salePriceTotal3,
          "Şubat":salePriceTotal4,
          "Mart":salePriceTotal5,
          "Nisan":salePriceTotal6
        })
      }

      if(columnName1 == monthOptions[11].name){
        excelList.push({
          "": "Toplam",
          "Aralık":salePriceTotal1,
          "Ocak":salePriceTotal2,
          "Şubat":salePriceTotal3,
          "Mart":salePriceTotal4,
          "Nisan":salePriceTotal5,
          "Mayıs": salePriceTotal6
        })
      }

      const worksheet = xlsx.utils.json_to_sheet(excelList);
      var wscols = [
        {width: 12},
        {width: 12},
        {width: 12},
        {width: 12},
        {width: 12},
        {width: 12},
        {width: 12}
      ];
      worksheet["!cols"] = wscols;
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      saveAsExcelFile(excelBuffer, 'Satış Tutarları');
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  let saleQuantityFooterGroup = <ColumnGroup>
    <Row>
      <Column footer={"Toplam"} footerStyle={{textAlign: 'left'}}/>
      <Column footer={addCommas(saleQuantityTotal1)} footerStyle={{textAlign: 'right'}}/>
      <Column footer={addCommas(saleQuantityTotal2)} footerStyle={{textAlign: 'right'}}/>
      <Column footer={addCommas(saleQuantityTotal3)} footerStyle={{textAlign: 'right'}}/>
      <Column footer={addCommas(saleQuantityTotal4)} footerStyle={{textAlign: 'right'}}/>
      <Column footer={addCommas(saleQuantityTotal5)} footerStyle={{textAlign: 'right'}}/>
      <Column footer={addCommas(saleQuantityTotal6)} footerStyle={{textAlign: 'right'}}/>
    </Row>
  </ColumnGroup>;

  let salePriceFooterGroup = <ColumnGroup>
    <Row>
      <Column footer={"Toplam"} footerStyle={{textAlign: 'left'}}/>
      <Column footer={addCommasWithToFixed(salePriceTotal1)} footerStyle={{textAlign: 'right'}}/>
      <Column footer={addCommasWithToFixed(salePriceTotal2)} footerStyle={{textAlign: 'right'}}/>
      <Column footer={addCommasWithToFixed(salePriceTotal3)} footerStyle={{textAlign: 'right'}}/>
      <Column footer={addCommasWithToFixed(salePriceTotal4)} footerStyle={{textAlign: 'right'}}/>
      <Column footer={addCommasWithToFixed(salePriceTotal5)} footerStyle={{textAlign: 'right'}}/>
      <Column footer={addCommasWithToFixed(salePriceTotal6)} footerStyle={{textAlign: 'right'}}/>
    </Row>
  </ColumnGroup>;

  const calculationsMonthlyQuantityHeader =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>Aksesuar Satış Adetleri Tablosu</div>
        <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
                onClick={exportQuantityExcel} style={{marginRight: 5}}/>
      </div>
    </div>;

  const calculationsMonthlyPriceHeader =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>Satış Tutarları Tablosu</div>
        <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
                onClick={exportPriceExcel} style={{marginRight: 5}}/>
      </div>
    </div>;


  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-12">
            <h5>Son 6 Ay Satış Raporu</h5>
            <SelectButton optionLabel="name" optionValue="value" value={selectedDimensionType}
                          options={dimensionTypeOptions} onChange={(e) => onChangeselectedDimensionType(e)}/>
          </div>
          <div className="p-col-12 p-md-6">
            <b style={{margin: 0}}>Aksesuar Satış Adetleri Grafiği</b>
            <Chart type="bar" data={saleQuantityChartData} options={stackedOptions}/>
          </div>
          <div className="p-col-12 p-md-6">
            <b style={{margin: 0}}>Satış Tutarları Grafiği</b>
            <Chart type="bar" data={salePriceChartData} options={stackedOptions}/>
          </div>
          <div className="p-col-12 p-md-6">
            <DataTable value={calculationsMonthlyList} footerColumnGroup={saleQuantityFooterGroup}
                       header={calculationsMonthlyQuantityHeader} style={{border: '2px solid lightgray'}}>
              <Column field="dimensionKey"/>
              <Column body={saleQuantityBody1} header={columnName1} headerStyle={{textAlign: 'right'}}
                      bodyStyle={{textAlign: 'right', padding: 14}}/>
              <Column body={saleQuantityBody2} header={columnName2} headerStyle={{textAlign: 'right'}}
                      bodyStyle={{textAlign: 'right', padding: 14}}/>
              <Column body={saleQuantityBody3} header={columnName3} headerStyle={{textAlign: 'right'}}
                      bodyStyle={{textAlign: 'right', padding: 14}}/>
              <Column body={saleQuantityBody4} header={columnName4} headerStyle={{textAlign: 'right'}}
                      bodyStyle={{textAlign: 'right', padding: 14}}/>
              <Column body={saleQuantityBody5} header={columnName5} headerStyle={{textAlign: 'right'}}
                      bodyStyle={{textAlign: 'right', padding: 14}}/>
              <Column body={saleQuantityBody6} header={columnName6} headerStyle={{textAlign: 'right'}}
                      bodyStyle={{textAlign: 'right', padding: 14}}/>
            </DataTable>
          </div>
          <div className="p-col-12 p-md-6">
            <DataTable value={calculationsMonthlyList} footerColumnGroup={salePriceFooterGroup}
                       header={calculationsMonthlyPriceHeader} style={{border: '2px solid lightgray'}}>
              <Column field="dimensionKey"/>
              <Column body={salePriceBody1} header={columnName1} headerStyle={{textAlign: 'right'}}
                      bodyStyle={{textAlign: 'right', padding: 14}}/>
              <Column body={salePriceBody2} header={columnName2} headerStyle={{textAlign: 'right'}}
                      bodyStyle={{textAlign: 'right', padding: 14}}/>
              <Column body={salePriceBody3} header={columnName3} headerStyle={{textAlign: 'right'}}
                      bodyStyle={{textAlign: 'right', padding: 14}}/>
              <Column body={salePriceBody4} header={columnName4} headerStyle={{textAlign: 'right'}}
                      bodyStyle={{textAlign: 'right', padding: 14}}/>
              <Column body={salePriceBody5} header={columnName5} headerStyle={{textAlign: 'right'}}
                      bodyStyle={{textAlign: 'right', padding: 14}}/>
              <Column body={salePriceBody6} header={columnName6} headerStyle={{textAlign: 'right'}}
                      bodyStyle={{textAlign: 'right', padding: 14}}/>
            </DataTable>
          </div>
        </div>
      </div>
    </>
  )

};

export default ReportingMonthly;
