import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import {useHistory} from "react-router-dom";
import {handleError} from "../../../service/HandleErrorService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from '../../../components/ResponsiveDataTable'

import {TabPanel, TabView} from "primereact/tabview";
import ProductPriceSupplyService from "../supply/ProductPriceSupplyService";

import ProductPriceDistyService from "../disty/ProductPriceDistyService";
import moment from "moment";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import CanSellService from "../../canSell/httpService";
import {InputTextarea} from "primereact/inputtextarea";
import {getCurrentModelPricesForReseller} from "../../../service/config";


const ProductPriceForReseller = () => {
  const history = useHistory();
  const toast = useRef(null);
  const productPriceService = new ProductPriceDistyService();

  const [searchProductPrice, setSearchProductPrice] = useState({});
  const [productPriceList, setProductPriceList] = useState([]);
  const [searchRequest, setSearchRequest] = useState({});
  const canSellService = new CanSellService();
  const [allBrand, setAllBrand] = useState([]);
  const [allSupply, setAllSupply] = useState([]);
  const [excelList, setExcelList] = useState([]);
  const [searchRequest2, setSearchRequest2] = useState({});
  const [searchProductPrice2, setSearchProductPrice2] = useState({});
  const [productPriceList2, setProductPriceList2] = useState([]);

  const [excelList2, setExcelList2] = useState([]);
const productPriceSupplyService=new ProductPriceSupplyService();
  const [paginationConfig2, setPaginationConfig2] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });
  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });


  useEffect(() => {
    getAll(paginationConfig);
    canSellService.getAllBrand().then(setAllBrand);
    canSellService.getAllSupply().then(setAllSupply);
  }, [])



  const getAll = (pageState) => {

    productPriceService.getCurrentModelPricesForReseller(searchRequest, pageState.activePage, pageState.itemsPerPage).then(res => {
      let _productPriceList = res?.content;

      setProductPriceList(_productPriceList);

      pageState.first = pageState.itemsPerPage * pageState.activePage;
      pageState.totalRecords = res?.totalElements;
      setPaginationConfig({
        ...pageState,
      });

    }).catch(err => handleError(err, toast))


  }

  const getExcelReport=()=>{
    let _excelList = [];
    productPriceService.getCurrentModelPricesForResellerList(searchRequest).then(res => {
      res?.forEach(value => {


        let _excelModel = {};
        _excelModel['Kategori'] = value?.category;
        _excelModel['Alt Kategori'] = value?.subCategory;
        _excelModel['Marka'] = value?.brand;
        _excelModel['Tedarikçi'] = value?.supply;
        _excelModel['Ürün Adı'] = value?.modelName;
        _excelModel['Barkod'] = value?.barcode;
        _excelModel['Ürün Fiyatının Geçerli Olacağı Başlangıç Tarihi'] = moment(value?.startDate).format("DD-MM-yyyy hh:mm:ss")
        _excelModel['Ürün Açıklaması'] = value?.description;
        _excelModel['Tedarik Durumu'] = value?.supplyStatus;
        _excelModel['Menşei'] = value?.origin;
        if (value.eol) {
          _excelModel['Eol'] = "EVET";
        } else if (value.eol === undefined) {
          _excelModel['Eol'] = "";
        } else {
          _excelModel['Eol'] = "HAYIR";

        }
        _excelModel['Onay Durumu'] = value?.approveType;

        _excelModel['Kdv Oranı'] = value?.taxRatio;
        _excelModel['Önerilen Bayi Alış Fiyatı'] = value?.recommendedResellerPrice;
        _excelModel['Önerilen Satış Fiyatı'] = value?.recommendedSellPrice;
        _excelModel['Bayi Alış Fiyatı'] = value?.resellerPrice;
        _excelModel['AKSF Fiyatı'] = value?.aksf;

        _excelList.push(_excelModel);
      })
      setExcelList(_excelList)

      import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(_excelList);
        const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
        saveAsExcelFile(excelBuffer, 'products');
      });
    }).catch(err => handleError(err, toast));
  }

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    getAll(pageState);
  };



  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, "Ürün-Fiyat Listesi" + EXCEL_EXTENSION);
    });
  }
  const priceTemplate = (e, name) => {
    if (e[name] === undefined) {
      return <div>Girilmedi.</div>
    } else return <div>{e[name]}₺</div>

  }


  const taxRatioTemplate = (e) => {
    if (e['taxRatio'] == undefined) {
      return <div>Girilmedi.</div>
    } else return <div>%{e['taxRatio']}</div>

  }


  const routeDetail = (rowData) => {
    console.log(rowData)
    return <Button
      icon="pi pi-pencil"
      className="p-button-rounded p-button-warning p-mr-2"
      label="Düzenle"
      onClick={e => history.push("/ProductPrice-Detail-Disty/" + rowData?.id+"/"+rowData.mid+"/"+rowData.mpsid)}
    />
  }


  const supplyTemplate=(e)=>{
    let _supply= allSupply.find(value=>value?.id===e?.sid);

    return _supply?.disties!==undefined? _supply?.disties[0]?.name:_supply?.name;


  }



  const saveAsExcelFile2 = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, "Ürün-Fiyat Listesi" + EXCEL_EXTENSION);
    });
  }



  const modelNameTemplate=(e)=>{

    return <div><InputTextarea autoResize style={{fontSize:10,width:'100%',backgroundColor:'lawngreen'}} disabled value={e?.modelName}/></div>
  }
  return (
    <>

      <Toast ref={toast}/>

      <div className="card">
        <div className="p-formgroup-inline">

          <div className="p-field">
            <label htmlFor="brand" className="p-sr-only">Marka</label>
            <Dropdown id="brand" value={searchRequest.brand} options={allBrand} showClear placeholder="Marka"
                      optionLabel="name" optionValue="name"
                      onChange={e => {
                        let _searchRequest = {...searchRequest};
                        _searchRequest.brand = e.target.value;
                        setSearchRequest(_searchRequest);
                      }

                      }/>
          </div>

          <div className="p-field">
            <label htmlFor="supply" className="p-sr-only">Tedarikçi</label>
            <Dropdown id="supply" value={searchRequest.supply} options={allSupply} optionValue="name"
                      optionLabel={e => {

                        if (e?.disties !== undefined) {

                          return e?.disties[0]?.name;
                        } else {
                          return e.name
                        }
                      }}
                      placeholder="Tedarikçi"
                      showClear
                      onChange={e => {
                        let _searchRequest = {...searchRequest};
                        _searchRequest.supply = e.target.value;
                        setSearchRequest(_searchRequest);
                      }

                      }/>
          </div>


          <div className="p-field">
            <label htmlFor="name" className="p-sr-only">Ürün</label>
            <InputText id="name" value={searchRequest.modelName} type="text" placeholder="Ürün"
                       onChange={e => {
                         let _searchRequest = {...searchRequest};
                         _searchRequest.modelName = e.target.value;
                         setSearchRequest(_searchRequest);
                       }

                       }/>
          </div>


          <div className="p-field">
            <label htmlFor="barcode" className="p-sr-only">Barkod</label>
            <InputText id="barcode" value={searchRequest.barcode} type="text" placeholder="Barkod"
                       onChange={e => {
                         let _searchRequest = {...searchRequest};
                         _searchRequest.barcode = e.target.value;
                         setSearchRequest(_searchRequest);
                       }

                       }/>


          </div>


          <Button type="button" label="Ara"
                  onClick={e => getAll({activePage: 0, itemsPerPage: paginationConfig.itemsPerPage})}/>

        </div>

      </div>

      <Button style={{marginBottom: 5}} label="Ürün Listesini İndir" type="button" icon="pi pi-file-excel"
              onClick={getExcelReport} className="p-button-success p-mr-2" data-pr-tooltip="XLS"/>
      <div className="datatable-responsive-demo">

        <ResponsiveDataTable value={productPriceList}
                             paginationConfig={paginationConfig}
                             onActivePageChange={onActivePageChange}
                             emptyMessage="Ürün Bulunamadı."
        >

          <Column field="modelName" body={modelNameTemplate} header="Ürün İsmi"/>
          <Column field="subCategory" header="Alt Kategori"/>
          <Column field="brand" header="Marka"/>
          <Column field="barcode" header="Barkod"/>
          <Column field="supply" body={supplyTemplate} header="Tedarikçi"/>
          <Column field="recommendedResellerPrice" body={e => priceTemplate(e, 'recommendedResellerPrice')} header="Önerilen Bayi Alış Fiyatı"
                  sortable/>
          <Column field="recommendedSellPrice" body={e => priceTemplate(e, 'recommendedSellPrice')}
                  header="Önerilen Satış Fiyatı" sortable/>

          <Column field="aksf" body={e => priceTemplate(e, 'aksf')} header="AKSF Fiyatı"
                  sortable/>



        </ResponsiveDataTable>

      </div>


    </>
  )
}
export default ProductPriceForReseller;
