import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import {handleError} from "../../service/HandleErrorService";
import VoucherService from "./httpService";
import {Column} from "primereact/column";
import {InputSwitch} from "primereact/inputswitch";
import {DataTable} from "primereact/datatable";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";

const TtCrmList = () => {

  const toast = useRef(null);
  const voucherService = new VoucherService();

  const [ttCrmList, setTtCrmList] = useState([]);
  const [updateSerialUsageModalHeader, setUpdateSerialUsageModalHeader] = useState(null);
  const [updateSerialUsageModalMessage, setUpdateSerialUsageModalMessage] = useState(null);
  const [updateSerialUsageModal, setUpdateSerialUsageModal] = useState(false);
  const [selectedTtCrmId, setSelectedTtCrmId] = useState(null);
  const [selectedSerialOptionActive, setSelectedSerialOptionActive] = useState(null);

  useEffect(() => {
    getAll();
  }, []);

  const getAll = () => {
    voucherService.getAllTtCrmList().then(res => {
      console.log("getAllTtCrmList", res);
      setTtCrmList(res);
    }).catch(err => handleError(err, toast))
  };

  const toggleSerialOption = (e) => {
    setSelectedTtCrmId(e.target.id);
    setSelectedSerialOptionActive(e.value);
    var ttCrmName = null;
    ttCrmList.forEach(t => {
      if (t.id == e.target.id) {
        ttCrmName = t.name;
      }
    });
    if (e.value) {
      setUpdateSerialUsageModalHeader("Seri Numara Kullanımını Etkinleştirme");
      setUpdateSerialUsageModalMessage(ttCrmName + " için seri numara kullanımını etkinleştirmek istediğinizden emin misiniz?");
    } else {
      setUpdateSerialUsageModalHeader("Seri Numara Kullanımını Devre Dışı Bırakma");
      setUpdateSerialUsageModalMessage(ttCrmName + " için seri numara kullanımını devre dışı bırakmak istediğinizden emin misiniz?");
    }
    setUpdateSerialUsageModal(true);
  };

  const updateSerialUsageModalHide = () => {
    setUpdateSerialUsageModal(false);
  };

  const ttCrmUpdateSerialUsage = () => {
    const request = {
      ttCrmId: selectedTtCrmId,
      serialOptionActive: selectedSerialOptionActive
    };
    voucherService.ttCrmUpdateSerialUsage(request).then(res => {
      console.log("ttCrmUpdateSerialUsage", res);
      getAll();
      toast.current.show({
        severity: 'success',
        summary: 'Başarılı İşlem',
        detail: 'Seri numara kullanımı güncellendi.'
      });
    }).catch(err => {
      console.log(err);
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Seri numara kullanımı güncellenemedi.'
      });
    }).finally(() => {
      setUpdateSerialUsageModal(false);
    });
  };

  const serialOption = (rowData) => {
    return <InputSwitch id={rowData.id} checked={rowData.serialOptionActive} onChange={(e) => toggleSerialOption(e)}/>
  };

  const passOption = (rowData) => {
    return <InputSwitch checked={true} disabled={true}/>
  };

  const renderFooter = () => {
    return (
      <div>
        <Button label="Hayır" icon="pi pi-times" onClick={updateSerialUsageModalHide} className="p-button-text"/>
        <Button label="Evet" icon="pi pi-check" onClick={ttCrmUpdateSerialUsage} autoFocus/>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card">
          <DataTable value={ttCrmList} header="TT CRM Listesi">
            <Column field="crmCode" header="Seri/Şifre’de Kullanılan Kod"/>
            <Column field="name" header="Ad"/>
            <Column field="firmName" header="Firma Adı"/>
            <Column body={serialOption} header="Seri Numara Kullanımı"/>
            <Column body={passOption} header="Şifre Kullanımı"/>
          </DataTable>
        </div>
      </div>
      <Dialog header={updateSerialUsageModalHeader} visible={updateSerialUsageModal} modal style={{width: '400px'}}
              footer={renderFooter('displayConfirmation')} onHide={updateSerialUsageModalHide}>
        <div className="confirmation-content">
          <span>{updateSerialUsageModalMessage}</span>
        </div>
      </Dialog>
    </>
  )

};

export default TtCrmList;
