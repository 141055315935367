import "react-app-polyfill/ie11";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Login } from "./pages/login/Login";
import App from "./App";
import ProtectedRoute from "./routes/ProtectedRoute";
import { ForgetPassword } from "./pages/login/ForgetPassword";
import { NotFound } from "./pages/NotFound";
import { ResellerLogin } from "./pages/login/ResellerLogin";
import {Provider as StoreProvider} from 'react-redux'
import store from './redux/store'
import {AutoLogin} from "./pages/login/AutoLogin";
import { ResellerLoginWarning } from "./pages/login/ResellerLoginWarning";

ReactDOM.render(
  <StoreProvider store={store}>
    <HashRouter>
      <Suspense fallback="loading">
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route
            exact
            path="/resellerLogin/:ticketId"
            component={ResellerLoginWarning}
          />
          <Route
            exact
            path="/autoLogin/:token/:refreshToken/:resellerCode/:redirectPage"
            component={AutoLogin}
          />
          <Route exact path="/sifremiunuttum" component={ForgetPassword} />
          <ProtectedRoute component={App} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </HashRouter>
  </StoreProvider>,
  document.getElementById("root")
);
