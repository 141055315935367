import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import {useHistory} from "react-router-dom";
import {handleError} from "../../../service/HandleErrorService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from '../../../components/ResponsiveDataTable'

import ProductPriceSupplyService from "./ProductPriceSupplyService";
import moment from "moment";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import CanSellService from "../../canSell/httpService";
import {InputTextarea} from "primereact/inputtextarea";
import {Calendar} from "primereact/calendar";


const ProductPriceSupplyList = () => {
  const history = useHistory();
  const toast = useRef(null);
  const productPriceService = new ProductPriceSupplyService();
  const [searchRequest, setSearchRequest] = useState({});
  const [searchProductPrice, setSearchProductPrice] = useState({});
  const [productPriceList, setProductPriceList] = useState([]);
  const canSellService = new CanSellService();
  const [excelList, setExcelList] = useState([]);

  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });


  useEffect(() => {
    getAll(paginationConfig);
  }, [])


  const getAll = (pageState) => {

    productPriceService.getCurrentModelPrices(searchRequest, pageState.activePage, pageState.itemsPerPage, searchRequest).then(res => {
      setProductPriceList(res?.content);
      pageState.first = pageState.itemsPerPage * pageState.activePage;
      pageState.totalRecords = res.totalElements;
      setPaginationConfig({
        ...pageState,
      });

    }).catch(err => handleError(err, toast))


  }

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    getAll(pageState);
  };


  const priceTemplate = (e, name) => {
    if (e[name] === undefined) {
      return <div>Girilmedi.</div>
    } else return <div>{e[name]}₺</div>

  }

  const getExcelReport=()=>{
    productPriceService.getCurrentModelPricesList(searchRequest).then(res => {
      let _excelList = [];
      res?.forEach(value => {

        let _excelModel = {};
        _excelModel['Kategori'] = value?.model?.subCategory?.category?.name;
        _excelModel['Alt Kategori'] = value?.model?.subCategory?.name;
        _excelModel['Marka'] = value?.model?.brand?.name;
        _excelModel['Tedarikçi'] = value?.supply?.name;
        _excelModel['Ürün Adı'] = value?.model?.name;
        _excelModel['Barkod'] = value?.model?.barcode;
        _excelModel['Ürün Açıklaması'] = value?.model?.description;
        _excelModel['Tedarik Durumu'] = value?.model?.supplyStatus;
        _excelModel['Menşei'] = value?.model?.origin;
        if (value.eol) {
          _excelModel['Eol'] = "EVET";
        } else if (value.eol === undefined) {
          _excelModel['Eol'] = "";
        } else {
          _excelModel['Eol'] = "HAYIR";

        }
        _excelModel['Onay Durumu'] = value?.model?.approveType;
        _excelModel['Ürün Fiyatlarının Geçerli Olacağı Tarih'] = moment(value?.startDate).format("DD-MM-yyyy hh:mm:ss")
        _excelModel['Kdv Oranı'] = value?.taxRatio;
        _excelModel['Distribütör Alış Fiyatı'] = value?.distyPrice;
        _excelModel['Önerilen EDM Alış Fiyatı'] = value?.recommendedEdmPrice;
        _excelModel['Önerilen Bayi Alış Fiyatı'] = value?.recommendedResellerPrice;
        _excelModel['Önerilen Satış Fiyatı'] = value?.recommendedSellPrice;
        _excelList.push(_excelModel);

      })
      import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(_excelList);
        const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
        saveAsExcelFile(excelBuffer, 'products');
      });

      setExcelList(_excelList)
    }).catch(err => handleError(err, toast))
  }

  const taxRatioTemplate = (e) => {
    if (e['taxRatio'] === undefined) {
      return <div>Girilmedi.</div>
    } else return <div>%{e['taxRatio']}</div>

  }


  const routeDetail = (rowData) => {
    console.log(rowData)
    return <Button
      icon="pi pi-pencil"
      className="p-button-rounded p-button-warning p-mr-2"
      label="Düzenle"
      onClick={e => history.push("/ProductPrice-Detail/" + rowData?.id+"/"+rowData.model?.id)}/>
  }


  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, "Ürün-Fiyat Listesi" + EXCEL_EXTENSION);
    });
  }


  const modelNameTemplate=(e)=>{

    return <div><InputTextarea autoResize style={{fontSize:10,width:'100%',backgroundColor:'lawngreen'}} disabled value={e?.model?.name}/></div>
  }

  return (
    <>

      <Toast ref={toast}/>


      <div className="card">
        <div className="p-formgroup-inline">


          <div className="p-field">
            <label htmlFor="name" className="p-sr-only">Ürün</label>
            <InputText id="name" value={searchRequest.modelName} type="text" placeholder="Ürün"
                       onChange={e => {
                         let _searchRequest = {...searchRequest};
                         _searchRequest.modelName = e.target.value;
                         setSearchRequest(_searchRequest);
                       }

                       }/>
          </div>


          <div className="p-field">
            <label htmlFor="barcode" className="p-sr-only">Barkod</label>
            <InputText id="barcode" value={searchRequest.barcode} type="text" placeholder="Barkod"
                       onChange={e => {
                         let _searchRequest = {...searchRequest};
                         _searchRequest.barcode = e.target.value;
                         setSearchRequest(_searchRequest);
                       }

                       }/>


          </div>
          <div className="p-field">
            <label htmlFor="date" className="p-sr-only">Fiyatın Geçerli Olacağı Tarih</label>
            <Calendar id="date"
                      style={{width: 240}}
                      placeholder="Fiyatın Geçerli Olacağı Tarih"
                      panelStyle={{height: 300}}
                      panelClassName={"smallCalendar"}
                      dateFormat={'dd-mm-yy'}
                      value={searchRequest.startDate}
                      onChange={e => {
                        let _searchRequest = {...searchRequest};
                        _searchRequest.startDate = e.target.value;
                        setSearchRequest(_searchRequest)
                      }}
            />
          </div>


          <Button type="button" label="Ara"
                  onClick={e => getAll({activePage: 0, itemsPerPage: paginationConfig.itemsPerPage})}/>

        </div>

      </div>

      <Button style={{marginBottom: 5}} label="Ürün Listesini İndir" type="button" icon="pi pi-file-excel"
              onClick={getExcelReport} className="p-button-success p-mr-2" data-pr-tooltip="XLS"/>
      <div className="datatable-responsive-demo">

        <ResponsiveDataTable value={productPriceList}
                             paginationConfig={paginationConfig}
                             onActivePageChange={onActivePageChange}
                             emptyMessage="Ürün Bulunamadı."
        >

          <Column field="model.name" body={modelNameTemplate} header="Ürün İsmi"/>
          <Column field="model.brand.name" header="Marka"/>
          <Column style={{width: 120}} field="model.barcode" header="Barkod"/>
          <Column body={taxRatioTemplate} header="KDV Oranı"/>
          <Column body={e => {
            if (e['nextPrice']) {
              return <div style={{backgroundColor: 'greenyellow'}}>{moment(e['startDate']).format('DD-MM-YYYY')}</div>
            } else {
              return <div>{moment(e['startDate']).format('DD-MM-YYYY')}</div>
            }
          }} header="Ürün Fiyatlarının Geçerli Olacağı Tarih"/>
          <Column field="distyPrice" body={e => priceTemplate(e, 'distyPrice')} header="Distribütör Alış Fiyatı"
                  sortable/>
          <Column field="recommendedEdmPrice" body={e => priceTemplate(e, 'recommendedEdmPrice')} header="Önerilen EDM Alış Fiyatı"
                  sortable/>
          <Column field="recommendedResellerPrice" body={e => priceTemplate(e, 'recommendedResellerPrice')}
                  header="Önerilen Bayi Alış Fiyatı" sortable/>
          <Column field="recommendedSellPrice" body={e => priceTemplate(e, 'recommendedSellPrice')}
                  header="Önerilen Satış Fiyatı" sortable/>


          <Column body={routeDetail} header="Ürün Detayı"/>


        </ResponsiveDataTable>

      </div>


    </>
  )
}
export default ProductPriceSupplyList;
