import React, {useEffect, useRef, useState} from "react";
import MarjService from "./httpService";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import {MultiSelect} from "primereact/multiselect";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Column} from "primereact/column";
import {handleError} from "../../service/HandleErrorService";
import {Toast} from "primereact/toast";
import {InputNumber} from "primereact/inputnumber";

const Marj = () => {
  const toast = useRef(null);
  const [errorFields, setErrorFields] = useState({});

  const marjService = new MarjService();
  const [search, setSearch] = useState({});
  const [allMarj, setAllMarj] = useState([]);
  const [upsertMarjDialog, setUpsertMarjDialog] = useState(false);
  const [editMarjDialog, setEditMarjDialog] = useState(false);
  const [editMarj, setEditMarj] = useState({});
  const [expandedRows, setExpandedRows] = useState([]);
  const [checkHeader, setCheckHeader] = useState(false);
  const [upsertMarj, setUpsertMarj] = useState({});
  const [selectSubCategory, setSelectSubCategory] = useState();
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [category, setCategory] = useState([]);
  useEffect(() => {

    getAll();
    getCategoryTree();
  }, []);

  const getAll = () => {
    marjService.getAll(search).then(res => {
      setAllMarj(res);
    })
  }
  const getCategoryTree = () => {
    marjService.categoryTree().then(res => {

      let _category=[];
      res.forEach(value=>{
        if(value?.id!==undefined)
        {
          _category.push(value)
        }
      })
      setCategory(_category)
    }).catch(err => handleError(err, toast))
  }

  const upsertMarjModalHide = () => {
    setUpsertMarjDialog(false);
    setEditMarjDialog(false);

  }

  const upsertMarjCall = () => {
    let _upsertMarj = {...upsertMarj};
    marjService.upsert(_upsertMarj).then(res => {
      toast.current.show({
        severity: 'success',
        summary: 'Başarılı İşlem',
        detail: 'Tolerans Oranı Eklendi.'
      });
      getAll();
      getCategoryTree();
      setUpsertMarj({});
      setUpsertMarjDialog(false)
    }).catch(err => setErrorFields(handleError(err, toast)))

  }

  const editMarjCall = () => {
    let _editMarj = {...editMarj};
    marjService.upsert(_editMarj).then(res => {
      toast.current.show({
        severity: 'success',
        summary: 'Başarılı İşlem',
        detail: 'Tolerans Oranı Eklendi.'
      });
      getAll();
      getCategoryTree();
      setEditMarj({});
      setEditMarjDialog(false)
    }).catch(err => setErrorFields(handleError(err, toast)))

  }
  const isInvalid = (bindingField1, bindingField2) => {
    if (errorFields[`${bindingField1}`]) {

      return " p-invalid";
    }
    if (errorFields[`${bindingField2}`]) {
      return " p-invalid";
    }
    return "";
  }
  const groupedItemTemplate = (option) => {

    return (
      <div className="p-d-flex p-ai-center country-item">
        <div><strong style={{color: 'black' ,fontSize:16}}><u>{option?.name}</u></strong></div>
      </div>
    );
  }
  const onSubCategoryChange = (e) => {

    setSelectSubCategory(e.value);
    let _upsertMarj = {...upsertMarj};
    _upsertMarj.subCategory = e.value
    setUpsertMarj(_upsertMarj)
  }

  const actions = (rowData) => {

    return (<Button
        icon="pi pi-pencil"
        className="p-button-rounded p-button-warning p-mr-2"
        label="Düzenle"
        style={{width: "50%"}}

        onClick={e => {

          setEditMarjDialog(true)
          let _editMarj = {...editMarj};
          _editMarj.id = rowData.id;
          _editMarj.marjRatio = rowData.marjRatio;
          _editMarj.subCategory = rowData.subCategory;
          setEditMarj(_editMarj);
        }}
      />
    )

  }
  const categoryOptionTemplate = (option) => {

    return (
      <div>
        {option?.name}
      </div>
    );
  }

  return (
    <>
      <Toast ref={toast}/>


      <br/>
      <br/>
      <br/>

      <div className="datatable-responsive-demo">
        <Button onClick={e => setUpsertMarjDialog(true)} icon="pi pi-plus-circle" label="Yeni Kayıt"
                className="p-button-success p-mr-2 p-mb-2"/>
        <ResponsiveDataTable value={allMarj}

                             emptyMessage="Tolerans Kayıtı Bulunamadı.">
          <Column field="subCategory.category.name" header="Kategori" sortable/>
          <Column field="subCategory.name" header="Alt Kategori" sortable/>

       <Column body={e => {
            return <div>%{e.marjRatio}</div>
          }} header="Tolerans Oranı"/>
         <Column body={e => actions(e)} header="Düzenle"/>


        </ResponsiveDataTable>

      </div>

      <Dialog header={"Tolerans Oranı Ekle"} visible={upsertMarjDialog} style={{width: '450px'}}
              onHide={upsertMarjModalHide}>
        <span className="p-float-label marginInput">
          <Dropdown value={selectSubCategory} style={{width: 250}} options={category}
                    id="category"
                    className={isInvalid('subCategory')}
                    onChange={onSubCategoryChange} optionLabel="name" optionGroupLabel="name"
                    optionGroupChildren='subCategories'
                    optionGroupTemplate={groupedItemTemplate}/>
          <label htmlFor="subCategory">Alt Kategori</label>
        </span>
        <label htmlFor="marjRatio">Tolerans Oranı</label>
        <br/>
        <InputNumber id="marjRatio" max="100" min="0" value={upsertMarj?.marjRatio} style={{width: 250}}
                     className={isInvalid('marjRatio')}
                     onChange={e => {
                       console.log(e)
                       let _errorFields = {...errorFields};
                       if (e.value === null) {

                         _errorFields['marjRatio'] = true;

                       } else {
                         _errorFields['marjRatio'] = false;

                       }
                       setErrorFields(_errorFields)
                       let _upsertMarj = {...upsertMarj};
                       _upsertMarj.marjRatio = e.value;
                       setUpsertMarj(_upsertMarj)
                     }}/>

        <br/>
        <br/>
        <Button label="Kaydet" onClick={e => upsertMarjCall(e)}/>
      </Dialog>

      <Dialog header={"Tolerans Oranı Düzenle"} visible={editMarjDialog} style={{width: '450px'}}
              onHide={upsertMarjModalHide}>
        <span className="p-float-label marginInput">
          <InputText disabled value={editMarj.subCategory?.name} style={{width: 250}}
                     id="subCategory"
                    />
          <label htmlFor="subCategory">Alt Kategori</label>
        </span>
        <label htmlFor="marjRatio">Tolerans Oranı</label>
        <br/>
        <InputNumber id="marjRatio" max="100" min="0" value={editMarj?.marjRatio} style={{width: 250}}
                     className={isInvalid('marjRatio')}
                     onChange={e => {

                       let _errorFields = {...errorFields};
                       if (e.value === null) {

                         _errorFields['marjRatio'] = true;

                       } else {
                         _errorFields['marjRatio'] = false;

                       }
                       setErrorFields(_errorFields)
                       let _editMarj = {...editMarj};
                       _editMarj.marjRatio = e.value;
                       setEditMarj(_editMarj)
                     }}/>

        <br/>
        <br/>
        <Button label="Kaydet" onClick={e => editMarjCall(e)}/>
      </Dialog>
    </>
  )
}
export default Marj;
