import AspAxios from "../../service/AspAxios"
import * as Paths from '../../service/config'

export default class CanSellService {

  getAllSupply() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllSupply).then(res => {
        resolve(res.data)

      }).catch(err => reject(err))
    })
  }
  getAllDisty() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllDisty).then(res => {
        resolve(res.data)

      }).catch(err => reject(err))
    })
  }
  getAllBrand() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllBrand).then(res => {
        resolve(res.data)

      }).catch(err => reject(err))
    })
  }

  getAllScoreType() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.allScoreType).then(res => {
        resolve(res.data);
      }).catch(reject)
    })

  }

  getAllCategory() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllCategory).then(res => {
        resolve(res.data)

      }).catch(err => reject(err))
    })
  }

  getAllSubCategory() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllSubCategory).then(res => {
        resolve(res.data)

      }).catch(err => reject(err))
    })
  }

  upsert(_canSell) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.canSellUpsert, _canSell).then(res => {
        resolve(res.data)

      }).catch(err => reject(err))
    })
  }

  update(_canSell) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.canSellUpdate, _canSell).then(res => {
        resolve(res.data)

      }).catch(err => reject(err))
    })
  }

  getAllCansell(canSellDto) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getAllCanSell, canSellDto).then(res => {
        resolve(res.data)

      }).catch(err => reject(err))
    })
  }

  deleteCanSell(canSellDto) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.deleteCanSell, canSellDto).then(res => {
        resolve(res.data)

      }).catch(err => reject(err))
    })
  }

  getCanSellByBrand(brand) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.canSellByBrand, brand).then(res => {
        resolve(res.data)

      }).catch(err => reject(err))
    })
  }

  createBrand(request, name, newBrand) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.createBrand + "/" + name + "/" + newBrand, request, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 60000
      })
        .then((res => {
          resolve(res.data);
        }))
        .catch(err => {
          reject(err);
        });
    });
  }

  updateBrand(request, id, name, newBrand) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.updateBrand + "/" + id + "/" + name + "/" + newBrand, request, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 60000
      })
        .then((res => {
          resolve(res.data);
        }))
        .catch(err => {
          reject(err);
        });
    });
  }

  passiveBrand(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.passiveBrand, request).then(res => {
        resolve(res.data)

      }).catch(err => reject(err))
    })
  }

  activeBrand(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.activeBrand, request).then(res => {
        resolve(res.data)

      }).catch(err => reject(err))
    })
  }

};
