import * as Paths from "../../service/config";
import AspAxios from "../../service/AspAxios";

export function getActiveDisties() {
  return new Promise((resolve, reject) => {
    AspAxios.post(Paths.searchDisty + "?page=0&size=2000", {status: 1})
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
