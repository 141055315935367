import CanSellService from "../canSell/httpService";
import {Column} from "primereact/column";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Toast} from "primereact/toast";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {handleError} from "../../service/HandleErrorService";
import {useEffect, useRef, useState} from "react";
import ResellerService from "./httpService";
import {Button} from "primereact/button";

const ResellerDistributionProduct = () => {
    const [searchCriteria, setSearchCriteria] = useState({});
    const [searchCriteriaLazyLoading, setSearchCriteriaLazyLoading] = useState(false);
    const [searchCriteriaProgressing, setSearchCriteriaProgressing] = useState(false);
    const [searchFilter, setSearchFilter] = useState({});
    const [loading, setLoading] = useState(false);

    const toast = useRef(null);
    const resellerService = new ResellerService();
    const [products, setProducts] = useState([]);
    const [excelProducts, setExcelProducts] = useState([]);
    const [searchRequest, setSearchRequest] = useState({});
    const [allBrand, setAllBrand] = useState([]);
    const canSellService = new CanSellService();


    const [paginationConfig, setPaginationConfig] = useState({
      itemsPerPage: 10,
      first: 0,
      totalRecords: 30,
      activePage: 0,
    });


    useEffect(() => {
        setTimeout(() => {
          getAll({...paginationConfig, activePage: 0})
        }, 500);

    }, [searchFilter, searchCriteria]);



    useEffect(() => {
      canSellService.getAllBrand().then(setAllBrand);

    }, []);

    const getAll =  (pageState) => {
      let _searchCriteria = {...searchCriteria};
      setLoading(true);
      _searchCriteria.brandName = searchFilter?.brandName;
      _searchCriteria.modelName = searchFilter.modelName;
      _searchCriteria.barcode = searchFilter.barcode;

       resellerService.resellerDistributionProduct(_searchCriteria, pageState.itemsPerPage,pageState.activePage)
        .then(res => {
          setProducts(res?.content);

          pageState.first = pageState.itemsPerPage * pageState.activePage;
          pageState.totalRecords = res?.totalElements;
          setPaginationConfig({
            ...pageState,
          });
        }).catch(err => handleError(err, toast))
        .finally(() => {
          setLoading(false);
        })


      resellerService.resellerDistributionProductList(_searchCriteria)
        .then(res => {

          let _excelProductList = [];
          res?.forEach(value => {
            let _excelModel = {};
            _excelModel['Marka'] = value?.brandName;
            _excelModel['Ürün İsmi'] = value?.modelName;
            _excelModel['Barkod'] = value?.barcode;
            _excelModel['Tedarikçi'] = value?.supplyName;
            _excelModel['Distribütör'] = value?.distyName;
            _excelModel['Önerilen Bayi Alış Fiyatı'] = value?.recommendedResellerPrice;
            _excelModel['Önerilen Satış Fiyatı'] = value?.recommendedSellPrice;
            _excelModel['Toleranslı Fiyatı'] = value?.tolerancePrice;
            _excelProductList.push(_excelModel)

          });
          setExcelProducts(_excelProductList)
        }).catch(err => handleError(err, toast))
        .finally(() => {
          setSearchCriteriaProgressing(false);
        })

    }

    const exportExcel = () => {
      import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(excelProducts);
        const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
        saveAsExcelFile(excelBuffer, 'Ürün Fiyat Listesi');
      });

    }

    const saveAsExcelFile = (buffer, fileName) => {
      import('file-saver').then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
      });
    }

    const onActivePageChange = (e) => {
      let pageState = {
        ...paginationConfig,
        itemsPerPage: e.rows,
        activePage: e.page,
      };
      getAll(pageState)
    };

  const onSort = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };


  const onFilter = (e) => {
      console.log("1")
      let _lazyParams = {...searchCriteria, ...e};
      setSearchCriteria(_lazyParams);
    };

    const header =
      <div>
        <div className="p-d-flex p-justify-between">

          <div>

          </div>
          <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"

                  onClick={exportExcel}/>
        </div>
      </div>;

    const renderBrand = () => {
      return (<Dropdown value={searchFilter?.brandName} options={allBrand} showClear
                        optionLabel="name" optionValue="name"
                        placeholder="Marka Ara"
                        onChange={e => {
                          let _searchFilter = {...searchFilter};
                          _searchFilter.brandName = e.target.value
                          setSearchFilter(_searchFilter)
                        }}
                        className="p-column-filter"/>);
    };
    const brandFilterName = renderBrand();

    const renderModel = () => {
      return (<InputText value={searchFilter?.modelName}
                         onChange={e => {
                           let _searchFilter = {...searchFilter};
                           _searchFilter.modelName = e.target.value
                           setSearchFilter(_searchFilter)
                         }}
                         className="p-column-filter"/>)
        ;
    };

    const modelNameFilter = renderModel();


    const renderBarcode = () => {
      return (<InputText value={searchFilter?.barcode}
                         onChange={e => {
                           let _searchFilter = {...searchFilter};
                           _searchFilter.barcode = e.target.value
                           setSearchFilter(_searchFilter)
                         }}
                         className="p-column-filter"/>);
    }

    const barcodeFilter = renderBarcode();


    return (
      < >
        < Toast
          ref={toast}
        />

        <div className="p-col-12">

          <ResponsiveDataTable
            header={header}
            lazy
            loading={loading}
            onFilter={onFilter}
            filters={searchCriteria.filters}
            onSort={onSort}
            sortField={searchCriteria.sortField}
            sortOrder={searchCriteria.sortOrder}
            paginationConfig={paginationConfig}
            onActivePageChange={onActivePageChange}
            emptyMessage="Kayıt Bulunamadı."
            value={products}
          >

            <Column field="brandName" header="Marka" filter
                    filterElement={brandFilterName}
                    filterMatchMode={"contains"}/>
            <Column header="Ürün İsmi" field="modelName" filter
                    filterElement={modelNameFilter}
                    filterMatchMode={"contains"}/>
            <Column header="Barkod" field="barcode" filter
                    filterElement={barcodeFilter}
                    filterMatchMode={"contains"}/>
            <Column header="Tedarikçi" field="supplyName" />
            <Column header="Distribütör" field="distyName" />
            <Column header="Önerilen Bayi Alış Fiyatı" field="recommendedResellerPrice"  />
            <Column header="Önerilen Satış Fiyatı" field="recommendedSellPrice" />
            <Column header="Toleranslı Fiyat" field="tolerancePrice" />
          </ResponsiveDataTable>

        </div>


      </>
    )

  }
;

export default ResellerDistributionProduct;
