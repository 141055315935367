import React, { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import classNames from "classnames";
import { Toast } from "primereact/toast";
import * as services from "./httpService";
import { handleError } from "../../service/HandleErrorService";

export const ForgetPassword = (props) => {
  let emptyProduct = {
    email: "",
    checkCode: "",
    password: "",
    rPassword: "",
  };

  const [submitted, setSubmitted] = useState(false);
  const [login, setLogin] = useState(emptyProduct);
  const toast = useRef(null);
  const [code, setCode] = useState(false);
  const [passwordState, setPasswordState] = useState(false);
  const [forgetState, setForgetState] = useState(true);

  useEffect(() => {
    setLogin(emptyProduct);
    setSubmitted(false);
  }, []);

  const onForget = () => {
    if (!login?.email) {
      return toast.current?.show({
        severity: "warn",
        summary: "Uyarı",
        detail: "GSM No / E-Posta boş bırakılamaz",
        life: 3000,
      });
    }
    services
      .forgetPassword(login)
      .then((res) => {
        setForgetState(false);
        setCode(true);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Şifre Sıfırlama Kodu GSM No / E-Posta Adresinize İletildi",
          life: 3000,
        });
      })
      .catch((error) => {
        handleError(error, toast);
      });
  };
  const checkCode = () => {
    setCode(false);
    setPasswordState(true);
    toast.current.show({
      severity: "success",
      summary: "Başarılı",
      detail: "Yeni Şifrenizi Belirleyebilirsiniz",
      life: 3000,
    });
  };

  const changePassword = () => {
    if (login.password === login.rPassword) {
      const resetPassword = {
        newPassword: login.password,
        passwordResetAuthCode: login.checkCode,
      };
      services
        .resetPassword(resetPassword)
        .then((res) => {
          toast.current.show({
            severity: "success",
            summary: "Başarılı",
            detail: "Şifreniz Başarıyla Yenilenmiştir.",
            life: 3000,
          });
          setTimeout(() => {
            props.history.push("/");
          }, 2000);
        })
        .catch((error) => {
          handleError(error, toast);
        });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Şifreleriniz uyuşmuyor.",
        life: 3000,
      });
    }
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _login = { ...login };
    _login[`${name}`] = val;
    setLogin(_login);
  };

  return (
    <div className="login-body">
      <Toast ref={toast} />
      <div className="login-wrapper">
        <div className="login-panel">
          <img
            src="assets/layout/images/logo.png"
            className="logo"
            alt="diamond-layout"
          />
          {forgetState && (
            <div className="login-form">
              <h2>HOŞ GELDİNİZ</h2>
              <br />
              <br />
              <InputText
                placeholder="GSM No / E-Posta Giriniz"
                id="email"
                value={login ? login.email : null}
                onChange={(e) => onInputChange(e, "email")}
                required
                autoFocus
                className={classNames({
                  "p-invalid": submitted && !login.email,
                })}
              />
              {submitted && !login.email && (
                <small className="p-invalid">
                  GSM No / E-Posta Gereklidir.
                </small>
              )}
              <p>
                Giriş Yapmak Mı İstiyorsunuz? <a href="/">Giriş Yap !</a>
              </p>
              <Button
                onClick={onForget}
                label=" Devam Et"
                type="button"
              ></Button>
            </div>
          )}
          {code && (
            <div className="login-form">
              <h2>Son bir adım kaldı...</h2>
              <br />
              <br />
              <p>GSM No / E-Posta adresinize gelen kodu giriniz.</p>
              <InputText
                placeholder="Değiştirme Kodunuzu Giriniz"
                id="email"
                value={login ? login.checkCode : null}
                onChange={(e) => onInputChange(e, "checkCode")}
                required
                autoFocus
                className={classNames({
                  "p-invalid": submitted && !login.checkCode,
                })}
              />
              {submitted && !login.checkCode && (
                <small className="p-invalid">
                  GSM No / E-Posta Gereklidir.
                </small>
              )}
              <p>
                Giriş Yapmak Mı İstiyorsunuz? <a href="/">Giriş Yap !</a>
              </p>
              <Button
                onClick={checkCode}
                label=" Devam Et"
                type="button"
              ></Button>
            </div>
          )}
          {passwordState && (
            <div className="login-form">
              <h2>Yeni Şifrenizi Belirleyebilirsiniz</h2>
              <br />
              <br />
              <InputText
                placeholder="Yeni şifre "
                id="password"
                type="password"
                value={login ? login.password : null}
                onChange={(e) => onInputChange(e, "password")}
                required
                autoFocus
                className={classNames({
                  "p-invalid": submitted && !login.password,
                })}
              />
              {submitted && !login.password && (
                <small className="p-invalid">
                  GSM No / E-Posta Gereklidir.
                </small>
              )}
              <InputText
                placeholder="Yeni şifre tekrar "
                type="password"
                id="rPassword"
                value={login ? login.rPassword : null}
                onChange={(e) => onInputChange(e, "rPassword")}
                required
                autoFocus
                className={classNames({
                  "p-invalid": submitted && !login.rPassword,
                })}
              />
              {submitted && !login.rPassword && (
                <small className="p-invalid">
                  GSM No / E-Posta Gereklidir.
                </small>
              )}
              <p>
                Giriş Yapmak Mı İstiyorsunuz? <a href="/">Giriş Yap !</a>
              </p>
              <Button
                onClick={changePassword}
                label=" Devam Et"
                type="button"
              ></Button>
            </div>
          )}
          <br />
        </div>

        <div className="login-image">
          <div className="login-image-content" align="center">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <h1>Şifre yenileme</h1>
            <h3>
              {" "}
              Şifre yenileme bağlantısını gönderebilmemiz için GSM No / E-Posta
              adresinize ihtiyacımız var.
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
