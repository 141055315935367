import AspAxios from "../../service/AspAxios";
import * as Paths from '../../service/config'

export default class VoucherService {

  searchModelStock(request, _size, _page, _sort) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.searchModelStock + '?size=' + _size + '&page=' + _page  + '&sort=' + _sort, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  searchModelStockEager(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.searchModelStockEager, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  getAllSubCategories() {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getAllSubCategory).then(res => {
        resolve(res.data);
      }).catch(reject)
    })
  }

  updateAllStatusMax(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.updateAllStatusMax, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  updateStatusMax(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.updateStatusMax, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  getParameterByKey(key) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.getParameterByKey + "/" + key).then(res => {
        resolve(res.data);
      }).catch(reject)
    })
  }

};
