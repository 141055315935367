import React, {useEffect, useRef, useState} from "react";
import * as services from "./httpService";
import {handleError} from "../../service/HandleErrorService";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {Toolbar} from "primereact/toolbar";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {InputMask} from "primereact/inputmask";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import DistyDropdown from "../../components/DistyDropdown";
import SupplyDropdown from "../../components/SupplyDropdown";
import {MultiSelect} from "primereact/multiselect";
import AspAxios from "../../service/AspAxios";
import * as Paths from "../../service/config";
import {Dropdown} from "primereact/dropdown";

const DistUser = () => {
  let emptyUser = {
    name: "",
    surname: "",
    email: "",
    gsmNo: "5",
    roles: [],
    disty: {},
    resellers: [],
    resellersCodes: [],
    supply: {}
  };

  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(emptyUser);
  const [filterRoles,setFilterRoles]=useState([]);

  const [searchRequest, setSearchRequest] = useState({});

  const [userSearch, setUserSearch] = useState({
    disty: "",
    supply: ""
  });

  const [userDialog, setUserDialog] = useState(false);
  const [inValidFields, setInValidFields] = useState({});
  const [roleMap, setRoleMap] = useState({});
  const [selectedResellers, setSelectedReseller] = useState([]);
  const [resellerRoles, setResellerRoles] = useState([]);
  const [distyRoles, setDistyRoles] = useState([]);
  const [supplyRoles, setSupplyRoles] = useState([]);
  const [childResellers, setChildResellers] = useState([]);
  let resellerAdmin = JSON.parse(localStorage.getItem('user')).user?.roles.includes("RESELLER_ADMIN") ? true : false;

  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectRoles, setSelectRoles] = useState(null);
  const loginUser = JSON.parse(localStorage.getItem("user"));
  let _isTTG = false;
  loginUser?.user?.roles.forEach(_role => {
    if (_role.includes("TTG")) {
      _isTTG = true;
    }
  });

  const [isTTGAdmin, setIsTTGAdmin] = useState(_isTTG);
  let parentResellers = JSON.parse(localStorage.getItem('user')).user?.resellers;

  const toast = useRef();
  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    searchUser(pageState, userSearch)
  };

  const isValidUserSearch = () => {
    if (userSearch.supply) {
      return true;
    }
    if (userSearch.disty) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    getAllRoles(paginationConfig, userSearch);
    fetchSupplyRoles();
    fetchDistyRoles();
    fetchResellerRoles();

    if (resellerAdmin) {


      let _parentResellersCode = [];
      parentResellers?.forEach(value => {
        _parentResellersCode.push(value.code)
      });
      AspAxios.post(Paths.getChildReseller, _parentResellersCode)
        .then((res) => {
          let _childrenReseller = [];
          _childrenReseller.push(...res?.data?.map(value => {

            return {
              fullName: value.code + " - " + value.name,
              ...value
            }
          }));
          setChildResellers(_childrenReseller)
        })
        .catch((err) => {


        });
    }
  }, []);

  useEffect(() => {
    searchUser(paginationConfig, userSearch);
  }, [userSearch]);

  function handleSearchUserResponse(res, pageState) {

    let _distinctArray = [];
    res?.content?.forEach(value => {
      let _object = _distinctArray.find(value2 => value2.id === value.id)
      if (_object === undefined) {
        _distinctArray.push(value);
      }
    })

    setUserList(_distinctArray?.filter(_user => {
      if (!_user.roles) {
        return true;
      } else {
        let isWsUserOrDummy = false;
        _user.roles.forEach(_role => {
          if (_role.includes("WEB_SERVICE")) {
            isWsUserOrDummy = true;
          }
        })
        return !isWsUserOrDummy;
      }
    }));


    pageState.first = pageState.itemsPerPage * pageState.activePage;
    pageState.totalRecords = res?.totalElements;
    setPaginationConfig({
      ...pageState,
    });
  }

  const searchUser = (pageState, userSearch) => {
    if (isTTGAdmin == null) {
      return;
    }
    let searchFunction;
    if (isTTGAdmin) {
      if (isValidUserSearch) {
        searchFunction = services.searchUser;
      }
    } else {
      searchFunction = services.searchUserOwn;
    }
    if (searchFunction) {
      searchFunction(userSearch, pageState.activePage, pageState.itemsPerPage)
        .then((res) => {
          handleSearchUserResponse(res, pageState);
        })
        .catch((err) => {
          handleError(err, toast);
        });
    }
  };

  const getAllRoles = () => {
    services
      .getAllRoles()
      .then((res) => {
        let _roleMap = {};
        res.forEach(_roleObject => {
          _roleMap[`${_roleObject.key}`] = _roleObject.value;
        })
        setRoleMap(_roleMap);
      })
      .catch((err) => {
        handleError(err, toast);
      });
  };

  const fetchDistyRoles = () => {
    services
      .getRolesByUsage("disty")
      .then((res) => {
        setDistyRoles(res);
      })
      .catch((err) => {
        handleError(err, toast);
      });
  };

  const fetchSupplyRoles = () => {
    services
      .getRolesByUsage("supply")
      .then((res) => {
        setSupplyRoles(res);
      })
      .catch((err) => {
        handleError(err, toast);
      });
  };

  const fetchResellerRoles = () => {
    services
      .getRolesByUsage("reseller")
      .then((res) => {
        setResellerRoles(res);
      })
      .catch((err) => {
        handleError(err, toast);
      });
  };

  const confirmDeleteUser = (request) => {
    services
      .deleteUser(request)
      .then(() => {
        setUserSearch({...userSearch});
      })
      .catch((err) => {
        handleError(err, toast);
      });
  };

  const confirmActiveUser = (request) => {
    services
      .activeUser(request)
      .then(() => {
        setUserSearch({...userSearch});
      })
      .catch((err) => {
        handleError(err, toast);
      });
  };

  const upsertUser = () => {
    if (validateUser(selectedUser)) {
      services
        .upsertUser(selectedUser)
        .then(() => {
          setUserSearch({...userSearch});
          hideDialog();
        })
        .catch((err) => {
          setInValidFields(handleError(err, toast));
        });


    } else {
      toast.current?.show({
        severity: "warn",
        summary: "Uyarı",
        detail: "Kullanıcı kaydı için zorunlu alanlar dolu olmalıdır.",
      });
    }
  };

  const validateUser = () => {
    let invalidFields = {};

    if (!selectedUser.name) {
      invalidFields.name = true;
    }
    if (!selectedUser.gsmNo || !validateGsmNo(selectedUser.gsmNo)) {
      invalidFields.gsmNo = true;
    }
    if (!selectedUser.roles) {
      invalidFields.roles = true;
    }
    if (!selectedUser.surname) {
      invalidFields.surname = true;
    }
    if (!selectedUser.roles) {
      invalidFields.roles = true;
    }
    if (!selectedUser.email || !validateEmail(selectedUser.email)) {
      invalidFields.email = true;
    }
    setInValidFields(invalidFields);
    return Object.keys(invalidFields).length === 0;

  }

  const onInputChange = (e, name) => {

    const val = (e.target && e.target.value) || "";
    let _user = {...selectedUser};
    _user[`${name}`] = val;
    console.log(selectedUser)
    setSelectedUser(_user);

  };


  const distyChangeHandler = (e) => {
    const val = (e.target && e.target.value) || "";
    setUserSearch({...userSearch, disty: val, supply: null});
  };

  const supplyChangeHandler = (e) => {
    const val = (e.target && e.target.value) || "";
    setUserSearch({...userSearch, disty: null, supply: val});
  };
  const leftTemplate = () => {
    return (
      <div className="p-formgroup-inline">

        <div className="p-field">
          <label htmlFor="name" className="p-sr-only">İsim</label>
          <InputText id="name" value={searchRequest.name} type="text" placeholder="İsim"
                     onChange={e => {
                       let _userSearch={...userSearch};
                       _userSearch.name=e.target.value;
                       setUserSearch(_userSearch)
                     }

                     }/>
        </div>

        <div className="p-field">
          <label htmlFor="gsmNo" className="p-sr-only">İsim</label>
          <InputText id="gsmNo" value={searchRequest.gsmNo} type="text" placeholder="GsmNo"
                     onChange={e => {
                       let _userSearch={...userSearch};
                       _userSearch.gsmNo=e.target.value;
                       setUserSearch(_userSearch)
                     }

                     }/>
        </div>

        <div className="p-field">
          <label htmlFor="email" className="p-sr-only">Email</label>
          <InputText id="email" value={searchRequest?.email} type="text" placeholder="E-mail"
                     onChange={e => {
                       let _userSearch={...userSearch};
                       _userSearch.email=e.target.value;
                       setUserSearch(_userSearch)
                     }

                     }/>
        </div>
        <div className="p-field">
          <MultiSelect display="chip"
                       id="roller"
                       placeholder="Rol"
                       optionLabel="value"
                       optionValue="key"
                       value={filterRoles}
                       onChange={e=>{
                         setFilterRoles(e.target.value);
                         let _userSearch={...userSearch};
                         _userSearch.roles=e.target.value;
                         setUserSearch(_userSearch)
                       }}
                       options={distyRoles}/>
        </div>

        <div className="p-field">

        </div>

      </div>
    )
  }
  const newTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Yeni"
          icon="pi pi-plus"
          className="p-button-success p-mr-2"
          onClick={openNew}
        />
      </React.Fragment>
    );
  };

  const openNew = () => {
    setInValidFields({});
    let newSelectedUser = {...emptyUser, disty: userSearch.disty, supply: userSearch.supply};

      setSelectedRoles(distyRoles);


    setSelectedUser(newSelectedUser);
    setUserDialog(true);
  };


  const editUser = (rowData) => {
    setInValidFields({});

      setSelectedRoles(distyRoles);



    setSelectRoles(rowData?.roles[0])

    console.log(rowData)
    setSelectedUser(rowData);
    setUserDialog(true);
  };

  const hideDialog = () => {
    setSelectRoles(null);
    setUserDialog(false);
    setSelectedUser(emptyUser)
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {rowData.status === 1 ? (
          <div>
            <Button
              icon="pi pi-pencil"
              className="p-button-rounded p-button-warning p-mr-2"
              label="Düzenle"
              onClick={() => editUser(rowData)}
            />
            <Button
              icon="pi pi-eye-slash"
              className="p-button-rounded p-button-danger"
              label="Sil"
              onClick={() => confirmDeleteUser(rowData)}
            />
          </div>
        ) : (
          <div>
            <Button
              icon="pi pi-eye"
              className="p-button-rounded p-button-success p-mr-2"
              label="Aktif Et"
              onClick={() => confirmActiveUser(rowData)}
            />
          </div>
        )}
      </div>
    );
  };

  const userDialogFooter = (
    <>
      <Button
        label="Vazgeç"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button label="Kaydet" icon="pi pi-check" onClick={upsertUser}/>
    </>
  );

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function validateGsmNo(gsmNo) {
    const re = /^(5)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/;
    return re.test(String(gsmNo).toLocaleLowerCase());
  }

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const fullNameBodyTemplate = (rowData) => {
    return responsiveColumn(
      "Kullanıcı:",
      rowData?.name + " " + rowData?.surname
    );
  };

  const emailBodyTemplate = (rowData) => {
    return responsiveColumn(
      "Email:",
      rowData?.email?.length > 21
        ? rowData?.email.substring(0, 21) + "..."
        : rowData?.email
    );
  };

  const gsmBodyTemplate = (rowData) => {
    return responsiveColumn("Telefon:", rowData?.gsmNo);
  };

  const isInvalid = (bindingField1, bindingField2) => {
    if (inValidFields[`${bindingField1}`]) {
      return " p-invalid";
    }
    if (inValidFields[`${bindingField2}`]) {
      return " p-invalid";
    }
    return "";
  };

  const rolesBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Roller</span>
        {rowData?.roles?.map(_role => {

          return <>{roleMap[`${_role}`]}<br/></>
        })}
      </React.Fragment>
    );
  };

  const resellersBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Bayiler</span>
        {rowData?.resellers?.map(_reseller => {
          return <>{_reseller?.code}--{_reseller?.name}<br/></>
        })}
      </React.Fragment>
    );
  };

  return (
    <div className="datatable-responsive-demo">
      <div className="card">
        <Toast ref={toast}/>

        {isTTGAdmin && <DistyDropdown
          value={userSearch.disty}
          onChange={distyChangeHandler}
        />}

        {isTTGAdmin && <SupplyDropdown
          value={userSearch.supply}
          onChange={supplyChangeHandler}
        />}

        {(!isTTGAdmin || (isTTGAdmin && isValidUserSearch())) && <>
          <Toolbar className="p-mb-4" left={leftTemplate} right={newTemplate}/>

          <ResponsiveDataTable
            value={userList}
            datakey="id"
            header="Kullanıcılar"
            paginationConfig={paginationConfig}
            onActivePageChange={onActivePageChange}
            emptyMessage="Kullanıcı Bulunamadı."
          >
            <Column
              field="name"
              header="İsim"
              body={fullNameBodyTemplate}
            />
            <Column
              field="gsmNo"
              header="Gsm No"
              body={gsmBodyTemplate}
            />
            <Column
              field="email"
              header="Email"
              body={emailBodyTemplate}
            />
            <Column
              field="roles"
              header="Roller"
              body={rolesBodyTemplate}
            />


            <Column
              body={actionBodyTemplate}
              headerStyle={{width: "20%", textAlign: "center"}}
              bodyStyle={{textAlign: "center"}}
            />

          </ResponsiveDataTable>
        </>}

        <Dialog
          visible={userDialog}
          style={{width: "650px"}}
          header="Kullanıcı Bilgileri"
          modal
          className="p-fluid"
          footer={userDialogFooter}
          onHide={hideDialog}
        >

          <div className="p-float-label marginInput">
            <InputText
              id="name"
              value={selectedUser.name}
              onChange={(e) => onInputChange(e, "name")}
              required
              autoFocus
              className={"p-d-block" + isInvalid("name")}
            />
            <label htmlFor="name">İsim Giriniz</label>
          </div>
          <div className="p-float-label marginInput">
            <InputText
              id="surname"
              value={selectedUser.surname}
              onChange={(e) => onInputChange(e, "surname")}
              required
              autoFocus
              className={"p-d-block" + isInvalid("surname")}
            />
            <label htmlFor="surname">Soyisim Giriniz</label>
          </div>
          <div className="p-float-label marginInput">
            <InputText
              id="email"
              value={selectedUser.email}
              onChange={(e) => onInputChange(e, "email")}
              required
              autoFocus
              className={"p-d-block" + isInvalid("email")}
            />
            <label htmlFor="email">Email Giriniz</label>
          </div>
          <div className="p-float-label marginInput">
            <InputMask
              id="gsmNo"
              value={selectedUser.gsmNo}
              onChange={(e) => onInputChange(e, "gsmNo")}
              mask="599 999 99 99"
              autoClear={true}
              className={"p-d-block" + isInvalid("gsmNo")}
              required
              autoFocus
            />
            <label htmlFor="gsmNo">Gsm No Giriniz</label>
          </div>
          <div className="p-float-label marginInput">
            <Dropdown

              id="roller"
              optionLabel="value"
              optionValue="key"
              showClear
              value={selectRoles}

              onChange={(e) => {
                let _selectedRoles = [...selectedRoles];
                let _r = _selectedRoles.find(value => value.key === e.target.value)
                let _selectUser = {...selectedUser};
                let _roles = [];

                if (e.target.value !== undefined) {
                  _roles.push(e.target.value)

                } else {
                  _roles = [];

                }
                _selectUser.roles = _roles
                setSelectedUser(_selectUser)

                setSelectRoles(e.target.value)
              }}
              options={distyRoles}/>
            <label htmlFor="roller">Rol Ekleyiniz</label>


          </div>


        </Dialog>
      </div>
    </div>
  );
};

export default DistUser;
