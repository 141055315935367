import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import ParameterService from "./httpService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import classNames from "classnames";

const ParamList = () => {

  const toast = useRef(null);
  const parameterService = new ParameterService();

  const [loading, setLoading] = useState(false);
  const [parameterList, setParameterList] = useState(null);

  const [updateButtonDisabled, setUpdateButtonDisabled] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedKey, setSelectedKey] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState(false);
  const [selectedValue, setSelectedValue] = useState(false);

  useEffect(() => {
    getAllParameters();
  }, []);

  const getAllParameters = () => {
    setLoading(true);
    parameterService.getAllParameters().then(res => {
      console.log("searchModelStock", res);
      setParameterList(res.filter(value => value.manuelUpdateDisabled == null || !value.manuelUpdateDisabled));
    }).catch(err => {
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'Parametre bilgisi getirme başarısız.'
      });
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  };

  const updateModalShow = (e) => {
    setSubmitted(false);
    setUpdateModal(true);
    parameterList.forEach(p => {
      if (p.key == e.currentTarget.id) {
        setSelectedKey(p.key);
        setSelectedDescription(p.description);
        setSelectedValue(p.value);
      }
    });
  };

  const updateModalHide = () => {
    setUpdateModal(false);
  };

  const updateParameter = () => {
    setSubmitted(true);
    setUpdateButtonDisabled(true);
    if (selectedValue != null && selectedValue !== "") {
      const request = {
        key: selectedKey,
        description: selectedDescription,
        value: selectedValue
      };
      parameterService.updateParameter(request).then(res => {
        console.log("updateParameter", res);
        toast.current.show({
          severity: 'success',
          summary: 'Başarılı İşlem',
          detail: 'Parametre düzenlendi.'
        });
        updateModalHide();
        getAllParameters();
      }).catch(err => {
        console.log(err);
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Parametre düzenlenemedi.'
        });
      }).finally(() => {
        setUpdateButtonDisabled(false);
      })
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Değer alanı dolu olmalıdır.'
      });
      setUpdateButtonDisabled(false);
    }
  };

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const keyTemplate = (data) => {
    return responsiveColumn("Ad", data?.key)
  };

  const descriptionTemplate = (data) => {
    return responsiveColumn("Açıklama", data?.description)
  };

  const valueTemplate = (data) => {
    return responsiveColumn("Değer", data?.value)
  };

  const parametreButtonTemplate = (data) => {
    return (
      <div className="p-d-flex p-justify-even">
        <Button id={data.key} type="button" className="p-button-sm p-button-info" label="Düzenle"
                onClick={updateModalShow}/>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-12">
            <ResponsiveDataTable
              className="p-datatable-responsive-demo"
              header="Parametreler"
              value={parameterList}
              loading={loading}
              emptyMessage="Parametre bulunamadı"
              paginator rows={10} rowsPerPageOptions={[10, 20, 30]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} arası gösteriliyor"
            >
              <Column field="key" body={keyTemplate} header="Ad" filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="description" body={descriptionTemplate} header="Açıklama" filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="value" body={valueTemplate} header="Değer" filter={true}
                      filterMatchMode={"contains"}/>
              <Column body={parametreButtonTemplate}
                      headerStyle={{width: "8rem", textAlign: "center"}}
                      bodyStyle={{textAlign: 'center', overflow: 'visible', justifyContent: 'center'}}/>
            </ResponsiveDataTable>
          </div>
        </div>
        <Dialog header="Parametre Düzenle" visible={updateModal} modal style={{width: '320px'}}
                onHide={updateModalHide}>
          <div className="p-grid p-fluid">
            <div className="p-col-12">
           <span className="p-float-label marginInput">
            <InputText id="key" value={selectedKey}
                       disabled={true}/>
            <label htmlFor="key">Ad</label>
           </span>
              <span className="p-float-label marginInput">
            <InputText id="description" value={selectedDescription}
                       onChange={(e) => setSelectedDescription(e.target.value)}/>
            <label htmlFor="description">Açıklama</label>
           </span>
              <span className="p-float-label marginInput">
            <InputText id="value" value={selectedValue}
                       onChange={(e) => setSelectedValue(e.target.value)}
                       className={classNames({'p-invalid': submitted && !selectedValue})}/>
            <label htmlFor="value">Değer</label>
           </span>
              <Button style={{marginTop: 20}} className="p-button-success" label="Kaydet"
                      disabled={updateButtonDisabled} onClick={updateParameter}/>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  )

};

export default ParamList;
