import {Toast} from "primereact/toast";
import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import ProductPriceService from "./ProductPriceSupplyService";
import {handleError} from "../../../service/HandleErrorService";
import {InputText} from "primereact/inputtext";
import moment from "moment";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {ResponsiveDataTable} from "../../../components/ResponsiveDataTable";
import {InputTextarea} from "primereact/inputtextarea";
import {Calendar} from "primereact/calendar";
import {Dialog} from "primereact/dialog";

const ProductPriceSupplyDetail = () => {

  const history = useHistory();
  const toast = useRef(null);
  const {productId, mid} = useParams();
  const productPriceService = new ProductPriceService();
  const [currentModelPrice, setCurrentModelPrice] = useState({});
  const [upsertModelPrice, setUpsertModelPrice] = useState({});
  const [lastModelPrice, setLastModelPrice] = useState([]);
  const [startDate, setStartDate] = useState();
  const distyAdmin = JSON.parse(localStorage.getItem('user')).user?.roles.includes("DISTY_ADMIN");
  const distyUser = JSON.parse(localStorage.getItem('user')).user?.roles.includes("DISTY_USER");

  const [rejectedList, setRejectedList] = useState([]);
  const [selectDialog, setSelectDialog] = useState(false);
  const [rejectDescription, setRejectDescription] = useState(false);

  const [errorFields, setErrorFields] = useState({});

  useEffect(() => {
    getProductPriceDetail();
    modelPriceSupplyRejectedList();
  }, []);

  const getProductPriceDetail = () => {

    productPriceService.currentModelPricePriceDetail(productId).then(res => {
      setCurrentModelPrice(res);
      let _upsertModelPrice = {...upsertModelPrice};
      _upsertModelPrice.taxRatio = res?.taxRatio;
      _upsertModelPrice.distyPrice = res?.distyPrice;
      _upsertModelPrice.recommendedEdmPrice = res?.recommendedEdmPrice;
      _upsertModelPrice.recommendedResellerPrice = res?.recommendedResellerPrice;
      _upsertModelPrice.resellerPrice = res?.resellerPrice;
      _upsertModelPrice.recommendedSellPrice = res?.recommendedSellPrice;
      _upsertModelPrice.modelId = res?.model?.id;
      _upsertModelPrice.startDate = new Date(res?.startDate);

      setStartDate(res?.startDate);
      setUpsertModelPrice(_upsertModelPrice);

      productPriceService.lastModelPrices(mid).then(res => {
        setLastModelPrice(res);
      }).catch(err => handleError(err, toast))
    }).catch(err => {
      console.log(err);
    })
  };

  const modelPriceSupplyRejectedList = () => {
    productPriceService.modelPriceSupplyRejectedList(mid).then(res => {
      console.log("modelPriceSupplyRejectedList", res);
      setRejectedList(res);
    }).catch(err => {
      console.log(err);
      handleError(err, toast)
    })
  };

  const upsert = async () => {
    let _upsertModelPrice = {...upsertModelPrice};

    if(_upsertModelPrice.startDate != null){

      if (_upsertModelPrice.resellerPrice === currentModelPrice.resellerPrice) {
        _upsertModelPrice.sameControl = false;
      }
      await productPriceService.upsert(_upsertModelPrice).then(res => {
        toast.current.show({
          severity: 'success',
          summary: 'Başarılı İşlem',
          detail: 'İşleminiz Onaya Gönderilmiştir',
          life: 1200,

        });
        //getProductPriceDetail();
        setErrorFields({});
        if(distyAdmin || distyUser){
          setTimeout(() => {
            history.push("/ProductPrice-Disty")
          }, 1300)
        } else {
          setTimeout(() => {
            history.push("/ProductPrice-Supply")
          }, 1300)
        }
      }).catch(err => setErrorFields(handleError(err, toast)))
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Tarih alanı dolu olmalıdır.'
      });
    }
  };

  const priceTemplate = (e, name, checkStyle) => {

    if (e[name] === undefined) {
      return <div>Girilmedi.</div>
    } else if (e[checkStyle] === false) {

      return <div style={{backgroundColor: "pink"}}>{e[name]}₺</div>
    } else if (e[checkStyle] === true) {
      return <div style={{backgroundColor: "chartreuse"}}>{e[name]}₺</div>

    } else return <div style={{backgroundColor: 'antiquewhite'}}>{e[name]}₺</div>

  }


  const taxRatioTemplate = (e) => {
    if (e['taxRatio'] === undefined) {
      return <div>Girilmedi.</div>
    } else return <div>%{e['taxRatio']}</div>
  };

  const modelPriceTaxRatioTemplate = (e) => {
    if (e?.modelPriceSupply?.taxRatio === undefined) {
      return <div>Girilmedi.</div>
    } else return <div>%{e?.modelPriceSupply?.taxRatio}</div>
  };

  const isInvalid = (bindingField1, bindingField2) => {
    if (errorFields[`${bindingField1}`]) {

      return " p-invalid";
    }
    if (errorFields[`${bindingField2}`]) {
      return " p-invalid";
    }
    return "";
  };

  const actions = (rowData) => {
    if(rowData.description != null && rowData.description != ""){
      return <div className="p-justify-center"><Button
        className="p-button-rounded p-mr-2"
        label="Açıklama"
        onClick={e => {
          setRejectDescription(rowData.description);
          setSelectDialog(true)
        }}
      />
      </div>
    } else {
      return <div>Açıklama Yok</div>
    }
  };

  return (
    <>
      <Toast ref={toast}/>
      <div className="card" style={{paddingTop: 0}}>

        <h4 style={{marginBottom: 0}}>Ürün Bilgileri</h4>
        <div className="p-fluid p-formgrid p-grid">

          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="category">Kategori</label>
            <InputText disabled id="category" value={currentModelPrice?.model?.subCategory?.category.name}/>
          </div>

          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="subCategory">Alt Kategori</label>
            <InputText id="subCategory" disabled value={currentModelPrice?.model?.subCategory?.name}/>
          </div>

          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="brand">Marka</label>
            <InputText id="brand" disabled value={currentModelPrice?.model?.brand?.name}/>
          </div>

          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="product">Ürün İsmi</label>
            <InputText disabled id="product" value={currentModelPrice?.model?.name}/>
          </div>

          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="barcode">Barkod</label>
            <InputText id="barcode" disabled value={currentModelPrice?.model?.barcode}/>
          </div>


          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="barcode">Ürün Açıklaması</label>
            <InputTextarea autoResize id="description" disabled value={currentModelPrice?.model?.description}/>
          </div>


          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="taxRatioSelect">Önerilen KDV Oranları</label>
            <Dropdown
              options={[1, 8, 18, 20]}
              value={upsertModelPrice.taxRatio}
              onChange={e => {
                let _upsertModelPrice = {...upsertModelPrice};
                _upsertModelPrice.taxRatio = e.target.value;
                setUpsertModelPrice(_upsertModelPrice)
              }}

            />
          </div>

          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="startDate">Giriş Zamanı</label>
            <InputText disabled id="startDate"

                       value={moment(currentModelPrice?.startDate).format("DD/MM/YYYY")}
            />
          </div>
          <div className="p-field p-col-12 p-md-3">

            <label htmlFor="startDate">Ürün Fiyatlarının Geçerli Olacağı Tarih</label>
            <Calendar id="startDate"
                      panelStyle={{height: 300}}
                      panelClassName={"smallCalendar"}
                      dateFormat={'dd/mm/yy'}
                      value={upsertModelPrice.startDate}
                      onChange={e => {
                        let _upsertModelPrice = {...upsertModelPrice};
                        _upsertModelPrice.startDate = e.target.value;
                        setUpsertModelPrice(_upsertModelPrice)
                      }}

            />
          </div>


          <div className="p-field p-col-12 p-md-2">
            <label htmlFor="distyPrice">Distribütör Alış fiyatı</label>
            <InputText id="distyPrice" type="number" step="0,1" value={upsertModelPrice?.distyPrice}
                       className={isInvalid('distyPrice')}
                       onChange={e => {
                         let _errorFields = {...errorFields};
                         _errorFields[e.target.id] = false;
                         if (e.target.value === "") {
                           _errorFields[e.target.id] = true;
                         }
                         setErrorFields(_errorFields)
                         let _upsertModelPrice = {...upsertModelPrice};
                         _upsertModelPrice.distyPrice = parseFloat(e.target.value);
                         setUpsertModelPrice(_upsertModelPrice)
                       }}/></div>

          <div className="p-field p-col-12 p-md-2">
            <label htmlFor="recommendedEdmPrice">Önerilen EDM Alış fiyatı</label>
            <InputText id="recommendedEdmPrice" type="number" step="0,1" value={upsertModelPrice?.recommendedEdmPrice}
                       className={isInvalid('recommendedEdmPrice')}
                       onChange={e => {
                         let _errorFields = {...errorFields};
                         _errorFields[e.target.id] = false;
                         if (e.target.value === "") {
                           _errorFields[e.target.id] = true;
                         }
                         setErrorFields(_errorFields)
                         let _upsertModelPrice = {...upsertModelPrice};
                         _upsertModelPrice.recommendedEdmPrice = parseFloat(e.target.value);
                         setUpsertModelPrice(_upsertModelPrice)
                       }}/></div>


          <div className="p-field p-col-12 p-md-2">
            <label htmlFor="recommendedResellerPrice">Önerilen Bayi Alış fiyatı</label>
            <InputText id="recommendedResellerPrice" type="number" step="0,1"
                       value={upsertModelPrice?.recommendedResellerPrice}
                       className={isInvalid('recommendedResellerPrice')}
                       onChange={e => {
                         let _errorFields = {...errorFields};
                         _errorFields[e.target.id] = false;
                         if (e.target.value === "") {
                           _errorFields[e.target.id] = true;
                         }
                         setErrorFields(_errorFields)
                         let _upsertModelPrice = {...upsertModelPrice};
                         _upsertModelPrice.recommendedResellerPrice = parseFloat(e.target.value);
                         setUpsertModelPrice(_upsertModelPrice)
                       }}/></div>

          <div className="p-field p-col-12 p-md-2">
            <label htmlFor="recommendedSellPrice">Önerilen Satış fiyatı</label>
            <InputText id="recommendedSellPrice" type="number" step="0,1" value={upsertModelPrice?.recommendedSellPrice}
                       className={isInvalid('recommendedSellPrice')}
                       onChange={e => {

                         let _errorFields = {...errorFields};
                         _errorFields[e.target.id] = false;
                         if (e.target.value === "") {
                           _errorFields[e.target.id] = true;
                         }
                         setErrorFields(_errorFields)
                         let _upsertModelPrice = {...upsertModelPrice};
                         _upsertModelPrice.recommendedSellPrice = parseFloat(e.target.value);
                         setUpsertModelPrice(_upsertModelPrice)
                       }}/></div>

          <div style={{marginTop: 24}} className="p-field p-col-12 p-md-1 p-justify-start">
            <Button label="Güncelle" onClick={upsert}/>
          </div>
        </div>

      </div>


      <div className="datatable-responsive-demo">
        <div className="card">
          <ResponsiveDataTable value={lastModelPrice}
                               paginator
                               rows={10} rowsPerPageOptions={[10, 20, 50, 100]}
                               header="Ürünün Geçmiş Fiyat Listesi">
            <Column body={e => moment(e.startDate).format("DD/MM/YYYY")} header="Başlangıç Tarihi"/>
            <Column body={e => moment(e.endDate).format("DD/MM/YYYY")} header="Bitiş Tarihi"/>
            <Column body={taxRatioTemplate} header="KDV Oranı"/>
            <Column body={e => priceTemplate(e, 'distyPrice', 'checkDistyPrice')} header="Distribütor Alış Fiyatı"/>
            <Column body={e => priceTemplate(e, 'recommendedResellerPrice', 'checkRecommendedResellerPrice')}
                    header="Önerilen Bayi Alış Fiyatı"/>
            <Column body={e => priceTemplate(e, 'recommendedSellPrice', 'checkRecommendedSellPrice')}
                    header="Önerilen Satış Fiyatı"/>


          </ResponsiveDataTable>
        </div>
      </div>
      <div className="datatable-responsive-demo">
        <div className="card">
          <ResponsiveDataTable value={rejectedList}
                               paginator
                               rows={10} rowsPerPageOptions={[10, 20, 50, 100]}
                               header="Reddedilen Fiyat Listesi">
            <Column field="createDateTime" body={e => {
              return (<div>
                {moment(e?.createDateTime).format("DD/MM/YYYY")}
              </div>)
            }} header="Onaya Gönderilme Tarihi"/>
            <Column field="disty.name" header="Distribütör"/>
            <Column field="modelPriceSupply.startDate" body={e => {
              return (<div>
                {moment(e?.modelPriceSupply?.startDate).format("DD/MM/YYYY")}
              </div>)
            }} header="Başlangıç Tarihi"/>
            <Column field="modelPriceSupply.startDate" body={e => {
              return (<div>
                {moment(e?.modelPriceSupply?.endDate).format("DD/MM/YYYY")}
              </div>)
            }} header="Bitiş Tarihi"/>
            <Column body={modelPriceTaxRatioTemplate} header="KDV Oranı"/>
            <Column body={e => priceTemplate(e?.modelPriceSupply, 'distyPrice', 'checkDistyPrice')}
                    header="Distribütor Alış Fiyatı"/>
            <Column
              body={e => priceTemplate(e?.modelPriceSupply, 'recommendedResellerPrice', 'checkRecommendedResellerPrice')}
              header="Önerilen Bayi Alış Fiyatı"/>
            <Column body={e => priceTemplate(e?.modelPriceSupply, 'recommendedSellPrice', 'checkRecommendedSellPrice')}
                    header="Önerilen Satış Fiyatı"/>
            <Column body={e => actions(e)}/>
          </ResponsiveDataTable>
        </div>
      </div>
      <div className="p-col-1">
        <Button label="Geri Dön" onClick={e => history.push("/ProductPrice-Supply")} className="p-button-secondary"/>
      </div>
      <Dialog style={{width: 400}} onHide={e => {
        setRejectDescription(null);
        setSelectDialog(false);
      }}
              visible={selectDialog}>
        <h5>Açıklama</h5>
        <p>{rejectDescription}</p>
      </Dialog>
    </>
  )
}
export default ProductPriceSupplyDetail;
