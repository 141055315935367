import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import ModelStockDistyService from "./httpService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";

const ModelStockList = () => {

  const distyAdmin = JSON.parse(localStorage.getItem('user')).user?.roles.includes("DISTY_ADMIN");
  const distyCode = JSON.parse(localStorage.getItem('user')).user?.disty?.code;
  const supplyAdmin = JSON.parse(localStorage.getItem('user')).user?.roles.includes("SUPPLY_ADMIN");
  const supplyId = JSON.parse(localStorage.getItem('user')).user?.supply?.id;
  const toast = useRef(null);
  const modelStockDistyService = new ModelStockDistyService();

  const [loading, setLoading] = useState(false);
  const [searchCriteriaLazyLoading, setSearchCriteriaLazyLoading] = useState(false);
  const [searchCriteriaProgressing, setSearchCriteriaProgressing] = useState(false);
  const [saleInfoList, setSaleInfoList] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState({});
  const [selectedDistyCode, setSelectedDistyCode] = useState(null);
  const [selectedStatusCode, setSelectedStatusCode] = useState(null);

  const [exportExcelDisabled, setExportExcelDisabled] = useState(null);

  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10, first: 0, totalRecords: 30, activePage: 0,
  });

  const [saleStatusOptions, setSaleStatusOptions] = useState([{value: "NONE", name: "YOK"}, {
    value: "LIMITED",
    name: "LİMİTLİ"
  }, {value: "AVAILABLE", name: "VAR"}]);

  useEffect(() => {
    if (distyAdmin) {
      setSelectedDistyCode(distyCode);
    } else {
      searchSaleInfo({...paginationConfig, activePage: 0});
    }
  }, []);

  useEffect(() => {
    setSearchCriteriaLazyLoading(true);
  }, [selectedDistyCode, selectedStatusCode, searchCriteria]);

  useEffect(() => {
    if (searchCriteriaLazyLoading) {
      setTimeout(() => {
        setSearchCriteriaProgressing(true);
        setSearchCriteriaLazyLoading(false);
      }, 2000);
    }
  }, [searchCriteriaLazyLoading]);

  useEffect(() => {
    if (searchCriteriaProgressing) {
      searchSaleInfo({...paginationConfig, activePage: 0});
      setSearchCriteriaProgressing(false);
    }
  }, [searchCriteriaProgressing]);

  const searchSaleInfo = (pageState) => {
    setLoading(true);
    var sort = "";
    if (searchCriteria.sortField != null && searchCriteria.sortOrder != null) {
      sort += searchCriteria.sortField;
      if (searchCriteria.sortOrder == 1) {
        sort += ",asc";
      } else {
        sort += ",desc";
      }
    } else {
      sort += "updateDateTime,desc";
    }
    if (supplyAdmin) {
      searchCriteria.supplyId = supplyId;
    }
    searchCriteria.distyCode = selectedDistyCode;
    searchCriteria.stockStatus = selectedStatusCode;

    modelStockDistyService.searchModelStock(searchCriteria, pageState.itemsPerPage, pageState.activePage, sort).then(res => {
      console.log("searchModelStock", res.content);
      if (res.content && res.content.length > 0) {
        setSaleInfoList(res.content);

        pageState.totalRecords = res.totalElements;
        pageState.first = pageState.itemsPerPage * pageState.activePage;
        setPaginationConfig({
          ...pageState,
        });
      } else {
        setSaleInfoList([]);
      }
    }).catch(err => {
      toast?.current?.show({
        severity: 'error', summary: 'Hata', detail: 'Stok bilgisi getirme başarısız.'
      });
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  };

  const exportExcel = () => {
    setExportExcelDisabled(true);
    if (supplyAdmin) {
      searchCriteria.supplyId = supplyId;
    }
    searchCriteria.distyCode = selectedDistyCode;
    searchCriteria.stockStatus = selectedStatusCode;

    modelStockDistyService.searchModelStockEager(searchCriteria).then(res => {
      console.log("searchModelStockEager", res);
      import('xlsx').then(xlsx => {
        var excelList = [];
        res.forEach(a => {
          excelList.push({
            "Distribütör Kodu": a.disty?.code,
            "Barkod": a.model?.barcode,
            "Marka": a.model?.brand?.name,
            "Model": a.model?.name,
            "Stok Durumu": a.stockStatusDisplay,
            "Stok": a.stock,
            "Sepette": a.quantityInCart
          })
        });
        const worksheet = xlsx.utils.json_to_sheet(excelList);
        var wscols = [{width: 15}, {width: 15}, {width: 15}, {width: 80}, {width: 15}, {width: 15}, {width: 15},];
        worksheet["!cols"] = wscols;
        const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
        saveAsExcelFile(excelBuffer, 'Stok Bilgileri');
      });
    }).catch(err => {
      toast?.current?.show({
        severity: 'error', summary: 'Hata', detail: 'Stok bilgisi getirme başarısız.'
      });
      console.log(err);
    }).finally(() => {
      setExportExcelDisabled(false);
    });
  };

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig, itemsPerPage: e.rows, activePage: e.page,
    };
    searchSaleInfo(pageState);
  };

  const onFilter = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const onSort = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const distyCodeFilter = <InputText value={selectedDistyCode}
                                     onChange={(e) => setSelectedDistyCode(e.target.value)}
                                     disabled={distyAdmin} className="p-column-filter"/>;

  const addCommas = num => num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const responsiveColumn = (title, content) => {
    return (<div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>);
  };

  const responsiveColumnRight = (title, content) => {
    return (<div>
        <span className="p-column-title">{title}</span>
        <span style={{float: 'right'}}>{content}</span>
      </div>);
  };

  const distyCodeTemplate = (data) => {
    return responsiveColumn("Distribütör Kodu", data?.disty?.code)
  };

  const barcodeTemplate = (data) => {
    return responsiveColumn("Barkod", data?.model?.barcode)
  };

  const brandNameTemplate = (data) => {
    return responsiveColumn("Marka", data?.model?.brand?.name)
  };

  const modelNameTemplate = (data) => {
    return responsiveColumn("Model", data?.model?.name)
  };

  const productSaleStatusDisplayTemplate = (data) => {
    return responsiveColumn("Stok Durumu", data?.stockStatusDisplay)
  };

  const stockTemplate = (data) => {
    return responsiveColumnRight("Stok", addCommas(data?.stock))
  };

  const quantityInCartTemplate = (data) => {
    return responsiveColumnRight("Sepette", addCommas(data?.quantityInCart))
  };

  const header = <div>
    <div className="p-d-flex p-justify-between">
      <div>Distribütör Stok Bilgileri</div>
      <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
              disabled={exportExcelDisabled}
              onClick={exportExcel}/>
    </div>
  </div>;

  const renderSaleStatusFilter = () => {
    return (
      <Dropdown value={selectedStatusCode} options={saleStatusOptions} onChange={(e) => setSelectedStatusCode(e.value)}
                optionLabel="name" showClear className="p-column-filter"/>);
  };

  const saleStatusFilterElement = renderSaleStatusFilter();

  return (<>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-12">
            <ResponsiveDataTable
              lazy
              header={header}
              value={saleInfoList}
              paginationConfig={paginationConfig}
              onActivePageChange={onActivePageChange}
              onFilter={onFilter}
              filters={searchCriteria.filters}
              onSort={onSort}
              sortField={searchCriteria.sortField}
              sortOrder={searchCriteria.sortOrder}
              loading={loading}
              emptyMessage="İşlem bulunamadı"
            >
              <Column field="distyCode" body={distyCodeTemplate} header="Distribütör Kodu" filter={true}
                      filterElement={distyCodeFilter} filterMatchMode={"contains"}/>
              <Column field="barcode" body={barcodeTemplate} header="Barkod" filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="brandName" body={brandNameTemplate} header="Marka" filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="modelName" body={modelNameTemplate} header="Model" filter={true}
                      headerStyle={{width: "25%"}} filterMatchMode={"contains"}/>
              <Column field="productSaleStatus" body={productSaleStatusDisplayTemplate} header="Stok Durumu" filter
                      filterElement={saleStatusFilterElement}/>
              <Column field="stock" body={stockTemplate} header="Stok"
                      headerStyle={{textAlign: 'right'}} sortable/>
              <Column field="quantityInCart" body={quantityInCartTemplate} header="Sepette"
                      headerStyle={{textAlign: 'right'}} sortable/>
            </ResponsiveDataTable>
          </div>
        </div>
      </div>
    </>)

};

export default ModelStockList;
