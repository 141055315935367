import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {useHistory, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import ProductService from "./httpService";
import {Toast} from "primereact/toast";
import {handleError} from "../../service/HandleErrorService";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import historyContext from "react-router/modules/HistoryContext";
import {FileUpload} from "primereact/fileupload";
import {ProgressBar} from "primereact/progressbar";
import {Tag} from "primereact/tag";
import {Dialog} from "primereact/dialog";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Column} from "primereact/column";

const ProductDetail = () => {
  const [loading, setLoading] = useState(false);
  const {productId} = useParams();
  const toast = useRef(null);
  const fileUploadRef = useRef(null);
  const [totalSize, setTotalSize] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const history = useHistory();
  const [currentModel, setCurrentModel] = useState({});
  const [brand, setBrand] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const productService = new ProductService();
  const [errorFields, setErrorFields] = useState({});
  const [documentDialogOpen, setDocumentDialogOpen] = useState(false);
  const [deletePictures, setDeletePictures] = useState([]);
  const [approve, setApprove] = useState(false);
  const [pictures, setPictures] = useState([]);
  const [res, setRes] = useState(false);
  const [res2, setRes2] = useState(false);
  const [res3, setRes3] = useState(false);
  let [createModel, setCreateModel] = useState({
    id: null,
    category: null,
    subCategory: null,
    brand: null,
    name: null,
    description: null,
    barcode: null,
    eol: null,
    origin: null,
    supplyStatus: null

  });
  useEffect(() => {

    getDetail();


  }, [])

  const getDetail = () => {
    productService.getById(productId).then(res => {
      setCurrentModel(res)
      let _upsertProduct = {};
      _upsertProduct.id = res?.id
      _upsertProduct.category = res.subCategory?.category?.name;
      _upsertProduct.subCategory = res?.subCategory?.name;
      _upsertProduct.brand = res?.brand?.name;
      _upsertProduct.name = res.name
      _upsertProduct.description = res.description;
      _upsertProduct.barcode = res?.barcode;
      _upsertProduct.eol = res?.eol
      res.approveType === "WAITING" ? setApprove(true) : setApprove(false);

      setPictures(res?.pictures?.filter(value => value.pictureType === "WEB" && (value?.pictureCrudType === "INSERTED"
        || value?.pictureCrudType === "NOTWANTDELETE" || value?.pictureCrudType === "WANTDELETE")));
      if (res?.eol) {
        _upsertProduct.eolValue = "EVET";
      } else {
        _upsertProduct.eolValue = "HAYIR";

      }
      _upsertProduct.origin = res?.origin
      _upsertProduct.supplyStatus = res?.supplyStatus;

      setCreateModel(_upsertProduct);
    }).catch(err => handleError(err, toast))

  }
  const changeModel = (e, name) => {
    let _errorFields = {...errorFields};
    _errorFields[name] = false;
    if (e.target.value === "") {
      _errorFields[name] = true;
    }
    setErrorFields(_errorFields)

    let _createModel = {...createModel};
    _createModel[name] = e.target.value;
    setCreateModel(_createModel)
  }

  const upsertModel = () => {
    let _upsertProduct = {...createModel};
    let _selectedFiles = [...selectedFiles];
    setLoading(true);
    productService.upsert(_upsertProduct).then(res => {


      let formData = new FormData();
      _selectedFiles?.forEach(value => {
        formData.append("file", value);
      })
      productService.upsertPhoto(formData, _upsertProduct.id, res.success).then(res2 => {


        productService.deletePhotos(deletePictures, !res2 && res.success).then(res3 => {


          if (!res?.success && !res2 && !res3) {
            return toast.current?.show({
              severity: 'success',
              summary: 'Başarılı İşlem',
              detail: 'Ürün Güncellenmiştir.',
              life: 1200
            });
          }
          if (res.success || res3 || res2) {
            return toast.current?.show({
              severity: 'success',
              summary: 'Başarılı İşlem',
              detail: 'Ürün Güncelleme talebiniz onaya gönderilmiştir.',
              life: 1200
            });
          }


        }).catch(err => handleError(err, toast))

      }).catch(err => handleError(err, toast));

      setLoading(false);
    }).catch(err => setErrorFields(handleError(err, toast)))


    setErrorFields({});

    setTimeout(() => {
      history.push("/ProductManagement")

    }, 1300)
  }

  const onTemplateSelect = (e) => {

    let _selectedFiles = [...selectedFiles];
    let _totalSize = totalSize;
    Array.from(e.files)?.forEach(file => {
      _totalSize += file.size;

      _selectedFiles.push(file)
    });


    setSelectedFiles(_selectedFiles);
    setTotalSize(_totalSize);
  }
  const emptyTemplate = () => {
    return (
      <div className="p-d-flex p-ai-center p-dir-col">
        <i className="pi pi-image p-mt-3 p-p-5" style={{
          'fontSize': '5em',
          borderRadius: '50%',
          backgroundColor: 'var(--surface-b)',
          color: 'var(--surface-d)'
        }}/>
        <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}}
              className="p-my-5">Resimi Sürükleyiniz</span>
      </div>
    )
  }

  const onTemplateClear = () => {
    setTotalSize(0);
    setSelectedFiles([]);

  }
  const onTemplateRemove = (file, callback) => {

    setSelectedFiles(selectedFiles.filter(file2 => file2.name !== file.name));
    setTotalSize(totalSize - file.size);
    callback();
  }
  const headerTemplate = (options) => {
    const {className, chooseButton, cancelButton} = options;
    const value = totalSize / 10000;
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

    return (
      <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
        {chooseButton}

        {cancelButton}
        <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 1 MB`} style={{
          width: '300px',
          height: '20px',
          marginLeft: 'auto'
        }}/>
      </div>
    );
  }


  const itemTemplate = (file, props) => {
    return (
      <div className="p-d-flex p-ai-center p-flex-wrap">
        <div className="p-d-flex p-ai-center" style={{width: '40%'}}>


          <img alt={file.name} role="presentation" src={file.objectURL} width={100}/>
          <span className="p-d-flex p-dir-col p-text-left p-ml-3">
                        {file.name}
            <small>{new Date().toLocaleDateString()}</small>
                    </span>
        </div>
        <Tag value={props.formatSize} severity="warning" className="p-px-3 p-py-2"/>
        <Button type="button" icon="pi pi-times"
                className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
                onClick={() => onTemplateRemove(file, props.onRemove)}/>
      </div>
    )
  }

  const chooseOptions = {
    icon: 'pi pi-fw pi-images',
    iconOnly: true,
    className: 'custom-choose-btn p-button-rounded p-button-outlined'
  };
  const uploadOptions = {
    icon: 'pi pi-fw pi-cloud-upload',
    iconOnly: true,
    className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'
  };
  const cancelOptions = {
    icon: 'pi pi-fw pi-times',
    iconOnly: true,
    className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'
  };
  const isInvalid = (bindingField1, bindingField2) => {
    if (errorFields[`${bindingField1}`]) {

      return " p-invalid";
    }
    if (errorFields[`${bindingField2}`]) {
      return " p-invalid";
    }
    return "";
  }

  const showDocumentDialog = () => {
    setDocumentDialogOpen(true);
  }


  const onHide = () => {
    setDocumentDialogOpen(false)

  }

  const imageTemplate = (e) => {

    return <img src={e?.baseUrl} style={{height: 'auto', width: 200}}></img>
  }

  const actions = (rowData) => {


    return <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text"
                   onClick={e => {
                     let _pictures = [...pictures];
                     _pictures.splice(_pictures.indexOf(rowData), 1);
                     setPictures(_pictures)
                     deletePictures.push(rowData);

                   }}/>
  }

  return (
    <>


      <Toast ref={toast}/>
      <h4>Ürün Bilgileri</h4>

      <div className="card">

        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-8 p-md-4">
      <span className="p-float-label marginInput">
          <InputText id="category" value={createModel?.category}
                     className={isInvalid('category')}
                     onChange={e => changeModel(e, 'category')}


          />
                     <label htmlFor="category">Kategori</label>
        </span>
          </div>
          <div className="p-field p-col-8 p-md-4">
      <span className="p-float-label marginInput">
          <InputText id="subCategory" value={createModel?.subCategory}
                     className={isInvalid('subCategory')}

                     onChange={e => changeModel(e, 'subCategory')}

          />
                     <label htmlFor="subCategory">Alt Kategori</label>
        </span>
          </div>
          <div className="p-field p-col-8 p-md-4">

      <span className="p-float-label marginInput">
          <InputText id="brand" value={createModel?.brand}
                     className={isInvalid('brand')}

                     onChange={e => changeModel(e, 'brand')}

          />
                     <label htmlFor="brand">Marka</label>
        </span>
          </div>
          <div className="p-field p-col-8 p-md-4">
      <span className="p-float-label marginInput">
          <InputText id="name" value={createModel?.name}
                     className={isInvalid('name')}

                     onChange={e => changeModel(e, 'name')}

          />
                     <label htmlFor="name">Ürün İsmi</label>
        </span>
          </div>
          <div className="p-field p-col-8 p-md-4">
      <span className="p-float-label marginInput">
          <InputTextarea autoResize id="description" value={createModel?.description}
                         onChange={e => changeModel(e, 'description')}
                         className={isInvalid('description')}


          />
                     <label htmlFor="description">Ürün Açıklaması</label>
        </span>
          </div>
          <div className="p-field p-col-8 p-md-4">

      <span className="p-float-label marginInput">
          <InputText id="barcode" value={createModel?.barcode}
                     onChange={e => changeModel(e, 'barcode')}
                     className={isInvalid('barcode')}


          />
                     <label htmlFor="barcode">Ürün Barkodu</label>
        </span>
          </div>

          <div className="p-field p-col-8 p-md-3">

      <span className="p-float-label marginInput">
          <InputText id="origin" value={createModel?.origin}
                     onChange={e => changeModel(e, 'origin')}
                     className={isInvalid('origin')}

          />
                     <label htmlFor="origin">Menşei</label>
        </span>
          </div>
          <div className="p-field p-col-8 p-md-3">
      <span className="p-float-label marginInput">
        <Dropdown value={createModel?.eolValue}
                  options={["EVET", "HAYIR"]}
                  showClear
                  onChange={e => {
                    let _createModel = {...createModel};
                    if (e.target.value === "EVET") {
                      _createModel.eolValue = "EVET"
                    } else {
                      _createModel.eolValue = "HAYIR"
                    }

                    setCreateModel(_createModel)
                  }}
                  placeholder="Eol giriniz"/>
                             <label htmlFor="origin">EOL</label>

</span>
          </div>
          <div className="p-field p-col-8 p-md-3">
      <span className="p-float-label marginInput">
        <Dropdown value={createModel?.supplyStatus}
                  className={isInvalid('supplyStatus')}
                  id="supplyStatus"
                  options={["AÇIK", "KAPALI"]}
                  onChange={e => {

                    let _errorFields = {...errorFields};
                    _errorFields[e.target.id] = false;
                    if (e.target.value === "") {
                      _errorFields[e.target.id] = true;
                    }
                    setErrorFields(_errorFields)
                    let _createModel = {...createModel};


                    _createModel.supplyStatus = e.target.value;

                    setCreateModel(_createModel)
                  }}
                  placeholder="Tedarikçi Durumu"/>
         <label htmlFor="origin">Tedarik Durumu</label>
</span>

          </div>


          <br/>
          <br/>
          <div className="p-field p-col-8 p-md-8">

            <ResponsiveDataTable
              header="Kayıtlı Ürün Resimleri"
              value={pictures}
              emptyMessage="Resim Bulunamadı.">
              <Column body={imageTemplate} header="Url"/>
              <Column body={e => actions(e)} header="Sil"/>


            </ResponsiveDataTable>

          </div>
          <div className="p-field p-col-8 p-md-4">
            <h5>Yeni Resim yükle</h5>
            <FileUpload ref={fileUploadRef} name="demo[]"
                        url="https://primefaces.org/primereact/showcase/upload.php" multiple accept="image/*"
                        maxFileSize={1000000}
                        onSelect={onTemplateSelect} onError={onTemplateClear}
                        onClear={onTemplateClear}
                         itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                        chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions}/>

          </div>
        </div>


        <div className="p-field p-col-8 p-md-4">
          <Button loading={loading} disabled={loading} onClick={e => upsertModel(e)}

                  label="Kaydet"/>
        </div>
      </div>


    </>
  )
}
export default ProductDetail
