import AspAxios from "../../service/AspAxios";
import * as Paths from '../../service/config'

export default class ResellerService {

  resellerSearchLazy(request, _size, _page, _sort) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.resellerSearchLazy + '?size=' + _size + '&page=' + _page  + '&sort=' + _sort, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  resellerSearchEager(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.resellerSearchEager, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  resellerScore(resellerCode) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.resellerScore + "/" + resellerCode).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  resellerScoreWaitingTransactions(resellerCode) {
    return new Promise((resolve, reject) => {
      AspAxios.get(Paths.resellerScoreWaitingTransactions + "/" + resellerCode).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  resellerScoreApproveWaitingTransactions(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.resellerScoreApproveWaitingTransactions, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  resellerScoreCancelWaitingTransactions(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.resellerScoreCancelWaitingTransactions, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  resellerScoreSearchTransactions(request, _size, _page, _sort) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.resellerScoreSearchTransactions + '?size=' + _size + '&page=' + _page  + '&sort=' + _sort, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }


  resellerDistributionProduct(request, _size, _page) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.resellerDistributionProducts,request,{params:{size:_size,page:_page}}).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  resellerDistributionProductList(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.resellerDistributionProductsList,request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

  resellerLoginTicket(request) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.resellerLoginTicket, request).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })
  }

};
