import React, { useState, useEffect, useRef } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";

import { Column } from "primereact/column";
import "./OrderListDemo.css";

const Sales = ({ onClick }) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const [campaigns, setCampaigns] = React.useState([
    {
      id: 1,
      name: 100,
      campaign:"100 TL Aksesuar Kampanyası",
      description: "220 TL tahsil et, kampanyaya ekle",
    },
    {
      id: 2,
      name: 200,
      campaign:"200 TL Aksesuar Kampanyası ",
      description: "120 TL tahsil et, kampanyaya ekle",
    },
    {
      id: 3,
      name: 300,
      campaign:"300 TL Aksesuar Kampanyası ",
      description: "20 TL tahsil et, kampanyaya ekle",
    },
    {
      id: 4,
      name: 400,
      campaign:"400 TL Aksesuar Kampanyası",
      description: "80 ekle, dahil et",
    },
    {
      id: 5,
      name: 500,
      campaign:"500 TL Aksesuar Kampanyası",
      description: "",
    },
  ]);
  const toast = useRef();
  const [selectedCampaign, setSelectedCampaign] = React.useState({
    description: "20 TL tahsil et, kampanyaya ekle",
    id: 3,
    name: 300,
  });

  const campaignBodyTemplate = (item) => {
    let className = "";
    if (item.name < 320) {
      className += "price price-text-green p-p-3 p-text-center";
    }
    if (item.name > 320) {
      className += "price price-text-yellow p-p-3 p-text-center";
    }
    if (item.name === 500) {
      className += "price price-text-gray p-p-3 p-text-center p-disabled";
    }
    return (
      <div className="p-fluid p-formgrid p-grid">
        <div className="p-col-12 p-md-6">
          <h1 className={className} style={{ borderRadius: 8 }}>
            {item.campaign}
          </h1>
        </div>
      </div>
    );
  };

  const dialogHeader = () => {
    return (
      <div className="p-d-flex p-ai-center">
        <i
          style={{ fontSize: "1em", color: "orange" }}
          className="pi pi-shopping-cart p-mr-3"
        ></i>
        <div style={{ color: "orange" }}>ŞİFRE</div>
      </div>
    );
  };
  return (
    <div>
      <Toast ref={toast} />
      <div className="card">
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-6">
            <Button
              label="Alışverişe devam et"
              icon="pi pi-shopping-cart"
              onClick={onClick}
              className={"p-button"}
              style={{ backgroundColor: "orange", border: 0 }}
            />
          </div>
        </div>

        <div className="p-field p-col-12 p-md-6 p-m-0">
          <div className=" p-ai-center p-jc-center p-mt-2">
            <h5 className="p-mr-2" style={{ fontSize: 12 }}>
              ÖDENECEK TUTAR:{" "}
            </h5>
            <p className="p-mr-2">
              <h4
                style={{
                  color: "green",
                  fontSize: 24,
                  fontWeight: "bold",
                }}
              >
                320 TL
              </h4>
            </p>
          </div>
        </div>

        <DataTable
          tableStyle={{ margin: 0 }}
          value={campaigns}
          selection={selectedCampaign}
          onSelectionChange={(e) => setSelectedCampaign(e.value)}
          dataKey="id"
          selectionMode="checkbox"
        >
          <Column
            selectionMode="single"
            headerStyle={{ width: "3rem" }}
            frozen
          ></Column>
          <Column
            field="name"
            header="Kampanya"
            body={campaignBodyTemplate}
          ></Column>
          <Column
            field="description"
            header="Açıklama"
            style={{ fontWeight: "bold" }}
          ></Column>
        </DataTable>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-6">
            <Button
              label="SATIŞI TAMAMLA"
              icon="pi pi-credit-card"
              onClick={() => setShowDialog(true)}
              className={"p-button"}
              style={{ border: 0, height: 55 }}
            />
          </div>
        </div>

        <Dialog
          header={dialogHeader}
          visible={showDialog}
          onHide={() => setShowDialog(false)}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "50vw", height: "50vw" }}
          contentClassName="price-text-green"
          contentStyle={{
            backgroundColor: "#c8e6c9",
            color: "#256029",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              fontSize: 35,
              textAlign: "center",
              marginTop: 10,
              letterSpacing: 3,
              fontWeight: "bold",
            }}
          >
            XC2RM64KF
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default Sales;
