import React, {useEffect, useState} from "react";
import {Dropdown} from "primereact/dropdown";
import * as services from "./httpService";
import {handleError} from "../../service/HandleErrorService";

const DistyDropdown = ({
                              id,
                              value,
                              onChange,
                              disabled,
                              toast
                            }) => {

  const [disties, setDisties] = useState([]);

  useEffect(() => {
    getAssignableRoles();
  }, []);

  const getAssignableRoles = () => {
    services
      .getActiveDisties()
      .then((res) => {
        setDisties(res.content);
      })
      .catch((err) => {
        handleError(err, toast)
      });
  };

  return (
        <span className="p-float-label marginInput">
             <Dropdown showClear
                       id={id && "d_id"}
                       value={value}
                       disabled={disabled}
                       onChange={onChange}
                       style={{width: 300}}
                       optionLabel="name"
                       options={disties}/>
              <label htmlFor="origin">Distribütör</label>
        </span>
  );
};

export default DistyDropdown;
