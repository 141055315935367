import {menuTree} from "./MenuTree";

const Menu = () => {
  let roles = JSON.parse(localStorage.getItem('user')).user?.roles;

  function hasIntersect(_array1, _array2) {
    return _array1?.some(r => _array2?.includes(r));
  }

  function filterByRole(_menuTree, _roles) {
    let filteredTree = [];
    for (let parentIndex = 0; parentIndex < _menuTree.length; parentIndex++) {
      let currentParentNode = _menuTree[parentIndex];
      let copyParentNode;
      if (hasIntersect(currentParentNode?.roles, _roles)) {
        if (currentParentNode?.items) {
          copyParentNode = {...currentParentNode, items: []};
        } else {
          copyParentNode = {...currentParentNode};
          filteredTree?.push(copyParentNode);
          // child yoksa devam etme
          continue;
        }
      } else {
        continue;
      }

      for (let childIndex = 0; childIndex < _menuTree[parentIndex].items?.length; childIndex++) {

        let currentChildNode = _menuTree[parentIndex].items[childIndex];
        if (hasIntersect(currentChildNode?.roles, _roles)) {
          copyParentNode?.items.push(currentChildNode);
        }
      }
      filteredTree.push(copyParentNode);
    }
    return filteredTree;
  }

  return [
    {
      items: [
        {label: "Dashboard", icon: "pi pi-fw pi-home", to: "/dashboard"},
      ],
    },
    {
      items: filterByRole(menuTree, roles)
    }
  ];
};

export default Menu;