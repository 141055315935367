import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import {handleError} from "../../service/HandleErrorService";
import {GiftService} from "./GiftService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {FileUpload} from "primereact/fileupload";
import {Calendar} from "primereact/calendar";

const ApproveCampaignSerials = () => {

  const toast = useRef(null);
  const giftService = new GiftService();

  const [loading, setLoading] = useState(false);
  const [accessoryCampaignSerialTitleList, setAccessoryCampaignSerialTitleList] = useState([]);

  const [searchCriteriaLazyLoading, setSearchCriteriaLazyLoading] = useState(false);
  const [searchCriteriaProgressing, setSearchCriteriaProgressing] = useState(false);
  const [serialValue, setSerialValue] = useState(null);
  const [serialExcelDialog, setSerialExcelDialog] = useState(false);
  const [exportExcelDisabled, setExportExcelDisabled] = useState(false);
  const [validExcelRows, setValidExcelRows] = useState([]);

  const [selectedTimeBegin, setSelectedTimeBegin] = useState(null);
  const [selectedTimeEnd, setSelectedTimeEnd] = useState(null);

  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  useEffect(() => {
    getAll({...paginationConfig, activePage: 0});
  }, []);

  useEffect(() => {
    setSearchCriteriaLazyLoading(true);
  }, [serialValue, selectedTimeBegin, selectedTimeEnd]);

  useEffect(() => {
    if (searchCriteriaLazyLoading) {
      setTimeout(() => {
        setSearchCriteriaProgressing(true);
        setSearchCriteriaLazyLoading(false);
      }, 2000);
    }
  }, [searchCriteriaLazyLoading]);

  useEffect(() => {
    if (searchCriteriaProgressing) {
      getAll({...paginationConfig, activePage: 0});
      setSearchCriteriaProgressing(false);
    }
  }, [searchCriteriaProgressing]);

  const getAll = (pageState) => {
    var dateControl = true;
    if (selectedTimeBegin != null && selectedTimeEnd != null && selectedTimeBegin.getTime() > selectedTimeEnd.getTime()) {
      dateControl = false;
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Aramak için başlangıç tarihi bitiş tarihinden önce olmalıdır.'
      });
    }
    if (dateControl) {
      setLoading(true);
      const request = {
        serialValue: serialValue
      };
      if (selectedTimeBegin != null) {
        request.startDate = selectedTimeBegin.toLocaleDateString("tr-TR") + " " + selectedTimeBegin.toLocaleTimeString("tr-TR");
      } else {
        request.startDate = null;
      }
      if (selectedTimeEnd != null) {
        request.endDate = selectedTimeEnd.toLocaleDateString("tr-TR") + " " + selectedTimeEnd.toLocaleTimeString("tr-TR");
      } else {
        request.endDate = null;
      }
      giftService.getAllApprovedSerialsBySerialValue(request, pageState.itemsPerPage, pageState.activePage).then(res => {
        console.log("getAllApprovedSerials", res.content);
        setAccessoryCampaignSerialTitleList(res.content);

        pageState.totalRecords = res.totalElements;
        pageState.first = pageState.itemsPerPage * pageState.activePage;
        setPaginationConfig({
          ...pageState,
        });
      }).catch(err => handleError(err, toast)).finally(() => {
        setLoading(false);
      });
    }
  };

  const exportExcel = () => {
    var dateControl = false;
    if(serialValue != null && serialValue !== "") {
      dateControl = true
    } else if (selectedTimeBegin != null && selectedTimeEnd != null) {
      if (selectedTimeEnd.getTime() - selectedTimeBegin.getTime() <= 32 * 24 * 3600000) {
        if (selectedTimeBegin.getTime() <= selectedTimeEnd.getTime()) {
          dateControl = true;
        } else {
          toast.current.show({
            severity: 'warn',
            summary: 'Uyarı',
            detail: 'Başlangıç tarihi bitiş tarihinden önce olmalıdır.'
          });
        }
      } else {
        toast.current.show({
          severity: 'warn',
          summary: 'Uyarı',
          detail: 'Excel almak için tarih aralığı bir aydan fazla olmamalıdır.'
        });
      }
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Excel almak için tarih alanları veya seri numara filtresi dolu olmalıdır.'
      });
    }
    if (dateControl) {
      setExportExcelDisabled(true);
      const request = {
        serialValue: serialValue
      };
      if (selectedTimeBegin != null) {
        request.startDate = selectedTimeBegin.toLocaleDateString("tr-TR") + " " + selectedTimeBegin.toLocaleTimeString("tr-TR");
      } else {
        request.startDate = null;
      }
      if (selectedTimeEnd != null) {
        request.endDate = selectedTimeEnd.toLocaleDateString("tr-TR") + " " + selectedTimeEnd.toLocaleTimeString("tr-TR");
      } else {
        request.endDate = null;
      }
      giftService.getAllApprovedSerialsEagerBySerialValue(request).then(res => {
        import('xlsx').then(xlsx => {
          var excelList = [];
          res.forEach(a => {
            excelList.push({
              "Onaylanma Zamanı": a.approvedDateString,
              "Onaylayan Kişi Adı": a.userDto.name,
              "Onaylayan Kişi Soyadı": a.userDto.surname,
              "Seri Numara": a.serialValue,
            })
          });
          const worksheet = xlsx.utils.json_to_sheet(excelList);
          var wscols = [
            {width: 20},
            {width: 20},
            {width: 20},
            {width: 20},
          ];
          worksheet["!cols"] = wscols;
          const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
          const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
          saveAsExcelFile(excelBuffer, 'Onaylanan Kampanya Seri Numaraları');
        });
      }).catch(err => {
        toast?.current?.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'Onaylanan kampanya seri numaraları getirme başarısız.'
        });
        console.log(err);
      }).finally(() => {
        setExportExcelDisabled(false);
      });
    }
  };

  const openSerialExcelModal = () => {
    setSerialExcelDialog(true);
  }

  const exportExampleSerialExcel = () => {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet([
        {
          "Kampanya Seri Numara": "serinumara1",
        }
      ]);
      const wscols = [
        {width: 20},
      ];
      worksheet["!cols"] = wscols;
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      saveAsExcelFile(excelBuffer, 'Seri Numara Onaylamak İçin Örnek Excel Dosyası');
    });
  }

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const importExcel = ({files}) => {
    if (files.length === 1) {
      const file = files[0];
      let formData = new FormData();
      formData.append('file', file);
      files.length = 0;
      import('xlsx')
        .then(xlsx => {
          const reader = new FileReader();
          reader.onload = async (e) => {
            const wb = xlsx.read(e.target.result, {type: 'array'});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = xlsx.utils.sheet_to_json(ws, {header: 1});

            const cols = data[0];
            data.shift();

            let _importedData = data?.map(d => {
              return cols.reduce((obj, c, i) => {
                obj[c] = d[i];
                return obj;
              }, {});
            });

            let validRecords = [];

            _importedData.forEach(value => {
              let serial = null;
              if (value['Kampanya Seri Numara'] !== undefined && value['Kampanya Seri Numara'] !== null) {
                serial = value['Kampanya Seri Numara'].toString()
              }

              if (serial !== null) {
                validRecords.push({serial})
              }

            })

            setValidExcelRows(validRecords)
          };

          reader.readAsArrayBuffer(file);
        });
    }
  };


  const clear = () => {
    setValidExcelRows([])
  }

  const onHideSerialDialog = () => {
    if (!loading) {
      clear()
      setSerialExcelDialog(false)
    }
  }

  const saveValidRows = () => {
    setLoading(true);
    giftService.approveSerials(validExcelRows)
      .then(res => {
        clear()
        setSerialExcelDialog(false)
        getAll({...paginationConfig, activePage: 0});
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.current?.show({
            severity: "error",
            summary: "Hata",
            detail: err?.response?.data?.message,
            life: 4000,
          });
        }
        setLoading(false);
      });
  }

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    getAll(pageState);
  };

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const createDateTimeTemplate = (data) => {
    return responsiveColumn("Onaylanma Zamanı", data?.approvedDateString)
  };

  const nameTemplate = (data) => {
    return responsiveColumn("Onaylayan Kişi Adı", data?.userDto.name)
  };

  const surnameTemplate = (data) => {
    return responsiveColumn("Onaylayan Kişi Soyadı", data?.userDto.surname)
  };

  const serialTemplate = (data) => {
    return responsiveColumn("Seri Numara", data?.serialValue)
  };

  const header =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>Onaylanan Kampanya Seri Numaraları</div>
        <div>
          <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
                  disabled={exportExcelDisabled}
                  onClick={exportExcel}/>
        </div>
      </div>
    </div>;

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card">
          <div className="p-grid">
            <div className="p-col-12 p-md-2 p-fluid">
              <label htmlFor="baslangic">Tarihi Aralığı</label>
              <Calendar id="baslangic" showIcon dateFormat={'dd.mm.yy'} style={{marginTop: 5}}
                        value={selectedTimeBegin} showTime showButtonBar
                        onChange={(e) => setSelectedTimeBegin(e.value)}
                        placeholder="Tarih Başlangıç"/>
            </div>
            <div className="p-col-12 p-md-2 p-fluid"><br className="small_hidden"/>
              <Calendar id="bitis" showIcon dateFormat={'dd.mm.yy'} style={{marginTop: 5}}
                        value={selectedTimeEnd} showTime showButtonBar
                        onChange={(e) => setSelectedTimeEnd(e.value)}
                        placeholder="Tarih Bitiş"/>
            </div>
            <div className="p-col-12 p-md-2 p-fluid">
              <label htmlFor="seeial">Kampanya Seri Numara</label>
              <InputText id="seeial" style={{marginBottom: 10, marginRight: 10, marginTop: 5}} value={serialValue}
                         placeholder="Seri Numara"
                         onChange={(e) => setSerialValue(e.target.value)}/>
            </div>
            <div className="p-col-12 p-md-3 p-fluid"></div>
            <div className="p-col-12 p-md-3 p-fluid">
              <br className="small_hidden"/>
              <Button onClick={openSerialExcelModal} icon="pi pi-plus-circle" label="Kampanya Seri Numara Onaylama" style={{marginTop: 5}}
                      className="p-button-success p-mr-2 p-mb-2"/>
            </div>
          </div>
          <ResponsiveDataTable
            header={header}
            className="p-datatable-responsive-demo"
            loading={loading}
            value={accessoryCampaignSerialTitleList}
            paginationConfig={paginationConfig}
            onActivePageChange={onActivePageChange}
            emptyMessage="Seri numara bulunamadı"
          >
            <Column body={createDateTimeTemplate} header="Onaylanma Zamanı"/>
            <Column body={nameTemplate} header="Onaylayan Kişi Adı"/>
            <Column body={surnameTemplate} header="Onaylayan Kişi Soyadı"/>
            <Column body={serialTemplate} header="Seri Numara"/>
          </ResponsiveDataTable>
        </div>
      </div>
      <Dialog
        header='Kampanya Seri Numara Onaylama'
        style={{width: '50vw'}}
        onHide={onHideSerialDialog}
        visible={serialExcelDialog}
      >

        <div className="p-d-flex p-ai-center p-py-2">
          <Button label="Örnek Excel Dokümanı İndir" icon={"pi pi-download"} onClick={e => exportExampleSerialExcel(e)}
                  className="p-button-help p-mr-2" disabled={loading}/>
          <FileUpload
            disabled={loading}
            maxFileSize={100000000}
            chooseOptions={{label: 'Excel Dokümanı Yükle', icon: 'pi pi-file-excel', className: 'p-button-primary'}}
            mode="basic" name="demo[]" auto
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            className="p-mr-2" customUpload={true} uploadHandler={importExcel}/>

          {validExcelRows?.length > 0 ? <Button type="button" style={{marginRight: 10}} disabled={loading}
                                                label="Yüklenen Verileri Temizle" icon="pi pi-times"
                                                onClick={clear} className="p-button-info"/> : ""}
        </div>

        {
          validExcelRows?.length > 0 &&
          <ResponsiveDataTable
            loading={loading}
            header="Exceldeki Seri Numaralar"
            value={validExcelRows}
            paginator rows={5}
          >
            <Column field="serial"/>
          </ResponsiveDataTable>
        }

        <div className="p-d-flex p-ai-center p-my-3">
          {
            validExcelRows?.length > 0 &&
            <Button label="Exceldeki Seri Numaraları Onayla" icon={"pi pi-upload"} onClick={e => saveValidRows(e)}
                    className="p-button-success" disabled={loading}/>
          }
        </div>
      </Dialog>
    </>
  )

};

export default ApproveCampaignSerials;
