import AspAxios from "../../service/AspAxios";
import * as Paths from "../../service/config"
import {handleError} from "../../service/HandleErrorService";
import {modelPriceSupplyApproveRequestList} from "../../service/config";
import axios from "axios";

export class SupplyPriceService {

  findAllFilter(modelPriceSupplyDistyApprove) {
    return new Promise((resolve, reject) =>
      AspAxios.post(Paths.findAllApproveWaitingModelPriceSupply, modelPriceSupplyDistyApprove
      ).then(res => {
        resolve(res.data)
      }).catch(err => reject(err)));
  }

  approveModelPriceSupply(request, value) {
    return new Promise((resolve, reject) =>
      AspAxios.post(Paths.modelPriceSupplyApproveRequest + `/${value}`, request)
        .then(data => resolve(data))
        .catch(err => reject(err)))
  }

  approveModelPriceSupplyList(request,value) {
    return new Promise((resolve, reject) =>
      AspAxios.post(Paths.modelPriceSupplyApproveRequestList + `/${value}`, request)
        .then(data => resolve(data))
        .catch(err => reject(err)))
  }

  getapproveTypes() {
    return new Promise((resolve, reject) => {
      axios.get(Paths.allApproveTypes).then(res => {
        resolve(res.data)
      }).catch(err => reject(err))

    })
  }
}
