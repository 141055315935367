import React, {useEffect, useRef, useState} from "react";
import {BannerService} from "./BannerService";
import {handleError} from "../../service/HandleErrorService";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Calendar} from "primereact/calendar";
import {FileUpload} from "primereact/fileupload";
import moment from "moment";
import {Toast} from "primereact/toast";

const BannerView = () => {
  const toast = useRef(null);
  const bannerService = new BannerService();
  const [createBanner, setCreateBanner] = useState({});
  const [createDialog, setCreateDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [editBanner, setEditBanner] = useState({});
  var pattern = /(\d{2}).(\d{2}).(\d{4})/;
  const [allBanners, setAllBanners] = useState([]);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [saveBrandButtonDisabled, setSaveBrandButtonDisabled] = useState(true);

  const [brandImageModal, setBrandImageModal] = useState(false);
  const [selectedBrandForImage, setSelectedBrandForImage] = useState(null);
  useEffect(() => {
    findAll();
  }, [])
  useEffect(() => {
    setCreateBanner({});
    setSelectedImageFile(null);
    setSaveBrandButtonDisabled(true);

  }, [createDialog])

  const findAll = () => {
    console.log("1")
    bannerService.findAllBanners().then(res => setAllBanners(res)).catch(err => handleError(err, toast))
  }

  const renderHeader = () =>

    <Button onClick={e => setCreateDialog(true)} icon="pi pi-plus-circle" label="Yeni Banner Ekle"
            className="p-button-success p-mr-2 p-mb-2"/>
  const header = renderHeader();

  const photoUploadHandler = ({files}) => {
    if (files.length === 1) {
      const [file] = files;
      setSelectedImageFile(file);
      setSaveBrandButtonDisabled(false);
    } else {
      toast?.current?.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Sadece bir tane resim seçilmiş olmalıdır.'
      });
    }
  };
  const saveEditBanner = () => {
    let _editBanner = {...editBanner};
    console.log(_editBanner.endDate)
    _editBanner.startDate = moment(_editBanner?.startDate).format("YYYY-MM-DDTHH:mm:ss");
    _editBanner.endDate = moment(_editBanner?.endDate).format("YYYY-MM-DDTHH:mm:ss");
    bannerService.updateBanner(_editBanner).then(res => {

      toast?.current?.show({
        severity: 'success',
        summary: 'Başarılı İşlem',
        detail: 'Banner güncellendi.'
      });
      setEditDialog(false);
      findAll();
    }).catch(err => handleError(err, toast));


  }
  const saveBrand = () => {
    let _createBanner = {...createBanner};
    _createBanner.startDate = moment(_createBanner?.startDate).format("YYYY-MM-DDTHH:mm:ss");
    _createBanner.endDate = moment(_createBanner?.endDate).format("YYYY-MM-DDTHH:mm:ss");

    if (selectedImageFile != null) {
      let formData = new FormData();
      formData.append('file', selectedImageFile);
      bannerService.upsertBanner(formData, _createBanner?.startDate, _createBanner?.endDate).then(res => {
        toast?.current?.show({
          severity: 'success',
          summary: 'Başarılı İşlem',
          detail: 'Banner oluşturuldu.'
        });
        setSelectedImageFile(null);
        setSaveBrandButtonDisabled(true);
        setCreateBanner({});
        setCreateDialog(false);

        findAll();
      }).catch(err => handleError(err, toast))
    }

  }
  const brandImageModalShow = (e) => {
    allBanners.forEach(b => {
      if (b.id == e.currentTarget.id) {
        setSelectedBrandForImage(b);
      }
    });
    setBrandImageModal(true);
  };
  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };
  const bannerImageTemplate = (data) => {
    return responsiveColumn("Banner Resmi", <Button id={data.id} type="button" className="p-button-link" label="Resim"
                                                    onClick={brandImageModalShow}/>)
  }

  const brandImageModalHide = () => {
    setBrandImageModal(false);
  };

  const actions = (rowData) => {

    return (
      <div><Button label="Düzenle" icon="pi pi-pencil"
                   className="p-button-rounded p-button-warning" onClick={e => {
        setEditBanner(rowData)
        setEditDialog(true)
      }}/>

        <Button style={{marginLeft: 10}} label="Sil"
                className="p-button-rounded p-button-danger" onClick={e => {

          bannerService.deleteBanner(rowData?.id).then(res => {
            toast?.current?.show({
              severity: 'success',
              summary: 'Başarılı İşlem',
              detail: 'Banner silindi.'
            });
            findAll();
          }).catch(err => handleError(err, toast));
        }}/>
      </div>
    )
  }
  return (
    <>
<Toast ref={toast}/>
      <ResponsiveDataTable
        header={header}
        value={allBanners}
      >
        <Column field="startDate" body={e => {
          return moment(e.startDate).format("DD-MM-YYYY/HH:mm:SS")
        }} header="Başlangıç Tarihi"/>
        <Column field="endDate" body={e => {
          return moment(e.endDate).format("DD-MM-YYYY/HH:mm:SS")
        }} header="Bitiş Tarihi"/>
        <Column body={bannerImageTemplate} header="Resim"/>
        <Column body={e => actions(e)}/>


      </ResponsiveDataTable>


      <Dialog style={{width: 450}} visible={createDialog} onHide={e => setCreateDialog(false)}>
        <h5>Banner Ekleme</h5>

        <span className="p-float-label marginInput">

          <Calendar id="startDate" showIcon showTime value={createBanner?.startDate} dateFormat="dd-mm-yyyy"
                    onChange={e => setCreateBanner({startDate: e.value, ...createBanner})}>
          </Calendar>
 <label htmlFor="startDate">Başlangıç Tarihi</label>
        </span>

        <span className="p-float-label marginInput">

          <Calendar id="endDate" showIcon showTime value={createBanner?.endDate} dateFormat="dd-mm-yyyy"
                    onChange={e => setCreateBanner({endDate: e.value, ...createBanner})}>
          </Calendar>
          <label htmlFor="endDate">Bitiş Tarihi</label>
        </span>

        <span className="p-float-label marginInput">

        <FileUpload mode="basic" name="photo" accept="image/*" maxFileSize={1000000}
                    chooseLabel="Banner Resmi Seçin" auto customUpload={true}
                    uploadHandler={photoUploadHandler} disabled={selectedImageFile != null}/>
        </span>

        {selectedImageFile && <p><b>Seçili resim:</b> {selectedImageFile?.name}</p>}

        <Button style={{marginTop: 20}} className="p-button-success" label="Kaydet"
                disabled={saveBrandButtonDisabled} onClick={saveBrand}/>
      </Dialog>

      <Dialog header={" Banner Resmi"} visible={brandImageModal} style={{width: '300px'}}
              onHide={brandImageModalHide}>
        <img src={selectedBrandForImage?.image?.publicAddress} alt="Banner Image" style={{width: 256, height: 100}}/>
      </Dialog>


      <Dialog style={{width: 450}} visible={editDialog} onHide={e => setEditDialog(false)}>
        <h5>Banner Tarihi Düzenle</h5>

        <span className="p-float-label marginInput">

          <Calendar id="startDate" showIcon showTime showSeconds dateFormat="dd-mm-yyyy"
                    value={new Date(editBanner?.startDate)}
                    onChange={e => {
                      let _editBanner = {...editBanner};
                      _editBanner.startDate = e.value;
                      setEditBanner(_editBanner);
                    }}>
          </Calendar>
 <label htmlFor="startDate">Başlangıç Tarihi</label>
        </span>

        <span className="p-float-label marginInput">

          <Calendar id="endDate" showIcon showTime dateFormat="dd-mm-yyyy" showSeconds
                    value={new Date(editBanner?.endDate)}
                    onChange={e => {

                      let _editBanner = {...editBanner};
                      _editBanner.endDate = e.value;
                      setEditBanner(_editBanner);
                    }}>
          </Calendar>
          <label htmlFor="endDate">Bitiş Tarihi</label>
        </span>


        <p><b>Seçili resim:</b> {editBanner?.image?.name}</p>

        <Button style={{marginTop: 20}} className="p-button-success" label="Kaydet"
                onClick={saveEditBanner}/>
      </Dialog>

      <Dialog header={" Banner Resmi"} visible={brandImageModal} style={{width: '300px'}}
              onHide={brandImageModalHide}>
        <img src={selectedBrandForImage?.image?.publicAddress} alt="Banner Image" style={{width: 256, height: 100}}/>
      </Dialog>
    </>
  )

}
export default BannerView;
