import AspAxios from "../../service/AspAxios";
import * as Paths from "../../service/config";

  class MarjService  {


    getAll(searchProductPrice) {
    return new Promise((resolve, reject) => {
      AspAxios.post(Paths.getAllMarj, searchProductPrice).then(res =>
        resolve(res.data)
      ).catch(err => reject(err))
    })

  }

    categoryTree() {
      return new Promise((resolve, reject) => {
        AspAxios.get(Paths.getCategoryTree).then(res =>
          resolve(res.data)
        ).catch(err => reject(err))
      })

    }

    upsert(upsertMarj) {
      return new Promise((resolve, reject) => {
        AspAxios.post(Paths.upsertlMarj,upsertMarj).then(res =>
          resolve(res.data)
        ).catch(err => reject(err))
      })

    }

}
export default MarjService;
