import * as Paths from "./config";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const axios = require('axios').default;
//axios.defaults.timeout = 5000;
axios.defaults.headers.common["Accept-language"] = "tr";
axios.defaults.headers.common["x-ttg-app-name"] = "Map";


axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");

  if (!config.url.includes("public")) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  }

  return config;
});

//response interceptor to refresh token on receiving token expired error

const refreshAuthLogic = failedRequest => axios.post(Paths.refresh, {
  refreshToken: localStorage.getItem("refreshToken"),
  token: localStorage.getItem("token")
}).then(tokenRefreshResponse => {

  if (tokenRefreshResponse) {
    localStorage.setItem('token', tokenRefreshResponse.data.token);
    localStorage.setItem('refreshToken', tokenRefreshResponse.data.refreshToken);


    return Promise.resolve();
  }
});
createAuthRefreshInterceptor(axios, refreshAuthLogic);

class AspAxios {


  static async get(url = "", config = {}) {

    return await axios.get(url, config);

  }

  static async post(url = "", body = {}, config = {}) {

    return await axios.post(url, body, config);

  }

  static async put(url = "", body = {}, config = {}) {

    return await axios.put(url, body, config);
  }

  static async delete(url = "", data = {}, config = {}) {

    return await axios.delete(url, config);

  }


  // Set the AUTH token for any request

};

export default AspAxios;
