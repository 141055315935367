import { useHistory, useParams, useLocation } from "react-router-dom";
import ResellerOrderService from "./httpService";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Carousel } from "primereact/carousel";
import "./Carousel.css";
import { Galleria } from "primereact/galleria";
import { Badge } from "primereact/badge";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { handleError } from "../../service/HandleErrorService";
import { Toast } from "primereact/toast";
import Bestsellers from "../../assets/images/bestsellers.png";
import New from "../../assets/images/new.png";

const ResellerProductOrderCreate = () => {
  const location = useLocation();

  const [createButton, setCreateButton] = useState(false);
  const services = new ResellerOrderService();
  const toast = useRef(null);
  const { productId, barcode } = useParams();

  const [similarProducts, setSimilarProducts] = useState([]);
  const history = useHistory();
  const [quantity, setQuantity] = useState(0);
  const [product, setProduct] = useState({});

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];

  useEffect(() => {
    getDistyProductDetail();
    getSimilarProducts();
  }, [productId]);

  const getDistyProductDetail = () => {
    services
      .getDistyProductDetailById(productId)
      .then((res) => {
        setProduct(res);
      })
      .catch((err) => handleError(err, toast));
  };

  const getSimilarProducts = () => {
    services
      .getSimilarProducts(productId)
      .then((res) => {
        setSimilarProducts(res);
      })
      .catch((err) => handleError(err, toast));
  };

  const productTemplate = (similarProduct) => {
    return (
      <div className="product-item">
        <div className="product-item-content">
          {similarProduct.firstPicture !== undefined ? (
            <div className="p-mb-3">
              {labelCheckSimilar(similarProduct)}
              <img
                src={similarProduct?.firstPicture?.baseUrl}
                style={{ width: 200, height: 130 }}
                onError={(e) =>
                  (e.target.src =
                    "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                }
                className="product-image"
              />
            </div>
          ) : (
            <div className="p-mb-3">
              <img
                src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
                className="product-image"
              />
            </div>
          )}

          <div>
            <Badge
              value={similarProduct?.stockStatusLabel}
              size="normal"
              severity={similarProduct?.severity}
            />

            <p className="p-mb-3" style={{ fontSize: 12 }}>
              <b>{similarProduct?.modelName}</b>
            </p>
            <h5 className="p-mt-0 p-mb-3">{similarProduct?.price}₺</h5>
            <Button
              icon="pi pi-search"
              label="Ürüne git"
              className="btn btn-primary"
              onClick={(e) => changeModel(similarProduct)}
            />
          </div>
        </div>
      </div>
    );
  };

  const changeModel = (_product) => {
    history.push("/resellerProduct/" + _product?.modelId);
  };

  const itemTemplate = (item) => {
    return (
      <img
        src={item?.baseUrl}
        onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        alt={item.alt}
        style={{ width: 600, height: 420 }}
      />
    );
  };

  const createProductOrder = (e) => {
    setCreateButton(true);
    let _productOrderRequest = {
      modelId: parseInt(productId),
      quantity: quantity,
      barcode: barcode,
    };
    services
      .resellerCreateProductOrder(_productOrderRequest)
      .then((res) => {
        toast?.current?.show({
          severity: "success",
          detail: "Ürün Sepete Eklendi.",
        });

        setTimeout(() => {
          history.push("/resellerOrderManagement");
        }, 1000);
      })
      .catch((err) => {
        handleError(err, toast);
        setCreateButton(false);
      });
  };

  const labelCheck = (item) => {
    const labels = [];
    item?.model?.labels?.map((item) => labels.push(item?.labelStatus));
    if (
      labels.includes("NEW_PRODUCT") &&
      !labels.includes("SELL_BEST_PRODUCT")
    ) {
      return (
        <div style={{ position: "relative" }}>
          <img src={New} className="new-best-sellers" />
        </div>
      );
    }
    if (
      labels.includes("SELL_BEST_PRODUCT") &&
      !labels.includes("NEW_PRODUCT")
    ) {
      return (
        <div style={{ position: "relative" }}>
          <img src={Bestsellers} className="new-best-sellers" />
        </div>
      );
    }
    if (labels.includes("NEW_PRODUCT" && "SELL_BEST_PRODUCT")) {
      return (
        <div style={{ position: "relative" }}>
          <img src={New} className="new-new-best-sellers" />
          <img src={Bestsellers} className="new-best-sellers" />
        </div>
      );
    }
  };

  const labelCheckSimilar = (item) => {
    const labels = [];
    item?.labels?.map((item) => labels.push(item?.labelStatus));
    if (
      labels.includes("NEW_PRODUCT") &&
      !labels.includes("SELL_BEST_PRODUCT")
    ) {
      return (
        <div style={{ position: "relative" }}>
          <img src={New} className="new-similar" />
        </div>
      );
    }
    if (
      labels.includes("SELL_BEST_PRODUCT") &&
      !labels.includes("NEW_PRODUCT")
    ) {
      return (
        <div style={{ position: "relative" }}>
          <img src={Bestsellers} className="new-similar" />
        </div>
      );
    }
    if (labels.includes("NEW_PRODUCT" && "SELL_BEST_PRODUCT")) {
      return (
        <div style={{ position: "relative" }}>
          <img src={New} className="new-similar" />
          <img src={Bestsellers} className="new-best-sellers-similar" />
        </div>
      );
    }
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="card">
        <Button
          icon="pi pi-arrow-left"
          label="Ürün Listesine Git"
          className="p-button-outlined p-button-info"
          onClick={(e) => {
            history.push({
              pathname: "/resellerOrderManagement",
              state: { label: location?.state?.label },
            });
          }}
        />
      </div>

      <div className="p-d-flex p-flex-column p-flex-md-row">
        <div className="p-mb-2 p-mr-1">
          {labelCheck(product)}
          {product?.model?.pictures ? (
            <Galleria
              value={product?.model?.pictures}
              responsiveOptions={responsiveOptions}
              numVisible={1}
              circular
              style={{ maxWidth: "640px" }}
              showItemNavigators
              showThumbnails={false}
              item={itemTemplate}
            />
          ) : (
            <img
              style={{ width: 600, height: 420 }}
              src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
              onError={(e) =>
                (e.target.src =
                  "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
              }
            />
          )}
        </div>

        <div className="p-mb-2 p-mb-sm-2">
          <div>
            <h6
              style={{
                color: "blue",
              }}
            >
              {product?.model?.subCategory?.category?.name} -{">"}{" "}
              {product?.model?.subCategory?.name} -{">"}{" "}
              {product?.model?.brand?.name}
            </h6>
          </div>

          <div>
            <h5>
              <b>{product?.model?.name}</b>
            </h5>
          </div>

          <div>
            <h5>{product?.modelPriceDisty?.resellerPrice}₺</h5>
          </div>

          <div>
            <p style={{ paddingRight: 15 }}>
              <b>Ürün Açıklaması :</b>
              {product?.model?.description}
            </p>
          </div>

          <br />
          <br />

          <div>
            <div>
              <Badge
                value={product?.stockStatusLabel}
                size="normal"
                severity={product?.severity}
              />
            </div>

            <div>
              <br />
              <br />

              <InputNumber
                step={0}
                min={0}
                className="form-control"
                placeholder="Miktar"
                value={quantity}
                onChange={(e) => {
                  setQuantity(e.value);
                }}
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />

              <Button
                label="Sepete Ekle"
                onClick={(e) => createProductOrder(e)}
                disabled={product?.stockStatus === "NONE" || createButton}
                className={
                  product?.reserveStatus === "RESERVED"
                    ? "p-button-success"
                    : "p-button-primary"
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        {similarProducts.length > 0 ? (
          <Carousel
            value={similarProducts}
            numVisible={3}
            className="custom-carousel"
            circular
            autoplayInterval={3000}
            itemTemplate={productTemplate}
            header={<h5 style={{ fontSize: 24 }}>Benzer Ürünler</h5>}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default ResellerProductOrderCreate;
