import React, {useEffect, useRef, useState} from "react";
import {Toast} from 'primereact/toast';
import ProductSalesManagementService from "./httpService";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {ResponsiveDataTable} from "../../components/ResponsiveDataTable";
import {Calendar} from "primereact/calendar";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {InputTextarea} from "primereact/inputtextarea";
import {Checkbox} from "primereact/checkbox";

const CancelSale = () => {

  const toast = useRef(null);
  const productSalesManagementService = new ProductSalesManagementService();

  const [loading, setLoading] = useState(false);
  const [searchCriteriaLazyLoading, setSearchCriteriaLazyLoading] = useState(false);
  const [searchCriteriaProgressing, setSearchCriteriaProgressing] = useState(false);
  const [saleInfoList, setSaleInfoList] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState({});
  const [selectedParentResellerCode, setSelectedParentResellerCode] = useState(null);
  const [selectedTtCrmName, setSelectedTtCrmName] = useState(null);
  const [selectedResellerCode, setSelectedResellerCode] = useState(null);

  const [detailModal, setDetailModal] = useState(false);
  const [selectedSale, setSelectedSale] = useState(false);

  const [selectedTimeBegin, setSelectedTimeBegin] = useState(null);
  const [selectedTimeEnd, setSelectedTimeEnd] = useState(null);
  const [serialNo, setSerialNo] = useState(null);
  const [pass, setPass] = useState(null);
  const [serial, setSerial] = useState(null);
  const [saleType, setSaleType] = useState(null);
  const [saleStatus, setSaleStatus] = useState(null);

  const [exportExcelDisabled, setExportExcelDisabled] = useState(null);

  const [cancelModalHeader, setCancelModalHeader] = useState(null);
  const [cancelModalMessage, setCancelModalMessage] = useState(null);
  const [cancelModal, setCancelModal] = useState(false);

  const [selectedProductSaleId, setSelectedProductSaleId] = useState(null);
  const [selectedActionType, setSelectedActionType] = useState(null);
  const [rejectCancelSaleDescription, setRejectCancelSaleDescription] = useState(null);

  const [multiCancelDisabled, setMultiCancelDisabled] = useState(true);
  const [multiCancelModal, setMultiCancelModal] = useState(null);
  const [multiCancelModalMessage, setMultiCancelModalMessage] = useState(null);
  const [renderKey, setRenderKey] = useState(0);

  const [paginationConfig, setPaginationConfig] = useState({
    itemsPerPage: 10,
    first: 0,
    totalRecords: 30,
    activePage: 0,
  });

  const [saleTypeOptions, setSaleTypeOptions] = useState(
    [
      {value: "TEMLIK", name: "Kampanyalı"},
      {value: "VANILLA", name: "Peşin-Kredi Kartlı"}
    ]
  );

  const [saleStatusOptions, setSaleStatusOptions] = useState(
    [
      {value: "SALE_CANCEL_APPROVAL", name: "İptal Onayı"}
    ]
  );

  useEffect(() => {
    searchSaleInfo({...paginationConfig, activePage: 0});
  }, []);

  useEffect(() => {
    setSearchCriteriaLazyLoading(true);
  }, [selectedTimeBegin, selectedTimeEnd, selectedTtCrmName,selectedParentResellerCode, selectedResellerCode, serialNo, serial, pass, saleType, saleStatus, searchCriteria]);

  useEffect(() => {
    if (searchCriteriaLazyLoading) {
      setTimeout(() => {
        setSearchCriteriaProgressing(true);
        setSearchCriteriaLazyLoading(false);
      }, 2000);
    }
  }, [searchCriteriaLazyLoading]);

  useEffect(() => {
    if (searchCriteriaProgressing) {
      searchSaleInfo({...paginationConfig, activePage: 0});
      setSearchCriteriaProgressing(false);
    }
  }, [searchCriteriaProgressing]);

  const searchSaleInfo = (pageState) => {
    var dateControl = true;
    if (selectedTimeBegin != null && selectedTimeEnd != null && selectedTimeBegin.getTime() > selectedTimeEnd.getTime()) {
      dateControl = false;
      toast.current.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'İşlem aramak için başlangıç tarihi bitiş tarihinden önce olmalıdır.'
      });
    }
    if (dateControl) {
      setLoading(true);

      searchCriteria.parentResellerCode = selectedParentResellerCode;
      searchCriteria.ttCrmName = selectedTtCrmName;
      searchCriteria.resellerCode = selectedResellerCode;
      searchCriteria.serialNo = serialNo;
      searchCriteria.serial = serial;
      searchCriteria.pass = pass;
      searchCriteria.saleType = saleType;
      searchCriteria.saleStatus = saleStatus;

      if (selectedTimeBegin != null) {
        searchCriteria.saleDateStart = selectedTimeBegin.toLocaleDateString("tr-TR") + " " + selectedTimeBegin.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.saleDateStart = null;
      }

      if (selectedTimeEnd != null) {
        searchCriteria.saleDateEnd = selectedTimeEnd.toLocaleDateString("tr-TR") + " " + selectedTimeEnd.toLocaleTimeString("tr-TR");
      } else {
        searchCriteria.saleDateEnd = null;
      }

      searchCriteria.productSaleStatusList = ["SALE_CANCEL_APPROVAL", "SERIAL_NO_CANCEL_APPROVAL"];

      productSalesManagementService.searchProductSaleEager(searchCriteria).then(res => {
        console.log("searchProductSaleEager", res);
        res.forEach(r => {
          r.selected = false;
        });
        setMultiCancelDisabled(true);
        setSaleInfoList(res);
      }).catch(err => {
        toast?.current?.show({
          severity: 'error',
          summary: 'Hata',
          detail: 'İşlem bilgisi getirme başarısız.'
        });
        console.log(err);
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  const exportExcel = () => {
    setExportExcelDisabled(true);

    searchCriteria.resellerCode = selectedResellerCode;
    searchCriteria.serialNo = serialNo;
    searchCriteria.serial = serial;
    searchCriteria.pass = pass;

    if (selectedTimeBegin != null) {
      searchCriteria.saleDateStart = selectedTimeBegin.toLocaleDateString("tr-TR") + " " + selectedTimeBegin.toLocaleTimeString("tr-TR");
    } else {
      searchCriteria.saleDateStart = null;
    }

    if (selectedTimeEnd != null) {
      searchCriteria.saleDateEnd = selectedTimeEnd.toLocaleDateString("tr-TR") + " " + selectedTimeEnd.toLocaleTimeString("tr-TR");
    } else {
      searchCriteria.saleDateEnd = null;
    }

    searchCriteria.productSaleStatusList = ["SALE_CANCEL_APPROVAL", "SERIAL_NO_CANCEL_APPROVAL"];

    productSalesManagementService.searchProductSaleEager(searchCriteria).then(res => {
      console.log("searchProductSaleEager", res);
      import('xlsx').then(xlsx => {
        var excelList = [];
        res.forEach(a => {
          let barcodeList = "";
          let serialNoList = "";
          let brandList = "";
          let modelList = "";
          let priceList = "";
          let saleStatusList = "";
          a.productSerialNoList?.forEach(p => {
            barcodeList += p.barcode + ",";
            serialNoList += p.serialNo + ",";
            brandList += p.model?.brand?.name + ",";
            modelList += p.model?.name + ",";
            priceList += addCommas(p.recommendedSellPrice) + "|";
            saleStatusList += p.saleStatusDisplay + ",";
          });
          barcodeList = barcodeList.substring(0, barcodeList.length - 1);
          serialNoList = serialNoList.substring(0, serialNoList.length - 1);
          brandList = brandList.substring(0, brandList.length - 1);
          modelList = modelList.substring(0, modelList.length - 1);
          priceList = priceList.substring(0, priceList.length - 1);
          saleStatusList = saleStatusList.substring(0, saleStatusList.length - 1);
          excelList.push({
            "İşlem Tarihi": a.completedDateTimeDisplay,
            "İşlem Tipi": a.productSaleTypeDisplay,
            "İşlem Durumu": a.productSaleStatusDisplay,
            "Müşteri Tipi": a.ttCrm?.name,
            "Ana Bayi Kod": a.reseller?.parentReseller?.code,
            "Ana Bayi Ad": a.reseller?.parentReseller?.name,
            "Bayi Kod": a.reseller?.code,
            "Bayi Ad": a.reseller?.name,
            "İşlemi Yapan Ad": a.sellerUser?.name,
            "İşlemi Yapan Soyad": a.sellerUser?.surname,
            "İşlem Fiyatı": addCommas(a.finalPrice),
            "İskonto Tutarı": addCommas(a?.discountAmount),
            "Müşteri Faturası Tutarı": a?.accessoryCampaign != null ? addCommas(a?.accessoryCampaign?.termAmount * a?.accessoryCampaign?.termNumber) : null,
            "Peşin/Kredi Kartı ile Ödenen Tutar": a?.accessoryCampaign != null ? addCommas(a?.finalPrice - a?.accessoryCampaign?.amount) : null,
            "Seçilen Kampanya": a.accessoryCampaign?.description,
            "Kampanya Seri": a.accessoryCampaignSerial?.serialValue,
            "Kampanya Şifre": a.accessoryCampaignPass?.passValue,
            "Müşteri Adı": a.customerName,
            "Müşteri Soyadı": a.customerSurname,
            "Müşteri Telefon Numarası": a.customerPhoneNo,
            "Müşteri Hizmet No": a.customerServiceNo,
            "Müşteri PSTN No": a.customerPstnNo,
            "Aksesuar Barkodları": barcodeList,
            "Aksesuar Seri Numaraları": serialNoList,
            "Aksesuar Markaları": brandList,
            "Aksesuar Modelleri": modelList,
            "Aksesuar Tavsiye Edilen Satış Fiyatları": priceList,
            "Aksesuar İşlem Durumları": saleStatusList,
          })
        });
        const worksheet = xlsx.utils.json_to_sheet(excelList);
        var wscols = [
          {width: 18},
          {width: 15},
          {width: 15},
          {width: 15},
          {width: 15},
          {width: 60},
          {width: 15},
          {width: 60},
          {width: 18},
          {width: 18},
          {width: 15},
          {width: 15},
          {width: 20},
          {width: 30},
          {width: 35},
          {width: 18},
          {width: 18},
          {width: 15},
          {width: 15},
          {width: 25},
          {width: 20},
          {width: 30},
          {width: 30},
          {width: 30},
          {width: 30},
          {width: 30},
          {width: 30},
          {width: 30}
        ];
        worksheet["!cols"] = wscols;
        const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
        saveAsExcelFile(excelBuffer, 'İptal Onayı Bekleyen İşlemler');
      });
    }).catch(err => {
      toast?.current?.show({
        severity: 'error',
        summary: 'Hata',
        detail: 'İşlem bilgisi getirme başarısız.'
      });
      console.log(err);
    }).finally(() => {
      setExportExcelDisabled(false);
    });
  };

  const onActivePageChange = (e) => {
    let pageState = {
      ...paginationConfig,
      itemsPerPage: e.rows,
      activePage: e.page,
    };
    searchSaleInfo(pageState);
  };

  const onFilter = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const onSort = (e) => {
    let _lazyParams = {...searchCriteria, ...e};
    setSearchCriteria(_lazyParams);
  };

  const detailModalShow = (e) => {
    setDetailModal(true);
    saleInfoList.forEach(s => {
      if (s.id == e.currentTarget.id) {
        setSelectedSale(s);
      }
    });
  };

  const detaillModalHide = () => {
    setDetailModal(false);
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  };

  const cancelSale = () => {
    setLoading(true);
    setDetailModal(false);
    setCancelModal(false);
    const request = {
      productSaleId: selectedProductSaleId
    };
    if (selectedActionType === "approve") {
      productSalesManagementService.cancelSaleApprove(request).then(res => {
        console.log("cancelTheCompletedSale", res);
        toast.current.show({
          severity: 'success',
          summary: 'Başarılı İşlem',
          detail: 'İşlem iptali onaylandı.'
        });
      }).catch(err => {
        console.log(err);
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: err.response.data.message,
          life: 4000
        });
      }).finally(() => {
        searchSaleInfo(paginationConfig);
      });
    } else if (selectedActionType === "reject") {
      if (rejectCancelSaleDescription != null && rejectCancelSaleDescription != "") {
        request.rejectCancelSaleDescription = rejectCancelSaleDescription;
      }
      productSalesManagementService.cancelSaleReject(request).then(res => {
        console.log("cancelSaleReject", res);
        toast.current.show({
          severity: 'success',
          summary: 'Başarılı İşlem',
          detail: 'İşlem iptali reddedildi.'
        });
      }).catch(err => {
        console.log(err);
        toast.current.show({
          severity: 'error',
          summary: 'Hata',
          detail: err.response.data.message,
          life: 4000
        });
      }).finally(() => {
        searchSaleInfo(paginationConfig);
      });
    }
  };

  const renderFooter = () => {
    return (
      <div>
        <Button label="Hayır" icon="pi pi-times" onClick={cancelModalHide} className="p-button-text"/>
        <Button label="Evet" icon="pi pi-check" onClick={cancelSale} autoFocus/>
      </div>
    );
  };

  const selectAll = () => {
    let count = 0;
    saleInfoList.forEach(s => {
      s.selected = true;
      count++;
    });
    if(count > 0){
      setMultiCancelDisabled(false);
    }
    setRenderKey(Math.random());
  };

  const multiCancelModalShow = () => {
    var count = 0;
    saleInfoList.forEach(s => {
      if (s.selected) {
        count++;
      }
    });
    if (count > 0) {
      setMultiCancelModalMessage(count + " tane işlemin iptalini onaylamak istediğinizden emin misiniz?");
      setMultiCancelModal(true);
    } else {
      toast?.current?.show({
        severity: 'warn',
        summary: 'Uyarı',
        detail: 'Seçilen işlem yok.'
      });
    }
  };

  const multiCancelModalHide = () => {
    setMultiCancelModal(false);
  };

  const multiCancelSale = () => {
    setLoading(true);
    setMultiCancelModal(false);
    let productSaleIdList = [];
    saleInfoList.forEach(s => {
      if (s.selected) {
        productSaleIdList.push(s.id);
      }
    });
    const request = {
      productSaleIdList: productSaleIdList
    };
    productSalesManagementService.multiCancelSaleApprove(request).then(res => {
      console.log("multiCancelSaleApprove", res);
      toast.current.show({
        severity: 'success',
        summary: 'Başarılı İşlem',
        detail: 'İşlem iptali onaylandı.'
      });
    }).catch(err => {
      console.log(err);
      toast.current.show({
        severity: 'error',
        summary: 'Hata',
        detail: err.response.data.message,
        life: 4000
      });
    }).finally(() => {
      searchSaleInfo(paginationConfig);
    });
  };

  const multiCancelRenderFooter = () => {
    return (
      <div>
        <Button label="Hayır" icon="pi pi-times" onClick={multiCancelModalHide} className="p-button-text"/>
        <Button label="Evet" icon="pi pi-check" onClick={multiCancelSale} autoFocus/>
      </div>
    );
  };

  const saleCancelApproveModelShow = (e) => {
    saleInfoList.forEach(s => {
      if (s.id == e.currentTarget.id) {
        setSelectedProductSaleId(s.id);
      }
    });
    setSelectedActionType("approve");
    setCancelModalHeader("İşlem İptal Onaylama");
    setCancelModalMessage("İşlem iptalini onaylamak istediğinizden emin misiniz?");
    setCancelModal(true);
  };

  const saleCancelRejectModelShow = (e) => {
    saleInfoList.forEach(s => {
      if (s.id == e.currentTarget.id) {
        setSelectedProductSaleId(s.id);
      }
    });
    setSelectedActionType("reject");
    setCancelModalHeader("İşlem İptal Reddetme");
    setCancelModalMessage("İşlem iptalini reddetmek istediğinizden emin misiniz?");
    setRejectCancelSaleDescription(null);
    setCancelModal(true);
  };

  const cancelModalHide = () => {
    setSelectedProductSaleId(null);
    setSelectedActionType(null);
    setCancelModal(false);
  };

  const parentResellerCodeFilterFilter = <InputText value={selectedParentResellerCode}
                                                    onChange={(e) => setSelectedParentResellerCode(e.target.value)}
                                                    className="p-column-filter"/>;

  const ttCrmNameFilter = <InputText value={selectedTtCrmName}
                                                    onChange={(e) => setSelectedTtCrmName(e.target.value)}
                                                    className="p-column-filter"/>;

  const resellerCodeFilter = <InputText value={selectedResellerCode}
                                        onChange={(e) => setSelectedResellerCode(e.target.value)}
                                        className="p-column-filter"/>;

  const addCommas = num => num?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const responsiveColumn = (title, content) => {
    return (
      <div>
        <div className="p-column-title">{title}</div>
        {content}
      </div>
    );
  };

  const responsiveColumnRight = (title, content) => {
    return (
      <div>
        <span className="p-column-title">{title}</span>
        <span style={{float: 'right'}}>{content}</span>
      </div>
    );
  };

  const saleDateTemplate = (data) => {
    return responsiveColumn("İşlem Tarihi", data?.completedDateTimeDisplay)
  };

  const productSaleStatusDisplayTemplate = (data) => {
    return responsiveColumn("İşlem Durumu", data?.productSaleStatusDisplay)
  };

  const productSaleTypeDisplayTemplate = (data) => {
    return responsiveColumn("İşlem Tipi", data?.productSaleTypeDisplay)
  };

  const ttCrmTemplate = (data) => {
    return responsiveColumn("Müşteri Tipi", data?.ttCrm?.name)
  };

  const parentResellerCodeTemplate = (data) => {
    return responsiveColumn("Ana Kod", data?.reseller?.parentReseller?.code + " - " + data?.reseller?.parentReseller?.name)
  };

  const resellerCodeTemplate = (data) => {
    return responsiveColumn("Kod", data?.reseller?.code + " - " + data?.reseller?.name)
  };

  const nameTemplate = (data) => {
    return responsiveColumn("İşlemi Yapan Ad", data?.sellerUser?.name)
  };

  const surnameTemplate = (data) => {
    return responsiveColumn("İşlemi Yapan Soyad", data?.sellerUser?.surname)
  };

  const vatIncludedPriceTemplate = (data) => {
    return responsiveColumnRight("İşlem Fiyatı", addCommas(data?.finalPrice))
  };

  const accessoryCampaignSerialTitleButtonTemplate = (data) => {
    return (
      <div className="p-d-flex p-justify-even">
        <Button id={data.id} type="button" className="p-button-sm p-button-info" label="Detay"
                onClick={detailModalShow}/>
        <Button id={data.id} type="button" className="p-button-sm p-button-secondary" label="İptal Ret"
                onClick={saleCancelRejectModelShow}/>
        <Button id={data.id} type="button" className="p-button-sm p-button-danger" label="İptal Onay"
                onClick={saleCancelApproveModelShow}/>
      </div>
    );
  };

  const barcodeTemplate = (data) => {
    return responsiveColumn("Barkod", data?.barcode)
  };

  const serialNoTemplate = (data) => {
    return responsiveColumn("Seri Numara", data?.serialNo)
  };

  const brandTemplate = (data) => {
    return responsiveColumn("Marka", data?.model?.brand?.name)
  };

  const modelTemplate = (data) => {
    return responsiveColumn("Model", data?.model?.name)
  };

  const saleStatusDisplayTemplate = (data) => {
    return responsiveColumn("İşlem Durumu", data?.saleStatusDisplay)
  };

  const priceTemplate = (data) => {
    return responsiveColumnRight("Tavsiye Edilen Satış Fiyatı", addCommas(data?.recommendedSellPrice))
  };

  const header =
    <div>
      <div className="p-d-flex p-justify-between">
        <div>İptal Onayı Bekleyen İşlemler</div>
        <Button type="button" icon="pi pi-external-link" label="Excel'e Aktar" className="p-button p-button-sm"
                disabled={exportExcelDisabled}
                onClick={exportExcel}/>
      </div>
    </div>;

  const renderSaleTypeFilter = () => {
    return (
      <Dropdown value={saleType} options={saleTypeOptions} onChange={(e) => setSaleType(e.value)} optionLabel="name"
                showClear className="p-column-filter"/>
    );
  };

  const renderSaleStatusFilter = () => {
    return (
      <Dropdown value={saleStatus} options={saleStatusOptions} onChange={(e) => setSaleStatus(e.value)}
                optionLabel="name" showClear className="p-column-filter"/>
    );
  };

  const saleTypeFilterElement = renderSaleTypeFilter();
  const saleStatusFilterElement = renderSaleStatusFilter();

  const onChangeSelected = (e) => {
    let count = 0;
    saleInfoList.forEach(s => {
      if (s.id == e.target.id) {
        s.selected = e.target.checked;
      }
      if(s.selected){
        count++;
      }
    });
    if(count > 0){
      setMultiCancelDisabled(false);
    } else {
      setMultiCancelDisabled(true);
    }
    setRenderKey(Math.random());
  };

  const selectedTemplate = (rowData) => {
    return <Checkbox id={rowData.id}
                     style={{cursor: 'context-menu'}} checked={rowData.selected}
                     onChange={e => onChangeSelected(e)}/>
  };

  return (
    <>
      <Toast ref={toast}/>
      <div className="datatable-responsive-demo">
        <div className="card p-grid">
          <div className="p-col-12 p-md-3 p-fluid">
            <label htmlFor="baslangic">İşlem Tarihi Aralığı</label>
            <Calendar id="baslangic" showIcon dateFormat={'dd.mm.yy'} style={{marginTop: 5}}
                      value={selectedTimeBegin} showTime showButtonBar
                      onChange={(e) => setSelectedTimeBegin(e.value)}
                      placeholder="İşlem Tarihi Başlangıç"/>
            <Calendar id="bitis" showIcon dateFormat={'dd.mm.yy'} style={{marginTop: 5}}
                      value={selectedTimeEnd} showTime showButtonBar
                      onChange={(e) => setSelectedTimeEnd(e.value)}
                      placeholder="İşlem Tarihi Bitiş"/>
          </div>
          <div className="p-col-12 p-md-3 p-fluid">Aksesuar Ürün Seri No
            <InputText style={{marginTop: 5}} value={serialNo} placeholder="Seri No"
                       onChange={(e) => setSerialNo(e.target.value)}/>
          </div>
          <div className="p-col-12 p-md-3 p-fluid">Kampanya Seri Ve Şifre
            <InputText style={{marginTop: 5}} value={serial} placeholder="Seri"
                       onChange={(e) => setSerial(e.target.value)}/>
            <InputText style={{marginTop: 5}} value={pass} placeholder="Şifre"
                       onChange={(e) => setPass(e.target.value)}/>
          </div>
          <div className="p-col-12">
            <Button onClick={selectAll} label="Tümünü Seç"/>
            <Button onClick={multiCancelModalShow} label="Seçilenlerin İptalini Onayla" style={{marginLeft: 7}} disabled={multiCancelDisabled}
                    className="p-button-danger"/>
          </div>
          <div className="p-col-12">
            <ResponsiveDataTable
              header={header}
              value={saleInfoList}
              paginator rows={10} rowsPerPageOptions={[10, 20, 30]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} arası gösteriliyor"
              loading={loading}
              emptyMessage="İptal onayı bekleyen işlem bulunamadı"
              style={{fontSize: '11px'}}
            >
              <Column field="reserved" body={selectedTemplate} headerStyle={{width: '2rem'}}/>
              <Column field="completedDateTime" body={saleDateTemplate} header="İşlem Tarihi" sortable/>
              <Column field="productSaleType" body={productSaleTypeDisplayTemplate} header="İşlem Tipi" filter
                      filterElement={saleTypeFilterElement}/>
              <Column field="productSaleStatus" body={productSaleStatusDisplayTemplate} header="İşlem Durumu" filter
                      filterElement={saleStatusFilterElement}/>
              <Column field="ttCrm.name" body={ttCrmTemplate} header="Müşteri Tipi" filter={true}
                      headerStyle={{width: "16%"}}
                      filterElement={ttCrmNameFilter} filterMatchMode={"contains"}/>
              {/* <Column field="ttCrm.name" body={ttCrmTemplate} header="Müşteri Tipi" filter={true}
                      filterMatchMode={"contains"}/> */}
              <Column field="parentResellerCode" body={parentResellerCodeTemplate} header="Ana Kod" filter={true}
                      headerStyle={{width: "16%"}}
                      filterElement={parentResellerCodeFilterFilter} filterMatchMode={"contains"}/>
              <Column field="resellerCode" body={resellerCodeTemplate} header="Kod" filter={true}
                      headerStyle={{width: "16%"}}
                      filterElement={resellerCodeFilter} filterMatchMode={"contains"}/>
              <Column field="sellerUser.name" body={nameTemplate} header="İşlemi Yapan Ad" filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="sellerUser.surname" body={surnameTemplate} header="İşlemi Yapan Soyad" filter={true}
                      filterMatchMode={"contains"}/>
              <Column field="finalPrice" body={vatIncludedPriceTemplate} header="İşlem Fiyatı"
                      headerStyle={{textAlign: 'right'}} sortable/>
              <Column body={accessoryCampaignSerialTitleButtonTemplate}
                      headerStyle={{width: "18rem", textAlign: "center"}}
                      bodyStyle={{textAlign: 'center', overflow: 'visible', justifyContent: 'center'}}/>
            </ResponsiveDataTable>
          </div>
        </div>
        <Dialog header="İşlem Detay" visible={detailModal} modal style={{width: '1000px'}}
                onHide={detaillModalHide}>
          <div className="datatable-responsive-demo">
            <div className="p-grid">
              <div className="p-field p-col-12">
                <p className="marginParagraph"><b>İşlem Tarihi:</b> {selectedSale?.completedDateTimeDisplay}</p>
                <p className="marginParagraph"><b>İşlem Tipi:</b> {selectedSale?.productSaleTypeDisplay}</p>
                <p className="marginParagraph"><b>İşlem Durumu:</b> {selectedSale?.productSaleStatusDisplay}</p>
                {selectedSale?.ttCrm != null &&
                <p className="marginParagraph"><b>Müşteri Tipi:</b> {selectedSale?.ttCrm?.name}</p>}
                <p className="marginParagraph"><b>Ana
                  Kod:</b> {selectedSale?.reseller?.parentReseller?.code} - {selectedSale?.reseller?.parentReseller?.name}
                </p>
                <p className="marginParagraph">
                  <b>Kod:</b> {selectedSale?.reseller?.code} - {selectedSale?.reseller?.name}</p>
                <p className="marginParagraph"><b>İşlemi Yapan Ad:</b> {selectedSale?.sellerUser?.name}</p>
                <p className="marginParagraph"><b>İşlemi Yapan Soyad:</b> {selectedSale?.sellerUser?.surname}</p>
                <p className="marginParagraph"><b>İşlem Fiyatı:</b> {addCommas(selectedSale?.finalPrice)}</p>
                {selectedSale?.discountAmount != null &&
                <p className="marginParagraph"><b>İskonto Tutarı:</b> {addCommas(selectedSale?.discountAmount)}</p>}
                {selectedSale?.accessoryCampaign &&
                <p className="marginParagraph"><b>Müşteri Faturası
                  Tutarı:</b> {addCommas(selectedSale?.accessoryCampaign?.termAmount * selectedSale?.accessoryCampaign?.termNumber)}
                </p>}
                {selectedSale?.accessoryCampaign &&
                <p className="marginParagraph"><b>Peşin/Kredi Kartı ile Ödenen
                  Tutar:</b> {addCommas(selectedSale?.finalPrice - selectedSale?.accessoryCampaign?.amount)}</p>}
                {selectedSale?.accessoryCampaign &&
                <p className="marginParagraph"><b>Seçilen Kampanya:</b> {selectedSale?.accessoryCampaign?.description}
                </p>}
                {selectedSale?.accessoryCampaignSerial &&
                <p className="marginParagraph"><b>Kampanya
                  Seri:</b> {selectedSale?.accessoryCampaignSerial?.serialValue}
                </p>}
                {selectedSale?.accessoryCampaignPass &&
                <p className="marginParagraph"><b>Kampanya Şifre:</b> {selectedSale?.accessoryCampaignPass?.passValue}
                </p>}
                {selectedSale?.customerName &&
                <p className="marginParagraph"><b>Müşteri Adı:</b> {selectedSale?.customerName}</p>}
                {selectedSale?.customerSurname &&
                <p className="marginParagraph"><b>Müşteri Soyadı:</b> {selectedSale?.customerSurname}</p>}
                {selectedSale?.customerPhoneNo &&
                <p className="marginParagraph"><b>Müşteri Telefon Numarası:</b> {selectedSale?.customerPhoneNo}</p>}
                {selectedSale?.customerServiceNo &&
                <p className="marginParagraph"><b>Müşteri Hizmet No:</b> {selectedSale?.customerServiceNo}</p>}
                {selectedSale?.customerPstnNo &&
                <p className="marginParagraph"><b>Müşteri PSTN No:</b> {selectedSale?.customerPstnNo}</p>}
                {selectedSale?.scoreTransactionAmount != null &&
                <p className="marginParagraph"><b>Kazanılan Puan:</b> {addCommas(selectedSale?.scoreTransactionAmount)}
                </p>}
                {selectedSale?.rejectCancelSaleDescription &&
                <p className="marginParagraph"><b>Satışın Durumu:</b> {selectedSale?.rejectCancelSaleDescription}</p>}
                <ResponsiveDataTable value={selectedSale?.productSerialNoList}
                                     header="Aksesuar Bigileri"
                                     style={{fontSize: '13px', marginTop: 10}}
                                     paginator rows={5} rowsPerPageOptions={[5, 10, 15]}
                                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                     currentPageReportTemplate="{totalRecords} kayıttan {first} - {last} arası gösteriliyor">
                  <Column body={barcodeTemplate} header="Barkod"/>
                  <Column body={serialNoTemplate} header="Seri Numara" headerStyle={{width: "11rem"}}/>
                  <Column body={brandTemplate} header="Marka"/>
                  <Column body={modelTemplate} header="Model"/>
                  <Column body={saleStatusDisplayTemplate} header="İşlem Durumu"/>
                  <Column body={priceTemplate} header="Tavsiye Edilen Satış Fiyatı" headerStyle={{textAlign: 'right'}}/>
                </ResponsiveDataTable>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog header={cancelModalHeader} visible={cancelModal} modal style={{width: '400px'}}
                footer={renderFooter('displayConfirmation')} onHide={cancelModalHide}>
          <div className="confirmation-content">
            <span>{cancelModalMessage}</span>
            {selectedActionType && selectedActionType === "reject" &&
            <InputTextarea id="rejectCancelSaleDescription" value={rejectCancelSaleDescription} rows={5} cols={40}
                           placeholder="Reddetme sebebini yazabilirsiniz"
                           autoResize style={{marginTop: 10}}
                           onChange={(e) => setRejectCancelSaleDescription(e.target.value)}/>}
          </div>
        </Dialog>
        <Dialog header={"Seçilenlerin İptalini Onaylama"} visible={multiCancelModal} modal style={{width: '450px'}}
                footer={multiCancelRenderFooter('displayConfirmation')} onHide={multiCancelModalHide}>
          <div className="confirmation-content">
            <span>{multiCancelModalMessage}</span>
          </div>
        </Dialog>
      </div>
    </>
  )

};

export default CancelSale;
